import { Box, Typography } from '@mui/material';
import React from 'react'

const OneToOneMeetingDateView = (props: any) => {
    const { oneToOneMeetingDate } = props;

    return (
        <>
            <div style={{ marginBottom: "20px", marginLeft: "0px" }}>
                <Typography
                    style={{
                        fontSize: "16px",
                        color: "#717171",
                        marginBottom: "10px",
                        marginTop:"10px",
                        fontFamily: "arial"
                    }}
                ><b>One-to-One Meeting Date</b>
                </Typography>
                <Box sx={{ border: "1px solid #E0E0E0", padding: "8.5px 14px", borderRadius: "5px" }}>

                    <div style={{
                        color: "#333333",
                        fontSize: "14px",
                        fontFamily: "arial",
                        fontWeight: "400",
                        textTransform: "none",
                        // padding: "8px",
                        // height: "20px",
                        textAlign: "left",
                        lineHeight: "23px"
                    }}>
                        {oneToOneMeetingDate}
                    </div>

                </Box>
                {/* <div>
                    <input
                        type="date"
                        name="one_to_one_meeting_date"
                        style={{
                            width: "250px",
                            color: "#7b7b7b",
                            height: "37px",
                            border: "1px solid #c6c6c6",
                            borderRadius: "3px",
                            background: "none",
                        }}
                        value={oneToOneMeetingDate}
                        max={new Date().toISOString().slice(0, 10)}
                        data-date-format="DD MMMM YYYY"
                        onKeyDown={(e) => {
                            if (e.code !== "Tab") {
                                e.preventDefault();
                            }
                        }}
                    />
                </div> */}
            </div>
        </>
    )
}

export default OneToOneMeetingDateView