import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box,Breadcrumbs } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import SolidTalents from "../../../../components/Dashboard_Reusable_Component/SolidTalents";
import ExpandNineBox from "../../../../components/Dashboard_Reusable_Component/ExpandNineBox";
import { useLoggedInUser } from "../../../../hooks/useLoggedInUser";
import { useGetNineboxQuery } from "../../../../service/ninebox/ninebox";
import { useGetActiveCalenderQuery, useGetEmployeeByFilterQuery } from "../../../../service";
import Potentialtalents from "../../../../assets/Images/Potentialtalents.svg";
import Solidtalents from "../../../../assets/Images/Solidtalents.svg";
import Star from "../../../../assets/Images/Star.svg";
import Inconsistent from "../../../../assets/Images/Inconsistent.svg";
import Solidperformers from "../../../../assets/Images/Solidperformers.svg";
import HighPerformerstop from "../../../../assets/Images/HighPerformerstop.svg";
import Lowperformers from "../../../../assets/Images/Lowperformers.svg";
import Solidperformer from "../../../../assets/Images/Solidperformer.svg";
import Highperformers from "../../../../assets/Images/Highperformers.svg";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function ExpandNineBoxforMyTeamDashboard(props: any) {
  const { data: user } = useLoggedInUser();
  const location = useLocation();
  const { state }: { state: any } = useLocation();
  console.log(location, "select")
   // @ts-ignore
   const navigationFrom = location?.state?.navigationFrom;
   console.log(navigationFrom,"navigationFrom")
  //  9box values based on the filter coming from the dashboard it's applied to expandviews
   // @ts-ignore
   const low3Value = location?.state?.low3Value;
   // @ts-ignore
   const low4Value = location?.state?.low4Value;
   // @ts-ignore
   const low5Value = location?.state?.low5Value;
   // @ts-ignore
   const moderate3Value = location?.state?.moderate3Value;
   // @ts-ignore
   const moderate4Value = location?.state?.moderate4Value;
   // @ts-ignore
   const moderate5Value = location?.state?.moderate5Value;
   // @ts-ignore
   const high3Value = location?.state?.high3Value;
   // @ts-ignore
   const high4Value = location?.state?.high4Value;
   // @ts-ignore
   const high5Value = location?.state?.high5Value;
   const { data: RangeValue } = useGetNineboxQuery("");
  const navigate = useNavigate();
  console.log(RangeValue, "RangeValue")
  const [indexValue, setindexValue] = React.useState<any>(null);
  //function for nine box
  const [Range, setRange] = React.useState<any>([]);
  const [RangeHighFrom, setRangeHighFrom] = React.useState<any>(4);
  const [RangeHighTo, setRangeHighTo] = React.useState<any>(5);
  const [RangeMediumFrom, setRangeMediumFrom] = React.useState<any>(3);
  const [RangeMediumTo, setRangeMediumTo] = React.useState<any>(3.9);
  const [RangeLowFrom, setRangeLowFrom] = React.useState<any>(1);
  const [RangeLowTo, setRangeLowTo] = React.useState<any>(2.9);
  React.useEffect(() => {
    if (RangeValue?.data[0]?.performance_definitions !== undefined) {
      setRange(RangeValue?.data[0]?.performance_definitions)
      setRangeHighFrom(RangeValue?.data[0]?.performance_definitions?.high_from)
      setRangeHighTo(RangeValue?.data[0]?.performance_definitions?.high_to)
      setRangeMediumFrom(RangeValue?.data[0]?.performance_definitions?.medium_from)
      setRangeMediumTo(RangeValue?.data[0]?.performance_definitions?.medium_to)
      setRangeLowFrom(RangeValue?.data[0]?.performance_definitions?.low_from)
      setRangeLowTo(RangeValue?.data[0]?.performance_definitions?.low_to)
    }
  }, [RangeValue])
  const { data: activecalendardata, isLoading: isTitleLoading } = useGetActiveCalenderQuery('')
  const [appCalId, setappCalId] = React.useState<any>(`636cb1a7960b1548b80ff785`);
  React.useEffect(() => {
    if (activecalendardata?.data[0]?._id != undefined) {
      setappCalId(activecalendardata?.data[0]?._id)
    }
  }, [activecalendardata])
  const { data: low_3 } = useGetEmployeeByFilterQuery(
    `?select=appraisal.appraiser_rating,employee.employee_status,appraisal.appraiser_PA_accepted,normalizer.normalizer_rating,appraisal.potential,appraiser_name,normalizer_name,reviewer_name,employee_code,manager_code,manager_name,manager_position,work_location,legal_full_name,grade,section,sub%20section,division,position_long_description,appraisal.status,talent_category&appraiser_code=${user?.employee_code}&calendar=${appCalId}&appraisal.status=completed&normalizer.normalizer_rating[gte]=${RangeLowFrom}&appraisal.potential=Low&normalizer.normalizer_rating[lte]=${RangeLowTo}`
  );
  const { data: moderate_3 } = useGetEmployeeByFilterQuery(
    `?select=appraisal.appraiser_rating,employee.employee_status,appraisal.appraiser_PA_accepted,normalizer.normalizer_rating,appraisal.potential,appraiser_name,normalizer_name,reviewer_name,employee_code,manager_code,manager_name,manager_position,work_location,legal_full_name,grade,section,sub%20section,division,position_long_description,appraisal.status,talent_category&appraiser_code=${user?.employee_code}&calendar=${appCalId}&appraisal.status=completed&normalizer.normalizer_rating[gte]=${RangeLowFrom}&appraisal.potential=Moderate&normalizer.normalizer_rating[lte]=${RangeLowTo}`
  );
  const { data: high_3 } = useGetEmployeeByFilterQuery(
    `?select=appraisal.appraiser_rating,employee.employee_status,appraisal.appraiser_PA_accepted,normalizer.normalizer_rating,appraisal.potential,appraiser_name,normalizer_name,reviewer_name,employee_code,manager_code,manager_name,manager_position,work_location,legal_full_name,grade,section,sub%20section,division,position_long_description,appraisal.status,talent_category&appraiser_code=${user?.employee_code}&calendar=${appCalId}&appraisal.status=completed&normalizer.normalizer_rating[gte]=${RangeLowFrom}&appraisal.potential=High&normalizer.normalizer_rating[lte]=${RangeLowTo}`
  );
  const { data: low_4 } = useGetEmployeeByFilterQuery(
    `?select=appraisal.appraiser_rating,employee.employee_status,appraisal.appraiser_PA_accepted,normalizer.normalizer_rating,appraisal.potential,appraiser_name,normalizer_name,reviewer_name,employee_code,manager_code,manager_name,manager_position,work_location,legal_full_name,grade,section,sub%20section,division,position_long_description,appraisal.status,talent_category&appraiser_code=${user?.employee_code}&calendar=${appCalId}&appraisal.status=completed&normalizer.normalizer_rating[gte]=${RangeMediumFrom}&appraisal.potential=Low&normalizer.normalizer_rating[lte]=${RangeMediumTo}`
  );
  const { data: moderate_4 } = useGetEmployeeByFilterQuery(
    `?select=appraisal.appraiser_rating,employee.employee_status,appraisal.appraiser_PA_accepted,normalizer.normalizer_rating,appraisal.potential,appraiser_name,normalizer_name,reviewer_name,employee_code,manager_code,manager_name,manager_position,work_location,legal_full_name,grade,section,sub%20section,division,position_long_description,appraisal.status,talent_category&appraiser_code=${user?.employee_code}&calendar=${appCalId}&appraisal.status=completed&normalizer.normalizer_rating[gte]=${RangeMediumFrom}&appraisal.potential=Moderate&normalizer.normalizer_rating[lte]=${RangeMediumTo}`
  );
  const { data: high_4 } = useGetEmployeeByFilterQuery(
    `?select=appraisal.appraiser_rating,employee.employee_status,appraisal.appraiser_PA_accepted,normalizer.normalizer_rating,appraisal.potential,appraiser_name,normalizer_name,reviewer_name,employee_code,manager_code,manager_name,manager_position,work_location,legal_full_name,grade,section,sub%20section,division,position_long_description,appraisal.status,talent_category&appraiser_code=${user?.employee_code}&calendar=${appCalId}&appraisal.status=completed&normalizer.normalizer_rating[gte]=${RangeMediumFrom}&appraisal.potential=High&normalizer.normalizer_rating[lte]=${RangeMediumTo}`
  );
  const { data: low_5 } = useGetEmployeeByFilterQuery(
    `?select=appraisal.appraiser_rating,employee.employee_status,appraisal.appraiser_PA_accepted,normalizer.normalizer_rating,appraisal.potential,appraiser_name,normalizer_name,reviewer_name,employee_code,manager_code,manager_name,manager_position,work_location,legal_full_name,grade,section,sub%20section,division,position_long_description,appraisal.status,talent_category&appraiser_code=${user?.employee_code}&calendar=${appCalId}&appraisal.status=completed&normalizer.normalizer_rating[gte]=${RangeHighFrom}&appraisal.potential=Low&normalizer.normalizer_rating[lte]=${RangeHighTo}`
  );
  const { data: moderate_5 } = useGetEmployeeByFilterQuery(
    `?select=appraisal.appraiser_rating,employee.employee_status,appraisal.appraiser_PA_accepted,normalizer.normalizer_rating,appraisal.potential,appraiser_name,normalizer_name,reviewer_name,employee_code,manager_code,manager_name,manager_position,work_location,legal_full_name,grade,section,sub%20section,division,position_long_description,appraisal.status,talent_category&appraiser_code=${user?.employee_code}&calendar=${appCalId}&appraisal.status=completed&normalizer.normalizer_rating[gte]=${RangeHighFrom}&appraisal.potential=Moderate&normalizer.normalizer_rating[lte]=${RangeHighTo}`
  );
  const { data: high_5 } = useGetEmployeeByFilterQuery(
    `?select=appraisal.appraiser_rating,employee.employee_status,appraisal.appraiser_PA_accepted,normalizer.normalizer_rating,appraisal.potential,appraiser_name,normalizer_name,reviewer_name,employee_code,manager_code,manager_name,manager_position,work_location,legal_full_name,grade,section,sub%20section,division,position_long_description,appraisal.status,talent_category&appraiser_code=${user?.employee_code}&calendar=${appCalId}&appraisal.status=completed&normalizer.normalizer_rating[gte]=${RangeHighFrom}&appraisal.potential=High&normalizer.normalizer_rating[lte]=${RangeHighTo}`
  );

  const [indexBasedValue, setindexBasedValue] = React.useState<any>([]);
  const [indexBasedTitle, setindexBasedTitle] = React.useState<any>('');
  console.log(indexBasedTitle, "indexBasedTitle")
  const NineBoxValues = [
    {
      title: RangeValue?.data[0]?.box_9_definitions[0]?.title,
      count: high3Value?.count,
      category: high_3,
      defenition: RangeValue?.data[0]?.box_9_definitions[0]?.definition,
      color: "linear-gradient(to left, #F89994, #F7665E)",
      icon: <img src={Potentialtalents} alt="image" />,
    },
    {
      title: RangeValue?.data[0]?.box_9_definitions[1]?.title,
      defenition: RangeValue?.data[0]?.box_9_definitions[1]?.definition,
      count: high4Value?.count,
      category: high_4,
      color: "linear-gradient(to left, #71E1F6, #28B7D3)",
      icon: <img src={Solidtalents} alt="image" />,
    },
    {
      title: RangeValue?.data[0]?.box_9_definitions[2]?.title,
      defenition: RangeValue?.data[0]?.box_9_definitions[2]?.definition,
      count: high5Value?.count,
      category: high_5,
      color: "linear-gradient(to left, #71E1F6, #28B7D3)",
      icon: <img src={Star} alt="image" />,
    },
    {
      title: RangeValue?.data[0]?.box_9_definitions[3]?.title,
      defenition: RangeValue?.data[0]?.box_9_definitions[3]?.definition,
      count: moderate3Value?.count,
      category: moderate_3,
      color: "linear-gradient(to left, #F89994, #F7665E)",
      icon: <img src={Inconsistent} alt="image" />,

    },
    {
      title: RangeValue?.data[0]?.box_9_definitions[4]?.title,
      defenition: RangeValue?.data[0]?.box_9_definitions[4]?.definition,
      count: moderate4Value?.count,
      category: moderate_4,
      color: "linear-gradient(to left, #33CDB4, #079B82)",
      icon: <img src={Solidperformers} alt="image" />,
    },
    {
      title: RangeValue?.data[0]?.box_9_definitions[5]?.title,
      defenition: RangeValue?.data[0]?.box_9_definitions[5]?.definition,
      count: moderate5Value?.count,
      category: moderate_5,
      color: "linear-gradient(to left, #33CDB4, #079B82)",
      icon: <img src={HighPerformerstop} alt="image" />,
    },
    {
      title: RangeValue?.data[0]?.box_9_definitions[6]?.title,
      defenition: RangeValue?.data[0]?.box_9_definitions[6]?.definition,
      count: low3Value?.count,
      category: low_3,
      color: "linear-gradient(to left, #F89994, #F7665E)",
      icon: <img src={Lowperformers} alt="image" />,
    },
    {
      title: RangeValue?.data[0]?.box_9_definitions[7]?.title,
      defenition: RangeValue?.data[0]?.box_9_definitions[7]?.definition,
      count: low4Value?.count,
      category: low_4,
      color: "linear-gradient(to left, #F9C5A1, #F99B5B)",
      icon: <img src={Solidperformer} alt="image" />,
    },
    {
      title: RangeValue?.data[0]?.box_9_definitions[8]?.title,
      defenition: RangeValue?.data[0]?.box_9_definitions[8]?.definition,
      count: low5Value?.count,
      category: low_5,
      color: "linear-gradient(to left, #F9C5A1, #F99B5B)",
      icon: <img src={Highperformers} alt="image" />,
    },
  ];
  useEffect(() => {
    if (indexValue == 0) {
      setindexBasedValue(high3Value?.data)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[0]?.title)
    } else if (indexValue == 1) {
      setindexBasedValue(high4Value?.data)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[1]?.title)
    } else if (indexValue == 2) {
      setindexBasedValue(high5Value?.data)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[2]?.title)
    } else if (indexValue == 3) {
      setindexBasedValue(moderate3Value?.data)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[3]?.title)
    } else if (indexValue == 4) {
      setindexBasedValue(moderate4Value?.data)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[4]?.title)
    } else if (indexValue == 5) {
      setindexBasedValue(moderate5Value?.data)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[5]?.title)
    } else if (indexValue == 6) {
      setindexBasedValue(low3Value?.data)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[6]?.title)
    } else if (indexValue == 7) {
      setindexBasedValue(low4Value?.data)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[7]?.title)
    } else if (indexValue == 8) {
      setindexBasedValue(low5Value?.data)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[8]?.title)
    }
  }, [indexValue])

  console.log(indexValue, "indexValue")
// Function for expandNinebox expandview table 
  const handleNavigationForNineBoxEmployee = () => {
    if(navigationFrom == "Appraiser" || navigationFrom== "Reviewer" || navigationFrom== "Normalizer"){
    navigate("/filteredTableofAppraiserExpNineBox", { state: { indexBasedValue: indexBasedValue, indexBasedTitle: indexBasedTitle,navigationFrom:navigationFrom } })
    // }else if( navigationFrom== "Reviewer"){
    //   navigate("/filteredTableofReviewerExpNineBox", { state: { indexBasedValue: indexBasedValue, indexBasedTitle: indexBasedTitle } })
    // }else if( navigationFrom== "Normalizer"){
    //   navigate("/filteredTableofNormalizerExpNineBox", { state: { indexBasedValue: indexBasedValue, indexBasedTitle: indexBasedTitle } })
    }
  }

 
  return (
    <>
      <div
        style={{
          background: "#f1f1f1", minHeight: "100px",
          overflow: "hidden",
          height: "auto",
          paddingBottom: "25px"
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
          minHeight="50px"
          marginLeft="25px"
        >
          {navigationFrom == "Appraiser" &&
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                style={{
                  fontSize: "18px",
                  color: "#3e8cb5",
                  fontFamily: "Arial",
                }}
                color="inherit"
                to={"/dashboardreview"}
              >
                My Team Dashboard
              </Link>
              <Typography
                style={{
                  fontSize: "18px",
                  color: "#333333",
                  fontFamily: "Arial",
                }}
                color="text.primary"
              >
                9-Box Grid
              </Typography>
            </Breadcrumbs>}
          {navigationFrom == "Reviewer" &&
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                style={{
                  fontSize: "18px",
                  color: "#3e8cb5",
                  fontFamily: "Arial",
                }}
                color="inherit"
                to={"/reviewer"}
                state={{
                  from: "toMyTeamDashboard",
                }}
              >
                My Team Dashboard
              </Link>
              <Typography
                style={{
                  fontSize: "18px",
                  color: "#333333",
                  fontFamily: "Arial",
                }}
                color="text.primary"
              >
                9-Box Grid
              </Typography>
            </Breadcrumbs>
          }
          {navigationFrom == "Normalizer" &&
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                style={{
                  fontSize: "18px",
                  color: "#3e8cb5",
                  fontFamily: "Arial",
                }}
                color="inherit"
                to={"/normalizer"}
                state={{
                  from: "toMyTeamDashboard",
                }}
              >
                My Team Dashboard
              </Link>
              <Typography
                style={{
                  fontSize: "18px",
                  color: "#333333",
                  fontFamily: "Arial",
                }}
                color="text.primary"
              >
                9-Box Grid
              </Typography>
            </Breadcrumbs>
          }

        </Stack>
        <div>
          <Box
            style={{
              marginLeft: "25px",
              marginRight: "25px",
              background: "#ffffff",
              padding: "20px",
              height: "auto",
              paddingBottom: "50px"
            }}
          >
            <Grid container spacing={2}>
              <Grid sx={{ paddingRight: "16px" }} item xs={12} md={12} lg={8}>
                <ExpandNineBox nineboxValues={NineBoxValues} BaseStateValue={location.state} setindexValue={setindexValue} />
              </Grid>
              <Grid sx={{
                borderLeft: "1px solid #EEEEEE",
                '@media (max-width: 1024px)': {
                  borderLeft: "none",
                  marginTop: "20px"
                }
              }} item xs={12} md={12} lg={4}>
                <SolidTalents
                  setindexBasedValue={setindexBasedValue}
                  indexBasedValue={indexBasedValue}
                  BaseStateValue={location.state}
                  indexValue={indexValue}
                  setindexBasedTitle={setindexBasedTitle}
                  indexBasedTitle={indexBasedTitle}
                  handleNavigationForNineBoxEmployee={handleNavigationForNineBoxEmployee}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
}
