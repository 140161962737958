import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppraiserAcceptsEmployeeRatingMutation,useGetObjectiveGroupQuery, useCreateEmployeeAppraisalMutation, useGetEmployeeAppraisalQuery, useGetFeedBackQuery, useGetObjectiveTitleQuery, useGetRatingScaleQuery, useUpdateEmployeeAppraisalMutation } from '../../service';
import { useGetPerformanceGoalsQuery } from '../../service/performanceGoals/performanceGoals';
import { useGetNineboxQuery } from '../../service/ninebox/ninebox';
import _ from "lodash";
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { PROMPT } from '../../constants/DialogContents/DialogContents';

// Create the context
const AppraiserContext = createContext<any>(undefined);

// Custom hook to access the context
export function useAppraiserContext() {
  return useContext(AppraiserContext);
}


export function useBlocker(blocker: any, when = true) {

  const { navigator } = useContext(NavigationContext);
  //const navigator = React.useContext(UNSAFE_NavigationContext)
  interface navigator {
    block: {

      any: any
    }
  }
  React.useEffect(() => {
    if (!when) return;
    // @ts-ignore
    const unblock = navigator.block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {

          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [navigator, blocker, when]);
}

export function usePrompt(message: any, when = true) {
  const blocker = useCallback(
    (tx) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) tx.retry();
    },
    [message]
  );

  useBlocker(blocker, when);
}

// Custom hook to provide data to the context
function useAppraiserDataContext() {
  const { employee_id } = useParams();
  const [message, setMessage] = useState("");
  const [tabValue, setTabValue] = useState(0); // set the tab value
  const [moveTab, setMoveTab] = useState(false); // Allow to move between tabs
  const [navPrompt, setNavPrompt] = useState(false); // Allow to navigate between screens
  const [objectiveDescription, setObjectiveDescription] = useState<any>([])
  const [objectiveType, setObjectiveType] = useState<any>([])
  const [currentObjectiveDescription, setCurrentObjectiveDescription] = useState<any>([])
  const [currentOverAllRating, setCurrentOverAllRating] = useState<any>(0)
  const [appraiserRatingPreviousSubmission, setAppraiserRatingPreviousSubmission] = useState<any>("")
  const [disableTextAfterSubmission, setDisableTextAfterSubmission] = useState(false);
  const [visiblityColumnData, setVisiblityColumnData] = useState({
    showPreviousRating: false,
    showAppraiserComments: false,
    showAppraiserRejectionReason: false,
    showEmployeeRating: false,
    showRenormalizedRating: false,
    showHRNormalizerComments: false
  });
  const [PAStatus, setPAStatus] = useState("")
  const [potential, setPotential] = useState("")
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [overallRating, setOverallRating] = useState(0)
  const [ratingDefinition, setRatingDefinition] = useState<any>();
  const [ratingScaleDefinition, setRatingScaleDefinition] = useState<any>();
  const [feedbackQuestionnaire, setFeedbackQuestionnaire] = useState<any>([]);
  const [feedbackQuestionnaireeData, setFeedbackQuestionnaireeData] = useState<any>([]);
  const [appraiserFeedbackQuestionnaire, setAppraiserFeedbackQuestionnaire] = useState<any>([]);
  const [appraiserAreaOfImprovement, setAppraiserAreaOfImprovement] = useState<any>([
    {
      id: Date.now(),
      value: "",
      specific_actions: [{ value: "" }],
    },
  ]);
  const [employeeAreaOfImprovement, setEmployeeAreaOfImprovement] = useState([]);
  const [areaOfImprovement, setAreaOfImprovement] = useState([]);

  const [appraisalTemplateTrainingRecommendation, setAppraisalTemplateTrainingRecommendation] = useState([]);
  const [appraiserTrainingRecommendation, setAppraiserTrainingRecommendation] = useState([
    { name: "", training_name: "", justification: "" },
  ])
  const [employeeTrainingRecommendation, setEmployeeTrainingRecommendation] = useState([])
  const [TrainingRecommendation, setTrainingRecommendation] = useState([])
  const [furtherRecommendation, setFurtherRecommendation] = useState<any>("");
  const [appraiserFurtherRecommendation, setAppraiserFurtherRecommendation] = useState<any>([]);
  const [appraiserFurtherRecommendationOthers, setAppraiserFurtherRecommendationOthers] = useState<any>("")
  const [appraiserFurtherRecommendationOthersCheckbox, setAppraiserFurtherRecommendationOthersCheckbox] = useState<any>(false)
  const [appraiserMessageForEmployee, setAppraiserMessageForEmployee] = useState("")
  const [showAppraiserMessageForEmployee, setShowAppraiserMessageForEmployee] = useState(false)
  const [appraiserPerformanceGoals, setAppraiserPerformanceGoals] = useState([
    { goal_category: "", description: "", keyresult: "", due_date: "", remarks: "" },
  ]); /****************** these are the name property of the fields ****************/
  const [showPotentialEdit, setShowPotentialEdit] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState<any[]>([]);
  const [disableButtons, setDisableButtons] = useState(false); // disabling functional buttons if data is saving or fetching
  const [showReviewerRejectionReason, setShowReviewerRejectionReason] = useState(false);
  const [showreviewerComments, setShowReviewerComments] = useState(false);
  const [reviewerComments, setReviewerComments] = useState<any>("");
  const [normalizerComments, setNormalizerComments] = useState<any>("");
  const [showNormalizerComments, setShowNormalizerComments] = useState(false);
  const [shownormalizerRejectionReason, setShowNormalizerRejectionReason] = useState(false);
  const [normalizerRejectionReason, setNormalizerRejectionReason] = useState<any>("");
  const [employeeComments, setEmployeeComments] = useState("");
  const [showEmployeeComments, setShowEmployeeComments] = useState(false);
  const [oneToOneMeetingDate, setOneToOneMeetingDate] = useState("");
  const [showoneToOneMeetingDate, setShowoneToOneMeetingDate] = useState(false);
  const [showemployeeTrainingRecommendation, setShowEmployeeTrainingRecommendation] = useState(false);
  const [showAppraiserTrainingRecommendation, setShowAppraiserTrainingRecommendation] = useState(false);
  const [showAppraiserAreaOfImprovement, setShowAppraiserAreaOfImprovement] = useState(false);
  const [showEmployeeAreaOfImprovement, setShowEmployeeAreaOfImprovement] = useState(false);
  const [performanceGoalsFieldValues, setPerformanceGoalsFieldValues] = useState<any>("");
  const [performanceGoalsFieldValuesEmployee, setPerformanceGoalsFieldValuesEmployee] = useState<any>("");
  const [showPerformanceGoalsFieldValues, setShowPerformanceGoalsFieldValues] = useState<any>("");
  const [showPerformanceGoalsFieldValuesEmployee, setShowPerformanceGoalsFieldValuesEmployee] = useState<any>("");

  const [reviewerRejectionReason, setReviewerRejectionReason] = useState("");
  const [appraiserAgreedRatingWithEmployee, setAppraiserAgreedRatingWithEmployee] = useState(false);
  const [colorarray, setColorarray] = useState<any>("");
  const Colors = [
    "#B6E9EE",
    "#C9FBEA",
    "#B1EDEE",
    "#B9E9D0",
    "#BDE3E2",
    "#B1F3F2",
    "#B7E6F7",
    "#B8EFEF",
    "#BFFBE7",
    "#B7E6F7",
    "#B1F1F0",
    "#BEECF5",
  ]
  const formIsDirty = navPrompt;
  usePrompt(
    PROMPT,
    formIsDirty);



  // mutations
  const { data: employeeData, refetch: refetchEmployeeData, isLoading: employeeDataIsLoading, isFetching: employeeDataIsFetching } =
    useGetEmployeeAppraisalQuery(employee_id);
  const { data: ratingData } = useGetRatingScaleQuery("");
  const { data: feedbackQuestionnaireData } = useGetFeedBackQuery('')
  const { data: goalsCategoryData } = useGetPerformanceGoalsQuery("")
  const { data: nineBoxData } = useGetNineboxQuery("");
  const { data: objectiveTitleData } = useGetObjectiveTitleQuery("");
  const [updateMutation, { isLoading: employeeDataIsUpdating }] = useUpdateEmployeeAppraisalMutation()
  const [createMutation, { isLoading: isloadingCreateEmployee }] = useCreateEmployeeAppraisalMutation();
  const [appraiserAcceptsEmployee, { isLoading: isAppraiserAcceptsEmployee }] = useAppraiserAcceptsEmployeeRatingMutation();
  const {data : objectiveGroupdata} =useGetObjectiveGroupQuery("")

  // group Area of Improvement by specific Area
  const groupSpecificAreaHandler = (area: any) => {
    if (area) {
      let tempArea = area?.filter((area: any) => {
        return area[0] !== "" && area[0] !== undefined
      })
      if (tempArea && tempArea?.length > 0) {
        return {
          showArea: true,
          area: area,
        }
      } else {
        return {
          showArea: false
        }
      }
    }
  };
  const findObjectiveGropuById = (id: any) => {
    if (objectiveGroupdata) {
      //console.log(id, "objectiveTitleData");
      return objectiveGroupdata?.data?.find((item: any) =>{
       return (
        item?._id === id
       )
    });
    }
  };
  const date = new Date(employeeData?.data?.employee?.one_to_one_meeting?.slice(0, 10));
  const One_To_One_Meeting_Date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  // useEffects
  useEffect(() => {
    if (employeeData) {
      setObjectiveType(employeeData?.data?.appraisal?.objective_type);
      setAppraiserRatingPreviousSubmission(employeeData?.data?.appraisal?.appraiser_rating);
      setPotential(employeeData?.data?.appraisal?.potential);
      setShowPotentialEdit(employeeData?.data?.appraisal_template?.potential === true);
      setEmployeeDetails(() => {
        return {
          employee_code: employeeData?.data?.employee_code,
          previousRating: employeeData?.data?.previous_rating,
          profile_image_url: employeeData?.data?.profile_image_url,
          fullName: employeeData?.data?.legal_full_name,
          firstName: employeeData?.data?.first_name,
          position: employeeData?.data?.position_long_description,
          grade: employeeData?.data?.grade,
          status: employeeData?.data?.appraisal?.status,
          calendarName: employeeData?.data.calendar?.name,
          JoiningDate: employeeData?.data?.JoiningDate,
          Department: employeeData?.data?.Department,
          Tenure: parseFloat(employeeData?.data?.Tenure)?.toFixed(2),
          DepartmentHead: employeeData?.data?.DepartmentHead,
        }
      });
      setPAStatus(employeeData?.data?.appraisal?.pa_status);
      // const formattedRating = (employeeData?.data?.current_rating?.overall_rating?.toFixed(2));
      // setOverallRating(formattedRating);
      setOverallRating(employeeData?.data?.current_rating?.overall_rating?.toFixed(2));
      let objectiveType = employeeData?.data?.appraisal?.objective_group?.map((item: any, index: number) => {
        return {
          objective_group: item?.name?.name,
          color: Colors[index]
        }
      });
      setColorarray(objectiveType)
      setDisableTextAfterSubmission(!employeeData?.data?.appraisal?.pa_status?.includes("Pending with Appraiser"));
      setAttachedFiles(() => {
        return employeeData?.data?.appraisal?.attachments.filter((item: any) => {
          if (item.name != "") return item;
        });
      });
      let tempAppraiserAreaofImprovement = employeeData?.data?.appraisal?.area_of_improvement;
      const groupBySpecificAppraiser = _.groupBy(tempAppraiserAreaofImprovement, "value");
      const groupHandlerAppraiser = groupSpecificAreaHandler(Object.entries(groupBySpecificAppraiser));
      if (groupHandlerAppraiser?.showArea) {
        setShowAppraiserAreaOfImprovement(true);
        setAppraiserAreaOfImprovement(groupHandlerAppraiser?.area)
        setAreaOfImprovement(groupHandlerAppraiser?.area)
      } else {
        setShowAppraiserAreaOfImprovement(false);
      }
      let tempEmployeeAreaofImprovement = employeeData?.data?.employee?.area_of_improvement
      const groupBySpecificEmployee = _.groupBy(tempEmployeeAreaofImprovement, "value");
      const groupHandlerEmployee = groupSpecificAreaHandler(Object.entries(groupBySpecificEmployee));
      if (groupHandlerEmployee?.showArea) {
        setShowEmployeeAreaOfImprovement(true);
        setEmployeeAreaOfImprovement(groupHandlerAppraiser?.area)
      } else {
        setShowEmployeeAreaOfImprovement(false);
      }
      setPerformanceGoalsFieldValues(employeeData?.data?.appraisal?.performance_goal)
      setPerformanceGoalsFieldValuesEmployee(employeeData?.data?.employee?.performance_goal)
      let performancegoalvaluesappraiser = employeeData?.data?.appraisal?.performance_goal
      let performancegoalvaluesemployee = employeeData?.data?.employee?.performance_goal
      if (performancegoalvaluesappraiser?.length == 0) {
        setShowPerformanceGoalsFieldValues(false)
      } else {
        setShowPerformanceGoalsFieldValues(true)
      }
      if (performancegoalvaluesemployee?.length == 0) {
        setShowPerformanceGoalsFieldValuesEmployee(false)
      } else {
        setShowPerformanceGoalsFieldValuesEmployee(true)
      }
      setAppraiserAreaOfImprovement(employeeData?.data?.appraisal?.area_of_improvement);

      setAreaOfImprovement(employeeData?.data?.appraisal?.area_of_improvement);
      setEmployeeAreaOfImprovement(employeeData?.data?.employee?.area_of_improvement)
      setTrainingRecommendation(employeeData?.data?.appraisal?.training_recommendation);
      setAppraisalTemplateTrainingRecommendation(employeeData?.data?.appraisal_template?.training_recommendation);
      // setAppraiserTrainingRecommendation(employeeData?.data?.appraisal?.training_recommendation);
      setAppraiserTrainingRecommendation(() => {
        return employeeData?.data?.appraisal?.training_recommendation.map((j: any) => {
          return {
            name: j?.name?._id,
            training_name: j?.training_name,
            justification: j?.justification,
          };
        });
      });
      setEmployeeTrainingRecommendation(employeeData?.data?.employee?.training_recommendation);
      setFurtherRecommendation(() => {
        let otherRecommendation = employeeData?.data?.appraisal?.other_recommendation?.length > 0 ?
          employeeData?.data?.appraisal?.other_recommendation :
          employeeData?.data?.appraisal?.other_recommendation;
        return employeeData?.data?.appraisal?.other_recommendation?.map(
          (i: any) => {
            return {
              ...i,
              name: i?.name,
            };
          }
        );
      });
      setAppraiserFurtherRecommendation(() => {
        const checked = employeeData?.data?.appraisal?.other_recommendation?.map((i: any) => {
          return {
            ...i?.name,
            _id: i?.name?._id,
            isChecked: i?.isChecked,
          };
        });

        const notChecked = employeeData?.data?.appraisal_template?.other_recommendation?.map((i: any) => {
          return {
            ...i?.name,
            _id: i?.name?._id,
            isChecked: i?.isChecked,
          };
        });

        const newArr = [...notChecked, ...checked]
        // @ts-ignore
        let uniqueObjArray = [...new Map(newArr.map((item) => [item["_id"], item])).values()];
        return uniqueObjArray
      })
      setAppraiserFurtherRecommendationOthers(employeeData?.data?.appraisal?.other_recommendation_others);
      setAppraiserFurtherRecommendationOthersCheckbox(employeeData?.data?.appraisal?.others_checkbox);
      setAppraiserMessageForEmployee(employeeData?.data?.appraisal?.appraiser_overall_feedback);
      setShowAppraiserMessageForEmployee(employeeData?.data?.isGradeException != true);
      setAppraiserPerformanceGoals(() => {
        return employeeData?.data?.appraisal?.performance_goal?.map((j: any) => {
          return {
            goal_category: j?.goal_category?._id,
            description: j?.description,
            keyresult: j?.keyresult,
            due_date: j?.due_date?.slice(0, 10),
            remarks: j?.remarks
          };
        }) || []
      });
      let tempTraining = employeeData?.data?.appraisal?.training_recommendation?.filter((item: any) => {
        return item.name.title !== "" || item.name.title !== undefined
      });

      if (tempTraining && tempTraining?.length > 0) {
        setShowAppraiserTrainingRecommendation(true);
      } else {
        setShowAppraiserTrainingRecommendation(false)
      }
      let TrainingEmployee = employeeData?.data?.employee?.training_recommendation?.filter((item: any) => {
        return item?.name?.title !== "" || item?.name?.title !== undefined
      });
      console.log(TrainingEmployee, 'checkTrainingEmployee')
      if (TrainingEmployee && TrainingEmployee?.length > 0) {
        setShowEmployeeTrainingRecommendation(true);
      } else {
        setShowEmployeeTrainingRecommendation(false)
      }
      setShowReviewerComments(employeeData?.data?.reviewer?.reviewer_comments !== undefined &&
        employeeData?.data?.reviewer?.reviewer_comments !== "" &&
        employeeData?.data?.reviewer?.reviewer_PA_rejected !== true)
      setReviewerComments(employeeData?.data?.reviewer?.reviewer_comments)
      setNormalizerComments(employeeData?.data?.normalizer?.reason_for_rejection)
      setShowNormalizerComments(employeeData?.data?.normalizer?.reason_for_rejection &&
        employeeData?.data?.normalizer?.reason_for_rejection != "" &&
        employeeData?.data?.normalizer?.reason_for_rejection != undefined &&
        employeeData?.data?.normalizer?.normalizer_PA_rejected !== true)
      setShowNormalizerRejectionReason(employeeData?.data?.normalizer?.normalizer_overall_feedback !== undefined &&
        employeeData?.data?.normalizer?.normalizer_overall_feedback !== "" &&
        employeeData?.data?.normalizer?.normalizer_PA_rejected == true)
      setNormalizerRejectionReason(employeeData?.data?.normalizer?.normalizer_overall_feedback)
      setEmployeeComments(employeeData?.data?.employee?.comments)
      setShowEmployeeComments(employeeData?.data?.employee?.comments !== undefined &&
        employeeData?.data?.employee?.comments !== "")
      setOneToOneMeetingDate(One_To_One_Meeting_Date)
      setShowoneToOneMeetingDate(employeeData?.data?.employee?.one_to_one_meeting !== "" &&
        employeeData?.data?.employee?.one_to_one_meeting !== null &&
        employeeData?.data?.employee?.one_to_one_meeting !== undefined)
      setShowReviewerRejectionReason(employeeData && employeeData?.data?.reviewer?.reviewer_overall_feedback !== undefined &&
        employeeData?.data?.reviewer?.reviewer_overall_feedback !== "" &&
        employeeData?.data?.reviewer?.reviewer_PA_rejected == true);
      setReviewerRejectionReason(employeeData?.data?.reviewer?.reviewer_overall_feedback);
      setAppraiserAgreedRatingWithEmployee(employeeData?.data?.appraisal?.appraiserAgreedRatingWithEmployee)
    }
  }, [employeeData])


  const findObjectiveTypeById = (id: any) => {
    if (employeeData) {
      return employeeData?.data?.appraisal?.objective_type?.find(
        (item: any) => item.name._id === id
      );
    }
  }

  // to find objective title by Id
  const findObjectiveTitleById = (id: any) => {
    if (objectiveTitleData) {
      //console.log(id, "objectiveTitleData");
      return objectiveTitleData.data.find((item: any) => item._id === id);
    }
  };

  // to find Previous Rating
  const findPreviousRating = (id: any) => {
    let temp = (employeeData?.data?.appraisal_previous_rating?.objective_description?.filter(
      (i: any) =>
        i?.name === id
    ).map((k: any) => {
      if (ratingData) {
        let temp = ratingData?.data?.find((item: any) => k.ratings == item._id)
        return temp?.rating
      }
    })[0]);
    return temp
  }

  useEffect(() => {
    const sortedObjectiveDescription = Object.keys(employeeData?.data?.appraisal?.objective_description || {})
      .map(key => {
        const j = employeeData.data.appraisal.objective_description[key];
        const objectiveTypes = employeeData?.data?.appraisal?.objective_type || [];
        const objectiveGroupMap = new Map();

        objectiveTypes.forEach((item:any) => {
            if (item?.name?.objective_group) {
                objectiveGroupMap.set(item.name._id, item.name.objective_group);
            }
        });
        return {
          ...j,
          comments: j.comments,
          rejection_reason: j.rejection_reason,
          ratings: j?.ratings?._id,
          rating_rejected: j?.rating_rejected,
          rating_resubmitted: j?.rating_resubmitted,
          previous_rating: findPreviousRating(j.name?._id),
          objective_title: findObjectiveTitleById(j?.name?.objective_title),
          objective_type: findObjectiveTypeById(j?.name?.objective_type),
          objective_group: findObjectiveGropuById(objectiveGroupMap.get(j?.name?.objective_type))
        };
      })
      .sort((a, b) => {
        const typeA = a.objective_group?.name?.toString().toLowerCase()?.trim();
        const typeB = b.objective_group?.name?.toString().toLowerCase()?.trim();

        if (typeA < typeB) return 1;
        if (typeA > typeB) return -1;
        return 0;
      });

    console.log(sortedObjectiveDescription, 'checksortedObjectiveDescription')
    // Set the sorted array back to state or convert it to an object if needed
    setObjectiveDescription(sortedObjectiveDescription);
    // setObjectiveDescription(employeeData?.data?.appraisal?.objective_description?.map((j: any) => {
    //   return {
    //     ...j,
    //     comments: j.comments,
    //     rejection_reason: j.rejection_reason,
    //     ratings: j?.ratings?._id,
    //     rating_rejected: j?.rating_rejected,
    //     rating_resubmitted: j?.rating_resubmitted,
    //     previous_rating: findPreviousRating(j.name?._id),
    //     objective_title: findObjectiveTitleById(j?.name?.objective_title),
    //     objective_type: findObjectiveTypeById(j?.name?.objective_type)
    //   }
    // }));
    setCurrentObjectiveDescription(employeeData?.data?.current_rating?.objective_description?.map((j: any) => {
      return {
        ...j,
        objective_type: findObjectiveTypeById(j.name?.objective_type)

      }
    }))
  }, [employeeData, objectiveType])

  useEffect(() => {
    if (currentObjectiveDescription?.length > 0 && employeeData) {
      const currentObjectiveDescriptionMapped = currentObjectiveDescription?.map((i: any) => {
        // if (i.ratings) {
        // const sum = (i.value * i.objective_type) / 10000
        const sum = (i.value * i.objective_type?.value) / 10000
        const newSum = sum * i?.ratings?.rating
        // console.log(sum, 'newSum')
        // return newSum
        return newSum ? newSum : 0
        // }


      })
      setCurrentOverAllRating(() => {
        return _.sum(currentObjectiveDescriptionMapped)?.toFixed(2)
      });

    }
  }, [currentObjectiveDescription, employeeData, objectiveType])

  useEffect(() => {
    if (currentOverAllRating) {
      let int_CurrentOverAllRating = parseFloat(currentOverAllRating); // Use parseFloat for potential decimal values
      let ratingLabel;

      if (int_CurrentOverAllRating > 0 && int_CurrentOverAllRating <= 2) {
        ratingLabel = "Low";
      } else if (int_CurrentOverAllRating > 2 && int_CurrentOverAllRating <= 3.5) {
        ratingLabel = "Moderate";
      } else if (int_CurrentOverAllRating > 3.5 && int_CurrentOverAllRating <= 5) {
        ratingLabel = "High";
      }

      if (ratingLabel && currentOverAllRating) {
        console.log(currentOverAllRating,'checkratinglabel')
        setPotential(ratingLabel);
        updateMutation({
          "current_rating.overall_rating": currentOverAllRating,
          "appraisal.potential": ratingLabel, // Adding the label to the update
          id: employee_id
        });
      }
    }
  }, [currentOverAllRating])

  useEffect(() => {
    const Overall_rating = employeeData?.data?.current_rating?.overall_rating;
    const RatingScale = ratingData?.data?.map((j: any) => ({
      rating: j?.rating,
      definition: j?.definition,
      rating_titile: j?.rating_scale,
    }));

    const filterRatingScale = (item: any, minRating: any, maxRating: any) => {
      return (item?.rating >= minRating && item?.rating <= maxRating) && (Overall_rating >= minRating && Overall_rating <= maxRating);
    }

    const FilteredRatingScale = RatingScale?.filter((item: any) => {
      if (filterRatingScale(item, 1, 1.99) ||
        filterRatingScale(item, 2, 2.49) ||
        filterRatingScale(item, 2.5, 2.99) ||
        filterRatingScale(item, 3, 3.49) ||
        filterRatingScale(item, 3.5, 3.99) ||
        filterRatingScale(item, 4, 4.49) ||
        filterRatingScale(item, 4.5, 4.99) ||
        filterRatingScale(item, 5.0, 5.0)) {
        return {
          ratingScale: item?.rating_titile,
          definition: item?.definition,
          // rating: item?.rating,
        };
      }
    });

    if (FilteredRatingScale && FilteredRatingScale.length > 0) {
      setRatingDefinition(FilteredRatingScale[0]?.definition);
      setRatingScaleDefinition(FilteredRatingScale[0]?.rating_titile);
    } else {
      // Handle the case when FilteredRatingScale is empty
      // setratingdefenition("No rating definition found");
    }
  }, [ratingData, employeeData]);

  useEffect(() => {
    if (feedbackQuestionnaireData && employeeData) {
      setFeedbackQuestionnaire(() => {
        const filter = employeeData.data.appraisal_template?.feedback_questionnaire.map((i: any) => {
          return employeeData.data.appraisal?.feedback_questions.filter((j: any) => {
            if (j.name) {
              return i?.name?.name === j?.name?.name;
            }
          });
        });

        if (filter?.flat().length > 0) {
          return filter
            .map((j: any) =>
              j.map((a: any) => {
                console.log(
                  {
                    name: a?.name?.name,
                    value: a?.value,
                  },
                  "setOverallFeed"
                );
                return {
                  name: a?.name,
                  value: a?.value,
                };
              })
            )
            .flat();
        } else {
          return employeeData?.data?.appraisal_template?.feedback_questionnaire?.map((i: any) => {
            return {
              name: i?.name,
              value: "",
            };
          });
        }
      });
      setAppraiserFeedbackQuestionnaire(employeeData?.data?.appraisal?.feedback_questions);
      setFeedbackQuestionnaireeData(employeeData?.data?.appraisal?.feedback_questions)
    }
  }, [feedbackQuestionnaireData, employeeData]);

  useEffect(() => {
    setAppraiserFeedbackQuestionnaire(pre(feedbackQuestionnaire));
  }, [feedbackQuestionnaire]);

  const pre = (arr: any) => {
    return arr?.map((i: any) => {
      return {
        name: i?.name?._id,
        value: i?.value,
      };
    });
  };


  // function start : to save data for further recommendation
  const checkboxHandler = (checkbox: any[]) => {
    if (checkbox) {
      const res = checkbox.filter((i: any) => {
        return i.isChecked === true;
      });
      return res;
    }
  };

  const checkboxIdHandler = (res: any[]) => {
    console.log(res, '``res````')
    if (res) {
      const check = res.map((i: any) => {
        return {
          name: i._id,
          isChecked: i.isChecked,
        };
      });
      return check;
    }
  };
  // function end : to save data for further recommendation


  return {
    employee_id,
    employeeData,
    message,
    setMessage,
    tabValue,
    setTabValue,
    moveTab,
    setMoveTab,
    navPrompt,
    setNavPrompt,
    disableTextAfterSubmission,
    setDisableTextAfterSubmission,
    objectiveDescription,
    setObjectiveDescription,
    Colors,
    colorarray,
    PAStatus,
    setPAStatus,
    employeeDetails,
    overallRating,
    ratingData,
    ratingDefinition,
    ratingScaleDefinition,
    appraiserFeedbackQuestionnaire,
    setAppraiserFeedbackQuestionnaire,
    feedbackQuestionnaireeData,
    feedbackQuestionnaire,
    setFeedbackQuestionnaire,
    feedbackQuestionnaireData,
    appraiserAreaOfImprovement,
    setAppraiserAreaOfImprovement,
    employeeAreaOfImprovement,
    appraisalTemplateTrainingRecommendation,
    appraiserTrainingRecommendation,
    employeeTrainingRecommendation,
    setAppraiserTrainingRecommendation,
    appraiserFurtherRecommendation,
    setAppraiserFurtherRecommendation,
    appraiserFurtherRecommendationOthers,
    setAppraiserFurtherRecommendationOthers,
    appraiserFurtherRecommendationOthersCheckbox,
    setAppraiserFurtherRecommendationOthersCheckbox,
    appraiserMessageForEmployee,
    setAppraiserMessageForEmployee,
    showAppraiserMessageForEmployee,
    appraiserPerformanceGoals,
    setAppraiserPerformanceGoals,
    potential,
    setPotential,
    showPotentialEdit,
    nineBoxData,
    goalsCategoryData,
    visiblityColumnData,
    setVisiblityColumnData,
    attachedFiles,
    setAttachedFiles,
    appraiserRatingPreviousSubmission,
    checkboxHandler,
    checkboxIdHandler,
    disableButtons,
    setDisableButtons,
    refetchEmployeeData,
    employeeDataIsFetching,
    employeeDataIsLoading,
    updateMutation,
    employeeDataIsUpdating,
    showReviewerRejectionReason,
    reviewerRejectionReason,
    showreviewerComments,
    reviewerComments,
    normalizerComments,
    showNormalizerComments,
    shownormalizerRejectionReason,
    normalizerRejectionReason,
    employeeComments,
    showEmployeeComments,
    oneToOneMeetingDate,
    showoneToOneMeetingDate,
    showemployeeTrainingRecommendation,
    showAppraiserTrainingRecommendation,
    showAppraiserAreaOfImprovement,
    showEmployeeAreaOfImprovement,
    performanceGoalsFieldValues,
    performanceGoalsFieldValuesEmployee,
    showPerformanceGoalsFieldValues,
    showPerformanceGoalsFieldValuesEmployee,
    isloadingCreateEmployee,
    isAppraiserAcceptsEmployee,
    TrainingRecommendation,
    furtherRecommendation,
    areaOfImprovement,
    createMutation,
    appraiserAgreedRatingWithEmployee,
    setAppraiserAgreedRatingWithEmployee
  };
}

// Provider component to provide the context to its children
export default function ProvidedAppraiserContextProvider({ children }: { children: React.ReactNode }) {
  const data = useAppraiserDataContext();

  return (
    <AppraiserContext.Provider value={data}>
      {children}
    </AppraiserContext.Provider>
  );
}
