import { TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'

const Text = styled("div")({

    "& .MuiInputBase-root": {

        backgroundColor: "#f8f8f8",
    },
    "& .MuiTextField-root": {
        width: "100%",
        // backgroundColor: "#f8f8f8",
        borderRadius: "5px",
    },
    "& .MuiInputBase-input": {
        color: "#333333",
        fontSize: "14px",
        fontFamily: "Arial",
        textTransform: "none",
        textAlign: "left"

    },
});

const EmployeeCommentsEdit = (props: any) => {
    const { disableTextAfterSubmission, moveTab, setMoveTab, navPrompt, setNavPrompt, employeeComments, setEmployeeComments, employeeDataIsFetching , employeeDataIsLoading, showIfEmployeeLanding,showIfEmployeeReject } = props;

    return (
        <>
            <div style={{ marginBottom: "20px",
            //  marginLeft: "36px"
              }}>
                <Typography
                    style={{
                        fontSize: "16px",
                        color: "#717171",
                        marginBottom: "10px",
                        fontFamily: "arial"
                    }}
                >
                    <b>Employee Comments</b>
                   {showIfEmployeeReject && ( <span style={{
                        fontSize: "22px",
                    }}>*</span>)}
                </Typography>
                <div 
                // style={{ marginRight: "65px" }}
                >
                    <Text>
                        <TextField
                            // sx={{maxWidth:"95%"}}
                            // fullWidth
                            autoComplete="off"
                            InputProps={{
                                disableUnderline: true,
                                readOnly: disableTextAfterSubmission
                            }}
                            disabled = {employeeDataIsFetching || employeeDataIsLoading}
                            size="small"
                            multiline
                            placeholder="Add"
                            value={employeeComments}
                            inputProps={{ maxLength: 512 }}
                            onChange={(e) => {
                                setEmployeeComments(e.target.value);
                                setMoveTab(true);
                                setNavPrompt(true);
                            }}
                        />
                    </Text>
                </div>
            </div>
        </>
    )
}

export default EmployeeCommentsEdit