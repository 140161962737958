import React from "react";
import Box from "@mui/material/Box";
import StatusBar from "../../../../components/Dashboard_Reusable_Component/StatusBar";
import EmployeeRejection from "../../../../components/Dashboard_Reusable_Component/EmployeeRejection";
import { useEffect } from 'react';

function PerformanceRatingandEmpRejection(props: any) {
  const { setstatusSort, completedCount, inprogressCount, inMediaton, range1, range2, range3, range4, StatusValues,TotalEmp,CompletedEmp,setValue,handleOnClickCard,
    navigationFrom,checklengthinRanges,checkAppraisalStatusC,checkAppraisalStatusIR,checkAppraisalStatusIM} = props;
  const [linkofAppraiser, setlinkofAppraiser] = React.useState<string>('');
  console.log(navigationFrom,"navigationFrom")
  useEffect(() => {
    if(navigationFrom === "Appraiser" || navigationFrom === "Reviewer" || navigationFrom === "Normalizer"){
      setlinkofAppraiser("/filteredtable")
    // }else if(navigationFrom === "Reviewer"){
    //   setlinkofAppraiser("/filteredtableReviewer")
    // }else if(navigationFrom === "Normalizer"){
    //   setlinkofAppraiser("/filteredtableNormalizer")
    }
  }, [navigationFrom])

  return (
    <>
      <div style={{ marginTop: "20px", position: "relative" }}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "#fff",
            marginLeft: "25px",
            marginRight: "25px",
          }}
        >
        <StatusBar StatusValues={StatusValues} setValue={setValue} handleOnClickCard={handleOnClickCard}/>
          <div style={{ marginTop: "30px",paddingBottom:"30px", }} >
            <EmployeeRejection
            navigationFrom={navigationFrom}
            checkAppraisalStatusCompleted={checkAppraisalStatusC("completed")}
            checkAppraisalStatusInmediation={checkAppraisalStatusIM("rejected")}
            checkAppraisalStatusRenormalization={checkAppraisalStatusIR("rejected")}
              checklengthinRangesL={checklengthinRanges(1,2.49)}
            checklengthinRangesA={checklengthinRanges(2.5,2.99)}
            checklengthinRangesG={checklengthinRanges(3,3.99)}
            checklengthinRangesH={checklengthinRanges(4,5)}
              completedCount={completedCount}
              inprogressCount={inprogressCount}
              inMediaton={inMediaton}
              range1={range1}
              range2={range2}
              range3={range3}
              range4={range4}
              TotalEmp={TotalEmp}
              CompletedEmp={CompletedEmp}
              linkofAppraiser={linkofAppraiser}
            />
          </div>
        </Box>
      </div>
    </>
  );
}

export default PerformanceRatingandEmpRejection;
