import React from 'react'
import AppraiserActionforEmployee from '../../../components/appraisal/appraiser/employee_appraiser_submission/employee_appraiser_submission'

const Employee_Appraiser_Submission = () => {
  return (
    <>
    <AppraiserActionforEmployee/>
    </>
  )
}

export default Employee_Appraiser_Submission