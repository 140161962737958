import React from 'react'
import PerformanceGoalsView from '../performanceGoals/PerformanceGoalsView'
import { PERFORMANCE_GOAL_VALUE } from '../../../constants/DialogContents/DialogContents'

function PerformanceGoal(props:any) {
    const {showIfAppraiser,showIfEmployee,
        performanceGoalsFieldValues,
        performanceGoalsFieldValuesEmployee,
        showPerformanceGoalsFieldValuesEmployee,
        showPerformanceGoalsFieldValues} =props
      return (
        <>
        {!showPerformanceGoalsFieldValues && !showPerformanceGoalsFieldValuesEmployee &&
          (<div style={{
            marginTop:"20px",
            fontSize: "14px",
            fontFamily: "arial",
            fontWeight: "400",
            textTransform: "none",
          }}>{PERFORMANCE_GOAL_VALUE}</div>)
        }
        {showPerformanceGoalsFieldValues && showIfAppraiser &&
        <PerformanceGoalsView 
        showIfAppraiser={showIfAppraiser}
        performanceGoals={performanceGoalsFieldValues}/>
        }
        {showPerformanceGoalsFieldValuesEmployee &&showIfEmployee &&
        <PerformanceGoalsView 
        showIfEmployee={showIfEmployee}
        performanceGoals={performanceGoalsFieldValuesEmployee}/>
        }
      </>
      )
    }
export default PerformanceGoal