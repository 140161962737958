import React from 'react'
import EmployeeReject from '../../../components/appraisalNew/employee/employeeRejects/EmployeeReject'
import ProvidedEmployeeContextProvider from '../../../context/appraisal/EmployeeContext'

const EmployeeRejectPage = () => {
  return (
    <ProvidedEmployeeContextProvider>
   <>
   <EmployeeReject/>
   </>
   </ProvidedEmployeeContextProvider>
  )
}

export default EmployeeRejectPage