import React, { useEffect } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { TextField,} from "@mui/material";
import { useAppraisalContext } from "../../../context/appraiserOverviewContext";
import { useAppraiserContext } from "../../../context/appraisal/AppraiserContext";


const Overallfeedback = styled("div")({
    color: "#717171",
    fontSize: "16px",
    fontFamily: "arial",
    marginBottom: "10px",
});

const FeedbackQuestionnaireTextfield = styled(TextField)({
    width: "100%",
    backgroundColor: "#f8f8f8",
    borderRadius: "5px",
    "& .MuiInputBase-input": {
        color: "#333333",
        fontSize: "14px",
        fontFamily: "Arial",
        fontWeight: "400",
        textTransform: "none",
        // padding: "4px",
        textAlign: "left"
    },
});

const FeedbackQuestionnaireEdit = (props: any) => {
    //   @ts-ignore
    const { feedbackQuestionnaire, setFeedbackQuestionnaire,appraiserFeedbackQuestionnaire,setAppraiserFeedbackQuestionnaire,employeeDataIsFetching,employeeDataIsLoading, isLoading, feedbackLoading, disableTextAfterSubmission, setDisableTextAfterSubmission, moveTab, setMoveTab, navPrompt, setNavPrompt  } = props;


    // useEffect(() => {
    //     setAppraiserFeedbackQuestionnaire(pre(feedbackQuestionnaire));
    //   }, [feedbackQuestionnaire]);
    
    //   const pre = (arr: any) => {
    //     return arr?.map((i: any) => {
    //       return {
    //         name: i?.name?._id,
    //         value: i?.value,
    //       };
    //     });
    //   }; 
   
    

    //   if (isLoading) {
    //     return <div>Loading...</div>;
    //   }
    //   if (feedbackLoading) {
    //     return <div>Loading...</div>;
    //   }
    
      if (appraiserFeedbackQuestionnaire?.length === 0) {
        return <div>Loading...</div>;
      }

    return (
        <div>
            {feedbackQuestionnaire &&
            feedbackQuestionnaire?.map((j: any, mapIndex: any) => {
                    return (
                        <>
                         <div style={{ marginBottom: "20px" }}>
                            <Overallfeedback> <b>{j?.name?.name}</b>
                            {/* <span style={{
                                fontSize: "22px",
                            }}>*</span> */}
                            </Overallfeedback>

                            <FeedbackQuestionnaireTextfield
                                size="small"
                                placeholder="Add"
                                multiline
                                autoComplete="off"
                                key={j._id}
                                disabled={employeeDataIsFetching || employeeDataIsLoading}
                                value={feedbackQuestionnaire[mapIndex]?.value}
                                onChange={(e) => {
                                    setFeedbackQuestionnaire(
                                        feedbackQuestionnaire?.map((i: any, ix: any) =>
                                            ix === mapIndex
                                                ? {
                                                    ...i,
                                                    value: e.target.value,
                                                }
                                                : i
                                        )
                                    );
                                    setNavPrompt(true);
                                    setMoveTab(true);
                                }}
                                InputProps={{ readOnly: disableTextAfterSubmission }}
                            />
                            </div>
                        </>
                    );
                })}</div>
    )
}

export default FeedbackQuestionnaireEdit