import React from 'react'
import Header from '../../header/Header'
import JoiningDate from '../../Department/joiningDate';
import Department from '../../Department/Department';
import { Box, Stack, TableContainer, Popover, Table, TableCell, TableHead, TableBody, TableRow, IconButton, Typography } from '@mui/material';
import OverallFeedbackTab from '../OverallFeedback';
import PerformanceGoals from '../PerformanceGoals';
import PotentialView from '../../potential/potentialView';
import AppraiserFooterButton from '../AppraiserFooterButton';
import ReviewerFooterButton from '../ReviewerFooterButton';
import NormalizerFooterButton from '../NormalizerFooterButton';
import RatingsTab from '../../ratings/RatingsTab';
import { PDFExport } from "@progress/kendo-react-pdf";
import PerformanceAppraisalPeriod from '../../performanceAppraisalPeriod/PerformanceAppraisalPeriod';
import { Scrollbar } from "react-scrollbars-custom";
import { styled } from "@mui/material/styles";
import Infoicon from "../../../../assets/Images/Infoicon.svg";
import { useLoggedInUser } from '../../../../hooks/useLoggedInUser';
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";

import BreadCrumbs from '../../breadcrumbs/Breadcrumbs';
import ReNormalizerFooterButton from '../ReNormalizerFooterbutton';
import PerformanceAppraisalHistory from '../PerformanceAppraisalHistory';
const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
});
const ViewPA = (props: any) => {
  const { data: user } = useLoggedInUser();

  const { employeeDetails, ratingDefinition, ratingScaleDefinition, PAStatus, overallRating, employeeData, ratingData, appraiserFeedbackQuestionnaire, nineBoxData,
    showNormalizerMeetingAttachment, employeeDataIsLoading, showAppraiserAreaOfImprovement, appraiserAreaOfImprovement, employeeAreaOfImprovement, employeeTrainingRecommendation,
    appraiserTrainingRecommendation, showAppraiserTrainingRecommendation, appraisalTemplateTrainingRecommendation, employeeCommentsoneToOneMeetingDate, employeeComments, oneToOneMeetingDate,
    appraiserMessageForEmployee, showAppraiserMessageForEmployee, showNormalizerComments, showReviewerRejectionReason, reviewerComments, showreviewerComments, Colors,
    reviewerRejectionReason, employee_id, objectiveDescription, setObjectiveDescription, objectiveTitleData, colorarray,
    normalizerComments, potential, handleHide, pdfExportComponent, positionHide, employeecode, showEmployeeComments, performanceGoalsFieldValuesEmployee,
    performanceGoalsFieldValues, showEmployeeAreaOfImprovement, showPerformanceGoalsFieldValues, showoneToOneMeetingDate,
    showPerformanceGoalsFieldValuesEmployee, showEmployeeTrainingRecommendation, showPotential, showReNormalizerFooterButton,
    normalizerRejectionReason, shownormalizerRejectionReason, normalizerMeetingAttachment, furtherRecommendation, furtherRecommendationOthers, furtherRecommendationOthersCheckbox,
    shownormalizerdiscussedrating, employeeChecked, reviewerChecked, appraiserChecked, normalizerMeetingNotes, shownormalizermeetingnotes,
    showNormalizerFooterButton, showReviewerFooterButton, showAppraiserFooterButton, visiblityColumnData, getviewpalocation, appraiserAgreedRatingWithEmployee } = props
  let showIfAppraiser = true;
  let showDownload = true
  let showIfViewPa = true
  let showIfEmployee = true
  const CustomScrollbar = Scrollbar as any;
  const location: any = useLocation();
  console.log(showAppraiserFooterButton, "showAppraiserFooterButton")
  console.log(employeeData?.data, user?.employee_code, location?.state, "employeeData")
  const PAADMIN=location?.state?.Role
  console.log(PAADMIN,"PAADMIN")
  // Popovers
  const [anchorRatingInfo, setAnchorRatingInfo] = React.useState<HTMLButtonElement | null>(null);
  const openRatingInfo = Boolean(anchorRatingInfo);
  const idRatingInfo = openRatingInfo ? "simple-popover" : undefined;

  const handleClickOpenRatingInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorRatingInfo(event.currentTarget);
  };

  const handleCloseRatingInfo = () => {
    setAnchorRatingInfo(null);
  };
  console.log(employeeData, user, "user")
  // console.log(employeeData?.data?.appraiser_code ,employeeData?.data?.reviewer_code,employeeData?.data?.normalizer_code, user?.employee_code,"employeecode")
  return (
    <>
      {(getviewpalocation !== "value") &&
        <BreadCrumbs
        PAADMIN={PAADMIN}
          loggedinuser={user}
          showIfViewPa={showIfViewPa}
          employeeData={employeeData} />
      }
      <div
        style={{
          backgroundColor: "#F1F1F1",
          height: "auto",
          minHeight: "100px",
          overflow: "hidden",
        }}>
        <PDFExport
          paperSize="A4"
          scale={0.40}
          keepTogether="p"
          forcePageBreak=".page-break" ref={pdfExportComponent}
          fileName={`PA_${employeecode}.pdf`}>
          <Box
            style={{ padding: "35px", marginRight: "25px", marginLeft: "25px", background: "#fff", marginBottom: "25px" }}
          >
            <Box
              style={{
                // padding: "35px",
                paddingBottom: "35px"
              }}
            >
              <Header
                employee_id={employee_id}
                showDownload={showDownload}
                showIfViewPa={showIfViewPa}
                positionHide={positionHide}
                pdfExportComponent={pdfExportComponent}
                handleHide={handleHide}
                ratingData={ratingData}
                employeeDetails={employeeDetails}
                overallRating={overallRating}
                PAStatus={PAStatus}
                employeeData={employeeData}
                ratingDefinition={ratingDefinition}
                ratingScaleDefinition={ratingScaleDefinition}
              />
              <Box
                sx={{
                  marginTop: "20px",
                  // marginLeft: "60px",
                  // marginRight: "60px",
                  backgroundColor: "#d3d3d366",
                  borderRadius: "5px",
                  padding: "10px"
                }}>
                <JoiningDate employeeDetails={employeeDetails} />
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
              >
                <div style={{
                  marginTop: "10px",
                  marginBottom: "10px"
                }}>
                  <PerformanceAppraisalPeriod
                    employeeDetails={employeeDetails} />
                </div>
                <div style={{
                  marginTop: "10px",
                  marginBottom: "10px"
                }}>
                  <PotentialView
                   employeeDetails={employeeDetails}
                    potential={potential}
                    employeeData={employeeData}
                    showPotentialView={showPotential}
                  />
                </div>
              </Stack>
              <Stack direction="row" alignItems="center" >
                <Typography style={{ color: "#3e8cb5", fontSize: "16px", fontFamily: "Arial", }}>
                  <IconButton
                    sx={{ "&.MuiTab-iconWrapper": { marginLeft: "0px" } }}
                    onClick={handleClickOpenRatingInfo}
                  >
                    <img
                      style={{ width: "12px" }}
                      src={Infoicon}
                      alt="icon"
                    />
                  </IconButton> <b>Ratings</b>
                </Typography>
              </Stack>
              <RatingsTab
                employee_id={employee_id}
                employeeData={employeeData}
                objectiveDescription={objectiveDescription}
                setObjectiveDescription={setObjectiveDescription}
                objectiveTitleData={objectiveTitleData}
                Colors={Colors}
                colorarray={colorarray}
                visiblityColumnData={visiblityColumnData}
              />
              <div className="page-break">
                <Typography
                  style={{ color: "#3e8cb5", fontSize: "16px", fontFamily: "Arial", paddingTop: "20px", marginBottom: "20px" }}
                >
                  <b>Overall Feedback</b>
                </Typography>
                <OverallFeedbackTab
                  showNormalizerMeetingAttachment={showNormalizerMeetingAttachment}
                  appraiserMessageForEmployee={appraiserMessageForEmployee}
                  showPerformanceGoalsFieldValuesEmployee={showPerformanceGoalsFieldValuesEmployee}
                  showPerformanceGoalsFieldValues={showPerformanceGoalsFieldValues}
                  performanceGoalsFieldValues={performanceGoalsFieldValues}
                  performanceGoalsFieldValuesEmployee={performanceGoalsFieldValuesEmployee}
                  showEmployeeComments={showEmployeeComments}
                  employeeComments={employeeComments}
                  employeeData={employeeData}
                  appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire}
                  showAppraiserAreaOfImprovement={showAppraiserAreaOfImprovement}
                  appraiserAreaOfImprovement={appraiserAreaOfImprovement}
                  employeeAreaOfImprovement={employeeAreaOfImprovement}
                  showAppraiserTrainingRecommendation={showAppraiserTrainingRecommendation}
                  appraiserTrainingRecommendation={appraiserTrainingRecommendation}
                  employeeTrainingRecommendation={employeeTrainingRecommendation}
                  appraisalTemplateTrainingRecommendation={appraisalTemplateTrainingRecommendation}
                  oneToOneMeetingDate={oneToOneMeetingDate}
                  showAppraiserMessageForEmployee={showAppraiserMessageForEmployee}
                  normalizerComments={normalizerComments}
                  showNormalizerComments={showNormalizerComments}
                  reviewerRejectionReason={reviewerRejectionReason}
                  showReviewerRejectionReason={showReviewerRejectionReason}
                  showEmployeeAreaOfImprovement={showEmployeeAreaOfImprovement}
                  showEmployeeTrainingRecommendation={showEmployeeTrainingRecommendation}
                  showreviewerComments={showreviewerComments}
                  reviewerComments={reviewerComments}
                  shownormalizerRejectionReason={shownormalizerRejectionReason}
                  normalizerRejectionReason={normalizerRejectionReason}
                  appraiserChecked={appraiserChecked}
                  reviewerChecked={reviewerChecked}
                  employeeChecked={employeeChecked}
                  shownormalizerdiscussedrating={shownormalizerdiscussedrating}
                  normalizerMeetingNotes={normalizerMeetingNotes}
                  shownormalizermeetingnotes={shownormalizermeetingnotes}
                  normalizerMeetingAttachment={normalizerMeetingAttachment}
                  furtherRecommendation={furtherRecommendation}
                  furtherRecommendationOthers={furtherRecommendationOthers}
                  furtherRecommendationOthersCheckbox={furtherRecommendationOthersCheckbox}
                  showoneToOneMeetingDate={showoneToOneMeetingDate}
                  appraiserAgreedRatingWithEmployee={appraiserAgreedRatingWithEmployee}
                />
              </div>
              <div className="page-break">
                <PerformanceGoals
                  showPerformanceGoalsFieldValues={showPerformanceGoalsFieldValues}
                  showIfAppraiser={showIfAppraiser}
                  performanceGoalsFieldValues={performanceGoalsFieldValues}
                  performanceGoalsFieldValuesEmployee={performanceGoalsFieldValuesEmployee}
                  showPerformanceGoalsFieldValuesEmployee={showPerformanceGoalsFieldValuesEmployee}
                  showIfEmployee={showIfEmployee} />
              </div>
              
              <div>
                <PerformanceAppraisalHistory employeeData={employeeData} />
              </div>
              {positionHide === true &&
                <>

                  {(showAppraiserFooterButton && user?.employee_code == employeeData?.data?.appraiser_code && user?.current_role == "Appraiser" && getviewpalocation !== "value") &&
                    <AppraiserFooterButton employeeData={employeeData} />
                  }
                  {(showReviewerFooterButton && user?.current_role == "Reviewer" && getviewpalocation !== "value") &&
                    <ReviewerFooterButton employeeData={employeeData} />
                  }
                  {(showNormalizerFooterButton && user?.current_role == "Normalizer" && getviewpalocation !== "value") &&
                    <NormalizerFooterButton employeeData={employeeData} />
                  }
                  {(showReNormalizerFooterButton && user?.current_role == "Normalizer" && getviewpalocation !== "value") &&
                    <ReNormalizerFooterButton employeeData={employeeData} />
                  }
                </>
              }
              <Popover
                id={idRatingInfo}
                open={openRatingInfo}
                anchorEl={anchorRatingInfo}
                onClose={handleCloseRatingInfo}
                PaperProps={{
                  style: { width: "260px", marginTop: "55px" },
                }}
              >
                <TableContainer sx={{ overflowX: "hidden" }}>
                  <Scroll>
                    <CustomScrollbar style={{ width: "100%", height: "225px" }}>
                      <Table
                        sx={{
                          minWidth: 200,
                          '& th, & td': {
                            borderBottom: 'none', // Remove the bottom border for th and td
                          },
                        }}
                        size="small"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow sx={{ bgcolor: "#eaeced" }}>
                            <TableCell
                              align="center"
                              sx={{
                                maxWidth: "30%",
                                fontFamily: "Arial",
                                color: "#3E8CB5",
                                fontSize: "14px",
                                fontWeight: "600", // Adjust the maxWidth as needed
                              }}
                            >
                              Ratings
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                maxWidth: "70%",
                                fontFamily: "Arial",
                                color: "#3E8CB5",
                                fontSize: "14px",
                                fontWeight: "600",  // Adjust the maxWidth as needed
                              }}
                            >
                              Rating Title
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Map through your data and render rows */}
                          {ratingData &&
                            ratingData.data
                              .slice()
                              .sort((a: any, b: any) => a.rating - b.rating)
                              .map((row: any, index: any) => (
                                <TableRow key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      borderColor: "lightgrey",
                                    },
                                  }}>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: "14px",
                                      color: "#333333",
                                      fontFamily: "Arial",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {row.rating}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      fontSize: "14px",
                                      color: "#333333",
                                      fontFamily: "Arial",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {row.rating_scale}
                                  </TableCell>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </CustomScrollbar>
                  </Scroll>
                </TableContainer>
              </Popover>
            </Box>
          </Box>
        </PDFExport>
      </div>
    </>
  )
}
export default ViewPA
