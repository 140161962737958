import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { Breadcrumbs} from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import TopperformerchildExpandView from "../../../../components/Dashboard_Reusable_Component/TopperformerChildExpandview";


export default function TopPerformersparentforOverallDashboard(props: any) {
  const { FinalData, setTopperformersExpandActive,topPerformerEmployeeso,LOCATION_FROM } = props;
  const navigate = useNavigate();
  console.log(topPerformerEmployeeso, "topPerformerEmployees()?");
  const handleOnclickBack = () => {
    if (LOCATION_FROM == "NormalizerOverallDashboard") {
      navigate("/normalizer")
    } else if (LOCATION_FROM == "PAADMINOverallDashboard") {
      navigate("/PA_Dashboard")
    } else if (LOCATION_FROM == "ReviewerOverallDashboard") {
      navigate("/reviewer")
    }
    setTopperformersExpandActive(false)
  }
  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        minHeight="50px"
        marginLeft="25px"
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            style={{
              fontSize: "18px",
              color: "#3e8cb5",
              fontFamily: "Arial",
              cursor: "pointer"
            }}
            color="inherit"
            onClick={() => handleOnclickBack()}
          >
            Overall Dashboard
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              color: "#333333",
              fontFamily: "Arial",
            }}
            color="text.primary"
          >
            Top Performers
          </Typography>
        </Breadcrumbs>
      </Stack>
      <TopperformerchildExpandView
        topPerformerEmployees={topPerformerEmployeeso} />
    </>
  );
}
