import * as React from "react";
import { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Container, Box, FormControlLabel, MenuItem, IconButton, FormControl, Dialog, DialogContent, DialogContentText, DialogActions, Alert, Menu, ListItemText, ListItemIcon, Tooltip, Popover } from "@mui/material";
// import NBoxGrids from "./chartscomponents/nboxgrids";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Button, Drawer, TextField } from "@mui/material";
import Expand from "../../../../../assets/Images/Expand.svg";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import InputAdornment from "@mui/material/InputAdornment";
import Searchlensreview from "../../../../../components/reviewerMain/Reviewericons/Searchlensreview.svg";
// import Searchlensreview from "../../../../reviewerMain/Reviewericons/Searchlensreview.svg";
// import Newexcel from "../../../../reviewerMain/Reviewericons/Newexcel.svg";
import Newexcel from "../../../../../components/reviewerMain/Reviewericons/Newexcel.svg";

import Updown from "../../Dashboard/Reviewericons/Updown.svg";
import Leftarrow from "../../../../../assets/Images/Leftarrow.svg";
// import Eye from "../Reviewericons/Eyeicon.svg";
import Eye from "../../../../../components/reviewerMain/Reviewericons/Eyeicon.svg";

import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import * as XLSX from "xlsx";

import {
  useGetEmployeeByFilterQuery,
  useAcceptNormalizerMutation,
  useRejectNormalizerAppraisalEmployeeMutation,
  useGetEmployeeByManagerCodeQuery,
  useGetEmployeeByStatusQuery,
  useGetEmployeeQuery,
  useGetReviewerEmployeeByFilterQuery,
  useGetActiveCalenderQuery
} from "../../../../../service";
import {
  APPRAISAL_NOT_STARTED,
  APPRAISER,
  EMPLOYEE_APPRAISER_SUBMISSION,
  CREATE_APPRAISAL,
  EMPLOYEE_DOWNLOAD,
  VIEW_PA,
  NORMALIZER_ACTION,
  NORMALIZER_ACCEPTANCE,
  NORMALIZER_APPROVE,
  NORMALIZER_PAGE,
  VIEW_PAST_PA,
  NORMALIZER_REJECT,
  NORMALIZER_REJECTION,
  NORMALIZER_ACTION_SCREEN,
} from "../../../../../constants/routes/Routing";
import { useLoggedInUser } from "../../../../../hooks/useLoggedInUser";
import TablePagination from "@mui/material/TablePagination";
import { Link, useNavigate, useLocation } from "react-router-dom";
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import { Scrollbar } from "react-scrollbars-custom";
import AlertDialogSuccess from "../../../../../components/UI/DialogSuccess";
import { makeStyles } from '@mui/styles';
import ExportToexcel from "../../../../../components/ExportToExcel/ExporttoExcel";

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    // margin: theme?.spacing(1),
    width: 140,
    fontSize: "14px",
    color: "#333333",
    fontFamily: "Arial",

  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
    fontSize: "13px !important",
    fontFamily: "Arial",
    color: "#333333",

    // ['@media (max-width:768px)']: {
    //   color:"red !important",
    // }
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    fontSize: "12px !important",
    color: "#333333",
    fontFamily: "Arial",
  },

}));
const Searchfeild = styled("div")({
  // position: "absolute",
  // marginLeft: "78%",
  // //marginRight: '8px',
  // marginTop: "10px",
  "& .MuiOutlinedInput-root": {
    height: "28px",
    width: "144px",
    borderRadius: "15px",
    background: "#F2F6F8",
    // border:'2px solid white'
  },
  "& .MuiInputLabel-root": {
    fontSize: "13px",
    color: "#306D8F",
    marginTop: "-10px",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "13px",
    color: "#306D8F",
  },
});
const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
  "& .ScrollbarsCustom-Track": {

    //width:"10px !important"
  },
});
const TableHeadings = styled("div")({
  // "& .MuiTableRow-head ": {
  //   background: "#eaeced",
  // },
  // "& .MuiTableCell-head": {
  //   color: "#004C75",
  //   padding: "0px",
  //   height: "30px",
  //   borderBottom: "2px solid white",
  // },
  "& .MuiTableCell-root": {
    padding: "8px 15px 8px 15px",

  },
});
const userData = [
  { id: 0, reason: "rating", isChecked: false, value: "rating" },
  {
    id: 1,
    reason: "Feedback questionnaire",
    isChecked: false,
    value: "Feedback_questionnaire",
  },
  {
    id: 2,
    reason: "area of improvement",
    isChecked: false,
    value: "area_of_improvement",
  },
  {
    id: 3,
    reason: "Training recommendation(s)",
    isChecked: false,
    value: "training_recommendation(s)",
  },
  {
    id: 4,
    reason: "Other recommendation(s)",
    isChecked: false,
    value: "other_recommendation(s)",
  },
];
export default function ExpandedTeamTableofReviewer(props: any) {
  const [bulkupdate, setbulkupdate] = React.useState<HTMLElement | null>(null);
  const { expandEmployees, setbreadCrumbName, tabLocation,valueOfCard } = props;
  // const location: any = useLocation();
  // console.log(location?.state)
  const classes = useStyles();
  const CustomScrollbar = Scrollbar as any;
  const { data: activecalendardata } = useGetActiveCalenderQuery('')
  let CalendarName = activecalendardata?.data[0]?.name
  const { data: user } = useLoggedInUser();
  const SELECT_FOR_DASHBOARD = `employee_code,legal_full_name,appraiser_name,reviewer_name,normalizer_name,appraisal.potential,
  position_long_description,grade,service_reference_date,overall_rating,isSupervisor,email,function,appraisal.appraiser_rating,reviewer.reviewer_rating,normalizer.normalizer_rating,employee.employee_rating,employee.employee_status,appraisal.status,appraisal.appraiser_status,reviewer.reviewer_status,normalizer.normalizer_status,reviewer.rejection_count,appraisal.objective_description,
  reviewerIsDisabled,,normalizerIsChecked,normalizerIsDisabled,first_name,service_reference_date,position_code,division,section,sub section,manager_code,manager_name,manager_position,work_location,grade_set,job_code,job_level,appraiser_code,reviewer_code,normalizer_code,job_title,reviewer_previous_submission?.reviewer_rating`
  const [appCalId, setappCalId] = React.useState<any>(`636cb1a7960b1548b80ff785`);
  React.useEffect(() => {
    if (user?.calendar?._id !== undefined) {
      setappCalId(user?.calendar?._id)
    }
    if (tabLocation === 0) {
      // valueOfCard("")
      setbreadCrumbName("All")
    } else if (tabLocation === 1) {
      // valueOfCard("")
      setbreadCrumbName("My pending actions")
    } else if (tabLocation === 2) {
      // valueOfCard("")
      setbreadCrumbName("Not started")
    } else if (tabLocation === 3) {
      // valueOfCard("")
      setbreadCrumbName("In progress")
    } else if (tabLocation === 4) {
      // valueOfCard("")
      setbreadCrumbName("Employees rejection")
    } else if (tabLocation === 5) {
      // valueOfCard("")
      setbreadCrumbName("Completed")
    }
  }, [user])


  const [acceptNormalizer] = useAcceptNormalizerMutation();
  const [rejectNormalizer] = useRejectNormalizerAppraisalEmployeeMutation();
  const listInnerRef = useRef<any>();
  const navigate = useNavigate();
  const [users, setUsers] = useState<any>([]);
  const [enteredName, setenteredName] = useState("");
  //const [approved, setApproved] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tablecount, settablecount] = React.useState<any>(0);
  const [pageNumber, setPageNumber] = useState(0)

  //Data for populating the table
    console.log(expandEmployees,users,"users")


    useEffect(() => {
      const expandEmployeesData = expandEmployees;
      setUsers(expandEmployeesData)
      // setUsers(expandEmployeesData ? [...expandEmployeesData] : []);
    }, [expandEmployees]);
    
  // useEffect(() => {
  //   const expandEmployeesData = expandEmployees;
  //   setUsers(expandEmployeesData ? [...expandEmployeesData] : []);
  // }, [expandEmployees]);

  const getPAStatus = (j: any) => {
    if (
      j.appraisal?.objective_description &&
      j.appraisal?.objective_description.length === 0
    )
      return " PA Not Started";
    else if (j?.appraisal?.status == "completed") return "-";
    else if (j?.appraisal?.appraiser_status === "pending")
      return " Pending with Appraiser";
    else if (j?.appraisal?.status === "normalized")
      return " Pending with Employee";
    else if (j?.appraisal?.appraiser_status?.includes("draft")) return " Pending with Appraiser (Draft)";
    else if (
      j?.appraisal?.appraiser_status === "submitted" &&
      (j?.reviewer?.reviewer_status == "pending" ||
        j?.reviewer?.reviewer_status == "draft")
    )
      return " Pending with Reviewer";
    else if (
      j?.appraisal?.appraiser_status === "accepted" &&
      (j?.reviewer?.reviewer_status == "pending" || j?.reviewer?.reviewer_status == "appraiser-accepted" ||
        j?.reviewer?.reviewer_status == "draft")
    )
      return " Pending with Reviewer";
    else if (j?.reviewer?.reviewer_status == "appraiser-rejected") {
      return " Pending with Reviewer";
    } else if (
      // j.appraisal.appraiser_status === "submited" &&
      j?.reviewer?.reviewer_status == "accepted" &&
      (j?.normalizer?.normalizer_status == "pending" || j?.normalizer?.normalizer_status == "draft")
    )
      return " Pending with HR Normalizer";
    else if (j?.appraisal?.appraiser_status === "reviewer-rejected")
      return " Pending with Appraiser (Reviewer Rejection)";
    else if (j?.appraisal?.appraiser_status === "normalizer-rejected")
      return " Pending with Appraiser (Normalizer Rejection)";
    else if (j?.appraisal?.appraiser_status === "appraiser-rejected-employee")
      return " Pending with Employee";
    else if (j?.appraisal?.appraiser_status === "employee-rejected")
      return " Pending with Appraiser (Employee Rejection)";
    else if (
      j?.reviewer.reviewer_status == "accepted" &&
      j?.normalizer.normalizer_status == "employee-rejected"
    )
      return " Pending with HR Normalizer";
    else if (j?.normalizer?.normalizer_status == "accepted")
      return " Pending with Employee";
    else if (j?.normalizer?.normalizer_status === "draft")
      return " Pending with HR Normalizer";
    else if (
      j?.reviewer?.reviewer_status == "rejected" &&
      j?.reviewer?.rejection_count == 3 &&
      (j?.normalizer?.normalizer_status == "pending" || j?.normalizer?.normalizer_status == "draft")
    )
      return " Pending with HR Normalizer";
    else if (j?.normalizer?.normalizer_status == "employee-rejected")
      return " Pending with HR Normalizer";
    // 5
    else if (j?.appraisal?.appraiser_status === "employee-rejected")
      return " Pending with Appraiser";
    // 2
    else if (
      j?.reviewer?.reviewer_status == "normalizer-rejected" &&
      j?.reviewer?.reviewer_status == "appraiser-rejected"
    )
      return "Pending with Reviewer";
    // 1
    else if (
      j?.appraisal?.appraiser_status == "normalizer-rejected" &&
      j?.appraisal?.appraiser_status == "accepted"
    )
      return "Pending with Reviewer";
    else return "-";
  };

  const getStatus = (status: any) => {
    if (status == "in-progress") {
      return "In progress"
    } else if (status == "not-started") {
      return "Not started"
    } else if (status == "normalized") {
      return "Normalized"
    } else if (status == "rejected") {
      return "Rejected "
    } else if (status == "completed") {
      return "Completed "
    }
  }



  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    // setPageNumber(pageNumber + 1)
  };
// 
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // Menuprops added for filterdropdown
  const ITEM_HEIGHT = 28;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // maxWidth: 400,
      },
    },
  };
// scroll added for employeetable
  const onScroll = () => {
    console.log('running scroll')
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setPageNumber(pageNumber + 1);
      }
    }
  };
  //sorting

  //populating filter dropdown
  const [serviceRef, setserviceRef] = React.useState<any>([]);
  const [gradesArray, setgradesArray] = React.useState<any>([]);
  const [positionArray, setpositionArray] = React.useState<any>([]);
  const [positioncodeArray, setpositioncodeArray] = React.useState<any>([]);
  const [divisionArray, setdivisionArray] = React.useState<any>([]);
  const [sectionArray, setsectionArray] = React.useState<any>([]);
  const [subSectionArray, setsubSectionArray] = React.useState<any>([]);
  const [managerCodeArray, setmanagerCodeArray] = React.useState<any>([]);
  const [managerNameArray, setmanagerNameArray] = React.useState<any>([]);
  const [managerPositionArray, setmanagerPositionArray] = React.useState<any>(
    []
  );
  const [workLocationArray, setworkLocationArray] = React.useState<any>([]);
  const [gradeSetArray, setgradeSetArray] = React.useState<any>([]);
  const [jobCodeArray, setjobCodeArray] = React.useState<any>([]);
  const [jobTitleArray, setjobTitleArray] = React.useState<any>([]);
  const [jobLevelArray, setjobLevelArray] = React.useState<any>([]);

  const [excel, setExcel] = React.useState<any>([]);
  const [page1, setPage1] = React.useState<any>([]);
  //For multislect options
// Dropdown filter for section and position,grade
  const [sectionsFilter, setsectionsFilter] = React.useState<string[]>([]);
  const isAllsectionFilter =
    sectionArray?.length > 0 && sectionsFilter?.length === sectionArray?.length;
  const handleChangeSelectsections = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      console.log((sectionsFilter?.length === sectionsFilter?.length ? [] : "select all"), "newwwwww")
      setsectionsFilter(sectionsFilter?.length === sectionArray?.length ? [] : sectionArray);
      return;
    }
    setsectionsFilter(value);
    setPage(0);
  };
  const [GradesFilter, setGradesFilter] = React.useState<string[]>([]);
  const isAllGradesFilter =
    gradesArray?.length > 0 && GradesFilter?.length === gradesArray?.length;
  const newsection = gradesArray?.length == GradesFilter?.length
  const handleChangeSelectGrades = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      console.log((GradesFilter?.length === gradesArray?.length ? [] : "select all"), "newwwwww")
      setGradesFilter(GradesFilter?.length === gradesArray?.length ? [] : gradesArray);
      return;
    }
    setGradesFilter(value);
    setPage(0);
  };
  const [positionsFilter, setpositionsFilter] = React.useState<string[]>([]);
  const isAllpositionsFilter =
    positionArray?.length > 0 && positionsFilter?.length === positionArray?.length;
  const handleChangeSelectPositions = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      console.log((positionsFilter?.length === positionArray?.length ? [] : "select all"), "newwwwww")

      setpositionsFilter(positionsFilter?.length === positionArray?.length ? [] : positionArray);
      return;
    }
    setpositionsFilter(value);
    setPage(0);
  };
  //For multislect options
// tablecount used for Pagination
  useEffect(() => {
    let New = users ? users
      ?.filter((item1: any) => {
        if (sectionsFilter.includes("None") || sectionsFilter.length === 0) {
          return item1;
        } else {
          return !!sectionsFilter?.find((item2: any) => item1?.section === item2)
        }
      })
      .filter((item1: any) => {
        if (positionsFilter.includes("None") || positionsFilter.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      .filter((item1: any) => {
        if (GradesFilter.includes("None") || GradesFilter.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      ?.filter((j: any) => {
        if (enteredName === "") {
          return j;
        } else if (
          (j?.employee_code !== undefined &&
            j?.employee_code
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.legal_full_name !== undefined &&
            j?.legal_full_name
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.grade !== undefined &&
            j?.grade
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.position_long_description !== undefined &&
            j?.position_long_description
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.first_name !== undefined &&
            j?.first_name
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.service_reference_date !== undefined &&
            j?.service_reference_date
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.position_code !== undefined &&
            j?.position_code
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.division !== undefined &&
            j?.division
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.section !== undefined &&
            j?.section
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              ))  ||
              (j?.appraisal?.pa_status !== undefined &&
                j?.appraisal?.pa_status
                  ?.toLocaleLowerCase()
                  ?.includes(
                    enteredName?.toLocaleLowerCase()
                  )) ||
          (j?.appraisal?.status !== undefined &&
            j?.appraisal?.status
              ?.toLocaleLowerCase()
              ?.includes(enteredName?.toLocaleLowerCase()))||
          (j?.ManagerCode !== undefined &&
            j?.ManagerCode
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.ManagerName !== undefined &&
            j?.ManagerName
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.ManagerPosition !== undefined &&
            j?.ManagerPosition
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.WorkLocation !== undefined &&
            j?.WorkLocation
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.grade_set !== undefined &&
            j?.grade_set
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.job_code !== undefined &&
            j?.job_code
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.job_title !== undefined &&
            j?.job_title
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.job_level !== undefined &&
            j?.job_level
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              ))
        ) {
          return j;
        } 
      })  : []
    settablecount(New?.length)
  }, [users, enteredName, positionsFilter, GradesFilter, sectionsFilter]);

  // multiselect for Position and grade
  const [GradeFilter, setGradeFilter] = React.useState<string[]>([]);
  const [positionFilter, setpositionFilter] = React.useState<string[]>([]);
  // multiselect

  React.useEffect(() => {
    const serviceRefDate = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.service_reference_date?.localeCompare(b?.service_reference_date);
      })
      ?.map((i: any) => {
        return i.service_reference_date;
      });
    const serviceRefDateContents = serviceRefDate?.filter(
      (c: any, index: any) => {
        return (
          serviceRefDate?.indexOf(c) === index && c != null && c != undefined
        );
      }
    );
    setserviceRef(serviceRefDateContents);
    let grades = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.grade - b?.grade;
      })
      ?.map((i: any) => {
        return i?.grade;
      });
      if (positionsFilter.length > 0) {
        grades = users
          ?.slice()
          ?.sort(function (a: any, b: any) {
            return a?.grade - b?.grade;
          })
          ?.filter((i: any) => {
            return !!positionsFilter?.find(item2 => i?.position_long_description === item2)
          })
          ?.map((i: any) => {
            return i?.grade;
          });
      }
      else if (enteredName?.length > 0) {
        grades = users
          .slice()
          ?.sort(function (a: any, b: any) {
            return a?.grade?.localeCompare(
              b?.grade
            );
          })
          ?.filter((i: any) => {
            if (enteredName.length > 0) {
              const enteredTerms = enteredName.toLowerCase().split(" ");
              return enteredTerms.every(term =>
                i?.grade
                  ?.toLowerCase()
                  .includes(term)
              ) || enteredTerms.every(term =>
                i?.grade?.toLowerCase().includes(term)
              ) || enteredTerms.every(term =>
                i?.position_long_description?.toLowerCase().includes(term)
              ) || enteredTerms.every(term =>
                i?.section?.toLowerCase().includes(term)
              ) || enteredTerms.every(term =>
                i?.employee_code?.toLowerCase().includes(term)
              ) || enteredTerms.every(term =>
                i?.legal_full_name?.toLowerCase().includes(term)
              ) || enteredTerms.every((term: any) =>
                i?.appraisal?.pa_status?.toLowerCase().includes(term)
              )
            } else {
              return true;
            }
          })
          ?.map((i: any) => {
            return i?.grade;
          });
      }
    const gradeContents = grades?.filter((c: any, index: any) => {
      return grades?.indexOf(c) === index && c != null && c != undefined;
    });
    setgradesArray(gradeContents);
    console.log(gradeContents, "contents");
    let position = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.position_long_description?.localeCompare(b?.position_long_description);
      })
      ?.map((i: any) => {
        return i?.position_long_description;
      });
      if (GradesFilter.length > 0) {
        position = users
          ?.slice()
          ?.sort(function (a: any, b: any) {
            return a?.position_long_description - b?.position_long_description;
          })
          ?.filter((i: any) => {
            return !!GradesFilter?.find(item2 => i?.grade === item2)
          })
          ?.map((i: any) => {
            return i?.position_long_description;
          });
      }
      else if (enteredName?.length > 0) {
        position = users
          .slice()
          ?.sort(function (a: any, b: any) {
            return a?.position_long_description?.localeCompare(
              b?.position_long_description
            );
          })
          ?.filter((i: any) => {
            if (enteredName.length > 0) {
              const enteredTerms = enteredName.toLowerCase().split(" ");
              return enteredTerms.every(term =>
                i?.position_long_description
                  ?.toLowerCase()
                  .includes(term)
              ) || enteredTerms.every(term =>
                i?.grade?.toLowerCase().includes(term)
              ) || enteredTerms.every(term =>
                i?.position_long_description?.toLowerCase().includes(term)
              ) || enteredTerms.every(term =>
                i?.section?.toLowerCase().includes(term)
              ) || enteredTerms.every(term =>
                i?.employee_code?.toLowerCase().includes(term)
              ) || enteredTerms.every(term =>
                i?.legal_full_name?.toLowerCase().includes(term)
              ) || enteredTerms.every((term: any) =>
                i?.appraisal?.pa_status?.toLowerCase().includes(term)
              )
            } else {
              return true;
            }
          })
          ?.map((i: any) => {
            return i?.position_long_description;
          });
      }
    const positionContents = position?.filter((c: any, index: any) => {
      return position?.indexOf(c) === index && c != null && c != undefined;
    });
    setpositionArray(positionContents);
    console.log(positionContents, "contents");

    const positionCode = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.position_code?.localeCompare(b?.position_code);
      })
      .map((i: any) => {
        return i?.position_code;
      });
    const positionCodeContents = positionCode?.filter((c: any, index: any) => {
      return positionCode?.indexOf(c) === index && c != null && c != undefined;
    });
    setpositioncodeArray(positionCodeContents);
    console.log(positionContents, "contents");

    const division = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.division?.localeCompare(b?.division);
      })
      ?.map((i: any) => {
        return i.division;
      });
    const divisionContents = division

      ?.filter((c: any, index: any) => {
        return division?.indexOf(c) === index && c != null && c != undefined;
      });
    setdivisionArray(divisionContents);
    console.log(divisionContents, "contents");

    let section = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.section?.localeCompare(b?.section);
      })
      ?.map((i: any) => {
        return i.section;
      });
      if (GradesFilter.length > 0) {
        section = users
          ?.slice()
          ?.sort(function (a: any, b: any) {
            return a?.section - b?.section;
          })
          ?.filter((i: any) => {
            return !!GradesFilter?.find(item2 => i?.grade === item2)
          })
          ?.map((i: any) => {
            return i?.section;
          });
      } else if (positionsFilter.length > 0) {
        section = users
          ?.slice()
          ?.sort(function (a: any, b: any) {
            return a?.section - b?.section;
          })
          ?.filter((i: any) => {
            return !!positionsFilter?.find(item2 => i?.position_long_description === item2)
          })
          ?.map((i: any) => {
            return i?.section;
          });
      }
      else if (enteredName?.length > 0) {
        section = users
          .slice()
          ?.sort(function (a: any, b: any) {
            return a?.section?.localeCompare(
              b?.section
            );
          })
          ?.filter((i: any) => {
            if (enteredName.length > 0) {
              const enteredTerms = enteredName.toLowerCase().split(" ");
              return enteredTerms.every(term =>
                i?.position_long_description
                  ?.toLowerCase()
                  .includes(term)
              ) || enteredTerms.every(term =>
                i?.grade?.toLowerCase().includes(term)
              ) || enteredTerms.every(term =>
                i?.position_long_description?.toLowerCase().includes(term)
              ) || enteredTerms.every(term =>
                i?.section?.toLowerCase().includes(term)
              ) || enteredTerms.every(term =>
                i?.employee_code?.toLowerCase().includes(term)
              ) || enteredTerms.every(term =>
                i?.legal_full_name?.toLowerCase().includes(term)
              ) || enteredTerms.every((term: any) =>
                i?.appraisal?.pa_status?.toLowerCase().includes(term)
              )
            } else {
              return true;
            }
          })
          ?.map((i: any) => {
            return i?.section;
          });
      }
    const sectionContents = section?.filter((c: any, index: any) => {
      return section?.indexOf(c) === index && c != null && c != undefined;
    });
    setsectionArray(sectionContents);
    console.log(sectionContents, "contents");

    const subSection = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.["sub section"]?.localeCompare(b?.["sub section"]);
      })
      ?.map((i: any) => {
        return i["sub section"];
      });
    const subSectionContents = subSection?.filter((c: any, index: any) => {
      return subSection?.indexOf(c) === index && c != null && c != undefined;
    });
    setsubSectionArray(subSectionContents);
    console.log(subSectionContents, "contents");

    const managerCode = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a.manager_code - b.manager_code;
      })
      ?.map((i: any) => {
        return i.manager_code;
      });
    const managerCodeContents = managerCode?.filter((c: any, index: any) => {
      return managerCode?.indexOf(c) === index && c != null && c != undefined;
    });
    setmanagerCodeArray(managerCodeContents);
    console.log(managerCodeContents, "contents");

    const managerName = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.manager_name?.localeCompare(b?.manager_name);
      })
      ?.map((i: any) => {
        return i.manager_name;
      });
    const managerNameContents = managerName?.filter((c: any, index: any) => {
      return managerName?.indexOf(c) === index && c != null && c != undefined;
    });
    setmanagerNameArray(managerNameContents);
    console.log(managerNameContents, "contents");

    const managerPosition = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.manager_position?.localeCompare(b?.manager_position);
      })
      ?.map((i: any) => {
        return i.manager_position;
      });
    const managerPositionContents = managerPosition?.filter(
      (c: any, index: any) => {
        return (
          managerPosition?.indexOf(c) === index && c != null && c != undefined
        );
      }
    );
    setmanagerPositionArray(managerPositionContents);
    console.log(managerPositionContents, "contents");

    const workLocation = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.work_location?.localeCompare(b?.work_location);
      })
      ?.map((i: any) => {
        return i?.work_location;
      });
    const workLocationContents = workLocation?.filter((c: any, index: any) => {
      return workLocation?.indexOf(c) === index && c != null && c != undefined;
    });
    setworkLocationArray(workLocationContents);
    console.log(workLocationContents, "contents");

    const gradeSet = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.grade_set?.localeCompare(b?.grade_set);
      })
      ?.map((i: any) => {
        return i.grade_set;
      });
    const gradeSetContents = gradeSet?.filter((c: any, index: any) => {
      return gradeSet?.indexOf(c) === index && c != null && c != undefined;
    });
    setgradeSetArray(gradeSetContents);
    console.log(workLocationContents, "contents");

    const jobCode = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.job_code?.localeCompare(b?.job_code);
      })
      ?.map((i: any) => {
        return i.job_code;
      });
    const jobCodeContents = jobCode?.filter((c: any, index: any) => {
      return jobCode?.indexOf(c) === index && c != null && c != undefined;
    });
    setjobCodeArray(jobCodeContents);
    console.log(jobCodeContents, "contents");

    const jobTitle = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.job_title?.localeCompare(b?.job_title);
      })
      ?.map((i: any) => {
        return i.job_title;
      });
    const jobTitleContents = jobTitle?.filter((c: any, index: any) => {
      return jobTitle?.indexOf(c) === index && c != null && c != undefined;
    });
    setjobTitleArray(jobTitleContents);
    console.log(jobTitleContents, "contents");

    const jobLevel = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.job_level?.localeCompare(b?.job_level);
      })
      ?.map((i: any) => {
        return i.job_level;
      });
    const jobLevelContents = jobLevel?.filter((c: any, index: any) => {
      return jobLevel?.indexOf(c) === index && c != null && c != undefined;
    });
    setjobLevelArray(jobLevelContents);
    console.log(jobLevelContents, "contents");
  }, [users,GradesFilter,positionsFilter,enteredName]);
  //populating filter dropdown

  //Job level
  //  Filter icon
  const [icon2, setIcon2] = React.useState<any>([]);
  const [icon3, setIcon3] = React.useState<any>([]);
  const [icon4, setIcon4] = React.useState<any>([]);

  useEffect(()=>{
    if(sectionsFilter?.length == 0){
      setIcon4(false)
    }else{
      setIcon4(true)
    }
    if(GradesFilter?.length == 0){
      setIcon3(false) 
    }else{
      setIcon3(true)
    }
    if(positionsFilter?.length == 0){
      setIcon2(false)
    }else{
      setIcon2(true)
    }
  },[sectionsFilter,GradesFilter,positionsFilter])
  //drawer functions
  const [isDrawerOpen, setisDrawerOpen] = React.useState(false);

  // checkbox we used for export to excel drawer
  const [columnHeaders, setcolumnHeaders] = useState<any>({
    Ecode: true,
    Ename: true,
    Eposition: true,
    Firstname: true,
    ESection: true,
    EGrade: true,
    EDivision: true,
    ESubSection: true,
    AppraiserName: true,
    ReviewerName: true,
    NormalizerName: true,
    OverallRating: true,
    employeerating: true,
    appraiserRating: true,
    // reviewerRating: true,
    // normalizerRating: true,
    PreviousRating: true,
    PotentialLevel: true,
    TalentCategory: true,
    WorkLocation: true,
    ManagerCode: true,
    ManagerName: true,
    ManagerPosition: true,
    PendingAction:true,
    // Rating: true,
    // PAStatus: true,
    // pendingAction: true,
    appraiserCode: true,
    reviewerCode: true,
    normalizerCode: true,
    status: true,
    // email: true,
    // rejectionStatus: true,
    ServiceReferenceDate: true,
    Function: true,
    SupervisoryRole: true,
    EmailID: true,
    CalendarName: true,
    SelectAll: true,
    workflowHistory : true
  })
  // checkedcount and totalcount used for select all checkbox in choosefields
  const checkedCount = Object.keys(columnHeaders).reduce((count:any, key:any) => {
    if (key !== 'SelectAll') {
      return count + (columnHeaders[key] ? 1 : 0);
    }
    return count;
  }, 0);

  const totalCount = Object.keys(columnHeaders).length - 1; 
  console.log(checkedCount,totalCount,"totalCount")
  // 
  const [openGrade, setOpenGrade] = React.useState(false);

  // const [heading1, setheading1] = React.useState(true);
  const [headingcalendar, setheadingcalendar] = React.useState(true);
  //---------------------------------new drawer function(need to replace)
  const [ServiceReferenceDateData, setServiceReferenceDateData] = React.useState(true);
  const [FunctionData, setFunctionData] = React.useState(true);
  const [SupervisoryRoleData, setSupervisoryRoleData] = React.useState(true);
  const [EmailIDData, setEmailIDData] = React.useState(true);
  const [rejstatusValue, rejsetstatusValue] = React.useState(true);
  const [firstName, setfirstName] = React.useState(true);
  const [heading1, setheading1] = React.useState(true);
  const [heading2, setheading2] = React.useState(true);
  const [heading3, setheading3] = React.useState(true);
  const [heading4, setheading4] = React.useState(true);
  const [appraiserRating, setAppraiserRating] = React.useState(true);
  const [reviewerRating, setReviewerRating] = React.useState(true);
  const [normalizeRating, setNormalizeRating] = React.useState(true);
  const [employeerating, setemployeerating] = React.useState(true);
  const [headingOfEcode, setheadingOfEcode] = React.useState(true);
  const [headingOfSection, setheadingOfSection] = React.useState(true);
  const [headingOfPAStatus, setheadingOfPAStatus] = React.useState(true);
  const [headingOfDivision, setheadingOfDivision] = React.useState(true);
  const [headingOfSubSection, setheadingOfSubSection] = React.useState(true);
  const [headingOfAppraiserName, setheadingOfAppraiserName] = React.useState(true);
  const [headingOfReviewerName, setheadingOfReviewerName] = React.useState(true);
  const [headingOfNormalizerName, setheadingOfNormalizerName] = React.useState(true);
  const [headingOfOverallRating, setheadingOfOverallRating] = React.useState(true);
  const [headingOfPreviousRating, setheadingOfPreviousRating] = React.useState(true);
  const [headingOfPotentialLevel, setheadingOfPotentialLevel] = React.useState(true);
  const [headingOfTalentCategory, setheadingOfTalentCategory] = React.useState(true);
  const [headingOfWorkLocation, setheadingOfWorkLocation] = React.useState(true);
  const [headingOfManagerCode, setheadingOfManagerCode] = React.useState(true);
  const [headingOfManagerName, setheadingOfManagerName] = React.useState(true);
  const [headingOfManagerPosition, setheadingOfManagerPosition] = React.useState(true);
  const [headingOfPendingAction, setheadingOfPendingAction] = React.useState(true);
  const [appCodes, setappCodes] = React.useState(true);
  const [revCodes, setrevCodes] = React.useState(true);
  const [norCodes, setnorCodes] = React.useState(true);
  const [statusValue, setstatusValue] = React.useState(true);
  const handleStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setstatusValue(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheadingCalendar = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingcalendar(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  }
  const handleSelectAll = (selectAll:any) => {
    const updatedColumnHeaders = { ...columnHeaders };
    // Set all checkbox values to the selectAll value (true or false)
    Object.keys(updatedColumnHeaders).forEach((key) => {
      updatedColumnHeaders[key] = selectAll;
    });
    setcolumnHeaders(updatedColumnHeaders);
  };
  const handleAppCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setappCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleRevCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setrevCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNorCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setnorCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleEmployeeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfEcode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading1(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading2(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading3(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfSection(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleDivision = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfDivision(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSubSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfSubSection(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAppraiserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfAppraiserName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleReviewerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfReviewerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNormalizerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfNormalizerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleOverallRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfOverallRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlePreviousRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPreviousRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlePotentialLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPotentialLevel(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleTalentCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfTalentCategory(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleWorkLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfWorkLocation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerCode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerPosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerPosition(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlePendingAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPendingAction(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleWorkflowHistory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleemployeerating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setemployeerating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAppraiserRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppraiserRating(event.target.checked)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlefirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setfirstName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleServiceReferenceDateData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceReferenceDateData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleFunctionData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFunctionData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSupervisoryRoleData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSupervisoryRoleData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleEmailIDData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailIDData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleReviewerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReviewerRating(event.target.checked)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNormalizerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNormalizeRating(event.target.checked)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  //Button functionalities
  const [message, setMessage] = useState("")
  const [openAlertOk, setOpenAlertOk] = useState<any>(false)
  const [approved, setApproved] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [checkboxUser, setcheckboxUser] = React.useState<any>([]);
  const [zeroselect, setzeroselect] = React.useState(false);
  const [errorApprove, seterrorApprove] = React.useState(false);
  const [zeroselectApprove, setzeroselectApprove] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [opendialog, setOpendialog] = useState(false);
  const [reasonSelection, setreasonSelection] = React.useState(false);
  const [checkedEmployeeid, setcheckedEmployeeid] = React.useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
    setError(false);
    setzeroselectApprove(false);
    seterrorApprove(false);
    setzeroselect(false);
  };
  const handleClose2 = () => {
    setOpen1(false);
  };
  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleAlertClose = () => {
    setOpenAlertOk(false);
    setMessage("")
  }
  const checkboxIdHandler = (res: any) => {
    if (res) {
      const check = res?.map((i: any) => {
        return i?._id;
      });
      console.log(check, "check");
      return check;
    }
  };
  const checkboxHandler = (checkbox: any) => {
    if (checkbox) {
      const res = checkbox?.filter((i: any) => {
        return i?.normalizerIsChecked === true && !i?.normalizerIsDisabled;
      });
      console.log(res, "check22");
      return res;
    }
  };
  const acceptHandler = () => {
    const approvefilter = users?.filter((i: any) => {
      return i?.normalizerIsChecked === true && i?.normalizerIsDisabled === false;
    });
    if (checkboxIdHandler(checkboxHandler(users))?.length > 0) {
      if (approvefilter && approvefilter.length > 0) {
        let currentUser = approvefilter[0];
        if (currentUser?.appraisal?.status == "rejected") {
          navigate(`${NORMALIZER_ACTION_SCREEN}/employee/${checkboxIdHandler(checkboxHandler(users))}`);
        } else {
          navigate(`${NORMALIZER_ACCEPTANCE}/employee/${checkboxIdHandler(checkboxHandler(users))}`)
        }

      }

    } else {
      setOpenAlertOk(true);
      setMessage("Please select atleast one employee.")
    }
  }
  const approvedSuccessfully = () => {
    return setApproved(true);
  };
  const handleReject = () => {
    const checkedfilter = checkboxUser
      .filter((i: any) => {
        console.log(i.isChecked, "hhhhhhhhhhhhhhhhhhhhhhhh");
        return i.isChecked === true || i.isChecked === false;
      })
      .map((j: any) => {
        return {
          value: j.value,
          isChecked: true,
        };
      });
    const rejectfilter = users?.filter((i: any) => {
      console.log(i?.normalizerIsChecked, "filter");
      return i?.normalizerIsChecked === true && i?.normalizerIsDisabled === false;
    });
    const normalizerValue = rejectfilter?.map(
      (j: any) => j?.appraisal?.status
    )[0];
    if (rejectfilter?.length > 1) {
      setError(true);
      setOpenAlert(true);
    } else if (rejectfilter?.length === 0) {
      setzeroselect(true);
      setOpenAlert(true);
    } else {
      let checkedEmp = rejectfilter?.map((j: any) => j._id);
      if (normalizerValue == ("rejected")) {
        let id = rejectfilter[0]._id;
        navigate(`${NORMALIZER_ACTION_SCREEN}/employee/` + id);
      } else {
        rejectNormalizer({
          value: checkedfilter,
          id: checkedEmp[0],
        }).then((data: any) => {
          navigate(`${NORMALIZER_REJECT}/employee/${checkedEmp[0]}`);
        });
      }
    }
  };
  const bulkApproval = () => {
    let myReviewalusers = users?.filter(
      (item: any) => !item?.normalizerIsDisabled && !item.normalizerIsChecked
        && item.appraisal.status !== "rejected" && item.reviewer.reviewer_status !== "rejected"
    );
    let bulkUsers = myReviewalusers.map((user: any) => user?._id)
    const tempUser = users?.map((j: any) => {
      return bulkUsers.includes(j?._id) ? { ...j, normalizerIsChecked: true } : j;
    });
    setUsers(tempUser);
    acceptNormalizer({ id: checkboxIdHandler(myReviewalusers) });
    approvedSuccessfully();
  };
  useEffect(() => {
    setcheckboxUser(userData);
  }, []);
  console.log(checkboxUser, "ccccccccccccc");
  const handlecheckbox = (e: any) => {
    const { name, checked } = e.target;
    console.log(name, " values");
    if (name === "allselect") {
      let userReason = checkboxUser.map((reasons: any) => {
        return { ...reasons, isChecked: checked };
      });
      setcheckboxUser(userReason);
    } else {
      let userReason = checkboxUser.map((reasons: any) =>
        reasons?.reason === name ? { ...reasons, isChecked: checked } : reasons
      );
      setcheckboxUser(userReason);
    }
  };
  const handleDialogClose = () => {
    setOpendialog(false);
    //setIsOpen(false);
  };
  const handleSubmit = (e: any) => {
    const checkedfilter = checkboxUser
      .filter((i: any) => {
        console.log(i?.isChecked, "hhhhhhhhhhhhhhhhhhhhhhhh");
        return i?.isChecked === true || i?.isChecked === false;
      })
      .map((j: any) => {
        return {
          value: j?.value,
          isChecked: j?.isChecked,
        };
      });

    console.log(checkedfilter, "ids");
    if (checkedfilter.filter((k: any) => k?.isChecked === true)?.length === 0) {
      setreasonSelection(true);
    } else if (checkedfilter?.length > 0) {
      return (
        setreasonSelection(false),
        // handleDialogClose(),
        rejectNormalizer({
          value: checkedfilter,
          id: checkedEmployeeid[0],
        }),
        navigate(`${NORMALIZER_REJECT}/employee/${checkedEmployeeid[0]}`)
      );
    }
  }
  const handleDialogNo = () => {
    setOpendialog(false);
    // setIsOpen(true);
  };

  //  tableDataFilterdLength is sed for No data to display meesgae shown whilw empty employees
  const tableDataFilterdLength = users ? users
    ?.filter((item1: any) => {
      if (sectionsFilter.includes("None") || sectionsFilter.length === 0) {
        return item1;
      } else {
        return !!sectionsFilter?.find((item2: any) => item1?.section === item2)
      }
    })
    .filter((item1: any) => {
      if (positionsFilter.includes("None") || positionsFilter.length === 0) {
        return item1;
      } else {
        return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
      }
    })
    .filter((item1: any) => {
      if (GradesFilter.includes("None") || GradesFilter.length === 0) {
        return item1;
      } else {
        return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
      }
    })
    ?.filter((j: any) => {
      if (enteredName === "") {
        return j;
      } else if (
        (j?.employee_code !== undefined &&
          j?.employee_code
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.legal_full_name !== undefined &&
          j?.legal_full_name
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.grade !== undefined &&
          j?.grade
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.position_long_description !== undefined &&
          j?.position_long_description
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.first_name !== undefined &&
          j?.first_name
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.service_reference_date !== undefined &&
          j?.service_reference_date
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.division !== undefined &&
          j?.division
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.section !== undefined &&
          j?.section
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            ))  ||
            (j?.appraisal?.pa_status !== undefined &&
              j?.appraisal?.pa_status
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
        (j?.appraisal?.status !== undefined &&
          j?.appraisal?.status
            ?.toLocaleLowerCase()
            ?.includes(enteredName?.toLocaleLowerCase())) ||
        (j?.WorkLocation !== undefined &&
          j?.WorkLocation
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            ))
      ) {
        return j;
      }
    })  : []
    // function for export to excel 
    const handleExport1 = () => {
      const mapped =users && users
        .filter((item1: any) => {
          if (sectionsFilter.includes("None") || sectionsFilter.length === 0) {
            return item1;
          } else {
            return !!sectionsFilter?.find((item2: any) => item1?.section === item2)
          }
        })
        .filter((item1: any) => {
          if (positionsFilter.includes("None") || positionsFilter.length === 0) {
            return item1;
          } else {
            return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
          }
        })
        .filter((item1: any) => {
          if (GradesFilter.includes("None") || GradesFilter.length === 0) {
            return item1;
          } else {
            return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
          }
        })
        ?.filter((j: any) => {
          if (enteredName === "") {
            return j;
          } else if (
            (j?.employee_code !== undefined &&
              j?.employee_code
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.legal_full_name !== undefined &&
              j?.legal_full_name
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.grade !== undefined &&
              j?.grade
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.position_long_description !== undefined &&
              j?.position_long_description
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.first_name !== undefined &&
              j?.first_name
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.service_reference_date !== undefined &&
              j?.service_reference_date
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.position_code !== undefined &&
              j?.position_code
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.division !== undefined &&
              j?.division
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.section !== undefined &&
              j?.section
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                ))  ||
                (j?.appraisal?.pa_status !== undefined &&
                  j?.appraisal?.pa_status
                    ?.toLocaleLowerCase()
                    ?.includes(
                      enteredName?.toLocaleLowerCase()
                    )) ||
            (j?.appraisal?.status !== undefined &&
              j?.appraisal?.status
                ?.toLocaleLowerCase()
                ?.includes(enteredName?.toLocaleLowerCase()))||
            (j?.ManagerCode !== undefined &&
              j?.ManagerCode
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.ManagerName !== undefined &&
              j?.ManagerName
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.ManagerPosition !== undefined &&
              j?.ManagerPosition
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.WorkLocation !== undefined &&
              j?.WorkLocation
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.grade_set !== undefined &&
              j?.grade_set
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.job_code !== undefined &&
              j?.job_code
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.job_title !== undefined &&
              j?.job_title
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.job_level !== undefined &&
              j?.job_level
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                ))
          ) {
            return j;
          }
        })
        ?.map((j: any, emp: any, employee: any) => {
          let inputDate = j?.service_reference_date
          const dateParts = inputDate?.split("-");
          console.log(inputDate, dateParts, "inputDate")
          let date = new Date(inputDate);
          const year = date?.getFullYear();
          const month = date?.toLocaleString("default", { month: "short" });
          const day = date?.getDate();
          const formattedDate = `${day}-${month}-${year}`;
          let exportData: any = {}
          if (columnHeaders["CalendarName"] == true) exportData["Calendar Name"] = CalendarName
          if (columnHeaders["Ecode"] == true) exportData["Ecode"] = j?.employee_code
          if (columnHeaders["Ename"] == true) exportData["Employee Name"] = j?.legal_full_name
          if (columnHeaders["Firstname"] == true) exportData["Known As"] = j?.first_name
          if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date"] = formattedDate
          if (columnHeaders["Eposition"] == true) exportData["Position"] = j?.position_long_description
          if (columnHeaders["EGrade"] == true) exportData["Grade"] = j?.grade
          if (columnHeaders["Function"] == true) exportData["Function"] = j?.function
          if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] =  j?.isSupervisor != true ? "N-SP" : "SP"
          if (columnHeaders["EmailID"] == true) exportData["Email ID"] = j?.email
          // if (columnHeaders["EDivision"] == true) exportData["Division"] = j?.division
          if (columnHeaders["EDivision"] == true) exportData["Company"] = j?.division
          if (columnHeaders["ESection"] == true) exportData["Section"] = j?.section
          if (columnHeaders["ESubSection"] == true) exportData["Sub-section"] = j?.sub_section
          if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = j?.work_location
          if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = j?.appraiser_code
          if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = j?.appraiser_name
          if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = j?.reviewer_code
          if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = j?.reviewer_name
          if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = j?.normalizer_code
          if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = j?.normalizer_name
          if (columnHeaders["employeerating"] == true) exportData["Employee Rating "] = j?.employee?.employee_rating?.toFixed(2)
          if (columnHeaders["appraiserRating"] == true) exportData["Appraiser Rating"] = j?.appraisal?.appraiser_rating?.toFixed(2)
          // if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Rating"] = j?.reviewer?.reviewer_rating?.toFixed(2)
          // if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Rating"] = j?.normalizer?.normalizer_rating?.toFixed(2)
          // if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Status"] = j?.reviewer?.reviewer_rating?.toFixed(2)
          // if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Status"] = j?.normalizer?.normalizer_rating?.toFixed(2)
          if (columnHeaders["OverallRating"] == true) exportData["Overall Rating"] = (j?.current_rating?.overall_rating == 0 || j?.appraisal?.status !== "completed") ? "-" : j?.current_rating?.overall_rating?.toFixed(2)
          if (columnHeaders["PreviousRating"] == true) {exportData["Previous Period Rating"] =  (j && j.previous_rating ? j.previous_rating.toFixed(2) : "-");}
          if (columnHeaders["PotentialLevel"] == true) exportData["Potential Level"] = (j?.normalizer?.normalized_overallRating !== 0 ? j?.appraisal?.potential : "")
          if (columnHeaders["TalentCategory"] == true) exportData["Talent Category"] =  (j?.talent_category !== "" || j?.talent_category !== undefined
            ? j?.talent_category : "-")
          if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = j?.manager_code
          if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = j?.manager_name
          if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = j?.manager_position
          if (columnHeaders["status"] == true) exportData["Status"] = getStatus(j?.appraisal?.status)
            if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] = j.appraisal?.status == "not-started" ? "Pending with Appraiser" :j.appraisal?.status == "completed" ? ("-") : j.appraisal.pa_status
          if (columnHeaders["workflowHistory"] == true) exportData["Workflow History"] = j.workflow_history?.map((item: any) => item.pa_action_by)?.join(';\n');
          return exportData
        });
        const a = [1]
        const Emptymapped = a.map((j: any) => {
          let exportData: any = {}
          if (columnHeaders["CalendarName"] == true) exportData["Calendar Name"] = ""
          if (columnHeaders["Ecode"] == true) exportData["Ecode"] = ""
          if (columnHeaders["Ename"] == true) exportData["Employee Name"] = ""
          if (columnHeaders["Firstname"] == true) exportData["Known As"] = ""
          if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date "] = ""
          if (columnHeaders["Eposition"] == true) exportData["Position"] = ""
          if (columnHeaders["EGrade"] == true) exportData["Grade"] = ""
          if (columnHeaders["Function"] == true) exportData["Function"] = ""
          if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = ""
          if (columnHeaders["EmailID"] == true) exportData["Email ID"] = ""
          // if (columnHeaders["EDivision"] == true) exportData["Division"] = ""
          if (columnHeaders["EDivision"] == true) exportData["Company"] = ""
          if (columnHeaders["ESection"] == true) exportData["Section"] = ""
          if (columnHeaders["ESubSection"] == true) exportData["Sub-section"] = ""
          if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = ""
          if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = ""
          if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = ""
          if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = ""
          if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = ""
          if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = ""
          if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = ""
          if (columnHeaders["employeerating"] == true) exportData["Employee Rating "] = ""
          if (columnHeaders["appraiserRating"] == true) exportData["Appraiser Rating"] = ""
          // if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Rating"] = ""
          // if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Rating"] = ""
          // if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Status"] = ""
          // if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Status"] = ""
          if (columnHeaders["OverallRating"] == true) exportData["Overall Rating"] = ""
          if (columnHeaders["PreviousRating"] == true) exportData["Previous Period Rating"] = ""
          if (columnHeaders["PotentialLevel"] == true) exportData["Potential Level"] = ""
          if (columnHeaders["TalentCategory"] == true) exportData["Talent Category"] = ""
          if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = ""
          if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = ""
          if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = ""
          if (columnHeaders["status"] == true) exportData["Status"] = ""
          if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] =""
          if (columnHeaders["workflowHistory"] == true) exportData["Workflow History"] = ""
          return exportData
        })
      console.log(mapped,"mapped")
      if (mapped.length === 0) {
        // Use Emptymapped when the filtered data is empty
        var wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(Emptymapped);
      } else {
        var wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(mapped);
      }
  
      XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
  
      XLSX.writeFile(wb, "MyExcel.xlsx");
    }
  // function for Export to excel drawer Apply button
  const handleheadingSortAccept = () => {
    setisDrawerOpen(false);
    // while closing the drawer reset the checkbox 
    setcolumnHeaders({
      Ecode: true,
      Ename: true,
      Eposition: true,
      Firstname: true,
      ESection: true,
      EGrade: true,
      EDivision: true,
      ESubSection: true,
      AppraiserName: true,
      ReviewerName: true,
      NormalizerName: true,
      OverallRating: true,
      employeerating: true,
      appraiserRating: true,
      // reviewerRating: true,
      // normalizerRating: true,
      PreviousRating: true,
      PotentialLevel: true,
      TalentCategory: true,
      WorkLocation: true,
      ManagerCode: true,
      ManagerName: true,
      ManagerPosition: true,
      PendingAction:true,
      Rating: true,
      PAStatus: true,
      pendingAction: true,
      appraiserCode: true,
      reviewerCode: true,
      normalizerCode: true,
      status: true,
      email: true,
      rejectionStatus: true,
      ServiceReferenceDate: true,
      Function: true,
      SupervisoryRole: true,
      EmailID: true,
      CalendarName: true,
      SelectAll: true,
    })
    handleExport1();
  };
  // function for Export to excel drawer open 
  const handleExportFunction = () => {
    setisDrawerOpen(true);
  };
  // function for Export to excel close fuction
  const handleCloseGrade = () => {
    // while closing the drawer reset the checkbox 
    setcolumnHeaders({
      Ecode: true,
      Ename: true,
      Eposition: true,
      Firstname: true,
      ESection: true,
      EGrade: true,
      EDivision: true,
      ESubSection: true,
      AppraiserName: true,
      ReviewerName: true,
      NormalizerName: true,
      OverallRating: true,
      employeerating: true,
      appraiserRating: true,
      // reviewerRating: true,
      // normalizerRating: true,
      PreviousRating: true,
      PotentialLevel: true,
      TalentCategory: true,
      WorkLocation: true,
      ManagerCode: true,
      ManagerName: true,
      ManagerPosition: true,
      PendingAction:true,
      Rating: true,
      PAStatus: true,
      pendingAction: true,
      appraiserCode: true,
      reviewerCode: true,
      normalizerCode: true,
      status: true,
      email: true,
      rejectionStatus: true,
      ServiceReferenceDate: true,
      Function: true,
      SupervisoryRole: true,
      EmailID: true,
      CalendarName: true,
      SelectAll: true,
    })
    setisDrawerOpen(false);
  };

  // BULK ACCEPT BUTTON HOVER FUCNTION
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setbulkupdate(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setbulkupdate(null);
  };

  const openBulkHover = Boolean(bulkupdate);
    // function for Searchbar
  const maxLengthForSearch = 30;
  const handleSearchBar = (e: any) => {
    if (e.target.value.length > maxLengthForSearch) {
      e.target.value = e.target.value.slice(0, maxLengthForSearch);
    }
    setenteredName(e.target.value);
    setPage(0)
  }
  //function for pending action column status
  const getpaStatus = (status: any) => {
    if (status == "Completed" || status == "excepted") {
      return "-"
    }else{
      return status
    }
  }
  return (
    <>
      <Box
        style={{
          marginLeft: "25px",
          marginRight: "25px",
          background: "#ffffff",
          padding: "20px",
          height: "auto",
          marginBottom: "25px"
        }}
      >
        <AlertDialogSuccess
          isAlertOpen={openAlertOk}
          handleAlertClose={handleAlertClose}>
          {message}
        </AlertDialogSuccess>
        {approved && (
          <Dialog
            open={open1}
            onClose={handleClose2}
            BackdropProps={{ style: { background: "#333333 !important", opacity: "10%" } }}
            PaperProps={{
              style: {
                boxShadow: "none",
                borderRadius: "6px",
                maxWidth: "0px",
                minWidth: "26%",
                margin: "0px",
                padding: "30px",
              },
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  color: "#333333",
                  fontSize: "14px",
                  fontFamily: "Arial",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  wordBreak: "break-word",
                  alignItems: "center",
                  overflowY: "hidden",
                }}
              >The performance appraisal was accepted successfully.</DialogContentText>
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  color: "#3E8CB5",
                  background: "transparent",
                  height: "35px",
                  width: "70px"
                }}
                variant="outlined"
                onClick={handleClose2}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <Dialog
          open={openAlert}
          onClose={handleCloseAlert}
          BackdropProps={{ style: { background: "#333333 !important", opacity: "10%" } }}
          PaperProps={{
            style: {
              boxShadow: "none",
              borderRadius: "6px",
              maxWidth: "0px",
              minWidth: "26%",
              margin: "0px",
              padding: "30px",
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                color: "#333333",
                fontSize: "14px",
                fontFamily: "Arial",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                wordBreak: "break-word",
                alignItems: "center",
                overflowY: "hidden",
              }}
            >
              {error && (
                <div>
                  Multiple employee cannot be rejected - select one employee.
                </div>
              )}
              {zeroselect && (
                <div>
                  {" "}
                  Please select atleast one employee.
                </div>
              )}
              {errorApprove && (
                <div>
                  Multiple employee cannot be rejected - select one employee.
                </div>
              )}
              {zeroselectApprove && (
                <div>
                  {" "}
                  Please select atleast one employee.
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <div style={{ alignItems: "center" }}>
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  color: "#3E8CB5",
                  background: "transparent",
                  height: "35px",
                  width: "70px"
                }}
                variant="outlined"
                autoFocus
                onClick={handleCloseAlert}
              >
                Ok
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog
          open={open}
          onClose={handleClose}
          BackdropProps={{ style: { background: "#333333 !important", opacity: "10%" } }}
          PaperProps={{
            style: {
              boxShadow: "none",
              borderRadius: "6px",
              maxWidth: "0px",
              minWidth: "26%",
              margin: "0px",
              padding: "30px",
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                color: "#333333",
                fontSize: "14px",
                fontFamily: "Arial",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                wordBreak: "break-word",
                alignItems: "center",
                overflowY: "hidden",
              }}
            >
              Are you sure you want to accept all the pending actions?
            </DialogContentText>
          </DialogContent>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  marginRight: "10px",
                  background: "transparent",
                  height: "35px",
                  width: "70px",
                  color: "#3e8cb5"
                }}
                variant="outlined"
                autoFocus
                // onClick={bulkApproval}

                onClick={() => {
                  bulkApproval();
                  handleClose();
                  handleClickOpen1();
                }}
              >
                Yes
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  // marginRight: "10px",
                  background: "transparent",
                  height: "35px",
                  width: "70px",
                  color: "#3e8cb5"
                }}
                variant="outlined"
                onClick={handleClose}
              >
                No
              </Button>
            </DialogActions>
          </Stack>
        </Dialog>
        <div>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingBottom="10px"
          >
            <Typography
              gutterBottom
              component="div"
              sx={{ fontSize: "20px", fontFamily: "Arial", color: "#333333" }}
            >
            </Typography>
            <div>
              <Stack direction="row" spacing={2} alignItems="center">
                <Popover
                  id="mouse-over-popover"
                  open={openBulkHover}
                  anchorEl={bulkupdate}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                  PaperProps={{
                    style: {
                      // marginTop:"10px",
                      backgroundColor: "#696969",
                      boxShadow: "none",
                      maxWidth: "125px",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    pointerEvents: 'none',
                    "& .MuiPopover-paper": {
                      border: "1px solid #3e8cb5",
                      backgroundColor: "#ffffff",
                      marginTop: "5px",
                    },
                  }}
                >
                  <Typography
                    style={{
                      padding: "3px",
                      fontSize: "9px",
                      color: "#333333",
                      fontFamily: "Arial",
                      textAlign: "justify"
                    }}>Bulk accept is used for the PA rating acceptance without reviewing the PA details.</Typography>
                </Popover>
                <Searchfeild>
                  <div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Search Here..."
                      autoComplete="off"
                      inputProps={{ maxLength: 256 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={Searchlensreview} alt="icon" />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleSearchBar}
                    />
                    <img
                      src={Newexcel}
                      alt="icon"
                      style={{ marginLeft: "15px", marginTop: "5px", cursor: "pointer" }}
                      onClick={handleExportFunction}
                    />
                  </div>
                </Searchfeild>
              </Stack>
            </div>
            <ExportToexcel 
             isDrawerOpen={isDrawerOpen}
             setisDrawerOpen={setisDrawerOpen}
             checkedCount={checkedCount}
             totalCount={totalCount}
             columnHeaders={columnHeaders}
             handleSelectAll={handleSelectAll}
             handleheadingCalendar={handleheadingCalendar}
             handleEmployeeCode={handleEmployeeCode}
             handleheading1={handleheading1}
             handlefirstName={handlefirstName}
             handleServiceReferenceDateData={handleServiceReferenceDateData}
             handleheading2={handleheading2}
             handleheading3={handleheading3}
             handleFunctionData={handleFunctionData}
             handleSupervisoryRoleData={handleSupervisoryRoleData}
             handleEmailIDData={handleEmailIDData}
             handleDivision={handleDivision}
             handleSection={handleSection}
             handleSubSection={handleSubSection}
             handleWorkLocation={handleWorkLocation}
             handleAppCodes={handleAppCodes}
             handleAppraiserName={handleAppraiserName}
             handleRevCodes={handleRevCodes}
             handleReviewerName={handleReviewerName}
             handleNorCodes={handleNorCodes}
             handleNormalizerName={handleNormalizerName}
             handleemployeerating={handleemployeerating}
             handleAppraiserRating={handleAppraiserRating}
             handleReviewerRating={handleReviewerRating}
             handleNormalizerRating={handleNormalizerRating}
             handleOverallRating={handleOverallRating}
             handlePreviousRating={handlePreviousRating}
             handlePotentialLevel={handlePotentialLevel}
             handleTalentCategory={handleTalentCategory}
             handleManagerCode={handleManagerCode}
             handleManagerName={handleManagerName}
             handleManagerPosition={handleManagerPosition}
             handlePendingAction={handlePendingAction}
             handleStatus={handleStatus}
             handleheadingSortAccept={handleheadingSortAccept}
             handleCloseGrade={handleCloseGrade}
             handleWorkflowHistory = {handleWorkflowHistory}/>
          </Stack>
          <TableHeadings>
            <TableContainer>
              <Scroll>
                <Table size="small" aria-label="simple table">
                  <TableHead
                    style={{ position: "sticky", zIndex: "1000", top: "0px" }}
                  ><TableRow sx={{ bgcolor: "#eaeced" }}>
                     <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                          maxWidth: "60px"
                        }}
                      >
                        Ecode
                      </TableCell>
                     <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Employee Name
                      </TableCell>
                     <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                          <Stack direction="row">
                            <span>Position</span>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              size="small"
                              sx={{
                                width: "25px", fontSize: "0rem",
                                "& .MuiSvgIcon-root": {
                                  color: "#3e8cb5 !important"
                                },
                              }}
                              disableUnderline
                              variant="standard"
                              MenuProps={MenuProps}
                              multiple
                              value={positionsFilter}
                              onChange={handleChangeSelectPositions}
                              renderValue={(selected) => selected.join(', ')}
                            >
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                  padding: "0px",
                                }}
                                key="all"
                                value="all"
                                classes={{
                                  root: isAllpositionsFilter ? classes.selectedAll : "",
                                }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    sx={{ "& .MuiSvgIcon-root": { fontSize: "14px !important" } }}
                                    size="small"
                                    style={{ padding: "3px", paddingLeft: "14px", height: "0px" }} classes={{
                                      indeterminate: classes.indeterminateColor,
                                    }}
                                    checked={isAllpositionsFilter}
                                    indeterminate={
                                      positionsFilter?.length > 0 &&
                                      positionsFilter?.length < positionArray?.length
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "14px",
                                      fontFamily: "Arial",
                                      color: "#333333",
                                      paddingRight: "10px"
                                    },
                                  }}
                                  classes={{ primary: classes.selectAllText }}
                                  primary="Select All"
                                />
                              </MenuItem>
                              {positionArray?.map((option: any) => (
                                <MenuItem
                                  style={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                    padding: "0px",
                                  }}
                                  key={option}
                                  value={option}
                                >
                                <ListItemIcon>
                                    <Checkbox
                                      sx={{ "& .MuiSvgIcon-root": { fontSize: "14px !important" } }}
                                      size="small"
                                      style={{ padding: "3px", paddingLeft: "14px", height: "0px" }}
                                      checked={positionsFilter.indexOf(option) > -1}
                                    />
                                  </ListItemIcon>
                                  <ListItemText sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "13px",
                                      fontFamily: "Arial",
                                      color: "#333333",
                                      paddingRight: "10px"
                                    },
                                  }} primary={option} />
                                </MenuItem>
                              ))}
                            </Select>
                            {icon2 && (
                              <FilterAltTwoToneIcon />)}
                          </Stack>
                        </FormControl>
                      </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600",
                            maxWidth: "60px"
                          }}
                        ><FormControl sx={{ m: 0, width: 80, height: "0" }}>
                            <Stack direction="row">
                              <span>Grade</span>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                size="small"
                                sx={{
                                  width: "25px", fontSize: "0rem",

                                  "& .MuiSvgIcon-root": {
                                    color: "#3e8cb5 !important"
                                  },
                                }}
                                disableUnderline
                                variant="standard"
                                MenuProps={MenuProps}
                                multiple
                                value={GradesFilter}
                                onChange={handleChangeSelectGrades}
                                renderValue={(selected) => selected.join(', ')}
                              >
                                <MenuItem
                                  style={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                    padding: "0px",
                                  }}
                                  key="all"
                                  value="all"
                                  classes={{
                                    root: isAllGradesFilter ? classes.selectedAll : "",
                                  }}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      sx={{ "& .MuiSvgIcon-root": { fontSize: "14px !important" } }}
                                      size="small"
                                      style={{ padding: "3px", paddingLeft: "14px", height: "0px" }}
                                      classes={{
                                        indeterminate: classes.indeterminateColor,
                                      }}
                                      checked={isAllGradesFilter}
                                      indeterminate={
                                        GradesFilter?.length > 0 &&
                                        GradesFilter?.length < gradesArray?.length
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    sx={{
                                      "& .MuiTypography-root": {
                                        fontSize: "14px",
                                        fontFamily: "Arial",
                                        color: "#333333",
                                        paddingRight: "10px"
                                      },
                                    }}
                                    classes={{ primary: classes.selectAllText }}
                                    primary="Select All"
                                  />
                                </MenuItem>
                                {gradesArray?.map((option: any) => (
                                  <MenuItem
                                    style={{
                                      fontSize: "14px",
                                      color: "#333333",
                                      fontFamily: "Arial",
                                      padding: "0px",
                                    }}
                                    key={option}
                                    value={option}
                                  >
                                  <ListItemIcon>
                                      <Checkbox
                                        sx={{ "& .MuiSvgIcon-root": { fontSize: "14px !important" } }}
                                        size="small"
                                        style={{ padding: "3px", paddingLeft: "14px", height: "0px" }}
                                        checked={GradesFilter.indexOf(option) > -1}
                                      />
                                    </ListItemIcon>
                                    <ListItemText sx={{
                                      "& .MuiTypography-root": {
                                        fontSize: "13px",
                                        fontFamily: "Arial",
                                        color: "#333333",
                                        paddingRight: "10px"
                                      },
                                    }} primary={option} />
                                  </MenuItem>
                                ))}
                              </Select>
                              {icon3 && (
                                <FilterAltTwoToneIcon />)}
                            </Stack>
                          </FormControl>
                        </TableCell>
                     <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                          <Stack direction="row">
                            <span>Section</span>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              size="small"
                              sx={{
                                width: "25px", fontSize: "0rem",
                                "& .MuiSvgIcon-root": {
                                  color: "#3e8cb5 !important"
                                },
                              }}
                              disableUnderline
                              variant="standard"
                              MenuProps={MenuProps}
                              multiple
                              value={sectionsFilter}
                              onChange={handleChangeSelectsections}
                              renderValue={(selected) => selected.join(', ')}
                            >
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                  padding: "0px",
                                }}
                                key="all"
                                value="all"
                                classes={{
                                  root: isAllsectionFilter ? classes.selectedAll : "",
                                }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    sx={{ "& .MuiSvgIcon-root": { fontSize: "14px !important" } }}
                                    size="small"
                                    style={{ padding: "3px", paddingLeft: "14px", height: "0px" }}
                                    classes={{
                                      indeterminate: classes.indeterminateColor,
                                    }}
                                    checked={isAllsectionFilter}
                                    indeterminate={
                                      sectionsFilter?.length > 0 &&
                                      sectionsFilter?.length < sectionArray?.length
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "14px",
                                      fontFamily: "Arial",
                                      color: "#333333",
                                      paddingRight: "10px"
                                    },
                                  }}
                                  classes={{ primary: classes.selectAllText }}
                                  primary="Select All"
                                />
                              </MenuItem>
                              {sectionArray?.map((option: any) => (
                                <MenuItem
                                  style={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                    padding: "0px",
                                  }}
                                  key={option}
                                  value={option}
                                >
                                <ListItemIcon>
                                    <Checkbox
                                      size="small"
                                      style={{ padding: "3px", paddingLeft: "14px", height: "0px" }} checked={sectionsFilter.indexOf(option) > -1}
                                      sx={{ "& .MuiSvgIcon-root": { fontSize: "14px !important" } }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "13px",
                                      fontFamily: "Arial",
                                      color: "#333333",
                                      paddingRight: "10px"
                                    },
                                  }} primary={option} />
                                </MenuItem>

                              ))}
                            </Select>
                            {icon4 && <FilterAltTwoToneIcon />}
                          </Stack>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center" width="6%">
                        <div
                          style={{
                            color: "#3e8cb5",
                            fontSize: "14px",
                            fontWeight: "600",
                            fontFamily: "Arial",
                            border: "none",
                            background: "none",
                            maxWidth: "80px",
                          }}
                        >
                          Status
                        </div>
                      </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          Pending Action
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          Appraiser Rating
                        </TableCell>
                        {/* <TableCell
                          align="center"
                          sx={{
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          Reviewer Status
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          HR Normalizer Status
                        </TableCell> */}
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Employee Rating
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Overall Rating
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {tableDataFilterdLength?.length > 0 ? (
                    <TableBody
                      ref={listInnerRef}
                      onScroll={onScroll}
                    >
                      {users
                        ?.sort(
                          (a: any, b: any) =>
                            b?.current_rating?.overall_rating - a?.current_rating?.overall_rating // Sort by pa_rating in descending order
                        )
                        .filter((item1: any) => {
                          if (sectionsFilter.includes("None") || sectionsFilter.length === 0) {
                            return item1;
                          } else {
                            return !!sectionsFilter?.find((item2: any) => item1?.section === item2)
                          }
                        })
                        .filter((item1: any) => {
                          if (positionsFilter.includes("None") || positionsFilter.length === 0) {
                            return item1;
                          } else {
                            return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
                          }
                        })
                        .filter((item1: any) => {
                          if (GradesFilter.includes("None") || GradesFilter.length === 0) {
                            return item1;
                          } else {
                            return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
                          }
                        })
                        ?.filter((j: any) => {
                          if (enteredName === "") {
                            return j;
                          } else if (
                            (j?.employee_code !== undefined &&
                              j?.employee_code
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.legal_full_name !== undefined &&
                              j?.legal_full_name
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.grade !== undefined &&
                              j?.grade
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.position_long_description !== undefined &&
                              j?.position_long_description
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.first_name !== undefined &&
                              j?.first_name
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.service_reference_date !== undefined &&
                              j?.service_reference_date
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.division !== undefined &&
                              j?.division
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.section !== undefined &&
                              j?.section
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.appraisal?.pa_status !== undefined &&
                              j?.appraisal?.pa_status
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.appraisal?.status !== undefined &&
                              j?.appraisal?.status
                                ?.toLocaleLowerCase()
                                ?.includes(enteredName?.toLocaleLowerCase()))
                          ) {
                            return j;
                          }
                        })
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )?.map((j: any) => {
                          return (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  borderColor: "lightgrey",
                                },
                                whiteSpace: "nowrap"
                              }}
                            >
                             <TableCell
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                                align="center"
                              >{j.employee_code}
                              </TableCell>
                             <TableCell
                                align="justify"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {j.legal_full_name}
                              </TableCell>
                             <TableCell
                                align="justify"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {j.position_long_description}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                  paddingRight: "38px"
                                }}
                              >
                                {j.grade}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                }}
                              >
                                {j?.section}
                              </TableCell>
                              <TableCell
                                align="center">
                                {getStatus(j?.appraisal?.status)}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {getpaStatus(j.appraisal.pa_status)}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  wordBreak: "break-word",
                                  /******************Appraiser rating should be red if appraiser rejected : should be blue only if there
                           * is change in the rating that is ,if it is different from the normalized rating else it should be black*******************/
                                  color:
                                    j.appraisal.appraiser_PA_rejected == true ? "red" :
                                      (j.appraisal.status == "rejected" && j.appraisal.appraiser_PA_accepted == true &&
                                        (j.appraisal.appraiser_rating !== j.normalizer?.normalized_overallRating)) ? "#1976d2" : "#333333"
                                }}
                              >
                                {j.appraisal?.appraiser_rating == 0 ? (
                                  <span> - </span>
                                ) : (
                                  j.appraisal?.appraiser_rating?.toFixed(2)
                                )}
                              </TableCell>
                             {/* <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  wordBreak: "break-word",
                                  color:
                                    (j?.reviewer?.reviewer_PA_rejected === true) ? "red" :
                                      (j.appraisal.status == "rejected" && j?.reviewer?.reviewer_PA_accepted == true &&
                                        (j.reviewer.reviewer_rating !== j.normalizer?.normalized_overallRating)) ? "#1976d2" : "#333333"
                                }}
                              >
                                {
                                  (j.reviewer.reviewer_rating == 0) ? (
                                    <span> - </span>
                                  ) : (
                                    j.reviewer.reviewer_rating?.toFixed(2)
                                  )}
                              </TableCell> */}
                              {/* <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  wordBreak: "break-word",
                                  color:
                                    (j?.normalizer?.normalizer_PA_rejected === true) ? "red" :
                                      (j.appraisal.status == "completed" && j?.normalizer.normalizer_status == "re-normalized" &&
                                        (j?.normalizer?.normalizer_rating !== j.normalizer?.normalized_overallRating)) ? "#1976d2" : "#333333"
                                }}
                              >
                                {(
                                  j?.normalizer?.normalizer_rating == 0) ? (
                                  <span> - </span>
                                ) : (
                                  j?.normalizer?.normalizer_rating?.toFixed(2)
                                )}
                              </TableCell> */}
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: (j?.appraisal?.status == "rejected" || j.employee.employee_status === "rejected") ? "red" : "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {
                                  // j.reviewerIsDisabled &&
                                  (j?.employee?.employee_rating == 0) ? (
                                    <span> - </span>
                                  ) : (
                                    j?.employee?.employee_rating?.toFixed(2)
                                  )}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {/******************** If overall rating is changed with respect to the normalized rating then 
                      * rating will be in blue else black***************************/}
                                {(j?.appraisal?.status === "completed" && j?.employee?.employee_status === "rejected" &&
                                  (j?.normalizer?.normalizer_rating !== j.normalizer?.normalized_overallRating)) ? (
                                  <span style={{ color: "#1976d2" }}>{j?.current_rating?.overall_rating?.toFixed(2)}</span>
                                ) : (j?.appraisal?.status === "completed") ? (<span>{j?.current_rating?.overall_rating?.toFixed(2)}</span>) :
                                  "-"
                                }
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={9}
                          align="left"
                          style={{ fontWeight: '500', border: "none", color: "#808080", fontSize: "18px", fontFamily: "arial", paddingTop: "10px" }}
                        >
                          No data to display
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </Scroll>
            </TableContainer>
          </TableHeadings>
          {tableDataFilterdLength?.length > 0 && <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={tablecount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
        </div>
        <Dialog
          fullWidth
          maxWidth="md"
          open={opendialog}
          BackdropProps={{ style: { background: "#333333 !important", opacity: "10%" } }}
          PaperProps={{
            style: {
              boxShadow: "none",
              borderRadius: "6px",
              maxWidth: "0px",
              minWidth: "26%",
              margin: "0px",
              padding: "30px",
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {reasonSelection && (
            <Alert severity="error">
              Please select atleast one employee.
            </Alert>
          )}
          <DialogContent style={{ marginTop: "30px" }}>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                color: "#333333",
                fontSize: "14px",
                fontFamily: "Arial",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                wordBreak: "break-word",
                alignItems: "center",
                overflowY: "hidden",
              }}
            >
              Please select rejection reason(s)
              <div style={{ textAlign: "center", marginLeft: "37%" }}>
                <Stack sx={{ textAlign: "left", marginTop: "15px" }} spacing={1}>
                  <div>
                    <input
                      type="checkbox"
                      name="allselect"
                      onChange={handlecheckbox}
                      checked={
                        checkboxUser.filter(
                          (reasons: any) => reasons?.isChecked !== true
                        )?.length < 1
                      }
                    />
                    <label style={{ color: "#1976d2" }}>Select all</label>
                  </div>
                  {checkboxUser.map((reasons: any) => (
                    <div>
                      <input
                        type="checkbox"
                        name={reasons.reason}
                        checked={reasons?.isChecked || false}
                        onChange={handlecheckbox}
                      />
                      <label>{reasons.reason}</label>
                    </div>
                  ))}
                </Stack>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              paddingBottom: "15px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                textTransform: "none",
                backgroundColor: "#004D77",
                fontSize: "15px",
                color: "#FFFFFF",
                fontFamily: "regular",
                borderRadius: "5px",
              }}
              onClick={(e) => {
                handleSubmit(e);
              }}
              autoFocus
            >
              Submit
            </Button>
            <Button
              style={{
                width: "92px",
                height: "35px",
                textTransform: "none",
                fontSize: "15px",
                fontFamily: "regular",
                color: "#1976d2",
                opacity: "80%",
                padding: "4px 22px",
                border: "1px solid #1976d2",
                borderRadius: "5px",
              }}
              onClick={handleDialogNo}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
