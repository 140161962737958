import React from 'react'
import EmployeeLanding from '../../../components/appraisalNew/employee/employeeLanding/EmployeeLanding'
import ProvidedEmployeeContextProvider from '../../../context/appraisal/EmployeeContext'


const EmployeeLandingPage = () => {
  return (
    <ProvidedEmployeeContextProvider>
      <>
        <EmployeeLanding/>
      </>
    </ProvidedEmployeeContextProvider>
  )
}

export default EmployeeLandingPage