import React, { useEffect, useState } from 'react'
import ViewPA from '../../../../components/appraisalNew/viewPA/appraiser_Reviewer_Normalizer/Appraiser_Reviewer_Normalizer'
import { useGetEmployeeAppraisalForViewPAQuery, useUpdateEmployeeAppraisalMutation } from '../../../../service/employee/appraisal/appraisal';
import { useParams } from 'react-router-dom';
import {useGetObjectiveGroupQuery} from "../../../../service"
import { useGetFeedBackQuery, useGetObjectiveTitleQuery, useGetRatingScaleQuery } from '../../../../service';
import { useGetNineboxQuery } from '../../../../service/ninebox/ninebox';
import _ from "lodash";
import OverallFeedbackTab from '../../../../components/appraisalNew/appraiser/OverallFeedbackTab';
import { useLoggedInUser } from '../../../../hooks/useLoggedInUser';
import { PDFExport } from "@progress/kendo-react-pdf";



const ViewpA = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const { employee_id } = useParams();
  const { data: employeeData, isLoading: employeeDataIsLoading, isFetching: employeeDataIsFetching } = useGetEmployeeAppraisalForViewPAQuery(employee_id)
  const { data: nineBoxData } = useGetNineboxQuery("");
  const { data: ratingData } = useGetRatingScaleQuery("");
  const { data: loggedInUserData } = useLoggedInUser();
  const { data: feedbackQuestionnaireData } = useGetFeedBackQuery('')
  const { data: objectiveTitleData } = useGetObjectiveTitleQuery("");
  console.log(loggedInUserData, "loggedInUserData")
  const [updateEmployee] = useUpdateEmployeeAppraisalMutation();
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [overallRating, setOverallRating] = useState(0)
  const [ratingDefinition, setRatingDefinition] = useState<any>();
  const [ratingScaleDefinition, setRatingScaleDefinition] = useState<any>();
  const [PAStatus, setPAStatus] = useState("")
  const [appraiserFeedbackQuestionnaire, setAppraiserFeedbackQuestionnaire] = useState<any>([]);
  const [appraiserAreaOfImprovement, setAppraiserAreaOfImprovement] = useState([]);
  const [feedbackQuestionnaire, setFeedbackQuestionnaire] = useState<any>([]);
  const [appraiserTrainingRecommendation, setAppraiserTrainingRecommendation] = useState([])
  const [appraisalTemplateTrainingRecommendation, setAppraisalTemplateTrainingRecommendation] = useState([]);
  const [appraiserFurtherRecommendation, setAppraiserFurtherRecommendation] = useState<any>([]);
  const [appraiserFurtherRecommendationOthers, setAppraiserFurtherRecommendationOthers] = useState<any>("")
  const [appraiserFurtherRecommendationOthersCheckbox, setAppraiserFurtherRecommendationOthersCheckbox] = useState<any>(false)
  const [appraiserMessageForEmployee, setAppraiserMessageForEmployee] = useState("")
  const [showAppraiserMessageForEmployee, setShowAppraiserMessageForEmployee] = useState(false)
  const [oneToOneMeetingDate, setOneToOneMeetingDate] = useState("");
  const [showoneToOneMeetingDate, setShowoneToOneMeetingDate] = useState(false);
  const [employeeComments, setEmployeeComments] = useState("");
  const [showEmployeeComments, setShowEmployeeComments] = useState(false);
  const [employeeTrainingRecommendation, setEmployeeTrainingRecommendation] = useState([])
  const [showAppraiserTrainingRecommendation, setShowAppraiserTrainingRecommendation] = useState(false);
  const [showEmployeeTrainingRecommendation, setShowEmployeeTrainingRecommendation] = useState(false);
  const [employeeAreaOfImprovement, setEmployeeAreaOfImprovement] = useState<any>([]);
  const [showAppraiserAreaOfImprovement, setShowAppraiserAreaOfImprovement] = useState(false);
  const [showEmployeeAreaOfImprovement, setShowEmployeeAreaOfImprovement] = useState(false);
  const [normalizerComments, setNormalizerComments] = useState<any>("");
  const [showNormalizerComments, setShowNormalizerComments] = useState(false);
  const [showReviewerRejectionReason, setShowReviewerRejectionReason] = useState(false);
  const [reviewerRejectionReason, setReviewerRejectionReason] = useState<any>("");
  const [shownormalizerRejectionReason, setShowNormalizerRejectionReason] = useState(false);
  const [normalizerRejectionReason, setNormalizerRejectionReason] = useState<any>("");
  const [shownormalizerdiscussedrating, setShownormalizerdiscussedrating] = useState<any>("");
  const [shownormalizermeetingnotes, setShownormalizermeetingnotes] = useState<any>("");
  const [normalizerMeetingNotes, setNormalizerMeetingNotes] = useState<any>("");
  const [showreviewerComments, setShowReviewerComments] = useState(false);
  const [reviewerComments, setReviewerComments] = useState<any>("");
  const [performanceGoalsFieldValues, setPerformanceGoalsFieldValues] = useState<any>("");
  const [performanceGoalsFieldValuesEmployee, setPerformanceGoalsFieldValuesEmployee] = useState<any>("");
  const [showPerformanceGoalsFieldValues, setShowPerformanceGoalsFieldValues] = useState<any>("");
  const [showPerformanceGoalsFieldValuesEmployee, setShowPerformanceGoalsFieldValuesEmployee] = useState<any>("");
  const [showPotential, setShowPotential] = useState(false);
  const [potential, setPotential] = useState("")
  const [appraiserChecked, setAppraiserChecked] = useState(false);
  const [reviewerChecked, setReviewerChecked] = useState(false);
  const [employeeChecked, setEmployeeChecked] = useState(false);
  const [normalizerMeetingAttachment, setNormalizerMeetingAttachment] = useState<any>("");
  const [showNormalizerMeetingAttachment, setShowNormalizerMeetingAttachment] = useState(false);
  const [showNormalizerFooterButton, setShowNormalizerFooterButton] = useState(false);
  const [showReNormalizerFooterButton, setShowReNormalizerFooterButton] = useState(false);
  const [showReviewerFooterButton, setShowReviewerFooterButton] = useState(false);
  const [showAppraiserFooterButton, setShowAppraiserFooterButton] = useState(false);
  const [objectiveDescription, setObjectiveDescription] = useState<any>([]);
  const [colorarray, setColorarray] = useState<any>("");
  const [positionHide, setpositionHide] = useState<any>(true);
  const [employeecode, setemployeecode] = useState<any>([])
  const [furtherRecommendationOthers, setFurtherRecommendationOthers] = useState<any>("");
  const [furtherRecommendation, setFurtherRecommendation] = useState<any>("");
  const [furtherRecommendationOthersCheckbox, setFurtherRecommendationOthersCheckbox] = useState<any>("");
  const [getviewpalocation, setGetviewpalocation] = useState<any>([])
  const [appraiserAgreedRatingWithEmployee , setAppraiserAgreedRatingWithEmployee] = useState(false)

  const {data : objectiveGroupdata} =useGetObjectiveGroupQuery("")
  const [visiblityColumnData, setVisiblityColumnData] = useState({
    showPreviousRating: false,
    showAppraiserComments: false,
    showAppraiserRejectionReason: false,
    showReviewerRating: false,
    showReviewerRejectionReason: false,
    showEmployeeRating: false,
    showEmployeeRejectionReason: false,
    showNormalizerRating: false,
    showHRNormalizerComments: false
  });
  const Colors = [
    "#B6E9EE",
    "#C9FBEA",
    "#B1EDEE",
    "#B9E9D0",
    "#BDE3E2",
    "#B1F3F2",
    "#B7E6F7",
    "#B8EFEF",
    "#BFFBE7",
    "#B7E6F7",
    "#B1F1F0",
    "#BEECF5",
  ]

  useEffect(()=>{
    const searchParams = new URLSearchParams(window.location.search);
    const viewpa = searchParams.has("viewpa");
    if (viewpa) {
       var EventID = searchParams.get("viewpa");    
        setGetviewpalocation(EventID)
    }
  })
  const hideAlertHandler = () => {
    setTimeout(() => {
      // createPDF()
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
    }, 1000);
    setTimeout(() => {
      setpositionHide(true)
    }, 3000);
  };
  const handleHide = () => {
    setpositionHide(false)
    hideAlertHandler()
  }
  const findObjectiveGropuById = (id: any) => {
    if (objectiveGroupdata) {
      //console.log(id, "objectiveTitleData");
      return objectiveGroupdata?.data?.find((item: any) =>{
       return (
        item?._id === id
       )
    });
    }
  };
  // to find objective title by Id
  const findObjectiveTitleById = (id: any) => {
    if (objectiveTitleData) {
      //console.log(id, "objectiveTitleData");
      return objectiveTitleData.data.find((item: any) => item._id === id);
    }
  };
  // to find objective type by Id
  const findObjectiveTypeById = (id: any) => {
    if (employeeData) {
      return employeeData?.data?.reviewer?.objective_type?.find(
        (item: any) => item?.name?._id === id
      );
    }
  };
  // to find Previous Rating
  const findPreviousRating = (id: any) => {
    let temp = (employeeData?.data?.appraisal_previous_rating?.objective_description?.filter(
      (i: any) =>
        i?.name === id
    ).map((k: any) => {
      if (ratingData) {
        let temp = ratingData?.data?.find((item: any) => k.ratings == item._id)
        return temp?.rating
      }
    })[0]);
    return temp
  }
  //  to find employee rating
  const findEmployeeRating = (id: any) => {
    let temp = (employeeData?.data?.employee_previous_submission?.objective_description
      .filter(
        (i: any) =>
          i?.name?._id === id
      )
      .map((k: any) => {
        if (k?.ratings && k.rating_rejected == true)
          return {
            rating_rejected: k.rating_rejected,
            ratings: k.ratings,
            rejection_reason: k.rejection_reason
          }
        else return ""
      })[0]);
    return temp
  }
  //  to find Normalizer Rating
  const findNormalizerRating = (id: any) => {
    let temp = (employeeData?.data?.normalizer_previous_submission?.objective_description
      .filter(
        (i: any) =>
          i?.name?._id === id
      )
      .map((k: any) => {
        return {
          rating_resubmitted: k.rating_resubmitted,
          ratings: k?.ratings,
          rejection_reason: k?.rejection_reason
        }
      })[0]);
    return temp
  }
  // group Area of Improvement by specific Area
  const groupSpecificAreaHandler = (area: any) => {
    if (area) {
      let tempArea = area?.filter((area: any) => {
        return area[0] !== "" && area[0] !== undefined
      })
      if (tempArea && tempArea?.length > 0) {
        return {
          showArea: true,
          area: area,
        }
      } else {
        return {
          showArea: false
        }
      }
    }
  };
  const findTrainingName = (id: any) => {
    if (employeeData) {
      return employeeData?.data?.appraisal_template?.training_recommendation.find((i: any) =>
        i.name._id == id);
    }
  }
  //  to convert date dd/mm/yyyy format to mm/dd/yyyy
  const date = new Date(employeeData?.data?.employee_previous_submission?.one_to_one_meeting?.slice(0, 10));
  const One_To_One_Meeting_Date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  useEffect(() => {
    setPotential(employeeData?.data?.appraisal_previous_submission?.potential);
    setOneToOneMeetingDate(One_To_One_Meeting_Date)
    if(employeeData?.data?.employee_previous_submission?.one_to_one_meeting !== "" &&
    employeeData?.data?.employee_previous_submission?.one_to_one_meeting !== null &&
    employeeData?.data?.employee_previous_submission?.one_to_one_meeting !== undefined){
      setShowoneToOneMeetingDate(true)
    }else{
      setShowoneToOneMeetingDate(false)
    }
    setEmployeeComments(employeeData?.data?.employee_previous_submission?.comments)
    if (employeeData?.data?.employee_previous_submission?.comments !== undefined &&
      employeeData?.data?.employee_previous_submission?.comments !== "") {
      setShowEmployeeComments(true)
    } else {
      setShowEmployeeComments(false)
    }
    setEmployeeDetails(() => {
      return {
        employee_code: employeeData?.data?.employee_code,
        previousRating: employeeData?.data?.previous_rating,
        profile_image_url: employeeData?.data?.profile_image_url,
        fullName: employeeData?.data?.legal_full_name,
        firstName: employeeData?.data?.first_name,
        position: employeeData?.data?.position_long_description,
        grade: employeeData?.data?.grade,
        status: employeeData?.data?.appraisal?.status,
        calendarName: employeeData?.data.calendar?.name,
        JoiningDate:employeeData?.data?.JoiningDate,
        Department:employeeData?.data?.Department,
        Tenure:parseFloat(employeeData?.data?.Tenure)?.toFixed(2),
        DepartmentHead:employeeData?.data?.DepartmentHead,
      }
    });


    setObjectiveDescription(() => {
      const objectiveTypes = employeeData?.data?.appraisal?.objective_type || [];
      const objectiveGroupMap = new Map();

      objectiveTypes.forEach((item:any) => {
          if (item?.name?.objective_group) {
              objectiveGroupMap.set(item?.name?._id, item?.name?.objective_group);
          }
      });
      return employeeData?.data?.appraisal_previous_submission?.objective_description?.map(
        (i: any) => {
          return {
            ...i,
            comments: i.comments,
            rejection_reason: i.rejection_reason,
            rating: i.ratings,
            previous_rating: findPreviousRating(i.name?._id),
            objective_title: findObjectiveTitleById(i?.name?.objective_title),
            objective_type: findObjectiveTypeById(i?.name?.objective_type),
            employee_rating: findEmployeeRating(i.name?._id),
            normalizer_rating: findNormalizerRating(i.name?._id),
            objective_group: findObjectiveGropuById(objectiveGroupMap.get(i?.name?.objective_type))
          };
        }
      );
    });
    setVisiblityColumnData({
      ...visiblityColumnData,
      // show the previous rating popover if any previous rating data is present.
      showPreviousRating: (employeeData?.data?.appraisal_previous_rating?.objective_description?.filter((i: any) => i.ratings)?.length > 0) ? true : false,
      // show appraiser comments column if any of the rating has appraiser comments.
      showAppraiserComments: (employeeData?.data?.appraisal_previous_submission?.objective_description.filter((item: any) =>
        item.comments !== "" && item.comments !== undefined)?.length > 0) ? true : false,
      /**show appraiser rejection reason if appraiser has rejected any rating and added rejection reason and also if 
               if the PA is not renormalized or completed *****/
      showAppraiserRejectionReason: (employeeData?.data?.appraisal?.status !== "completed" &&
        employeeData?.data?.appraisal_previous_submission?.objective_description?.filter((item: any) =>
          ((item.rating_resubmitted && employeeData?.data?.appraisal?.status !== "rejected") || item.rating_rejected
          ) && (item.rejection_reason !== "" && item.rejection_reason !== undefined)).length > 0) ? true : false,
      showReviewerRating: (employeeData?.data?.reviewer_previous_submission?.objective_description?.filter((item: any) =>
        item.rating_rejected == true).length > 0) ? true : false,
      showReviewerRejectionReason: (employeeData?.data?.reviewer_previous_submission?.objective_description?.filter((item: any) =>
        item.rating_rejected == true).length > 0) ? true : false,
      /**show employee rating if any of the rating has been rejected by the employee */
      showEmployeeRating: ((employeeData?.data?.normalizer?.normalizer_status !== "re-normalized") && (employeeData?.data?.employee_previous_submission?.objective_description?.filter((item: any) =>
        item.rating_rejected == true)?.length > 0)) ? true : false,
      showEmployeeRejectionReason: ((employeeData?.data?.normalizer?.normalizer_status !== "re-normalized") && (employeeData?.data?.employee_previous_submission?.objective_description?.filter((item: any) =>
        item.rating_rejected == true).length > 0)) ? true : false,
      /**Show normalizer rating*/
      showNormalizerRating: (employeeData?.data?.normalizer_previous_submission?.objective_description?.filter((item: any) =>
        item.rating_resubmitted == true).length > 0) ? true : false,
      /** Show Normalizer comments */
      showHRNormalizerComments: (employeeData?.data?.normalizer_previous_submission?.objective_description?.filter((item: any) =>
        item.rating_resubmitted == true).length > 0) ? true : false

    })
    let objectiveType = employeeData?.data?.appraisal?.objective_group?.map((item: any, index: number) => {
      return {
        objective_group: item?.name?.name,
        color: Colors[index]
      }
    });
    setColorarray(objectiveType)
    setemployeecode(employeeData?.data?.employee_code)
    setNormalizerMeetingAttachment(employeeData?.data?.normalizer_previous_submission?.meetingNotesAttachments)
    if (employeeData?.data?.normalizer_previous_submission?.meetingNotesAttachments?.length >0) {
      setShowNormalizerMeetingAttachment(true)
    } else {
      setShowNormalizerMeetingAttachment(false)
    }
    setShowPotential(employeeData?.data?.appraisal_template?.potential === true);
    setPAStatus(employeeData?.data?.appraisal?.pa_status);
    setOverallRating(employeeData?.data?.appraisal?.pa_rating?.toFixed(2));
    setAppraiserFeedbackQuestionnaire(employeeData?.data?.appraisal_previous_submission?.feedback_questions)
    setAppraiserAreaOfImprovement(employeeData?.data?.appraisal_previous_submission?.area_of_improvement);
    setAppraiserTrainingRecommendation(employeeData?.data?.appraisal_previous_submission?.training_recommendation);
    setNormalizerComments(employeeData?.data?.normalizer_previous_submission?.reason_for_rejection)
    setReviewerComments(employeeData?.data?.reviewer_previous_submission?.reviewer_comments)
    setReviewerRejectionReason(employeeData?.data?.reviewer_previous_submission?.reviewer_overall_feedback)
    setNormalizerRejectionReason(employeeData?.data?.normalizer_previous_submission?.normalizer_overall_feedback)
    setPerformanceGoalsFieldValues(employeeData?.data?.appraisal_previous_submission?.performance_goal)
    setEmployeeTrainingRecommendation(employeeData?.data?.employee_previous_submission?.training_recommendation);
    setPerformanceGoalsFieldValuesEmployee(employeeData?.data?.employee_previous_submission?.performance_goal)
    if (loggedInUserData?.current_role !== "PA Admin" && employeeData?.data?.appraisal?.pa_status?.includes("Pending with HR Normalizer") &&
      loggedInUserData?.employee_code == employeeData?.data?.normalizer_code && employeeData?.data?.appraisal?.status !== "rejected"
    ) {
      setShowNormalizerFooterButton(true)
      setShowReNormalizerFooterButton(false)
    } else {
      setShowNormalizerFooterButton(false)
    }
    if(loggedInUserData?.current_role !== "PA Admin" && employeeData?.data?.appraisal?.pa_status?.includes("Pending with HR Normalizer") && employeeData?.data?.appraisal?.status == "rejected"
  && loggedInUserData?.employee_code == employeeData?.data?.normalizer_code ){
      setShowReNormalizerFooterButton(true)
      setShowNormalizerFooterButton(false)
    }else{
      setShowReNormalizerFooterButton(false)
    }
    if (loggedInUserData?.current_role !== "PA Admin" &&
      employeeData?.data?.appraisal?.pa_status?.includes("Pending with Reviewer") &&
      loggedInUserData?.employee_code == employeeData?.data?.reviewer_code) {
      setShowReviewerFooterButton(true)
    } else {
      setShowReviewerFooterButton(false)
    }
    if (loggedInUserData?.current_role !== "PA Admin" &&
      employeeData?.data?.appraisal?.pa_status?.includes("Pending with Appraiser") 
      ) {
      setShowAppraiserFooterButton(true)
    } else {
      setShowAppraiserFooterButton(false)
    }
    setAppraiserChecked(
      employeeData?.data?.normalizer_previous_submission?.isAppraiserChecked
    );
    setReviewerChecked(
      employeeData?.data?.normalizer_previous_submission?.isReviewerChecked
    );
    setEmployeeChecked(
      employeeData?.data?.normalizer_previous_submission?.isEmployeeChecked
    );
    if (employeeData?.data?.appraisal?.status == "completed" &&
      employeeData?.data?.normalizer?.normalizer_status == "re-normalized" &&
      (employeeData?.data?.normalizer_previous_submission?.isAppraiserChecked ||
        employeeData?.data?.normalizer_previous_submission?.isEmployeeChecked ||
        employeeData?.data?.normalizer_previous_submission?.isReviewerChecked)) {
      setShownormalizerdiscussedrating(true)
    } else {
      setShownormalizerdiscussedrating(false)
    }
    setNormalizerMeetingNotes(employeeData?.data?.normalizer_previous_submission?.normalizer_meeting_notes)
    if (employeeData?.data?.appraisal?.status == "completed" &&
      employeeData?.data?.normalizer?.normalizer_status == "re-normalized" &&
      employeeData?.data?.normalizer_previous_submission?.normalizer_meeting_notes !== "") {
      setShownormalizermeetingnotes(true)
    } else {
      setShownormalizermeetingnotes(false)
    }
    let performancegoalvaluesappraiser = employeeData?.data?.appraisal_previous_submission?.performance_goal
    let performancegoalvaluesemployee = employeeData?.data?.employee_previous_submission?.performance_goal
    if (employeeData?.data?.normalizer_previous_submission?.normalizer_overall_feedback !== undefined &&
      employeeData?.data?.normalizer_previous_submission?.normalizer_overall_feedback !== "" &&
      employeeData?.data?.normalizer?.normalizer_PA_rejected == true) {
      setShowNormalizerRejectionReason(true)
    } else {
      setShowNormalizerRejectionReason(false)
    }
    if (performancegoalvaluesappraiser?.length == 0) {
      setShowPerformanceGoalsFieldValues(false)
    } else {
      setShowPerformanceGoalsFieldValues(true)
    }
    if (performancegoalvaluesemployee?.length == 0) {
      setShowPerformanceGoalsFieldValuesEmployee(false)
    } else {
      setShowPerformanceGoalsFieldValuesEmployee(true)
    }
    if (employeeData?.data?.reviewer_previous_submission?.reviewer_comments !== undefined &&
      employeeData?.data?.reviewer_previous_submission?.reviewer_comments !== "" &&
      employeeData?.data?.reviewer?.reviewer_PA_rejected !== true) {
      setShowReviewerComments(true)
    } else {
      setShowReviewerComments(false)
    }
    if (employeeData?.data?.reviewer_previous_submission?.reviewer_overall_feedback !== undefined &&
      employeeData?.data?.reviewer_previous_submission?.reviewer_overall_feedback !== "" &&
      employeeData?.data?.reviewer?.reviewer_PA_rejected == true) {
      setShowReviewerRejectionReason(true)
    } else {
      setShowReviewerRejectionReason(false)
    }
    if (employeeData?.data?.normalizer_previous_submission?.reason_for_rejection &&
      employeeData?.data?.normalizer_previous_submission?.reason_for_rejection != "" &&
      employeeData?.data?.normalizer_previous_submission?.reason_for_rejection != undefined &&
      employeeData?.data?.normalizer?.normalizer_PA_rejected !== true) {
      setShowNormalizerComments(true)
    } else {
      setShowNormalizerComments(false)
    }
    let tempTraining = employeeData?.data?.appraisal_previous_submission?.training_recommendation?.filter((item: any) => {
      return item?.name?.title !== "" || item?.name?.title !== undefined
    });

    if (tempTraining && tempTraining?.length > 0) {
      setShowAppraiserTrainingRecommendation(true);
    } else {
      setShowAppraiserTrainingRecommendation(false)
    }
    let TrainingEmployee = employeeData?.data?.employee_previous_submission?.training_recommendation?.filter((item: any) => {
      return item?.name?.title !== "" || item?.name?.title !== undefined
    });
    if (TrainingEmployee && TrainingEmployee?.length > 0) {
      setShowEmployeeTrainingRecommendation(true);
    } else {
      setShowEmployeeTrainingRecommendation(false)
    }
    setAppraisalTemplateTrainingRecommendation(employeeData?.data?.appraisal_template?.training_recommendation);
    setEmployeeComments(employeeData?.data?.employee_previous_submission?.comments);
    // setOneToOneMeetingDate(employeeData?.data?.employee?.one_to_one_meeting?.slice(0, 10));
    setFurtherRecommendation(() => {
      let otherRecommendation = employeeData?.data?.appraisal_previous_submission?.other_recommendation?.length > 0 ?
        employeeData?.data?.appraisal_previous_submission?.other_recommendation :
        employeeData?.data?.appraisal?.other_recommendation;
      return employeeData?.data?.appraisal_previous_submission?.other_recommendation?.map(
        (i: any) => {
          return {
            ...i,
            name: i?.name,
          };
        }
      );
    });
    setFurtherRecommendationOthersCheckbox(employeeData?.data?.appraisal_previous_submission?.others_checkbox ? 
      employeeData?.data?.appraisal_previous_submission?.others_checkbox :
      employeeData?.data?.appraisal?.others_checkbox
    )
  
    // to get Appraiser Area of Improvement
    let tempAppraiserAreaofImprovement = employeeData?.data?.appraisal_previous_submission?.area_of_improvement;
    const groupBySpecificAppraiser = _.groupBy(tempAppraiserAreaofImprovement, "value");
    const groupHandlerAppraiser = groupSpecificAreaHandler(Object.entries(groupBySpecificAppraiser));
    if (groupHandlerAppraiser?.showArea) {
      setShowAppraiserAreaOfImprovement(true);
      setAppraiserAreaOfImprovement(groupHandlerAppraiser?.area)
    } else {
      setShowAppraiserAreaOfImprovement(false);
    }
    // to get Employee Area of Improvement
    let tempEmployeeAreaofImprovement = employeeData?.data?.employee_previous_submission?.area_of_improvement
    const groupBySpecificEmployee = _.groupBy(tempEmployeeAreaofImprovement, "value");
    const groupHandlerEmployee = groupSpecificAreaHandler(Object.entries(groupBySpecificEmployee));
    if (groupHandlerEmployee?.showArea) {
      setShowEmployeeAreaOfImprovement(true);
      setEmployeeAreaOfImprovement(groupHandlerEmployee?.area)
    } else {
      setShowEmployeeAreaOfImprovement(false);
    }
    // setEmployeeAreaOfImprovement(employeeData?.data?.employee_previous_submission?.area_of_improvement);
    setFurtherRecommendationOthers(employeeData?.data?.appraisal_previous_submission?.other_recommendation_others);
    setAppraiserFurtherRecommendationOthersCheckbox(employeeData?.data?.appraisal_previous_submission?.others_checkbox);
    setAppraiserMessageForEmployee(employeeData?.data?.appraisal_previous_submission?.appraiser_overall_feedback);
    setShowAppraiserMessageForEmployee(employeeData?.data?.isGradeException != true && (employeeData?.data?.appraisal?.appraiser_overall_feedback !== null &&
      employeeData?.data?.appraisal?.appraiser_overall_feedback !== ""))

    const Overall_rating = employeeData?.data?.current_rating?.overall_rating;
    const RatingScale = ratingData?.data?.map((j: any) => ({
      rating: j?.rating,
      definition: j?.definition,
      rating_titile: j?.rating_scale,
    }));
    setAppraiserAgreedRatingWithEmployee(employeeData?.data?.appraisal_previous_submission?.appraiserAgreedRatingWithEmployee)

    const filterRatingScale = (item: any, minRating: any, maxRating: any) => {
      return (item?.rating >= minRating && item?.rating <= maxRating) && (Overall_rating >= minRating && Overall_rating <= maxRating);
    }
    const FilteredRatingScale = RatingScale?.filter((item: any) => {
      if (filterRatingScale(item, 1, 1.99) ||
        filterRatingScale(item, 2, 2.49) ||
        filterRatingScale(item, 2.5, 2.99) ||
        filterRatingScale(item, 3, 3.49) ||
        filterRatingScale(item, 3.5, 3.99) ||
        filterRatingScale(item, 4, 4.49) ||
        filterRatingScale(item, 4.5, 4.99) ||
        filterRatingScale(item, 5.0, 5.0)) {
        return {
          ratingScale: item?.rating_titile,
          definition: item?.definition,
        };
      }
    });
    if (FilteredRatingScale && FilteredRatingScale.length > 0) {
      setRatingDefinition(FilteredRatingScale[0]?.definition);
      setRatingScaleDefinition(FilteredRatingScale[0]?.rating_titile);
    } else {
      // Handle the case when FilteredRatingScale is empty
      // setratingdefenition("No rating definition found");
    }
  }, [ratingData, feedbackQuestionnaireData, employeeData])

  return (
    <div>
      <ViewPA
      showReNormalizerFooterButton={showReNormalizerFooterButton}
        getviewpalocation={getviewpalocation}
        employee_id={employee_id}
        employeeDetails={employeeDetails}
        overallRating={overallRating}
        PAStatus={PAStatus}
        showPotential={showPotential}
        potential={potential}
        ratingScaleDefinition={ratingScaleDefinition}
        ratingDefinition={ratingDefinition}
        employeeData={employeeData}
        ratingData={ratingData}
        nineBoxData={nineBoxData}
        objectiveTitleData={objectiveTitleData}
        updateEmployee={updateEmployee}
        employeeDataIsFetching={employeeDataIsFetching}
        employeeDataIsLoading={employeeDataIsLoading}
        appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire}
        showAppraiserAreaOfImprovement={showAppraiserAreaOfImprovement}
        appraiserAreaOfImprovement={appraiserAreaOfImprovement}
        employeeAreaOfImprovement={employeeAreaOfImprovement}
        setEmployeeAreaOfImprovement={setEmployeeAreaOfImprovement}
        showAppraiserTrainingRecommendation={showAppraiserTrainingRecommendation}
        appraiserTrainingRecommendation={appraiserTrainingRecommendation}
        employeeTrainingRecommendation={employeeTrainingRecommendation}
        setEmployeeTrainingRecommendation={setEmployeeTrainingRecommendation}
        appraisalTemplateTrainingRecommendation={appraisalTemplateTrainingRecommendation}
        employeeComments={employeeComments}
        setEmployeeComments={setEmployeeComments}
        oneToOneMeetingDate={oneToOneMeetingDate}
        setOneToOneMeetingDate={setOneToOneMeetingDate}
        appraiserMessageForEmployee={appraiserMessageForEmployee}
        showAppraiserMessageForEmployee={showAppraiserMessageForEmployee}
        setAppraiserFeedbackQuestionnaire={setAppraiserFeedbackQuestionnaire}
        normalizerComments={normalizerComments}
        showNormalizerComments={showNormalizerComments}
        reviewerRejectionReason={reviewerRejectionReason}
        showReviewerRejectionReason={showReviewerRejectionReason}
        performanceGoalsFieldValues={performanceGoalsFieldValues}
        performanceGoalsFieldValuesEmployee={performanceGoalsFieldValuesEmployee}
        showEmployeeAreaOfImprovement={showEmployeeAreaOfImprovement}
        showPerformanceGoalsFieldValuesEmployee={showPerformanceGoalsFieldValuesEmployee}
        showPerformanceGoalsFieldValues={showPerformanceGoalsFieldValues}
        showEmployeeTrainingRecommendation={showEmployeeTrainingRecommendation}
        showreviewerComments={showreviewerComments}
        reviewerComments={reviewerComments}
        normalizerRejectionReason={normalizerRejectionReason}
        shownormalizerRejectionReason={shownormalizerRejectionReason}
        appraiserChecked={appraiserChecked}
        reviewerChecked={reviewerChecked}
        employeeChecked={employeeChecked}
        shownormalizerdiscussedrating={shownormalizerdiscussedrating}
        shownormalizermeetingnotes={shownormalizermeetingnotes}
        normalizerMeetingNotes={normalizerMeetingNotes}
        normalizerMeetingAttachment={normalizerMeetingAttachment}
        showAppraiserFooterButton={showAppraiserFooterButton}
        showReviewerFooterButton={showReviewerFooterButton}
        showNormalizerFooterButton={showNormalizerFooterButton}
        visiblityColumnData={visiblityColumnData}
        objectiveDescription={objectiveDescription}
        setObjectiveDescription={setObjectiveDescription}
        Colors={Colors}
        colorarray={colorarray}
        pdfExportComponent={pdfExportComponent}
        handleHide={handleHide}
        positionHide={positionHide}
        employeecode={employeecode}
        showEmployeeComments={showEmployeeComments}
        furtherRecommendation={furtherRecommendation}
        furtherRecommendationOthers={furtherRecommendationOthers}
        furtherRecommendationOthersCheckbox = {furtherRecommendationOthersCheckbox}
        showoneToOneMeetingDate={showoneToOneMeetingDate}
        showNormalizerMeetingAttachment={showNormalizerMeetingAttachment}
        appraiserAgreedRatingWithEmployee = {appraiserAgreedRatingWithEmployee}
      />

    </div>
  )
}

export default ViewpA