import { Box, Dialog, Drawer, FormControl, IconButton, MenuItem, Popover, Select, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography, styled } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import RatingsHeader from '../../ratings/RatingsHeader';
import RatingsObjectiveDetails from '../../ratings/RatingsObjectiveDetails';
import CustomPopOverForTable from '../../../UI/CustomPopoverForTable';
import Infoicon from "../../../../assets/Images/Infoicon.svg";
import Uploadatt from "../../../../assets/Images/Uploadatt.svg";
import Removeattnew from "../../../../assets/Images/Removeattnew.svg";
import { useCreateAzureBlobMutation } from '../../../../service/azureblob';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useAttachmentsAppraiserDeleteMutation, useAttachmentsRejectionAppraiserDeleteMutation, useCreateEmployeeAppraisalMutation } from '../../../../service';
import Edit from "../../../../assets/Images/Edit.svg";
import AppraiserResubmissionRatingSlider from '../appraiserRatingSlider/AppraiserResubmissionRatingSlider';


const Train = styled("div")({
  "& .MuiInputBase-root": {
    // color: "rgb(51 51 51/50%)",
    fontSize: "14px",
    color: "#333333",
    textTransform: "none",
    fontFamily: "Arial",
    backgroundColor: "#f8f8f8",
    borderRadius: "5px",
    padding: "5px",
  },
});

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 60,
      fontSize: "14px !important",
      fontFamily: "arial !important",
      color: "#333333 !important",
    },
  },
};

const Text = styled("div")({
  // position: "absolute",
  width: "100%",

  "& .MuiInputBase-input": {
    fontWeight: "400",
    textTransform: "none",
    fontFamily: "Arial",
    fontSize: "14px",
    color: "#333333",
    // backgroundColor: "#f8f8f8",
    backgroundColor: "#ededed",
    padding: "5px",
    borderRadius: "5px",
    // minHeight: "50px",
  },
});

const RatingBackground = styled("div")({
  width: "27px",
  lineHeight: "27px",
  borderRadius: "50%",
  display: "block",
  // color: "white",  background: "red",
});

const AppraiserResubmissionRatings = (props: any) => {
  const { visiblityColumnData,
    employeeData,
    employee_id,
    colorarray,
    ratingData,
    setMoveTab,
    setNavPrompt,
    disableTextAfterSubmission,
    setDisableTextAfterSubmission,
    objectiveDescription,
    setObjectiveDescription,
    attachedFiles,
    setAttachedFiles,
    refetchEmployeeData,
    employeeDataIsFetching,
    employeeDataIsLoading,
    updateAction,
    isLoadingAction,
    updateMutation,
    employeeDataIsUpdating,
    createMutation
  } = props;
  const inputRef = useRef<any>(null);
  const [popoverIndex, setPopoverIndex] = useState("");
  const [activeObjectiveId, setActiveObjectiveId] = useState<any>();
  const [activeObjectiveDescriptionName, setActiveObjectiveDescriptionName] =
    useState<any>();
  const [fileSelected, setFileSelected] = useState<any>("");
  const [name, setName] = useState<string>("");
  const [showIsUploading, setShowIsUploading] = useState<any>(false)
  const [normalizedRating, setNormalizedRating] = useState(0)
  const [previousRating, setPreviousRating] = useState<any>("");
  const [title, setTitle] = useState<any>("");
  const [accept, setAccept] = useState("");
  const [activeObjectiveDescription, setActiveObjectiveDescription] =
    useState("");
  const [comments, setComments] = useState("");
  const [resubmissionComments, setResubmissionComments] = useState("");
  const [appraiserNewRatingId, setAppraiserNewRatingId] = useState<any>("");
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [appraiserNewRating, setAppraiserNewRating] = useState<any>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [open, setOpen] = useState<any>(false);


  // mutations
  const [sendItem, { data, isLoading: isUploadingAttachment }] =
    useCreateAzureBlobMutation();
  const [deleteAppraiserMutation, { isLoading: isDeleting, data: deletes }] =
    useAttachmentsAppraiserDeleteMutation();
  const [deleteRejectionAppraiserMutation, { isLoading: isRejectionDeleting, data: deleteRejection }] =
    useAttachmentsRejectionAppraiserDeleteMutation()


  // popovers

  //  pop over for rating definition
  const [anchorRatingDefinition, setAnchorRatingDefinition] = React.useState<HTMLButtonElement | null>(null);
  const openInfoRatingDefinition = Boolean(anchorRatingDefinition);
  const idRatingDefinition = openInfoRatingDefinition ? "simple-popover" : undefined;


  const handleOpenRatingDefinitionInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorRatingDefinition(event.currentTarget);
  };

  const handleCloseRatingDefinitionInfo = () => {
    setAnchorRatingDefinition(null);
  };


  // popover for appraiser comments attachment
  const [anchorAppraiserCommentsAttachment, setAnchorAppraiserCommentsAttachment] = React.useState<HTMLButtonElement | null>(
    null
  );
  const OpenAppraiserCommentsAttachment = Boolean(anchorAppraiserCommentsAttachment);
  const handleClickOpenAppraiserCommentsAttachment = (
    event: React.MouseEvent<HTMLButtonElement>,
    j: any
  ) => {
    // setOpen2(true);
    setAnchorAppraiserCommentsAttachment(event.currentTarget);
    // setappraisalAttachments(
    //   employeeData &&
    //   employeeData?.data?.appraisal?.attachments
    //     .filter((i: any) => i?.objective_description === j.name._id)
    //     .map((k: any) => {
    //       return (
    //         <div>
    //           <a href={k.url}> {k.name} </a>
    //           <br />
    //         </div>
    //       );
    //     })
    // );
  };
  const handleClosehandleClickOpenAppraiserCommentsAttachment = () => {
    setAnchorAppraiserCommentsAttachment(null);

    // setOpen2(false);
  };

  // popover for appraiser rejection reason

  const [anchorAppraiserRejectionReasonAttachment, setAnchorAppraiserRejectionReasonAttachment] = React.useState<HTMLButtonElement | null>(null);
  const openAppraiserRejectionReasonAttachment = Boolean(anchorAppraiserRejectionReasonAttachment)
  const idAppraiserRejectionReasonAttachment = openAppraiserRejectionReasonAttachment ? "simple-popover" : undefined;

  const handleClickOpenAppraiserRejectionReasonAttachment = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorAppraiserRejectionReasonAttachment(event.currentTarget);
  };
  const handleClickCloseAppraiserRejectionReasonAttachment = () => {
    setAnchorAppraiserRejectionReasonAttachment(null);
  };


  // popover for previous rating
  const [anchorPreviousRatingPopOver, setAnchorPreviousRatingPopOver] = React.useState<HTMLElement | null>(null);
  const openPreviousRating = Boolean(anchorPreviousRatingPopOver);
  const id_Previous_Rating = openPreviousRating ? "simple-popover" : undefined;

  const handlePreviousRatingPopOverClose = () => {
    setAnchorPreviousRatingPopOver(null)
  }

  const handlePreviousRatingPopOverOpen = (event: React.MouseEvent<HTMLElement>, j: any) => {
    setAnchorPreviousRatingPopOver(event.currentTarget);
  };


  //  popover for rating info
  const [anchorRatingInfo, setAnchorRatingInfo] = React.useState<HTMLButtonElement | null>(null);
  const openRatingInfo = Boolean(anchorRatingInfo);
  const idRatingInfo = openRatingInfo ? "simple-popover" : undefined;

  const handleClickOpenRatingInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorRatingInfo(event.currentTarget);
  };
  const handleClickCloseRatingInfo = () => {
    setAnchorRatingInfo(null);
  };




  useEffect(() => {
    setShowIsUploading(true);
    sendItem({
      // newspic: fileList[0].name,
      newspic: fileSelected,
      newspicname: name,
    })
      .then((res: any) => {
        setShowIsUploading(false);
      });
    if (name != "" && name != undefined) {
      let files = attachedFiles.map((item: any) => {
        return item;
      });
      files.push({
        url: name,
        name: name,
        objective_description: activeObjectiveDescriptionName,
      });
      setAttachedFiles(files);
    }
  }, [fileSelected]);

  useEffect(() => {
    if (isUploadingAttachment == true) {
      setShowIsUploading(true)
    } else if (isUploadingAttachment == false) {
      setShowIsUploading(false)
    }
  }, [isUploadingAttachment])

  const getAttachments = (id: any) => {
    return attachedFiles
      ?.filter((i: any) => i?.objective_description == id)
      .map((k: any) => {
        console.log(k, "zzzzz");
        return {
          resp: (
            <div>
              {" "}
              {(k.url == k.name) ? (k.name) : (<a href={k.url}> {k.name} </a>)}<br />

            </div>
          ),
          remove: {
            name: k.name,
            objective_description: k.objective_description,
          },
        };

        // return k.name
      });
  };

  const getAppraiserRejectionAttachments = (id: any) => {
    return employeeData?.data?.appraisal?.rejection_attachments
      .filter((i: any) => i?.objective_description == id)
      // .filter((i: any) => i?.objective_description === j.name._id)
      .map((k: any) => {
        console.log(k, "zzzzz");
        return {
          resp: (
            <div>
              {" "}
              <a href={k.url}> {k.name} </a> <br />
            </div>
          ),
          remove: k.name,
        };
        // return k.name
      });
  };

  const appraiserCommentsHandler = (e: any, j: any) => {
    // const selectedRating = ratingData?.data?.find((rating: any) => rating._id === j);
    setObjectiveDescription(() => {
      return objectiveDescription?.map((i: any) => {
        return i._id === j._id
          ? {
            ...i,
            comments: e.target.value,
            // rating_scale :selectedRating?.rating_scale 
          }
          : i;
      });
    });
  };


  const appraiserRatingsHandler = (j: any, ratingId: any) => {
    const selectedRating = ratingData?.data?.find((rating: any) => rating._id === ratingId);
    setObjectiveDescription(() => {
      return objectiveDescription?.map((i: any) => {

        return i._id === j._id
          ? {
            ...i,
            ratings: ratingId,
            rating_scale: selectedRating?.rating_scale,
            ratingdef: selectedRating?.definition, // Setting ratingdef here
            // rating_scale :selectedRating?.definition // rating_scale
          }
          : i;
      });
    });
    if (ratingData && ratingData?.data) {
      const selectedRating = ratingData?.data?.find((rating: any) => rating._id === ratingId);
    }
  }

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const openDrawerHandler = (objective: any) => {
    console.log(objective,'checkObjective')
    let previousRatingValue = employeeData?.data?.appraisal_previous_rating?.objective_description
      ?.filter((i: any) => i.name === objective.name._id)
      ?.map((k: any) => {
        let tempRating = ratingData?.data?.find((item: any) => k?.ratings == item?._id)
        if (tempRating) return tempRating?.rating;
      })[0];

    let normalizerRating = employeeData?.data?.normalizer?.objective_description
      ?.filter((i: any) => i.name._id === objective.name._id)
      ?.map((k: any) => {
        if (k.ratings) return k.ratings.rating;
      })[0];

    setNormalizedRating(normalizerRating);
    setPreviousRating(previousRatingValue);
    setName("")
    setTitle(objective?.name?.objectiveTitle);
    setAccept("Reject");
    openDrawer();
    setActiveObjectiveDescriptionName(objective.name._id);
    setActiveObjectiveDescription(objective._id);
    setComments(objective.rejection_reason);
    setResubmissionComments(objective.comments)
    setAppraiserNewRatingId(objective.ratings);
   let AppraiserRating =  ratingData?.data?.find((rating: any) => rating._id === objective.ratings);
    if (objective.rating_rejected == true && objective.action_performed == true) {
      setShowWithdraw(true);
      setAppraiserNewRatingId(objective.ratings);
      setAppraiserNewRating(AppraiserRating);
    } else {
      setShowWithdraw(false);
      setAppraiserNewRatingId("");
      setAppraiserNewRating("");
    }
  };



  return (

    <>

      <Drawer
        anchor={"right"}
        open={isDrawerOpen}
      >
        <AppraiserResubmissionRatingSlider
          accept={accept}
          title={title}
          ratingData={ratingData}
          employeeData={employeeData}
          appraiserNewRating={appraiserNewRating}
          setAppraiserNewRating={setAppraiserNewRating}
          appraiserNewRatingId={appraiserNewRatingId}
          setAppraiserNewRatingId={setAppraiserNewRatingId}
          previousRating={previousRating}
          comments={comments}
          setComments={setComments}
          setNavPrompt={setNavPrompt}
          resubmissionComments={resubmissionComments}
          setResubmissionComments={setResubmissionComments}
          name={name}
          setName={setName}
          showWithdraw={showWithdraw}
          setShowWithdraw={setShowWithdraw}
          setIsDrawerOpen={setIsDrawerOpen}
          open={open}
          setOpen={setOpen}
          objectiveDescription={objectiveDescription}
          activeObjectiveDescriptionName={activeObjectiveDescriptionName}
          employee_id={employee_id}
          updateMutation={updateMutation}
          activeObjectiveDescription={activeObjectiveDescription}
          normalizedRating={normalizedRating}
          createMutation={createMutation} />

      </Drawer>
      <div style={{
        // marginRight: "66px", marginLeft: "36px"
      }} >
        <TableContainer>
          <Table sx={{
            borderCollapse: 'separate',
            borderSpacing: '0px 15px'
          }} size="small" aria-label="simple table">
            <RatingsHeader
              visiblityColumnData={visiblityColumnData}
              isLoadingAction={isLoadingAction} />

            <TableBody>
              {objectiveDescription?.map((j: any, index: any) => {
                const selectedRating: any = ratingData?.data?.find((ratings: any) => ratings?._id === j?.ratings)
                console.log(ratingData, selectedRating, j, 'checkratingData')

                return (
                  <>
                    <TableRow
                      sx={{
                        "& td, & th": {
                          border: "1px solid #80808014 ",
                          boxShadow: "1px 0px 0px 1px #80808014",
                          borderBottom: "none",
                          borderLeft: "0px",
                          borderTop: "0px",
                        },
                      }}
                    >
                      <RatingsObjectiveDetails
                        index={index}
                        colorarray={colorarray}
                        popoverIndex={popoverIndex}
                        setPopoverIndex={setPopoverIndex}
                        objectiveDescription={j}
                        activeObjectiveId={activeObjectiveId}
                        setActiveObjectiveId={setActiveObjectiveId}
                      />

                      <TableCell
                        width="10px"
                        style={{
                          padding: "0px 8px",
                          fontFamily: "Arial",
                          fontSize: "14px",
                          color: "#333333",
                          textAlign: "center",
                          lineHeight: "20px",
                          background: "#ffffff",
                        }}
                      >
                        <div style={{ display: "inline-flex", alignItems: "center" }}>
                          <div>
                            {/* {selectedRating?.definition !== undefined && selectedRating?.definition !== "" && ( */}
                            <IconButton
                              sx={{ padding: "4px" }}
                              onClick={(e) => {
                                handleClickOpenRatingInfo(e);
                                setPopoverIndex(index);
                              }}
                            >
                              <img width="12px" src={Infoicon} alt="icon" />
                            </IconButton>
                            {/* )} */}

                            {/* Popover for icon */}
                            <Popover
                              id={idRatingInfo}
                              open={popoverIndex === index && openRatingInfo}
                              anchorEl={anchorRatingInfo}
                              onClose={handleClickCloseRatingInfo}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              PaperProps={{
                                style: {
                                  backgroundColor: "FEFCF8",
                                  boxShadow: "none",
                                  maxWidth: "400px",
                                  borderRadius: "5px",
                                },
                              }}
                              sx={{
                                "& .MuiPopover-paper": {
                                  border: "1px solid #3e8cb5",
                                  backgroundColor: "#ffffff",
                                },
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "arial",
                                  padding: "5px",
                                }}
                              >
                                <b>{selectedRating?.rating_scale}</b>:{selectedRating?.definition}
                              </Typography>
                            </Popover>
                          </div>
                          <div>
                            {employeeData?.data?.appraisal?.objective_description
                              .filter((i: any) => i?.name?._id === j?.name?._id)
                              .map((k: any) => {
                                console.log(k, "kkkkkk");
                                let ratingElement;

                                if (k?.ratings && k.rating_rejected === true) {
                                  ratingElement = (
                                    <RatingBackground
                                      onClick={(e: any) => {
                                        handlePreviousRatingPopOverOpen(e, j);
                                        setPopoverIndex(index);
                                      }}
                                      style={{ color: "white", background: "#D2122E",textAlign:"center" }}
                                      key={k._id}
                                    >
                                      {k?.ratings?.rating}
                                    </RatingBackground>
                                  );
                                } else if (
                                  k?.ratings &&
                                  (k.rating_accepted === true || k.rating_resubmitted === true)
                                ) {
                                  ratingElement = (
                                    <RatingBackground
                                      onClick={(e: any) => {
                                        handlePreviousRatingPopOverOpen(e, j);
                                        setPopoverIndex(index);
                                      }}
                                      style={{ color: "white", background: "#3e8cb5" }}
                                      key={k._id}
                                    >
                                      {k?.ratings?.rating}
                                    </RatingBackground>
                                  );
                                } else {
                                  ratingElement = (
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                      direction="row"
                                      alignItems="center"
                                      key={k._id}
                                    >
                                      {/* {k?.ratingdef !== undefined && k?.ratingdef !== "" && (
            <div>
              <Typography
                style={{
                  fontSize: "12px",
                  fontFamily: "arial",
                  padding: "5px",
                }}
              >
                <b>{k?.rating_scale}</b> :{k?.ratingdef}
              </Typography>
            </div>
          )} */}

                                      {/* Icon in the center */}



                                      {/* Rating to the right */}
                                      <span>{k?.ratings?.rating}</span>
                                    </Stack>
                                  );
                                }

                                return ratingElement;
                              })}

                            {employeeData?.data?.appraisal_previous_rating?.objective_description?.filter((i: any) => i.ratings).length > 0 && (
                              <Popover
                                id={id_Previous_Rating}
                                open={popoverIndex === index && openPreviousRating}
                                anchorEl={anchorPreviousRatingPopOver}
                                onClose={handlePreviousRatingPopOverClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                PaperProps={{
                                  style: {
                                    backgroundColor: "FEFCF8",
                                    boxShadow: "none",
                                    maxWidth: "400px",
                                    borderRadius: "5px",
                                  },
                                }}
                                sx={{
                                  "& .MuiPopover-paper": {
                                    border: "1px solid #3e8cb5",
                                    backgroundColor: "#ffffff",
                                    // width: "30%",
                                  },
                                }}
                              >
                                <div
                                  style={{
                                    padding: "5px",
                                    fontSize: "12px",
                                    lineHeight: "20px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}
                                >
                                  {<span>Previous Rating:{j.previous_rating}</span>}

                                </div>
                              </Popover>
                            )}
                          </div>
                        </div>
                      </TableCell>

                      {visiblityColumnData?.showAppraiserComments &&
                        (<TableCell
                          width="100px"
                          style={{
                            textAlign: "left",

                            lineHeight: "20px",
                            // background: "#FBFBFB",
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}

                          >
                            <Text>
                              <TextField
                                style={{
                                  backgroundColor:
                                    employeeData?.data?.employee?.employee_status !== "rejected" || employeeData?.data?.appraisal?.status !== "rejected"
                                      ? "#f8f8f8"
                                      : "#ffffff",
                                }}
                                fullWidth
                                multiline
                                autoComplete="off"
                                size="small"
                                placeholder="Add"
                                // name="employeeTrainingComments"
                                inputProps={{ maxLength: 500 }}
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  readOnly: disableTextAfterSubmission
                                }}
                                value={j.comments}
                                onChange={(e) => {
                                  appraiserCommentsHandler(e, j);
                                  setMoveTab(true);
                                  setNavPrompt(true);
                                }}
                              />
                            </Text>

                            {getAttachments(j?.name?._id)?.length >
                              0 && (
                                <AttachFileIcon
                                  style={{
                                    color: "#93DCFA",
                                    height: "18px",
                                    cursor: 'pointer',
                                    transform: "rotate(30deg)",
                                  }}

                                  aria-describedby={"id"}
                                  onClick={(e: any) => {
                                    handleClickOpenAppraiserCommentsAttachment(e, j);
                                    setPopoverIndex(index);
                                  }}
                                />
                              )}
                            <CustomPopOverForTable
                              id={"id"}
                              open={popoverIndex === index && OpenAppraiserCommentsAttachment}
                              anchorEl={anchorAppraiserCommentsAttachment}
                              onClose={handleClosehandleClickOpenAppraiserCommentsAttachment}

                            >

                              <div
                                style={{
                                  padding: "5px",
                                  fontSize: "12px",
                                  lineHeight: "20px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                }}
                              >

                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Arial",
                                    color: "#333333",

                                  }}
                                >
                                  {/* Attachments:{appraisalAttachments} */}
                                  {getAttachments(j?.name?._id)?.map(
                                    (k: any, index1: any) => {
                                      return (
                                        <>
                                          <Stack
                                            spacing={1}
                                            direction="row"
                                            alignItems="center"
                                          >
                                            <Typography
                                              style={{
                                                fontSize: "12px",
                                                fontFamily: "Arial",
                                                color: "#333333",

                                                // maxWidth:"215px",
                                                // wordBreak:"break-all"
                                              }}
                                            >
                                              {index1 + 1}.
                                            </Typography>
                                            <Typography
                                              style={{
                                                fontSize: "12px",
                                                fontFamily: "Arial",
                                                color: "#333333",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow:
                                                  "ellipsis",
                                                width: "170px",
                                              }}
                                            >
                                              {k.resp}
                                            </Typography>
                                            <Stack direction="row">
                                              {/* {disableTextAfterSubmission ?
                                                <img
                                                  style={{ cursor: 'default' }}
                                                  src={Removeattnew}
                                                /> :
                                                <img
                                                  style={{ cursor: 'pointer' }}
                                                  src={Removeattnew}
                                                  onClick={() =>
                                                    deleteAppraiserMutation(
                                                      {
                                                        employee_id:
                                                          employee_id,
                                                        name: k.remove,
                                                      }
                                                    )
                                                  }
                                                />

                                              } */}


                                            </Stack>
                                          </Stack>
                                        </>
                                      );
                                    }
                                  )}
                                </Typography>

                              </div>
                            </CustomPopOverForTable>
                          </Stack>
                        </TableCell>)}


                      {visiblityColumnData?.showAppraiserRejectionReason && (
                        <TableCell width="150px" style={{ textAlign: "left", lineHeight: "20px", background: "#fbfbfb" }}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}

                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontFamily: "Arial",
                                color: "#333333",
                              }}
                            >

                              {((j.rating_rejected || j.rating_resubmitted) && j?.rejection_reason)}


                            </Typography>
                            <Box sx={{ flexGrow: 0, display: "block" }}>
                              {getAppraiserRejectionAttachments(j?.name?._id)?.length > 0 &&
                                (j.rating_rejected == true || j.rating_resubmitted == true) && (j.rejection_reason !== "" && j.rejection_reason !== undefined) &&
                                <AttachFileIcon
                                  style={{
                                    color: "#93DCFA",
                                    height: "18px",
                                    transform: "rotate(30deg)",
                                    cursor: 'pointer'
                                  }}
                                  onClick={(e: any) => {
                                    handleClickOpenAppraiserRejectionReasonAttachment(e)
                                    setPopoverIndex(index)
                                  }}
                                  aria-describedby={"id8"}

                                />}
                            </Box>

                            <CustomPopOverForTable
                              id={idAppraiserRejectionReasonAttachment}
                              open={(popoverIndex === index) && openAppraiserRejectionReasonAttachment}
                              anchorEl={anchorAppraiserRejectionReasonAttachment}
                              onClose={handleClickCloseAppraiserRejectionReasonAttachment}

                            >
                              <div
                                style={{
                                  padding: "5px",
                                  fontSize: "12px",
                                  lineHeight: "20px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                }}
                              >
                                {getAppraiserRejectionAttachments(j?.name._id)?.map((k: any, index1: any) => {
                                  return (
                                    <>
                                      <Stack
                                        spacing={1}
                                        direction="row"
                                        alignItems="center"
                                      >

                                        <Typography
                                          style={{
                                            fontSize: "12px",
                                            fontFamily: "Arial",
                                            color: "#333333",

                                            // maxWidth:"215px",
                                            // wordBreak:"break-all"
                                          }}
                                        >
                                          {index1 + 1}.
                                        </Typography>
                                        <Typography
                                          style={{
                                            fontSize: "12px",
                                            fontFamily: "Arial",
                                            color: "#333333",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "170px"
                                          }}
                                        >
                                          {k.resp}
                                        </Typography>
                                        <Stack direction="row">
                                          {disableTextAfterSubmission ?
                                            <img
                                              style={{ cursor: 'default' }}
                                              src={Removeattnew}
                                            /> :
                                            <img
                                              style={{ cursor: 'pointer' }}
                                              src={Removeattnew}
                                              onClick={() => deleteRejectionAppraiserMutation({
                                                id: employee_id,
                                                name: k.remove,
                                                objective_description: j.name._id
                                              })} />
                                          }

                                          {/* </IconButton> */}
                                        </Stack>

                                      </Stack>
                                    </>
                                  )
                                })}
                              </div>
                            </CustomPopOverForTable>
                            {/* <Popover
                            id={"NormalizerComments"}
                            open={openNormalizerDetails}
                            anchorEl={anchorElNormalizer}
                            onClose={handleCloseNormalizer}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            PaperProps={{
                              style: {
                                backgroundColor: "transparent",
                                boxShadow: "none",
                                borderRadius: 0,
                              },
                            }}
                            sx={{
                              "& .MuiPopover-paper": {
                                border: "1px solid #FFCF7A",
                                backgroundColor: "#f8f8ff",
                              },
                            }}
                          >
                          </Popover> */}


                          </Stack>
                        </TableCell>
                      )}
                      {visiblityColumnData?.showEmployeeRating &&
                        <TableCell>
                          {employeeData &&
                            employeeData?.data?.employee?.objective_description
                              ?.filter(
                                (i: any) => i?.name._id === j?.name?._id
                              )
                              ?.map((k: any) => {
                                if (k?.ratings && k?.rating_rejected == true)
                                  return <RatingBackground style={{ color: "white", background: "#D2122E",textAlign:"center" }}>
                                    {k?.ratings?.rating}
                                  </RatingBackground>;
                              })[0]}
                        </TableCell>
                      }
                      {visiblityColumnData?.showEmployeeRating &&
                        <TableCell>
                          {employeeData &&
                            employeeData?.data?.employee?.objective_description
                              ?.filter(
                                (i: any) => i?.name._id === j?.name?._id
                              )
                              ?.map((k: any) => {
                                if (k?.rating_rejected) return k?.rejection_reason;
                              })[0]}
                        </TableCell>
                      }
                      {visiblityColumnData?.showAppraiserAction &&
                        <TableCell
                          width="30px"
                          style={{ textAlign: "center", background: "#ffffff", padding: "0px 8px" }}
                        >

                          <>
                            {employeeData &&
                              <span>
                                <Stack
                                  direction="row"
                                  justifyContent="center"
                                >

                                  {
                                    <Tooltip title="Edit">
                                      <IconButton
                                        disabled={isLoadingAction || employeeDataIsFetching || employeeDataIsUpdating}
                                        onClick={() => openDrawerHandler(j)}
                                      >
                                        <img
                                          src={Edit}
                                          alt="icon"
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  }

                                </Stack>
                              </span>
                            }

                          </>
                        </TableCell>
                      }
                      {visiblityColumnData?.showNormalizerAction && (
                        <TableCell
                          width="30px"
                          style={{ textAlign: "center", background: "#ffffff", padding: "0px 8px" }}
                        >
                          <>
                            {employeeData &&
                              <span>
                                <Stack
                                  direction="row"
                                  justifyContent="center"
                                >

                                  {
                                    <Tooltip title="Edit">
                                      <IconButton
                                        disabled={isLoadingAction || employeeDataIsFetching || employeeDataIsUpdating}
                                      //  onClick={() => openDrawerHandler(j)}
                                      >
                                        <img
                                          src={Edit}
                                          alt="icon"
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  }

                                </Stack>
                              </span>
                            }

                          </>
                        </TableCell>
                      )}

                    </TableRow >
                  </>
                )
              })}

            </TableBody>
          </Table>
        </TableContainer>
      </div >
    </>
  )
}

export default AppraiserResubmissionRatings