import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel, Radio, RadioGroup, Snackbar, Stack, Typography, } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import DialogWithOkButton from '../../../UI/DialogWithOkButton';
import AlertDialogOkCancelForUnsavedChanges from "../../../UI/DialogforunsavedChangesAlert";
import AlertYesNo from "../../../UI/DialogYesNo";
import { EMPLOYEE_ACCEPTS_AGREED_INFO, EMPLOYEE_ACCEPTS_PA_INFO, EMPLOYEE_REJECTS_DISAGREED_INFO } from "../../../../constants/AppraisalEmailContents/NotificationForInfo/Employee";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useEmployeeRejectSaveMutation } from "../../../../service";
import { EMPLOYEE_LANDING } from "../../../../constants/routes/Routing";
import { useGetEmailIdsQuery, useSendEmailNotificationMutation } from "../../../../service/email/emailNotification";
import { EMPLOYEE_REJECTS_AGREED, EMPLOYEE_REJECTS_DISAGREED } from "../../../../constants/AppraisalEmailContents/NotificationForAction/Employee";
import DialogCircularProgressLoader from "../../../UI/DialogCircularProgressLoader";
import { LOADER_MESSAGE } from "../../../../constants/DialogContents/DialogContents";
import SnackBar from "../../../UI/SnackBar";
const useStyles = makeStyles(({
    heading: {
        color: "#3E8CB5",
        fontWeight: "400",
        fontSize: "28px",
        fontFamily: "Arial",

        // @media Querry for responsive codes
        ['@media (max-width:868px)']: {
            flexWrap: "wrap",
        }
    },
    customAlert: {
        backgroundColor: '#3e8cb5',
        color: "white",
        height: '60px !important',
        alignItems: "center",
        fontSize: "1rem"
    },
    customSnackbar: {
        paddingBottom: '16px',
        paddingRight: '16px',
    },

}));

const EmployeeRejectFooterButtons = (props: any) => {
    const { employeeData,
        disableButtons,
        setDisableButtons,
        isEmployeeDataFetching,
        employeeTrainingRecommendation,
        employeeAreaOfImprovement,
        employeePerformanceGoals,
        tabValue,
        setTabValue,
        updateEmployee,
        employee_id,
        employeeComments,
        oneToOneMeetingDate,
        moveTab,
        setMoveTab,
        navPrompt,
        setNavPrompt,
        objectiveDescription,
        employeeAgreesWithAppraiser,
        setEmployeeAgreesWithAppraiser,
        updateLoggedRole,
        refetchEmployeeData
    } = props;
    const classes = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [openAlertOk, setOpenAlertOk] = useState(false);
    const [tempTabValue, setTempTabValue] = useState(tabValue);
    const [message, setMessage] = useState("");
    const [successAlertTriger, setSuccessAlertTriger] = useState(false);
    const [successAlertTrigerMSG, setSuccessAlertTrigerMSG] = useState("Changes were successfully saved.");
    const [doubleConfirmSelectedOption, setDoubleConfirmSelectedOption] = useState(false)
    const [agreeSelectedOption, setAgreeSelectedOption] = useState(false)
    const [confirmSelectedOption, setConfirmSelectedOption] = useState(false)
    const [openAlertForPeformance, setOpenAlertForPeformance] = React.useState(false);
    const [cancelRatingsAlert, setCancelRatingsAlert] = useState(false)
    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
    const [showLoader, setShowLoader] = useState(false);

    // mutations
    const [employeeRejectionSave] = useEmployeeRejectSaveMutation();
    const { data: emailData } = useGetEmailIdsQuery({ appraiser_code: employeeData?.data?.appraiser_code, reviewer_code: employeeData?.data?.reviewer_code, normalizer_code: employeeData?.data?.normalizer_code })
    const [sendEmailNotification] = useSendEmailNotificationMutation();


    let trainingRecommendationCategoryNotEmpty = employeeTrainingRecommendation?.filter((item: any) => item.name !== "")
        .filter((item: any) => item.justification?.trim() === "" || item.training_name?.trim() === "");
    let trainingRecommendationNameNotEmpty = employeeTrainingRecommendation?.filter((item: any) => item.training_name?.trim() !== "")
        .filter((item: any) => item.name === "" || item.justification?.trim() === "");
    let trainingRecommendationJustificationNotEmpty = employeeTrainingRecommendation?.filter((item: any) => item.justification?.trim() !== "")
        .filter((item: any) => item.name === "" || item.training_name?.trim() === "");


    /****** check if specific area is not empty and specific action is empty ****************/
    let specificAreaNotEmpty = employeeAreaOfImprovement?.filter((item: any) => {
        return item.value?.trim() !== ""
    }).filter((item: any) => {
        return item.specific_actions[0]?.value?.trim() === ""
    });
    /****** check if specific action is not empty and specific area is empty ****************/
    let specificActionNotEmpty = employeeAreaOfImprovement?.filter((item: any) => {
        return item.specific_actions[0]?.value?.trim() !== ""
    }).filter((item: any) => {
        return item?.value?.trim() === ""
    });

    /** if PerformanceGoal category is empty and one of the performanceGoals field is not empty */
    let performanceGoalsCategoryNotEmpty = employeePerformanceGoals?.filter((item: any) => item.goal_category != "")
        .filter((item: any) =>
            item.description?.trim() === "" || item.keyresult?.trim() === "" ||
            item.due_date == "");
    /** if PerformanceGoal description is empty and one of the performanceGoals field is not empty */
    let performanceGoalsDescriptionNotEmpty = employeePerformanceGoals?.filter((item: any) => item.description?.trim() != "")
        .filter((item: any) => item.goal_category === "" || item.keyresult?.trim() === "" ||
            item.due_date == "")
    /** if PerformanceGoal keyresult is empty and one of the performanceGoals field is not empty */
    let performanceGoalskeyResultNotEmpty = employeePerformanceGoals?.filter((item: any) => item.keyresult?.trim() != "")
        .filter((item: any) => item.goal_category === "" || item.description?.trim() === "" ||
            item.due_date == "")
    /** if PerformanceGoal dueDate is empty and one of the performanceGoals field is not empty */
    let performanceGoalsDueDateNotEmpty = employeePerformanceGoals?.filter((item: any) => (item.due_date != "" && item.due_date != undefined))
        .filter((item: any) => item.goal_category === "" || item.description?.trim() === "" ||
            item.keyresult?.trim() == "")
    /** if PerformanceGoal remarks is empty and one of the performanceGoals field is not empty */
    let performanceGoalsRemarksNotEmpty = employeePerformanceGoals?.filter((item: any) => item.remarks?.trim() != "")
        .filter((item: any) => item.goal_category === "" || item.description?.trim() === "" ||
            item.due_date == "");
    //end : common function to check the values and trim

    let trainingRecommendationValues = employeeTrainingRecommendation?.filter((item: any) => item.name !== "")
        ?.map((item: any) => {
            console.log(item, "newitem")
            return {
                ...item,
                training_name: item?.training_name?.trim(),
                justification: item?.justification?.trim(),
            }
        });

    let areaValues = employeeAreaOfImprovement?.filter((item: any) => item.value.trim() != "").map((item: any) => {
        let trimSpecificAction = item.specific_actions?.map((item: any) => {
            return {
                ...item,
                value: item?.value?.trim()
            }
        })
        return {
            ...item,
            value: item?.value?.trim(),
            specific_actions: trimSpecificAction
        }
    });

    let performancegoalValues = employeePerformanceGoals?.filter((item: any) => item.goal_category != "")?.map((item: any) => {
        return {
            ...item,
            goal_category: item.goal_category,
            description: item?.description?.trim(),
            keyresult: item?.keyresult?.trim(),
            due_date: item?.due_date,
            remarks: item?.remarks?.trim()
        }
    });


    const saveAsDraftValidation = () => {
        let status = false;
        let Message = "";
        let TabValue = tabValue;

        if (specificAreaNotEmpty?.length > 0 || specificActionNotEmpty?.length > 0) {
            status = true;
            Message = "Please add the missing details in the Areas for Improvement.";
            TabValue = 1
        } else if (trainingRecommendationCategoryNotEmpty?.length > 0 || trainingRecommendationNameNotEmpty?.length > 0 || trainingRecommendationJustificationNotEmpty?.length > 0) {
            status = true;
            Message = "Please add the missing details in the Training Recommendations.";
            TabValue = 1
        } else if (performanceGoalsCategoryNotEmpty?.length > 0 || performanceGoalsDescriptionNotEmpty?.length > 0 ||
            performanceGoalskeyResultNotEmpty?.length > 0 || performanceGoalsDueDateNotEmpty?.length > 0 ||
            performanceGoalsRemarksNotEmpty?.length > 0) {
            status = true;
            Message = "Please add the missing details in the Performance Goals.";
            TabValue = 2
        } else {
            status = false;
            Message = "";
        }
        return { status, Message, TabValue }
    }

    const saveAsDraftHandler = () => {
        setDisableButtons(true);
        const { status, Message, TabValue } = saveAsDraftValidation();
        if (status == true) {
            setOpenAlertOk(status);
            setMessage(Message);
            setTempTabValue(TabValue);
        } else {
            updateEmployee({
                id: employee_id,
                "employee.employee_status": "draft",
                "appraisal.pa_status": "Pending with Employee",
                "employee.training_recommendation": trainingRecommendationValues,
                "employee.area_of_improvement": areaValues,
                "employee.comments": employeeComments?.trim(),
                "employee.one_to_one_meeting": oneToOneMeetingDate,
                "employee.performance_goal": performancegoalValues,
            }).then((res: any) => {
                if (!res.error) {
                    setMoveTab(false);
                    setNavPrompt(false);
                    setDisableButtons(false);
                    //new alert 
                    setSuccessAlertTrigerMSG("Changes were successfully saved.")
                    setSuccessAlertTriger(true)
                }
            })
        }
    }

    const addPerformanceGoalsAlertHandler = () => {
        saveAsDraftHandler();
        setOpenAlertForPeformance(false)
        setMessage("")
        setDisableButtons(false);
        setMoveTab(false);
        setTabValue(2)
    }

    const closePerformanceGoalsAlertHandler = () => {      
        setOpenAlertForPeformance(false)
        setMessage("")
        saveAndSubmitPA()
    }

    const handleRejectRadioChange = (event: any) => {
        if (event.target.value.toLowerCase() == "true") return setEmployeeAgreesWithAppraiser(true);
        if (event.target.value.toLowerCase() == "false") return setEmployeeAgreesWithAppraiser(false);
        // navigate(`${EMPLOYEE_REJECTS}/employee/${employee_id}`);  
    };  

    const saveAndSubmitPA = () => {
        setShowLoader(true);
        setMessage(LOADER_MESSAGE);
        let employeeObjDesc =
            employeeData?.data?.employee?.objective_description?.map((i: any) => {
                return {
                    ...i,
                    action_performed: false,
                };
            });

        updateEmployee({
            id: employee_id,
            "appraisal.status": "rejected",
            "employee.employee_status": "rejected",
            "employee_previous_submission.performance_goal": performancegoalValues,
            "employee.performance_goal": performancegoalValues,
            "employee.objective_description": employeeObjDesc,
            "employee.comments": employeeComments?.trim(),
            "employee_previous_submission.comments": employeeComments?.trim(),
            "employee.one_to_one_meeting": oneToOneMeetingDate,
            "employee_previous_submission.one_to_one_meeting": oneToOneMeetingDate,
            "employee_previous_submission.objective_description": employeeObjDesc,
            "employee_previous_submission.employee_rating": employeeData?.data?.employee?.employee_rating,
            "appraisal.show_employee": true,
            "appraisal.pa_status": employeeAgreesWithAppraiser ? "Pending with Appraiser (Employee Rejection)" : "Pending with HR Normalizer (Mediation)",
            "appraisal_previous_submission.pa_status": employeeAgreesWithAppraiser ? "Pending with Appraiser (Employee Rejection)" : "Pending with HR Normalizer (Mediation)",
            "appraisal.pa_rating": employeeData?.data?.current_rating?.overall_rating,
            "employee.employee_rating": employeeData?.data?.current_rating?.overall_rating,
            "employee.employee_rejection": true,
            "current_previous_submission.objective_description": employeeData?.data?.current_rating.objective_description,
            "appraisal_previous_submission.objective_description": employeeData?.data?.appraisal?.objective_description,
            "employee.training_recommendation": trainingRecommendationValues,
            "employee_previous_submission.training_recommendation": trainingRecommendationValues,
            "employee.area_of_improvement": areaValues,
            "employee_previous_submission.area_of_improvement": areaValues,
            "employee.employee_PA_rejected": true,
            "employee.selectedToReject": false, /************** to hide Accept button on landing screen if wish to reject ******************/
            // "employee.employee_agree" : employeeAgreesWithAppraiser,
        }).then((res: any) => {
            console.log(employeeAgreesWithAppraiser,'checkemployeeAgreesWithAppraiser')
            if (!res.error) {  
                let employeeName = employeeData?.data?.first_name;
                      
                if ( employeeAgreesWithAppraiser !== undefined) {
                    employeeRejectionSave({
                        id: employee_id,
                        agreeValue: employeeAgreesWithAppraiser
                    }).then((res:any) => refetchEmployeeData() ) 
                    .then((res: any) => navigate(`${EMPLOYEE_LANDING}/employee/${employee_id}`)) ;  ;
                }
                setShowLoader(false);
                setMessage("");
                setNavPrompt(false)               
                updateLoggedRole({
                    pa_action_by: `Employee (Rejected) : ${new Date()}`,
                    pa_history : `Performance Appraisal was rejected by ${employeeName} on ${new Date()} `,
                    id: employee_id
                });
                setDisableButtons(false);

                let appraiserName = employeeData?.data?.appraiser_name;
                let normalizerName = employeeData?.data?.normalizer_name;
                let calendarName = employeeData?.data?.calendar?.calendar_type;
                let calendarYear = employeeData?.data?.calendar?.start_date?.slice(0, 4);
                let empCode = employeeData?.data?.employee_code;
                let normalizerFirstName = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.normalizer_code)?.firstName;
                let appraiserFirstName = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.appraiser_code)?.firstName;
                let employeeComment = employeeData.data.employee.comments;

                if (employeeAgreesWithAppraiser == true) {
                    //  setOpenSubmit(true);
                    //snackbar
                    setSuccessAlertTriger(true)
                    /* changed message as per 6/16  */
                    if (Math.abs(employeeData?.data?.current_rating?.overall_rating - employeeData?.data?.normalizer?.normalized_overallRating) >= 0.3) {
                        // setMessage("The performance appraisal has been submitted to the Appraiser for re-normalization. The Employee’s overall PA rating changed after the PA meeting by more than 0.3 and must be re-normalized by the Appraiser, Reviewer and the HR Normalizer.")
                        //  setMessage("The performance appraisal has been submitted to the Appraiser for re-normalization. Your overall rating was changed by more than 0.3 and must be re-normalized by the Appraiser, Reviewer and the HR Normalizer.")
                        setSuccessAlertTrigerMSG("The performance appraisal has been submitted to the Appraiser for re-normalization. Your overall rating was changed by more than 0.3 and must be re-normalized by the Appraiser, Reviewer and the HR Normalizer.")
                    } else {
                        //  setMessage("The performance appraisal has been submitted to the Appraiser for re-normalization.")
                        setSuccessAlertTrigerMSG("The performance appraisal has been submitted to the Appraiser for re-normalization.")
                    }

                    if (employeeData?.data?.employee?.objective_description?.filter((item: any) => item.rating_rejected)?.length === 0) {
                        // Notification action Employee rejects PA (agreed with Appraiser) (for Appraiser)
                        let tempSubject = EMPLOYEE_ACCEPTS_AGREED_INFO?.subject;
                        tempSubject = tempSubject.replace("[year]", `${calendarYear}`);
                        tempSubject = tempSubject.replace("[calendar name]", `${calendarName}`);
                        tempSubject = tempSubject.replace("[employee name]", `${employeeName}`);
                        tempSubject = tempSubject.replace("[employee code]", `${empCode}`);
                        tempSubject = tempSubject?.replace("[employee comment]", employeeComments ? `Employee Comments : ${employeeComments}` : "");

                        let tempHtml = EMPLOYEE_ACCEPTS_AGREED_INFO?.html;
                        tempHtml = tempHtml?.replace("[year]", `${calendarYear}`);
                        tempHtml = tempHtml?.replace("[calendar name]", `${calendarName}`);
                        tempHtml = tempHtml?.replace("[employee name]", `${employeeName}`);
                        tempHtml = tempHtml?.replace("[Appraiser name]", `${appraiserName}`);
                        tempHtml = tempHtml?.replace("[employee code]", `${empCode}`);
                        tempHtml = tempHtml?.replace("[employee comment]", employeeComments ? `Employee Comments : ${employeeComments}` : "");
                        tempHtml = tempHtml?.replace("[Appraiser FirstName]", `${appraiserFirstName}`);

                        let appraiserEmail = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.appraiser_code)?.email
                        let email = EMPLOYEE_ACCEPTS_AGREED_INFO?.to;
                        email = email?.replace("[email]", `${appraiserEmail}`);

                        sendEmailNotification(
                            {
                                to: email,
                                subject: tempSubject,
                                html: tempHtml
                            }
                        )
                    } else {
                        // Notification action Employee rejects PA (agreed with Appraiser) (for Appraiser)
                        let tempSubject = EMPLOYEE_REJECTS_AGREED?.subject;
                        tempSubject = tempSubject.replace("[year]", `${calendarYear}`);
                        tempSubject = tempSubject.replace("[calendar name]", `${calendarName}`);
                        tempSubject = tempSubject.replace("[employee name]", `${employeeName}`);
                        tempSubject = tempSubject.replace("[employee code]", `${empCode}`);
                        tempSubject = tempSubject?.replace("[employee comment]", ` ${employeeComments}`);


                        let tempHtml = EMPLOYEE_REJECTS_AGREED?.html;
                        tempHtml = tempHtml?.replace("[year]", `${calendarYear}`);
                        tempHtml = tempHtml?.replace("[calendar name]", `${calendarName}`);
                        tempHtml = tempHtml?.replace("[employee name]", `${employeeName}`);
                        tempHtml = tempHtml?.replaceAll("[Appraiser FirstName]", `${appraiserFirstName}`);
                        tempHtml = tempHtml?.replaceAll("[Appraiser name]", `${appraiserName}`);
                        tempHtml = tempHtml?.replace("[employee code]", `${empCode}`);
                        tempHtml = tempHtml?.replace("[employee comment]", `${employeeComments}`);


                        let appraiserEmail = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.appraiser_code)?.email
                        let email = EMPLOYEE_REJECTS_AGREED?.to;
                        email = email?.replace("[email]", `${appraiserEmail}`);

                        sendEmailNotification(
                            {
                                to: email,
                                subject: tempSubject,
                                html: tempHtml
                            }
                        )
                    }

                } else {
                    //  setOpenSubmit(true);
                    // setMessage("The performance appraisal was submitted to the HR Normalizer for the mediation.")
                    //snackbar
                    setSuccessAlertTrigerMSG("The performance appraisal was submitted to the HR Normalizer for the mediation.")
                    setSuccessAlertTriger(true)

                    // Notification action Employee rejects PA (disagreed with Appraiser) (for Normalizer)
                    let tempSubject = EMPLOYEE_REJECTS_DISAGREED?.subject;
                    tempSubject = tempSubject?.replace("[year]", `${calendarYear}`);
                    tempSubject = tempSubject?.replace("[calendar name]", `${calendarName}`);
                    tempSubject = tempSubject?.replace("[employee name]", `${employeeName}`);
                    tempSubject = tempSubject.replace("[employee code]", `${empCode}`);
                    // tempSubject = tempSubject?.replace("[employee comment]", `${employeeComment}`);


                    let tempHtml = EMPLOYEE_REJECTS_DISAGREED?.html;
                    tempHtml = tempHtml?.replace("[year]", `${calendarYear}`);
                    tempHtml = tempHtml?.replace("[calendar name]", `${calendarName}`);
                    tempHtml = tempHtml?.replace("[employee name]", `${employeeName}`);
                    tempHtml = tempHtml.replace("[Appraiser name]", `${appraiserName}`);
                    tempHtml = tempHtml?.replace("[employee code]", `${empCode}`);
                    tempHtml = tempHtml?.replace("[Normalizer FirstName]", `${normalizerFirstName}`);
                    // tempHtml = tempHtml?.replace("[employee comment]", `${employeeComment}`);


                    let normalizerEmail = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.normalizer_code)?.email
                    let email = EMPLOYEE_REJECTS_DISAGREED?.to;
                    email = email?.replace("[email]", `${normalizerEmail}`);

                    sendEmailNotification(
                        {
                            to: email,
                            subject: tempSubject,
                            html: tempHtml
                        }
                    )

                    // Notification info Employee rejects PA (disagreed with Appraiser) - (for Appraiser/Reviewer)
                    let tempSubjectInfo = EMPLOYEE_REJECTS_DISAGREED_INFO?.subject;
                    tempSubjectInfo = tempSubjectInfo?.replace("[year]", `${calendarYear}`);
                    tempSubjectInfo = tempSubjectInfo?.replace("[calendar name]", `${calendarName}`);
                    tempSubjectInfo = tempSubjectInfo?.replace("[employee name]", `${employeeName}`);
                    tempSubjectInfo = tempSubjectInfo?.replace("[employee code]", `${empCode}`);
                    tempSubjectInfo = tempSubjectInfo?.replace("[employee comment]", employeeComments ? `Employee Comments : ${employeeComments}` : "");


                    let tempHtmlInfo = EMPLOYEE_REJECTS_DISAGREED_INFO?.html;
                    tempHtmlInfo = tempHtmlInfo?.replace("[year]", `${calendarYear}`);
                    tempHtmlInfo = tempHtmlInfo?.replace("[calendar name]", `${calendarName}`);
                    tempHtmlInfo = tempHtmlInfo?.replace("[employee name]", `${employeeName}`);
                    tempHtmlInfo = tempHtmlInfo?.replace("[Normalizer name]", `${normalizerName}`);
                    tempHtmlInfo = tempHtmlInfo?.replace("[employee code]", `${empCode}`);
                    tempHtmlInfo = tempHtmlInfo?.replace("[Appraiser name]", `${appraiserName}`);
                    tempHtmlInfo = tempHtmlInfo?.replace("[employee comment]", employeeComments ? `Employee Comments : ${employeeComments}` : "");
                    tempHtmlInfo = tempHtmlInfo?.replace("[Normalizer firstName]", `${normalizerFirstName}`);


                    let appraiserEmail = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.appraiser_code)?.email
                    let reviewerEmail = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.reviewer_code)?.email
                    let employeeEmail = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.employee_code)?.email

                    let emailInfo = EMPLOYEE_REJECTS_DISAGREED_INFO?.to;
                    const recipientEmails = [`${appraiserEmail}`, `${reviewerEmail}`, `${employeeEmail}`]
                    emailInfo = emailInfo?.replace("[email]", `${normalizerEmail}`);
                    sendEmailNotification(
                        {
                            to: emailInfo,
                            cc: recipientEmails,
                            subject: tempSubjectInfo,
                            html: tempHtmlInfo
                        }
                    )
                }
            } else {
                updateLoggedRole({
                    pa_action_by: `${res.error} : ${new Date()}`,
                    id: employee_id
                })
                setMessage("Something Went Wrong.")
                setOpenAlertOk(true)
            }
        })
    }


    const backButtonHandler = () => {
        if (moveTab == true) {
            setOpenAlertOk(true);
            setMessage("Please save the changes before leaving the page.");
            setTempTabValue(tabValue);
        } else {
            if (tabValue == 2) {
                setTabValue(1);
            } else if (tabValue == 1) {
                setTabValue(0);
            }
        }
    };



    const addPerformanceGoals = () => {
        if ((employeePerformanceGoals?.length == 0 || (employeePerformanceGoals?.filter((performanceGoalsItem: any) =>
            performanceGoalsItem?.description !== "")?.length == 0))) {
            setOpenAlertForPeformance(true)
            setMessage("Would you like to add performance goals for the next period?")
        } else {
            saveAndSubmitPA()
        }
    };

    const handleAgreeSelectedOptionYes = () => {
        // saveAsDraftHandler();
        setDoubleConfirmSelectedOption(true)
        setAgreeSelectedOption(false);
        addPerformanceGoals();
    }

    const handleAgreeSelectedOptionNo = () => {
        // saveAsDraftHandler()
        setAgreeSelectedOption(false);
        setConfirmSelectedOption(true);
    }

    const handleCloseConfirmSelectedOption = () => {
        console.log(employeeAgreesWithAppraiser, 'cehckemployeeAgreesWithAppraiser')     
        setConfirmSelectedOption(false);
        console.log(employeeAgreesWithAppraiser, 'cehckemployeeAgreesWithAppraiser1')
        updateEmployee({
            "employee.employee_agree": employeeAgreesWithAppraiser,
            id: employee_id,
        }).then((res: any) => {
            if (!res.error) {
                addPerformanceGoals();
            }
        })
    }


    const saveAndSubmitValidation = () => {
        let submitStatus = false;
        let submitMessage = "";
        let submitTabValue = tabValue;

        const RejectChanges = objectiveDescription
            .map((i: any) => {
                return i.rating_rejected;
            })
            .find((elements: any) => {
                return elements === true;
            });
        let appraiserRejectedRatings = employeeData?.data?.appraisal?.objective_description?.filter((item: any) => {
            return item.rating_rejected == true
        }).map((item: any) => item?.name?._id);
        let actionPerformed = objectiveDescription?.filter((item: any) => {
            return appraiserRejectedRatings?.includes(item?.name?._id) && item?.action_performed == false
        });

        if (oneToOneMeetingDate == "" || oneToOneMeetingDate == undefined) {
            submitStatus = true;
            submitMessage = "Please select One-to-One Meeting Date in the overall feedback.";
            submitTabValue = 1
        } else if ((employeeComments?.trim() === "" || employeeComments?.trim() === undefined)) {
            submitStatus = true;
            submitMessage = "It is mandatory to add employee comments in the overall feedback.";
            submitTabValue = 1
        } else if (employeeData?.data?.appraisal?.appraiser_status == "appraiser-rejected-employee" && actionPerformed?.length > 0) {
            submitStatus = true;
            submitMessage = "You cannot submit the performance appraisal. Please accept or reject the ratings.";
        } else {
            submitStatus = false;
            submitMessage = "";
        }
        return { submitStatus, submitMessage, submitTabValue }
    }



    const saveAndSubmitHandler = () => {
        setDisableButtons(true);
        const { status, Message, TabValue } = saveAsDraftValidation();
        
        const { submitStatus, submitMessage, submitTabValue } = saveAndSubmitValidation();

        if (status == true) {
            saveAsDraftHandler();
            setOpenAlertOk(status);
            setMessage(Message);
            setTempTabValue(TabValue);
        }
        else {
            if (submitStatus) {
                saveAsDraftHandler();
                setOpenAlertOk(submitStatus);
                setMessage(submitMessage);               
                setTempTabValue(submitTabValue);
            } else {
                if (employeeData?.data?.appraisal?.status !== "rejected") {
                    if (!doubleConfirmSelectedOption) {
                        setAgreeSelectedOption(true);
                    } else {
                        addPerformanceGoals();
                    }
                }
                else {
                    addPerformanceGoals();
                }
            }
        }
    }

    const handleCloseAlertOk = () => {
        setOpenAlertOk(false);
        setMessage("");
        setTabValue(tempTabValue);
        setDisableButtons(false);
    }

    const handleCloseSnackbar = () => {
        setSuccessAlertTriger(false)
        setSuccessAlertTrigerMSG("")
    }

    const acceptCancelButtonHandler = () => {
        setOpenCancelDialog(false);
        setMoveTab(false);
        setNavPrompt(false);
        refetchEmployeeData().then((res: any) => setTabValue(0));
    }

    const rejectCancelButtonHandler = () => {
        setOpenCancelDialog(false);
    }


    const cancelButtonHandler = () => {
        if (tabValue == 0) {
            let employeeActionPerformed = objectiveDescription?.filter((item: any) => item.action_performed)
            if (employeeActionPerformed?.length > 0) {
                setCancelRatingsAlert(true);
            } else if (moveTab || navPrompt) {
                setCancelRatingsAlert(true);
            } else {
                updateEmployee({
                    id: employee_id,
                    "employee.selectedToReject": false   /************** to display Accept button on landing screen if do not wish to reject ******************/
                }).then((res: any) => navigate(`${EMPLOYEE_LANDING}/employee/${employee_id}`))
            }
        } else {
            if (navPrompt == true || moveTab == true) {
                setOpenCancelDialog(true);
            } else if (tabValue == 1) {
                setOpenCancelDialog(false);
                setTabValue(0);
            } else if (tabValue == 2) {
                setOpenCancelDialog(false);
                setTabValue(1);
            }
        }
    }

    const handleCloseCancelRatingsAlert = () => {
        setCancelRatingsAlert(false);
        setMessage("")
    }

    const handleCancelRatingsAlert = () => {
        setMoveTab(false);
        setNavPrompt(false);
        setCancelRatingsAlert(false);
        let previousData = {
            appraisal: employeeData?.data?.appraisal_previous_submission?.objective_description,
            employee: employeeData?.data?.employee_previous_submission?.objective_description?.length == 0 ?
                employeeData?.data?.current_previous_submission.objective_description :
                employeeData?.data?.employee_previous_submission.objective_description,
            current: employeeData?.data?.current_previous_submission.objective_description,
            overallRating: employeeData?.data?.current_previous_submission?.overall_rating
        }

        updateEmployee({
            "appraisal.objective_description": previousData?.appraisal,
            "employee.objective_description": previousData?.employee,
            "current_rating.objective_description": previousData?.current,
            "current_rating.overall_rating": previousData?.overallRating,
            "employee.selectedToReject": false, /************** to display Accept button on landing screen if do not wish to reject ******************/
            id: employee_id,
        })
            .then((res: any) => {
                navigate(`${EMPLOYEE_LANDING}/employee/${employee_id}`);
            })
    }


    return (
        <>
            <Stack
                direction="row"
                spacing={2}
                style={{
                    margin: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {employeeData?.data?.appraisal?.pa_status?.includes("Pending with Employee") &&
                    <>
                        <Button
                            disabled={disableButtons || isEmployeeDataFetching}
                            variant="outlined"
                            style={{
                                borderRadius: "4px",
                                textTransform: "none",
                                fontSize: "15px",
                                fontFamily: "Arial",
                                borderColor: "#3E8CB5",
                                color: "#3E8CB5",
                                height: "35px",
                                ...((disableButtons || isEmployeeDataFetching) && {
                                    cursor: "not-allowed",
                                    borderColor: "#ccc",
                                    color: "#ccc",
                                }),
                                background: "transparent",
                            }}
                            onClick={() => {
                                saveAsDraftHandler()
                            }}
                        >
                            Save as Draft
                        </Button>
                        <Button
                            disabled={disableButtons || isEmployeeDataFetching}
                            style={{
                                borderRadius: "4px",
                                textTransform: "none",
                                fontSize: "15px",
                                fontFamily: "Arial",
                                borderColor: "#3E8CB5",
                                color: "#3E8CB5",
                                background: "transparent",
                                height: "35px",
                                ...((disableButtons || isEmployeeDataFetching) && {
                                    cursor: "not-allowed",
                                    borderColor: "#ccc",
                                    color: "#ccc",
                                }),
                                // width: "70px",
                            }}
                            variant="outlined"
                            //onClick={addPerformanceGoals}
                            onClick={() => {
                                saveAndSubmitHandler()
                            }}
                        >
                            Save and Submit
                        </Button>

                        {tabValue !== 0 && (<Button
                            disabled={disableButtons || isEmployeeDataFetching}
                            style={{
                                borderRadius: "4px",
                                textTransform: "none",
                                fontSize: "15px",
                                fontFamily: "Arial",
                                borderColor: "#3E8CB5",
                                color: "#3E8CB5",
                                background: "transparent",
                                height: "35px",
                                width: "70px",
                                ...((disableButtons || isEmployeeDataFetching) && {
                                    cursor: "not-allowed",
                                    borderColor: "#ccc",
                                    color: "#ccc",
                                }),
                            }}
                            variant="outlined"
                            onClick={() => {
                                // saveRecommendationsHandler();
                                backButtonHandler();
                            }}
                        >
                            Back
                        </Button>)}

                        <Button
                            disabled={disableButtons || isEmployeeDataFetching}
                            style={{
                                borderRadius: "4px",
                                textTransform: "none",
                                fontSize: "15px",
                                fontFamily: "Arial",
                                borderColor: "#3E8CB5",
                                color: "#3E8CB5",
                                background: "transparent",
                                height: "35px",
                                width: "70px",
                                ...((disableButtons || isEmployeeDataFetching) && {
                                    cursor: "not-allowed",
                                    borderColor: "#ccc",
                                    color: "#ccc",
                                }),
                            }}
                            variant="outlined"
                            onClick={() => {
                                // saveRecommendationsHandler();
                                cancelButtonHandler();
                            }}
                        >
                            Cancel
                        </Button>
                    </>
                }

            </Stack>

            <DialogWithOkButton
                isAlertOpen={openAlertOk}
                handleAlertClose={handleCloseAlertOk}
            >
                {message}
            </DialogWithOkButton>

            <Dialog
                fullScreen={fullScreen}
                open={confirmSelectedOption}
                // onClose={handleClose4}
                aria-labelledby="responsive-dialog-title"
                // sx={{
                //   "& .MuiDialog-container": {
                //     "& .MuiPaper-root": {
                //       width: "40%",
                //       margin: "auto",
                //       textAlign: "center",
                //       minHeight: "25%",
                //       // paddingTop: "25px",
                //     },
                //   },
                // }}
                // BackdropProps={{ style: { background: "#333333 !important", opacity: "10%" } }}
                PaperProps={{
                    style: {
                        // borderColor:'blue',
                        //border:'1px solid',
                        boxShadow: "none",
                        borderRadius: "6px",
                        //marginTop: "155px",
                        maxWidth: "0px",
                        minWidth: "26%",
                        margin: "0px",
                        padding: "30px",
                        // maxHeight:"30%"
                        // display: "flex",
                        // justifyContent: "center",
                        // alignItems: "center",
                        // textAlign: "center",
                    },
                }}
            >
                <DialogContent>
                    <DialogContentText
                        style={{
                            color: "#333333",
                            fontSize: "14px",
                            fontFamily: "Arial",
                            // paddingBottom: "12px",
                            // paddingRight: "10px",
                            // paddingLeft: "10px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "justify",
                            wordBreak: "break-word",
                            // height: "100px",
                            alignItems: "center",
                            overflowY: "hidden",
                        }}
                    >
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={employeeData?.data?.employee?.employee_agree}
                                name="radio-buttons-group"
                                onChange={(e) => {
                                    handleRejectRadioChange(e);
                                }}
                            >
                                <FormControlLabel
                                    // style={{
                                    //   color: "#333333",
                                    //   fontSize: "14px",
                                    //   fontFamily: "Arial",
                                    // }}
                                    sx={{
                                        "& .MuiFormControlLabel-label": {
                                            fontSize: "14px",
                                            color: "#333333",
                                            fontFamily: "Arial",
                                        },
                                    }}
                                    value={true}
                                    control={<Radio size="small" />}
                                    label="I met the Appraiser and agreed on changing the ratings"
                                />
                                <FormControlLabel
                                    sx={{
                                        "& .MuiFormControlLabel-label": {
                                            fontSize: "14px",
                                            color: "#333333",
                                            fontFamily: "Arial",
                                        },
                                    }}
                                    value={false}
                                    // onChange={(e) => {
                                    //   handleRejectRadioChange(e);
                                    // }}
                                    control={<Radio size="small" />}
                                    label="I met the Appraiser and disagreed on the ratings"
                                />
                            </RadioGroup>
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
                <div style={{ alignItems: "center" }}>
                    <DialogActions
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            style={{
                                textTransform: "none",
                                fontSize: "15px",
                                fontFamily: "Arial",
                                borderColor: "#3E8CB5",
                                color: "#3e8cb5",
                                // marginRight: "10px",
                                background: "transparent",
                                width: "70px",
                                height: "35px",
                            }}
                            variant="outlined"
                            autoFocus
                            onClick={() => { handleCloseConfirmSelectedOption() }}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>

            <AlertYesNo
                isAlertOpen={agreeSelectedOption}
                handleAlertYes={handleAgreeSelectedOptionYes}
                handleAlertClose={handleAgreeSelectedOptionNo}
            >
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"

                    >
                        {employeeData?.data?.employee?.employee_agree == true && (
                            <Typography
                                style={{
                                    color: "#333333",
                                    fontSize: "14px",
                                    fontFamily: "Arial",
                                    // paddingBottom: "12px",
                                    // paddingRight: "10px",
                                    // paddingLeft: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    wordBreak: "break-word",
                                    // height: "100px",
                                    alignItems: "center",
                                }}

                            >Are you sure you wish to proceed with the agreed changes?</Typography>
                        )}

                        {employeeData?.data?.employee?.employee_agree == false && (
                            <Typography style={{
                                color: "#333333",
                                fontSize: "14px",
                                fontFamily: "Arial",
                                // paddingBottom: "12px",
                                // paddingRight: "10px",
                                // paddingLeft: "10px",
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                                wordBreak: "break-word",
                                // height: "100px",
                                alignItems: "center",
                            }}>Are you sure you wish to reject the ratings and proceed with mediation?</Typography>

                        )}

                    </RadioGroup>
                </FormControl>
            </AlertYesNo>

            {/* Any changes you have made will not be saved if you leave the page************ */}
            <AlertDialogOkCancelForUnsavedChanges
                isAlertOpen={cancelRatingsAlert}
                handleAlertClose={handleCloseCancelRatingsAlert}
                handleAlertIdClose={handleCancelRatingsAlert}
            >
            </AlertDialogOkCancelForUnsavedChanges>

            <AlertYesNo
                isAlertOpen={openAlertForPeformance}
                handleAlertYes={addPerformanceGoalsAlertHandler}
                handleAlertClose={closePerformanceGoalsAlertHandler}
            >
                {message}
            </AlertYesNo>

            <AlertDialogOkCancelForUnsavedChanges
                isAlertOpen={openCancelDialog}
                handleAlertClose={rejectCancelButtonHandler}
                handleAlertIdClose={acceptCancelButtonHandler}
            >
            </AlertDialogOkCancelForUnsavedChanges>

            <DialogCircularProgressLoader
            isAlertOpen = {showLoader}>
                {message}
            </DialogCircularProgressLoader>

            {/* <Snackbar
                className={classes.customSnackbar}
                open={successAlertTriger}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    className={classes.customAlert}
                    onClose={handleCloseSnackbar}
                    sx={{ width: '100%' }}
                    icon={false}
                >
                    <b>{successAlertTrigerMSG}</b>
                </Alert>
            </Snackbar> */}
             <SnackBar
      successAlertTriger={successAlertTriger}
      successAlertTrigerMSG={successAlertTrigerMSG}
      handleCloseSnackbar={handleCloseSnackbar}
      >
        {/* {message} */}
      </SnackBar>
        </>
    )
}

export default EmployeeRejectFooterButtons

