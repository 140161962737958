import * as React from "react";
import {Breadcrumbs } from "@mui/material";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useGetActiveCalenderQuery, useGetEmployeeByFilterQuery, useGetReviewerEmployeeByFilterQuery } from "../../../../service";
import FilteredTableofExpNinebox from "../../../../components/Dashboard_Reusable_Component/FilteredTableofExpNinebox";
import { useLoggedInUser } from "../../../../hooks/useLoggedInUser";
import { useState, useEffect } from "react";

export default function NineboxparentForMyteamDashboard(props: any) {
  
  const location: any = useLocation();
  const tabLocation = location?.state?.selectName;
   console.log(location, "tabLocation");
  const navigationFrom =location?.state?.navigationFrom
  const { data: user } = useLoggedInUser();
  const navigate = useNavigate();
  const { data: activecalendardata, isLoading: isTitleLoading } = useGetActiveCalenderQuery('')
  const [employee, setEmployee] = React.useState<any>(`636cb1a7960b1548b80ff785`);
  const [appCalId, setappCalId] = React.useState<any>(`636cb1a7960b1548b80ff785`);
  const SELECT_FOR_DASHBOARD = `employee_code,legal_full_name,position_long_description,grade,
  division,appraiser_name,reviewer_name,normalizer_name,appraisal.potential,manager_code,manager_name,manager_position,work_location,
  section,sub_section,appraisal.appraiser_rating,reviewer.reviewer_rating,first_name,
  normalizer.normalizer_rating,appraisal.status,overall_rating,talent_category, service_reference_date,isSupervisor,email,function,
  appraisal.appraiser_status,reviewer.reviewer_status,normalizer.normalizer_status,reviewer.rejection_count,
  appraisal.objective_description,reviewerIsDisabled,pa_status,employee.employee_rating, workflow_history`
  React.useEffect(() => {
    if (activecalendardata?.data[0]?._id !== undefined) {
      setappCalId(activecalendardata?.data[0]?._id)
    }

  }, [activecalendardata])
  const { data: employeeData } = useGetEmployeeByFilterQuery(
    `?appraiser_code=${user?.employee_code}&calendar=${appCalId}&limit=800&select=${SELECT_FOR_DASHBOARD}`
  );
  const [users, setUsers] = useState<any>([]);
  const [enteredName, setenteredName] = useState("");
  const [hideEmployeeRating, sethideEmployeeRating] = React.useState<any>(false);
  useEffect(()=>{
    setEmployee(location?.state?.indexBasedValue);
   },[location])
  //getting employee details
  useEffect(() => {
  const employeeDetails = employeeData?.data?.filter((i:any)=>{
    return i?.legal_full_name == tabLocation
  })
  setUsers(employeeDetails);
  }, [employeeData])
  return (
    <div
    style={{
      background: "#F1F1F1",
      height: "auto",
    }}
  >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
          minHeight="50px"
          marginLeft="25px"
        >
          {navigationFrom == "Appraiser" &&
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/dashboardreview"}
            >
             My Team Dashboard
            </Link>
          {(location?.state?.indexBasedTitle == "" || location?.state?.indexBasedTitle 
         ==  undefined) &&  
           <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
              color="text.primary"
            >
         9-Box Grid
           </Typography>
          } 
             {location?.state?.indexBasedTitle !== ""  &&  
           <Typography
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
                cursor:"pointer"
              }}
              color="text.primary"
              onClick={()=>{navigate(-1)}}
            >
         9-Box Grid
           </Typography>
          } 
           {location?.state?.indexBasedTitle !== ""  && 
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
              color="text.primary"
            >
             {/* {tabLocation} */}
             {location?.state?.indexBasedTitle }

            </Typography>}
          </Breadcrumbs>}
          {navigationFrom == "Reviewer" &&
            <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/Reviewer"}
              state={{
                from: "toMyTeamDashboard",
               }}
            >
              My Team Dashboard
            </Link>
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
              color="text.primary"
            >
             {/* {tabLocation} */}
             {  location?.state?.indexBasedTitle == "" || location?.state?.indexBasedTitle ==  undefined ? "9-Box Grid" : location?.state?.indexBasedTitle }

            </Typography>
          </Breadcrumbs>}
          {navigationFrom == "Normalizer" &&
              <Breadcrumbs aria-label="breadcrumb">
                     <Link
                style={{
                  fontSize: "18px",
                  color: "#3e8cb5",
                  fontFamily: "Arial",
                }}
                color="inherit"
                to={"/normalizer"}
                state={{
                  from: "toMyTeamDashboard",
                 }}
              >
                My Team Dashboard
              </Link>
              
              <Typography
                style={{
                  fontSize: "18px",
                  color: "#333333",
                  fontFamily: "Arial",
                }}
                color="text.primary"
              >
               { location?.state?.indexBasedTitle == "" || location?.state?.indexBasedTitle ==  undefined ? "9-Box Grid" : location?.state?.indexBasedTitle }
  
              </Typography>
            </Breadcrumbs>}
        </Stack>
      <div >
      <FilteredTableofExpNinebox
      EmployeeData = {users}
      hideEmployeeRating={hideEmployeeRating}
      AllEmployee={location?.state?.indexBasedValue}
      />
      </div>
    </div>
  );
}
