import React from 'react'
import AppraiserResubmission from '../../../components/appraisalNew/appraiser/appraiserResubmission/AppraiserResubmission'
import ProvidedAppraiserContextProvider from '../../../context/appraisal/AppraiserContext'

const AppraiserResubmissionPage = () => {
  return (
    <ProvidedAppraiserContextProvider>
   <>
   <AppraiserResubmission/>
   </>
   </ProvidedAppraiserContextProvider>
  )
}

export default AppraiserResubmissionPage