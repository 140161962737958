import * as React from "react";
import { useEffect, useState } from "react";
import { styled } from "@mui/system";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Popover,
  Typography,
  Button,
} from "@mui/material";
import Blueadd from "../../../assets/Images/Blueadd.svg";
import Blueminus from "../../../assets/Images/Blueminus.svg";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { IconButton } from "@mui/material";
import { useAppraisalContext } from "../../../context/appraiserOverviewContext";
import Infoicon from "../../../assets/Images/Infoicon.svg";
import { useUpdateEmployeeAppraisalMutation } from "../../../service";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPerformanceGoalsQuery } from "../../../service/performanceGoals/performanceGoals";



const Tf3 = styled("div")({
  borderRadius: "5px",
  backgroundColor: "#f8f8f8",
  "& .MuiInputBase-input": {
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "none",
    fontFamily: "Arial",
    color: "#333333",
    padding: "8px",
    textAlign: "left"
  },
});

// const Contain = styled("div")({
//   marginTop: "10px",
// });

const PerformanceGoalsHeader = styled("div")({
  // marginLeft: "25px",
  marginBottom: "10px",
  paddingTop:"10px",
  color: "#717171",
  fontSize: "16px",
  fontFamily: "arial",
  //opacity: 0.85
});


const Train = styled("div")({
  "& .MuiTextField-root": {
    // color: "rgb(51 51 51/50%)",
    fontSize: "14px",
    color: "#333333",
    textTransform: "none",
    fontFamily: "Arial",
    backgroundColor: "#f8f8f8",
    borderRadius: "5px",
    padding: "8px",
    // paddingRight:"0px",
    width: "96%"

  },
  "& .MuiInputBase-input": {
    color: "#333333",
    fontSize: "14px",
    fontFamily: "Arial",
    textAlign: "left",
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#f8f8f8",
    padding: "3px",
  },
  "& .MuiOutlinedInput-root": {
    maxWidth: "0px",
    minWidth: "100%",
    "& fieldset": {
      borderColor: "#f8f8f8",
    },
    "&:hover fieldset": {
      borderColor: "#f8f8f8",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#f8f8f8",
    },
  },
});

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
      fontSize: "14px !important",
      fontFamily: "arial !important",
      color: "#333333 !important",
    },
  },
};


const PerformanceGoals = (props: any) => {
  const { NAVIGATION_FROM,setnavPrompt, setMoveTab, disableTextAfterSubmission, empData, setPerformanceGoals, employeeDataIsLoading,employeeDataIsFetching } = props;
  //@ts-ignore
  // const { setMoveTab, disableTextAfterSubmission, empData, setPerformanceGoals, employeeDataIsLoading,employeeDataIsFetching
  // } = useAppraisalContext();
  const { employee_id } = useParams();

  /********************************** mutations*********************************/

  const { data: goalsCategoryData } = useGetPerformanceGoalsQuery("")

  const [updateObjectiveDescription] = useUpdateEmployeeAppraisalMutation(); 
  

  /*********************** states ***************************************************/
  const [PerformanceGoalsFieldValues, setPerformanceGoalsFieldValues] = useState([
    { goal_category: "", description: "", keyresult: "", due_date: "", remarks: "" },    
  ]); /****************** these are the name property of the fields ****************/
  const [anchorEls, setAnchorEls] = React.useState<HTMLButtonElement | null>(null);
  const openGoalsCategoryInfo = Boolean(anchorEls);
  const openGoalsCategoryInfoId = openGoalsCategoryInfo ? "simple-popover" : undefined;
  const [popoverIndex, setPopoverIndex] = useState<any>("");



  useEffect(() => {
    /**************** To add an empty field if the fields are not there ********************/
    if (PerformanceGoalsFieldValues?.length == 0) {
      addPerformanceGoalFields()
    }
  }, [PerformanceGoalsFieldValues])


  useEffect(() => {
    if (empData) {
      setPerformanceGoalsFieldValues(() => {
        return empData?.data?.appraisal?.performance_goal?.map((j: any) => {
          return {
            goal_category: j?.goal_category?._id,
            description: j?.description,
            keyresult: j?.keyresult,
            due_date: j?.due_date?.slice(0, 10),
            remarks: j?.remarks
          };
        });
      });
    }
  }, [empData]);

  useEffect(() => {
    if (PerformanceGoalsFieldValues)
    setPerformanceGoals(PerformanceGoalsFieldValues)
  },[PerformanceGoalsFieldValues])


  const handlePerformanceGoalsChange = (i: any, e: any) => {
    setnavPrompt(true);
    const newGoalValues = [...PerformanceGoalsFieldValues];
    //@ts-ignore
    newGoalValues[i][e.target.name] = e.target.value; /********* based on the name property respective values getting added ************/
    setPerformanceGoalsFieldValues(newGoalValues);
  };

  const addPerformanceGoalFields = () => {
    setPerformanceGoalsFieldValues([
      ...PerformanceGoalsFieldValues,
      { goal_category: "", description: "", keyresult: "", due_date: "", remarks: "" }, /****************** these are the name property of the fields ****************/
    ]);
  };

  const removePerformanceGoalFields = (i: any) => {
    setnavPrompt(true);
    const newGoalValues = [...PerformanceGoalsFieldValues];
    newGoalValues.splice(i, 1);
    setPerformanceGoalsFieldValues(newGoalValues);
  };

  const handleClickOpenGoalCategoryInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEls(event.currentTarget);
  };

  const handleClickCloseGoalCategoryInfo = () => {
    setAnchorEls(null);
  };

  // const saveHandler = () => {
  //   let performancegoalValues = PerformanceGoalsFieldValues?.map((item: any) => {
  //     return {
  //       ...item,
  //       goal_category: item.goal_category,
  //       description: item?.description?.trim(),
  //       keyresult: item?.keyresult?.trim(),
  //       due_date: item?.due_date,
  //       remarks: item?.remarks?.trim()
  //     }
  //   });
  //   updateObjectiveDescription({
  //     "appraisal.performance_goal": performancegoalValues,
  //     id: employee_id,
  //   })
  // }

  
  return (
    // <div style={{ marginBottom: "50px", marginTop: "30px" }}>
    <>
    {NAVIGATION_FROM == "Appraiseraction" ? 
    <div style={{marginRight : "-30px"}}> 
     <PerformanceGoalsHeader>
     <b>
     Performance Goals (Appraiser)
     </b>
   </PerformanceGoalsHeader>



   <Table size="small">
     <TableHead>
       <TableRow
         sx={{
           "& td, & th": {
             border: "1px solid #e0e0e0",
             bgcolor: "#eaeced",
             whiteSpace: "nowrap",
           },
         }}
       >
         <TableCell
           align="center"
           // width="30% !important"
           // width= "20%",
           style={{
             border: "1px solid #e0e0e0",
             fontFamily: "Arial",
             color: "#3E8CB5",
             fontSize: "14px",
             fontWeight: "600",
             width: "13%",
           }}
         >
           Goal Category
         </TableCell>
         <TableCell
           align="center"
           // width="36%"
           style={{
             border: "1px solid #e0e0e0",
             fontFamily: "Arial",
             color: "#3E8CB5",
             fontSize: "14px",
             fontWeight: "600",
             width: "25%",
           }}
         >
           Description
         </TableCell>
         <TableCell
           align="center"
           // width="36%"
           style={{
             border: "1px solid #e0e0e0",
             fontFamily: "Arial",
             color: "#3E8CB5",
             fontSize: "14px",
             fontWeight: "600",
             width: "20%",
           }}
         >
           Key Result/Target
         </TableCell>
         <TableCell
           align="center"
           // width="36%"
           style={{
             border: "1px solid #e0e0e0",
             fontFamily: "Arial",
             color: "#3E8CB5",
             fontSize: "14px",
             fontWeight: "600",
             width: "15%",
           }}
         >
           Due Date
         </TableCell>
         <TableCell
           align="center"
           // width="36%"
           style={{
             border: "1px solid #e0e0e0",
             fontFamily: "Arial",
             color: "#3E8CB5",
             fontSize: "14px",
             fontWeight: "600",
             width: "30%",
           }}
         >
           Remarks
         </TableCell>
       </TableRow>
     </TableHead>


     {PerformanceGoalsFieldValues?.map((element: any, index: any) => {
       const selectedDefinition = goalsCategoryData?.data?.find(
         (item: any) => item?._id === element?.goal_category
       )?.definition; 
       return (
       <>
         <TableBody>
           <TableRow
             sx={{
               "& td, & th": {
                 border: "1px solid #e0e0e0",
                 whiteSpace: "nowrap",
               },
             }}
           >
             <TableCell
               // width="30% !important"
               style={{
                 width: "13%",
               }}
             >
               <Train>
                 <Stack
                   direction={"row"}
                   alignItems="center"
                   justifyContent="center"
                   spacing={1}
                 >
                   {element?.goal_category != "" && (
                     <IconButton
                       style={{ padding: "0px" }}
                       onClick={(e: any) => {
                         handleClickOpenGoalCategoryInfo(e)
                         setPopoverIndex(index);
                       }}
                     >
                       <img width="12px" src={Infoicon} alt="icon" />
                     </IconButton>
                   )}
                   <Select
                     sx={{
                       boxShadow: "none",
                       ".MuiOutlinedInput-notchedOutline": { border: 0 },
                       background: "#f8f8f8",
                       // minWidth:"338px",
                       // maxWidth:"0px"
                     }}
                     fullWidth
                     autoComplete="off"
                     // label="Add  Name"
                     // label="Select"
                     placeholder="Select"
                     size="small"
                     name="goal_category"
                     displayEmpty
                     MenuProps={MenuProps}
                     value={element?.goal_category || ""}                      
                     renderValue={
                       element?.goal_category || ""
                         ? undefined
                         : () => <div style={{ color: "#aaa" }}>Select</div>
                     }
                     onChange={(e) => {
                       handlePerformanceGoalsChange(index, e);
                       setMoveTab(true);
                     }}
                     disableUnderline
                   >
                     {goalsCategoryData?.data?.length > 0 &&
                       goalsCategoryData?.data?.map((categoryItem: any) => {
                         return (
                           <MenuItem
                             style={{
                               fontSize: "14px",
                               fontFamily: "arial",
                               color: "#333333",
                             }}
                             value={categoryItem?._id}
                             disabled={disableTextAfterSubmission || employeeDataIsLoading || employeeDataIsFetching}
                           >
                             {categoryItem?.title}
                           </MenuItem>
                         );
                       })}
                   </Select>



                   <Popover
                     id={openGoalsCategoryInfoId}
                     open={(popoverIndex === index) && openGoalsCategoryInfo}
                     anchorEl={anchorEls}
                     onClose={handleClickCloseGoalCategoryInfo}
                     anchorOrigin={{
                       vertical: "bottom",
                       horizontal: "left",
                     }}
                     transformOrigin={{
                       vertical: "top",
                       horizontal: "left",
                     }}
                     PaperProps={{
                       style: {
                         backgroundColor: "FEFCF8",
                         boxShadow: "none",
                         maxWidth: "400px",
                         borderRadius: "5px",
                       },
                     }}
                     sx={{
                       "& .MuiPopover-paper": {
                         border: "1px solid #3e8cb5",
                         backgroundColor: "#ffffff",
                       },
                     }}
                   >
                     <Typography
                       style={{
                         fontSize: "12px",
                         fontFamily: "arial",
                         padding: "5px",
                       }}
                     >                         
                       {selectedDefinition}
                     </Typography>
                   </Popover>
                 </Stack>
               </Train>
             </TableCell>
             <TableCell
             style={{
               width: "25%",
             }}>
               <Tf3>
                 <TextField
                 disabled = {disableTextAfterSubmission || employeeDataIsLoading || employeeDataIsFetching}
                   fullWidth
                   multiline
                   autoComplete="off"
                   placeholder="Add"
                   size="small"
                   name="description"
                   value={element.description || ""}
                   onChange={(e) => {
                     handlePerformanceGoalsChange(index, e);
                     setMoveTab(true);
                   }}
                   // inputProps={{ maxLength: 500 }}
                   variant="standard"
                   InputProps={{
                     disableUnderline: true,
                     // readOnly: disableTextAfterSubmission
                   }}
                 />
               </Tf3>
             </TableCell>
             <TableCell
             style={{
               width: "20%",
             }}>
               <Tf3>
                 <TextField
                 disabled = {disableTextAfterSubmission || employeeDataIsLoading || employeeDataIsFetching}
                   fullWidth
                   multiline
                   placeholder="Add"
                   autoComplete="off"
                   size="small"
                   name="keyresult"
                   id="outlined-select-select"
                   // inputProps={{ maxLength: 500 }}
                   value={element.keyresult || ""}
                   onChange={(e) => {
                     handlePerformanceGoalsChange(index, e);
                     setMoveTab(true);
                   }}
                   variant="standard"
                   InputProps={{
                     disableUnderline: true,
                     readOnly: disableTextAfterSubmission
                   }}
                 ></TextField>
               </Tf3>
             </TableCell>
             <TableCell
             style={{
               width: "15%",
             }}>
               {/* <Tf3> */}
                 <input
                 disabled = {disableTextAfterSubmission || employeeDataIsLoading || employeeDataIsFetching}
                   type="date"
                   name="due_date"
                   style={{
                    backgroundColor: "#f8f8f8",
                    width: "100%", 
                    maxWidth: "0px",
                    minWidth:"200px",
                     color: "#7b7b7b",
                     height: "37px",
                     border: "1px solid #c6c6c6",
                     borderRadius: "3px",
                    //  background: "none",

                   }}
                   // disabled={disableTextAfterSubmission}
                   // value={formattedDueDate}
                   value = {element?.due_date || ""}
                   // min={employeeData?.data?.calendar?.end_date_normalizer?.slice(0, 10)}
                   // min={employeeData?.data?.normalizer?.normalized_Date?.slice(0, 10)}
                   min={new Date()?.toISOString()?.slice(0, 10)}
                   // data-date=""
                   // data-date-format="DD MMMM YYYY"
                   onKeyDown={(e) => {
                     if (e.code !== "Tab") {
                       e.preventDefault();
                     }
                   }}
                   onChange={(e) => {
                     handlePerformanceGoalsChange(index, e);
                     setMoveTab(true);
                   }}
                 />
               {/* </Tf3> */}
             </TableCell>
             <TableCell
              style={{
               width: "30%",
             }}>
               <Tf3>
                 <TextField
                 disabled = {disableTextAfterSubmission || employeeDataIsLoading || employeeDataIsFetching}
                   fullWidth
                   multiline
                   placeholder="Add"
                   autoComplete="off"
                   size="small"
                   name="remarks"
                   id="outlined-select-select"
                   // inputProps={{ maxLength: 500 }}
                   value={element.remarks || ""}
                   onChange={(e) => {
                     handlePerformanceGoalsChange(index, e);
                     setMoveTab(true);
                   }}
                   variant="standard"
                   InputProps={{
                     disableUnderline: true,
                     readOnly: disableTextAfterSubmission
                   }}
                 ></TextField>
               </Tf3>
             </TableCell>


             {/* Add and Delete buttons to add and delete the Performance Goals fields */}
             <TableCell
               style={{ borderColor: "#ffffff", padding: "0px", paddingLeft: "12px", width: "20%", }}
             >
               {PerformanceGoalsFieldValues?.length !== 0 && (
                 <>
                   <Tooltip title="Delete">
                     {disableTextAfterSubmission ? <img
                       style={{ cursor: "default" }}
                       src={Blueminus}
                       alt="icon"
                     /> :
                       <img
                         style={{ cursor: "pointer" }}
                         src={Blueminus}
                         onClick={() => {
                           removePerformanceGoalFields(index);
                           setMoveTab(true);
                         }}
                         alt="icon"
                       />}
                   </Tooltip>
                 </>
               )}
               {PerformanceGoalsFieldValues?.length - 1 === index && PerformanceGoalsFieldValues?.length < 6 && (
                 <>
                   <Tooltip title="Add">
                     <div>
                       {disableTextAfterSubmission ? <img src={Blueadd}
                         style={{ cursor: "default" }}
                         alt="icon" /> :
                         <img src={Blueadd}
                           style={{ cursor: "pointer" }}
                           onClick={() => {
                             addPerformanceGoalFields();
                             setMoveTab(true);
                             setnavPrompt(true);
                           }}
                           alt="icon" />}
                     </div>
                   </Tooltip>
                 </>
               )}
             </TableCell>
           </TableRow>
         </TableBody>
       </>
       )
     })}
   </Table> 
   </div> : 
   <div>
      <PerformanceGoalsHeader>
        <b>
          Performance Goals (Appraiser)
        </b>
      </PerformanceGoalsHeader>



      <Table size="small">
        <TableHead>
          <TableRow
            sx={{
              "& td, & th": {
                border: "1px solid #e0e0e0",
                bgcolor: "#eaeced",
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell
              align="center"
              // width="30% !important"
              // width= "20%",
              style={{
                border: "1px solid #e0e0e0",
                fontFamily: "Arial",
                color: "#3E8CB5",
                fontSize: "14px",
                fontWeight: "600",
                width: "13%",
              }}
            >
              Goal Category
            </TableCell>
            <TableCell
              align="center"
              // width="36%"
              style={{
                border: "1px solid #e0e0e0",
                fontFamily: "Arial",
                color: "#3E8CB5",
                fontSize: "14px",
                fontWeight: "600",
                width: "25%",
              }}
            >
              Description
            </TableCell>
            <TableCell
              align="center"
              // width="36%"
              style={{
                border: "1px solid #e0e0e0",
                fontFamily: "Arial",
                color: "#3E8CB5",
                fontSize: "14px",
                fontWeight: "600",
                width: "20%",
              }}
            >
              Key Result/Target
            </TableCell>
            <TableCell
              align="center"
              // width="36%"
              style={{
                border: "1px solid #e0e0e0",
                fontFamily: "Arial",
                color: "#3E8CB5",
                fontSize: "14px",
                fontWeight: "600",
                width: "15%",
              }}
            >
              Due Date
            </TableCell>
            <TableCell
              align="center"
              // width="36%"
              style={{
                border: "1px solid #e0e0e0",
                fontFamily: "Arial",
                color: "#3E8CB5",
                fontSize: "14px",
                fontWeight: "600",
                width: "30%",
              }}
            >
              Remarks
            </TableCell>
          </TableRow>
        </TableHead>


        {PerformanceGoalsFieldValues?.map((element: any, index: any) => {
          const selectedDefinition = goalsCategoryData?.data?.find(
            (item: any) => item?._id === element?.goal_category
          )?.definition; 
          return (
          <>
            <TableBody>
              <TableRow
                sx={{
                  "& td, & th": {
                    border: "1px solid #e0e0e0",
                    whiteSpace: "nowrap",
                  },
                }}
              >
                <TableCell
                  // width="30% !important"
                  style={{
                    width: "13%",
                  }}
                >
                  <Train>
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                    >
                      {element?.goal_category != "" && (
                        <IconButton
                          style={{ padding: "0px" }}
                          onClick={(e: any) => {
                            handleClickOpenGoalCategoryInfo(e)
                            setPopoverIndex(index);
                          }}
                        >
                          <img width="12px" src={Infoicon} alt="icon" />
                        </IconButton>
                      )}
                      <Select
                        sx={{
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          background: "#f8f8f8",
                          // minWidth:"338px",
                          // maxWidth:"0px"
                        }}
                        fullWidth
                        autoComplete="off"
                        // label="Add  Name"
                        // label="Select"
                        placeholder="Select"
                        size="small"
                        name="goal_category"
                        displayEmpty
                        MenuProps={MenuProps}
                        value={element?.goal_category || ""}                      
                        renderValue={
                          element?.goal_category || ""
                            ? undefined
                            : () => <div style={{ color: "#aaa" }}>Select</div>
                        }
                        onChange={(e) => {
                          handlePerformanceGoalsChange(index, e);
                          setMoveTab(true);
                        }}
                        disableUnderline
                      >
                        {goalsCategoryData?.data?.length > 0 &&
                          goalsCategoryData?.data?.map((categoryItem: any) => {
                            return (
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "arial",
                                  color: "#333333",
                                }}
                                value={categoryItem?._id}
                                disabled={disableTextAfterSubmission || employeeDataIsLoading || employeeDataIsFetching}
                              >
                                {categoryItem?.title}
                              </MenuItem>
                            );
                          })}
                      </Select>



                      <Popover
                        id={openGoalsCategoryInfoId}
                        open={(popoverIndex === index) && openGoalsCategoryInfo}
                        anchorEl={anchorEls}
                        onClose={handleClickCloseGoalCategoryInfo}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        PaperProps={{
                          style: {
                            backgroundColor: "FEFCF8",
                            boxShadow: "none",
                            maxWidth: "400px",
                            borderRadius: "5px",
                          },
                        }}
                        sx={{
                          "& .MuiPopover-paper": {
                            border: "1px solid #3e8cb5",
                            backgroundColor: "#ffffff",
                          },
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontFamily: "arial",
                            padding: "5px",
                          }}
                        >                         
                          {selectedDefinition}
                        </Typography>
                      </Popover>
                    </Stack>
                  </Train>
                </TableCell>
                <TableCell
                style={{
                  width: "25%",
                }}>
                  <Tf3>
                    <TextField
                    disabled = {disableTextAfterSubmission || employeeDataIsLoading || employeeDataIsFetching}
                      fullWidth
                      multiline
                      autoComplete="off"
                      placeholder="Add"
                      size="small"
                      name="description"
                      value={element.description || ""}
                      onChange={(e) => {
                        handlePerformanceGoalsChange(index, e);
                        setMoveTab(true);
                      }}
                      // inputProps={{ maxLength: 500 }}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        // readOnly: disableTextAfterSubmission
                      }}
                    />
                  </Tf3>
                </TableCell>
                <TableCell
                style={{
                  width: "20%",
                }}>
                  <Tf3>
                    <TextField
                    disabled = {disableTextAfterSubmission || employeeDataIsLoading || employeeDataIsFetching}
                      fullWidth
                      multiline
                      placeholder="Add"
                      autoComplete="off"
                      size="small"
                      name="keyresult"
                      id="outlined-select-select"
                      // inputProps={{ maxLength: 500 }}
                      value={element.keyresult || ""}
                      onChange={(e) => {
                        handlePerformanceGoalsChange(index, e);
                        setMoveTab(true);
                      }}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: disableTextAfterSubmission
                      }}
                    ></TextField>
                  </Tf3>
                </TableCell>
                <TableCell
                style={{
                  width: "15%",
                }}>
                  {/* <Tf3> */}
                    <input
                    disabled = {disableTextAfterSubmission || employeeDataIsLoading || employeeDataIsFetching}
                      type="date"
                      name="due_date"
                      style={{
                        // width: "140px",
                        backgroundColor: "#f8f8f8",
                        width: "100%", 
                        maxWidth: "0px",
                        minWidth:"200px",
                        color: "#7b7b7b",
                        height: "37px",
                        border: "1px solid #c6c6c6",
                        borderRadius: "3px",
                        // background: "none",

                      }}
                      // disabled={disableTextAfterSubmission}
                      // value={formattedDueDate}
                      value = {element?.due_date || ""}
                      // min={employeeData?.data?.calendar?.end_date_normalizer?.slice(0, 10)}
                      // min={employeeData?.data?.normalizer?.normalized_Date?.slice(0, 10)}
                      min={new Date()?.toISOString()?.slice(0, 10)}
                      // data-date=""
                      // data-date-format="DD MMMM YYYY"
                      onKeyDown={(e) => {
                        if (e.code !== "Tab") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        handlePerformanceGoalsChange(index, e);
                        setMoveTab(true);
                      }}
                    />
                  {/* </Tf3> */}
                </TableCell>
                <TableCell
                 style={{
                  width: "30%",
                }}>
                  <Tf3>
                    <TextField
                    disabled = {disableTextAfterSubmission || employeeDataIsLoading || employeeDataIsFetching}
                      fullWidth
                      multiline
                      placeholder="Add"
                      autoComplete="off"
                      size="small"
                      name="remarks"
                      id="outlined-select-select"
                      // inputProps={{ maxLength: 500 }}
                      value={element.remarks || ""}
                      onChange={(e) => {
                        handlePerformanceGoalsChange(index, e);
                        setMoveTab(true);
                      }}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: disableTextAfterSubmission
                      }}
                    ></TextField>
                  </Tf3>
                </TableCell>


                {/* Add and Delete buttons to add and delete the Performance Goals fields */}
                <TableCell
                  style={{ borderColor: "#ffffff", padding: "0px", paddingLeft: "12px", width: "20%", }}
                >
                  {PerformanceGoalsFieldValues?.length !== 0 && (
                    <>
                      <Tooltip title="Delete">
                        {disableTextAfterSubmission ? <img
                          style={{ cursor: "default" }}
                          src={Blueminus}
                          alt="icon"
                        /> :
                          <img
                            style={{ cursor: "pointer" }}
                            src={Blueminus}
                            onClick={() => {
                              removePerformanceGoalFields(index);
                              setMoveTab(true);
                            }}
                            alt="icon"
                          />}
                      </Tooltip>
                    </>
                  )}
                  {PerformanceGoalsFieldValues?.length - 1 === index && PerformanceGoalsFieldValues?.length < 6 && (
                    <>
                      <Tooltip title="Add">
                        <div>
                          {disableTextAfterSubmission ? <img src={Blueadd}
                            style={{ cursor: "default" }}
                            alt="icon" /> :
                            <img src={Blueadd}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                addPerformanceGoalFields();
                                setMoveTab(true);
                                setnavPrompt(true);
                              }}
                              alt="icon" />}
                        </div>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </>
          )
        })}
      </Table>
      </div>
}
      {/* <Button
        style={{
          borderRadius: "4px",
          textTransform: "none",
          fontSize: "15px",
          fontFamily: "Arial",
          borderColor: "#3E8CB5",
          color: "#3E8CB5",
          height: "35px",
          //  width: "153px",
          background: "transparent",

        }}
        variant="outlined"
        onClick={() => {
          saveHandler();
        }}
      >
        Save as Draft
      </Button> */}
    {/* </div> */}
    </>
  );
};

export default PerformanceGoals;
