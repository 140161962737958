import React from 'react'
import PerformanceGoalsView from '../performanceGoals/PerformanceGoalsView'
import PerformanceGoalsEdit from '../performanceGoals/PerformanceGoalsEdit'

const PerformanceGoalsTab = (props: any) => {
  const { performanceGoals, moveTab, setMoveTab, navPrompt, setNavPrompt, disableTextAfterSubmission, employeePerformanceGoals, setEmployeePerformanceGoals, goalsCategoryData,
    showIfAppraiser, showIfEmployee, employeeDataIsFetching, employeeDataIsLoading
  } = props
  return (
    <>
      <PerformanceGoalsView
        performanceGoals={performanceGoals}
        showIfAppraiser={showIfAppraiser}
      />

      <PerformanceGoalsEdit
        showIfEmployee={showIfEmployee}
        moveTab={moveTab}
        setMoveTab={setMoveTab}
        navPrompt={navPrompt}
        setNavPrompt={setNavPrompt}
        disableTextAfterSubmission={disableTextAfterSubmission}
        performanceGoals={employeePerformanceGoals}
        setPerformanceGoals={setEmployeePerformanceGoals}
        goalsCategoryData={goalsCategoryData}
        employeeDataIsLoading={employeeDataIsLoading}
        employeeDataIsFetching={employeeDataIsFetching} />
    </>
  )
}

export default PerformanceGoalsTab