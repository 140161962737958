import * as React from "react";
import Grid from "@mui/material/Grid";
import { Box, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Expand from "../../../../assets/Images/Expand.svg";
import NineBox from "../../../../components/Dashboard_Reusable_Component/NineBox";
import { useLoggedInUser } from "../../../../hooks/useLoggedInUser";
import { useGetNineboxQuery } from "../../../../service/ninebox/ninebox";
import * as XLSX from 'xlsx';
import { useNavigate } from "react-router-dom";
import TopPerformerspfOverallDashboard from "../../../../components/Dashboard_Reusable_Component/TopPerformerspfOverallDashboard";
import Topperformers from "../../../../components/homepage/TopPerformers"

export default function NineboxandTopPerformanceofNormalizerOverallDashboard(props: any) {
  // const { data: user } = useLoggedInUser();
  const { data: RangeValue } = useGetNineboxQuery("");
  const navigate = useNavigate();
  const { NineBoxData, topPerformerEmployees, ExcelData9Box,
    setNineboxExpand, navigationFrom, setTopperformersExpandActive,
    indexBasedValue, setindexBasedValue, indexBasedTitle, setindexBasedTitle, setSolidperformersExpandActive,
    NAVIGATION_FROM
  } = props;
  const handleNavigationForNineBox = () => {
    setSolidperformersExpandActive(true);
  }
  const [indexValue, setindexValue] = React.useState<any>('');
  const [trigger, setTrigger] = React.useState<any>(false);

  const HandleNaviation = () => {
    setTrigger(true);
  }
  React.useEffect(() => {
    if (trigger === true) {
      if (indexBasedTitle == RangeValue?.data[0]?.box_9_definitions[0]?.title) {
        setNineboxExpand(true)
      } else if (indexBasedTitle == RangeValue?.data[0]?.box_9_definitions[1]?.title) {
        setNineboxExpand(true)
      } else if (indexBasedTitle == RangeValue?.data[0]?.box_9_definitions[2]?.title) {
        setNineboxExpand(true)
      } else if (indexBasedTitle == RangeValue?.data[0]?.box_9_definitions[3]?.title) {
        setNineboxExpand(true)
      } else if (indexBasedTitle == RangeValue?.data[0]?.box_9_definitions[4]?.title) {
        setNineboxExpand(true)
      } else if (indexBasedTitle == RangeValue?.data[0]?.box_9_definitions[5]?.title) {
        setNineboxExpand(true)
      } else if (indexBasedTitle == RangeValue?.data[0]?.box_9_definitions[6]?.title) {
        setNineboxExpand(true)
      } else if (indexBasedTitle == RangeValue?.data[0]?.box_9_definitions[7]?.title) {
        setNineboxExpand(true)
      } else if (indexBasedTitle == RangeValue?.data[0]?.box_9_definitions[8]?.title) {
        setNineboxExpand(true)
      }
    }
  }, [indexBasedTitle])
  const setIndexBasedValueforNineBox = (indexValue: any) => {
    if (indexValue == 0) {
      setindexBasedValue(NineBoxData[0]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[0]?.title)
    }
  }
  React.useEffect(() => {
    let DataforInitial: any[] = NineBoxData[0]?.category?.concat(NineBoxData[1]?.category, NineBoxData[2]?.category, NineBoxData[3]?.category, NineBoxData[4]?.category, NineBoxData[5]?.category, NineBoxData[6]?.category, NineBoxData[7]?.category, NineBoxData[8]?.category)
    if (indexValue == "") {
      setindexBasedValue(DataforInitial)
    }
    else if (indexValue == 0) {
      setindexBasedValue(NineBoxData[0]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[0]?.title)
    } else if (indexValue == 1) {
      setindexBasedValue(NineBoxData[1]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[1]?.title)
    } else if (indexValue == 2) {
      setindexBasedValue(NineBoxData[2]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[2]?.title)
    } else if (indexValue == 3) {
      setindexBasedValue(NineBoxData[3]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[3]?.title)
    } else if (indexValue == 4) {
      setindexBasedValue(NineBoxData[4]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[4]?.title)
    } else if (indexValue == 5) {
      setindexBasedValue(NineBoxData[5]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[5]?.title)
    } else if (indexValue == 6) {
      setindexBasedValue(NineBoxData[6]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[6]?.title)
    } else if (indexValue == 7) {
      setindexBasedValue(NineBoxData[7]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[7]?.title)
    } else if (indexValue == 8) {
      setindexBasedValue(NineBoxData[8]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[8]?.title)
    }
  }, [indexValue, NineBoxData[0]?.category, NineBoxData[8]?.category, NineBoxData[7]?.category, NineBoxData[6]?.category, NineBoxData[5]?.category
    , NineBoxData[4]?.category, NineBoxData[3]?.category, NineBoxData[2]?.category, NineBoxData[1]?.category])
  return (
    <div>
      <Box
        style={{
          marginLeft: "25px",
          marginRight: "25px",
          background: "#ffffff",
          padding: "20px",
          height: "auto",
          paddingBottom: "50px"
        }}
      >
        <Grid container spacing={2}>
          <Grid sx={{ paddingRight: "16px" }} item md={12} xs={12} lg={8}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              paddingBottom="20px"
            >
              <Typography
                sx={{ fontSize: "20px", fontFamily: "Arial", color: "#3e8cb5" }}
              >
                9-Box Grid
              </Typography>
              <div>
                <img style={{ cursor: "pointer" }} src={Expand} onClick={handleNavigationForNineBox} />
              </div>
            </Stack>
            <NineBox setIndexBasedValueforNineBox={setIndexBasedValueforNineBox}
              nineboxValues={NineBoxData} navigationFrom={navigationFrom} HandleNaviation={HandleNaviation} setindexValue={setindexValue} />
          </Grid>
          <Grid
            sx={{
              borderLeft: "1px solid #EEEEEE",
              '@media (max-width: 1024px)': {
                borderLeft: "none",
                marginTop: "20px"
              }
            }} item xs={12} md={12} lg={4}>
            {topPerformerEmployees != undefined &&
              <TopPerformerspfOverallDashboard
                topPerformerEmployees={topPerformerEmployees}
                navigationFrom={navigationFrom}
                setTopperformersExpandActive={setTopperformersExpandActive}
                NAVIGATION_FROM={NAVIGATION_FROM} />
            }
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
