import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Checkbox, Drawer, FormControlLabel, FormGroup, IconButton, Stack,Box } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Deleteicon from "../../assets/Images/Deleteicon.svg";
import Closeicon from "../../assets/Images/Closeicon.svg";
import { borderRadius, display } from "@mui/system";
import Scrollbar from "react-scrollbars-custom";
import { styled } from "@mui/material/styles";

const Scroll = styled("div")({
    "& .ScrollbarsCustom-Thumb": {
      background: "#C2C1C1 !important",
    },
    "& .ScrollbarsCustom-Track": {
  
      //width:"10px !important"
    },
  });
const GenerateReport: React.FC<any> = (props: any) => {
  const {
    isDrawerOpen1,
    setisDrawerOpen1,
    handleTrainingRecommendation,
    handlefurtherrecommendation,
    handleAreaofimprovement,
    handleheadingSortAccept1,
    handleCloseGrade1,
    columnHeadersDisplay,
  } = props;
  const CustomScrollbar = Scrollbar as any;

  return (
   
    <>
     <Drawer
          anchor="right"
          open={isDrawerOpen1}
          onClose={() => {
            setisDrawerOpen1(false);
          }}
        > <Box sx={{ paddingLeft: "10px" }}>
          <Stack direction="column" width="250px">
              <Scroll>
                <CustomScrollbar
                  style={{
                    height: "calc(100vh - 100px)",
                  }}>
                  <div>
                    <FormGroup>
                    <FormControlLabel
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "14px",
                                color: "#333333",
                                fontFamily: "Arial",
                              },
                            }}
                            control={
                              <Checkbox
                                checked={columnHeadersDisplay.TrainingRecommendation}
                                name="TrainingRecommendation"
                                onChange={handleTrainingRecommendation} />
                            }
                            label="Training Report"
                          />
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "14px",
                            color: "#333333",
                            fontFamily: "Arial",
                          },
                        }}
                        control={
                          <Checkbox
                            checked={columnHeadersDisplay.Areaofimprovement}
                            name="Areaofimprovement"
                            onChange={handleAreaofimprovement} />
                        }
                        label="Areas for Improvement Report"
                      />
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "14px",
                            color: "#333333",
                            fontFamily: "Arial",
                          },
                        }}
                        control={
                          <Checkbox
                            checked={columnHeadersDisplay.furtherrecommendation}
                            name="furtherrecommendation"
                            onChange={handlefurtherrecommendation} />
                        }
                        label="Recommendation Report"
                      />
                    </FormGroup>
                    </div>
                    </CustomScrollbar>
                    </Scroll>
                    <Stack
                direction="row"
                spacing={2}
                paddingBottom="10px"
                paddingTop="55px"
                justifyContent="center"
              >
                <Button
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: "Arial",
                    borderColor: "#3E8CB5",
                    color: "#3E8CB5",
                    background: "transparent",
                    width: "70px",
                    height: "35px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    handleheadingSortAccept1();
                  }}
                >
                  Apply
                </Button>
                <Button
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: "Arial",
                    borderColor: "#3E8CB5",
                    color: "#3E8CB5",
                    background: "transparent",
                    width: "70px",
                    height: "35px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    handleCloseGrade1();
                  }}
                >
                  Cancel
                </Button>
              </Stack>
                    </Stack>
                   
          </Box></Drawer>
    </>
  );
};

export default GenerateReport;
