import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useEmployeeRejectionMutation, useGetEmployeeAppraisalQuery,useGetObjectiveGroupQuery, useGetObjectiveTitleQuery, useGetRatingScaleQuery, useGetTalentCategoryQuery, useUpdateEmployeeAppraisalMutation } from '../../service';
import { useParams } from 'react-router-dom';
import _ from "lodash";
import { useGetPerformanceGoalsQuery } from '../../service/performanceGoals/performanceGoals';
import { useCheckRoleLogsMutation } from '../../service/employee/employee';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { PROMPT } from '../../constants/DialogContents/DialogContents';

// Create the context
const EmployeeContext = createContext<any>(undefined);

// Custom hook to access the context
export function useEmployeeContext() {
    return useContext(EmployeeContext);
}


export function useBlocker(blocker: any, when = true) {

    const { navigator } = useContext(NavigationContext);
    //const navigator = React.useContext(UNSAFE_NavigationContext)
    interface navigator {
        block: {

            any: any
        }
    }
    React.useEffect(() => {
        if (!when) return;
        // @ts-ignore
        const unblock = navigator.block((tx: any) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {

                    unblock();
                    tx.retry();
                },
            };
            blocker(autoUnblockingTx);
        });
        return unblock;
    }, [navigator, blocker, when]);
}

export function usePrompt(message: any, when = true) {
    const blocker = useCallback(
        (tx) => {
            // eslint-disable-next-line no-alert
            if (window.confirm(message)) tx.retry();
        },
        [message]
    );

    useBlocker(blocker, when);
}

// Custom hook to provide data to the context
function useEmployeeDataContext() {

    // Add more state variables or functions as needed
    let showIfEmployee = true;
    const { employee_id } = useParams();
    const [message, setMessage] = useState("");
    const [tabValue, setTabValue] = useState(0); // set the tab value
    const [moveTab, setMoveTab] = useState(false); // Allow to move between tabs
    const [navPrompt, setNavPrompt] = useState(false); // Allow to navigate between screens
    const [objectiveDescription, setObjectiveDescription] = useState<any>([]);
    const [currentObjectiveDescription, setCurrentObjectiveDescription] = useState<any>([])
    const [currentOverAllRating, setCurrentOverAllRating] = useState<any>(0)
    const [disableTextAfterSubmission, setDisableTextAfterSubmission] = useState(false);
    const [visiblityColumnData, setVisiblityColumnData] = useState({
        showPreviousRating: false,
        showAppraiserComments: false,
        showAppraiserRejectionReason: false,
        showEmployeeRating: false,
        showNormalizerRating: false,
        showHRNormalizerComments: false
    });
    const [appraiserFeedbackQuestionnaire, setAppraiserFeedbackQuestionnaire] = useState<any>([]);
    const [showAppraiserAreaOfImprovement, setShowAppraiserAreaOfImprovement] = useState(false);
    const [appraiserAreaOfImprovement, setAppraiserAreaOfImprovement] = useState([]);
    const [employeeAreaOfImprovement, setEmployeeAreaOfImprovement] = useState<any>([
        {
            id: Date.now(),
            value: "",
            specific_actions: [{ value: "" }],
        },
    ]);
    const [employeeTrainingRecommendation, setEmployeeTrainingRecommendation] = useState([
        { name: "", training_name: "", justification: "" },
    ])
    const [showAppraiserTrainingRecommendation, setShowAppraiserTrainingRecommendation] = useState(false);
    const [appraiserTrainingRecommendation, setAppraiserTrainingRecommendation] = useState([]);
    const [appraisalTemplateTrainingRecommendation, setAppraisalTemplateTrainingRecommendation] = useState([]);
    const [employeeComments, setEmployeeComments] = useState("");
    const [oneToOneMeetingDate, setOneToOneMeetingDate] = useState("");
    const [appraiserPerformanceGoals, setAppraiserPerformanceGoals] = useState([]);
    const [employeePerformanceGoals, setEmployeePerformanceGoals] = useState([
        { goal_category: "", description: "", keyresult: "", due_date: "", remarks: "" },
    ]); /****************** these are the name property of the fields ****************/
    const [PAStatus, setPAStatus] = useState("")
    const [employeeDetails, setEmployeeDetails] = useState({});
    const [overallRating, setOverallRating] = useState(0);
    const [ratingDefinition, setRatingDefinition] = useState<any>();
    const [ratingScaleDefinition, setRatingScaleDefinition] = useState<any>();
    const [appraiserMessageForEmployee, setAppraiserMessageForEmployee] = useState("")
    const [disableButtons, setDisableButtons] = useState(false); // disabling functional buttons if data is saving or fetching
    const [talentPotential, setTalentPotential] = useState<any>("")
    const [talentRating, setTalentRating] = useState<any>(0)
    const [appraiserAgreedRatingWithEmployee, setAppraiserAgreedRatingWithEmployee] = useState(false)
    const [colorarray, setColorarray] = useState<any>("");
    const Colors = [
        "#B6E9EE",
        "#C9FBEA",
        "#B1EDEE",
        "#B9E9D0",
        "#BDE3E2",
        "#B1F3F2",
        "#B7E6F7",
        "#B8EFEF",
        "#BFFBE7",
        "#B7E6F7",
        "#B1F1F0",
        "#BEECF5",
    ]
    const formIsDirty = navPrompt;
    usePrompt(
        PROMPT,
        formIsDirty);



    // mutations
    const { data: employeeData, refetch: refetchEmployeeData, isLoading: employeeDataIsLoading, isFetching: employeeDataIsFetching } =
        useGetEmployeeAppraisalQuery(employee_id);
    const { data: objectiveTitleData } = useGetObjectiveTitleQuery("");
    const { data: ratingData } = useGetRatingScaleQuery("");
    const { data: goalsCategoryData } = useGetPerformanceGoalsQuery("")
    const [updateEmployee] = useUpdateEmployeeAppraisalMutation();
    const [updateLoggedRole] = useCheckRoleLogsMutation()
    const { data: talentCategory } = useGetTalentCategoryQuery({ overall_rating: talentRating, potential: talentPotential });
    const [updateEmployeeRejection, { isLoading: isLoadingRejection }] = useEmployeeRejectionMutation();
    const {data : objectiveGroupdata} =useGetObjectiveGroupQuery("")


    // initialization
    const [employeeAgreesWithAppraiser, setEmployeeAgreesWithAppraiser] = useState(employeeData?.data?.employee?.employee_agree);

    // functions

    // to find objective title by Id
    const findObjectiveTitleById = (id: any) => {
        if (objectiveTitleData) {
            //console.log(id, "objectiveTitleData");
            return objectiveTitleData.data.find((item: any) => item._id === id);
        }
    };

    // to find objective type by Id
    const findObjectiveTypeById = (id: any) => {
        if (employeeData) {
            return employeeData?.data?.reviewer?.objective_type?.find(
                (item: any) => item?.name?._id === id
            );
        }
    };

    // to find Previous Rating
    const findPreviousRating = (id: any) => {
        let temp = (employeeData?.data?.appraisal_previous_rating?.objective_description?.filter(
            (i: any) =>
                i?.name === id
        ).map((k: any) => {
            if (ratingData) {
                let temp = ratingData?.data?.find((item: any) => k.ratings == item._id)
                return temp?.rating
            }
        })[0]);
        return temp
    }

    //  to find employee rating
    const findEmployeeRating = (id: any) => {
        let temp = (employeeData?.data?.employee?.objective_description
            .filter(
                (i: any) =>
                    i?.name?._id === id
            )
            .map((k: any) => {
                if (k?.ratings && k.rating_rejected == true)
                    return {
                        rating_rejected: k.rating_rejected,
                        ratings: k.ratings,
                        rejection_reason: k.rejection_reason
                    }
                else return ""
            })[0]);
        return temp
    }

    //  to find Normalizer Rating
    const findNormalizerRating = (id: any) => {
        let temp = (employeeData?.data?.normalizer?.objective_description
            .filter(
                (i: any) =>
                    i?.name?._id === id
            )
            .map((k: any) => {
                return {
                    rating_resubmitted: k.rating_resubmitted,
                    ratings: k?.ratings,
                    rejection_reason: k?.rejection_reason
                }
            })[0]);
        return temp
    }
    const findObjectiveGropuById = (id: any) => {
        if (objectiveGroupdata) {
          //console.log(id, "objectiveTitleData");
          return objectiveGroupdata?.data?.find((item: any) =>{
           return (
            item?._id === id
           )
        });
        }
      };
    // group Area of Improvement by specific Area
    const groupSpecificAreaHandler = (area: any) => {
        if (area) {
            let tempArea = area?.filter((area: any) => {
                return area[0] !== "" && area[0] !== undefined
            })
            if (tempArea && tempArea?.length > 0) {
                return {
                    showArea: true,
                    area: area,
                }
            } else {
                return {
                    showArea: false
                }
            }
        }
    };

    // useEffects
    useEffect(() => {
        if (employeeData && objectiveTitleData) {
            setEmployeeDetails(() => {
                return {
                    employee_code: employeeData?.data?.employee_code,
                    previousRating: employeeData?.data?.previous_rating,
                    profile_image_url: employeeData?.data?.profile_image_url,
                    fullName: employeeData?.data?.legal_full_name,
                    firstName: employeeData?.data?.first_name,
                    position: employeeData?.data?.position_long_description,
                    grade: employeeData?.data?.grade,
                    status: employeeData?.data?.appraisal?.status,
                    calendarName: employeeData?.data.calendar?.name,
                    JoiningDate: employeeData?.data?.JoiningDate,
                    Department: employeeData?.data?.Department,
                    Tenure: parseFloat(employeeData?.data?.Tenure)?.toFixed(2),
                    DepartmentHead: employeeData?.data?.DepartmentHead,
                }
            });
            setObjectiveDescription(() => {
                const objectiveTypes = employeeData?.data?.appraisal?.objective_type || [];
                const objectiveGroupMap = new Map();

                objectiveTypes.forEach((item:any) => {
                    if (item?.name?.objective_group) {
                        objectiveGroupMap.set(item?.name?._id, item?.name?.objective_group);
                    }
                });
                return employeeData?.data?.appraisal?.objective_description?.map(
                    (i: any) => {
                        return {
                            ...i,
                            comments: i.comments,
                            rejection_reason: i.rejection_reason,
                            rating: i.ratings,
                            previous_rating: findPreviousRating(i.name?._id),
                            objective_title: findObjectiveTitleById(i?.name?.objective_title),
                            objective_type: findObjectiveTypeById(i?.name?.objective_type),
                            employee_rating: findEmployeeRating(i.name?._id),
                            normalizer_rating: findNormalizerRating(i.name?._id),
                            objective_group: findObjectiveGropuById(objectiveGroupMap.get(i?.name?.objective_type))
                        };
                    }
                );
            });

            setCurrentObjectiveDescription(() => {
                return employeeData?.data?.current_rating?.objective_description?.map(
                    (i: any) => {
                        return {
                            ...i,
                            objective_title: findObjectiveTitleById(i?.name?.objective_title),
                            objective_type: findObjectiveTypeById(i?.name?.objective_type),
                        };
                    }
                );
            });

            let objectiveType = employeeData.data.appraisal.objective_group.map((item: any, index: number) => {
                return {
                    objective_group: item?.name?.name,
                    color: Colors[index]
                }
            });
            setColorarray(objectiveType)

            setDisableTextAfterSubmission(!employeeData?.data?.appraisal?.pa_status?.includes("Pending with Employee"))

            setVisiblityColumnData({
                ...visiblityColumnData, // Spread the current state

                // show the previous rating popover if any previous rating data is present.
                showPreviousRating: (employeeData?.data?.appraisal_previous_rating?.objective_description?.filter((i: any) => i.ratings)?.length > 0) ? true : false,

                // show appraiser comments column if any of the rating has appraiser comments.
                showAppraiserComments: (employeeData?.data?.appraisal?.objective_description.filter((item: any) =>
                    item.comments !== "" && item.comments !== undefined)?.length > 0) ? true : false,

                /**show appraiser rejection reason if appraiser has rejected any rating and added rejection reason and also if 
                if the PA is not renormalized or completed *****/
                showAppraiserRejectionReason: (employeeData?.data?.normalizer?.normalizer_status !== "re-normalized" && employeeData?.data?.appraisal?.status !== "completed" &&
                    employeeData?.data?.appraisal?.objective_description?.filter((item: any) =>
                        (item.rating_rejected || (item.rating_resubmitted == true &&
                            (employeeData?.data?.appraisal?.status !== "rejected"))) && (item.rejection_reason !== "" && item.rejection_reason !== undefined))?.length > 0) ? true : false,

                /**show employee rating if any of the rating has been rejected by the employee */
                showEmployeeRating: ((employeeData?.data?.normalizer?.normalizer_status !== "re-normalized") && (employeeData?.data?.employee?.objective_description?.filter((item: any) =>
                    item.rating_rejected == true)?.length > 0)) ? true : false,

                /**Show normalizer rating if normalizer has resubmitted any rating and the PA is renormalized
                  and completed */
                showNormalizerRating: (employeeData?.data?.appraisal?.status == "completed" &&
                    employeeData?.data?.normalizer?.normalizer_status == "re-normalized" &&
                    employeeData?.data?.normalizer?.objective_description?.filter((item: any) =>
                        item.rating_resubmitted == true)?.length > 0) ? true : false,

                /**Show normalizer rejection reason if normalizer has resubmitted any rating and the PA is renormalized
                   and completed */
                showHRNormalizerComments: ((employeeData?.data?.appraisal?.status == "completed" &&
                    employeeData?.data?.normalizer?.normalizer_status == "re-normalized" &&
                    employeeData?.data?.normalizer?.objective_description?.filter((item: any) =>
                        item.rating_resubmitted == true)?.length > 0) && (employeeData?.data?.normalizer?.objective_description?.filter((item: any) =>
                            item.rating_resubmitted == true)?.length > 0)) ? true : false,

            });

            setAppraiserFeedbackQuestionnaire(employeeData?.data?.appraisal?.feedback_questions)
            // to get Appraiser Area of Improvement
            let tempAppraiserAreaofImprovement = employeeData?.data?.appraisal?.area_of_improvement;
            const groupBySpecificAppraiser = _.groupBy(tempAppraiserAreaofImprovement, "value");
            const groupHandlerAppraiser = groupSpecificAreaHandler(Object.entries(groupBySpecificAppraiser));
            if (groupHandlerAppraiser?.showArea) {
                setShowAppraiserAreaOfImprovement(true);
                setAppraiserAreaOfImprovement(groupHandlerAppraiser?.area)
            } else {
                setShowAppraiserAreaOfImprovement(false);
            }

            setEmployeeAreaOfImprovement(employeeData?.data?.employee?.area_of_improvement);

            setAppraiserTrainingRecommendation(employeeData?.data?.appraisal?.training_recommendation)
            let tempTraining = employeeData?.data?.appraisal?.training_recommendation?.filter((item: any) => {
                return item.name.title !== "" || item.name.title !== undefined
            });
            if (tempTraining && tempTraining?.length > 0) {
                setShowAppraiserTrainingRecommendation(true);
            } else {
                setShowAppraiserTrainingRecommendation(false)
            }
            setEmployeeTrainingRecommendation(() => {
                return employeeData.data.employee.training_recommendation.map(
                    (i: any) => {
                        return {
                            ...i,
                            name: i.name?._id,
                            justification: i.justification,
                            trainingName: i.training_name,
                        };
                    }
                );
            });
            // setEmployeeTrainingRecommendation(employeeData?.data?.employee?.training_recommendation)
            setAppraisalTemplateTrainingRecommendation(employeeData?.data?.appraisal_template?.training_recommendation)
            setEmployeeComments(employeeData?.data?.employee?.comments);
            setOneToOneMeetingDate(employeeData?.data?.employee?.one_to_one_meeting?.slice(0, 10));
            setAppraiserPerformanceGoals(employeeData?.data?.appraisal?.performance_goal);
            setEmployeePerformanceGoals(() => {
                return employeeData?.data?.employee?.performance_goal?.map((j: any) => {
                    return {
                        goal_category: j?.goal_category?._id,
                        description: j?.description,
                        keyresult: j?.keyresult,
                        due_date: j?.due_date?.slice(0, 10),
                        remarks: j?.remarks
                    };
                }) || []
            });
            setOverallRating((employeeData?.data?.appraisal?.status === "completed") ?
                (employeeData?.data?.current_rating?.overall_rating?.toFixed(2)) :
                employeeData?.data?.employee && (employeeData?.data?.employee?.employee_status == "pending" ||
                    employeeData?.data?.employee?.employee_status == "draft") ?
                    (employeeData?.data?.current_rating?.overall_rating?.toFixed(2)) :
                    (employeeData?.data?.employee?.employee_rating?.toFixed(2)));
            setPAStatus(employeeData?.data?.appraisal?.pa_status);
            setDisableTextAfterSubmission(!employeeData?.data?.appraisal?.pa_status?.includes("Pending with Employee"));
            setAppraiserMessageForEmployee(employeeData?.data?.appraisal?.appraiser_overall_feedback);
            setTalentPotential(employeeData?.data?.appraisal?.potential);
            setTalentRating(employeeData?.data?.normalizer?.normalizer_rating);
            setEmployeeAgreesWithAppraiser(employeeData?.data?.employee?.employee_agree);
            setAppraiserAgreedRatingWithEmployee(employeeData?.data?.appraisal?.appraiserAgreedRatingWithEmployee)
        }
    }, [employeeData, objectiveTitleData])

    useEffect(() => {
        let Overall_rating: any;


        if (employeeData?.data?.appraisal?.status === "completed") {
            Overall_rating = employeeData?.data?.current_rating?.overall_rating?.toFixed(2);
        } else if (
            employeeData?.data?.employee?.employee_status === "pending" ||
            employeeData?.data?.employee?.employee_status === "draft"
        ) {
            Overall_rating = employeeData?.data?.current_rating?.overall_rating?.toFixed(2);
        } else {
            Overall_rating = employeeData?.data?.employee?.employee_rating?.toFixed(2);
        }

        const RatingScale = ratingData?.data?.map((j: any) => ({
            rating: j?.rating,
            definition: j?.definition,
            rating_titile: j?.rating_scale,
        }));

        const filterRatingScale = (item: any, minRating: any, maxRating: any) => {
            return (item?.rating >= minRating && item?.rating <= maxRating) && (Overall_rating >= minRating && Overall_rating <= maxRating);
        }

        const FilteredRatingScale = RatingScale?.filter((item: any) => {
            if (filterRatingScale(item, 1, 1.99) ||
                filterRatingScale(item, 2, 2.49) ||
                filterRatingScale(item, 2.5, 2.99) ||
                filterRatingScale(item, 3, 3.49) ||
                filterRatingScale(item, 3.5, 3.99) ||
                filterRatingScale(item, 4, 4.49) ||
                filterRatingScale(item, 4.5, 4.99) ||
                filterRatingScale(item, 5.0, 5.0)) {
                return {
                    ratingScale: item?.rating_titile,
                    definition: item?.definition,
                    // rating: item?.rating,
                };
            }
        });

        if (FilteredRatingScale && FilteredRatingScale?.length > 0) {
            setRatingDefinition(FilteredRatingScale[0]?.definition);
            setRatingScaleDefinition(FilteredRatingScale[0]?.rating_titile);
        } else {
            // Handle the case when FilteredRatingScale is empty
            // setratingdefenition("No rating definition found");
        }
    }, [ratingData, employeeData]);

    useEffect(() => {
        console.log("working")
        if (currentObjectiveDescription?.length > 0 && employeeData) {
            const currentObjectiveDescriptionMapped = currentObjectiveDescription.map((i: any) => {
                // if (i.ratings) {
                console.log(i, i.objective_type, i.value, i.objective_type?.value, 'objective_type')
                const sum = (i.value * i.objective_type?.value) / 10000
                const newSum = sum * i?.ratings?.rating
                console.log(sum, 'newSum')
                return newSum
                // }


            })

            setCurrentOverAllRating(() => {
                return _.sum(currentObjectiveDescriptionMapped).toFixed(2)
            });

        }
    }, [currentObjectiveDescription, employeeData])

    useEffect(() => {
        if (currentOverAllRating) {
            updateEmployee({
                "current_rating.overall_rating": currentOverAllRating,
                id: employee_id
            })
        }
    }, [currentOverAllRating])


    return {
        employee_id,
        employeeData,
        message,
        setMessage,
        tabValue,
        setTabValue,
        moveTab,
        setMoveTab,
        navPrompt,
        setNavPrompt,
        objectiveDescription,
        setObjectiveDescription,
        objectiveTitleData,
        Colors,
        colorarray,
        disableTextAfterSubmission,
        setDisableTextAfterSubmission,
        visiblityColumnData,
        showAppraiserAreaOfImprovement,
        appraiserAreaOfImprovement,
        employeeAreaOfImprovement,
        setEmployeeAreaOfImprovement,
        showAppraiserTrainingRecommendation,
        appraiserTrainingRecommendation,
        employeeTrainingRecommendation,
        setEmployeeTrainingRecommendation,
        appraisalTemplateTrainingRecommendation,
        employeeComments,
        setEmployeeComments,
        oneToOneMeetingDate,
        setOneToOneMeetingDate,
        appraiserPerformanceGoals,
        setAppraiserPerformanceGoals,
        employeePerformanceGoals,
        setEmployeePerformanceGoals,
        goalsCategoryData,
        PAStatus,
        setPAStatus,
        employeeDetails,
        overallRating,
        ratingData,
        showIfEmployee,
        appraiserFeedbackQuestionnaire,
        setAppraiserFeedbackQuestionnaire,
        appraiserMessageForEmployee,
        ratingDefinition,
        ratingScaleDefinition,
        disableButtons,
        setDisableButtons,
        updateEmployee,
        updateLoggedRole,
        talentCategory,
        refetchEmployeeData,
        isLoadingRejection,
        updateEmployeeRejection,
        employeeDataIsFetching,
        employeeDataIsLoading,
        employeeAgreesWithAppraiser,
        setEmployeeAgreesWithAppraiser,
        appraiserAgreedRatingWithEmployee
        // Add more data here
    };
}

// Provider component to provide the context to its children
export default function ProvidedEmployeeContextProvider({ children }: { children: React.ReactNode }) {
    const data = useEmployeeDataContext();

    return (
        <EmployeeContext.Provider value={data}>
            {children}
        </EmployeeContext.Provider>
    );
}
