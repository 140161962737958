import * as React from "react";
import { useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Badge, FormControl, Grid, MenuItem, BadgeProps, Menu, Drawer, FormGroup, FormControlLabel } from "@mui/material";
import { InputLabel, Stack, Tab, Tabs, Box, Typography } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { TextField } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { Button } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { ListItemIcon } from '@mui/material';
import {
  useGetEmployeeByFilterQuery,
  useGetEmployeeByManagerCodeQuery,
  useGetEmployeeByStatusQuery,
  useGetEmployeeQuery,
  useGetReviewerEmployeeByFilterQuery
} from "../../../../service";
import { useEffect } from "react";
import {
  APPRAISAL_NOT_STARTED,
  APPRAISER,
  EMPLOYEE_APPRAISER_SUBMISSION,
  PREVIOUSAPPRAISAL_VIEWPA,
  PREVIOUS_VIEWPA,
  CREATE_APPRAISAL,
  EMPLOYEE_DOWNLOAD,
  VIEW_PA,
  VIEW_PAST_PA,
  APPRAISER_SUBMISSION,
} from "../../../../constants/routes/Routing";
import UpDown from "../../../../assets/Images/UpDown.svg";
import Opennew from "../../../../assets/Images/Opennew.svg";
import Application from "../../../../assets/Images/Application.svg";
import Searchlens from "../../../../assets/Images/Searchlens.svg";
import Eye from "../../../../assets/Images/Eye.svg";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Checkbox from "@mui/material/Checkbox";
import Searchlensreview from "../../../../assets/Images/Searchlens.svg";
import Expand from "../../../../assets/Images/Expand.svg";
import Newexcel from "../../../../assets/Images/Newexcel.svg"
import Updown from "../../Dashboard/Reviewericons/Updown.svg";
import Alert from "@mui/material/Alert";
import TablePagination from "@mui/material/TablePagination";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as XLSX from "xlsx";
import { useAppContext } from "../../../../context/AppContext";
import { useLoggedInUser } from "../../../../hooks/useLoggedInUser";
import Scrollbar from "react-scrollbars-custom";
import { makeStyles } from '@mui/styles';
import ExportToexcel from "../../../../components/ExportToExcel/ExporttoExcel";
import AlertDialogSuccess from "../../../../components/UI/DialogSuccess";
import GenerateReport from "../../../../components/ExportToExcel/GenerateReport";

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    width: 140,
    fontSize: "14px",
    color: "#333333",
    fontFamily: "Arial",
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
    fontSize: "13px !important",
    fontFamily: "Arial",
    color: "#333333",
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    fontSize: "12px !important",
    color: "#333333",
    fontFamily: "Arial",
  },
}));

const Mytable = styled("div")({
  background: "#FFFFFF",
  marginLeft: "25px",
  marginRight: "25px",
  position: "relative",
  paddingBottom:"40px",
});
const Tabstyles = styled("div")({
  "& .MuiButtonBase-root": {
    color: "#999999",
    textTransform: "none",
    fontWeight: 400,
  },
  "& .Mui-selected": {
    color: "#004C75",
  },
  "&.MuiTabs-indicator": {
    backgroundColor: "#004C75",
  },
  display: "flex",
});
const Heading = styled("div")({
  fontSize: "18px",
  color: "#3e8cb5",
  fontFamily: "Arial",
});
const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
  "& .ScrollbarsCustom-Track": {
  },
});
const Searchfeild = styled("div")({
  "& .MuiOutlinedInput-root": {
    height: "28px",
    width: "144px",
    borderRadius: "15px",
    background: "#F2F6F8",
  },
  "& .MuiInputLabel-root": {
    fontSize: "13px",
    color: "#306D8F",
    marginTop: "-10px",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "13px",
    color: "#306D8F",
  },
});
const TableHeadings = styled("div")({
  marginLeft:"24px",
        marginRight:"24px",
        marginTop:"10px",
  "& .MuiTableRow-head ": {
    background: "#eaeced",
  },
  "& .MuiTableCell-root": {
    padding: "0px",
  },
});

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -17,
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const Names = styled("div")({
  color: "#333333",
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//@ts-ignore
const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};

const checkboxHandler = (event: any) => {
  console.log(event.target.value);
};


const Closedcalendarteamtable = (props: any) => {
  const classes = useStyles();
  const { startAppraisal, employeeData1, SectionFilter, setSectionFilter, subSectionFilter,
    setSubSectionFilter,NaviGationFrom, setTeamtableExpandActive, valueOfActiveCalender,Role, Rolee, appCalId } = props;
  console.log(Role,appCalId, "Role")
  const [tabValue, setTabValue] = React.useState<any>(0);
  const { statusSort } = props;
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log("handle change run");
    console.log(newValue, "index check");
    setTabValue(newValue);
  };
  console.log(employeeData1, "usersusers");
  const listInnerRef = useRef<any>();

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<number>();
  const [users, setUsers] = useState<any>([]);
  const [filter, setFilter] = React.useState("");
  const [employee, setEmployee] = React.useState([]);
  const [enteredName, setenteredName] = useState("");
  const [approved, setApproved] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tablecount, settablecount] = React.useState<any>(0);
  const [pageNumber, setPageNumber] = useState(0)
  console.log(tablecount, "tablecount");
  const [tablecount1, settablecount1] = React.useState<any>(0);
  // console.log(tablecount1, "tablecount1");
  const navigate = useNavigate();
  const [isDrawerOpen1, setisDrawerOpen1] = React.useState(false);
  const [GenerateReportOpen, setGenerateReportOpen] = React.useState(false);
 const handleGenerateReportOpen = () =>{
  setGenerateReportOpen(false)
 }
  //multiselect
  const [positionFilter, setpositionFilter] = React.useState<string[]>([]);
  const [GradeFilter, setGradeFilter] = React.useState<string[]>([]);
  console.log(positionFilter, "positionFilter");
  const handleChangeSelectPosition = (event: SelectChangeEvent<typeof positionFilter>) => {
    const {
      target: { value },
    } = event;
    if (value.includes("None")) { setpositionFilter([]) } else {
      setpositionFilter(

        typeof value === 'string' ? value.split(',') : value,
      );
    }

  };
  const handleChangeSelectGrade = (event: SelectChangeEvent<typeof GradeFilter>) => {
    const {
      target: { value },
    } = event;

    if (value.includes("None")) { setGradeFilter([]) } else {
      setGradeFilter(

        typeof value === 'string' ? value.split(',') : value,
      );
    }
  };
  // console.log(statusSort, "statussort");
  // useEffect(() => {
  //   if (valueOfCard === "") {
  //     return setTabValue(0);
  //   } else if (valueOfCard === "Not-Started") {
  //     return setTabValue(2);
  //   } else if (valueOfCard === "In Progress") {
  //     return setTabValue(3);
  //   } else if (valueOfCard === "Normalized") {
  //     return setTabValue(3);
  //   } else if (valueOfCard === "Rejected") {
  //     return setTabValue(4);
  //   } else if (valueOfCard === "Completed") {
  //     return setTabValue(5);
  //   }
  // }, [valueOfCard]);
  // //sorting
  //headings-sort


  //filter
  const ITEM_HEIGHT = 28;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth: 400,

      },
    },
  };
  const MenuPropsGrade = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 70,
      },
    },
  };
  const [status, setStatus] = React.useState("");
  // console.log(empEmployeeCode, "position");
  const handleChangeEmployeeCode = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };
  const [empFullName, setempFullName] = React.useState("");
  console.log(empFullName, "position");
  const handleChangeFullName = (event: SelectChangeEvent) => {
    setempFullName(event.target.value);
  };
  const [employeeCode, setemployeeCode] = React.useState("");
  // console.log(employeeCode, "employeeCode");
  const handleChangeemployeeCode = (event: SelectChangeEvent) => {
    setemployeeCode(event.target.value);
  };
  const [employeeName, setemployeeName] = React.useState("");
  //console.log(employeeName, "position");
  const handleChangeemployeeName = (event: SelectChangeEvent) => {
    setemployeeName(event.target.value);
  };
  const [positions, setPositions] = React.useState("");
  console.log(positions, "position");
  const handleChangeposition = (event: SelectChangeEvent) => {
    setPositions(event.target.value);
  };
  const [empgrades, setempGrades] = React.useState("");
  console.log(empgrades, "position");
  const handleChangegrades = (event: SelectChangeEvent) => {
    setempGrades(event.target.value);
  };
  //filter
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    // setPageNumber(pageNumber + 1)
  };

  //populating filter for status  dropdown
  const [anchorElnew, setAnchorElnew] = React.useState<null | HTMLElement>(
    null
  );
  const opennew = Boolean(anchorElnew);
  const handleClicknew = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnew(event.currentTarget);
  };
  const handleClosenew = (event: React.MouseEvent<HTMLElement>) => {
    // console.log(event?.target.value)
    setAnchorElnew(null);
  };
  const handleTarget = (event: any) => {
    // console.log(event?.target.value)
    setStatus(event?.target?.getAttribute("value"))
    // setempEmployeeCode(event?.target?.value?.toString());
    console.log(event?.target.name.toString(), "handleTarget")
    // setAnchorElnew(null);
    handleClosenew(event);
  };
  //Legal Full Name
  const [anchorElnewFullName, setAnchorElnewFullName] = React.useState<null | HTMLElement>(
    null
  );
  const openFullName = Boolean(anchorElnewFullName);
  const handleClickFullName = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewFullName(event.currentTarget);
  };
  const handleCloseFullName = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewFullName(null);
  };
  const handleTargetFullName = (event: any) => {

    setempFullName(event?.target?.getAttribute("value"));


    setAnchorElnewFullName(null);
  };
  //Legal Full Name
  //Position
  const [anchorElnewservicePosition, setAnchorElnewservicePosition] = React.useState<null | HTMLElement>(
    null
  );
  const openservicePosition = Boolean(anchorElnewservicePosition);
  const handleClickservicePosition = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewservicePosition(event.currentTarget);
  };
  const handleCloseservicePosition = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewservicePosition(null);
  };
  const handleTargetservicePosition = (event: any) => {

    setPositions(event?.target?.getAttribute("value"));


    setAnchorElnewservicePosition(null);
  };
  //Position
  const [anchorElnewserviceGrade, setAnchorElnewserviceGrade] = React.useState<null | HTMLElement>(
    null
  );
  const openserviceGrade = Boolean(anchorElnewserviceGrade);
  const handleClickserviceGrade = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewserviceGrade(event.currentTarget);
  };
  const handleCloseserviceGrade = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewserviceGrade(null);
  };
  const handleTargetserviceGrade = (event: any) => {

    setempGrades(event?.target?.getAttribute("value"));


    setAnchorElnewserviceGrade(null);
  };
  //Grade



  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { data: user } = useLoggedInUser();
  // console.log(user, "user");
  // console.log(users, "users");
  // console.log(statusSort, "statussort");
  // const {data: filtenrData,refetch } = useGetEmployeeByStatusQuery(filter);
  // const { data: employeeData, refetch } = useGetEmployeeQuery("all");
  //const employee_code = user?.manager_code?.toString();


  const PAGE_NUMBER = 5
  const SELECT_FOR_DASHBOARD = `workflow_history,employee_code,legal_full_name,overall_rating,first_name,position_long_description,section,sub_section,grade,appraisal.appraiser_rating,reviewer.reviewer_rating,normalizer.normalizer_rating,appraisal.status,appraisal.appraiser_status,reviewer.reviewer_status,normalizer.normalizer_status,reviewer.rejection_count,appraisal.objective_description,reviewerIsDisabled`
  const { data: employeeData } = useGetEmployeeByFilterQuery(
    `?manager_code=${user?.employee_code}&limit=600&select=${SELECT_FOR_DASHBOARD}`
  );
  // const { data: reviewerData } = useGetReviewerEmployeeByFilterQuery(
  //   '62ac2037c1c19127416ab14c'
  // );
  // console.log(reviewerData,'reviewerData')

  // console.log(employeeData?.pagination?.next?.page,'employeeData')
  useEffect(() => {
    setUsers(employeeData1)
  }, [employeeData1]);


// function for getting status  based on appraisal status
  const getStatus = (status: any) => {
    if (status == "in-progress") {
      return "In progress"
    } else if (status == "not-started") {
      return "Not started"
    } else if (status == "normalized") {
      return "Normalized"
    } else if (status == "rejected") {
      return "Rejected "
    } else if (status == "completed") {
      return "Completed "
    }
  }

// function for eye icon viewpa
  const viewPAClickHandler = (j: any) => {
    // window.open(`${PREVIOUS_VIEWPA}/employee/${j._id}`, '_blank') { state: { from: 1 } }
    navigate(`${PREVIOUS_VIEWPA}/employee/${j._id}`,{state:{ valueOfActiveCalender:valueOfActiveCalender}})
  };
// function for getting pending action status
  const getPAStatus = (j: any) => {
    if (
      j.appraisal?.objective_description &&
      j.appraisal?.objective_description.length === 0
    )
      return " PA Not Started";
    else if (j?.appraisal?.status == "completed") return "-";
    else if (j?.appraisal?.appraiser_status === "pending")
      return " Pending with Appraiser";
    else if (j?.appraisal?.status === "normalized")
      return " Pending with Employee";
    else if (j?.appraisal?.appraiser_status?.includes("draft")) return " Pending with Appraiser (Draft)";
    else if (
      j?.appraisal?.appraiser_status === "submitted" &&
      (j?.reviewer?.reviewer_status == "pending" ||
        j?.reviewer?.reviewer_status == "draft")
    )
      return " Pending with Reviewer";
    else if (
      j?.appraisal?.appraiser_status === "accepted" &&
      (j?.reviewer?.reviewer_status == "pending" || j?.reviewer?.reviewer_status == "appraiser-accepted" ||
        j?.reviewer?.reviewer_status == "draft")
    )
      return " Pending with Reviewer";
    else if (j?.reviewer?.reviewer_status == "appraiser-rejected" || j?.reviewer?.reviewer_status == "draft") {
      return " Pending with Reviewer";
    } else if (
      (j?.reviewer?.reviewer_status == "accepted") &&
      (j?.normalizer?.normalizer_status == "pending" || j?.normalizer?.normalizer_status == "draft")
    )
      return " Pending with HR Normalizer";
    else if (j?.appraisal?.appraiser_status === "reviewer-rejected")
      return " Pending with Appraiser (Reviewer Rejection)";
    else if (j?.appraisal?.appraiser_status === "normalizer-rejected")
      return " Pending with Appraiser (Normalizer Rejection)";
    else if (j?.appraisal?.appraiser_status === "appraiser-rejected-employee")
      return " Pending with Employee";
    else if (j?.appraisal?.appraiser_status === "employee-rejected")
      return " Pending with Appraiser (Employee Rejection)";
    else if (
      j?.reviewer?.reviewer_status == "accepted" &&
      j?.normalizer.normalizer_status == "employee-rejected"
    )
      return " Pending with HR Normalizer";
    else if (j?.normalizer?.normalizer_status == "accepted")
      return " Pending with Employee";
    else if (j?.normalizer?.normalizer_status === "draft")
      return " Pending with HR Normalizer";
    else if (
      j?.reviewer?.reviewer_status == "rejected" &&
      j?.reviewer?.rejection_count == 3 &&
      (j?.normalizer?.normalizer_status == "pending" || j?.normalizer?.normalizer_status == "draft")
    )
      return " Pending with HR Normalizer";
    else if (j?.normalizer?.normalizer_status == "employee-rejected")
      return " Pending with HR Normalizer";
    else if (j?.appraisal?.appraiser_status === "employee-rejected")
      return " Pending with Appraiser";
    else if (
      j?.reviewer?.reviewer_status == "normalizer-rejected" &&
      j?.reviewer?.reviewer_status == "appraiser-rejected"
    )
      return "Pending with Reviewer";
    else if (
      j?.appraisal?.appraiser_status == "normalizer-rejected" &&
      j?.appraisal?.appraiser_status == "accepted"
    )
      return "Pending with Reviewer";
    else return "-";
  };

  //  Filter icon
  const [icon, setIcon] = React.useState<any>([]);
  const [icon1, setIcon1] = React.useState<any>([]);
  const [icon2, setIcon2] = React.useState<any>([]);
  const [icon3, setIcon3] = React.useState<any>([]);
  const [GradesFilter, setGradesFilter] = React.useState<string[]>([]);
  const [positionsFilter, setpositionsFilter] = React.useState<string[]>([]);
  const [statusFilter, setstatusFilter] = React.useState<string[]>([]);

  useEffect(() => {
    if (statusFilter?.length == 0) {
      setIcon2(false);
    } else {
      setIcon2(true);
    }
  }, [statusFilter])
  useEffect(() => {
    if (status === "None" || status === "") {
      setIcon(false);
    } else {
      setIcon(true);
    }
  }, [status])
  useEffect(() => {
    if (positionsFilter?.length == 0) {
      setIcon1(false);
    } else {
      setIcon1(true);
    }
  }, [positionsFilter])
  useEffect(() => {
    if (positions === "None" || positions === "") {
      setIcon2(false);
    } else {
      setIcon2(true);
    }
  }, [positions])
  useEffect(() => {
    if (GradesFilter?.length === 0) {
      setIcon3(false);
    } else {
      setIcon3(true);
    }
  }, [GradesFilter])

  const [isDrawerOpen, setisDrawerOpen] = React.useState(false);
  const handleExportFunction = () => {
    setisDrawerOpen(true);
  };
  const [columnHeaders, setcolumnHeaders] = React.useState<any>({
    Ecode: true,
    Ename: true,
    Firstname: true,
    Eposition: true,
    EGrade: true,
    Ependingaction: true,
    Apprating: true,
    Revrating: true,
    Normrating: true,
    Status: true,
    ViewPA: true,
    FirstName: true,
    SupervisoryRolee: true,
    SupervisoryRole:true,
    Function: true,
    ServiceReferenceDate: true,
    PositionCode: true,
    division: true,
    Section: true,
    ESection: true,
    EDivision: true,
    SubSection: true,
    ManagerCode: true,
    ManagerName: true,
    ManagerPosition: true,
    PendingAction:true,
    WorkLocation: true,
    GradeSet: true,
    JobCode: true,
    JobTitle: true,
    JobLevel: true,
    AppraiserName: true,
    Reviewername: true,
    Normalizername: true,
    PotentialLevel: true,
    TalentCategory: true,
    employeerating: true,
    appraiserRating: true,
    // reviewerRating: true,
    // normalizerRating: true,
    OverallRating: true,
    PreviousRating: true,
    AppraiserCode: true,
    ReviewerCode: true,
    NormalizerCode: true,
    ProbationStatus: true,
    ESubSection: true,
    // ReviewerName: true,
    // NormalizerName: true,
    // appraiserCode:true,
    // reviewerCode:true,
    // normalizerCode:true,
    // status:true,
    ReviewerName: true,
    NormalizerName: true,
    appraiserCode:true,
    reviewerCode:true,
    normalizerCode:true,
    status:true,
    EmailId: true,
    EmailID:true,
    CalendarName:true,
    SelectAll: true,
    workflowHistory : true,
  })
  const checkedCount = Object.keys(columnHeaders).reduce((count:any, key:any) => {
    if (key !== 'SelectAll') {
      return count + (columnHeaders[key] ? 1 : 0);
    }
    return count;
  }, 0);
  
  const totalCount = Object.keys(columnHeaders).length - 1; 
  const [columnHeadersDisplay, setcolumnHeadersDisplay] = useState<any>({
    TrainingRecommendation:true,
    Areaofimprovement:true,
    furtherrecommendation:true
  })
// checkedcount and totalcount used for choose fields select all button 
  const [heading1, setheading1] = React.useState(true);
  const [employeerating, setemployeerating] = React.useState(true);
  const [headingOfOverallRating, setheadingOfOverallRating] = React.useState(true);
  const [headingOfPendingAction, setheadingOfPendingAction] = React.useState(true);
  const [Areaofimprovement, setAreaofimprovement] = React.useState(true);
  const [TrainingRecommendation, setTrainingRecommendation] = React.useState(true);
  const [furtherrecommendation, setfurtherrecommendation] = React.useState(true);
  const handleAreaofimprovement = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAreaofimprovement(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeadersDisplay((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlefurtherrecommendation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setfurtherrecommendation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeadersDisplay((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleTrainingRecommendation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrainingRecommendation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeadersDisplay((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheadingEcode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading1(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleReviewerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNormalizerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleemployeerating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setemployeerating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAppraiserRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleOverallRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfOverallRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [firstname, setfirstname] = React.useState(true);
  const handlefirstname = (event: React.ChangeEvent<HTMLInputElement>) => {
    setfirstname(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading2, setheading2] = React.useState(true);
  const handleheading2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading2(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading3, setheading3] = React.useState(true);
  const handleheading3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading3(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [headingSN, setheadingSN] = React.useState(true);
  const handleheadingSN = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingSN(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [headingAppraiser, setheadingAppraiser] = React.useState(true);
  const handleheadingAppraiser = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingAppraiser(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [headingReviewer, setheadingReviewer] = React.useState(true);
  const handleheadingReviewer = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingReviewer(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [headingNormalizer, setheadingNormalizer] = React.useState(true);
  const handleheadingNormalizer = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingNormalizer(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
   const [statusValue, setstatusValue] = React.useState(true);
  const handleStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setstatusValue(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading10, setheading10] = React.useState(true);
  const handleheading10 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading10(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading13, setheading13] = React.useState(true);
  const handleheading13 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading13(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading14, setheading14] = React.useState(true);
  const handleheading14 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading14(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlePendingAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPendingAction(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleWorkflowHistory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const [headingOfPotentialLevel, setheadingOfPotentialLevel] = React.useState(true);
  const handlePotentialLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPotentialLevel(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [headingOfPreviousRating, setheadingOfPreviousRating] = React.useState(true);
  const handlePreviousRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPreviousRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [talentcategory, setTalentcategory] = React.useState(true);
  const handletalentcategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTalentcategory(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [AppraiserCode, setAppraiserCode] = React.useState(true);
  const handleAppraiserCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppraiserCode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [ReviewerCode, setReviewerCode] = React.useState(true);
  const handleReviewerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReviewerCode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSelectAll = (selectAll:any) => {
    const updatedColumnHeaders = { ...columnHeaders };
    Object.keys(updatedColumnHeaders).forEach((key) => {
      updatedColumnHeaders[key] = selectAll;
    });
    setcolumnHeaders(updatedColumnHeaders);
  };
  const [NormalizerCode, setNormalizerCode] = React.useState(true);
  const handleNormalizerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNormalizerCode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [emailId, setEmailId] = React.useState(true);
  const handleEmailId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailId(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [managerCode, setManagerCode] = React.useState(true);
  const handleManagerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setManagerCode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [managerName, setManagerName] = React.useState(true);
  const handleManagerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setManagerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [ServiceReferenceDate, setServiceReferenceDate] = React.useState(true);
  const handleServiceReferenceDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceReferenceDate(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [Function, setFunction] = React.useState(true);
  const handleFunction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFunction(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [sRole, setsRole] = React.useState(true);
  const handleSupervisoryRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsRole(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [headingcalendar, setheadingcalendar] = React.useState(true);
  const handleheadingCalendar = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingcalendar(event.target.checked);
    const { name, checked } = event.target;
      setcolumnHeaders((prevColumnHeaders:any) => ({
        ...prevColumnHeaders,
        [name]: checked,
      }));
  }
  const [divisionVal, setdivisionVal] = React.useState(true);
  const handledivisionVal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setdivisionVal(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [sectionVal, setsectionVal] = React.useState(true);
  const handlesectionVal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsectionVal(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };

  const handleExportFunction1 = () => {
    const filteredEmployeeData = users?.filter((item: any) => {
      return item?.appraisal?.status === "completed";
    });
    console.log(filteredEmployeeData?.length,"length")
    if(filteredEmployeeData?.length == 0){
      setGenerateReportOpen(true)
    }else{
      setGenerateReportOpen(false)
      setisDrawerOpen1(true);
    }
  };
  const handleExport = () => {
    let mapped =activeTemplate
      ?.filter((item1: any) => {
        if (statusFilter.includes("None") || statusFilter.length === 0) {
          return item1;
        } else {
          return !!statusFilter?.find((item2: any) => item1?.appraisal?.status === item2)
        }
      })
      ?.filter((item1: any) => {
        if (positionsFilter.includes("None") || positionsFilter.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      ?.filter((item1: any) => {
        if (GradesFilter.includes("None") || GradesFilter.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      .filter((j: any) => {
        if (enteredName === "") {
          return j;
        } else if (
          (j?.employee_code !== undefined &&
            j?.employee_code
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.legal_full_name !== undefined &&
            j?.legal_full_name
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j.grade !== undefined &&
            j?.grade
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j.position_long_description !== undefined &&
            j?.position_long_description
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.appraisal?.status !== undefined &&
            j?.appraisal?.status
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (getPAStatus(j) !== undefined &&
            getPAStatus(j)
              ?.toLocaleLowerCase()
              ?.includes(enteredName?.toLocaleLowerCase()))
        ) {
          return j;
        }
        })
        ?.map((j: any) => {
          let inputDate = j?.service_reference_date
          const dateParts = inputDate?.split("-");
          let date = new Date(inputDate);
          const year = date?.getFullYear();
          const month = date?.toLocaleString("default", { month: "short" });
          const day = date?.getDate();
          const formattedDate = `${day}-${month}-${year}`;
          let exportData: any = {}
           if (columnHeaders["CalendarName"] == true) exportData["Calendar Name"] = valueOfActiveCalender
          if (columnHeaders["Ecode"] == true) exportData["Ecode"] = j?.employee_code
          if (columnHeaders["Ename"] == true) exportData["Employee Name"] = j?.legal_full_name
          if (columnHeaders["Firstname"] == true) exportData["Known As"] = j?.first_name
          if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date"] = formattedDate
          if (columnHeaders["Eposition"] == true) exportData["Position"] = j?.position_long_description
          if (columnHeaders["EGrade"] == true) exportData["Grade"] = j?.grade
          if (columnHeaders["Function"] == true) exportData["Function"] = j?.function
          if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = j?.isSupervisor != true ? "N-SP" : "SP"
          if (columnHeaders["EmailID"] == true) exportData["Email Id"] = j?.email
          // if (columnHeaders["division"] == true) exportData["Division"] = j?.division
          if (columnHeaders["EDivision"] == true) exportData["Company"] = j?.division
          if (columnHeaders["ESection"] == true) exportData["Section"] = j?.section
          if (columnHeaders["SubSection"] == true) exportData["Sub-section"] = j.sub_section
          if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = j?.work_location
          if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = j?.appraiser_code
          if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = j?.appraiser_name
          if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = j?.reviewer_code
          if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = j?.reviewer_name
          if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = j?.normalizer_code
          if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = j?.normalizer_name
          if (columnHeaders["employeerating"] === true) {const employeeRating = parseFloat(j?.employee_rating);
            exportData["Employee Rating"] = isNaN(employeeRating) ? '-' : employeeRating.toFixed(2);
          }
          if (columnHeaders["appraiserRating"] === true) {
            const appraiserRating = parseFloat(
              j?.normalized_overallRating == 0 ?
                (parseFloat(j?.appraiser_rating)) :
                j?.normalized_overallRating
            );
            exportData["Appraiser Rating"] = isNaN(appraiserRating) ? '-' : appraiserRating.toFixed(2);
          }
          // if (columnHeaders["reviewerRating"] === true) {const reviewerRating = parseFloat(j?.reviewer_rating);
          //   exportData["Reviewer Status"] = isNaN(reviewerRating) ? '' : reviewerRating.toFixed(2);
          // }
          // if (columnHeaders["normalizerRating"] === true) {const normalizerRating = parseFloat(j?.normalizer_rating);
          //   exportData["HR Normalizer Status"] = isNaN(normalizerRating) ? '' : normalizerRating.toFixed(2);
          // }
          if (columnHeaders["OverallRating"] === true) {const OverallRating = parseFloat(j?.overall_rating);
            exportData["Overall Rating"] = isNaN(OverallRating) ? '-' : OverallRating.toFixed(2);
          }
          if (columnHeaders["PreviousRating"] === true) {const PreviousRating = parseFloat(j?.previous_rating);
            exportData["Previous Period Rating"] = isNaN(PreviousRating) ? '-' : PreviousRating.toFixed(2);
          }
          if (columnHeaders["PotentialLevel"] == true) exportData["Potential Level"] = (j?.appraisal?.potential !== "" ? j?.appraisal?.potential : "-")
          if (columnHeaders["TalentCategory"] == true) exportData["Talent Category"] =  (j?.talent_category !== "" || j?.talent_category !== undefined
            ? j?.talent_category : "-")
          if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = j?.manager_code
          if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = j?.manager_name
          if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = j?.manager_position
          if (columnHeaders["status"] == true) exportData["Status"] = getStatus(j?.appraisal?.status)
          if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] = j.appraisal?.status == "not-started" ? "Pending with Appraiser" :j.appraisal?.status == "completed" ? ("-") : j.appraisal.pa_status
          if (columnHeaders["workflowHistory"] == true) exportData["Workflow History"] = j.workflow_history?.map((item: any) => item.pa_action_by)?.join(';\n');
          return exportData
        });
    const a = [1]
    const Emptymapped = a.map((j: any) => {
      let exportData: any = {}
      if (columnHeaders["CalendarName"] == true) exportData["Calendar Name"] = ""
      if (columnHeaders["Ecode"] == true) exportData["Ecode"] = ""
      if (columnHeaders["Ename"] == true) exportData["Employee Name"] = ""
      if (columnHeaders["Firstname"] == true) exportData["Known As"] = ""
      if (columnHeaders["Eposition"] == true) exportData["Position"] = ""
      if (columnHeaders["EGrade"] == true) exportData["Grade"] = ""
      if (columnHeaders["ProbationStatus"] == true) exportData["Probation Status"] = ""
      if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = ""
      if (columnHeaders["Function"] == true) exportData["Function "] = ""
      if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Date"] = ""
      if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = ""
      if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = ""
      if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = ""
      if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = ""
      if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = ""
      if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = ""
      if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = ""
      if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = ""
      if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = ""
      // if (columnHeaders["division"] == true) exportData["Division"] = ""
      if (columnHeaders["EDivision"] == true) exportData["Company"] = ""
      if (columnHeaders["ESection"] == true) exportData["Section"] = ""
      if (columnHeaders["SubSection"] == true) exportData["Sub-section"] = ""
      if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = ""
      if (columnHeaders["EmailID"] == true) exportData["Email Id"] = ""
      if (columnHeaders["status"] == true) exportData["Status"] = ""
      if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] =""
      if (columnHeaders["workflowHistory"] == true) exportData["Workflow History"] = ""
      return exportData
    });
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(mapped?.length == 0 ? Emptymapped : mapped);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, "MyExcel.xlsx");
  }
  const handleExport3 = (columnHeadersDisplay: any) => {
    const filteredEmployeeData = users?.filter((item: any) => {
      return item?.appraisal?.status === "completed";
    });
      let lastEmployeeCode: string | null = null;
      var wb = XLSX.utils.book_new();
      var wsData: any[][] = [[]];
      wsData.push(
        ["Ecode",
          "Employee Name",
          "Known As",
          "Service Reference Date",
          "Position",
          "Grade",
          // "Division",
          "Company",
          "Overall Rating",
          "Potential Level",
          "Talent Category",
          "Section",
          "Sub-section",
          "Appraiser Code",
          "Appraiser Name",
          "Training Category(Appraiser)",
          "Training Name(Appraiser)",
          "Justification(Appraiser)",
          "Training Category(Employee)",
          "Training Name(Employee)",
          "Justification(Employee)",
        ]
      );
      filteredEmployeeData.forEach((employee: any) => {
        const employeeCode =employee?.employee_code 
        let inputDate = employee?.service_reference_date;
        const dateParts = inputDate?.split("-");
        const date = new Date(inputDate);
        const year = date?.getFullYear();
        const month = date?.toLocaleString("default", { month: "short" });
        const day = date?.getDate();
        const formattedDate = `${day}-${month}-${year}`;
        if (lastEmployeeCode !== employeeCode) {
          const appraisalTraining = employee?.training_recommendation[0]
          const employeeTraining = employee?.training_recommendations_employee[0]
          wsData.push([
            employeeCode,
            employee?.legal_full_name,
            employee?.first_name,
            formattedDate,
            employee?.position_long_description,
            employee?.grade,
            employee?.division,
            employee?.overall_rating?.toFixed(2),
            employee?.appraisal?.potential,
            employee?.talent_category,
            employee?.section,
            employee?.sub_section,
            employee?.appraiser_code,
            employee?.appraiser_name,
            appraisalTraining?.title,
            appraisalTraining?.training_name,
            appraisalTraining?.justification,
            employeeTraining?.title,
            employeeTraining?.training_name,
            employeeTraining?.justification,
          ]);
          lastEmployeeCode = employeeCode;
        }
        const maxTrainingRecommendations = Math.max(employee?.training_recommendation?.length, employee?.training_recommendations_employee?.length);
          for (let i = 1; i < maxTrainingRecommendations; i++) {
          const appraisalTraining = employee?.training_recommendation[i] || {};
          const employeeTraining = employee?.training_recommendations_employee[i] || {};
            wsData.push([
              employeeCode || "",
              employee?.legal_full_name || "",
              employee?.first_name || "",
              formattedDate,
              employee?.position_long_description || "",
              employee?.grade || "",
              employee?.division || "",
              employee?.overall_rating?.toFixed(2)|| "",
              employee?.appraisal?.potential || "",
              employee?.talent_category || "",
              employee?.section || "",
              employee?.sub_section || "",
              employee?.appraiser_code || "",
              employee?.appraiser_name || "", // Empty columns for employee details
              appraisalTraining?.title || "",
              appraisalTraining?.training_name || "",
              appraisalTraining?.justification || "",
              employeeTraining?.title || "",
              employeeTraining?.training_name || "",
              employeeTraining?.justification || "",
            ]);
        }
      });
      const sheet2Data = filteredEmployeeData
      ?.map((j: any, emp: any, employee: any) => {
        let inputDate = j?.service_reference_date
        const dateParts = inputDate?.split("-");
        console.log(inputDate, dateParts, "inputDate")
        let date = new Date(inputDate);
        const year = date?.getFullYear();
        const month = date?.toLocaleString("default", { month: "short" });
        const day = date?.getDate();
        const formattedDate = `${day}-${month}-${year}`;
        let exportData: any = {}
        exportData["Ecode"] = j?.employee_code
        exportData["Employee Name"] = j?.legal_full_name
        exportData["Known As"] = j?.first_name
        exportData["Service Reference Date"] = formattedDate
        exportData["Position"] = j?.position_long_description
        exportData["Grade"] = j?.grade
        exportData["Function"] = j?.function
        exportData["Supervisory Role"] = j?.isSupervisor != true ? "N-SP" : "SP"
        exportData["Overall Rating"] = j?.overall_rating?.toFixed(2)
        exportData["Potential Level"] = (j?.appraisal?.potential)
        // exportData["Division"] = j?.division
        exportData["Company"] = j?.division
        exportData["Section"] = j?.section
        exportData["Sub-section"] = j?.sub_section
        exportData["Appraiser Code"] = j?.appraiser_code
        exportData["Appraiser Name"] = j?.appraiser_name
        exportData["Recommendations"] =  j?.appraisal?.other_recommendation?.map((rec: any) => rec?.name?.name).join(', ')
        exportData["Recommendations Justification"] = j?.appraisal?.other_recommendation_others && j?.appraisal?.other_recommendation_others
        return exportData
      });
      var wsData1: any[][] = [[]];
      wsData1.push(
        ["Ecode",
        "Employee name",
        "Known As",
        "Service Reference Date",
        "Position",
        "Grade",
        // "Division",
        "Company",
        "Overall Rating",
        "Potential Level",
        "Talent Category",
        "Section",
        "Sub-section",
        "Appraiser Code",
        "Appraiser Name",
        "Specific Areas(Appraiser)",
        "Specific Actions(Appraiser)",
        "Specific Areas(Employee)",
        "Specific Actions(Employee)",
        ]
      );
    filteredEmployeeData.forEach((employee: any) => {
      const employeeCode = employee?.employee_code
      let inputDate = employee?.service_reference_date;
      const dateParts = inputDate?.split("-");
      const date = new Date(inputDate);
      const year = date?.getFullYear();
      const month = date?.toLocaleString("default", { month: "short" });
      const day = date?.getDate();
      const formattedDate = `${day}-${month}-${year}`;
      if (lastEmployeeCode !== employeeCode) {
        if (lastEmployeeCode !== null) {
        }
        const maxTrainingRecommendations = Math.max(employee?.training_recommendation?.length, employee?.employee?.training_recommendation.length);
        const appraisalTraining = (employee?.area_of_improvement|| [])[0] || {};
        const employeeTraining = (employee?.area_of_improvements_employee || [])[0] || {};
        // const appraisalSpecificAction = (appraisalTraining[0] || [])[0] || "";
        // const appraisalSpecificArea = (appraisalTraining[0] || [])[0] || "";
        // const employeeSpecificAction = (employeeTraining[0] || [])[0] || "";
        // const appraisalSpecificAction = (appraisalTraining?.specific_actions || [])[0]?.value || "";
        // const employeeSpecificAction = (employeeTraining?.specific_actions || [])[0]?.value || "";
        console.log(employeeTraining,appraisalTraining,"employeeSpecificAction")
        wsData1.push([
          employeeCode ,
          employee?.legal_full_name,
          employee?.first_name,
          formattedDate ,
          employee?.position_long_description,
          employee?.grade,
          employee?.division,
          employee?.overall_rating?.toFixed(2),
          employee?.appraisal?.potential,
          employee?.talent_category,
          employee?.section,
          employee?.sub_section,
          employee?.appraiser_code,
          employee?.appraiser_name,
          appraisalTraining?.specific_area,
          appraisalTraining?.specific_actions,
          employeeTraining?.specific_area,
          employeeTraining?.specific_actions,
          // employeeTraining?.value,
          // employeeSpecificAction || "",
        ]);
        lastEmployeeCode = employeeCode;
      }
      // Update maxTrainingRecommendations for the current employee
      const maxTrainingRecommendations = Math.max(employee?.area_of_improvement?.length, employee?.area_of_improvements_employee?.length);
      // Add training recommendation details for the subsequent rows (excluding the first row)
      for (let i = 1; i < maxTrainingRecommendations; i++) {
        const appraisalTraining = employee?.area_of_improvement[i] || {};
        const employeeTraining = employee?.area_of_improvements_employee[i] || {};
        wsData1.push([
          employeeCode,
          employee?.legal_full_name,
          employee?.first_name,
          formattedDate,
          employee?.position_long_description,
          employee?.grade,
          employee?.division,
          employee?.overall_rating?.toFixed(2),
          employee?.appraisal?.potential,
          employee?.talent_category,
          employee?.section,
          employee?.sub_section,
          employee?.appraiser_code,
          employee?.appraiser_name,
          appraisalTraining?.specific_area|| "", 
          appraisalTraining?.specific_actions || "",
          employeeTraining?.specific_area|| "", 
          employeeTraining?.specific_actions || "",
          // (appraisalTraining || [])[0]?.specific_actions || "",
          // employeeTraining?.value || "",
          // (employeeTraining?.specific_actions || [])[0]?.value || "",
        ]);
      }
    });
// shift method remove first row of index number
        wsData.shift();
        wsData1.shift();
        const sheet3 = XLSX.utils.aoa_to_sheet(wsData1);
        const sheet2 = XLSX.utils.json_to_sheet(sheet2Data, { header: Object.keys(sheet2Data[0]) });
        const sheet1 = XLSX.utils.aoa_to_sheet(wsData);
        if (columnHeadersDisplay.TrainingRecommendation && columnHeadersDisplay.furtherrecommendation && columnHeadersDisplay.Areaofimprovement) {
          XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
          XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
          XLSX.utils.book_append_sheet(wb, sheet2, 'Recommendations');
        } else if (columnHeadersDisplay.TrainingRecommendation && columnHeadersDisplay.furtherrecommendation) {
          XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
          XLSX.utils.book_append_sheet(wb, sheet2, 'Recommendations');
        } else if (columnHeadersDisplay.TrainingRecommendation && columnHeadersDisplay.Areaofimprovement) {
          XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
          XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
        }else if (columnHeadersDisplay.furtherrecommendation && columnHeadersDisplay.Areaofimprovement) {
          XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
          XLSX.utils.book_append_sheet(wb, sheet2, 'Recommendations');
        }else if(columnHeadersDisplay.TrainingRecommendation){
          XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
        }else if(columnHeadersDisplay.furtherrecommendation){
          XLSX.utils.book_append_sheet(wb, sheet2, 'Recommendations');
        }else if( columnHeadersDisplay.Areaofimprovement){
          XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
        }
        XLSX.writeFile(wb, `Performance Appraisal Report -${valueOfActiveCalender}.xlsx`);
    }
  // Function for Close Button on General report 
  const handleCloseGrade1 = () => {
    setisDrawerOpen1(false);
    // function for reseting checkbox state on Generate report
    setcolumnHeadersDisplay({
      TrainingRecommendation:true,
      Areaofimprovement:true,
      furtherrecommendation:true
    })
  }
  const handleCloseGrade = () => {
    // setOpenGrade(false);
    setisDrawerOpen(false);
    // checkbox reset
    setcolumnHeaders({
      Ecode: true,
      Ename: true,
      Firstname: true,
      Eposition: true,
      EGrade: true,
      Ependingaction: true,
      Apprating: true,
      Revrating: true,
      Normrating: true,
      Status: true,
      ViewPA: true,
      FirstName: true,
      SupervisoryRolee: true,
      SupervisoryRole:true,
      Function: true,
      ServiceReferenceDate: true,
      PositionCode: true,
      division: true,
      Section: true,
      ESection: true,
      EDivision: true,
      SubSection: true,
      ManagerCode: true,
      ManagerName: true,
      ManagerPosition: true,
      PendingAction:true,
      WorkLocation: true,
      GradeSet: true,
      JobCode: true,
      JobTitle: true,
      JobLevel: true,
      AppraiserName: true,
      Reviewername: true,
      Normalizername: true,
      PotentialLevel: true,
      TalentCategory: true,
      employeerating: true,
      appraiserRating: true,
      // reviewerRating: true,
      // normalizerRating: true,
      OverallRating: true,
      PreviousRating: true,
      AppraiserCode: true,
      ReviewerCode: true,
      NormalizerCode: true,
      ProbationStatus: true,
      ESubSection: true,
      // ReviewerName: true,
      // NormalizerName: true,
      // appraiserCode:true,
      // reviewerCode:true,
      // normalizerCode:true,
      // status:true,
      ReviewerName: true,
      NormalizerName: true,
      appraiserCode:true,
      reviewerCode:true,
      normalizerCode:true,
      status:true,
      EmailId: true,
      EmailID:true,
      CalendarName:true,
      SelectAll: true,
      workflowHistory : true,
    })
  };
// function for apply button on export to excel
  const handleheadingSortAccept = () => {
    setisDrawerOpen(false);
    handleExport();
    // setcolumnHeaders
  };
  const handleheadingSortAccept1 = () => {
    setisDrawerOpen1(false);
    handleExport3(columnHeadersDisplay)
    setcolumnHeadersDisplay({
      TrainingRecommendation:true,
      Areaofimprovement:true,
      furtherrecommendation:true
    })
  }
  const [activeTemplate, setactiveTemplate] = useState<any>([]);
  //For multislect options
  const [gradesArray, setgradesArray] = React.useState<any>([]);
  const [positionArray, setpositionArray] = React.useState<any>([]);
  const [statusArray, setstatusArray] = React.useState<any>([]);
// function for Filter dropdown
  useEffect(() => {
    let grades = activeTemplate
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.grade - b?.grade;
      })
      ?.map((i: any) => {

        return i?.grade;
      });
    //for filtering graades options
    if (positionsFilter.length > 0) {
      grades = activeTemplate
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.grade - b?.grade;
        })
        ?.filter((i: any) => {
          return !!positionsFilter?.find(item2 => i?.position_long_description === item2)
        })
        ?.map((i: any) => {
          return i?.grade;
        });
    }
    else if (statusFilter?.length > 0) {
      grades = activeTemplate
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.appraisal?.status - b?.appraisal?.status;
        })
        ?.filter((i: any) => {
          return !!statusFilter?.find(item2 => i?.appraisal?.status === item2)
        })
        ?.map((i: any) => {
          return i?.grade;
        });
    }
    // search functionality based on grade
    else if (enteredName?.length > 0) {
      grades = activeTemplate
        .slice()
        ?.sort(function (a: any, b: any) {
          return a?.grade?.localeCompare(
            b?.grade
          );
        })
        ?.filter((i: any) => {
          if (enteredName.length > 0) {
            const enteredTerms = enteredName.toLowerCase().split(" ");
            return enteredTerms.every(term =>
              i?.grade
                ?.toLowerCase()
                .includes(term)
            ) || enteredTerms.every(term =>
              i?.grade?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.position_long_description?.toLowerCase().includes(term)
            )|| enteredTerms.every(term =>
              i?.section?.toLowerCase().includes(term)
            )|| enteredTerms.every(term =>
              i?.employee_code?.toLowerCase().includes(term)
            )|| enteredTerms.every(term =>
              i?.legal_full_name?.toLowerCase().includes(term)
            )
          } else {
            return true;
          }
        })
        ?.map((i: any) => {
          return i?.grade;
        });
    }
    const gradeContents = grades
      ?.filter((c: any, index: any) => {
        return grades?.indexOf(c) === index && c != null && c != undefined;

      });
    setgradesArray(gradeContents);
    let position = activeTemplate
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.position_long_description?.localeCompare(b?.position_long_description);
      })
      ?.map((i: any) => {

        return i?.position_long_description;
      });
    // GradesFilter
    if (GradesFilter?.length > 0) {
      position = activeTemplate
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.position_long_description - b?.position_long_description;
        })
        ?.filter((i: any) => {
          return !!GradesFilter?.find(item2 => i?.grade === item2)
        })
        ?.map((i: any) => {
          return i?.position_long_description;
        });
    }
   else if (statusFilter?.length > 0) {
      position = activeTemplate
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.position_long_description - b?.position_long_description;
        })
        ?.filter((i: any) => {
          return !!statusFilter?.find(item2 => i?.appraisal?.status === item2)
        })
        ?.map((i: any) => {
          return i?.position_long_description;
        });
    }
    else if (enteredName?.length > 0) {
      position = activeTemplate
        .slice()
        ?.sort(function (a: any, b: any) {
          return a?.position_long_description?.localeCompare(
            b?.position_long_description
          );
        })
        ?.filter((i: any) => {
          if (enteredName.length > 0) {
            const enteredTerms = enteredName.toLowerCase().split(" ");
            return enteredTerms.every(term =>
              i?.position_long_description
                ?.toLowerCase()
                .includes(term)
            )|| enteredTerms.every(term =>
              i?.grade?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.position_long_description?.toLowerCase().includes(term)
            )|| enteredTerms.every(term =>
              i?.section?.toLowerCase().includes(term)
            )|| enteredTerms.every(term =>
              i?.employee_code?.toLowerCase().includes(term)
            )|| enteredTerms.every(term =>
              i?.legal_full_name?.toLowerCase().includes(term)
            )
          } else {
            return true;
          }
        })
        ?.map((i: any) => {
          return i?.position_long_description;
        });
    }
    const positionContents = position?.filter((c: any, index: any) => {
      return position?.indexOf(c) === index && c != null && c != undefined;

    });
    setpositionArray(positionContents);
    // status filter
    let status = activeTemplate
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.appraisal?.status?.localeCompare(b?.appraisal?.status);
      })
      ?.map((i: any) => {

        return i?.appraisal?.status;
      });
      if (positionsFilter.length > 0) {
      status = activeTemplate
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.appraisal?.status - b?.appraisal?.status;
        })
        ?.filter((i: any) => {
          return !!positionsFilter?.find(item2 => i?.position_long_description === item2)
        })
        ?.map((i: any) => {
          return i?.appraisal?.status;
        });
    }
    // GradesFilter
   else if (GradesFilter?.length > 0) {
      status = activeTemplate
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.appraisal?.status - b?.appraisal?.status;
        })
        ?.filter((i: any) => {
          return !!GradesFilter?.find(item2 => i?.grade === item2)
        })
        ?.map((i: any) => {
          return i?.appraisal?.status;
        });
    } 
    else if (enteredName?.length > 0) {
      status = activeTemplate
        .slice()
        ?.sort(function (a: any, b: any) {
          return a?.appraisal?.status?.localeCompare(
            b?.appraisal?.status
          );
        })
        ?.filter((i: any) => {
          if (enteredName.length > 0) {
            const enteredTerms = enteredName.toLowerCase().split(" ");
            return enteredTerms.every(term =>
              i?.appraisal?.status
                ?.toLowerCase()
                .includes(term)
            ) || enteredTerms.every(term =>
              i?.grade?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.position_long_description?.toLowerCase().includes(term)
            )|| enteredTerms.every(term =>
              i?.section?.toLowerCase().includes(term)
            )|| enteredTerms.every(term =>
              i?.employee_code?.toLowerCase().includes(term)
            )|| enteredTerms.every(term =>
              i?.legal_full_name?.toLowerCase().includes(term)
            )
          }
          else {
            return true;
          }
        })
        ?.map((i: any) => {
          return i?.appraisal?.status;
        });
    }
    const statusContents = status?.filter((c: any, index: any) => {
      return status?.indexOf(c) === index && c != null && c != undefined;

    });
    setstatusArray(statusContents);
  }, [activeTemplate, GradesFilter,statusFilter, positionsFilter, enteredName])
  //for section multiselect

// onchage for statusfilter
  const isAllstatusFilter =
  statusArray?.length > 0 && statusFilter?.length === statusArray?.length;
const handleChangeSelectstatus = (event: any) => {
  const value = event.target.value;
  if (value[value.length - 1] === "all") {
    console.log((statusFilter?.length === statusFilter?.length ? [] : "select all"), "newwwwww")
    setstatusFilter(statusFilter?.length === statusArray?.length ? [] : statusArray);
    return;
  }
  setstatusFilter(value);
};
// onchange for grade
  const isAllGradesFilter =
    gradesArray?.length > 0 && GradesFilter?.length === gradesArray?.length;
  const newsection = gradesArray?.length == GradesFilter?.length
  const handleChangeSelectGrades = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      console.log((GradesFilter?.length === gradesArray?.length ? [] : "select all"), "newwwwww")
      setGradesFilter(GradesFilter?.length === gradesArray?.length ? [] : gradesArray);
      return;
    }
    setGradesFilter(value);
    setPage(0);
  };
// omchange for position
  const isAllpositionsFilter =
    positionArray?.length > 0 && positionsFilter?.length === positionArray?.length;
  const handleChangeSelectPositions = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setpositionsFilter(positionsFilter?.length === positionArray?.length ? [] : positionArray);
      return;
    }
    setpositionsFilter(value);
    setPage(0);
  };

// function for pagination
  useEffect(() => {
    const paginate = activeTemplate
      ?.filter((item1: any) => {
        if (positionsFilter.includes("None") || positionsFilter.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      ?.filter((item1: any) => {
        if (GradesFilter.includes("None") || GradesFilter.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      ?.filter((item1: any) => {
        if (statusFilter.includes("None") || statusFilter.length === 0) {
          return item1;
        } else {
          return !!statusFilter?.find((item2: any) => item1?.appraisal?.status === item2)
        }
      })
      ?.filter((j: any) => {
        if (enteredName === "") {
          return j;
        } else if (
          (j?.employee_code !== undefined &&
            j?.employee_code
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.legal_full_name !== undefined &&
            j?.legal_full_name
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j.grade !== undefined &&
            j?.grade
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j.position_long_description !== undefined &&
            j?.position_long_description
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.appraisal?.status !== undefined &&
            j?.appraisal?.status
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (getPAStatus(j) !== undefined &&
            getPAStatus(j)
              ?.toLocaleLowerCase()
              ?.includes(enteredName?.toLocaleLowerCase()))
        ) {
          return j;
        }
      })
    settablecount(paginate?.length)
  }, [tabValue, statusFilter,activeTemplate, GradesFilter, enteredName, positionsFilter])
  useEffect(() => {
    const Dashfilter = users &&
      users
        ?.filter((item1: any) => {
          if (positionsFilter.includes("None") || positionsFilter.length === 0) {
            return item1;
          } else {
            return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
          }
        })
        ?.filter((item1: any) => {
          if (GradesFilter.includes("None") || GradesFilter.length === 0) {
            return item1;
          } else {
            return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
          }
        })
        ?.filter((j: any) => {
          if (enteredName === "") {
            return j;
          } else if (
            (j?.employee_code !== undefined &&
              j?.employee_code
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.legal_full_name !== undefined &&
              j?.legal_full_name
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j.grade !== undefined &&
              j?.grade
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j.position_long_description !== undefined &&
              j?.position_long_description
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.appraisal?.status !== undefined &&
              j?.appraisal?.status
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (getPAStatus(j) !== undefined &&
              getPAStatus(j)
                ?.toLocaleLowerCase()
                ?.includes(enteredName?.toLocaleLowerCase()))
          ) {
            return j;
          }
        })
    setactiveTemplate(Dashfilter)
  }, [users, positions, empgrades, tabValue])

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setPageNumber(pageNumber + 1);
      }
    }
  };
// function for no data to display message while empty
  const tableDataFilterdLength = activeTemplate
    ?.filter((item1: any) => {
      if (statusFilter.includes("None") || statusFilter.length === 0) {
        return item1;
      } else {
        return !!statusFilter?.find((item2: any) => item1?.appraisal?.status === item2)
      }
    })
    ?.filter((item1: any) => {
      if (positionsFilter.includes("None") || positionsFilter.length === 0) {
        return item1;
      } else {
        return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
      }
    })
    ?.filter((item1: any) => {
      if (GradesFilter.includes("None") || GradesFilter.length === 0) {
        return item1;
      } else {
        return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
      }
    })
    ?.filter((j: any) => {
      if (enteredName === "") {
        return j;
      } else if (
        (j?.employee_code !== undefined &&
          j?.employee_code
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.legal_full_name !== undefined &&
          j?.legal_full_name
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j.grade !== undefined &&
          j?.grade
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j.position_long_description !== undefined &&
          j?.position_long_description
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.appraisal?.status !== undefined &&
          j?.appraisal?.status
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (getPAStatus(j) !== undefined &&
          getPAStatus(j)
            ?.toLocaleLowerCase()
            ?.includes(enteredName?.toLocaleLowerCase()))
      ) {
        return j;
      }
    })
// function for searchbar
    const maxLengthForSearch = 30;
    const handleSearchBar = (e: any) => {
        if (e.target.value.length > maxLengthForSearch) {
          e.target.value = e.target.value.slice(0, maxLengthForSearch);
        }
        setenteredName(e.target.value);
        setPage(0);
      }
  return (
    <Mytable>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="30px"
        paddingBottom="10px"
      >
      </Stack>
      {approved && <Alert severity="success">Approved successfully!</Alert>}
      <Stack
        marginLeft="24px"
        marginRight="24px"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
         <AlertDialogSuccess
          isAlertOpen={GenerateReportOpen}
          handleAlertClose={handleGenerateReportOpen}
        >
          Unable to Generate Report: No employees have completed appraisals.
        </AlertDialogSuccess>
        <Heading>My Team</Heading>
        <div>
          <Stack direction="row" alignItems="center" gap="12px" >
          <Button
          style={{
            textTransform: "none",
            fontSize: "15px",
            fontFamily: "Arial",
            borderColor: "#3E8CB5",
            color: "#3E8CB5",
            background: "transparent",
            height: "35px",
          }}
          variant="outlined"
          onClick={handleExportFunction1}
        >
          Generate Report
        </Button>
          {((NaviGationFrom == "PAAdmin") || (NaviGationFrom == "PreviousPa")) && (
            <Link to={`/viewExceptionhandling`}
             state={{ calendarId: appCalId, role: "paadmin" }}
             >
                <Button
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: "Arial",
                    borderColor: "#3E8CB5",
                    color: "#3E8CB5",
                    background: "transparent",
                    // width: "100px",
                    height: "35px",
                  }}
                  variant="outlined"
                >Employee Master</Button>
                </Link>
          )}
            <Searchfeild >
              <TextField
                id="outlined-basic"
                placeholder="Search Here..."
                autoComplete="off"
                inputProps={{ maxLength: 256 }}
                onChange={handleSearchBar}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={Searchlensreview} alt="icon" />
                    </InputAdornment>
                  ),
                }}
              />
            </Searchfeild>
            <div>
              <img
                src={Newexcel}
                alt="icon"
                style={{ marginLeft: "15px", marginTop: "5px", cursor: "pointer" }}
                onClick={handleExportFunction}
              />
            </div>
            {Role === "Normalizer" && (
              <img
                src={Expand}
                alt="icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate('/myteamtableexpandviewclosedappraiser', { state: { appCalId: appCalId,users:users,valueOfActiveCalender:valueOfActiveCalender } })
                }}
              />
            )}
          </Stack>
        </div>
        <GenerateReport
          isDrawerOpen1={isDrawerOpen1}
          setisDrawerOpen1={setisDrawerOpen1}
          handleTrainingRecommendation={handleTrainingRecommendation}
          handlefurtherrecommendation={handlefurtherrecommendation}
          handleAreaofimprovement={handleAreaofimprovement}
          handleheadingSortAccept1={handleheadingSortAccept1}
          handleCloseGrade1={handleCloseGrade1}
          columnHeadersDisplay={columnHeadersDisplay} />
        <ExportToexcel 
         isDrawerOpen={isDrawerOpen}
         setisDrawerOpen={setisDrawerOpen}
         checkedCount={checkedCount}
         totalCount={totalCount}
         columnHeaders={columnHeaders}
         handleSelectAll={handleSelectAll}
         handleheadingCalendar={handleheadingCalendar}
         handleEmployeeCode={handleheadingEcode}
         handleheading1={handleheading2}
         handlefirstName={handlefirstname}
         handleServiceReferenceDateData={handleServiceReferenceDate}
         handleheading2={handleheading3}
         handleheading3={handleheadingSN}
         handleFunctionData={handleFunction}
         handleSupervisoryRoleData={handleSupervisoryRole}
         handleEmailIDData={handleEmailId}
         handleDivision={handledivisionVal}
         handleSection={handlesectionVal}
         handleSubSection={handleheading10}
         handleWorkLocation={handleheading14}
         handleAppCodes={handleAppraiserCode}
         handleAppraiserName={handleheadingAppraiser}
         handleRevCodes={handleReviewerCode}
         handleReviewerName={handleheadingReviewer}
         handleNorCodes={handleNormalizerCode}
         handleNormalizerName={handleheadingNormalizer}
         handleemployeerating={handleemployeerating}
         handleAppraiserRating={handleAppraiserRating}
         handleReviewerRating={handleReviewerRating}
         handleNormalizerRating={handleNormalizerRating}
         handleOverallRating={handleOverallRating}
         handlePreviousRating={handlePreviousRating}
         handlePotentialLevel={handlePotentialLevel}
         handleTalentCategory={handletalentcategory}
         handleManagerCode={handleManagerCode}
         handleManagerName={handleManagerName}
         handleManagerPosition={handleheading13}
         handleStatus={handleStatus}
         handleheadingSortAccept={handleheadingSortAccept}
         handlePendingAction={handlePendingAction}
         handleCloseGrade={handleCloseGrade}
         handleWorkflowHistory= {handleWorkflowHistory}/> 
      </Stack>
            <TableHeadings>
                <TableContainer
                >
                  <Table size="small" aria-label="simple table" stickyHeader >
                    <TableHead
                      style={{ height: "30px" }}>
                      <TableRow
                        sx={{
                          "& td, & th": {
                            whiteSpace: "nowrap",
                            bgcolor: "#eaeced",
                          },
                        }}
                      >
                        <TableCell align="center"
                          sx={{
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                          width="6%">
                          <div
                            style={{
                              color: "#3e8cb5",
                              fontSize: "14px",
                              fontWeight: "600",
                              fontFamily: "Arial",
                              border: "none",
                              background: "none",
                            }}
                          >
                            Ecode
                          </div>
                        </TableCell>
                        <TableCell align="center" width="20%"
                          sx={{
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >Employee Name
                        </TableCell>
                        <TableCell align="center" width="15%"
                          sx={{
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          <div
                            style={{
                              color: "#3e8cb5",
                              fontSize: "14px",
                              fontWeight: "600",
                              fontFamily: "Arial",
                              border: "none",
                              background: "none",
                            }}
                          ><FormControl sx={{ m: 0, width: 80, height: "0" }}>
                              <Stack direction="row">
                                <span>Position</span>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  size="small"
                                  sx={{
                                    width: "25px", fontSize: "0rem",
                                    "& .MuiSvgIcon-root": {
                                      color: "#3e8cb5 !important"
                                    },
                                  }}
                                  disableUnderline
                                  variant="standard"
                                  MenuProps={MenuProps}
                                  multiple
                                  value={positionsFilter}
                                  onChange={handleChangeSelectPositions}
                                  renderValue={(selected) => selected.join(', ')}
                                ><MenuItem
                                    style={{
                                      fontSize: "14px",
                                      color: "#333333",
                                      fontFamily: "Arial",
                                      padding: "0px",
                                    }}
                                    key="all"
                                    value="all"
                                    classes={{
                                      root: isAllpositionsFilter ? classes.selectedAll : "",
                                    }}
                                  ><ListItemIcon>
                                      <Checkbox
                                        sx={{

                                          "& .MuiSvgIcon-root": {
                                            fontSize: "14px !important",
                                          },
                                        }}
                                        style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                        size="small"
                                        classes={{
                                          indeterminate: classes.indeterminateColor,
                                        }}
                                        checked={isAllpositionsFilter}
                                        indeterminate={
                                          positionsFilter?.length > 0 &&
                                          positionsFilter?.length < positionArray?.length
                                        }
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{
                                        "& .MuiTypography-root": {
                                          fontSize: "13px",
                                          fontFamily: "Arial",
                                          color: "#333333",
                                          paddingRight: "10px"
                                        },
                                      }}
                                      classes={{ primary: classes.selectAllText }}
                                      primary="Select All"
                                    />
                                  </MenuItem>
                                  {positionArray?.map((option: any) => (
                                    <MenuItem
                                      style={{
                                        fontSize: "14px",
                                        color: "#333333",
                                        fontFamily: "Arial",
                                        padding: "0px",
                                      }}
                                      key={option}
                                      value={option}
                                    ><ListItemIcon>
                                        <Checkbox
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: "14px !important",
                                            },
                                          }}
                                          style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                          size="small"
                                          checked={positionsFilter.indexOf(option) > -1}
                                        />
                                      </ListItemIcon>
                                      <ListItemText sx={{
                                        "& .MuiTypography-root": {
                                          fontSize: "13px",
                                          fontFamily: "Arial",
                                          color: "#333333",
                                          paddingRight: "10px"
                                        },
                                      }} primary={option} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                {icon1 && (
                                  <FilterAltTwoToneIcon />)}
                              </Stack>
                            </FormControl>
                          </div>
                        </TableCell>
                        <TableCell align="center" width="5%">
                          <div
                            style={{
                              color: "#3e8cb5",
                              fontSize: "14px",
                              fontWeight: "600",
                              fontFamily: "Arial",
                              border: "none",
                              background: "none",
                            }}
                          ><FormControl sx={{ m: 0, width: 80, height: "0" }}>
                              <Stack direction="row">
                                <span>Grade</span>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  size="small"
                                  sx={{
                                    width: "25px", fontSize: "0rem",
                                    "& .MuiSvgIcon-root": {
                                      color: "#3e8cb5 !important"
                                    },
                                  }}
                                  disableUnderline
                                  variant="standard"
                                  MenuProps={MenuProps}
                                  multiple
                                  value={GradesFilter}
                                  onChange={handleChangeSelectGrades}
                                  renderValue={(selected) => selected.join(', ')}
                                >
                                  <MenuItem
                                    style={{
                                      fontSize: "14px",
                                      color: "#333333",
                                      fontFamily: "Arial",
                                      padding: "0px",
                                    }}
                                    key="all"
                                    value="all"
                                    classes={{
                                      root: isAllGradesFilter ? classes.selectedAll : "",
                                    }}
                                  ><ListItemIcon>
                                      <Checkbox
                                        sx={{

                                          "& .MuiSvgIcon-root": {
                                            fontSize: "14px !important",
                                          },
                                        }}
                                        style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                        size="small"
                                        classes={{
                                          indeterminate: classes.indeterminateColor,
                                        }}
                                        checked={isAllGradesFilter}
                                        indeterminate={
                                          GradesFilter?.length > 0 &&
                                          GradesFilter?.length < gradesArray?.length
                                        }
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{
                                        "& .MuiTypography-root": {
                                          fontSize: "14px",
                                          fontFamily: "Arial",
                                          color: "#333333",
                                          paddingRight: "10px"
                                        },
                                      }}
                                      classes={{ primary: classes.selectAllText }}
                                      primary="Select All"
                                    />
                                  </MenuItem>
                                  {gradesArray?.map((option: any) => (
                                    <MenuItem
                                      style={{
                                        fontSize: "14px",
                                        color: "#333333",
                                        fontFamily: "Arial",
                                        padding: "0px",
                                      }}
                                      key={option}
                                      value={option}
                                    ><ListItemIcon>
                                        <Checkbox
                                          sx={{

                                            "& .MuiSvgIcon-root": {
                                              fontSize: "14px !important",
                                            },
                                          }}
                                          style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                          size="small"
                                          checked={GradesFilter.indexOf(option) > -1}
                                        />
                                      </ListItemIcon>
                                      <ListItemText sx={{
                                        "& .MuiTypography-root": {
                                          fontSize: "13px",
                                          fontFamily: "Arial",
                                          color: "#333333",
                                          paddingRight: "10px"
                                        },
                                      }} primary={option} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                {icon3 && (
                                  <FilterAltTwoToneIcon />)}
                              </Stack>
                            </FormControl>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          width="7%"
                          text-align="-webkit-center"
                        ><div
                          style={{
                            color: "#3e8cb5",
                            fontSize: "14px",
                            fontWeight: "600",
                            fontFamily: "Arial",
                            border: "none",
                            background: "none",
                            wordWrap: "break-word",
                          }}
                        >Overall<br></br> Rating</div></TableCell>
                        <TableCell align="center" text-align="-webkit-center" width="6%">
                          <div
                            style={{
                              color: "#3e8cb5",
                              fontSize: "14px",
                              fontWeight: "600",
                              fontFamily: "Arial",
                              border: "none",
                              background: "none",
                            }}
                          >
                             <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                              <Stack direction="row">
                                <span>Status</span>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  size="small"
                                  sx={{
                                    width: "25px", fontSize: "0rem",
                                    "& .MuiSvgIcon-root": {
                                      color: "#3e8cb5 !important"
                                    },
                                  }}
                                  disableUnderline
                                  variant="standard"
                                  MenuProps={MenuProps}
                                  multiple
                                  value={statusFilter}
                                  onChange={handleChangeSelectstatus}
                                  renderValue={(selected) => selected.join(', ')}
                                >
                                  <MenuItem
                                    style={{
                                      fontSize: "14px",
                                      color: "#333333",
                                      fontFamily: "Arial",
                                      padding: "0px",
                                    }}
                                    key="all"
                                    value="all"
                                    classes={{
                                      root: isAllstatusFilter ? classes.selectedAll : "",
                                    }}
                                  >
                                    <ListItemIcon>
                                      <Checkbox
                                        sx={{

                                          "& .MuiSvgIcon-root": {
                                            fontSize: "14px !important",
                                          },
                                        }}
                                        style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                        size="small"
                                        classes={{
                                          indeterminate: classes.indeterminateColor,
                                        }}
                                        checked={isAllstatusFilter}
                                        indeterminate={
                                          statusFilter?.length > 0 &&
                                          statusFilter?.length < statusArray?.length
                                        }
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{
                                        "& .MuiTypography-root": {
                                          fontSize: "13px",
                                          fontFamily: "Arial",
                                          color: "#333333",
                                          paddingRight: "10px"
                                        },
                                      }}
                                      classes={{ primary: classes.selectAllText }}
                                      primary="Select All"
                                    />
                                  </MenuItem>
                                  {statusArray?.map((option: any) => (
                                    <MenuItem
                                      style={{
                                        fontSize: "14px",
                                        color: "#333333",
                                        fontFamily: "Arial",
                                        padding: "0px",
                                      }}
                                      key={option}
                                      value={option}
                                    >
                                      <ListItemIcon>
                                        <Checkbox
                                          sx={{

                                            "& .MuiSvgIcon-root": {
                                              fontSize: "14px !important",
                                            },
                                          }}
                                          style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                          size="small"
                                          checked={statusFilter.indexOf(option) > -1}
                                        />
                                      </ListItemIcon>
                                      <ListItemText sx={{
                                        "& .MuiTypography-root": {
                                          fontSize: "13px",
                                          fontFamily: "Arial",
                                          color: "#333333",
                                          paddingRight: "10px"
                                        },
                                      }} primary={getStatus(option)} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                {icon2 && <FilterAltTwoToneIcon />}
                              </Stack>
                            </FormControl>
                          </div>
                        </TableCell>
                        <TableCell
                          width="6%"
                          style={{
                            color: "#3e8cb5",
                            fontSize: "14px",
                            fontWeight: "600",
                            fontFamily: "Arial",
                            textAlign: "center",
                          }}
                        >
                          View<br></br> PA
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {tableDataFilterdLength?.length > 0 ? (
                      <TableBody
                        ref={listInnerRef}
                        onScroll={onScroll}
                      >
                        {activeTemplate
                         ?.sort(
                          (a: any, b: any) =>
                            b?.overall_rating - a?.overall_rating // Sort by pa_rating in descending order
                        )
                          .filter((item1: any) => {
                            if (statusFilter.includes("None") || statusFilter.length === 0) {
                              return item1;
                            } else {
                              return !!statusFilter?.find((item2: any) => item1?.appraisal?.status === item2)
                            }
                          })
                          .filter((item1: any) => {
                            if (positionsFilter.includes("None") || positionsFilter.length === 0) {
                              return item1;
                            } else {
                              return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
                            }
                          })
                          .filter((item1: any) => {
                            if (GradesFilter.includes("None") || GradesFilter.length === 0) {
                              return item1;
                            } else {
                              return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
                            }
                          })
                          .filter((j: any) => {
                            if (enteredName === "") {
                              return j;
                            } else if (
                              (j?.employee_code !== undefined &&
                                j?.employee_code
                                  ?.toLocaleLowerCase()
                                  ?.includes(
                                    enteredName?.toLocaleLowerCase()
                                  )) ||
                              (j?.legal_full_name !== undefined &&
                                j?.legal_full_name
                                  ?.toLocaleLowerCase()
                                  ?.includes(
                                    enteredName?.toLocaleLowerCase()
                                  )) ||
                              (j.grade !== undefined &&
                                j?.grade
                                  ?.toLocaleLowerCase()
                                  ?.includes(
                                    enteredName?.toLocaleLowerCase()
                                  )) ||
                              (j.position_long_description !== undefined &&
                                j?.position_long_description
                                  ?.toLocaleLowerCase()
                                  ?.includes(
                                    enteredName?.toLocaleLowerCase()
                                  )) ||
                              (j?.appraisal?.status !== undefined &&
                                j?.appraisal?.status
                                  ?.toLocaleLowerCase()
                                  ?.includes(
                                    enteredName?.toLocaleLowerCase()
                                  )) ||
                              (getPAStatus(j) !== undefined &&
                                getPAStatus(j)
                                  ?.toLocaleLowerCase()
                                  ?.includes(enteredName?.toLocaleLowerCase()))
                            ) {
                              return j;
                            }
                          })
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((j: any) => {
                            return (
                              <TableRow>
                                <TableCell
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Arial",
                                    color: "#333333",
                                  }}
                                  align="center"
                                >
                                  {j.employee_code}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Arial",
                                    color: "#333333",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  <p>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1.5}
                                    >
                                      <Names>
                                        {j.legal_full_name}
                                      </Names>
                                    </Stack>
                                  </p>
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Arial",
                                    color: "#333333",
                                  }} align="left">
                                  {j.position_long_description}
                                </TableCell>
                                <TableCell style={{
                                  textAlign: "center", paddingRight: "30px", fontSize: "14px",
                                  fontFamily: "Arial",
                                  color: "#333333",
                                }} >{j.grade}</TableCell>
                                <TableCell style={{
                                  fontSize: "14px",
                                  fontFamily: "Arial",
                                  color: (j?.appraisal?.status == "completed" && j?.employee?.employee_status == "rejected") ? "#0099FF" : "#333333",
                                }} align="center">{j?.overall_rating?.toFixed(2)}</TableCell>
                                <TableCell style={{
                                  fontSize: "14px",
                                  fontFamily: "Arial",
                                  color: "#333333",
                                }} align="center">
                                  {getStatus(j?.appraisal?.status)}
                                </TableCell>
                                <TableCell style={{
                                  textAlign: "center", fontSize: "14px",
                                  fontFamily: "Arial",
                                  color: "#333333",
                                }}>
                                  {j.appraisal?.status === "completed" && (
                                    <IconButton
                                      onClick={() => viewPAClickHandler(j)}>
                                      <img src={Eye} alt="icon" />
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>) : (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            align="left"
                            style={{ fontWeight: '500', border: "none", color: "#808080", fontSize: "18px", fontFamily: "arial", paddingTop: "10px" }}
                          >
                            No data to display
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                {tableDataFilterdLength?.length > 0 && <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={tablecount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                }
            </TableHeadings>
    </Mytable>
  );
};
export default Closedcalendarteamtable;