import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Expand from "../../assets/Images/Expand.svg"
import { useGetEmployeeByFilterQuery } from "../../service";

const Contain = styled("div")({
  "& .MuiButton-root": {
    background: "rgb(155 155 155 / 17%)",
    minWidth: "0px",
    borderRadius: "50px",
    width: "55px",
    height: "55px",
  },
});

export default function TopPerformerspfOverallDashboard(props: any) {
  const { topPerformerEmployees, navigationFrom, setTopperformersExpandActive, NAVIGATION_FROM, activeCalenderName, appCalId, navigationRole, valueOfActiveCalender } = props;
  const navigate = useNavigate();
  console.log(topPerformerEmployees, NAVIGATION_FROM, navigationRole, "topPerformerEmployeeschild")
  const handleNavigationForTopPerformers = () => {
    if (navigationRole == "PreviousDashboard") {
      navigate("/TopPerformersExpandedViewofPA", { state: { appCalId, topPerformerEmployees: topPerformerEmployees, valueOfActiveCalender } })
    } else if (NAVIGATION_FROM == "OverallDashboard") {
      setTopperformersExpandActive(true)
    }
    else if (navigationFrom === "Appraiser" || navigationFrom === "Reviewer" || navigationFrom === "Normalizer" && NAVIGATION_FROM != "OverallDashboard") {
      navigate("/topperformersexpand", { state: { activeCalenderName: activeCalenderName, topPerformerEmployees: topPerformerEmployees, navigationFrom: navigationFrom } })
    }
  }
  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          gutterBottom
          component="div"
          sx={{ fontSize: "20px", fontFamily: "Arial", color: "#3e8cb5" }}
        >
          Top Performers
        </Typography>
        <img src={Expand} style={{ cursor: "pointer" }} onClick={handleNavigationForTopPerformers} />
      </Stack>
      {topPerformerEmployees && topPerformerEmployees
        ?.slice(0, 10)
        ?.map((i: any) => {
          return (
            <>
              <div>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div
                    style={{
                      width: '200px'
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Stack direction="column" spacing={1}>
                        <span
                          style={{
                            fontSize: "17px",
                            fontFamily: "Arial",
                            color: "#333333",
                            paddingTop: "14px"
                          }}
                        >
                          {i?.first_name}
                        </span>
                        <span
                          style={{
                            color: "#333333",
                            opacity: "50%",
                            fontSize: "12px",
                            fontFamily: "Arial",
                            marginTop: "10px",
                          }}
                        >
                          {i?.position_long_description}
                        </span>
                        <span
                          style={{
                            color: "#333333",
                            opacity: "50%",
                            fontSize: "12px",
                            fontFamily: "Arial",
                            marginTop: "5px",
                          }}
                        >
                        </span>
                      </Stack>
                    </Stack>
                  </div>
                  <div
                  >
                    <div
                      style={{
                        width: "55px",
                        lineHeight: "50px",
                        borderRadius: "50%",
                        textAlign: "center",
                        fontSize: "16px",
                        fontFamily: "Arial",
                        background: "rgb(155 155 155 / 17%)",
                      }}
                    >
                       {navigationRole == "PreviousDashboard" ?  i?.overall_rating :i?.appraisal?.pa_rating}
                      {/* {i?.overall_rating} */}
                    </div>
                  </div>
                </Stack>
              </div>
            </>
          )
        })}
      <div>
        {topPerformerEmployees?.length == 0 &&
          <Typography
            style={{ fontWeight: '500', border: "none", color: "#808080", fontSize: "18px", fontFamily: "arial", paddingTop: "10px" }}
          >
            No data to display
          </Typography>
        }
      </div>
    </div>
  );
}
