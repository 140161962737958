import React, { useEffect, useState } from 'react'
import { useEmployeeContext } from '../../../../context/appraisal/EmployeeContext'
import Header from '../../header/Header'
import { styled } from "@mui/material/styles";
import { Box, Breadcrumbs, IconButton, Link, Popover, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import Infoicon from "../../../../assets/Images/Infoicon.svg";
import Infowhiteicon from "../../../../assets/Images/Infowhiteicon.svg";
import { useGetObjectiveGroupQuery, useGetRatingScaleQuery } from '../../../../service';
import { Scrollbar } from "react-scrollbars-custom";
import RatingsTab from '../../ratings/RatingsTab';
import DialogWithOkButton from '../../../UI/DialogWithOkButton';
import { PLEASE_SAVE_CHANGES } from '../../../../constants/DialogContents/DialogContents';
import OverallFeedBackTab from '../OverallFeedBackTab';
import PerformanceGoalsTab from '../PerformanceGoalsTab';
import PerformanceAppraisalPeriod from '../../performanceAppraisalPeriod/PerformanceAppraisalPeriod';
import EmployeeRatings from './EmployeeRatings';
import EmployeeRejectFooterButtons from '../footerButtons/EmployeeRejectFooterButtons';
import { EMPLOYEE_LANDING } from '../../../../constants/routes/Routing';
import BreadCrumbs from '../../breadcrumbs/Breadcrumbs';
import JoiningDate from '../../Department/joiningDate';
import Department from '../../Department/Department';

const Heading1 = styled("div")({
  fontSize: "24px",
  fontWeight: 400,
  color: "#004C75",
  marginLeft: "25px",
  // marginTop: "20px",
  fontFamily: "Arial",
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabPanel2(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
});

const EmployeeReject = (props: any) => {
  const { employee_id,
    showIfEmployee,
    employeeData,
    message,
    setMessage,
    tabValue,
    setTabValue,
    moveTab,
    setMoveTab,
    navPrompt,
    setNavPrompt,
    objectiveTitleData,
    Colors,
    colorarray,
    disableTextAfterSubmission,
    setDisableTextAfterSubmission,
    showAppraiserAreaOfImprovement,
    appraiserAreaOfImprovement,
    employeeAreaOfImprovement,
    setEmployeeAreaOfImprovement,
    showAppraiserTrainingRecommendation,
    appraiserTrainingRecommendation,
    employeeTrainingRecommendation,
    setEmployeeTrainingRecommendation,
    appraisalTemplateTrainingRecommendation,
    employeeComments,
    setEmployeeComments,
    oneToOneMeetingDate,
    setOneToOneMeetingDate,
    appraiserPerformanceGoals,
    employeePerformanceGoals,
    setEmployeePerformanceGoals,
    goalsCategoryData,
    PAStatus,
    employeeDetails,
    overallRating,
    ratingData,
    appraiserFeedbackQuestionnaire,
    setAppraiserFeedbackQuestionnaire,
    appraiserMessageForEmployee,
    ratingDefinition,
    ratingScaleDefinition,
    isLoadingRejection,
    refetchEmployeeData,
    updateEmployeeRejection,
    updateLoggedRole,
    employeeDataIsFetching,
    employeeDataIsLoading,
    disableButtons,
    setDisableButtons,
    updateEmployee,
    employeeAgreesWithAppraiser,
    setEmployeeAgreesWithAppraiser,
    appraiserAgreedRatingWithEmployee 

  } = useEmployeeContext()
  const {data : objectiveGroupdata} =useGetObjectiveGroupQuery("")

  const CustomScrollbar = Scrollbar as any;
  let showIfAppraiser = true;
  let showIfEmployeeReject = true;
  let NAVIGATION_FROM = "EmployeeReject"
  const [openAlertOK, setOpenAlertOK] = useState(false);   // Alert with Ok button
  const [showDownload, setShowDownload] = useState(true);
  const [visibilityColumnData, setVisibilityColumnData] = useState({
    showAppraiserComments: false,
    showAppraiserRejectionReason: false,
    showEmployeeRating: false,
    showEmployeeRejectionReason: false,
    showEmployeeAction: false
  });
  const [objectiveDescription, setObjectiveDescription] = useState<any>([]);



  // Popovers
  const [anchorRatingInfo, setAnchorRatingInfo] = React.useState<HTMLButtonElement | null>(null);
  const openRatingInfo = Boolean(anchorRatingInfo);
  const idRatingInfo = openRatingInfo ? "simple-popover" : undefined;

  const handleClickOpenRatingInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorRatingInfo(event.currentTarget);
  };

  const handleCloseRatingInfo = () => {
    setAnchorRatingInfo(null);
  };


  // Functions
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (moveTab === true) {
      //open alert for saving 
      setOpenAlertOK(true);
      setMessage(PLEASE_SAVE_CHANGES);
    } else {
      setTabValue(newValue);
    }
  };

  const handleAlertOKClose = () => {
    setOpenAlertOK(false);
    setMessage("");
  }


  // to find objective title by Id
  const findObjectiveTitleById = (id: any) => {
    if (objectiveTitleData) {
      //console.log(id, "objectiveTitleData");
      return objectiveTitleData?.data?.find((item: any) => item._id === id);
    }
  };

  // to find objective type by Id
  const findObjectiveTypeById = (id: any) => {
    if (employeeData) {
      return employeeData?.data?.reviewer?.objective_type?.find(
        (item: any) => item?.name?._id === id
      );
    }
  };



  const findAppraiserRating = (id: any) => {
    let temp = (employeeData?.data?.appraisal?.objective_description
      .filter(
        (i: any) =>
          i?.name?._id === id
      )
      .map((k: any) => {
        return {
          rating_rejected: k.rating_rejected,
          rating_accepted: k.rating_accepted,
          rating_resubmitted: k.rating_resubmitted,
          ratings: k.ratings,
          comments: k.comments,
          rejection_reason: k.rejection_reason
        }
      })[0]);
    return temp
  }

  // to find Previous Rating
  const findPreviousRating = (id: any) => {
    let temp = (employeeData?.data?.appraisal_previous_rating?.objective_description?.filter(
      (i: any) =>
        i?.name === id
    ).map((k: any) => {
      if (ratingData) {
        let temp = ratingData?.data?.find((item: any) => k.ratings == item._id)
        return temp?.rating
      }
    })[0]);
    return temp
  }
  const findObjectiveGropuById = (id: any) => {
    if (objectiveGroupdata) {
      //console.log(id, "objectiveTitleData");
      return objectiveGroupdata?.data?.find((item: any) =>{
       return (
        item?._id === id
       )
    });
    }
  };
  // useEffect 
  useEffect(() => {
    setVisibilityColumnData({
      ...visibilityColumnData,
      showAppraiserComments: (employeeData?.data?.appraisal?.objective_description.filter((item: any) =>
        item.comments !== "" && item.comments !== undefined)?.length > 0),
      showAppraiserRejectionReason: (employeeData?.data?.appraisal?.objective_description?.filter((item: any) =>
        item.rating_rejected == true && (item.rejection_reason !== "" && item.rejection_reason !== undefined)).length > 0),
      showEmployeeRating: (employeeData?.data?.employee?.objective_description?.filter((item: any) =>
        item.rating_rejected == true)?.length > 0),
      showEmployeeRejectionReason: (employeeData?.data?.employee?.objective_description?.filter((item: any) =>
        item.rating_rejected == true)?.length > 0),
      showEmployeeAction: (employeeData?.data?.appraisal?.pa_status?.includes("Pending with Employee") &&
        ((employeeData?.data?.appraisal?.status == "normalized") ||
          ((employeeData?.data?.employee?.objective_description?.filter((item: any) =>
            item.rating_rejected == true).length > 0 ||
            employeeData?.data?.appraisal?.objective_description?.filter((item: any) =>
              item.rating_rejected == true).length > 0))))
    });

    setObjectiveDescription(() => {
      const objectiveTypes = employeeData?.data?.appraisal?.objective_type || [];
                const objectiveGroupMap = new Map();

                objectiveTypes.forEach((item:any) => {
                    if (item?.name?.objective_group) {
                        objectiveGroupMap.set(item?.name?._id, item?.name?.objective_group);
                    }
                });
      return employeeData?.data?.employee?.objective_description?.map(
        (i: any, index: any) => {
          return {
            ...i,
            appraiser_rating: findAppraiserRating(i.name?._id),
            previous_rating: findPreviousRating(i.name?._id),
            objective_title: findObjectiveTitleById(i?.name?.objective_title),
            objective_type: findObjectiveTypeById(i?.name?.objective_type),
            attachment: employeeData.data.employee?.attachments ? employeeData.data.employee?.attachments[index] : "",
            rating: i.ratings,
            rejection_reason: i.rejection_reason,
            objective_group: findObjectiveGropuById(objectiveGroupMap.get(i?.name?.objective_type))
          };
        }
      );
    });
  }, [employeeData])


  return (
    <>
      <div
        style={{
          backgroundColor: "#F1F1F1",
          height: "auto",
          minHeight: "100px",
          overflow: "hidden",
        }}>
        <BreadCrumbs
          showIfEmployee={showIfEmployeeReject}
          employeeData={employeeData}
          employee_id={employee_id} />

        <Box
          style={{ padding: "35px", marginRight: "25px", marginLeft: "25px", background: "#fff", marginBottom: "25px" }}
        >
          <Box
            style={{
              paddingBottom: "0px"
            }}
          >
            <div>
              <Header
                NAVIGATION_FROM={NAVIGATION_FROM}
                ratingData={ratingData}
                showIfEmployee={showIfEmployee}
                employeeDetails={employeeDetails}
                overallRating={overallRating}
                PAStatus={PAStatus}
                employeeData={employeeData}
                employee_id={employee_id}
                // showDownload={showDownload}
                ratingDefinition={ratingDefinition}
                ratingScaleDefinition={ratingScaleDefinition} />
            </div>
          </Box>

          {employeeData?.data?.appraisal?.status == "not-started" && (
            <div>
              <Typography
                style={{
                  color: "#717171",
                  marginTop: "8px",
                  fontSize: "16px",
                  fontFamily: "Arial",
                  // marginLeft: "35px"
                }}
              >
                Performance Appraisal has not started!
              </Typography>
            </div>
          )}

          <div>
            <Box
              sx={{
                marginTop: "20px",
                // marginLeft: "60px",
                // marginRight: "60px",
                backgroundColor: "#d3d3d366",
                borderRadius: "5px",
                padding: "10px"
              }}>
              <JoiningDate employeeDetails={employeeDetails} />
            </Box>
            <Box
              sx={{
                paddingTop: "20px",
                // paddingRight: "33px"
              }}
            >
              <PerformanceAppraisalPeriod
                employeeDetails={employeeDetails} />
            </Box>
          </div>


          {employeeData?.data?.appraisal?.status !== "not-started" && (
            <>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  // marginLeft: "36px",
                  // marginRight: "66px",
                  paddingTop: "12px",
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  sx={{
                    "& .MuiTabs-indicator": {
                      // display: "none",
                      backgroundColor: "#3e8cb5",
                    }
                  }}
                >
                  <Tab
                    sx={{
                      "&.Mui-selected": {
                        color: "#ffffff",
                        background: "#3e8cb5",
                      },
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontFamily: "Arial",
                      fontWeight: "700",
                      border: "1px solid #3e8cb59e",
                      maxHeight: "0px",
                      minHeight: "48px",
                      paddingRight: "15px",
                      paddingLeft: "20px"
                    }}
                    label="Ratings"
                    icon={
                      <>
                        <TabPanel2 value={tabValue} index={1}>
                          <IconButton
                            sx={{ "&.MuiTab-iconWrapper": { marginLeft: "0px" } }}
                          >
                            <img
                              style={{ width: "12px" }}
                              src={Infoicon}
                              alt="icon"
                            />
                          </IconButton>
                        </TabPanel2>
                        <TabPanel2 value={tabValue} index={2}>
                          <IconButton
                            sx={{ "&.MuiTab-iconWrapper": { marginLeft: "0px" } }}
                          >
                            <img
                              style={{ width: "12px" }}
                              src={Infoicon}
                              alt="icon"
                            />
                          </IconButton>
                        </TabPanel2>
                        <TabPanel2 value={tabValue} index={0}>
                          <IconButton
                            sx={{ "&.MuiTab-iconWrapper": { marginLeft: "0px" } }}
                            aria-describedby={idRatingInfo}
                            onClick={handleClickOpenRatingInfo}
                          >
                            <img
                              style={{ width: "12px" }}
                              src={Infowhiteicon}
                              alt="icon"
                            />

                          </IconButton>
                        </TabPanel2>
                      </>
                    }
                    iconPosition="start"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{
                      "&.Mui-selected": {
                        color: "#ffffff",
                        background: "#3e8cb5",

                      },
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontFamily: "Arial",
                      fontWeight: "700",
                      border: "1px solid #3e8cb59e",
                      paddingLeft: "20px",
                      paddingRight: "20px"
                    }}
                    label="Overall Feedback"
                    {...a11yProps(1)}
                  />
                  <Tab
                    sx={{
                      "&.Mui-selected": {
                        color: "#ffffff",
                        background: "#3e8cb5",

                      },
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontFamily: "Arial",
                      fontWeight: "700",
                      border: "1px solid #3e8cb59e",
                      paddingLeft: "20px",
                      paddingRight: "20px"
                    }}
                    label="Performance Goals"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>

              <TabPanel value={tabValue} index={0}>
                <EmployeeRatings
                  employee_id={employee_id}
                  employeeData={employeeData}
                  objectiveDescription={objectiveDescription}
                  setObjectiveDescription={setObjectiveDescription}
                  objectiveTitleData={objectiveTitleData}
                  Colors={Colors}
                  colorarray={colorarray}
                  disableTextAfterSubmission={disableTextAfterSubmission}
                  visibilityColumnData={visibilityColumnData}
                  isLoadingRejection={isLoadingRejection}
                  refetchEmployeeData={refetchEmployeeData}
                  ratingData={ratingData}
                  moveTab={moveTab}
                  setMoveTab={setMoveTab}
                  navPrompt={navPrompt}
                  setNavPrompt={setNavPrompt}
                  updateEmployeeRejection={updateEmployeeRejection}
                  updateLoggedRole={updateLoggedRole}
                  employeeDataIsFetching={employeeDataIsFetching}
                  />
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <OverallFeedBackTab
                  showIfEmployee={showIfEmployee}
                  showIfAppraiser={showIfAppraiser}
                  showIfEmployeeReject = {showIfEmployeeReject}
                  employeeData={employeeData}
                  showAppraiserAreaOfImprovement={showAppraiserAreaOfImprovement}
                  appraiserAreaOfImprovement={appraiserAreaOfImprovement}
                  employeeAreaOfImprovement={employeeAreaOfImprovement}
                  disableTextAfterSubmission={disableTextAfterSubmission}
                  setEmployeeAreaOfImprovement={setEmployeeAreaOfImprovement}
                  moveTab={moveTab}
                  setMoveTab={setMoveTab}
                  navPrompt={navPrompt}
                  setNavPrompt={setNavPrompt}
                  showAppraiserTrainingRecommendation={showAppraiserTrainingRecommendation}
                  appraiserTrainingRecommendation={appraiserTrainingRecommendation}
                  employeeTrainingRecommendation={employeeTrainingRecommendation}
                  setEmployeeTrainingRecommendation={setEmployeeTrainingRecommendation}
                  appraisalTemplateTrainingRecommendation={appraisalTemplateTrainingRecommendation}
                  employeeComments={employeeComments}
                  setEmployeeComments={setEmployeeComments}
                  oneToOneMeetingDate={oneToOneMeetingDate}
                  setOneToOneMeetingDate={setOneToOneMeetingDate}
                  appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire}
                  setAppraiserFeedbackQuestionnaire={setAppraiserFeedbackQuestionnaire}
                  appraiserMessageForEmployee={appraiserMessageForEmployee}
                  employeeDataIsLoading={employeeDataIsLoading}
                  employeeDataIsFetching={employeeDataIsFetching}
                  appraiserAgreedRatingWithEmployee  = {appraiserAgreedRatingWithEmployee }
                />
              </TabPanel>

              <TabPanel value={tabValue} index={2}>
                <PerformanceGoalsTab
                  showIfAppraiser={showIfAppraiser}
                  showIfEmployee={showIfEmployeeReject}
                  performanceGoals={appraiserPerformanceGoals}
                  moveTab={moveTab}
                  setMoveTab={setMoveTab}
                  navPrompt={navPrompt}
                  setNavPrompt={setNavPrompt}
                  disableTextAfterSubmission={disableTextAfterSubmission}
                  employeePerformanceGoals={employeePerformanceGoals}
                  setEmployeePerformanceGoals={setEmployeePerformanceGoals}
                  goalsCategoryData={goalsCategoryData}
                  employeeDataIsLoading={employeeDataIsLoading}
                  employeeDataIsFetching={employeeDataIsFetching} />
              </TabPanel>
            </>
          )}

          <Box>
            <EmployeeRejectFooterButtons
              employeeData={employeeData}
              disableButtons={disableButtons}
              setDisableButtons={setDisableButtons}
              employeeDataIsFetching={employeeDataIsFetching}
              employeeTrainingRecommendation={employeeTrainingRecommendation}
              employeeAreaOfImprovement={employeeAreaOfImprovement}
              employeePerformanceGoals={employeePerformanceGoals}
              tabValue={tabValue}
              setTabValue={setTabValue}
              updateEmployee={updateEmployee}
              employee_id={employee_id}
              employeeComments={employeeComments}
              oneToOneMeetingDate={oneToOneMeetingDate}
              moveTab={moveTab}
              setMoveTab={setMoveTab}
              navPrompt={navPrompt}
              setNavPrompt={setNavPrompt}
              objectiveDescription={objectiveDescription}
              employeeAgreesWithAppraiser={employeeAgreesWithAppraiser}
              setEmployeeAgreesWithAppraiser={setEmployeeAgreesWithAppraiser}
              updateLoggedRole={updateLoggedRole}
              refetchEmployeeData={refetchEmployeeData}
            />
          </Box>

          <Popover
            id={idRatingInfo}
            open={openRatingInfo}
            anchorEl={anchorRatingInfo}
            onClose={handleCloseRatingInfo}
            PaperProps={{
              style: { width: "260px", marginTop: "55px" },
            }}
          >
            <TableContainer sx={{ overflowX: "hidden" }}>
              <Scroll>
                <CustomScrollbar style={{ width: "100%", height: "225px" }}>
                  <Table
                    sx={{
                      minWidth: 200,
                      '& th, & td': {
                        borderBottom: 'none', // Remove the bottom border for th and td
                      },
                    }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow sx={{ bgcolor: "#eaeced" }}>
                        <TableCell
                          align="center"
                          sx={{
                            maxWidth: "30%",
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600", // Adjust the maxWidth as needed
                          }}
                        >
                          Ratings
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            maxWidth: "70%",
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600",  // Adjust the maxWidth as needed
                          }}
                        >
                          Rating Title
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Map through your data and render rows */}
                      {ratingData &&
                        ratingData.data
                          .slice()
                          .sort((a: any, b: any) => a.rating - b.rating)
                          .map((row: any, index: any) => (
                            <TableRow key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  borderColor: "lightgrey",
                                },
                              }}>
                              <TableCell
                                align="center"
                                sx={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row.rating}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row.rating_scale}
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </CustomScrollbar>
              </Scroll>
            </TableContainer>
          </Popover>

          <DialogWithOkButton
            isAlertOpen={openAlertOK}
            handleAlertClose={handleAlertOKClose}>
            {message}
          </DialogWithOkButton>
        </Box>
      </div>
    </>
  )

}

export default EmployeeReject