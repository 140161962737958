import React from 'react'
import FeedbackQuestionnaireView from '../overallFeedback/FeedbackQuestionnaireView'
import { Box, IconButton, Popover, Stack, Typography } from '@mui/material'
import AreaOfImprovementView from '../overallFeedback/AreaOfImprovementView';
import TrainingRecommendationView from '../overallFeedback/TrainingRecommendationView';
import AppraiserFurtherRecommendationView from '../overallFeedback/AppraiserFurtherRecommendationView';
import AppraiserMessageForEmployeeView from '../overallFeedback/AppraiserMessageForEmployeeView';
import PerformanceGoals from './PerformanceGoals';
import AppraiserDiscussedRatingWithEmployeeView from '../overallFeedback/AppraiserDiscussedRatingWithEmployeeView';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import NormalizerDiscussedRatingView from '../overallFeedback/NormalizerDiscussedRatingView';

function OverallFeedBack(props: any) {
  const { employeeData, appraiserFeedbackQuestionnaire, showAppraiserAreaOfImprovement, appraiserAreaOfImprovement, employeeAreaOfImprovement,shownormalizerdiscussedrating,
    showAppraiserTrainingRecommendation, appraiserTrainingRecommendation, furtherRecommendation, furtherRecommendationOthers, showEmployeeTrainingRecommendation,
    showAppraiserMessageForEmployee, appraiserMessageForEmployee, performanceGoalsFieldValues, performanceGoalsFieldValuesEmployee, showPerformanceGoalsFieldValuesEmployee
    , showPerformanceGoalsFieldValues, appraiserAgreedRatingWithEmployee, employeeTrainingRecommendation, showEmployeeAreaOfImprovement,
    appraiserChecked,reviewerChecked,employeeChecked } = props;
  let showIfAppraiser = true
  let showIfEmployee = true
  let showIfpreviousappraisal = true
  const handleClickOpen8 = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl8(event.currentTarget);
  };
  const [anchorEl8, setAnchorEl8] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open8 = Boolean(anchorEl8);
  const handleClose8 = () => {
    setAnchorEl8(null);
  };
  const date = new Date(employeeData?.employee?.employee?.one_to_one_meeting?.slice(0, 10));
  const One_To_One_Meeting_Date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontSize: "20px",
          color: "#3E8CB5",
          fontFamily: "Arial",
          marginBottom: "20px",
        }}
      >
        Performance Appraisal Summary
      </Typography>
      <div>
        <FeedbackQuestionnaireView
          employeeData={employeeData}
          appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire}
          showIfpreviousappraisal={showIfpreviousappraisal} />
      </div>
      {(showAppraiserAreaOfImprovement &&
        <AreaOfImprovementView
          // showIfEmployee={showIfEmployee}
          showIfpreviousappraisal={showIfpreviousappraisal}
          showIfAppraiser={showIfAppraiser}
          showAppraiserAreaOfImprovement={showAppraiserAreaOfImprovement}
          areaOfImprovement={appraiserAreaOfImprovement} />
      )}
      {(showEmployeeAreaOfImprovement &&
        <AreaOfImprovementView
          showIfEmployee={showIfEmployee}
          showIfpreviousappraisal={showIfpreviousappraisal}
          // showIfAppraiser={showIfAppraiser}
          showAppraiserAreaOfImprovement={showEmployeeAreaOfImprovement}
          areaOfImprovement={employeeAreaOfImprovement} />
      )}
      {/* employeeAreaOfImprovement */}
      {showAppraiserTrainingRecommendation && (
        <TrainingRecommendationView
          showIfpreviousappraisal={showIfpreviousappraisal}
          showIfAppraiser={showIfAppraiser}
          showTrainingRecommendation={showAppraiserTrainingRecommendation}
          trainingRecommendation={appraiserTrainingRecommendation} />
      )}
      {showEmployeeTrainingRecommendation && (
        <TrainingRecommendationView
          showIfEmployee={showIfEmployee}
          showIfpreviousappraisal={showIfpreviousappraisal}
          showTrainingRecommendation={showEmployeeTrainingRecommendation}
          trainingRecommendation={employeeTrainingRecommendation} />
      )}
      <AppraiserFurtherRecommendationView
        showIfpreviousappraisal={showIfpreviousappraisal}
        furtherRecommendation={furtherRecommendation}
        furtherRecommendationOthers={furtherRecommendationOthers} />
      {/* {showAppraiserMessageForEmployee && (
        <div>
          <AppraiserMessageForEmployeeView
            appraiserMessageForEmployee={appraiserMessageForEmployee}
            employeeData={employeeData} />
        </div>
      )} */}
       {employeeData?.employee?.appraisal?.appraiser_overall_feedback !== undefined &&
                    employeeData?.employee?.appraisal?.appraiser_overall_feedback !== "" &&
                    <div style={{ marginBottom: "20px" }}>
                      <Typography
                        style={{
                          fontSize: "16px",
                          color: "#717171",
                          fontFamily: "arial",
                          marginBottom: "10px",
                        }}
                      >
                        <b>Appraiser Message for Employee</b>
                      </Typography>

                      <Box sx={{ border: "1px solid #E0E0E0", padding: "8.5px 14px", borderRadius: "5px" }}>

                        <div style={{
                          color: "#333333",
                          fontSize: "14px",
                          fontFamily: "arial",
                          fontWeight: "400",
                          textTransform: "none",
                          // padding: "8px",
                          textAlign: "left",
                          lineHeight: "23px"
                        }}>
                          {employeeData?.employee?.appraisal?.appraiser_overall_feedback}
                        </div>

                      </Box>
                    </div>
                  }
      {(employeeData?.employee?.normalizer_comments !== "" && 
      employeeData?.employee?.normalizer_comments !== undefined) &&
      <div style={{ marginBottom: "20px" }}>
      <Typography
        style={{ fontSize: "16px", color: "#717171", 
          // paddingBottom: "10px", paddingTop: "20px",
           fontFamily: "arial", wordBreak: "break-word" }}
      >
        <b>HR Normalizer Comments</b>
      </Typography>
      <Box sx={{ border: "1px solid #E0E0E0", padding: "8.5px 14px", borderRadius: "5px" }}>

        <div style={{
          color: "#333333",
          fontSize: "14px",
          fontFamily: "arial",
          fontWeight: "400",
          textTransform: "none",
          // padding: "8px",
          // height: "20px",
          textAlign: "left",
          lineHeight: "23px"
        }}>
          {employeeData?.employee?.normalizer_comments}
        </div>

      </Box>
      </div>}
      {employeeData?.employee?.normalizer_meeting_notes !== "" && 
      employeeData?.employee?.normalizer_meeting_notes !== null &&
      employeeData?.employee?.normalizer_meeting_notes !== undefined &&
      <div style={{ marginBottom: "20px" }}>
        <Typography
          style={{
            fontSize: "16px",
            color: "#717171",
            fontFamily: "arial",
            marginBottom: "10px",
          }}
        >
          <b>HR Normalizer Meeting Notes</b>
        </Typography>

        <Box sx={{ border: "1px solid #E0E0E0", padding: "8.5px 14px", borderRadius: "5px" }}>

          <div style={{
            color: "#333333",
            fontSize: "14px",
            fontFamily: "arial",
            fontWeight: "400",
            textTransform: "none",
            // padding: "8px",
            textAlign: "left",
            lineHeight: "23px"
          }}>
            {employeeData?.employee?.normalizer_meeting_notes}
          </div>

        </Box>
      </div>
      }
      {/* {employeeData?.employee?.meetingNotesAttachments && employeeData?.employee?.meetingNotesAttachments[0]?.url ! == "" && 
      employeeData?.employee?.meetingNotesAttachments && employeeData?.employee?.meetingNotesAttachments[0]?.url !== undefined && */}
     {employeeData && employeeData?.employee?.meetingNotesAttachments && employeeData?.employee?.meetingNotesAttachments.length > 0 ?
      <div style={{ marginBottom: "20px" }}>
        <Typography
          style={{
            fontSize: "16px",
            color: "#717171",
            fontFamily: "arial",
            marginBottom: "10px",
          }}
        >
          <b>HR Normalizer Attachment</b>
        </Typography>

        <AttachFileIcon
          sx={{ color: "#93DCFA", height: "18px", cursor: "pointer", transform: "rotate(30deg)", }}
          aria-describedby={"id"}
          onClick={(e: any) => {
            handleClickOpen8(e)
          }}
        />
      
        <Popover
          id={"id"}
          open={open8}
          anchorEl={anchorEl8}
          onClose={handleClose8}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              backgroundColor: "FEFCF8",
              boxShadow: "none",
              maxWidth: "400px",
              borderRadius: "5px",
            },
          }}
          sx={{
            "& .MuiPopover-paper": {
              border: "1px solid #3e8cb5",
              backgroundColor: "#ffffff",
              // width: "30%",
            },
          }}
        >
          <div
            style={{
              padding: "5px",
              fontSize: "12px",
              lineHeight: "20px",
              color: "#333333",
              fontFamily: "Arial",
            }}
          >
            {/* Attachments: {appraisalAttachments} */}
            {employeeData && employeeData?.employee?.meetingNotesAttachments?.map((k: any, index1: any) => {
              return (
                <>
                  <Stack
                    spacing={1}
                    direction="row"
                    alignItems="center"
                  >

                    <Typography
                      style={{
                        fontSize: "12px",
                        fontFamily: "Arial",
                        color: "#333333",

                        // maxWidth:"215px",
                        // wordBreak:"break-all"
                      }}
                    >
                      {index1 + 1}.
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontFamily: "Arial",
                        color: "#333333",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "170px"
                      }}
                    >
                      <a href={k.url}>{k.name}</a>  <br />
                    </Typography>
                    <Stack direction="row">
                      <IconButton>
                      </IconButton>
                    </Stack>

                  </Stack>
                </>
              )
            })}
          </div>
        </Popover>
      </div>
  : ""}
      {/* } */}
      {employeeData &&
        employeeData?.employee?.employee?.comments !== undefined &&
        employeeData?.employee?.employee?.comments !== "" && (
          <>
            <div style={{ marginBottom: "20px" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  color: "#717171",
                  fontFamily: "arial",
                  marginBottom: "10px",
                }}
              >
                <b>Employee Comments</b>
              </Typography>


              <Box sx={{ border: "1px solid #E0E0E0", padding: "8.5px 14px", borderRadius: "5px" }}>

                <div style={{
                  color: "#333333",
                  fontSize: "14px",
                  fontFamily: "arial",
                  fontWeight: "400",
                  textTransform: "none",
                  // padding: "8px",
                  // height: "20px",
                  textAlign: "left",
                  lineHeight: "23px"
                }}>
                  {employeeData?.employee?.employee?.comments}
                </div>

              </Box>

            </div>
          </>
        )}
      {(employeeData?.employee?.employee?.one_to_one_meeting !== "" &&
        employeeData?.employee?.employee?.one_to_one_meeting !== null &&
        employeeData?.employee?.employee?.one_to_one_meeting !== undefined) && (
          <>
            <div style={{ marginBottom: "20px" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  color: "#717171",
                  fontFamily: "arial",
                  marginBottom: "10px",
                }}
              >
                <b>One-to-One Meeting Date</b>
              </Typography>


              <Box sx={{ border: "1px solid #E0E0E0", padding: "8.5px 14px", borderRadius: "5px" }}>

                <div style={{
                  color: "#333333",
                  fontSize: "14px",
                  fontFamily: "arial",
                  fontWeight: "400",
                  textTransform: "none",
                  // padding: "8px",
                  // height: "20px",
                  textAlign: "left",
                  lineHeight: "23px"
                }}>
                  {One_To_One_Meeting_Date}
                </div>

              </Box>

            </div>
          </>
        )}
         {shownormalizerdiscussedrating &&
        <NormalizerDiscussedRatingView
          appraiserChecked={appraiserChecked}
          reviewerChecked={reviewerChecked}
          employeeChecked={employeeChecked}
          shownormalizerdiscussedrating={shownormalizerdiscussedrating} />
      }
      {/* <AppraiserDiscussedRatingWithEmployeeView
        showIfpreviousappraisal={showIfpreviousappraisal}
        appraiserAgreedRatingWithEmployee={appraiserAgreedRatingWithEmployee} /> */}
      <PerformanceGoals
        performanceGoalsFieldValues={performanceGoalsFieldValues}
        performanceGoalsFieldValuesEmployee={performanceGoalsFieldValuesEmployee}
        showPerformanceGoalsFieldValuesEmployee={showPerformanceGoalsFieldValuesEmployee}
        showPerformanceGoalsFieldValues={showPerformanceGoalsFieldValues}
        showIfAppraiser={showIfAppraiser}
        showIfEmployee={showIfEmployee} />
    </div>
  )
}

export default OverallFeedBack