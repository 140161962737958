import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IconButton, Stack } from "@mui/material";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


const DialogCircularProgressLoader: React.FC<any> = (props: any) => {
  const {
    rowDialog,
    isAlertOpen,
    handleAlertClose,
    handleAlertIdClose,
    children,
  } = props;

  return (
    <Dialog
      open={isAlertOpen}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "6px",
          maxWidth: "0px",
          minWidth: "26%",
          margin: "0px",
          padding: "30px",
        },
      }}
    >
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            color: "#333333",
            fontSize: "14px",
            fontFamily: "Arial",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            wordBreak: "break-word",
            alignItems: "center",
            overflowY: "hidden",
          }}
        >
          {children}
        </DialogContentText>
      </DialogContent>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      // paddingBottom="30px"
      >

        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          {/* <Button
            style={{
              textTransform: "none",
              fontSize: "15px",
              fontFamily: "Arial",
              borderColor: "#3E8CB5",
              color: "#3E8CB5",
              background: "transparent",
              height: "35px",
              width: "70px"
            }}
            variant="outlined"
            onClick={handleAlertClose}
            autoFocus
          >
            Ok
          </Button> */}
        </DialogActions>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </Stack>
    </Dialog>
  );
};

export default DialogCircularProgressLoader;
