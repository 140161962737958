import * as React from "react";
import { useState, useRef } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Badge, FormControl, Grid, MenuItem, BadgeProps, Menu, FormGroup, FormControlLabel, ListItemIcon } from "@mui/material";
import { InputLabel, Stack, Tab, Tabs, Box, Typography, Drawer } from "@mui/material";
import { TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import {
  useGetActiveCalenderQuery,
  useGetEmployeeByFilterQuery,
  useGetEmployeeByManagerCodeQuery,
  useGetEmployeeByStatusQuery,
  useGetEmployeeQuery,
  useGetReviewerEmployeeByFilterQuery
} from "../../../../../service";
import { useEffect } from "react";
import {
  APPRAISAL_NOT_STARTED,
  APPRAISER,
  EMPLOYEE_APPRAISER_SUBMISSION,
  APPRAISER_OVERVIEW,
  APPRAISER_SUBMISSION,
  APPRAISER_VIEW_PA,
  CREATE_APPRAISAL,
  EMPLOYEE_DOWNLOAD,
  VIEW_PA,
  VIEW_PAST_PA,
  APPRAISER_RESUBMISSION,
  APPRAISER_ACTION,
} from "../../../../../constants/routes/Routing";
import UpDown from "../../assets/Images/UpDown.svg";
import Opennew from "../../assets/Images/Opennew.svg";
import Application from "../../assets/Images/Application.svg";
import Searchlens from "../../assets/Images/Searchlens.svg";
import Eye from "../../../../reviewer/Reviewericons/Eyeicon.svg";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Checkbox from "@mui/material/Checkbox";
import Searchlensreview from "../../../../../components/reviewer/Dashboard/Reviewericons/Searchlensreview.svg";
import Expand from "../../../../reviewer/Dashboard/Reviewericons/Expand.svg";
import Newexcel from "../../../../../components/reviewer/Dashboard/Reviewericons/Newexcel.svg";
import Updown from "../../../../reviewer/Dashboard/Reviewericons/Updown.svg";
import Alert from "@mui/material/Alert";
import TablePagination from "@mui/material/TablePagination";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as XLSX from "xlsx";
import { useAppContext } from "../../../../../context/AppContext";
import { useLoggedInUser } from "../../../../../hooks/useLoggedInUser";
import Scrollbar from "react-scrollbars-custom";
import { makeStyles } from '@mui/styles';
import MyTeamTable from "./TeamTableUIAppraiser";
import GenerateReport from "../../../../../components/ExportToExcel/GenerateReport";
import ExportToexcel from "../../../../../components/ExportToExcel/ExporttoExcel";
import AlertDialogSuccess from "../../../../../components/UI/DialogSuccess";

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    width: 140,
    fontSize: "14px",
    color: "#333333",
    fontFamily: "Arial",
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
    fontSize: "13px !important",
    fontFamily: "Arial",
    color: "#333333",
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    fontSize: "12px !important",
    color: "#333333",
    fontFamily: "Arial",
  },
}));
const Mytable = styled("div")({
  background: "#FFFFFF",
  marginLeft: "25px",
  marginRight: "25px",
  position: "relative",
  marginBottom: "25px"
});
const Tabstyles = styled("div")({
  "& .MuiButtonBase-root": {
    color: "#999999",
    textTransform: "none",
    fontWeight: 400,
  },
  "& .Mui-selected": {
    color: "#004C75",
  },
  "&.MuiTabs-indicator": {
    backgroundColor: "#004C75",
  },
  display: "flex",
});
const Heading = styled("div")({
  fontSize: "18px",
  color: "#3e8cb5",
  fontFamily: "Arial",
  flexGrow:1 ,
  alignSelf:"flex-start"
});
const Searchfeild = styled("div")({
  "& .MuiOutlinedInput-root": {
    height: "28px",
    width: "144px",
    borderRadius: "15px",
    background: "#F2F6F8",
  },
  "& .MuiInputLabel-root": {
    fontSize: "13px",
    color: "#306D8F",
    marginTop: "-10px",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "13px",
    color: "#306D8F",
  },
});
const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
  "& .ScrollbarsCustom-Track": {
  },
});
const TableHeadings = styled("div")({
  "& .MuiTableRow-head ": {
    background: "#eaeced",
  },
  "& .MuiTableCell-root": {
    padding: "0px 10px",
  },
});

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -17,
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const Names = styled("div")({
  color: "#333333",
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//@ts-ignore
const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};


const MyTeam = (props: any) => {
  const classes = useStyles();
  const { startAppraisal, employeeData1, SectionFilter, setSectionFilter,
    subSectionFilter, setSubSectionFilter, valueOfCard, appCalId,CalenderName } = props;
  const [tabValue, setTabValue] = React.useState<any>(0);
  const { statusSort } = props;
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log("handle change run");
    console.log(newValue, "index check");
    setTabValue(newValue);
    //clear search
    setenteredName("");
  };
  const CustomScrollbar = Scrollbar as any;
  const { data: activecalendardata} = useGetActiveCalenderQuery('')
let CalendarName =activecalendardata?.data[0]?.name
  const listInnerRef = useRef<any>();
  const [acceptButton, setacceptButton] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<number>();
  const [users, setUsers] = useState<any>([]);
  const [filter, setFilter] = React.useState("");
  const [employee, setEmployee] = React.useState([]);
  const [enteredName, setenteredName] = useState("");
  const [approved, setApproved] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageNumber, setPageNumber] = useState(0)
  const navigate = useNavigate();

  //multiselect
  const [positionFilter, setpositionFilter] = React.useState<string[]>([]);
  const [GradeFilter, setGradeFilter] = React.useState<string[]>([]);
  console.log(positionFilter?.length, "positionFilter");


  useEffect(() => {
    if (valueOfCard === "") {
      return setTabValue(0);
    } else if (valueOfCard === "Not-Started") {
      return setTabValue(2);
    } else if (valueOfCard === "In Progress") {
      return setTabValue(3);
    } else if (valueOfCard === "Normalized") {
      return setTabValue(3);
    } else if (valueOfCard === "Rejected") {
      return setTabValue(4);
    } else if (valueOfCard === "Completed") {
      return setTabValue(5);
    }
  }, [valueOfCard]);
  // //sorting

  const [empEmployeeCode, setempEmployeeCode] = React.useState("");
  const [empFullName, setempFullName] = React.useState("");
  const [employeeCode, setemployeeCode] = React.useState("");
  const [employeeName, setemployeeName] = React.useState("");
  const [positions, setPositions] = React.useState("");
  const [empgrades, setempGrades] = React.useState("");
// function for Next page
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
// function for Pagination shown Rowsperpage
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const { data: user } = useLoggedInUser();
  // const SELECT_FOR_DASHBOARD = `employee_code,overall_rating,service_reference_date,isSupervisor,first_name,email,function,employee.employee_status,legal_full_name,position_long_description,section,sub_section,grade,appraisal.appraiser_rating,reviewer.reviewer_rating,normalizer.normalizer_rating,appraisal.status,appraisal.appraiser_status,reviewer.reviewer_status,normalizer.normalizer_status,reviewer.rejection_count,appraisal.objective_description,employee.employee_rating,reviewerIsDisabled`
  // const { data: employeeData } = useGetEmployeeByFilterQuery(
  //   `?appraiser_code=${user?.employee_code}&calendar=${appCalId}&limit=600&select=${SELECT_FOR_DASHBOARD}`
  // );
  // const { data: reviewerData } = useGetReviewerEmployeeByFilterQuery(
  //   '62ac2037c1c19127416ab14c'
  // );

  useEffect(() => {
    if (employeeData1 != undefined) {
      setUsers(employeeData1?.filter((j: any) => j?.appraisal?.status != "excepted"))
    }
  }, [employeeData1]);

  // Function for getStatus Used for Export to excel
  const getStatus = (status: any) => {
    if (status == "in-progress") {
      return "In progress"
    } else if (status == "not-started") {
      return "Not started"
    } else if (status == "normalized") {
      return "Normalized"
    } else if (status == "rejected") {
      return "Rejected "
    } else if (status == "completed") {
      return "Completed "
    }
  }


// function for Clicking employeename To go for Appraiser screens
  const empNameClickHandler = (j: any) => {
    console.log(j, "empactsat");
    if (j?.appraisal?.pa_status?.includes("Pending with Appraiser") && j.reviewer.reviewer_status?.includes("rejected")) {
      // navigate(`/reviewerrejection/employee/${j._id}`);
      navigate(`${APPRAISER_RESUBMISSION}/employee/${j._id}`);
    }
    else if (j?.appraisal?.pa_status?.includes("Pending with Appraiser") && j.appraisal?.status?.includes("rejected")) {
      navigate(`${APPRAISER_ACTION}/employee/${j._id}`);
    } else if (j?.appraisal?.pa_status?.includes("Pending with Appraiser") && j.normalizer.normalizer_status?.includes("rejected")) {
      navigate(`${APPRAISER_RESUBMISSION}/employee/${j._id}`);
    } else if (
      j.appraisal.appraiser_status == "draft" ||
      j.appraisal.appraiser_status == "pending"
    ) {
      navigate(`${APPRAISER_OVERVIEW}/employee/${j._id}`);
    }
  };
// function for Pending action status
  const getPAStatus = (j: any) => {
    if (
      j.appraisal?.objective_description &&
      j.appraisal?.objective_description.length === 0
    )
      return " PA Not Started";
    else if (j?.appraisal?.status == "completed") return "-";
    else if (j?.appraisal?.appraiser_status === "pending")
      return " Pending with Appraiser";
    else if (j?.appraisal?.status === "normalized")
      return " Pending with Employee";
    else if (j?.appraisal?.appraiser_status?.includes("draft")) return " Pending with Appraiser (Draft)";
    else if (
      j?.appraisal?.appraiser_status === "submitted" &&
      (j?.reviewer?.reviewer_status == "pending" ||
        j?.reviewer?.reviewer_status == "draft")
    )
      return " Pending with Reviewer";
    else if (
      j?.appraisal?.appraiser_status === "accepted" &&
      (j?.reviewer?.reviewer_status == "pending" || j?.reviewer?.reviewer_status == "appraiser-accepted" ||
        j?.reviewer?.reviewer_status == "draft")
    )
      return " Pending with Reviewer";
    else if (j?.reviewer?.reviewer_status == "appraiser-rejected" || j?.reviewer?.reviewer_status == "draft") {
      return " Pending with Reviewer";
    } else if (
      (j?.reviewer?.reviewer_status == "accepted") &&
      (j?.normalizer?.normalizer_status == "pending" || j?.normalizer?.normalizer_status == "draft")
    )
      return " Pending with HR Normalizer";
    else if (j?.appraisal?.appraiser_status === "reviewer-rejected")
      return " Pending with Appraiser (Reviewer Rejection)";
    else if (j?.appraisal?.appraiser_status === "normalizer-rejected")
      return " Pending with Appraiser (Normalizer Rejection)";
    else if (j?.appraisal?.appraiser_status === "appraiser-rejected-employee")
      return " Pending with Employee";
    else if (j?.appraisal?.appraiser_status === "employee-rejected")
      return " Pending with Appraiser (Employee Rejection)";
    else if (
      j?.reviewer.reviewer_status == "accepted" &&
      j?.normalizer.normalizer_status == "employee-rejected"
    )
      return " Pending with HR Normalizer";
    else if (j?.normalizer?.normalizer_status == "accepted")
      return " Pending with Employee";
    else if (j?.normalizer?.normalizer_status === "draft")
      return " Pending with HR Normalizer";
    else if (
      j?.reviewer?.reviewer_status == "rejected" &&
      j?.reviewer?.rejection_count == 3 &&
      (j?.normalizer?.normalizer_status == "pending" || j?.normalizer?.normalizer_status == "draft")
    )
      return " Pending with HR Normalizer";
    else if (j?.normalizer?.normalizer_status == "employee-rejected")
      return " Pending with HR Normalizer";
    else if (j?.appraisal?.appraiser_status === "employee-rejected")
      return " Pending with Appraiser";
    else if (
      j?.reviewer?.reviewer_status == "normalizer-rejected" &&
      j?.reviewer?.reviewer_status == "appraiser-rejected"
    )
      return "Pending with Reviewer";
    else if (
      j?.appraisal?.appraiser_status == "normalizer-rejected" &&
      j?.appraisal?.appraiser_status == "accepted"
    )
      return "Pending with Reviewer";
    else return "-";
  };

  // Badge
  const [All, setAll] = React.useState<any>(0);
  useEffect(() => {
    setAll(users?.length);
  }, [users]);
  const [completedemp, setcompletedemp] = React.useState<any>(0);
  useEffect(() => {
    let badge = users?.filter((j: any) => {
      return j?.appraisal?.status === "completed";
    });
    setcompletedemp(badge?.length);
    return badge;
  }, [users]);
  const [inProgressemp, setinProgressemp] = React.useState<any>(0);
  useEffect(() => {
    let badge = users?.filter((j: any) => {
      return j?.appraisal?.status === "in-progress" || j?.appraisal?.status === "normalized";
    });
    setinProgressemp(badge?.length);
    return badge;
  }, [users]);
  const [notStartedemp, setnotStartedemp] = React.useState<any>(0);
  useEffect(() => {
    let badge = users?.filter((j: any) => {
      return j?.appraisal?.status === "not-started";
    });
    setnotStartedemp(badge?.length);
    return badge;
  }, [users]);
  const [emprejected, setemprejected] = React.useState<any>(0);
  useEffect(() => {
    let badge = users?.filter((j: any) => {
      return j?.appraisal?.status === "rejected";
    });
    setemprejected(badge?.length);
    return badge;
  }, [users]);

  const [mypendingActions, setmypendingActions] = React.useState<any>(0);
  const [GradesFilter, setGradesFilter] = React.useState<string[]>([]);
  const [positionsFilter, setpositionsFilter] = React.useState<string[]>([]);
  useEffect(() => {
    let badge = users?.filter((j: any) => {
      return j?.appraisal?.pa_status?.includes("Appraiser")
    });
    console.log(badge, "badge");
    setmypendingActions(badge?.length);
    return badge;
  }, [users]);
  // Badge
  //Export to excel drawer functions
  const [isDrawerOpen, setisDrawerOpen] = React.useState(false);
  const [isDrawerOpen1, setisDrawerOpen1] = React.useState(false);
  const [headingSortAccept, setheadingSortAccept] = React.useState(false);
  const [openGrade, setOpenGrade] = React.useState(false);
  const [GenerateReportOpen, setGenerateReportOpen] = React.useState(false);
  // Dialogue for  Generate report empty excel
  const handleGenerateReportOpen = () =>{
   setGenerateReportOpen(false)
  }
  // Export to excel opendrawer functions
  const handleExportFunction = () => {
    setisDrawerOpen(true);
  };
  // function for Generate Report Export to excel opendrawer 
  const handleExportFunction1 = () => {
    const filteredEmployeeData = users?.filter((item: any) => {
      return item?.appraisal?.pa_status === "Completed";
    });
    console.log(filteredEmployeeData?.length,"length")
    if(filteredEmployeeData?.length == 0){
      setGenerateReportOpen(true)
    }else{
      setGenerateReportOpen(false)
      setisDrawerOpen1(true);
    }
  };
  const [columnHeaders, setcolumnHeaders] = useState<any>({
    Ecode: true,
    Ename: true,
    Eposition: true,
    Firstname:true,
    ESection: true,
    EGrade: true,
    EDivision: true,
    ESubSection: true,
    AppraiserName: true,
    ReviewerName: true,
    NormalizerName: true,
    OverallRating: true,
    employeerating : true,
    appraiserRating: true,
    // reviewerRating:true,
    // normalizerRating:true,
    PreviousRating: true,
    PotentialLevel: true,
    TalentCategory: true,
    WorkLocation: true,
    ManagerCode: true,
    ManagerName: true,
    ManagerPosition: true,
    PendingAction:true,
    // Rating: true,
    // PAStatus: true,
    // pendingAction: true,
    appraiserCode:true,
    reviewerCode:true,
    normalizerCode:true,
    status:true,
    // email:true,
    // rejectionStatus:true,
    ServiceReferenceDate:true,
    Function:true,
    SupervisoryRole:true,
    EmailID:true,
    CalendarName:true,
    SelectAll: true,
    workflowHistory : true
  })
  // Checkedcount and totalcount used for Drawer selectAll function
  const checkedCount:any = Object.keys(columnHeaders).reduce((count:any, key:any) => {
    if (key !== 'SelectAll') {
      return count + (columnHeaders[key] ? 1 : 0);
    }
    return count;
  }, 0);
  const totalCount = Object.keys(columnHeaders).length - 1; 
  console.log(checkedCount,totalCount,"totalCount")
  // Checkedcount and totalcount used for Drawer selectAll function

// columnHeadersDisplay used for Gnerate report
  const [columnHeadersDisplay, setcolumnHeadersDisplay] = useState<any>({
    TrainingRecommendation:true,
    Areaofimprovement:true,
    furtherrecommendation:true
  })

  const [headingcalendar, setheadingcalendar] = React.useState(true);
  const [Areaofimprovement, setAreaofimprovement] = React.useState(true);
  const [furtherrecommendation, setfurtherrecommendation] = React.useState(true);
  const [TrainingRecommendation, setTrainingRecommendation] = React.useState(true);
  const [ServiceReferenceDateData, setServiceReferenceDateData] = React.useState(true);
  const [FunctionData, setFunctionData] = React.useState(true);
  const [SupervisoryRoleData, setSupervisoryRoleData] = React.useState(true);
  const [EmailIDData, setEmailIDData] = React.useState(true);
  const [rejstatusValue, rejsetstatusValue] = React.useState(true);
  const [firstName, setfirstName] = React.useState(true);
  const [heading1, setheading1] = React.useState(true);
  const [heading2, setheading2] = React.useState(true);
  const [heading3, setheading3] = React.useState(true);
  const [heading4, setheading4] = React.useState(true);
  const [appraiserRating, setAppraiserRating] = React.useState(true);
  const [reviewerRating, setReviewerRating] = React.useState(true);
  const [normalizeRating, setNormalizeRating] = React.useState(true);
  const [employeerating, setemployeerating] = React.useState(true);
  const [headingOfEcode, setheadingOfEcode] = React.useState(true);
  const [headingOfSection, setheadingOfSection] = React.useState(true);
  const [headingOfPAStatus, setheadingOfPAStatus] = React.useState(true);
  const [headingOfDivision, setheadingOfDivision] = React.useState(true);
  const [headingOfSubSection, setheadingOfSubSection] = React.useState(true);
  const [headingOfAppraiserName, setheadingOfAppraiserName] = React.useState(true);
  const [headingOfReviewerName, setheadingOfReviewerName] = React.useState(true);
  const [headingOfNormalizerName, setheadingOfNormalizerName] = React.useState(true);
  const [headingOfOverallRating, setheadingOfOverallRating] = React.useState(true);
  const [headingOfPreviousRating, setheadingOfPreviousRating] = React.useState(true);
  const [headingOfPotentialLevel, setheadingOfPotentialLevel] = React.useState(true);
  const [headingOfTalentCategory, setheadingOfTalentCategory] = React.useState(true);
  const [headingOfWorkLocation, setheadingOfWorkLocation] = React.useState(true);
  const [headingOfManagerCode, setheadingOfManagerCode] = React.useState(true);
  const [headingOfManagerName, setheadingOfManagerName] = React.useState(true);
  const [headingOfManagerPosition, setheadingOfManagerPosition] = React.useState(true);
  const [headingOfPendingAction, setheadingOfPendingAction] = React.useState(true);
  const [appCodes, setappCodes] = React.useState(true);
  const [revCodes, setrevCodes] = React.useState(true);
  const [norCodes, setnorCodes] = React.useState(true);
  const [statusValue, setstatusValue] = React.useState(true);
  const handleStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setstatusValue(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleheadingCalendar = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingcalendar(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  }
  const handleAreaofimprovement = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAreaofimprovement(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeadersDisplay((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlefurtherrecommendation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setfurtherrecommendation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeadersDisplay((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleTrainingRecommendation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrainingRecommendation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeadersDisplay((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAppCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setappCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleRevCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setrevCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleNorCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setnorCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleEmployeeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfEcode(event.target.checked);
    //  const values = Object.keys(columnHeaders).filter(key => key !== 'SelectAll').map(key => columnHeaders[key]);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleheading1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading1(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleheading2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading2(event.target.checked);
    const { name, checked } = event.target;
    const values = Object.keys(columnHeaders).filter(key => key !== 'SelectAll').map(key => columnHeaders[key]);
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleheading3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading3(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfSection(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleSelectAll = (selectAll: any) => {
    const updatedColumnHeaders = { ...columnHeaders };
    Object.keys(updatedColumnHeaders).forEach((key) => {
      updatedColumnHeaders[key] = selectAll;
    });
    const { SelectAll, ...headersWithoutSelectAll } = columnHeaders;
    setcolumnHeaders(updatedColumnHeaders);
  };
  const handleDivision = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfDivision(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleSubSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfSubSection(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleAppraiserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfAppraiserName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleReviewerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfReviewerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleNormalizerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfNormalizerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleOverallRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfOverallRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handlePreviousRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPreviousRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handlePotentialLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPotentialLevel(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleTalentCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfTalentCategory(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleWorkLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfWorkLocation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleManagerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerCode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleManagerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleManagerPosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerPosition(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handlePendingAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPendingAction(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleWorkflowHistory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleemployeerating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setemployeerating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleAppraiserRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppraiserRating(event.target.checked)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handlefirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setfirstName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleServiceReferenceDateData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceReferenceDateData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleFunctionData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFunctionData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleSupervisoryRoleData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSupervisoryRoleData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleEmailIDData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailIDData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleReviewerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReviewerRating(event.target.checked)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleNormalizerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNormalizeRating(event.target.checked)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const [EmployeeData, setEmployeeData] = React.useState<any>([])
  // compltedemployee details report only we need for training recommendation
  const filteredEmployeeData = EmployeeData?.filter((item:any) => {
    return item?.appraisal?.pa_status === "Completed";
});
// function for Generate report
  const handleExport3 = (columnHeadersDisplay: any) => {
    const filteredEmployeeData = users?.filter((item: any) => {
      return item?.appraisal?.pa_status === "Completed";
    });
    // return mapped
    let lastEmployeeCode: string | null = null;
    var wb = XLSX.utils.book_new();
    var wsData: any[][] = [[]];

    wsData.push(
      ["Ecode",
        "Employee Name",
        "Known As",
        "Service Reference Date",
        "Position",
        "Grade",
        // "Division",
        "Company",
        "Overall Rating",
        "Potential Level",
        "Talent Category",
        "Section",
        "Sub-section",
        "Appraiser Code",
        "Appraiser Name",
        "Training Category(Appraiser)",
        "Training Name(Appraiser)",
        "Justification(Appraiser)",
        "Training Category(Employee)",
        "Training Name(Employee)",
        "Justification(Employee)",
      ]
    );
    filteredEmployeeData.forEach((employee: any) => {
      const employeeCode = employee?.employee_code
      let inputDate = employee?.service_reference_date;
      const dateParts = inputDate?.split("-");
      const date = new Date(inputDate);
      const year = date?.getFullYear();
      const month = date?.toLocaleString("default", { month: "short" });
      const day = date?.getDate();
      const formattedDate = `${day}-${month}-${year}`;
      console.log(employeeCode,lastEmployeeCode,employee, 'chekemployeegenerate1')
      if (lastEmployeeCode !== employeeCode) {
        if (lastEmployeeCode !== null) {
        }
        const appraisalTraining = employee?.appraisal?.training_recommendation[0]
        const employeeTraining = employee?.employee?.training_recommendation[0]
        wsData.push([
          employeeCode,
          employee?.legal_full_name,
          employee?.first_name,
          formattedDate,
          employee?.position_long_description,
          employee?.grade,
          employee?.division,
          employee?.current_rating?.overall_rating?.toFixed(2),
          employee?.appraisal?.potential,
          employee?.talent_category,
          employee?.section,
          employee?.sub_section,
          employee?.appraiser_code,
          employee?.appraiser_name,
          appraisalTraining?.name?.title,
          appraisalTraining?.training_name,
          appraisalTraining?.justification,
          employeeTraining?.name?.title,
          employeeTraining?.training_name,
          employeeTraining?.justification,
        ]);
        lastEmployeeCode = employeeCode;
      }
      // Update maxTrainingRecommendations for the current employee
      const maxTrainingRecommendations = Math.max(employee?.appraisal?.training_recommendation.length, employee?.employee?.training_recommendation.length);
      // Add training recommendation details for the subsequent rows (excluding the first row)
      for (let i = 1; i < maxTrainingRecommendations; i++) {
        const appraisalTraining = employee?.appraisal?.training_recommendation[i] || {};
        const employeeTraining = employee?.employee?.training_recommendation[i] || {};
        wsData.push([
          employeeCode || "",
          employee?.legal_full_name || "",
          employee?.first_name || "",
          formattedDate,
          employee?.position_long_description || "",
          employee?.grade || "",
          employee?.division || "",
          employee?.current_rating?.overall_rating?.toFixed(2) || "",
          employee?.appraisal?.potential || "",
          employee?.talent_category || "",
          employee?.section || "",
          employee?.sub_section || "",
          employee?.appraiser_code || "",
          employee?.appraiser_name || "", // Empty columns for employee details
          appraisalTraining?.name?.title || "",
          appraisalTraining?.training_name || "",
          appraisalTraining?.justification || "",
          employeeTraining?.name?.title || "",
          employeeTraining?.training_name || "",
          employeeTraining?.justification || "",
        ]);
      }
    });
    const sheet2Data = filteredEmployeeData
      ?.map((j: any, emp: any, employee: any) => {
        let inputDate = j?.service_reference_date
        const dateParts = inputDate?.split("-");
        console.log(inputDate, dateParts, "inputDate")
        let date = new Date(inputDate);
        const year = date?.getFullYear();
        const month = date?.toLocaleString("default", { month: "short" });
        const day = date?.getDate();
        //const day = dateParts[2]?.slice(0, 2)
        const formattedDate = `${day}-${month}-${year}`;
        let exportData: any = {}
        exportData["Ecode"] = j?.employee_code
        exportData["Employee Name"] = j?.legal_full_name
        exportData["Known As"] = j?.first_name
        exportData["Service Reference Date"] = formattedDate
        exportData["Position"] = j?.position_long_description
        exportData["Grade"] = j?.grade
        exportData["Function"] = j?.function
        exportData["Supervisory Role"] = j?.isSupervisor != true ? "N-SP" : "SP"
        exportData["Overall Rating"] = j?.current_rating?.overall_rating?.toFixed(2)
        exportData["Potential Level"] = (j?.appraisal?.potential)
        // exportData["Division"] = j?.division
        exportData["Company"] = j?.division
        exportData["Section"] = j?.section
        exportData["Sub-section"] = j?.sub_section
        exportData["Appraiser Code"] = j?.appraiser_code
        exportData["Appraiser Name"] = j?.appraiser_name
        exportData["Recommendations"] =  j?.appraisal?.other_recommendation?.map((rec: any) => rec?.name?.name).join(', ')
        exportData["Recommendations Justification"] = j?.appraisal?.other_recommendation_others && j?.appraisal?.other_recommendation_others

        return exportData
      });
    var wsData1: any[][] = [[]];
    wsData1.push(
      ["Ecode",
        "Employee name",
        "Known As",
        "Service Reference Date",
        "Position",
        "Grade",
        // "Division",
        "Company",
        "Overall Rating",
        "Potential Level",
        "Talent Category",
        "Section",
        "Sub-section",
        "Appraiser Code",
        "Appraiser Name",
        "Specific Areas(Appraiser)",
        "Specific Actions(Appraiser)",
        "Specific Areas(Employee)",
        "Specific Actions(Employee)",
      ]
    )
    filteredEmployeeData.forEach((employee: any) => {     
      const employeeCode = employee?.employee_code
      let inputDate = employee?.service_reference_date;
      const dateParts = inputDate?.split("-");
      const date = new Date(inputDate);
      const year = date?.getFullYear();
      const month = date?.toLocaleString("default", { month: "short" });
      const day = date?.getDate();
      const formattedDate = `${day}-${month}-${year}`;
      console.log(employeeCode,lastEmployeeCode,employee, 'chekemployeegenerate2')
      // if (lastEmployeeCode !== employeeCode) {
        if (lastEmployeeCode !== null) {
        }
       
        // const maxTrainingRecommendations = Math.max(employee?.appraisal?.training_recommendation.length, employee?.employee?.training_recommendation.length);
        const appraisalTraining = (employee?.appraisal?.area_of_improvement || [])[0] || {};
        const employeeTraining = (employee?.employee?.area_of_improvement || [])[0] || {};
        const appraisalSpecificAction = (appraisalTraining?.specific_actions || [])[0]?.value || "";
        const employeeSpecificAction = (employeeTraining?.specific_actions || [])[0]?.value || "";
        console.log(appraisalTraining,employeeTraining,appraisalSpecificAction,employeeSpecificAction,'cehckgenerate')
        wsData1.push([
          employeeCode,
          employee?.legal_full_name,
          employee?.first_name,
          formattedDate,
          employee?.position_long_description,
          employee?.grade,
          employee?.division,
          employee?.current_rating?.overall_rating?.toFixed(2),
          employee?.appraisal?.potential,
          employee?.talent_category,
          employee?.section,
          employee?.sub_section,
          employee?.appraiser_code,
          employee?.appraiser_name,
          appraisalTraining?.value,
          appraisalSpecificAction || "",
          employeeTraining?.value,
          employeeSpecificAction || "",
        ]);
        lastEmployeeCode = employeeCode;
      // }
      // Update maxTrainingRecommendations for the current employee
      const maxTrainingRecommendations = Math.max(employee?.appraisal?.area_of_improvement?.length, employee?.employee?.area_of_improvement?.length);
      // Add training recommendation details for the subsequent rows (excluding the first row)
      for (let i = 1; i < maxTrainingRecommendations; i++) {
        const appraisalTraining = employee?.appraisal?.area_of_improvement[i] || {};
        const employeeTraining = employee?.employee?.area_of_improvement[i] || {};
        wsData1.push([
          employeeCode,
          employee?.legal_full_name,
          employee?.first_name,
          formattedDate,
          employee?.position_long_description,
          employee?.grade,
          employee?.division,
          employee?.current_rating?.overall_rating?.toFixed(2),
          employee?.appraisal?.potential,
          employee?.talent_category,
          employee?.section,
          employee?.sub_section,
          employee?.appraiser_code,
          employee?.appraiser_name,
          appraisalTraining?.value || "",
          (appraisalTraining?.specific_actions || [])[0]?.value || "",
          employeeTraining?.value || "",
          (employeeTraining?.specific_actions || [])[0]?.value || "",
        ]);
      }
    });
    wsData.shift();
    wsData1.shift();
    const sheet3 = XLSX.utils.aoa_to_sheet(wsData1);
    const sheet1 = XLSX.utils.aoa_to_sheet(wsData);
    const sheet2 = XLSX.utils.json_to_sheet(sheet2Data, { header: Object.keys(sheet2Data[0]) });
    if (columnHeadersDisplay.TrainingRecommendation && columnHeadersDisplay.furtherrecommendation && columnHeadersDisplay.Areaofimprovement) {
      XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
      XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
      XLSX.utils.book_append_sheet(wb, sheet2, 'Recommendations');
    } else if (columnHeadersDisplay.TrainingRecommendation && columnHeadersDisplay.furtherrecommendation) {
      XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
      XLSX.utils.book_append_sheet(wb, sheet2, 'Recommendations');
    } else if (columnHeadersDisplay.TrainingRecommendation && columnHeadersDisplay.Areaofimprovement) {
      XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
      XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
    } else if (columnHeadersDisplay.furtherrecommendation && columnHeadersDisplay.Areaofimprovement) {
      XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
      XLSX.utils.book_append_sheet(wb, sheet2, 'Recommendations');
    } else if (columnHeadersDisplay.TrainingRecommendation) {
      XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
    } else if (columnHeadersDisplay.furtherrecommendation) {
      XLSX.utils.book_append_sheet(wb, sheet2, 'Recommendations');
    } else if (columnHeadersDisplay.Areaofimprovement) {
      XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
    }
    XLSX.writeFile(wb, `Performance Appraisal Report -${CalenderName}.xlsx`);
  }
//Function for Export to Excel 
  const handleExport = () => {
    const mapped = EmployeeData
    ?.filter((item1: any) => {
      if (positionsFilter?.includes("None") || positionsFilter?.length === 0) {
        return item1;
      } else {
        return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
      }
    })
    ?.filter((item1: any) => {
      if (GradesFilter?.includes("None") || GradesFilter?.length === 0) {
        return item1;
      } else {
        return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
      }
    })
    .filter((j: any) => {
      if (enteredName === "") {
        return j;
      } else if (
        (j?.employee_code !== undefined &&
          j?.employee_code
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.legal_full_name !== undefined &&
          j?.legal_full_name
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j.grade !== undefined &&
          j?.grade
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j.position_long_description !== undefined &&
          j?.position_long_description
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.appraisal?.status !== undefined &&
          j?.appraisal?.status
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (getPAStatus(j) !== undefined &&
          getPAStatus(j)
            ?.toLocaleLowerCase()
            ?.includes(enteredName?.toLocaleLowerCase()))
      ) {
        return j;
      }
    })
        ?.map((j: any, emp: any, employee: any) => {
          console.log(j,'checkjjjjjj')
          let inputDate = j?.service_reference_date
          const dateParts = inputDate?.split("-");
          console.log(inputDate,dateParts,"inputDate")
          let date = new Date(inputDate);
          const year = date?.getFullYear();
          const month = date?.toLocaleString("default", { month: "short" });
          const day = date?.getDate();
          const formattedDate = `${day}-${month}-${year}`;
          let exportData: any = {}
          if (columnHeaders["CalendarName"] == true) exportData["Calendar Name"] = CalendarName
          if (columnHeaders["Ecode"] == true) exportData["Ecode"] = j?.employee_code
          if (columnHeaders["Ename"] == true) exportData["Employee Name"] = j?.legal_full_name
          if (columnHeaders["Firstname"] == true) exportData["Known As"] = j?.first_name
          if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date"] = formattedDate
          if (columnHeaders["Eposition"] == true) exportData["Position"] = j?.position_long_description     
          if (columnHeaders["EGrade"] == true) exportData["Grade"] = j?.grade
          if (columnHeaders["Function"] == true) exportData["Function"] = j?.function
          if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = j?.isSupervisor != true ? "N-SP" : "SP"
          if (columnHeaders["EmailID"] == true) exportData["Email ID"] = j?.email
          // if (columnHeaders["EDivision"] == true) exportData["Division"] = j?.division
          if (columnHeaders["EDivision"] == true) exportData["Company"] = j?.division
          if (columnHeaders["ESection"] == true) exportData["Section"] = j?.section
          if (columnHeaders["ESubSection"] == true) exportData["Sub-section"] = j?.sub_section
          if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = j?.work_location
          if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = j?.appraiser_code
          if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = j?.appraiser_name
          if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = j?.reviewer_code
          if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = j?.reviewer_name
          if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = j?.normalizer_code
          if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = j?.normalizer_name
          if (columnHeaders["employeerating"] == true) exportData["Employee Rating "] = j?.employee?.employee_rating?.toFixed(2)
          if (columnHeaders["appraiserRating"] == true) exportData["Appraiser Rating"] = j?.appraisal?.appraiser_rating?.toFixed(2)
          // if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Rating"] = j?.reviewer?.reviewer_rating?.toFixed(2)
          // if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Rating"] = j?.normalizer?.normalizer_rating?.toFixed(2)
          // if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Status"] = j?.reviewer?.reviewer_rating?.toFixed(2)
          // if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Status"] = j?.normalizer?.normalizer_rating?.toFixed(2)
          if (columnHeaders["OverallRating"] == true) exportData["Overall Rating"] = (j?.current_rating?.overall_rating == 0 || j?.appraisal?.status !== "completed") ? "-" : j?.current_rating?.overall_rating?.toFixed(2)
          // if (columnHeaders["PreviousRating"] == true) exportData["Previous Period Rating"] =(j?.previous_rating && j?.previous_rating ==0 ? "-" : j?.previous_rating?.toFixed(2))
          if (columnHeaders["PreviousRating"] == true) {exportData["Previous Period Rating"] =  (j && j.previous_rating ? j.previous_rating.toFixed(2) : "-");}
          if (columnHeaders["PotentialLevel"] == true) exportData["Potential Level"] =(j?.normalizer?.normalized_overallRating !== 0 ? j?.appraisal?.potential : "")
          if (columnHeaders["TalentCategory"] == true) exportData["Talent Category"] =  (j?.talent_category !== "" || j?.talent_category !== undefined
            ? j?.talent_category : "-")   
          if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = j?.manager_code
          if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = j?.manager_name
          if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = j?.manager_position
          if (columnHeaders["status"] == true) exportData["Status"] = getStatus(j?.appraisal?.status)
          if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] = j.appraisal?.status == "not-started" ? "Pending with Appraiser" :j.appraisal?.status == "completed" ? ("-") : j.appraisal.pa_status
          if (columnHeaders["workflowHistory"] == true) exportData["Workflow History"] = j.workflow_history?.map((item: any) => item.pa_action_by)?.join(';\n');

          return exportData
        });
    const a = [1]
    const Emptymapped = a.map((j: any) => {
      let exportData: any = {}
    if (columnHeaders["CalendarName"] == true) exportData["Calendar Name"] = ""
    if (columnHeaders["Ecode"] == true) exportData["Ecode"] = ""
    if (columnHeaders["Ename"] == true) exportData["Employee Name"] = ""
    if (columnHeaders["Firstname"] == true) exportData["Known As"] = ""
    if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date "] = ""      
    if (columnHeaders["Eposition"] == true) exportData["Position"] = ""   
    if (columnHeaders["EGrade"] == true) exportData["Grade"] = ""
    if (columnHeaders["Function"] == true) exportData["Function"] = ""
    if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = ""
    if (columnHeaders["EmailID"] == true) exportData["Email ID"] = ""
    // if (columnHeaders["EDivision"] == true) exportData["Division"] = ""
    if (columnHeaders["EDivision"] == true) exportData["Company"] = ""
    if (columnHeaders["ESection"] == true) exportData["Section"] = ""
    if (columnHeaders["ESubSection"] == true) exportData["Sub-section"] = ""
    if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = ""
    if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = ""
    if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = ""
    if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = ""
    if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = ""
    if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = ""
    if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = ""
    if (columnHeaders["employeerating"] == true) exportData["Employee Rating "] = ""
    if (columnHeaders["appraiserRating"] == true) exportData["Appraiser Rating"] = ""
    // if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Rating"] = ""
    // if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Rating"] = ""
    // if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Status"] = ""
    // if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Status"] = ""
    if (columnHeaders["OverallRating"] == true) exportData["Overall Rating"] = ""
    if (columnHeaders["PreviousRating"] == true) exportData["Previous Period Rating"] = ""
    if (columnHeaders["PotentialLevel"] == true) exportData["Potential Level"] = ""
    if (columnHeaders["TalentCategory"] == true) exportData["Talent Category"] = ""      
    if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = ""
    if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = ""
    if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = ""  
    if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] =""
    if (columnHeaders["status"] == true) exportData["Status"] = ""
    if (columnHeaders["workflowHistory"] == true) exportData["Workflow History"] = ""
      return exportData
    });
    // Use Emptymapped when the filtered data is empty
    if (mapped.length === 0) {
      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(Emptymapped);
    } else {
      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(mapped);
    }
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1"); 
    XLSX.writeFile(wb, "MyExcel.xlsx");
  }
// function for generate report drawer close function
  const handleCloseGrade1 = () => {
    setisDrawerOpen1(false);
    // making checkbox activate as true while closing the drawer
    setcolumnHeadersDisplay({
      TrainingRecommendation:true,
      Areaofimprovement:true,
      furtherrecommendation:true
    })
  }
  // function for Excelreport drawer close function
  const handleCloseGrade = () => {
    // making checkbox activate as true while closing the drawer
    setcolumnHeaders({
    Ecode: true,
    Ename: true,
    Eposition: true,
    Firstname:true,
    ESection: true,
    EGrade: true,
    EDivision: true,
    ESubSection: true,
    AppraiserName: true,
    ReviewerName: true,
    NormalizerName: true,
    OverallRating: true,
    employeerating : true,
    appraiserRating: true,
    // reviewerRating:true,
    // normalizerRating:true,
    PreviousRating: true,
    PotentialLevel: true,
    TalentCategory: true,
    WorkLocation: true,
    ManagerCode: true,
    ManagerName: true,
    ManagerPosition: true,
    PendingAction:true,
    Rating: true,
    PAStatus: true,
    pendingAction: true,
    appraiserCode:true,
    reviewerCode:true,
    normalizerCode:true,
    status:true,
    // email:true,
    // rejectionStatus:true,
    ServiceReferenceDate:true,
    Function:true,
    SupervisoryRole:true,
    EmailID:true,
    CalendarName:true,
    SelectAll: true,
    workflowHistory : true
    })
    setisDrawerOpen(false);
    setheadingSortAccept(false);
  };
  // Excel report drawer Apply button
  const handleheadingSortAccept = () => {
    setisDrawerOpen(false);
    handleExport();
  };
   // Generate report drawer Apply button
  const handleheadingSortAccept1 = () => {
    setisDrawerOpen1(false);
    handleExport3(columnHeadersDisplay)
     // making checkbox activate as true while closing the drawer
    setcolumnHeadersDisplay({
      TrainingRecommendation:true,
      Areaofimprovement:true,
      furtherrecommendation:true
    })
  }
  const Appraiserdata = users?.map((j: any) => {
    return {
      ECode: j?.employee_code,
      EmployeeName: j?.legal_full_name,
      Position: j?.position_long_description,
      Grade: j?.grade,
      PendingAction: getPAStatus(j),
      AppraiserRating:
        j?.appraisal?.appraiser_rating == 0 ? (
          <p> - </p>
        ) : (
          j?.appraisal?.appraiser_rating
        ),
      ReviewerRating:
        j?.reviewerIsDisabled && j?.reviewer.reviewer_rating != 0 ? (
          j?.reviewer.reviewer_rating
        ) : (
          <p> - </p>
        ),
      NormalizerRating:
        j?.normalizerIsDisabled && j?.normalizer?.normalizer_rating != 0 ? (
          j?.normalizer?.normalizer_rating
        ) : (
          <p> - </p>
        ),
      Status: getStatus(j?.appraisal?.status),
    };
  });
  console.log(Appraiserdata, "data")
  const [activeTemplate, setactiveTemplate] = useState<any>([]);
  console.log(activeTemplate, "activeTemplate")

  console.log(tabValue, "tabValue");
  useEffect(() => {
    const Dashfilter = users &&
      users
        ?.filter((j: any) => j?.appraisal?.status !== "excepted")
        ?.filter((j: any, index: any) => {
          const status = j?.appraisal?.status?.toLowerCase();
          if (tabValue === 2) {
            return status === "not-started";
          }
          else if (tabValue === 0) {
            return j;
          } else if (tabValue === 1) {
            return j?.appraisal?.pa_status?.includes("appraiser") || j?.appraisal?.status?.includes("not-started");
          }
          else if (tabValue === 3) {
            return j?.appraisal?.status?.includes("in-progress") || j?.appraisal?.status?.includes("normalized");
          } else if (tabValue === 4) {
            return j?.appraisal?.status?.includes("rejected");
          } else if (tabValue === 5) {
            return j?.appraisal?.status?.includes("completed");
          }
        })
        ?.filter((item1: any) => {
          if (SectionFilter?.includes("None") || SectionFilter?.length === 0) {
            return item1;
          } else {
            return !!SectionFilter?.find((item2: any) => item1?.section === item2)
          }
        })
        ?.filter((item1: any) => {
          if (subSectionFilter?.includes("None") || subSectionFilter?.length === 0) {
            return item1;
          } else {
            return !!subSectionFilter?.find((item2: any) => item1?.sub_section === item2)
          }
        })
        ?.filter((j: any) => {
          if (positions === "None" || positions === "") {
            return j;
          } else {
            return j?.position_long_description
              ?.toLocaleLowerCase()
              ?.includes(positions?.toLocaleLowerCase());
          }
        })
        .filter((item1: any) => {
          if (sectionsFilter.includes("None") || sectionsFilter.length === 0) {
            return item1;
          } else {
            return !!sectionsFilter?.find((item2: any) => item1?.section === item2)
          }
        })
        .filter((item1: any) => {
          if (positionsFilter.includes("None") || positionsFilter.length === 0) {
            return item1;
          } else {
            return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
          }
        })
        .filter((item1: any) => {
          if (GradesFilter.includes("None") || GradesFilter.length === 0) {
            return item1;
          } else {
            return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
          }
        })
        .filter((j: any) => {
          if (empgrades === "None" || empgrades === "") {
            return j;
          } else {
            return j?.grade
              ?.toLocaleLowerCase()
              ?.includes(empgrades?.toLocaleLowerCase());
          }
        })
        .filter((j: any) => {
          if (enteredName === "") {
            return j;
          } else if (
            (j?.employee_code !== undefined &&
              j?.employee_code
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.legal_full_name !== undefined &&
              j?.legal_full_name
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j.grade !== undefined &&
              j?.grade
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j.position_long_description !== undefined &&
              j?.position_long_description
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.appraisal?.status !== undefined &&
              j?.appraisal?.status
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (getPAStatus(j) !== undefined &&
              getPAStatus(j)
                ?.toLocaleLowerCase()
                ?.includes(enteredName?.toLocaleLowerCase()))
          ) {
            return j;
          }
        })
    setactiveTemplate(Dashfilter)
  }, [users, tabValue])
  //for section multiselect
  const [sectionsFilter, setsectionsFilter] = React.useState<string[]>([]);
  const [show, setShow] = React.useState<any>(0);
  console.log(show, "show");
  // employeedata based on tabvalue applied
  useEffect(() => {
    let PendingAction = users?.filter((j: any) => {
      return j?.appraisal?.pa_status?.includes("Appraiser")
    });
    let Notstarted = users?.filter((j: any) => {
      return j?.appraisal?.status === "not-started";
    });
    let inprogress = users?.filter((j: any) => {
      return j?.appraisal?.status === "in-progress" || j?.appraisal?.status === "normalized";
    });
    let emprejected = users?.filter((j: any) => {
      return j?.appraisal?.status === "rejected";
    });
    let completed = users?.filter((j: any) => {
      return j?.appraisal?.status === "completed";
    });
    if (tabValue === 0) {
      setEmployeeData(users)
    } else if (tabValue === 1) {
      setEmployeeData(PendingAction)
    } else if (tabValue === 2) {
      setEmployeeData(Notstarted)
    } else if (tabValue === 3) {
      setEmployeeData(inprogress)
    } else if (tabValue === 4) {
      setEmployeeData(emprejected)
    } else if (tabValue === 5) {
      setEmployeeData(completed)
    }
  }, [tabValue, users])
  console.log(EmployeeData, "EmployeeDataEmployeeData")
  useEffect(() => {
    const All = Appraiserdata?.filter((j: any, index: number) => {
      {
        return j;
      }
    });
    let Completed = Appraiserdata?.filter((j: any, index: number) => {
      {
        return j?.status
          ?.toLocaleLowerCase()
          ?.includes("completed"?.toLocaleLowerCase());
      }
    });
    const inProgress = Appraiserdata?.filter((j: any, index: number) => {
      {
        return j?.status
          ?.toLocaleLowerCase()
          ?.includes("in-progress"?.toLocaleLowerCase());
      }
    });
    const notStarted = Appraiserdata?.filter((j: any, index: number) => {
      {
        console.log(
          j?.Status?.toLocaleLowerCase()?.includes(
            "not-started"?.toLocaleLowerCase()
          ),
          "position"
        );
        return j?.Status?.toLocaleLowerCase()?.includes(
          "not-started"?.toLocaleLowerCase()
        );
      }
    });
    const Rejected = Appraiserdata?.filter((j: any, index: number) => {
      {
        return j?.status
          ?.toLocaleLowerCase()
          ?.includes("rejected"?.toLocaleLowerCase());
      }
    });
    if (tabValue === 0) {
      setShow(All);
    } else if (tabValue === 1) {
      setShow(Completed);
    } else if (tabValue === 2) {
      setShow(inProgress);
    } else if (tabValue === 3) {
      setShow(notStarted);
    } else if (tabValue === 4) {
      setShow(Rejected);
    } else if (tabValue === 5) {
      setShow(Appraiserdata);
    }
  }, [users, tabValue]);

  const onScroll = () => {
    console.log('running scroll')
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setPageNumber(pageNumber + 1);
      }
    }
  };
  const maxLengthForSearch = 30;
  // function for searchfield
  const handleSearchBar = (e: any) => {
      if (e.target.value.length > maxLengthForSearch) {
        e.target.value = e.target.value.slice(0, maxLengthForSearch);
      }
      setenteredName(e.target.value);
    setPage(0);
    }

  return (
    <Mytable>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="30px"
        paddingBottom="10px"
      >
        <AlertDialogSuccess
          isAlertOpen={GenerateReportOpen}
          handleAlertClose={handleGenerateReportOpen}
        >
          Unable to Generate Report: No employees have completed appraisals.
        </AlertDialogSuccess>
        <GenerateReport
          isDrawerOpen1={isDrawerOpen1}
          setisDrawerOpen1={setisDrawerOpen1}
          handleTrainingRecommendation={handleTrainingRecommendation}
          handlefurtherrecommendation={handlefurtherrecommendation}
          handleAreaofimprovement={handleAreaofimprovement}
          handleheadingSortAccept1={handleheadingSortAccept1}
          handleCloseGrade1={handleCloseGrade1}
          columnHeadersDisplay={columnHeadersDisplay}
        />
        <Heading> My Team</Heading>
        <Button
          style={{
            textTransform: "none",
            fontSize: "15px",
            fontFamily: "Arial",
            borderColor: "#3E8CB5",
            color: "#3E8CB5",
            background: "transparent",
            height: "35px",
          }}
          variant="outlined"
          onClick={handleExportFunction1}
        >
          Generate  Report
        </Button>
      </Stack>
      {approved && <Alert severity="success">Approved successfully!</Alert>}
      <Stack
        marginLeft="24px"
        marginRight="24px"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderBottom: 1, borderColor: "#E3E3E3", }}
      >
        <Tabstyles>
          <Box sx={{ maxWidth: { xs: 490, md: 730, lg: 1000 } }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                label={
                  <StyledBadge badgeContent={All} color="primary" max={999}>
                    All
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "200px" }}
                label={

                  <StyledBadge
                    badgeContent={mypendingActions}
                    color="primary"
                    max={999}
                  >
                    My pending actions
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "155px" }}
                label={
                  <StyledBadge
                    badgeContent={notStartedemp}
                    color="primary"
                    max={999}
                  >
                    Not started
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "142px" }}
                label={

                  <StyledBadge
                    badgeContent={inProgressemp}
                    color="primary"
                    max={999}
                  >
                    In progress
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "190px" }}
                label={
                  <StyledBadge badgeContent={emprejected} color="primary" max={999}>
                    Employees rejection
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "125px" }}
                label={
                  <StyledBadge
                    badgeContent={completedemp}
                    color="primary"
                    max={999}
                  >
                    Completed
                  </StyledBadge>
                }
              />
            </Tabs>
          </Box>
        </Tabstyles>
        <div>
          <Stack direction="row" alignItems="flex-start" >
            <Searchfeild >
              <TextField
                id="outlined-basic"
                placeholder="Search Here..."
                autoComplete="off"
                inputProps={{ maxLength: 256 }}
                onChange={handleSearchBar}
                value={enteredName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={Searchlensreview} alt="icon" />
                    </InputAdornment>
                  ),
                }}
              />
            </Searchfeild>
            <div>
              <img
                src={Newexcel}
                alt="icon"
                style={{ marginLeft: "15px", marginTop: "5px", cursor: "pointer" }}
                onClick={handleExportFunction}
              />
            </div>
          </Stack>
        </div>
        <ExportToexcel 
        isDrawerOpen={isDrawerOpen}
         setisDrawerOpen={setisDrawerOpen}
         checkedCount={checkedCount}
         totalCount={totalCount}
         columnHeaders={columnHeaders}
         handleSelectAll={handleSelectAll}
         handleheadingCalendar={handleheadingCalendar}
         handleEmployeeCode={handleEmployeeCode}
         handleheading1={handleheading1}
         handlefirstName={handlefirstName}
         handleServiceReferenceDateData={handleServiceReferenceDateData}
         handleheading2={handleheading2}
         handleheading3={handleheading3}
         handleFunctionData={handleFunctionData}
         handleSupervisoryRoleData={handleSupervisoryRoleData}
         handleEmailIDData={handleEmailIDData}
         handleDivision={handleDivision}
         handleSection={handleSection}
         handleSubSection={handleSubSection}
         handleWorkLocation={handleWorkLocation}
         handleAppCodes={handleAppCodes}
         handleAppraiserName={handleAppraiserName}
         handleRevCodes={handleRevCodes}
         handleReviewerName={handleReviewerName}
         handleNorCodes={handleNorCodes}
         handleNormalizerName={handleNormalizerName}
         handleemployeerating={handleemployeerating}
         handleAppraiserRating={handleAppraiserRating}
         handleReviewerRating={handleReviewerRating}
         handleNormalizerRating={handleNormalizerRating}
         handleOverallRating={handleOverallRating}
         handlePreviousRating={handlePreviousRating}
         handlePotentialLevel={handlePotentialLevel}
         handleTalentCategory={handleTalentCategory}
         handleManagerCode={handleManagerCode}
         handleManagerName={handleManagerName}
         handleManagerPosition={handleManagerPosition}
         handlePendingAction={handlePendingAction}
         handleStatus={handleStatus}
         handleheadingSortAccept={handleheadingSortAccept}
         handleCloseGrade={handleCloseGrade}
         handleWorkflowHistory = {handleWorkflowHistory}/>
      
      </Stack>
      <TableHeadings>
        <TabPanel value={tabValue} index={0} >
          <MyTeamTable users={EmployeeData}
            positionsFilter={positionsFilter}
            setpositionsFilter={setpositionsFilter}
            GradesFilter={GradesFilter}
            setGradesFilter={setGradesFilter}
            setPage={setPage}
            tabValue={tabValue}
            getPAStatus={getPAStatus}
            empNameClickHandler={empNameClickHandler}
            rowsPerPage={rowsPerPage}
            page={page}
            enteredName={enteredName}
            listInnerRef={listInnerRef}
            onScroll={onScroll}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />

        </TabPanel>
        <TabPanel value={tabValue} index={1} >
          <MyTeamTable users={EmployeeData}
            positionsFilter={positionsFilter}
            setpositionsFilter={setpositionsFilter}
            GradesFilter={GradesFilter}
            setGradesFilter={setGradesFilter}
            setPage={setPage}
            tabValue={tabValue}
            getPAStatus={getPAStatus}
            empNameClickHandler={empNameClickHandler}
            rowsPerPage={rowsPerPage}
            page={page}
            enteredName={enteredName}
            listInnerRef={listInnerRef}
            onScroll={onScroll}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2} >
          <MyTeamTable users={EmployeeData}
            positionsFilter={positionsFilter}
            setpositionsFilter={setpositionsFilter}
            GradesFilter={GradesFilter}
            setGradesFilter={setGradesFilter}
            tabValue={tabValue}
            setPage={setPage}
            getPAStatus={getPAStatus}
            empNameClickHandler={empNameClickHandler}
            rowsPerPage={rowsPerPage}
            page={page}
            enteredName={enteredName}
            listInnerRef={listInnerRef}
            onScroll={onScroll}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={3} >
          <MyTeamTable users={EmployeeData}
            positionsFilter={positionsFilter}
            setpositionsFilter={setpositionsFilter}
            GradesFilter={GradesFilter}
            setGradesFilter={setGradesFilter}
            tabValue={tabValue}
            setPage={setPage}
            getPAStatus={getPAStatus}
            empNameClickHandler={empNameClickHandler}
            rowsPerPage={rowsPerPage}
            page={page}
            enteredName={enteredName}
            listInnerRef={listInnerRef}
            onScroll={onScroll}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={4} >
          <MyTeamTable users={EmployeeData}
            positionsFilter={positionsFilter}
            setpositionsFilter={setpositionsFilter}
            GradesFilter={GradesFilter}
            setGradesFilter={setGradesFilter}
            tabValue={tabValue}
            setPage={setPage}
            getPAStatus={getPAStatus}
            empNameClickHandler={empNameClickHandler}
            rowsPerPage={rowsPerPage}
            page={page}
            enteredName={enteredName}
            listInnerRef={listInnerRef}
            onScroll={onScroll}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={5} >
          <MyTeamTable users={EmployeeData}
            positionsFilter={positionsFilter}
            setpositionsFilter={setpositionsFilter}
            GradesFilter={GradesFilter}
            setGradesFilter={setGradesFilter}
            tabValue={tabValue}
            setPage={setPage}
            getPAStatus={getPAStatus}
            empNameClickHandler={empNameClickHandler}
            rowsPerPage={rowsPerPage}
            page={page}
            enteredName={enteredName}
            listInnerRef={listInnerRef}
            onScroll={onScroll}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TabPanel>
      </TableHeadings> 
    </Mytable>
  );
};
export default MyTeam;