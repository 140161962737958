import { Typography } from '@mui/material'
import React from 'react'
import FeedbackQuestionnaireAppraiser from '../../appraisal/FeedbackQuestionnaireAppraiser'
import FeedbackQuestionnaireView from '../overallFeedback/FeedbackQuestionnaireView'
import AreaOfImprovementView from '../overallFeedback/AreaOfImprovementView'
import AreaOfImprovementEdit from '../overallFeedback/AreaOfImprovementEdit'
import TrainingRecommendationView from '../overallFeedback/TrainingRecommendationView'
import TrainingRecommendationEdit from '../overallFeedback/TrainingRecommendationEdit'
import AppraiserMessageForEmployeeView from '../overallFeedback/AppraiserMessageForEmployeeView'
import EmployeeCommentsEdit from '../overallFeedback/EmployeeCommentsEdit'
import OneToOneMeetingDateEdit from '../overallFeedback/OneToOneMeetingDateEdit'
import AppraiserDiscussedRatingWithEmployeeView from '../overallFeedback/AppraiserDiscussedRatingWithEmployeeView'

const OverallFeedBackTab = (props: any) => {
  const {
    showIfEmployee,
    showIfAppraiser,
    employee_id,
    employeeData,
    showAppraiserAreaOfImprovement,
    appraiserAreaOfImprovement,
    employeeAreaOfImprovement,
    setEmployeeAreaOfImprovement,
    disableTextAfterSubmission,
    moveTab,
    setMoveTab,
    navPrompt,
    setNavPrompt,
    showAppraiserTrainingRecommendation,
    appraiserTrainingRecommendation,
    employeeTrainingRecommendation,
    setEmployeeTrainingRecommendation,
    appraisalTemplateTrainingRecommendation,
    employeeComments,
    setEmployeeComments,
    oneToOneMeetingDate,
    setOneToOneMeetingDate,
    appraiserFeedbackQuestionnaire,
    setAppraiserFeedbackQuestionnaire,
    appraiserMessageForEmployee,
    employeeDataIsLoading,
    employeeDataIsFetching,
    showIfEmployeeLanding,
    showIfEmployeeReject,
    appraiserAgreedRatingWithEmployee
  } = props;
  return (
    <>
      <Typography
        style={{
          marginTop: "20px",
          fontSize: "20px",
          color: "#3E8CB5",
          fontFamily: "Arial",
          marginBottom: "20px",
          // marginLeft: "36px"
        }}
      >
        Performance Appraisal Summary
      </Typography>


      <div 
      // style={{ marginRight: "65px", marginLeft: "36px" }}
      >
        <FeedbackQuestionnaireView
          employeeData={employeeData}
          appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire}
          setAppraiserFeedbackQuestionnaire={setAppraiserFeedbackQuestionnaire} />

        {(showAppraiserAreaOfImprovement &&
          <AreaOfImprovementView
            showAreaOfImprovement={showAppraiserAreaOfImprovement}
            areaOfImprovement={appraiserAreaOfImprovement}
            showIfAppraiser={showIfAppraiser}
          />
        )}

        <AreaOfImprovementEdit
          showIfEmployee={showIfEmployee}
          areaOfImprovement={employeeAreaOfImprovement}
          setAreaOfImprovement={setEmployeeAreaOfImprovement}
          disableTextAfterSubmission={disableTextAfterSubmission}
          moveTab={moveTab}
          setMoveTab={setMoveTab}
          navPrompt={navPrompt}
          setNavPrompt={setNavPrompt}
          employeeDataIsLoading = {employeeDataIsLoading}
          employeeDataIsFetching = {employeeDataIsFetching} />

        {showAppraiserTrainingRecommendation && (
          <TrainingRecommendationView
            showIfAppraiser={showIfAppraiser}
            showTrainingRecommendation={showAppraiserTrainingRecommendation}
            trainingRecommendation={appraiserTrainingRecommendation} />
        )}

        <TrainingRecommendationEdit
          showIfEmployee={showIfEmployee}
          trainingRecommendation={employeeTrainingRecommendation}
          setTrainingRecommendation={setEmployeeTrainingRecommendation}
          appraisalTemplateTrainingRecommendation={appraisalTemplateTrainingRecommendation}
          disableTextAfterSubmission={disableTextAfterSubmission}
          moveTab={moveTab}
          setMoveTab={setMoveTab}
          navPrompt={navPrompt}
          setNavPrompt={setNavPrompt}
          employeeDataIsLoading = {employeeDataIsLoading}
          employeeDataIsFetching = {employeeDataIsFetching} />
      </div>

      <AppraiserMessageForEmployeeView
        employeeData={employeeData}
        appraiserMessageForEmployee={appraiserMessageForEmployee} />        

      <EmployeeCommentsEdit
        disableTextAfterSubmission={disableTextAfterSubmission}
        moveTab={moveTab}
        setMoveTab={setMoveTab}
        navPrompt={navPrompt}
        setNavPrompt={setNavPrompt}
        employeeComments={employeeComments}
        setEmployeeComments={setEmployeeComments} 
        employeeDataIsLoading = {employeeDataIsLoading}
        employeeDataIsFetching = {employeeDataIsFetching}
        showIfEmployeeLanding = {showIfEmployeeLanding}
        showIfEmployeeReject = {showIfEmployeeReject}/>

      <OneToOneMeetingDateEdit
        disableTextAfterSubmission={disableTextAfterSubmission}
        moveTab={moveTab}
        setMoveTab={setMoveTab}
        navPrompt={navPrompt}
        setNavPrompt={setNavPrompt}
        oneToOneMeetingDate={oneToOneMeetingDate}
        setOneToOneMeetingDate={setOneToOneMeetingDate}
        employeeData={employeeData}
        employeeDataIsLoading = {employeeDataIsLoading}
        employeeDataIsFetching = {employeeDataIsFetching} />

        {/* <AppraiserDiscussedRatingWithEmployeeView 
        appraiserAgreedRatingWithEmployee = {appraiserAgreedRatingWithEmployee}/> */}


    </>
  )
}

export default OverallFeedBackTab