import { Drawer, IconButton, Popover, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography, styled } from '@mui/material'
import React, { useState } from 'react'
import RatingsHeader from '../../ratings/RatingsHeader'
import RatingsObjectiveDetails from '../../ratings/RatingsObjectiveDetails'
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Removeattnew from "../../../../assets/Images/Removeattnew.svg";
import { useAttachmentsEmployeeDeleteMutation, useEmployeeAcceptsAppraiserRatingMutation } from '../../../../service';
import thumsup_colored from "../../../../assets/Images/thumsup_colored.svg";
import Thumsup from "../../../../assets/Images/Thumsup.svg";
import thumbsdown_colored from "../../../../assets/Images/Thumbsdowncolored.svg";
import Thumsdown from "../../../../assets/Images/Thumsdown.svg";
import EmployeeRejectRatingSlider from './EmployeeRejectRatingSlider';


const RatingBackground = styled("div")({
    width: "27px",
    lineHeight: "27px",
    borderRadius: "50%",
    display: "block",
    // color: "white",  background: "red",
});

const EmployeeRatings = (props: any) => {
    const { employeeData,
        employee_id,
        objectiveDescription,
        colorarray,
        disableTextAfterSubmission,
        visibilityColumnData,
        isLoadingRejection,
        refetchEmployeeData,
        ratingData,
        moveTab,
        setMoveTab,
        navPrompt,
        setNavPrompt,
        updateEmployeeRejection,
        updateLoggedRole,
        employeeDataIsFetching } = props;
    const [activeObjectiveId, setActiveObjectiveId] = useState<any>();
    const [activeObjectiveDescriptionName, setActiveObjectiveDescriptionName] = useState<any>();
    const [popoverIndex, setPopoverIndex] = useState("");
    const [objectiveData, setObjectiveData] = useState<any>({})
    const [openDrawer, setOpenDrawer] = useState(false);
    const [acceptRating, setAcceptRating] = useState(false);

    // mutation
    const [deleteEmployeeMutation, { isLoading: delete1, data: deleted }] =
        useAttachmentsEmployeeDeleteMutation();
    const [updateAppraisalRating, { isLoading: isAcceptLoading }] = useEmployeeAcceptsAppraiserRatingMutation();

    // get attachment values
    const getAttachments = (id: any) => {
        return employeeData?.data?.appraisal?.attachments
            .filter((i: any) => i?.objective_description == id)
            .map((k: any) => {
                console.log(k, "zzzzz");
                return {
                    resp: (
                        <div>
                            {" "}
                            <a href={k.url}> {k.name} </a> <br />
                        </div>
                    ),
                    remove: k.name,
                };
            });
    };

    const getRejectionAppraisalAttachments = (id: any) => {
        return employeeData?.data?.appraisal?.rejection_attachments
            .filter((i: any) => i?.objective_description == id)
            .map((k: any) => {
                console.log(k, "zzzzz");
                return {
                    resp: (
                        <div>
                            {" "}
                            <a href={k.url}> {k.name} </a> <br />
                        </div>
                    ),
                    remove: k.name,
                };
                // return k.name
            });
    };

    const getEmployeeAttachment = (id: any) => {
        console.log(id, "id for attachmetns ");

        return employeeData?.data?.employee?.attachments
            .filter((i: any) => i?.objective_description == id)
            .map((k: any) => {
                console.log(k, "zzzzz");
                return {
                    resp: (
                        <div>
                            {" "}
                            <a href={k.url}> {k.name} </a> <br />
                        </div>
                    ),
                    remove: k.name,
                };
                // return k.name
            });
    };

    // popover for previous rating
    const [anchorPreviousRatingPopOver, setAnchorPreviousRatingPopOver] = React.useState<HTMLElement | null>(
        null
    );
    const openPreviousRating = Boolean(anchorPreviousRatingPopOver);
    const id_Previous_Rating = openPreviousRating ? "simple-popover" : undefined;

    const handlePreviousRatingPopOverClose = () => {
        setAnchorPreviousRatingPopOver(null)
    }

    const handlePreviousRatingPopOverOpen = (event: React.MouseEvent<HTMLElement>, j: any) => {
        setAnchorPreviousRatingPopOver(event.currentTarget);
    };

    //  popover for appraiser comments attachment
    const [anchorAppraiserCommentsAttachment, setAnchorAppraiserCommentsAttachment] = useState<HTMLButtonElement | null>(null);
    const openAppraiserCommentsAttachment = Boolean(anchorAppraiserCommentsAttachment);

    const handleClickOpenAppraiserCommentsAttachment = (event: React.MouseEvent<HTMLButtonElement>, j: any) => {
        setAnchorAppraiserCommentsAttachment(event.currentTarget);
    };

    const handleClickCloseAppraiserCommentsAttachment = () => {
        setAnchorAppraiserCommentsAttachment(null);
    };

    // popover for appraiser rejection reason
    const [anchorAppraiserRejectionReasonAttachment, setAnchorAppraiserRejectionReasonAttachment] = React.useState<HTMLButtonElement | null>(
        null
    );
    const openAppraiserRejectionReasonAttachment = Boolean(anchorAppraiserRejectionReasonAttachment);
    const idAppraiserRejectionReasonAttachment = openAppraiserRejectionReasonAttachment ? "simple-popover" : undefined;

    const handleClickOpenAppraiserRejectionReasonAttachment = (event: React.MouseEvent<HTMLButtonElement>, j: any) => {
        setAnchorAppraiserRejectionReasonAttachment(event.currentTarget);
    };

    const handleClickCloseAppraiserRejectionReasonAttachment = () => {
        setAnchorAppraiserRejectionReasonAttachment(null);
    };

    // popover for employee attachment
    const [anchorEmployeeAttachment, setAnchorEmployeeAttachment] = React.useState<HTMLButtonElement | null>(null);
    const openAnchorEmployeeAttachment = Boolean(anchorEmployeeAttachment);

    const handleClickOpenEmployeeAttachment = (event: React.MouseEvent<HTMLButtonElement>, j: any) => {
        setAnchorEmployeeAttachment(event.currentTarget);
    };

    const handleClickCloseEmployeeAttachment = () => {
        setAnchorEmployeeAttachment(null);
    };


    //  to display action button based on the appraiser rejected objective title

    const getActionStatus = (data: any, j: any) => {
        if (data?.appraisal?.appraiser_status == "appraiser-rejected-employee") {
            return data?.appraisal?.objective_description
                .filter(
                    (i: any) => i.name._id === j.name._id
                )
                .map((k: any) => {
                    if (k.rating_rejected == true || j.action_performed == true)
                        return k.ratings;
                })[0]
        } else {
            return data
        }
    }


    const openDrawerHandler = () => {
        setOpenDrawer(true);
    }

    return (
        <>
            <Drawer anchor={"right"} open={openDrawer}>
                <EmployeeRejectRatingSlider
                    objectiveData={objectiveData}
                    ratingData={ratingData}
                    moveTab={moveTab}
                    setMoveTab={setMoveTab}
                    navPrompt={navPrompt}
                    setNavPrompt={setNavPrompt}
                    openDrawer = {openDrawer} 
                    setOpenDrawer = {setOpenDrawer}
                    employeeData = {employeeData}
                    employee_id = {employee_id}
                    refetchEmployeeData = {refetchEmployeeData}
                    updateEmployeeRejection = {updateEmployeeRejection}
                    updateLoggedRole= {updateLoggedRole}
                    acceptRating = {acceptRating}
                    updateAppraisalRating = {updateAppraisalRating} />
            </Drawer>

            <div 
            // style={{ marginRight: "66px", marginLeft: "36px" }} 
            >
                <TableContainer>
                    <Table sx={{
                        borderCollapse: 'separate',
                        borderSpacing: '0px 15px'
                    }} size="small" aria-label="simple table">
                        <RatingsHeader
                            visiblityColumnData={visibilityColumnData}
                            isLoadingRejection={isLoadingRejection} />
                   
                    <TableBody>
                        {objectiveDescription?.map((j: any, index: any) => {
                            return (
                                <TableRow
                                    sx={{
                                        "& td, & th": {
                                            border: "1px solid #80808014 ",
                                            boxShadow: "1px 0px 0px 1px #80808014",
                                            borderBottom: "none",
                                            borderLeft: "0px",
                                            borderTop: "0px",
                                        },
                                    }}
                                >
                                    <RatingsObjectiveDetails
                                        index={index}
                                        colorarray={colorarray}
                                        popoverIndex={popoverIndex}
                                        setPopoverIndex={setPopoverIndex}
                                        objectiveDescription={j}
                                        activeObjectiveId={activeObjectiveId}
                                        setActiveObjectiveId={setActiveObjectiveId}
                                    />
                                    <TableCell
                                        width="10px"
                                        sx={{
                                            fontSize: "14x",
                                            color: "#333333",
                                            fontFamily: "Arial",
                                            background: "#ffffff",
                                            padding: "0px 8px"

                                        }}
                                        align="center"
                                    >
                                        <Stack
                                            direction="column"
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <div style={{ display: "inline-flex" }}>
                                                <span>
                                                    {" "}
                                                    {(j.appraiser_rating?.rating_rejected == true && j.appraiser_rating?.ratings) ?
                                                        <RatingBackground
                                                            onClick={(e: any) => {
                                                                handlePreviousRatingPopOverOpen(e, j);
                                                                setPopoverIndex(index)
                                                            }}
                                                            style={{ color: "white", background: "#D2122E",textAlign:"center" }}>
                                                            {j.appraiser_rating?.ratings?.rating}
                                                        </RatingBackground> :
                                                        ((j.appraiser_rating?.ratings &&
                                                            (j.appraiser_rating?.rating_accepted || j.appraiser_rating?.rating_resubmitted))) ?
                                                            <RatingBackground
                                                                onClick={(e: any) => {
                                                                    handlePreviousRatingPopOverOpen(e, j);
                                                                    setPopoverIndex(index)
                                                                }}
                                                                style={{ color: "white", background: "#3e8cb5",textAlign:"center" }}>
                                                                {j.appraiser_rating?.ratings?.rating}
                                                            </RatingBackground> :
                                                            j.appraiser_rating?.ratings?.rating
                                                    }
                                                    {employeeData?.data?.appraisal_previous_rating?.objective_description?.filter((i: any) => i.ratings)?.length > 0 && (
                                                        <Popover
                                                            id={id_Previous_Rating}
                                                            open={popoverIndex === index && openPreviousRating}
                                                            anchorEl={anchorPreviousRatingPopOver}
                                                            onClose={handlePreviousRatingPopOverClose}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'center',
                                                              }}
                                                              transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'center',
                                                              }}
                                                            PaperProps={{
                                                                style: {
                                                                    backgroundColor: "FEFCF8",
                                                                    boxShadow: "none",
                                                                    maxWidth: "400px",
                                                                    borderRadius: "5px",
                                                                },
                                                            }}
                                                            sx={{
                                                                "& .MuiPopover-paper": {
                                                                    border: "1px solid #3e8cb5",
                                                                    backgroundColor: "#ffffff",
                                                                    // width: "30%",
                                                                },
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    padding: "5px",
                                                                    fontSize: "12px",
                                                                    lineHeight: "20px",
                                                                    color: "#333333",
                                                                    fontFamily: "Arial",
                                                                }}
                                                            >
                                                                {<span>Previous Rating:{j.previous_rating}</span>}
                                                            </div>
                                                        </Popover>
                                                    )}



                                                </span>
                                            </div>
                                        </Stack>
                                    </TableCell>
                                    {visibilityColumnData?.showAppraiserComments &&
                                        (<TableCell
                                            width="250px"
                                            sx={{
                                                fontSize: "14x",
                                                color: "#333333",
                                                fontFamily: "Arial",
                                                background: "#fbfbfb",

                                            }}
                                            align="left"
                                        >
                                            {" "}
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                // justifyContent="center"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "14x",
                                                        color: "#333333",
                                                        fontFamily: "Arial",
                                                        wordBreak: "break-word",
                                                    }}
                                                >
                                                    {" "}
                                                    {(j.appraiser_rating?.comments == "" || j.appraiser_rating?.comments == undefined) ? "" : j.appraiser_rating?.comments}

                                                </span>
                                                {employeeData &&
                                                    getAttachments(j?.name?._id)?.length > 0 && (
                                                        <AttachFileIcon
                                                            sx={{
                                                                color: "#93DCFA",
                                                                height: "18px",
                                                                transform: "rotate(30deg)",
                                                                cursor: "pointer",
                                                            }}
                                                            aria-describedby={"id"}
                                                            onClick={(e: any) => {
                                                                handleClickOpenAppraiserCommentsAttachment(e, j);
                                                                setPopoverIndex(index);
                                                            }}
                                                        />
                                                    )}
                                                <Popover
                                                    id={"id"}
                                                    open={popoverIndex === index && openAppraiserCommentsAttachment}
                                                    anchorEl={anchorAppraiserCommentsAttachment}
                                                    onClose={handleClickCloseAppraiserCommentsAttachment}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            backgroundColor: "FEFCF8",
                                                            boxShadow: "none",
                                                            maxWidth: "400px",
                                                            borderRadius: "5px",
                                                        },
                                                    }}
                                                    sx={{
                                                        "& .MuiPopover-paper": {
                                                            border: "1px solid #3e8cb5",
                                                            backgroundColor: "#ffffff",
                                                            // width: "30%",
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: "12px",
                                                            fontFamily: "Arial",
                                                            color: "#333333",
                                                            // whiteSpace:"nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            width: "170px",
                                                            padding: "5px",
                                                        }}
                                                    >
                                                        {/* Attachments: {appraisalAttachments} */}
                                                        {employeeData &&
                                                            getAttachments(j?.name?._id)?.map(
                                                                (k: any, index1: any) => {
                                                                    return (
                                                                        <>
                                                                            <Stack
                                                                                spacing={1}
                                                                                direction="row"
                                                                                alignItems="center"
                                                                            >
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: "12px",
                                                                                        fontFamily: "Arial",
                                                                                        color: "#333333",

                                                                                        // maxWidth:"215px",
                                                                                        // wordBreak:"break-all"
                                                                                    }}
                                                                                >
                                                                                    {index1 + 1}.
                                                                                </Typography>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: "12px",
                                                                                        fontFamily: "Arial",
                                                                                        color: "#333333",
                                                                                        whiteSpace: "nowrap",
                                                                                        overflow: "hidden",
                                                                                        textOverflow:
                                                                                            "ellipsis",
                                                                                        width: "170px",
                                                                                    }}
                                                                                >
                                                                                    {k.resp}
                                                                                </Typography>
                                                                                <Stack direction="row">
                                                                                </Stack>
                                                                            </Stack>
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                    </Typography>
                                                </Popover>
                                            </Stack>
                                        </TableCell>)}

                                    {visibilityColumnData?.showAppraiserRejectionReason && (
                                        <TableCell
                                            width="250px"
                                            sx={{
                                                fontSize: "14x",
                                                color: "#333333",
                                                fontFamily: "Arial",
                                                background: "#ffffff",

                                            }}
                                            align="left"
                                        >
                                            {" "}
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                // justifyContent="center"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "14x",
                                                        color: "#333333",
                                                        fontFamily: "Arial",
                                                        wordBreak: "break-word",
                                                    }}
                                                >
                                                    {" "}
                                                    {j.appraiser_rating?.rating_rejected && j.appraiser_rating?.rejection_reason}

                                                </span>
                                                {employeeData &&
                                                    getRejectionAppraisalAttachments(j?.name?._id)?.length > 0 &&
                                                    (employeeData?.data?.appraisal?.objective_description
                                                        .filter(
                                                            (i: any) =>
                                                                i?.name?._id === j?.name?._id
                                                        )

                                                        .map((k: any) => {
                                                            console.log(
                                                                employeeData?.data?.appraisal
                                                                    ?.objective_description,
                                                                "data"
                                                            );
                                                            if (k?.rating_rejected == true) return k.rejection_reason;
                                                        })[0]) && (
                                                        <AttachFileIcon
                                                            sx={{
                                                                color: "#93DCFA",
                                                                height: "18px",
                                                                transform: "rotate(30deg)",
                                                                cursor: "pointer",
                                                            }}
                                                            aria-describedby={"id"}
                                                            onClick={(e: any) => {
                                                                handleClickOpenAppraiserRejectionReasonAttachment(e, j);
                                                                setPopoverIndex(index);
                                                            }}
                                                        />
                                                    )}
                                                <Popover
                                                    id={idAppraiserRejectionReasonAttachment}
                                                    open={popoverIndex === index && openAppraiserRejectionReasonAttachment}
                                                    anchorEl={anchorAppraiserRejectionReasonAttachment}
                                                    onClose={handleClickCloseAppraiserRejectionReasonAttachment}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            backgroundColor: "FEFCF8",
                                                            boxShadow: "none",
                                                            maxWidth: "400px",
                                                            borderRadius: "5px",
                                                        },
                                                    }}
                                                    sx={{
                                                        "& .MuiPopover-paper": {
                                                            border: "1px solid #3e8cb5",
                                                            backgroundColor: "#ffffff",
                                                            // width: "30%",
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: "12px",
                                                            fontFamily: "Arial",
                                                            color: "#333333",
                                                            // whiteSpace:"nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            width: "170px",
                                                            padding: "5px",
                                                        }}
                                                    >
                                                        {/* Attachments: {appraisalAttachments} */}
                                                        {employeeData &&
                                                            getRejectionAppraisalAttachments(j?.name?._id)?.map(
                                                                (k: any, index1: any) => {
                                                                    console.log(k, 'kkkkkkkkkkkkkkkk')
                                                                    return (
                                                                        <>
                                                                            <Stack
                                                                                spacing={1}
                                                                                direction="row"
                                                                                alignItems="center"
                                                                            >
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: "12px",
                                                                                        fontFamily: "Arial",
                                                                                        color: "#333333",

                                                                                        // maxWidth:"215px",
                                                                                        // wordBreak:"break-all"
                                                                                    }}
                                                                                >
                                                                                    {index1 + 1}.
                                                                                </Typography>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: "12px",
                                                                                        fontFamily: "Arial",
                                                                                        color: "#333333",
                                                                                        whiteSpace: "nowrap",
                                                                                        overflow: "hidden",
                                                                                        textOverflow:
                                                                                            "ellipsis",
                                                                                        width: "170px",
                                                                                    }}
                                                                                >
                                                                                    {k.resp}
                                                                                </Typography>
                                                                                <Stack direction="row">

                                                                                </Stack>
                                                                            </Stack>
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                    </Typography>
                                                </Popover>
                                            </Stack>
                                        </TableCell>
                                    )}

                                    {visibilityColumnData?.showEmployeeRating && (
                                        <TableCell
                                            width="10px"
                                            sx={{
                                                fontSize: "14x",
                                                color: "#333333",
                                                fontFamily: "Arial",
                                                // background: "#fbfbfb",
                                                padding: "0px 8px"

                                            }}
                                            align="center"

                                        >
                                            <div
                                                style={{ display: "inline-flex" }}
                                            >

                                                {j.rating_rejected == true && (
                                                    <RatingBackground style={{ color: "white", background: "#D2122E",textAlign:"center" }}>
                                                        {j.ratings && j.ratings.rating}
                                                    </RatingBackground>
                                                )}

                                            </div>

                                        </TableCell>
                                    )}
                                    {visibilityColumnData?.showEmployeeRejectionReason && (
                                        <TableCell
                                            width="250px"
                                            sx={{
                                                fontSize: "14x",
                                                color: "#333333",
                                                fontFamily: "Arial",
                                                background: "#fbfbfb",

                                            }}
                                            align="left"
                                        >
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                {j.rating_rejected ? (
                                                    <span
                                                        style={{
                                                            fontSize: "14x",
                                                            color: "#333333",
                                                            fontFamily: "Arial",
                                                            wordBreak: "break-word",
                                                        }}
                                                    >
                                                        {j.rejection_reason}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}

                                                {/* {j.rating_rejected ? ( */}

                                                {employeeData && j.rejection_reason &&
                                                    getEmployeeAttachment(j?.name?._id)?.length > 0 &&
                                                    j.rating_rejected && (
                                                        <AttachFileIcon
                                                            style={{
                                                                color: "#93DCFA",
                                                                height: "18px",
                                                                transform: "rotate(30deg)",
                                                                cursor: "pointer",
                                                            }}
                                                            aria-describedby={"id"}
                                                            onClick={(e: any) => {
                                                                handleClickOpenEmployeeAttachment(e, j);
                                                                setPopoverIndex(index);
                                                            }}
                                                        />
                                                    )}
                                                <Popover
                                                    id={"id"}
                                                    open={popoverIndex === index && openAnchorEmployeeAttachment}
                                                    anchorEl={anchorEmployeeAttachment}
                                                    onClose={handleClickCloseEmployeeAttachment}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            backgroundColor: "FEFCF8",
                                                            boxShadow: "none",
                                                            maxWidth: "400px",
                                                            borderRadius: "5px",
                                                        },
                                                    }}
                                                    sx={{
                                                        "& .MuiPopover-paper": {
                                                            border: "1px solid #3e8cb5",
                                                            backgroundColor: "#ffffff",
                                                            // width: "30%",
                                                        },
                                                    }}
                                                >
                                                    <div
                                                        // sx={{
                                                        //   p: 2,
                                                        //   backgroundColor: "#f8f8ff",
                                                        // }}
                                                        style={{
                                                            padding: "5px",
                                                            fontSize: "12px",
                                                            lineHeight: "20px",
                                                            color: "#333333",
                                                            fontFamily: "Arial",
                                                        }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                fontSize: "12px",
                                                                fontFamily: "Arial",
                                                                color: "#333333",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                width: "170px",
                                                            }}
                                                        >
                                                            {/* Attachments: {employeeAttachments} */}
                                                            {employeeData &&
                                                                getEmployeeAttachment(
                                                                    j?.name?._id
                                                                )?.map((k: any, index1: any) => {
                                                                    return (
                                                                        <>
                                                                            <Stack
                                                                                spacing={1}
                                                                                direction="row"
                                                                                alignItems="center"
                                                                            >
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: "12px",
                                                                                        fontFamily: "Arial",
                                                                                        color: "#333333",

                                                                                        // maxWidth:"215px",
                                                                                        // wordBreak:"break-all"
                                                                                    }}
                                                                                >
                                                                                    {index1 + 1}.
                                                                                </Typography>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: "12px",
                                                                                        fontFamily: "Arial",
                                                                                        color: "#333333",
                                                                                        whiteSpace: "nowrap",
                                                                                        overflow: "hidden",
                                                                                        textOverflow:
                                                                                            "ellipsis",
                                                                                        width: "170px",
                                                                                    }}
                                                                                >
                                                                                    {k.resp}
                                                                                </Typography>
                                                                                <Stack direction="row">

                                                                                    {disableTextAfterSubmission ?
                                                                                        <img
                                                                                            src={Removeattnew}
                                                                                            style={{ cursor: "default" }}
                                                                                        /> :
                                                                                        <img
                                                                                            src={Removeattnew}
                                                                                            style={{ cursor: "pointer" }}
                                                                                            onClick={() =>
                                                                                                deleteEmployeeMutation(
                                                                                                    {
                                                                                                        employee_id:
                                                                                                            employee_id,
                                                                                                        objective_description: j.name._id,
                                                                                                        name: k.remove,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    }

                                                                                    {/* </IconButton> */}
                                                                                </Stack>
                                                                            </Stack>
                                                                        </>
                                                                    );
                                                                })}
                                                        </Typography>

                                                    </div>
                                                </Popover>
                                                {/* </Dialog> */}
                                            </Stack>
                                        </TableCell>
                                    )}

                                    {employeeData?.data?.appraisal?.pa_status?.includes("Pending with Employee") &&
                                        ((employeeData?.data?.appraisal?.status == "normalized") ||
                                            ((employeeData?.data?.employee?.objective_description?.filter((item: any) =>
                                                item.rating_rejected == true).length > 0 ||
                                                employeeData?.data?.appraisal?.objective_description?.filter((item: any) =>
                                                    item.rating_rejected == true).length > 0))) &&
                                        (

                                            <TableCell
                                                width="50px"
                                                sx={{
                                                    fontSize: "14x",
                                                    color: "#333333",
                                                    opacity: "80%",
                                                    fontFamily: "arial",
                                                    // background: "#fbfbfb",
                                                    padding: "0px 8px"

                                                }}
                                                align="center"
                                            >
                                                <Stack direction="row" justifyContent="center">


                                                    {employeeData && getActionStatus(employeeData.data, j) && (
                                                        <>
                                                            {(employeeData?.data?.appraisal?.status !== "normalized") &&
                                                                (j.action_performed === true && j.rating_rejected === false ?

                                                                    <Tooltip title="Accepted">

                                                                        <IconButton
                                                                            disabled={isLoadingRejection || employeeDataIsFetching || isAcceptLoading}
                                                                            onClick={() => {
                                                                                setAcceptRating(true);
                                                                                // openAcceptDrawerHandler();
                                                                                setObjectiveData(j);
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={thumsup_colored}
                                                                                alt="icon"
                                                                                style={{ width: "16px", height: "16px" }}
                                                                            />
                                                                        </IconButton>

                                                                    </Tooltip>
                                                                    : <Tooltip title="Accept">
                                                                        <IconButton
                                                                            disabled={isLoadingRejection || employeeDataIsFetching || isAcceptLoading}
                                                                            onClick={() => {
                                                                                // openAcceptDrawerHandler();
                                                                                setAcceptRating(true);
                                                                                setObjectiveData(j)
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={Thumsup}
                                                                                alt="icon"
                                                                                style={{
                                                                                    width: "16px",
                                                                                    height: "16px",
                                                                                }}
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>)
                                                            }
                                                            {j.action_performed === true &&
                                                                j.rating_rejected === true ? (
                                                                <Tooltip title="Rejected">
                                                                    <IconButton
                                                                     disabled={isLoadingRejection || employeeDataIsFetching || isAcceptLoading}
                                                                        onClick={() => {
                                                                            setObjectiveData(j);
                                                                            openDrawerHandler();

                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={thumbsdown_colored}
                                                                            alt="icon"
                                                                            style={{
                                                                                width: "16px",
                                                                                height: "16px",
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="Reject">
                                                                    <IconButton 
                                                                    disabled={isLoadingRejection || employeeDataIsFetching || isAcceptLoading}
                                                                        onClick={() => {
                                                                            setObjectiveData(j);
                                                                            openDrawerHandler();

                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={Thumsdown}
                                                                            alt="icon"
                                                                            style={{
                                                                                width: "16px",
                                                                                height: "16px",
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                    )}

                                                </Stack>
                                            </TableCell>
                                        )}



                                </TableRow>
                            )
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

export default EmployeeRatings