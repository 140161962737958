import * as React from "react";
import { Typography,Stack,Breadcrumbs } from "@mui/material";
import ExpandedTeamTableofNormalizer from "./ExpandedTeamTableofNormalizer";
import { Link,useLocation  } from "react-router-dom";
import Loader from "../../../../../components/Loader/Loader"
interface StateType {
  range: {
    [x: string]: any;
    users: any[]; // Replace 'any' with the appropriate type for the 'users' property
    // Other properties in the 'range' object if any
  };
  // Other properties in the state object if any
}
export default function ExpandedTeamtableParentofNormalizer(props: any) {
  const location: any = useLocation();
  console.log(location?.state,"location")
const [breadCrumbName, setbreadCrumbName] = React.useState<any>("")
const tabLocation = location?.state?.tab;
const expandEmployees:any = (location?.state as StateType)?.range?.EmployeeData;
const valueOfCard:any = location?.state?.setvalueOfCard;

  return (

    <>
      <div
        style={{
          background: "#f1f1f1",
          minHeight: "100px",
          overflow: "hidden",
           height: "auto",
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
          minHeight="50px"
          marginLeft="32px"
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/normalizer"}
              state={{
                from: "toMyTeamDashboard",
                breadCrumbName: breadCrumbName
              }}
            >
              My Team Dashboard
            </Link>
            <Link
              to={"/normalizer"}
              state={{
                from: `${1}`,
                valueOfCard: valueOfCard,
                tabLocation: tabLocation,
                breadCrumbName: breadCrumbName
              }}
            >
              <Typography
                style={{
                  fontSize: "18px",
                  color: "#3e8cb5",
                  fontFamily: "Arial",
                }}
                color="text.primary"
              >
                My Actions
              </Typography>
            </Link>
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
              color="text.primary"
            >
              {breadCrumbName}
            </Typography>
          </Breadcrumbs>
        </Stack>
        <div>
          <ExpandedTeamTableofNormalizer 
          valueOfCard={valueOfCard}
          expandEmployees ={expandEmployees}
          setbreadCrumbName={setbreadCrumbName}
          tabLocation={tabLocation}
          />
        </div>
      </div>
    </>
  );
}
