import { Button, Link, Stack } from '@mui/material'
import React from 'react'
import { APPRAISER_SUBMISSION,APPRAISER_RESUBMISSION, EMPLOYEE_APPRAISER_SUBMISSION, APPRAISER_ACTION } from '../../../constants/routes/Routing'
import { useParams, useNavigate } from "react-router-dom";

const AppraiserFooterButton = (props:any) => {
  const { employeeData } = props
  const navigate = useNavigate();
  const { employee_id } = useParams();
  const handleReject = () => {
    if (employeeData?.data?.appraisal?.pa_status?.includes("Pending with Appraiser") && employeeData?.data.reviewer.reviewer_status?.includes("rejected")) {
      navigate(`${APPRAISER_RESUBMISSION}/employee/${employee_id}`);
    }
    else if (employeeData?.data?.appraisal?.pa_status?.includes("Pending with Appraiser") && employeeData?.data.appraisal?.status?.includes("rejected")) {
      navigate(`${APPRAISER_ACTION}/employee/${employee_id}`);
    } 
    // else if (employeeData?.data?.appraisal?.pa_status?.includes("Pending with Appraiser") && employeeData?.data.reviewer.reviewer_status?.includes("rejected")) {
    //   navigate(`${APPRAISER_RESUBMISSION}/employee/${employee_id}`);
    // }
     else{
    //  if (employeeData?.data?.appraisal?.pa_status?.includes("Pending with Appraiser") && employeeData?.data.normalizer.normalizer_status?.includes("rejected")) {
      navigate(`${APPRAISER_RESUBMISSION}/employee/${employee_id}`);
    }
  }

  const handleCancel = () =>{
    navigate('/dashboardreview', { state: { from: 1 } });

  }
  return (
   <>
    <Stack justifyContent="center" spacing={2} paddingTop="30px" direction="row">
        <>

          <Button
            variant="outlined"
            style={{
              backgroundColor: "Transparent",
              fontSize: "15px",
              fontWeight: 400,
              textTransform: "none",
              color: "#3e8cb5",
              borderColor: "#3E8CB5",
              height: "35px",
              fontFamily: "Arial",
              width: "110px",
            }}
            onClick={() => { handleReject() }}
          >
            Re-Submit
          </Button>
            <Button
              variant="outlined"
              style={{
                backgroundColor: "Transparent",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "none",
                color: "#3e8cb5",
                borderColor: "#3E8CB5",
                height: "35px",
                fontFamily: "Arial",
                width: "70px",
              }}
              onClick ={() => {handleCancel()}}
            >
              Cancel
            </Button>
        </>
      </Stack>
   </>
  )
}

export default AppraiserFooterButton