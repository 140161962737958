import React, { useState } from 'react'
import { useAttachmentsEmployeeDeleteMutation} from '../../../service';
import RatingTable from './RatingTable';


const RatingsTab = (props: any) => {

    // states and variables
    const { employeeData,
        employee_id,
        objectiveDescription,
        colorarray,
        disableTextAfterSubmission,
        visiblityColumnData,
        showIfpreviousappraisal } = props;
    const [popoverIndex, setPopoverIndex] = useState<any>("");
    const [activeObjectiveId, setActiveObjectiveId] = useState<any>();
    const [employeeAttachments, setemployeeAttachments] = useState<any>("");


    // mutations
    const [deleteEmployeeMutation, { isLoading: delete1, data: deleted }] =
        useAttachmentsEmployeeDeleteMutation();


    // popovers for ObjectiveTitleInfo
    const [anchorObjectiveTitleInfo, setAnchorObjectiveTitleInfo] = useState<HTMLButtonElement | null>(null);
    const openObjectiveTitleInfo = Boolean(anchorObjectiveTitleInfo);
    const idObjectiveTitleInfo = openObjectiveTitleInfo ? "simple-popover" : undefined;

    const handleOpenObjectiveTitleInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorObjectiveTitleInfo(event.currentTarget);

    };

    const handleCloseObjectiveTitleInfo = () => {
        setAnchorObjectiveTitleInfo(null);
    };

    // popovers for ObjectiveLevelInfo
    const [anchorObjectiveLevelInfo, setAnchorObjectiveLevelInfo] = useState<HTMLButtonElement | null>(null);
    const openObjectiveLevelInfo = Boolean(anchorObjectiveLevelInfo);
    const idObjectiveLevelInfo = openObjectiveLevelInfo ? "simple-popover" : undefined;

    const handleOpenObjectiveLevelInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        // setAnchorEl(event.currentTarget);
        setAnchorObjectiveLevelInfo(anchorObjectiveLevelInfo ? null : event.currentTarget);
    };

    const handleCloseObjectiveLevelInfo = () => {
        setAnchorObjectiveLevelInfo(null);
    };

    // popovers for PreviousRating
    const [anchorPreviousRatingPopOver, setAnchorPreviousRatingPopOver] = useState<HTMLElement | null>(null);
    const openPreviousRating = Boolean(anchorPreviousRatingPopOver);
    const id_Previous_Rating = openPreviousRating ? "simple-popover" : undefined;

    const handlePreviousRatingPopOverClose = () => {
        setAnchorPreviousRatingPopOver(null)
    }

    const handlePreviousRatingPopOverOpen = (event: React.MouseEvent<HTMLElement>, j: any) => {
        setAnchorPreviousRatingPopOver(event.currentTarget);
    };

    // popovers for Appraiser Comments Attachments
    const [anchorAppraiserCommentsAttachments, setAnchorAppraiserCommentsAttachments] = useState<HTMLButtonElement | null>(null);
    const openAppraiserCommentsAttachments = Boolean(anchorAppraiserCommentsAttachments);
    const idAppraiserCommentsAttachments = openAppraiserCommentsAttachments ? "simple-popover" : undefined;

    const handleOpenAppraiserCommentsAttachments = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorAppraiserCommentsAttachments(event.currentTarget);
    };

    const handleCloseAppraiserCommentsAttachments = () => {
        setAnchorAppraiserCommentsAttachments(null);
    };


    // popovers for Appraiser Rejection Reason Attachments
    const [anchorRejectionReasonAttachment, setAnchorRejectionReasonAttachment] = useState<HTMLButtonElement | null>(null);
    const openRejectionReasonAttachment = Boolean(anchorRejectionReasonAttachment);
    const idRejectionReasonAttachment = openRejectionReasonAttachment ? "simple-popover" : undefined;

    const handleOpenRejectionReasonAttachment = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorRejectionReasonAttachment(event.currentTarget);
    };

    const handleCloseRejectionReasonAttachment = () => {
        setAnchorRejectionReasonAttachment(null);
    };

    // popovers for Reviewer Comments Attachments
    const [anchorReviewerCommentsAttachments, setAnchorReviewerCommentsAttachments] = useState<HTMLButtonElement | null>(null);
    const openReviewerCommentsAttachments = Boolean(anchorReviewerCommentsAttachments);
    const idReviewerCommentsAttachments = openReviewerCommentsAttachments ? "simple-popover" : undefined;

    const handleOpenReviewerCommentsAttachments = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorReviewerCommentsAttachments(event.currentTarget);
    };

    const handleCloseReviewerCommentsAttachments = () => {
        setAnchorReviewerCommentsAttachments(null);
    };

     // popovers for Reviewer Rejection Reason Attachments
     const [anchorReviewerRejectionReasonAttachment, setAnchorReviewerRejectionReasonAttachment] = useState<HTMLButtonElement | null>(null);
     const openReviewerRejectionReasonAttachment = Boolean(anchorReviewerRejectionReasonAttachment);
     const idReviewerRejectionReasonAttachment = openReviewerRejectionReasonAttachment ? "simple-popover" : undefined;
 
     const handleOpenReviewerRejectionReasonAttachment = (event: React.MouseEvent<HTMLButtonElement>) => {
         setAnchorReviewerRejectionReasonAttachment(event.currentTarget);
     };
 
     const handleCloseReviewerRejectionReasonAttachment = () => {
         setAnchorReviewerRejectionReasonAttachment(null);
     };

    // popovers for Employee Rejection Reason Attachments
    const [anchorEmployeeRejectionAttachment, setAnchorEmployeeRejectionAttachment] = React.useState<HTMLButtonElement | null>(null);
    const openEmployeeRejectionAttachment = Boolean(anchorEmployeeRejectionAttachment);

    const handleClickOpenEmployeeRejectionAttachment = (
        event: React.MouseEvent<HTMLButtonElement>,
        j: any
    ) => {
        setemployeeAttachments(
            employeeData &&
            employeeData?.data?.employee?.attachments
                .filter((i: any) => i?.objective_description === j.name._id)
                .map((k: any) => {
                    return (
                        <div>
                            <a href={k.url}> {k.name} </a>
                            <br />
                        </div>
                    );
                })
        );
        setAnchorEmployeeRejectionAttachment(event.currentTarget);
    };

    const handleCloseEmployeeRejectionAttachment = () => {
        setAnchorEmployeeRejectionAttachment(null);
    };

    // popovers for Normalizer Reason Attachments
    const [anchorNormalizerReasonAttachment, setAnchorNormalizerReasonAttachment] = React.useState<HTMLButtonElement | null>(null);
    const openNormalizerReasonAttachment = Boolean(anchorNormalizerReasonAttachment);
    const idNormalizerReasonAttachment = openNormalizerReasonAttachment ? "simple-popover" : undefined;

    const handleOpenNormalizerReasonAttachment = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorNormalizerReasonAttachment(event.currentTarget);
    };

    const handleCloseNormalizerReasonAttachment = () => {
        setAnchorNormalizerReasonAttachment(null);
    };

    // Functions
    const getAppraiserCommentsAttachments = (id: any) => {
        return employeeData?.data?.appraisal?.attachments
            .filter((i: any) => i?.objective_description == id)
            .map((k: any) => {
                return {
                    resp: (
                        <div>
                            {" "}
                            <a href={k.url}> {k.name} </a> <br />
                        </div>
                    ),
                    remove: k.name,
                };
            });
    };

    const getAppraiserRejectionReasonAttachments = (id: any) => {
        return employeeData?.data?.appraisal?.rejection_attachments
            .filter((i: any) => i?.objective_description == id)
            .map((k: any) => {
                return {
                    resp: (
                        <div>
                            {" "}
                            <a href={k.url}> {k.name} </a> <br />
                        </div>
                    ),
                    remove: k.name,
                };
            });
    };
    const getReviewerRejectionReasonAttachments = (id: any) => {
        return employeeData?.data?.reviewer?.rejection_attachments
            .filter((i: any) => i?.objective_description == id)
            .map((k: any) => {
                return {
                    resp: (
                        <div>
                            {" "}
                            <a href={k.url}> {k.name} </a> <br />
                        </div>
                    ),
                    remove: k.name,
                };
            });
    };
    const getReviewerReasonAttachments = (id: any) => {
        return employeeData?.data?.reviewer?.attachments
            .filter((i: any) => i?.objective_description == id)
            .map((k: any) => {
                return {
                    resp: (
                        <div>
                            {" "}
                            <a href={k.url}> {k.name} </a> <br />
                        </div>
                    ),
                    remove: k.name,
                };
            });
    };
    const getEmployeeRejectionReasonAttachments = (id: any) => {
        return employeeData?.data?.employee?.attachments
            .filter((i: any) => i?.objective_description == id)
            .map((k: any) => {
                console.log(k, "zzzzz");
                return {
                    resp: (
                        <div>
                            {" "}
                            <a href={k.url}> {k.name} </a> <br />
                        </div>
                    ),
                    remove: k.name,
                };
            });
    };

    const getNormalizerReasonAttachments = (id: any) => {
        return employeeData?.data?.normalizer?.attachments
            .filter((i: any) => i?.objective_description == id)
            .map((k: any) => {
                return {
                    resp: (
                        <div>
                            {" "}
                            <a href={k.url}> {k.name} </a> <br />
                        </div>
                    ),
                    remove: k.name,
                };
            });
    };

    return (
        <>
            {employeeData && (
                <RatingTable
                showIfpreviousappraisal={showIfpreviousappraisal}
                    employee_id = {employee_id}
                    employeeData={employeeData}
                    objectiveDescription={objectiveDescription}
                    colorarray={colorarray}
                    disableTextAfterSubmission={disableTextAfterSubmission}
                    visiblityColumnData={visiblityColumnData}
                    popoverIndex={popoverIndex}
                    setPopoverIndex={setPopoverIndex}
                    activeObjectiveId={activeObjectiveId}
                    setActiveObjectiveId={setActiveObjectiveId}
                    employeeAttachments={employeeAttachments}
                    setemployeeAttachments={setemployeeAttachments}
                    deleteEmployeeMutation={deleteEmployeeMutation}
                    anchorObjectiveTitleInfo={anchorObjectiveTitleInfo}
                    setAnchorObjectiveTitleInfo={setAnchorObjectiveTitleInfo}
                    openObjectiveTitleInfo={openObjectiveTitleInfo}
                    idObjectiveTitleInfo={idObjectiveTitleInfo}
                    handleOpenObjectiveTitleInfo={handleOpenObjectiveTitleInfo}
                    handleCloseObjectiveTitleInfo={handleCloseObjectiveTitleInfo}
                    anchorObjectiveLevelInfo={anchorObjectiveLevelInfo}
                    setAnchorObjectiveLevelInfo={setAnchorObjectiveLevelInfo}
                    openObjectiveLevelInfo={openObjectiveLevelInfo}
                    idObjectiveLevelInfo={idObjectiveLevelInfo}
                    handleOpenObjectiveLevelInfo={handleOpenObjectiveLevelInfo}
                    handleCloseObjectiveLevelInfo={handleCloseObjectiveLevelInfo}
                    anchorPreviousRatingPopOver={anchorPreviousRatingPopOver}
                    setAnchorPreviousRatingPopOver={setAnchorPreviousRatingPopOver}
                    openPreviousRating={openPreviousRating}
                    id_Previous_Rating={id_Previous_Rating}
                    handlePreviousRatingPopOverClose={handlePreviousRatingPopOverClose}
                    handlePreviousRatingPopOverOpen={handlePreviousRatingPopOverOpen}
                    anchorAppraiserCommentsAttachments={anchorAppraiserCommentsAttachments}
                    setAnchorAppraiserCommentsAttachments={setAnchorAppraiserCommentsAttachments}
                    anchorReviewerCommentsAttachments={anchorReviewerCommentsAttachments}
                    setAnchorReviewerCommentsAttachments={setAnchorReviewerCommentsAttachments}
                    openAppraiserCommentsAttachments={openAppraiserCommentsAttachments}
                    openReviewerCommentsAttachments={openReviewerCommentsAttachments}
                    idAppraiserCommentsAttachments={idAppraiserCommentsAttachments}
                    idReviewerCommentsAttachments={idReviewerCommentsAttachments}
                    idReviewerRejectionReasonAttachment={idReviewerRejectionReasonAttachment}
                    handleOpenReviewerRejectionReasonAttachment={handleOpenReviewerRejectionReasonAttachment}
                    handleCloseReviewerRejectionReasonAttachment={handleCloseReviewerRejectionReasonAttachment}
                    openReviewerRejectionReasonAttachment={openReviewerRejectionReasonAttachment}
                    handleOpenAppraiserCommentsAttachments={handleOpenAppraiserCommentsAttachments}
                    handleCloseReviewerCommentsAttachments={handleCloseReviewerCommentsAttachments}
                    handleOpenReviewerCommentsAttachments={handleOpenReviewerCommentsAttachments}
                    handleCloseAppraiserCommentsAttachments={handleCloseAppraiserCommentsAttachments}
                    anchorRejectionReasonAttachment={anchorRejectionReasonAttachment}
                    anchorReviewerRejectionReasonAttachment={anchorReviewerRejectionReasonAttachment}
                    setAnchorRejectionReasonAttachment={setAnchorRejectionReasonAttachment}
                    setAnchorReviewerRejectionReasonAttachment={setAnchorReviewerRejectionReasonAttachment}
                    openRejectionReasonAttachment={openRejectionReasonAttachment}
                    idRejectionReasonAttachment={idRejectionReasonAttachment}
                    handleOpenRejectionReasonAttachment={handleOpenRejectionReasonAttachment}
                    handleCloseRejectionReasonAttachment={handleCloseRejectionReasonAttachment}
                    anchorEmployeeRejectionAttachment={anchorEmployeeRejectionAttachment}
                    setAnchorEmployeeRejectionAttachment={setAnchorEmployeeRejectionAttachment}
                    openEmployeeRejectionAttachment={openEmployeeRejectionAttachment}
                    handleClickOpenEmployeeRejectionAttachment={handleClickOpenEmployeeRejectionAttachment}
                    handleCloseEmployeeRejectionAttachment={handleCloseEmployeeRejectionAttachment}
                    anchorNormalizerReasonAttachment={anchorNormalizerReasonAttachment}
                    setAnchorNormalizerReasonAttachment={setAnchorNormalizerReasonAttachment}
                    openNormalizerReasonAttachment={openNormalizerReasonAttachment}
                    idNormalizerReasonAttachment={idNormalizerReasonAttachment}
                    handleOpenNormalizerReasonAttachment={handleOpenNormalizerReasonAttachment}
                    handleCloseNormalizerReasonAttachment={handleCloseNormalizerReasonAttachment}
                    getAppraiserCommentsAttachments={getAppraiserCommentsAttachments}
                    getAppraiserRejectionReasonAttachments={getAppraiserRejectionReasonAttachments}
                    getEmployeeRejectionReasonAttachments={getEmployeeRejectionReasonAttachments}
                    getNormalizerReasonAttachments={getNormalizerReasonAttachments}
                    getReviewerReasonAttachments={getReviewerReasonAttachments}
                    getReviewerRejectionReasonAttachments={getReviewerRejectionReasonAttachments}

                />
            )}

        </>
    )
}

export default RatingsTab