import React, { useEffect, useState, useContext, useCallback } from "react";
import Stack from "@mui/material/Stack";
import { Alert, CircularProgress, Container, makeStyles, TextField } from "@mui/material";
import { Button, TableContainer } from "@mui/material";
import { Grid } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EmployeeUploadlisttable from "./EmployeeUploadListtable";
import PAMaster from "../../components/UI/PAMaster";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import * as XLSX from "xlsx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Scrollbar } from "react-scrollbars-custom";
import Newexcel from "../employee/icons/Newexcel.svg";
import { useUpdateEmployeeinBulkMutation } from "../../service/employee/bulkUpload";
import AlertDialogSuccess from "../UI/DialogSuccess";
import { ADD_EMPLOYEE } from "../../constants/routes/Routing";
import { useLocation } from "react-router-dom";
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { useGetActiveCalenderQuery, useGetTemplateQuery, useGetEmployeeByFilterQuery, useUpdateEmployeeAppraisalMutation } from "../../service";
import dayjs from 'dayjs'
import DisplayingErrors from "./DisplayingErrors"
import AlertYesNo from "../UI/DialogYesNo";
import { parse, isValid, format } from 'date-fns';
const Text = styled("div")({
  fontSize: "14px",
  color: "#333333",
  paddingBottom: "6px",
  // opacity: 0.84,
  // marginLeft: "5px",
});
const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
});
const Headings = styled("div")({
  fontSize: "12px",
  color: "#717171",

  fontFamily: "Arial",
  // marginLeft: "23px",
});
const Labels = styled("div")({
  fontSize: "12px",
  // opacity: 0.64,
  color: "#717171",
  fontFamily: "Arial",
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Item(props: BoxProps) {
  const { sx, ...other } = props;

  return (
    <Box
      sx={{
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "#fff",
        color: (theme) => (theme.palette.mode === "dark" ? "black" : "black"),

        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        borderRadius: 1,
        fontSize: "1.1rem",
        fontWeight: "100",
        // height: "80px",
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
      {...other}
    />
  );
}
const steps = ["Export Template", "Excel File"];

export function useBlocker(blocker: any, when = true) {
  const { navigator } = useContext(NavigationContext);
  // @ts-ignore
  //const navigator = React.useContext(UNSAFE_NavigationContext)
  interface navigator {
    block: {

      any: any
    }
  }
  React.useEffect(() => {
    if (!when) return;
    // @ts-ignore
    const unblock = navigator.block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {

          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [navigator, blocker, when]);
}

export function usePrompt(message: any, when = true) {
  const blocker = useCallback(
    (tx) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) tx.retry();
    },
    [message]
  );

  useBlocker(blocker, when);
}

export default function EmployeeUpload() {
  const [value, setValue] = React.useState(0);
  const [employeeName, setEmployeeName] = useState("");
  const { data, isLoading } = useGetTemplateQuery("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [division, setDivision] = useState("");
  const [grade, setGrade] = useState("");
  const [excelldata, setexcelldata] = useState<any>([]);
  const [Excel, setExcel] = useState<any>([]);
  const [Excelfile, setExcelfile] = useState<any>([]);
  const [Exceldata, setExceldata] = useState<any>([]);
  const [ExcelError, setExcelError] = useState<any>([]);
  const [trigger, setTrigger] = useState(false);
  const [download, setDownload] = useState<any>([]);
  const [added, setAdded] = useState<any>([]);
  const [addEmployees, { error: uploadError, isLoading: UploadingInProgress }] = useUpdateEmployeeinBulkMutation();
  const { data: activeCalendarData } = useGetActiveCalenderQuery('');
  const [addSingleData, setAddSingleData] = useState<any>({})
  const [dateofjoining, setDateofjoining] = useState("");
  // openAlertmsg
  const [openAlertmsg, setopenAlertmsg] = useState(false);
  const [openAlertForPeformance, setopenAlertForPeformance] = useState(false);

  // openAlertForPeformance
  const [message1, setMessage1] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [navPrompt, setnavPrompt] = React.useState(false);
  const formIsDirty = navPrompt;
  const [template, setTemplate] = useState<any>([]);

  usePrompt(
    // 'Please save the changes before you leave the page.',
    "Any changes you have made will not be saved if you leave the page.",
    formIsDirty);
  console.log(Exceldata, "Exceldata")
  console.log(Excelfile, "Excelfile")
  const location = useLocation();
  const SELECT_FOR_DASHBOARD = `employee_code,legal_full_name,position_long_description,
  grade,service_reference_date,isSupervisor,grade_set,first_name,position_code,division,function,
  launchcalendarvalidations.confirmEmployeeMaster,confirmTemplate,appraiser_code,reviewer_code,normalizer_code,
  section,sub section,sub_section,manager_code,overall_rating,manager_name,manager_position,work_location,job_code,job_title,job_level,probation_status,
  appraiser_name,master_appraiser_code,master_appraiser_name,normalizer_name,reviewer_name,isCEORole,isLeavers,isExcluded,isGradeException,isRoleException,employee_upload_flag,isSelected,roles,email`
  const { data: employeedata } = useGetEmployeeByFilterQuery(
    `?limit=700&select=${SELECT_FOR_DASHBOARD}&employee_upload_flag=true`
  );
  console.log(employeedata, "employeedata")
  const { state }: { state: any } = useLocation();
  console.log(location, "select")
  const CustomScrollbar = Scrollbar as any;

  const [columnHeaders, setcolumnHeaders] = React.useState<any>({
    Ecode: true,
    Ename: true,
    Firstname: true,
    Eposition: true,
    EGrade: true,
    Ependingaction: true,
    Apprating: true,
    Revrating: true,
    Normrating: true,
    Status: true,
    ViewPA: true,
    FirstName: true,
    SupervisoryRole: true,
    Function: true,
    ServiceReferenceDate: true,
    TemplateName: true,
    PositionCode: true,
    division: true,
    Section: true,
    SubSection: true,
    ManagerCode: true,
    ManagerName: true,
    ManagerPosition: true,
    WorkLocation: true,
    GradeSet: true,
    JobCode: true,
    JobTitle: true,
    JobLevel: true,
    AppraiserName: true,
    Reviewername: true,
    Normalizername: true,
    Potentiallevel: true,
    TalentCategory: true,
    OverAllRating: true,
    PreviousRating: true,
    AppraiserCode: true,
    ReviewerCode: true,
    NormalizerCode: true,
    ProbationStatus: true,
    Emailid: true,
    ceoRole: true,
    previousRating: true,
    isExlcuded: true,
    isCLevel: true,
    isGradeException: true,
    JoiningDate: true,
    Department: true,
    Tenure: true,
    DepartmentHead: true,
  })
  //states for error component
  const [hideErrors, setHideErrors] = useState<any>(false)
  const [errorsList, setErrorsList] = useState<any>([])
  //@ts-ignore
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setTemplate(data?.templates
      ?.filter((item: any) => {
        return item?.weightage != "" && item?.status_template == "Completed" && item?.template_active_status == true;
      }));
  }, [data, isLoading]);
  console.log(template, "template")
  const handleAddData = () => {
    let tempSingleData = [{
      employeeName: employeeName,
      email: email,
      department: department,
      division: division,
      grade: grade,
      date_of_joining: dateofjoining
    }]

    let data = tempSingleData && tempSingleData
    console.log(employeeName, email, department, division, grade, dateofjoining, data, 'dataaaaaaaaaaaaaaaaaa')

    if (data) {
      addEmployees({ data: data }).then((res: any) => {
        console.log(res, 'ressssss')
      })
    }




    // tempSingleData = 
  }
  const activeCalendar = activeCalendarData?.data?.filter((i: any) => {
    return i?.isActive === true
  })

  // console.log(Excel, "Excel")
  const handleExport = () => {
    setTrigger(true);
    setDownload(true);
    const sheet1 = XLSX.utils.json_to_sheet(Excel, { header: Object.keys(Excel[0]) });
    const columnHeaders2: Record<string, boolean> = {
      "Template Name": true,
      "Description": true,
      // Add other headers as needed for the second sheet
    };
    const sheet2Data = template.map((item: any) => {
      let exportData: Record<string, any> = {};

      if (columnHeaders2["Template Name" as keyof typeof columnHeaders2]) {
        exportData["Template Name"] = item?.name; // Default value
      }
      if (columnHeaders2["Description" as keyof typeof columnHeaders2]) {
        exportData["Description"] = item?.description; // Default value
      }

      // Add other data fields for the second sheet as needed

      return exportData;
    });
    const sheet2 = XLSX.utils.json_to_sheet(sheet2Data, { header: Object.keys(sheet2Data[0]) });
    // var wb = XLSX.utils.book_new(),
    // Adjust column widths for the second sheet
    const columnWidths2 = Object.keys(sheet2Data[0]).reduce((acc: Record<string, any>, key) => {
      const maxColumnWidth = Math.max(
        ...sheet2Data.map((item: any) => String(item[key] || '').length),
        key.length
      );
      acc[key] = { wch: maxColumnWidth + 2 }; // Add some padding
      return acc;
    }, {});

    sheet2['!cols'] = Object.values(columnWidths2);
    const wb = XLSX.utils.book_new();
    //   ws = XLSX.utils.json_to_sheet(Excel);

    // XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    // XLSX.writeFile(wb, "Employee Upload Template.xlsx");
    XLSX.utils.book_append_sheet(wb, sheet1, 'Sheet1'); // First sheet with existing data
    if (activeCalendar?.length > 0) {
      XLSX.utils.book_append_sheet(wb, sheet2, 'Sheet2'); // Second sheet with new data
    }

    XLSX.writeFile(wb, 'Employee_Upload_Template.xlsx');
  }

  const fileType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
  const handleFile = (e: any) => {

    let selectedFile = e.target.files[0]
    if (selectedFile) {
      console.log(selectedFile.type)
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelError(null)
          setExcelfile(e.target?.result)
        }
      }
      console.log(selectedFile.type)
    } else {
      console.log("plz upload")
      setExcelError("please select only file types")
      setExcelfile(null);
    }

  }
  useEffect(() => {
    const a = [1]
    const New = a.map((j: any) => {
      let exportData: any = {}
      // if (columnHeaders["TemplateName"] == true) exportData["TemplateName"] = {
      //   t: 'd', // Data Type: Dropdown
      //   v: ['Template1', 'Template2', 'Template3'], // Your dropdown options
      //   o: {
      //     dv: 'Template1', // Default value
      //     showDropDown: true, // Show dropdown in the Excel sheet
      //     autoSort: true, // Auto-sort dropdown options
      //   },
      // }
      if (columnHeaders["Ecode"] == true) exportData["Ecode"] = ""
      if (columnHeaders["Ename"] == true) exportData["Employee Name"] = ""
      if (columnHeaders["Firstname"] == true) exportData["Known As"] = ""
      if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date"] = ""
      if (columnHeaders["Eposition"] == true) exportData["Position"] = ""
      if (columnHeaders["EGrade"] == true) exportData["Grade"] = ""
      if (columnHeaders["Function"] == true) exportData["Function"] = ""
      if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = ""
      if (columnHeaders["ProbationStatus"] == true) exportData["Probation Status"] = ""
      if (columnHeaders["Emailid"] == true) exportData["Email Id"] = ""
      if (columnHeaders["division"] == true) exportData["Division"] = ""
      if (columnHeaders["Section"] == true) exportData["Section"] = ""
      if (columnHeaders["SubSection"] == true) exportData["Sub-section"] = ""
      if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = ""
      if (columnHeaders["AppraiserCode"] == true) exportData["Appraiser Code"] = ""
      if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = ""
      if (columnHeaders["ReviewerCode"] == true) exportData["Reviewer Code"] = ""
      if (columnHeaders["Reviewername"] == true) exportData["Reviewer Name"] = ""
      if (columnHeaders["NormalizerCode"] == true) exportData["HR Normalizer Code"] = ""
      if (columnHeaders["Normalizername"] == true) exportData["HR Normalizer Name"] = ""
      if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = ""
      if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = ""
      if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = ""
      if (columnHeaders["ceoRole"] == true) exportData["C-Level"] = ""
      if (columnHeaders["previousRating"] == true) exportData["Previous Rating"] = ""
      // if (columnHeaders["TemplateName"] == true) exportData["Template Name"] = ""
      if (columnHeaders["isExlcuded"] == true) exportData["Exclusion"] = ""
      // if (columnHeaders["isCLevel"] == true) exportData["isCLevel"] = ""
      if (columnHeaders["isGradeException"] == true) exportData["Grade Exception"] = ""
      if (columnHeaders["JoiningDate"] == true) exportData["Joining Date"] = ""
      if (columnHeaders["Department"] == true) exportData["Department"] = ""
      if (columnHeaders["Tenure"] == true) exportData["Tenure"] = ""
      if (columnHeaders["DepartmentHead"] == true) exportData["Department Head"] = ""

      if (activeCalendar?.length > 0) exportData["Template Name"] = ""
      // TemplateName
      // Emailid

      return exportData
      //  });
    })
    setExcel(New)
  }, [trigger])
  // submit
  // const handleSubmit =(e: React.FormEvent<HTMLInputElement>) =>{
  //   e.preventDefault();
  //   // if(Excelfile !== null){
  //     const workbook = XLSX.read(Excelfile,{type:"buffer"});
  //     const worksheetName =workbook.SheetNames[0];
  //     const worksheet =workbook.Sheets[worksheetName]
  //     const data= XLSX.utils.sheet_to_json(worksheet)
  //     console.log(data,"data")
  //     setExceldata(data)
  //   // }else{
  //   //   setExceldata(null)
  //   // }
  // }

  // useEffect(()=>{
  const stepper = () => {
    if (download == true && added == false) {
      return 1
    } else if (download == false && added == true) {
      return 1
    } else if (download == true && added == true)
      return 2
    else return 0
  }


  // const useStyles :any[] = makeStyles(() => ({root: { "& .MuiStepIcon-active": { color: "red" }, "& .MuiStepIcon-completed": { color: "green" }, 

  //  "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" } } }));
  // const c = useStyles();


  // },[added,download])
  const readUploadFile = (e: any) => {
    setnavPrompt(true)
    let selectedFile = e.target.files![0]
    e.preventDefault();
    const ExistingEmployeeData = employeedata?.data?.map((employeeList: any) => {
      return employeeList
    })

    if (selectedFile && fileType.includes(selectedFile.type)) {
      // if (e.target.files![0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        // this condition is added if user swaps sheets in excel after it is downloaded.
        if (workbook.SheetNames.includes("Sheet1")) { // Check if "Sheet1" exists in the array
          const sheetName: any = "Sheet1"; // Assign "Sheet1" to sheetName
          // const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, { raw: false, dateNF: 'mm/dd/yyyy' });
          setExceldata(json)
          setexcelldata(json)
          const Exceeeldata = json
          const existingEmployeeCodesSet = ExistingEmployeeData?.map((item: any) => item.employee_code);
          const existingEmployeesData = Exceeeldata.filter((item: any) => existingEmployeeCodesSet.includes(item.Ecode));
          if (existingEmployeesData?.length > 0) {
            setOpenAlert(true);
            setMessage("Please note that only new employees can be added, and already mapped employees cannot be added again it can be removed while saving")
          }
        } else {
          setOpenAlert(true);
          setMessage("The uploaded Excel template has been modified and cannot be validated. Please upload the original template."); // Log an error if "Sheet1" does not exist
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      setAdded(true);
    } else {
      setExcelError("please select only file types")
      setExceldata("")
    }
  };

  const handleOpenFile = () => {
    // if (activeCalendar?.length > 0) {
    //   setOpenAlert(true);
    //   setMessage("Calendar is in live.")
    // } else {
    document.getElementById("upload")?.click();
    // }
  }

  const handleupload = () => {
    let hasError = false;
    let emailError = false;
    let templateError = false;
    let infoalert = true;
    const ExistingEmployeeData = employeedata?.data?.map((j: any) => {
      return j
    })




    function parseDateString(dateString: any): string | null {
      if (typeof dateString !== 'string') return null;

      const formats = [
        'dd/MM/yyyy', 'MM/dd/yyyy', 'yyyy-MM-dd', 'dd-MM-yyyy', 'MM-dd-yyyy', 'dd/MM/yy', 'dd-MM-yy', 'dd-MMM-yyyy'
      ];

      for (const formatStr of formats) {
        const parsedDate = parse(dateString, formatStr, new Date());
        if (isValid(parsedDate)) {
          return format(parsedDate, formatStr);
        }
      }

      return null;
    }


    if (activeCalendar?.length > 0) {

      if (Exceldata.length > 0) {
        let data: any = Exceldata?.map((item: any) => {
          const probationStatus = item["Probation Status"]
          const TemplateName = item["Template Name"]
          console.log(TemplateName, probationStatus, "probationStatus")

          const templateItem = template.map((templateItem: { name: string }) => templateItem?.name);
          console.log(item, 'itemmmmm')
          return {
            employee_code: item?.Ecode,
            legal_full_name: item["Employee Name"],
            first_name: item["Known As"],
            // service_reference_date: new Date(item["Service Reference Date"]),
            service_reference_date: parseDateString(item["Service Reference Date"]),
            position_long_description: item?.Position,
            grade: item?.Grade,
            function: item?.Function,
            isSupervisor: item?.["Supervisory Role"]?.toLowerCase() === "sp" ? true : false,
            probation_status: item["Probation Status"],
            email: item["Email Id"],
            division: item?.Division,
            section: item?.Section,
            sub_section: item["Sub-section"],
            work_location: item["Work Location"],
            // service_reference_date: parsedDate.toDate(),
            appraiser_code: item["Appraiser Code"],
            appraiser_name: item["Appraiser Name"],
            reviewer_code: item["Reviewer Code"],
            reviewer_name: item["Reviewer Name"],
            normalizer_code: item["HR Normalizer Code"],
            normalizer_name: item["HR Normalizer Name"],
            manager_code: item["Manager Code"],
            manager_name: item["Manager Name"],
            manager_position: item["Manager Position"],
            isCEORole: item["C-Level"]?.toLowerCase() === "yes" ? true : false,
            previous_rating: item["Previous Rating"],
            live_employee_upload_flag: true,
            // 'Template_Name': templateItem["Template Name"] , 
            isExcluded: item["Exclusion"]?.toLowerCase() === "yes" ? true : false,
            isGradeException: item["Grade Exception"]?.toLowerCase() === "yes" ? true : false,
            // JoiningDate: item["Joining Date"],
            JoiningDate: parseDateString(item["Joining Date"]),
            Department: item["Department"],
            Tenure: item["Tenure"],
            DepartmentHead: item["Department Head"],
            TemplateName: item["Template Name"],
            employee_upload_flag: true,

          }
        })

        let Uploaddatatemplate = data?.map((j: any) => {
          return j["TemplateName"]
        })
        let Uploaddataemployeecode = data?.map((j: any) => {
          return j["employee_code"]
        })
        let UploaddataProbation = data?.map((j: any) => {
          return j["probation_status"]
        })
        let Uploaddataemail = data?.map((j: any) => {
          return j["email"]
        })
        const invalidEmails = Uploaddataemail.filter((email: any) => {
          return email && !email.endsWith("@taqeef.com")
        })
        const existingEmployeeCodesSet = new Set(ExistingEmployeeData?.map((item: any) => item.employee_code));
        const existingEmployeesData = data?.filter((item: any) => existingEmployeeCodesSet.has(item.employee_code));
        console.log(existingEmployeesData?.length, "length")
        // Filter data based on employee_code not present in existingEmployeeCodesSet
        const filteredData = data?.filter((item: any) => !existingEmployeeCodesSet.has(item.employee_code));

        console.log(filteredData, Uploaddataemail, invalidEmails?.length, "filteredData");
        console.log(ExistingEmployeeData, Uploaddataemployeecode, Uploaddatatemplate, "Uploaddatatemplate")
        // templatevalidation
        const templatevalidation = new Set(template?.map((item: any) => item?.name))
        const existingtemplate = Uploaddatatemplate?.filter((j: any) => !templatevalidation.has(j))
        console.log(existingtemplate, "kkkkkkkkkkk")
        if (Uploaddatatemplate.some((template: any) => template === undefined)) {
          templateError = true;
        }
        else if (UploaddataProbation.some((probation: any) => probation !== "In-Probation" && probation !== "Confirmed")) {
          hasError = true;
        }
        if (invalidEmails.length > 0) {
          emailError = true;
          // const message = "Invalid Email Domain: Some email addresses in the list are not part of the company domain. Would you like to proceed with the upload? Yes/No"
          // alert(message)
          // return message
        }
        // else if(Uploaddataemail)
        else {
          hasError = false;
          console.log("error")
        }
        console.log(data, "exceldata")
        if (templateError) {
          setOpenAlert(true);
          setMessage("The Template name should be mandatory")
        } else if (existingtemplate?.length > 0) {
          setOpenAlert(true);
          setMessage(`Cannot find template ${existingtemplate}.`)
        }
        //  else if(existingEmployeesData?.length > 0){
        //   setOpenAlert(true);
        //   setMessage("Please note that only new employees can be added, and already mapped employees cannot be added again.")
        // }
        else if (hasError) {
          // Display error messages
          setOpenAlert(true);
          setMessage("The probation status can only be either 'In-Probation' or 'Confirmed'.");
        }
        // else if (emailError) {
        //   setopenAlertForPeformance(true)
        //   setMessage("Invalid Email Domain: Some email addresses in the list are not part of the company domain. Would you like to proceed with the upload?")
        // }
        else {
          console.log(filteredData, "filteredDataaa")

          addEmployees({ data: filteredData }).then((res: any) => {
            if (res.error) {
              setHideErrors(true);
              setErrorsList(res.error);
            } else {
              console.log(res, 'resssssssssssssss');
              setOpenAlert(true)
              // message change as per 5/3/2023
              setMessage("Employees were saved.")
              setnavPrompt(false)
            }
          })
          setOpenAlert(false)
          setMessage("")
        }
      } else {
        setOpenAlert(true);
        setShowAlert(false);
        setMessage("Please choose a file to upload.")
      }
    } else {
      if (Exceldata.length > 0) {
        let data: any = Exceldata.map((item: any) => {
          const probationStatus = item["Probation Status"]
          const TemplateName = item["TemplateName"]
          console.log(TemplateName, probationStatus, "probationStatus")

          const templateItem = template.map((templateItem: { name: string }) => templateItem?.name);
          // console.log(item, 'itemmmmm')

          return {
            employee_code: item?.Ecode,
            legal_full_name: item["Employee Name"],
            first_name: item["Known As"],
            // service_reference_date: new Date(item["Service Reference Date"]),
            service_reference_date: parseDateString(item["Service Reference Date"]),
            position_long_description: item?.Position,
            grade: item?.Grade,
            function: item?.Function,
            isSupervisor: item?.["Supervisory Role"]?.toLowerCase() === "sp" ? true : false,
            probation_status: item["Probation Status"],
            email: item["Email Id"],
            division: item?.Division,
            section: item?.Section,
            sub_section: item["Sub-section"],
            work_location: item["Work Location"],
            // service_reference_date: parsedDate.toDate(),
            appraiser_code: item["Appraiser Code"],
            appraiser_name: item["Appraiser Name"],
            reviewer_code: item["Reviewer Code"],
            reviewer_name: item["Reviewer Name"],
            normalizer_code: item["HR Normalizer Code"],
            normalizer_name: item["HR Normalizer Name"],
            manager_code: item["Manager Code"],
            manager_name: item["Manager Name"],
            manager_position: item["Manager Position"],
            isCEORole: item["C-Level"]?.toLowerCase() === "yes" ? true : false,
            previous_rating: item["Previous Rating"],
            employee_upload_flag: true,
            // 'Template_Name': templateItem["Template Name"] , 
            isExcluded: item["Exclusion"]?.toLowerCase() === "yes" ? true : false,
            isGradeException: item["Grade Exception"]?.toLowerCase() === "yes" ? true : false,
            // JoiningDate: new Date(item["Joining Date"]),
            JoiningDate: parseDateString(item["Joining Date"]),
            Department: item["Department"],
            Tenure: item["Tenure"],
            DepartmentHead: item["Department Head"],
            // TemplateName:item["TemplateName"]

          }
        })
        // let Uploaddatatemplate=data?.map((j:any)=>{
        //   return j["TemplateName"]
        // })
        console.log(data?.map((item: any) => {
          return {
            code: item.employee_code,
            date: (item.service_reference_date)
          }
        }
        ), 'checkreferrencedate')
        let Uploaddataemployeecode = data?.map((j: any) => {
          return j["employee_code"]
        })
        let UploaddataProbation = data?.map((j: any) => {
          return j["probation_status"]
        })
        let Uploaddataemail = data?.map((j: any) => {
          return j["email"]
        })
        const invalidEmails = Uploaddataemail.filter((email: any) => {
          return email && !email.endsWith("@taqeef.com")
        })
        console.log(ExistingEmployeeData, invalidEmails, Uploaddataemployeecode, "Uploaddatatemplate")
        //   if (Uploaddatatemplate.some((template:any) => template === undefined )) {
        //     templateError = true;
        //   }
        //  else
        if (UploaddataProbation.some((probation: any) => probation !== "In-Probation" && probation !== "Confirmed")) {
          hasError = true;
        }
        else if (invalidEmails.length > 0) {
          emailError = true;
        }
        else {
          hasError = false;
          emailError = false
          console.log("error")
        }
        console.log(data, "exceldata")
        if (hasError) {
          // Display error messages
          setOpenAlert(true);
          setMessage("The probation status can only be either 'In-Probation' or 'Confirmed'.");
        }
        // else if (emailError) {
        //   setopenAlertForPeformance(true)
        //   setMessage("Invalid Email Domain: Some email addresses in the list are not part of the company domain. Would you like to proceed with the upload?")
        // }
        else {
          addEmployees({ data: data }).then((res: any) => {
            if (res.error) {
              setHideErrors(true);
              setErrorsList(res.error);
            } else {
              console.log(res, 'resssssssssssssss');
              setOpenAlert(true)
              // message change as per 5/3/2023
              setMessage("Employees were saved.")
              setnavPrompt(false)
            }
          })
          setOpenAlert(false)
          setMessage("")
        }
      } else {
        setOpenAlert(true);
        setShowAlert(false);
        setMessage("Please choose a file to upload.")
      }
    }
    console.log(Exceldata, "Exceldata")
  }
  const handleopenAccpetForPeformance = () => {
    setopenAlertForPeformance(false);
    setMessage("")
  }
  const handleopenAlertForPeformance = () => {
    setOpenAlert(false)
    setMessage("")
    addEmployees({ data: data }).then((res: any) => {
      if (res.error) {
        setHideErrors(true);
        setErrorsList(res.error);
      } else {
        console.log(res, 'resssssssssssssss');
        setOpenAlert(true)
        // message change as per 5/3/2023
        setMessage("Employees were saved.")
        setnavPrompt(false)
      }
    })
    setopenAlertForPeformance(false);
    setMessage("")
  }
  //  const handleupload = () => {
  //   let hasError = false;
  //   let templateError = false;

  //   if (Exceldata.length > 0) {
  //     let data = Exceldata.map((item: any) => {
  //       const probationStatus = item["Probation_Status"]
  //       const TemplateName  = item["TemplateName"]

  //       const templateItem = template.map((templateItem: { name: string }) => templateItem?.name);

  //       return {
  //         employee_code: item?.Ecode,
  //         legal_full_name: item["Employee Name"],
  //         first_name:item["Known As"],
  //         service_reference_date: new Date(item["Service Reference Date"]),
  //         position_long_description: item?.Position,
  //         grade: item?.Grade,
  //         function: item?.Function,
  //         isSupervisor: item?.["Supervisory Role"]?.toLowerCase() === "sp" ? true : false,
  //         probation_status: item["Probation_Status"],
  //         email: item["Email Id"],
  //         division: item?.Division,
  //         section: item?.Section,
  //         sub_section: item["Sub-section"],
  //         work_location: item["Work Location"],
  //         appraiser_code: item["Appraiser Code"],
  //         appraiser_name: item["Appraiser Name"],
  //         reviewer_code: item["Reviewer Code"],
  //         reviewer_name: item["Reviewer Name"],
  //         normalizer_code: item["HR Normalizer Code"],
  //         normalizer_name: item["HR Normalizer Name"],
  //         manager_code: item["Manager Code"],
  //         manager_name: item["Manager Name"],
  //         manager_position: item["Manager Position"],
  //         isCEORole : item["CEO Role"]?.toLowerCase() === "yes" ? true : false,
  //         previous_rating : item["Previous Rating"],
  //         employee_upload_flag: true,
  //         isExcluded :item["isExcluded"],
  //         isGradeException:item["isGradeException"],
  //         TemplateName:item["TemplateName"]
  //       }
  //     })
  //     let Uploaddatatemplate=data?.map((j:any)=>{
  //       return j?.TemplateName
  //     })
  //     let UploaddataProbation=data?.map((j:any)=>{
  //       return j["probation_status"]
  //     })
  //     console.log(Uploaddatatemplate,UploaddataProbation,"Uploaddatatemplate")
  //     if (UploaddataProbation !== "In-Probation" || UploaddataProbation !== "Confirmed") {
  //       hasError = true;
  //     }
  //     else  if (!Uploaddatatemplate || Uploaddatatemplate.trim() === "") {
  //       templateError = true;
  //     }
  //     else{
  //       hasError = false;
  //       console.log("error")
  //     }
  //     console.log(data, "exceldata")
  //     if (hasError) {
  //       setOpenAlert(true);
  //       setMessage("The probation status can only be either 'In-Probation' or 'Confirmed'.");
  //     } 
  //     else if (templateError){
  //       setOpenAlert(true);
  //       setMessage("The Template name should be mandatory")
  //     }
  //     else{
  //       // addEmployees({ data: data }).then((res: any) => {
  //       //   if (res.error) {
  //       //     setHideErrors(true);
  //       //     setErrorsList(res.error);
  //       //   } else {
  //       //     console.log(res, 'resssssssssssssss');
  //       //     setOpenAlert(true)
  //       //     // message change as per 5/3/2023
  //       //     setMessage("Employees were saved.")
  //       //     setnavPrompt(false)
  //       //   }
  //       // })
  //       setOpenAlert(false)
  //       setMessage("")
  //     }
  //   } else {
  //     setOpenAlert(true);
  //     setShowAlert(false);
  //     setMessage("Please choose a file to upload.")
  //   }
  //   console.log(Exceldata, "Exceldata")
  // }
  const handleCancel = () => {
    const fileInput = document.getElementById('upload') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
      setExceldata("");
    }
    setnavPrompt(false)
    setHideErrors(false);
    setErrorsList("");
  }

  const handleAlertClose = () => {
    setOpenAlert(false);
    setShowAlert(false);
    setMessage("");
  }

  const handleAlertClosemsg = () => {
    setopenAlertmsg(false)
    setMessage("");

  }

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <PAMaster
        name={"Add Employees"}
        nav={`${ADD_EMPLOYEE}`}
        secondName={state?.nav + " Master"} />
      <Box
        sx={{
          // maxWidth: "96% !important",
          // width: "100%",
          // height: 800,
          backgroundColor: "#fff",
          marginLeft: "25px",
          marginRight: "25px",
          padding: "20px",
          height: "calc(100vh - 180px)",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {/* <Tab
                style={{
                  textTransform: "capitalize",
                  color: "#3E8CB5",
                  fontSize: "14px",
                  fontFamily: "Arial",
                  fontWeight: "600",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
                label="Single Upload"
                {...a11yProps(0)}
              /> */}
              <Tab
                style={{
                  textTransform: "capitalize",
                  color: "#3E8CB5",
                  fontSize: "14px",
                  fontFamily: "Arial",
                  fontWeight: "600",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
                label="Multiple Upload"
                {...a11yProps(0)}
              />
            </Tabs>


          </Box>
        </Box>
        {/* <TabPanel value={value} index={0}>
          <div>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gridTemplateRows: "repeat(2, 1fr)",
                columnGap: "40px",
                rowGap: "10px",
                marginTop: "10px",
              }}
            >
              <Item>
                <TextField
                  style={{ width: "100%" }}
                  label="Employee Name"
                  id="standard-size-normal"
                  variant="standard"
                  value={employeeName}
                  onChange={(e) => setEmployeeName(e.target.value)}
                />
              </Item>

              <Item>
                <TextField
                  style={{ width: "100%" }}
                  label="Email"
                  id="standard-size-normal"
                  variant="standard"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Item>

              <Item>
                <TextField
                  style={{ width: "100%" }}
                  label="Department"
                  id="standard-size-normal"
                  variant="standard"
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                />
              </Item>

              <Item>
                <TextField
                  style={{ width: "100%" }}
                  label="Division"
                  id="standard-size-normal"
                  variant="standard"
                  value={division}
                  onChange={(e) => setDivision(e.target.value)}
                />
              </Item>

              <Item>
                <TextField
                  style={{ width: "100%" }}
                  label="Grade"
                  id="standard-size-normal"
                  variant="standard"
                  value={grade}
                  onChange={(e) => setGrade(e.target.value)}
                />
              </Item>

              <Item>
                <TextField
                  style={{ width: "100%" }}
                  label="Date of joining"
                  id="standard-size-normal"
                  variant="standard"
                  value={dateofjoining}
                  onChange={(e) => setDateofjoining(e.target.value)}
                />
              </Item>
            </Box>
          </div>
          <Stack direction="row" spacing={2} marginTop="20px">
            <Button
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: "Arial",
                borderColor: "#3E8CB5",
                color: "#3E8CB5",
                background: "transparent",
                height: "35px",
                width: "70px",
              }}
              variant="outlined"
              // onClick = {handleAddData}
            >
              Add
            </Button>
            <Button
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: "Arial",
                borderColor: "#3E8CB5",
                color: "#3E8CB5",
                background: "transparent",
                height: "35px",
                width: "70px",
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </Stack>
          <EmployeeUploadlisttable />
        </TabPanel> */}
        <TabPanel value={value} index={0}>
          <div>
            {/* <Box sx={{ width: "300px", paddingTop: "25px" }}>
              <Stepper
                sx={{
                  "& .MuiStepConnector-line": {
                    borderTopWidth: "6px",
                  },
                  "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
                    borderColor: "red",
                  },
                  "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
                    borderColor: "green",
                  },
                  ".MuiSvgIcon-root": {
                    borderRadius: "50%",
                    border: "1px solid #1976d2"
                  },
                  ".MuiSvgIcon-root:not(.Mui-completed)": {
                    color: "white"
                  },

                }}

                activeStep={stepper()} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>
                      <Labels> {label}</Labels>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box> */}
            <Stack direction="row" spacing={7} marginTop="20px" alignItems="center" paddingLeft="28px">

              <Button
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  color: "#3E8CB5",
                  background: "transparent",
                  height: "30px",
                  // width: "70px",
                }}
                variant="outlined"
                onClick={handleExport}
              >
                {/* Export File */}
                Export Template
              </Button>

              {/* <input type="file" className="form-control" id="file" 
                  onChange={handleFile}
                 /> */}
              {/* <label htmlFor="upload">Upload File</label> */}
              {/* <input
                type="file"
                accept="Upload"
                name="upload"
                id="upload"
                onChange={readUploadFile}
              /> */}


              <input
                type="file"
                name="upload"
                id="upload"
                onChange={readUploadFile}
                style={{ display: "none" }}
              />

              <Button
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  color: "#3E8CB5",
                  background: "transparent",
                  height: "30px",
                  // width: "70px",
                }}
                variant="outlined"
                onClick={handleOpenFile}
              >
                {/* Upload File */}
                Upload
              </Button>

              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontFamily: "Arial",
                    borderColor: "#3E8CB5",
                    color: "#3E8CB5",
                    background: "transparent",
                    height: "30px",
                    // width: "70px",
                  }}
                  variant="outlined"
                  onClick={handleupload}
                >
                  {/* Upload File */}
                  Save
                </Button>

                {UploadingInProgress && (
                  <CircularProgress style={{ marginTop: "4px" }} size={15} thickness={7} />
                )}
              </Stack>


              <Button
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  color: "#3E8CB5",
                  background: "transparent",
                  height: "30px",
                  // width: "70px",
                }}
                variant="outlined"
                onClick={handleCancel}
              >
                {/* Upload File */}
                Cancel
              </Button>
              {/* handleFile */}
            </Stack>


            <hr></hr>
            {Exceldata == "" && <>No file selected</>}
            {Exceldata != "" && hideErrors === false && (
              <TableContainer style={{ marginTop: "18px", height: "calc(100vh - 330px)", }} >
                <Scroll >
                  <CustomScrollbar style={{ width: "100%", height: "calc(100vh - 330px)" }}>
                    <Table size="small" aria-label="simple table" stickyHeader>
                      <TableHead
                      // style={{
                      //   color: "#004C75",
                      //   fontSize: "15px",
                      //   fontWeight: "400",
                      //   opacity: "0.9",
                      //   height: "50px"
                      // }}
                      >
                        <TableRow
                          sx={{
                            "& td, & th": {
                              whiteSpace: "nowrap",
                              bgcolor: "#eaeced",
                              // border: 1,
                              // borderColor: "#e0e0e0",
                            },
                          }}>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            Ecode
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Employee Name</TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Known As</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Position</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Grade</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Supervisory Role</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Function</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Probation<br></br> Status</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Service Reference Date</TableCell>

                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Appraiser Name</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Appraiser Code</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Reviewer Name</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Reviewer Code</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            HR Normalizer Name</TableCell>

                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            HR Normalizer Code</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Manager Code</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Manager Name</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Manager Position </TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Division</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Section</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Sub-section</TableCell>

                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Work Location</TableCell>

                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Email Id</TableCell>

                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            C-Level</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Joining Date</TableCell>
                          <TableCell align="center"
                            sx={{
                              fontFamily: "Arial",
                              color: "#3E8CB5",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                            Department</TableCell> <TableCell align="center"
                              sx={{
                                fontFamily: "Arial",
                                color: "#3E8CB5",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}>
                            Tenure</TableCell> <TableCell align="center"
                              sx={{
                                fontFamily: "Arial",
                                color: "#3E8CB5",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}>
                            Department Head</TableCell>
                          {(activeCalendar?.length > 0) &&
                            <TableCell align="center"
                              sx={{
                                fontFamily: "Arial",
                                color: "#3E8CB5",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}>
                              Template Name</TableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Exceldata?.map((j: any) => {
                          console.log(j, 'jjjjjjjjjjjjjjjjjjjjjjjjj')
                          return (
                            <>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    whiteSpace: "nowrap",

                                  },
                                }}
                              >
                                <TableCell
                                  align="center"

                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                    paddingRight: "40px"
                                  }}>
                                  {j?.Ecode}
                                </TableCell>
                                <TableCell align="left"
                                  // width={250}
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",

                                  }}>{j?.["Employee Name"]}</TableCell>
                                <TableCell
                                  align="left"

                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                    paddingRight: "40px"
                                  }}>
                                  {j?.["Known As"]}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.Position}</TableCell>
                                <TableCell align="center"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                    paddingRight: "40px"
                                  }}>{j?.Grade}</TableCell>
                                <TableCell align="center"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                    paddingRight: "40px"
                                  }} >{j?.["Supervisory Role"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.Function}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Probation Status"]}</TableCell>
                                <TableCell align="center"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Service Reference Date"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Appraiser Name"]}</TableCell>
                                <TableCell align="center"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Appraiser Code"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Reviewer Name"]}</TableCell>
                                <TableCell align="center"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Reviewer Code"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["HR Normalizer Name"]}</TableCell>
                                <TableCell align="center"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["HR Normalizer Code"]}</TableCell>
                                <TableCell align="center"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Manager Code"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Manager Name"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Manager Position"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.Division}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.Section}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j["Sub-section"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Work Location"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Email Id"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["C-Level"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Joining Date"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Department"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Tenure"]}</TableCell>
                                <TableCell align="left"
                                  sx={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                  }}>{j?.["Department Head"]}</TableCell>
                                {(activeCalendar?.length > 0) &&
                                  <TableCell align="left"
                                    sx={{
                                      fontSize: "14px",
                                      color: "#333333",
                                      fontFamily: "Arial",
                                    }}>{j?.["Template Name"]}</TableCell>
                                }
                              </TableRow>
                            </>
                          )
                        })}
                        {/* <EmployeeUploadlisttable Exceldata={Exceldata} /> */}
                      </TableBody>
                    </Table>
                  </CustomScrollbar>
                </Scroll>
              </TableContainer>
            )}

            <AlertDialogSuccess
              isAlertOpen={openAlert}
              handleAlertClose={handleAlertClose} >
              {/* @ts-ignore */}
              {showAlert && uploadError?.data?.message}
              {message}
            </AlertDialogSuccess>
            <AlertDialogSuccess
              isAlertOpen={openAlertmsg}
              handleAlertClose={handleAlertClosemsg} >
              {/* @ts-ignore */}
              {/* {showAlert && uploadError?.data?.message} */}
              {message1}
            </AlertDialogSuccess>
            <AlertYesNo
              isAlertOpen={openAlertForPeformance}
              handleAlertYes={handleopenAlertForPeformance}
              handleAlertClose={handleopenAccpetForPeformance}
            >
              {message}
            </AlertYesNo>
          </div>
          {/* File Upload failed design */}
          {/* <div>
            <Stack sx={{ width: "100%", paddingTop: "10px" }}>
              <Alert severity="warning" color="error" style={{ fontSize: "" }}>
                File upload failed
              </Alert>
            </Stack>

            <div style={{paddingTop:"10px",paddingLeft:"3px"}}>
              <Grid display="flex">
                <Text>
                  <li > The spread of computers and layout programmes thus made </li>
                  <li > The spread of computers and layout programmes thus made </li>
                  <li > The spread of computers and layout programmes thus made </li>
                  <li > The spread of computers and layout programmes thus made </li>
                  
                </Text>
              </Grid>
            </div>
            <Stack direction="row" spacing={2} marginTop="20px" justifyContent="center">
            <Button
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: "Arial",
                borderColor: "#3E8CB5",
                color: "#3E8CB5",
                background: "transparent",
                height: "35px",
                width: "70px",
              }}
              variant="outlined"
            >
              Retry
            </Button>
            <Button
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: "Arial",
                borderColor: "#3E8CB5",
                color: "#3E8CB5",
                background: "transparent",
                height: "35px",
                width: "70px",
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </Stack>
          </div> */}
          {/* All Files Uploaded  design */}

          {/* <div>
            <Stack sx={{ width: "100%", paddingTop: "10px" }}>
              <Alert severity="success" color="success" style={{ fontSize: "" }}>
                All Files uploaded
              </Alert>
            </Stack>

            <div style={{paddingTop:"10px",paddingLeft:"3px"}}>
              <Grid display="flex">
                <Text>
                  <li > The spread of computers and layout programmes thus made </li>
                  
                </Text>
              </Grid>
            </div>
            <Stack direction="row" spacing={2} marginTop="20px" justifyContent="center">
            <Button
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: "Arial",
                borderColor: "#3E8CB5",
                color: "#3E8CB5",
                background: "transparent",
                height: "35px",
                width: "70px",
              }}
              variant="outlined"
            >
              Save
            </Button>
            <Button
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: "Arial",
                borderColor: "#3E8CB5",
                color: "#3E8CB5",
                background: "transparent",
                height: "35px",
                width: "70px",
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </Stack>
          </div> */}
        </TabPanel>
        {hideErrors &&
          <DisplayingErrors
            from="employeeUpload"
            uploadError={uploadError}
            setHideErrors={setHideErrors}
            errorsList={errorsList}
            setErrorsList={setErrorsList}
          />}
      </Box>

    </>
  );
}
