import { Popover, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import AttachFileIcon from '@mui/icons-material/AttachFile';

const NormalizerAttachmentView = (props : any) => {
    const {normalizerMeetingAttachment} = props;

    // popovers
    const [anchorMeetingAttachment, setAnchorMeetingAttachment] = useState<HTMLButtonElement | null>(null);
    const openMeetingAttachment = Boolean(anchorMeetingAttachment);

    const handleClickOpenMeetingAttachment = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorMeetingAttachment(event.currentTarget);
    };

    const handleClickCloseMeetingAttachment = () => {
        setAnchorMeetingAttachment(null);
    };
    console.log(normalizerMeetingAttachment,"normalizerMeetingAttachment")
    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <Typography
                    style={{
                        fontSize: "16px",
                        color: "#717171",
                        fontFamily: "arial",
                        marginBottom: "10px",
                    }}
                >
                    <b>HR Normalizer Attachment</b>
                </Typography>

                <AttachFileIcon
                    sx={{ color: "#93DCFA", height: "18px", cursor: "pointer", transform: "rotate(30deg)", }}
                    aria-describedby={"id"}
                    onClick={(e: any) => {
                        handleClickOpenMeetingAttachment(e)
                    }}
                />
            </div>
            <Popover
                id={"id"}
                open={openMeetingAttachment}
                anchorEl={anchorMeetingAttachment}
                onClose={handleClickCloseMeetingAttachment}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        backgroundColor: "FEFCF8",
                        boxShadow: "none",
                        maxWidth: "400px",
                        borderRadius: "5px",
                    },
                }}
                sx={{
                    "& .MuiPopover-paper": {
                        border: "1px solid #3e8cb5",
                        backgroundColor: "#ffffff",
                        // width: "30%",
                    },
                }}
            >
                <div
                    style={{
                        padding: "5px",
                        fontSize: "12px",
                        lineHeight: "20px",
                        color: "#333333",
                        fontFamily: "Arial",
                    }}
                >
                    {/* {employeeData && employeeData?.data?.normalizer?.meetingNotesAttachments?.map((k: any, index1: any) => { */}
                      {normalizerMeetingAttachment && normalizerMeetingAttachment?.map((k: any, index1: any) => {
                        return (
                            <>
                                <Stack
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                >

                                    <Typography
                                        style={{
                                            fontSize: "12px",
                                            fontFamily: "Arial",
                                            color: "#333333",

                                            // maxWidth:"215px",
                                            // wordBreak:"break-all"
                                        }}
                                    >
                                        {index1 + 1}.
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontSize: "12px",
                                            fontFamily: "Arial",
                                            color: "#333333",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "170px"
                                        }}
                                    >
                                        <a href={k.url}>{k.name}</a>  <br />
                                    </Typography>
                                </Stack>
                            </>
                        )
                    })}
                </div>
            </Popover>
        </>
    )
}

export default NormalizerAttachmentView