import React from 'react'
import AppraiserOverview from '../../../components/appraisalNew/appraiser/appraiserOverview/AppraiserOverview'
import ProvidedAppraiserContextProvider from '../../../context/appraisal/AppraiserContext'

const AppraiserOverviewPage = () => {
    return (
        <ProvidedAppraiserContextProvider>
            <>
                <AppraiserOverview />
            </>
        </ProvidedAppraiserContextProvider>
    )
}

export default AppraiserOverviewPage