import { IconButton, Popover, Stack, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import Infoicon from "../../../assets/Images/Infoicon.svg";

const TrainingRecommendationView = (props: any) => {
    const {
        showTrainingRecommendation,
        trainingRecommendation,
        showIfEmployee,
        showIfAppraiser,
        showIfpreviousappraisal,
    } = props;
    const [popoverIndex, setPopoverIndex] = useState<any>("");


    // popovers

    const [anchorTrainingCategoryInfo, setAnchorTrainingCategoryInfo] = useState<HTMLButtonElement | null>(null);
    const openTrainingCategoryInfo = Boolean(anchorTrainingCategoryInfo);
    const idTrainingCategoryInfo = openTrainingCategoryInfo ? "simple-popover" : undefined;

    const handleOpenTrainingCategoryInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorTrainingCategoryInfo(event.currentTarget);
    };

    const handleCloseTrainingCategoryInfo = () => {
        setAnchorTrainingCategoryInfo(null);
    };

    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <div
                    style={{
                        color: "#717171",
                        fontSize: "16px",
                        fontFamily: "Arial",
                        marginBottom: "10px",
                    }}
                >
                    {showIfAppraiser && <b> Training Recommendations (Appraiser)</b>}
                    {showIfEmployee && <b> Training Recommendations (Employee)</b>}

                </div>
                <Table size="small">
                    <TableHead>
                        <TableRow
                            sx={{
                                "& td, & th": {
                                    border: "1px solid #e0e0e0",
                                    bgcolor: "#eaeced",
                                },
                            }}
                        >
                            <TableCell
                                align="center"
                                // width="25%"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                Training Category
                            </TableCell>
                            <TableCell
                                align="center"
                                // width="25%"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                Training Name
                            </TableCell>
                            <TableCell
                                align="center"
                                // width="25%"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                Justification
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    {trainingRecommendation?.map((item: any, index: any) => {
                        return (
                            <>
                                <TableRow
                                    sx={{
                                        "& td, & th": {
                                            border: "1px solid #e0e0e0",
                                        },
                                    }}
                                >
                                    <TableCell
                                        width="200px"
                                        align="left"
                                        style={{
                                            fontSize: "14px",
                                            color: "#333333",
                                            fontFamily: "Arial",
                                            wordBreak: "break-word"
                                        }}
                                    >
                                        <IconButton
                                            onClick={(e: any) => {
                                                handleOpenTrainingCategoryInfo(e)
                                                setPopoverIndex(index);
                                            }}>
                                            <img width="12px" src={Infoicon} alt="icon" />
                                        </IconButton>
                                        {showIfpreviousappraisal ? item?.title : item?.name?.title}
                                        <Popover
                                            id={idTrainingCategoryInfo}
                                            open={(popoverIndex === index) && openTrainingCategoryInfo}
                                            anchorEl={anchorTrainingCategoryInfo}
                                            onClose={handleCloseTrainingCategoryInfo}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                            }}
                                            PaperProps={{
                                                style: {
                                                    backgroundColor: "FEFCF8",
                                                    boxShadow: "none",
                                                    maxWidth: "400px",
                                                    borderRadius: "5px",
                                                },
                                            }}
                                            sx={{
                                                // width: "60%",
                                                "& .MuiPopover-paper": {
                                                    border: "1px solid #3e8cb5",
                                                    backgroundColor: "#ffffff",
                                                    // width:"30%"
                                                },
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: "12px",
                                                    fontFamily: "arial",
                                                    padding: "5px",
                                                }}
                                            >
                                                 {showIfpreviousappraisal ? item?.definition :item?.name?.definition}
                                            </Typography>
                                        </Popover>
                                    </TableCell>
                                    <TableCell
                                        width="200px"
                                        align="left"
                                        style={{
                                            fontSize: "14px",
                                            color: "#333333",
                                            fontFamily: "Arial",
                                            wordBreak: "break-word"
                                        }}
                                    >
                                        {item?.training_name}
                                    </TableCell>
                                    <TableCell
                                        width="200px"
                                        align="left"
                                        style={{
                                            fontSize: "14px",
                                            color: "#333333",
                                            fontFamily: "Arial",
                                            wordBreak: "break-word"
                                        }}
                                    >
                                        {item?.justification}
                                    </TableCell>
                                </TableRow>
                            </>
                        )
                    })}
                </Table>
            </div>
        </>
    )
}

export default TrainingRecommendationView