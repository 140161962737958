import { IconButton, MenuItem, Popover, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Infoicon from "../../../assets/Images/Infoicon.svg";
import Blueminus from "../../../assets/Images/Blueminus.svg";
import Blueadd from "../../../assets/Images/Blueadd.svg";



const PerformanceGoalsHeader = styled("div")({
    // marginLeft: "25px",
    marginBottom: "10px",
    color: "#717171",
    fontSize: "16px",
    fontFamily: "arial",
    //opacity: 0.85
});

const Train = styled("div")({
    "& .MuiTextField-root": {
        // color: "rgb(51 51 51/50%)",
        fontSize: "14px",
        color: "#333333",
        textTransform: "none",
        fontFamily: "Arial",
        backgroundColor: "#f8f8f8",
        borderRadius: "5px",
        padding: "8px",
        // paddingRight:"0px",
        width: "96%"

    },
    "& .MuiInputBase-input": {
        color: "#333333",
        fontSize: "14px",
        fontFamily: "Arial",
        textAlign: "left",
    },
    "& .MuiInputBase-root": {
        backgroundColor: "#f8f8f8",
        padding: "3px",
    },
    "& .MuiOutlinedInput-root": {
        maxWidth: "0px",
        minWidth: "100%",
        "& fieldset": {
            borderColor: "#f8f8f8",
        },
        "&:hover fieldset": {
            borderColor: "#f8f8f8",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#f8f8f8",
        },
    },
});

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 100,
            fontSize: "14px !important",
            fontFamily: "arial !important",
            color: "#333333 !important",
        },
    },
};

const Tf3 = styled("div")({
    borderRadius: "5px",
    backgroundColor: "#f8f8f8",
    "& .MuiInputBase-input": {
        fontSize: "14px",
        fontWeight: "400",
        textTransform: "none",
        fontFamily: "Arial",
        color: "#333333",
        padding: "8px",
        textAlign: "left"
    },
});

const PerformanceGoalsEdit = (props: any) => {
    const { showIfAppraiserOverview,
        showIfEmployee,
        moveTab,
        setMoveTab,
        navPrompt,
        setNavPrompt,
        disableTextAfterSubmission,
        performanceGoals,
        setPerformanceGoals,
        goalsCategoryData,
        employeeDataIsFetching,
        employeeDataIsLoading
    } = props;

    // popovers
    const [anchorEls, setAnchorEls] = React.useState<HTMLButtonElement | null>(null);
    const openGoalsCategoryInfo = Boolean(anchorEls);
    const openGoalsCategoryInfoId = openGoalsCategoryInfo ? "simple-popover" : undefined;
    const [popoverIndex, setPopoverIndex] = useState<any>("");

    const handleClickOpenGoalCategoryInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEls(event.currentTarget);
    };

    const handleClickCloseGoalCategoryInfo = () => {
        setAnchorEls(null);
    };


    //   functions
    const handlePerformanceGoalsChange = (i: any, e: any) => {
        setNavPrompt(true);
        setMoveTab(true)
        const newGoalValues = [...performanceGoals];
        //@ts-ignore
        newGoalValues[i][e.target.name] = e.target.value; /********* based on the name property respective values getting added ************/
        setPerformanceGoals(newGoalValues);
    };

    const addPerformanceGoalFields = () => {
        setPerformanceGoals([
            ...performanceGoals,
            { goal_category: "", description: "", keyresult: "", due_date: "", remarks: "" }, /****************** these are the name property of the fields ****************/
        ]);
    };

    const removePerformanceGoalFields = (i: any) => {
        setNavPrompt(true);
        setMoveTab(true);
        const newGoalValues = [...performanceGoals];
        newGoalValues?.splice(i, 1);
        setPerformanceGoals(newGoalValues);
    };

    // useEffects
    useEffect(() => {
        /**************** To add an empty field if the fields are not there ********************/
        if (performanceGoals?.length == 0) {
            addPerformanceGoalFields()
        }
    }, [performanceGoals])

    return (
        <>
            <div style={{
                marginBottom: "50px",
                // marginRight: "31px", 
                marginTop: "30px",
                //  marginLeft : "36px"
            }}>
                <PerformanceGoalsHeader>
                    {showIfAppraiserOverview && (<b>Performance Goals </b>)}
                    {showIfEmployee && (<b>Performance Goals (Employee)</b>)}
                </PerformanceGoalsHeader>

                {/* <div style={{ marginRight:"65px" }}> */}
                <Table size="small">
                    <TableHead>
                        <TableRow
                            sx={{
                                "& td, & th": {
                                    border: "1px solid #e0e0e0",
                                    bgcolor: "#eaeced",
                                    whiteSpace: "nowrap",
                                },
                            }}
                        >
                            <TableCell
                                align="center"
                                // width="30% !important"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    width: "13%",
                                }}
                            >
                                Goal Category
                            </TableCell>
                            <TableCell
                                align="center"
                                // width="36%"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    width: "25%",
                                }}
                            >
                                Description
                            </TableCell>
                            <TableCell
                                align="center"
                                // width="36%"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    width: "20%",
                                }}
                            >
                                Key Result/Target
                            </TableCell>
                            <TableCell
                                align="center"
                                // width="36%"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    width: "15%",
                                }}
                            >
                                Due Date
                            </TableCell>
                            <TableCell
                                align="center"
                                // width="36%"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    width: "30%",
                                }}
                            >
                                Remarks
                            </TableCell>
                            <TableCell
                                align="center"
                                // width="36%"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    width: "30%",
                                }}
                            >
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>


                    {performanceGoals?.map((element: any, index: any) => {
                        const selectedDefinition = goalsCategoryData?.data?.find(
                            (item: any) => item?._id === element?.goal_category
                        )?.definition;
                        console.log(element, "element")
                        return (

                            <TableBody>
                                <TableRow key={index}
                                    sx={{
                                        "& td, & th": {
                                            border: "1px solid #e0e0e0",
                                            whiteSpace: "nowrap",
                                        },
                                    }}
                                >
                                    <TableCell
                                        // width="30% !important"
                                        style={{
                                            width: "13%",
                                        }}
                                    >
                                        <Train>
                                            <Stack
                                                direction={"row"}
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={1}
                                            >
                                                {element?.goal_category != "" && (
                                                    <IconButton
                                                        style={{ padding: "0px" }}
                                                        onClick={(e: any) => {
                                                            handleClickOpenGoalCategoryInfo(e)
                                                            setPopoverIndex(index);
                                                        }}
                                                    >
                                                        <img width="12px" src={Infoicon} alt="icon" />
                                                    </IconButton>
                                                )}
                                                <Select
                                                    sx={{
                                                        boxShadow: "none",
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        background: "#f8f8f8",
                                                        // minWidth:"338px",
                                                        // maxWidth:"0px"
                                                    }}
                                                    fullWidth
                                                    autoComplete="off"
                                                    disabled={disableTextAfterSubmission}
                                                    // label="Add  Name"
                                                    // label="Select"
                                                    placeholder="Select"
                                                    size="small"
                                                    name="goal_category"
                                                    displayEmpty
                                                    MenuProps={MenuProps}
                                                    value={element?.goal_category || ""}
                                                    renderValue={
                                                        element?.goal_category || ""
                                                            ? undefined
                                                            : () => <div style={{ color: "#aaa" }}>Select</div>
                                                    }
                                                    onChange={(e) => {
                                                        handlePerformanceGoalsChange(index, e);
                                                        setMoveTab(true);
                                                    }}
                                                    disableUnderline
                                                >
                                                    {goalsCategoryData?.data?.length > 0 &&
                                                        goalsCategoryData?.data?.map((categoryItem: any) => {
                                                            return (
                                                                <MenuItem
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        fontFamily: "arial",
                                                                        color: "#333333",
                                                                    }}
                                                                    value={categoryItem?._id}
                                                                    disabled={employeeDataIsFetching || employeeDataIsLoading}
                                                                >
                                                                    {categoryItem?.title}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>



                                                <Popover
                                                    id={openGoalsCategoryInfoId}
                                                    open={(popoverIndex === index) && openGoalsCategoryInfo}
                                                    anchorEl={anchorEls}
                                                    onClose={handleClickCloseGoalCategoryInfo}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left",
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            backgroundColor: "FEFCF8",
                                                            boxShadow: "none",
                                                            maxWidth: "400px",
                                                            borderRadius: "5px",
                                                        },
                                                    }}
                                                    sx={{
                                                        "& .MuiPopover-paper": {
                                                            border: "1px solid #3e8cb5",
                                                            backgroundColor: "#ffffff",
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: "12px",
                                                            fontFamily: "arial",
                                                            padding: "5px",
                                                        }}
                                                    >
                                                        {selectedDefinition}
                                                    </Typography>
                                                </Popover>
                                            </Stack>
                                        </Train>
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            width: "25%",
                                        }}>
                                        <Tf3>
                                            <TextField
                                                fullWidth
                                                multiline
                                                autoComplete="off"
                                                placeholder="Add"
                                                size="small"
                                                disabled={employeeDataIsFetching || employeeDataIsLoading}
                                                name="description"
                                                value={element.description || ""}
                                                onChange={(e) => {
                                                    handlePerformanceGoalsChange(index, e);
                                                }}
                                                // inputProps={{ maxLength: 500 }}
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    readOnly: disableTextAfterSubmission
                                                }}
                                            />
                                        </Tf3>
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            width: "20%",
                                        }}>
                                        <Tf3>
                                            <TextField
                                                fullWidth
                                                multiline
                                                placeholder="Add"
                                                autoComplete="off"
                                                size="small"
                                                name="keyresult"
                                                id="outlined-select-select"
                                                // inputProps={{ maxLength: 500 }}
                                                value={element.keyresult || ""}
                                                onChange={(e) => {
                                                    handlePerformanceGoalsChange(index, e);
                                                }}
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    readOnly: disableTextAfterSubmission
                                                }}
                                                disabled={employeeDataIsFetching || employeeDataIsLoading}
                                            ></TextField>
                                        </Tf3>
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            width: "15%",
                                        }}>
                                        <Tf3>
                                            <input
                                                type="date"
                                                name="due_date"
                                                style={{
                                                    backgroundColor: "#f8f8f8",
                                                    width: "100%",
                                                    maxWidth: "0px",
                                                    minWidth: "200px",
                                                    color: "#7b7b7b",
                                                    height: "37px",
                                                    border: "1px solid #c6c6c6",
                                                    borderRadius: "3px",
                                                    // background: "none",

                                                }}
                                                disabled={disableTextAfterSubmission || employeeDataIsFetching || employeeDataIsLoading}
                                                value={element?.due_date || ""}
                                                // min={employeeData?.data?.calendar?.end_date_normalizer?.slice(0, 10)}
                                                // min={employeeData?.data?.normalizer?.normalized_Date?.slice(0, 10)}
                                                min={new Date()?.toISOString()?.slice(0, 10)}
                                                // data-date-format="DD MMMM YYYY"
                                                onKeyDown={(e) => {
                                                    if (e.code !== "Tab") {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    handlePerformanceGoalsChange(index, e);
                                                }}
                                            />
                                        </Tf3>
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            width: "30%",
                                        }}>
                                        <Tf3>
                                            <TextField
                                                fullWidth
                                                multiline
                                                placeholder="Add"
                                                autoComplete="off"
                                                size="small"
                                                name="remarks"
                                                id="outlined-select-select"
                                                // inputProps={{ maxLength: 500 }}
                                                value={element.remarks || ""}
                                                onChange={(e) => {
                                                    handlePerformanceGoalsChange(index, e);
                                                }}
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    readOnly: disableTextAfterSubmission
                                                }}
                                                disabled={employeeDataIsFetching || employeeDataIsLoading}
                                            ></TextField>
                                        </Tf3>
                                    </TableCell>


                                    {/* Add and Delete buttons to add and delete the Performance Goals fields */}
                                    <TableCell
                                     align="center"
                                    //     style={{
                                            //  borderColor: "#ffffff",
                                            //   padding: "0px", paddingLeft: "12px", width: "20%"
                                            //    }}
                                    >
                                        {performanceGoals?.length !== 0 && (
                                            <>
                                                <Tooltip title="Delete">
                                                    <div>{disableTextAfterSubmission
                                                            ? 
                                                            <img
                                                                style={{ cursor: "default" }}
                                                                src={Blueminus}
                                                                alt="icon"
                                                            /> 
                                                            :
                                                            <img
                                                                style={{ cursor: "pointer" }}
                                                                src={Blueminus}
                                                                // disabled={disableButtons}
                                                                onClick={() => {
                                                                    removePerformanceGoalFields(index);
                                                                }}
                                                                alt="icon"
                                                            />
                                                            }
                                                            </div>
                                                </Tooltip>
                                            </>
                                        )}
                                        {performanceGoals?.length - 1 === index && performanceGoals?.length < 6 && (
                                            <>
                                                <Tooltip title="Add">
                                                    <div>
                                                        {
                                                            disableTextAfterSubmission ? <img src={Blueadd}
                                                                style={{ cursor: "default" }}
                                                                alt="icon" /> :
                                                                <img src={Blueadd}
                                                                    // disabled={disableButtons}
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        addPerformanceGoalFields();
                                                                        setNavPrompt(true);
                                                                        setMoveTab(true);
                                                                    }}
                                                                    alt="icon" />}
                                                    </div>
                                                </Tooltip>
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>

                        )
                    })}
                </Table>
            </div>
        </>
    )
}

export default PerformanceGoalsEdit