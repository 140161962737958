import React from 'react'
import { useState, useEffect, useMemo, useRef } from 'react';
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import AppraiserDashboardContext from '../../../components/reviewer/Dashboard/AppraiserDashboardContext';
import Timelinerevview from '../../../components/Dashboard_Reusable_Component/Timelinerevview';
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";
import {
    useGetEmployeeByFilterQuery,
    useGetlineManagerEmployeeQuery,
    useGetlineManagerPlusOneEmployeeQuery
} from "../../../service";
import { useGetNineboxQuery } from "../../../service/ninebox/ninebox";
import Potentialtalents from "../../../assets/Images/Potentialtalents.svg";
import Solidtalents from "../../../assets/Images/Solidtalents.svg";
import Star from "../../../assets/Images/Star.svg";
import Inconsistent from "../../../assets/Images/Inconsistent.svg";
import Solidperformers from "../../../assets/Images/Solidperformers.svg";
import HighPerformerstop from "../../../assets/Images/HighPerformerstop.svg";
import Lowperformers from "../../../assets/Images/Lowperformers.svg";
import Solidperformer from "../../../assets/Images/Solidperformer.svg";
import Highperformers from "../../../assets/Images/Highperformers.svg";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import CalendarFilters from '../../../components/Dashboard_Reusable_Component/calnderfilter';
import LineManagersTeamTable from './MyTeamTable/LineManagersTeamTable';
import PerformanceRatingandEmpRejectionofOverallDashboard from './GlobeChart/PerformanceRatingandEmpRejectionofOverallDashboard';
import { useDashboardContext } from "../../../components/reviewer/Dashboard/AppraiserDashboardContext";
// import GlobeChartforNormalizerOverallDashboard from '../../../components/homepage/OverallDashboardComponents/GlobeChart/GlobeChartforNormalizerOverallDashboard';
import OverallDashboardExpandViewofTable from './MyTeamTable/OverallDashboardExpandViewofTable';
import TopPerformersparentforOverallDashboard from './Topperformance/TopPerformersparentforOverallDashboard';
import NineboxandTopPerformanceofNormalizerOverallDashboard from './NineBox/NineboxandTopPerformanceofNormalizerOverallDashboard';
import ExpandNineBoxandSolidtalentsofOverallDashboard from './ExpandNineBox/ExpandNineBoxandSolidtalentsofOverallDashboard';
import NineboxparentforOveralldashboard from './NineBox/NineboxparentforOveralldashboard';
import GlobechartParentforoverallDashboard from './GlobeChart/GlobechartParentforoverallDashboard';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const OverallDashboardForNormalizer = (props: any) => {
    const {
        valueofTab,
        setTabsActive,
        activeCalenderName,
        valueOfActiveCalender,
        setvalueOfActiveCalender,
        appCalId,
        navigationFrom,
        CalenderName,
        RatingsExpandActive,
        NineboxExpand,
        TopperformersExpandActive,
        TeamtableExpandActive,
        selectedRatingRange,
        solidperformersExpandActive,
        setRatingsExpandActive,
        setNineboxExpand,
        setTopperformersExpandActive,
        setselectedRatingRange,
        setTeamtableExpandActive,
        setSolidperformersExpandActive,
        GradeFilterr1,
        SectionFilter1,
        setGradeFilterr1,
        subSectionFilter1,
        divisionFilter1,
        setSectionFilter1,
        setSubSectionFilter1,
        setDivisionFilter1,
        tabLocation
    } = props;
    const { data: user } = useLoggedInUser();
    const myRef = useRef(null);
    const navigate = useNavigate();
    //Query for lineManager Employee 
    const { data: lineManagerEmployee } = useGetlineManagerEmployeeQuery(user?.employee_code);
    const { data: lineManagerPlusOneEmployee } = useGetlineManagerPlusOneEmployeeQuery(user?.employee_code);
    //Query for lineManager Employee
    const [lineManagerEmployeeData, setlineManagerEmployeeData] = React.useState<any>([]);
    const [lineManagerPlusOneEmployeeData, setlineManagerPlusOneEmployeeData] = React.useState<any>([]);
    const [DRandDRplus1, setDRandDRplus1] = React.useState<any>([]);
    // const { data: employeeData } = useGetEmployeeByFilterQuery("all")
    const { data: employeeData } = useGetEmployeeByFilterQuery(`?select=workflow_history,employee.employee_rejection,employee_code,legal_full_name,position_long_description,grade,appraiser_name,section,normalizer_name,reviewer_name,section,sub section,division,manager_position,work_location,
    appraiser_code,reviewer_code,normalizer_code,talent_category,appraisal.pa_rating,reviewer.reviewer_rating,normalizer.normalizer_rating,isGradeException,previous_rating,appraisal.appraiser_status,reviewer.reviewer_status,normalizer.normalizer_status,appraisal.pa_status,appraisal.objective_description,
    appraiser_code,previous_rating,reviewer_code,normalizer_code,pa_status,talent_category,reviewer.reviewer_rating,normalizer.normalizer_rating,previous_rating,appraisal.appraiser_status,reviewer.reviewer_status,normalizer.normalizer_status,isSupervisor,email,function,
                              (j?.normalizer?.normalizer_rejected === true) ? "red" : 
    appraisal.appraiser_rating,overall_rating,first_name,current_rating.overall_rating,normalizer.normalized_overallRating,employee.employee_rating,service_reference_date,sub_section,appraisal.potential,manager_code,manager_name,appraisal.status,employee.employee_agree,division,section,appraisal.pa_rating,
    normalizer.normalizer_rejected,appraisal.appraiser_PA_rejected, appraisal.appraiser_PA_accepted,reviewer.reviewer_PA_rejected, reviewer.reviewer_PA_accepted, normalizer.normalizer_PA_rejected, normalizer.normalizer_PA_accepted,appraisal.appraiser_rejected,employee.employee_status,appraisal.training_recommendation,
    employee.training_recommendation,
    appraisal.area_of_improvement,
    employee.area_of_improvement,
    appraisal_template.training_recommendation,appraisal.other_recommendation_others,
    appraisal.other_recommendation&employee_upload_flag=true&populate=employee.training_recommendation.name&populate=appraisal.other_recommendation.name&populate=appraisal.training_recommendation.name&calendar=${appCalId}&limit=800`)
    console.log(employeeData, "newdata")
    //setting DR data
    useEffect(() => {
        setlineManagerEmployeeData(lineManagerPlusOneEmployee?.lineManager?.filter((j: any) => j?.appraisal?.status != "excepted"))
        setlineManagerPlusOneEmployeeData(lineManagerPlusOneEmployee?.lineManagerPlusOne?.filter((j: any) => j?.appraisal?.status != "excepted"))
        if (lineManagerEmployeeData?.length > 0) {
            let firstArray = lineManagerEmployeeData;
            let secondArray = lineManagerPlusOneEmployeeData;
            let combinedArray = [...firstArray, ...secondArray];
            setDRandDRplus1(combinedArray)
        }
    }, [lineManagerPlusOneEmployee])
    console.log(lineManagerEmployeeData, "lineManager", lineManagerPlusOneEmployeeData, "Total", DRandDRplus1)
    //setting DR data
    //@ts-ignore
    const { FilteredEmployeeData, setFilteredEmployeeData, statusofUnknown } = useDashboardContext();
    console.log(FilteredEmployeeData, statusofUnknown, "FilteredEmployeeData")
    //DashboardContext
    //For Identifiction
    let NAVIGATION_FROM = "OverallDashboard";
    let LOCATION_FROM = "NormalizerOverallDashboard";
    //For Identifiction
    let Role = "Normalizer"
    const [Rolee, setRolee] = React.useState<any>([]);
    const { data: RangeValue } = useGetNineboxQuery("");
    useEffect(() => {
        setRolee(Role)
    }, [RangeValue])
    //function for nine box
    const [Range, setRange] = React.useState<any>([]);
    const [RangeHighFrom, setRangeHighFrom] = React.useState<any>(4);
    const [RangeHighTo, setRangeHighTo] = React.useState<any>(5);
    const [RangeMediumFrom, setRangeMediumFrom] = React.useState<any>(3);
    const [RangeMediumTo, setRangeMediumTo] = React.useState<any>(3.9);
    const [RangeLowFrom, setRangeLowFrom] = React.useState<any>(1);
    const [RangeLowTo, setRangeLowTo] = React.useState<any>(2.9);
    React.useEffect(() => {
        if (RangeValue?.data[0]?.performance_definitions !== undefined) {
            setRange(RangeValue?.data[0]?.performance_definitions)
            setRangeHighFrom(RangeValue?.data[0]?.performance_definitions?.high_from)
            setRangeHighTo(RangeValue?.data[0]?.performance_definitions?.high_to)
            setRangeMediumFrom(RangeValue?.data[0]?.performance_definitions?.medium_from)
            setRangeMediumTo(RangeValue?.data[0]?.performance_definitions?.medium_to)
            setRangeLowFrom(RangeValue?.data[0]?.performance_definitions?.low_from)
            setRangeLowTo(RangeValue?.data[0]?.performance_definitions?.low_to)
        }
    }, [RangeValue])
    //States for mulitiselect
    const [SectionFilter, setSectionFilter] = React.useState<string[]>([]);
    const [GradeFilter, setGradeFilter] = React.useState<string[]>([]);
    const [subSectionFilter, setSubSectionFilter] = React.useState<string[]>([]);
    const [divisionFilter, setDivisionFilter] = React.useState<string[]>([]);
    const [talentCategoreyFilter, settalentCategoreyFilter] = React.useState<string[]>([]);
    const [talentCategoreyData, settalentCategoreyData] = React.useState<any>([]);
    const [page, setPage] = React.useState(0);
    //States for ninebox data
    const [indexBasedValue, setindexBasedValue] = React.useState<any>([]);
    const [indexBasedTitle, setindexBasedTitle] = React.useState<any>('');
    console.log(indexBasedValue, indexBasedTitle, "ibasedValues")
    const [low3Value, setLow3Value] = React.useState<any>([]);
    const [low4Value, setLow4Value] = React.useState<any>([]);
    const [low5Value, setLow5Value] = React.useState<any>([]);
    const [moderate3Value, setmoderate3Value] = React.useState<any>([]);
    const [moderate4Value, setmoderate4Value] = React.useState<any>([]);
    const [moderate5Value, setmoderate5Value] = React.useState<any>([]);
    const [high3Value, sethigh3Value] = React.useState<any>([]);
    const [high4Value, sethigh4Value] = React.useState<any>([]);
    const [high5Value, sethigh5Value] = React.useState<any>([]);
    //Nine box filtered data 
    //pass data as params
    const Low3 = (data: any) => {
        let temp = data?.filter((j: any) => j?.appraisal?.status != "excepted")
            ?.filter((i: any) => {
                return i?.appraisal?.status == "completed" &&
                    i?.normalizer?.normalizer_rating >= RangeLowFrom && i?.normalizer?.normalizer_rating <= RangeLowTo &&
                    i?.appraisal?.potential == 'Low'
            });
        setLow3Value(temp)
        return temp;
    };
    const moderate3 = (data: any) => {
        let temp = data?.filter((j: any) => j?.appraisal?.status != "excepted")
            ?.filter((i: any) => {
                return i?.appraisal?.status == "completed" &&
                    i?.normalizer?.normalizer_rating >= RangeLowFrom && i?.normalizer?.normalizer_rating <= RangeLowTo &&
                    i?.appraisal?.potential == 'Moderate'
            });
        setmoderate3Value(temp)
        return temp;
    };
    const high3 = (data: any) => {
        let temp = data?.filter((j: any) => j?.appraisal?.status != "excepted")
            ?.filter((i: any) => {
                return i?.appraisal?.status == "completed" &&
                    i?.normalizer?.normalizer_rating >= RangeLowFrom && i?.normalizer?.normalizer_rating <= RangeLowTo &&
                    i?.appraisal?.potential == 'High'
            });
        sethigh3Value(temp)
        return temp;
    };
    const Low4 = (data: any) => {
        let temp = data?.filter((j: any) => j?.appraisal?.status != "excepted")
            ?.filter((i: any) => {
                return i?.appraisal?.status == "completed" &&
                    i?.normalizer?.normalizer_rating >= RangeMediumFrom && i?.normalizer?.normalizer_rating <= RangeMediumTo &&
                    i?.appraisal?.potential == 'Low'
            });
        setLow4Value(temp)
        return temp;
    };
    const moderate4 = (data: any) => {
        let temp = data?.filter((j: any) => j?.appraisal?.status != "excepted")
            ?.filter((i: any) => {
                return i?.appraisal?.status == "completed" &&
                    i?.normalizer?.normalizer_rating >= RangeMediumFrom && i?.normalizer?.normalizer_rating <= RangeMediumTo &&
                    i?.appraisal?.potential == 'Moderate'
            });
        setmoderate4Value(temp)
        return temp;
    };
    const high4 = (data: any) => {
        let temp = data?.filter((j: any) => j?.appraisal?.status != "excepted")
            ?.filter((i: any) => {
                return i?.appraisal?.status == "completed" &&
                    i?.normalizer?.normalizer_rating >= RangeMediumFrom && i?.normalizer?.normalizer_rating <= RangeMediumTo &&
                    i?.appraisal?.potential == 'High'
            });
        sethigh4Value(temp)
        return temp;
    };
    const Low5 = (data: any) => {
        let temp = data?.filter((j: any) => j?.appraisal?.status != "excepted")
            ?.filter((i: any) => {
                return i?.appraisal?.status == "completed" &&
                    i?.normalizer?.normalizer_rating >= RangeHighFrom && i?.normalizer?.normalizer_rating <= RangeHighTo &&
                    i?.appraisal?.potential == 'Low'
            });
        setLow5Value(temp)
        return temp;
    };
    const moderate5 = (data: any) => {
        let temp = data?.filter((j: any) => j?.appraisal?.status != "excepted")
            ?.filter((i: any) => {
                return i?.appraisal?.status == "completed" &&
                    i?.normalizer?.normalizer_rating >= RangeHighFrom && i?.normalizer?.normalizer_rating <= RangeHighTo &&
                    i?.appraisal?.potential == 'Moderate'
            });
        setmoderate5Value(temp)
        return temp;
    };
    const high5 = (data: any) => {
        let temp = data?.filter((j: any) => j?.appraisal?.status != "excepted")
            ?.filter((i: any) => {
                return i?.appraisal?.status == "completed" &&
                    i?.normalizer?.normalizer_rating >= RangeHighFrom && i?.normalizer?.normalizer_rating <= RangeHighTo &&
                    i?.appraisal?.potential == 'High'
            });
        sethigh5Value(temp)
        return temp;
    };
    //Nine box filter Function

    //Main data use effect
    // useEffect(() => {
    //     //@ts-ignore

    //     if (
    //      //@ts-ignore
    //      (SectionFilter === "" || SectionFilter.includes("None") || SectionFilter.length === 0) &&
    //      //@ts-ignore
    //      (subSectionFilter === "" || subSectionFilter.includes("None") || subSectionFilter.length === 0)
    //      //&& (talentCategoreyFilter === "")
    //    ) {
    //     //check the talent category condition1 and the again condition 2 and both
    //     //concat two data
    //      settalentCategoreyData(lineManagerEmployee?.getEmployee)


    //      //@ts-ignore
    //    }else{
    //      const Temp = lineManagerEmployee?.getEmployee?.filter((item1: any) => {
    //        if (SectionFilter.length === 0) {
    //          return item1
    //        } else { return !!SectionFilter?.find(item2 => item1.section === item2) }
    //      }).filter((item1: any) => {
    //        if (subSectionFilter.length === 0) {
    //          return item1
    //        } else { return !!subSectionFilter?.find(item2 => item1.sub_section === item2) }
    //      });
    //      settalentCategoreyData(Temp)
    //    }

    // // if(talentCategoreyFilter){
    // //   settalentCategoreyData(lineManagerEmployee?.getEmployee)
    // // }else if(talentCategoreyFilter){
    // //   settalentCategoreyData()
    // // }
    //  }, [ SectionFilter,subSectionFilter,lineManagerEmployee])
    // useEffect(() => {
    //     settalentCategoreyData(lineManagerEmployeeData)
    // }, [lineManagerPlusOneEmployee])

    //chat gpt
    // const processedData = useMemo(() => {
    //     // Perform some expensive calculation here
    //      settalentCategoreyData(lineManagerEmployee?.getEmployee)
    //   }, [lineManagerPlusOneEmployee]);

    console.log(SectionFilter?.length, subSectionFilter?.length, talentCategoreyFilter?.length, "filtervaluesss")
    const processedData = useMemo(() => {
        let firstArray = lineManagerEmployeeData?.filter((j: any) => j?.appraisal?.status != "excepted");
        let secondArray = lineManagerPlusOneEmployeeData?.filter((j: any) => j?.appraisal?.status != "excepted");
        // let combinedArray = firstArray?.concat(secondArray);
        let combinedArray = employeeData?.data?.filter((j: any) => j?.appraisal?.status != "excepted")
        setDRandDRplus1(combinedArray)
        //@ts-ignore
        if (
            //@ts-ignore
            (SectionFilter === "" || SectionFilter?.includes("None") || SectionFilter?.length === 0) &&
            //@ts-ignore
            (subSectionFilter === "" || subSectionFilter?.includes("None") || subSectionFilter?.length === 0) &&
            //@ts-ignore
            (divisionFilter === "" || divisionFilter.includes("None") || divisionFilter.length === 0) &&
            //@ts-ignore
            (GradeFilter === "" || GradeFilter.includes("None") || GradeFilter.length === 0) &&
            //@ts-ignore
            (GradeFilterr1 === "" || GradeFilterr1?.length === 0 || GradeFilterr1 === undefined) &&
            //@ts-ignore
            (SectionFilter1 === "" || SectionFilter1?.length === 0 || SectionFilter1 === undefined) &&
            //@ts-ignore
            (subSectionFilter1 === "" || subSectionFilter1?.length === 0 || subSectionFilter1 === undefined)

        ) {
            console.log("level-1")
            //@ts-ignore
            if (talentCategoreyFilter === "" || talentCategoreyFilter.length === 0 || talentCategoreyFilter.length === 2) {
                settalentCategoreyData(combinedArray)
                Low3(combinedArray)
                moderate3(combinedArray)
                high3(combinedArray)
                Low4(combinedArray)
                moderate4(combinedArray)
                high4(combinedArray)
                Low5(combinedArray)
                moderate5(combinedArray)
                high5(combinedArray)
                console.log("level-1.1")
                console.log(
                    Low3(combinedArray),
                    moderate3(combinedArray),
                    high3(combinedArray),
                    Low4(combinedArray),
                    moderate4(combinedArray),
                    high4(combinedArray),
                    Low5(combinedArray),
                    moderate5(combinedArray),
                    high5(combinedArray)
                    , "NineboxtalentCategoreyData"
                )
                //@ts-ignore               
            } else if (talentCategoreyFilter.includes("Level 1")) {
                settalentCategoreyData(combinedArray)
                Low3(combinedArray)
                moderate3(combinedArray)
                high3(combinedArray)
                Low4(combinedArray)
                moderate4(combinedArray)
                high4(combinedArray)
                Low5(combinedArray)
                moderate5(combinedArray)
                high5(combinedArray)
                console.log("level-1.2")
                console.log(
                    Low3(combinedArray),
                    moderate3(combinedArray),
                    high3(combinedArray),
                    Low4(combinedArray),
                    moderate4(combinedArray),
                    high4(combinedArray),
                    Low5(combinedArray),
                    moderate5(combinedArray),
                    high5(combinedArray)
                    , "NineboxtalentCategoreyData"
                )
                //@ts-ignore
            } else if (talentCategoreyFilter.includes("Level 2")) {
                settalentCategoreyData(lineManagerPlusOneEmployeeData)
                Low3(lineManagerPlusOneEmployeeData)
                moderate3(lineManagerPlusOneEmployeeData)
                high3(lineManagerPlusOneEmployeeData)
                Low4(lineManagerPlusOneEmployeeData)
                moderate4(lineManagerPlusOneEmployeeData)
                high4(lineManagerPlusOneEmployeeData)
                Low5(lineManagerPlusOneEmployeeData)
                moderate5(lineManagerPlusOneEmployeeData)
                high5(lineManagerPlusOneEmployeeData)
                console.log("level-1.3")
                console.log(
                    Low3(lineManagerPlusOneEmployeeData),
                    moderate3(lineManagerPlusOneEmployeeData),
                    high3(lineManagerPlusOneEmployeeData),
                    Low4(lineManagerPlusOneEmployeeData),
                    moderate4(lineManagerPlusOneEmployeeData),
                    high4(lineManagerPlusOneEmployeeData),
                    Low5(lineManagerPlusOneEmployeeData),
                    moderate5(lineManagerPlusOneEmployeeData),
                    high5(lineManagerPlusOneEmployeeData)
                    , "NineboxtalentCategoreyData"
                )
            }
            //@ts-ignore
        } else {
            console.log("level-2")
            //@ts-ignore
            if (talentCategoreyFilter === "" || talentCategoreyFilter.length === 0 || talentCategoreyFilter.length === 2) {
                //settalentCategoreyData(DRandDRplus1)
                console.log("level-2.1")
                const Temp = combinedArray?.filter((item1: any) => {
                    if (divisionFilter.length === 0) {
                        return item1
                    } else { return !!divisionFilter?.find(item2 => item1.division === item2) }
                })?.filter((item1: any) => {
                    if (SectionFilter.length === 0) {
                        return item1
                    } else { return !!SectionFilter?.find(item2 => item1.section === item2) }
                })?.filter((item1: any) => {
                    if (subSectionFilter.length === 0) {
                        return item1
                    } else { return !!subSectionFilter?.find(item2 => item1.sub_section === item2) }
                })?.filter((item1: any) => {
                    if (GradeFilter?.length === 0) {
                        return item1
                    } else { return !!GradeFilter?.find(item2 => item1?.grade === item2) }
                });
                settalentCategoreyData(Temp)
                Low3(Temp)
                moderate3(Temp)
                high3(Temp)
                Low4(Temp)
                moderate4(Temp)
                high4(Temp)
                Low5(Temp)
                moderate5(Temp)
                high5(Temp)
                console.log(
                    Low3(Temp),
                    moderate3(Temp),
                    high3(Temp),
                    Low4(Temp),
                    moderate4(Temp),
                    high4(Temp),
                    Low5(Temp),
                    moderate5(Temp),
                    high5(Temp)
                    , "NineboxtalentCategoreyData"
                )
                //@ts-ignore               
            } else if (talentCategoreyFilter.length === "Level 1") {
                //settalentCategoreyData(lineManagerEmployeeData)
                console.log("level-2.2")
                const Temp = lineManagerEmployeeData?.filter((item1: any) => {
                    if (divisionFilter.length === 0) {
                        return item1
                    } else { return !!divisionFilter?.find(item2 => item1.division === item2) }
                }).filter((item1: any) => {
                    if (SectionFilter.length === 0) {
                        return item1
                    } else { return !!SectionFilter?.find(item2 => item1.section === item2) }
                }).filter((item1: any) => {
                    if (subSectionFilter.length === 0) {
                        return item1
                    } else { return !!subSectionFilter?.find(item2 => item1.sub_section === item2) }
                })?.filter((item1: any) => {
                    if (GradeFilter?.length === 0) {
                        return item1
                    } else { return !!GradeFilter?.find(item2 => item1?.grade === item2) }
                });
                settalentCategoreyData(Temp)
                Low3(Temp)
                moderate3(Temp)
                high3(Temp)
                Low4(Temp)
                moderate4(Temp)
                high4(Temp)
                Low5(Temp)
                moderate5(Temp)
                high5(Temp)
                console.log(
                    Low3(Temp),
                    moderate3(Temp),
                    high3(Temp),
                    Low4(Temp),
                    moderate4(Temp),
                    high4(Temp),
                    Low5(Temp),
                    moderate5(Temp),
                    high5(Temp)
                    , "NineboxtalentCategoreyData"
                )
                //@ts-ignore
            } else if (talentCategoreyFilter.length === "Level 2") {
                //settalentCategoreyData(lineManagerPlusOneEmployeeData)
                console.log("level-2.3")
                const Temp = lineManagerPlusOneEmployeeData?.filter((item1: any) => {
                    if (divisionFilter.length === 0) {
                        return item1
                    } else { return !!divisionFilter?.find(item2 => item1.division === item2) }
                }).filter((item1: any) => {
                    if (SectionFilter.length === 0) {
                        return item1
                    } else { return !!SectionFilter?.find(item2 => item1.section === item2) }
                }).filter((item1: any) => {
                    if (subSectionFilter.length === 0) {
                        return item1
                    } else { return !!subSectionFilter?.find(item2 => item1.sub_section === item2) }
                })?.filter((item1: any) => {
                    if (GradeFilter?.length === 0) {
                        return item1
                    } else { return !!GradeFilter?.find(item2 => item1?.grade === item2) }
                });
                settalentCategoreyData(Temp)
                Low3(Temp)
                moderate3(Temp)
                high3(Temp)
                Low4(Temp)
                moderate4(Temp)
                high4(Temp)
                Low5(Temp)
                moderate5(Temp)
                high5(Temp)
                console.log(
                    Low3(Temp),
                    moderate3(Temp),
                    high3(Temp),
                    Low4(Temp),
                    moderate4(Temp),
                    high4(Temp),
                    Low5(Temp),
                    moderate5(Temp),
                    high5(Temp)
                    , "NineboxtalentCategoreyData"
                )
            }
        }
    }, [SectionFilter, divisionFilter, GradeFilter, subSectionFilter, talentCategoreyFilter, employeeData])
    //Main data use effect
    const dataforContext = useMemo(() => {
        if (RatingsExpandActive === false && TopperformersExpandActive === false
            && TeamtableExpandActive === false && NineboxExpand === false && solidperformersExpandActive === false
        ) {
            setTabsActive(false)
        } else {
            setTabsActive(true)
        }

    }, [RatingsExpandActive, TopperformersExpandActive, TeamtableExpandActive, NineboxExpand, solidperformersExpandActive])

    //Data for Ninebox
    const NineBoxValues = [
        {
            title: RangeValue?.data[0]?.box_9_definitions[0]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[0]?.definition,
            count: high3Value?.length,
            category: high3Value,
            color: "linear-gradient(to left, #F89994, #F7665E)",
            icon: <img src={Potentialtalents} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[1]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[1]?.definition,
            count: high4Value?.length,
            category: high4Value,
            color: "linear-gradient(to left, #71E1F6, #28B7D3)",
            icon: <img src={Solidtalents} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[2]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[2]?.definition,
            count: high5Value?.length,
            category: high5Value,
            color: "linear-gradient(to left, #71E1F6, #28B7D3)",
            icon: <img src={Star} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[3]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[3]?.definition,
            count: moderate3Value?.length,
            category: moderate3Value,
            color: "linear-gradient(to left, #F89994, #F7665E)",
            icon: <img src={Inconsistent} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[4]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[4]?.definition,
            count: moderate4Value?.length,
            category: moderate4Value,
            color: "linear-gradient(to left, #33CDB4, #079B82)",
            icon: <img src={Solidperformers} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[5]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[5]?.definition,
            count: moderate5Value?.length,
            category: moderate5Value,
            color: "linear-gradient(to left, #33CDB4, #079B82)",
            icon: <img src={HighPerformerstop} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[6]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[6]?.definition,
            count: low3Value?.length,
            category: low3Value,
            color: "linear-gradient(to left, #F89994, #F7665E)",
            icon: <img src={Lowperformers} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[7]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[7]?.definition,
            count: low4Value?.length,
            category: low4Value,
            color: "linear-gradient(to left, #F9C5A1, #F99B5B)",
            icon: <img src={Solidperformer} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[8]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[8]?.definition,
            count: low5Value?.length,
            category: low5Value,
            color: "linear-gradient(to left, #F9C5A1, #F99B5B)",
            icon: <img src={Highperformers} alt="image" />,
        },
    ];
    console.log(NineBoxValues, "NineBoxValuesofOD")
    //Data for Ninebox

    //Overall dashboard
    //For changing my actions's tab
    const [valueOfCardMyteam, setvalueOfCardMyteam] = React.useState<any>("");
    const handleOnClickCardMyteam = (e: any, title: any) => {
        setvalueOfCardMyteam(title)
        //@ts-ignore
        myRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    //For changing my actions's tab
    //functions for emp rej pie chart
    // in-Renormalization employee rejection chart count on dashboard
    const checkAppraisalStatusInProgressMyteam = (status: string) => {
        return talentCategoreyData?.filter((item: any) => {
            return item?.employee?.employee_agree == true && item?.appraisal?.status === status;
        }).length;
    };
    // completed employee rejection chart count on dashboard
    const checkAppraisalStatusCompletedMyteam = (status: string) => {
        return talentCategoreyData?.filter((item: any) => {
            return item?.appraisal?.status === status && (item?.employee?.employee_rejection === true);
        })?.length;
    };
    // inmediation employee rejection chart count on dashboard
    const checkAppraisalinmediationStatusMyteam = (status: string) => {
        return talentCategoreyData?.filter((item: any) => {
            return item?.employee?.employee_agree == false && item?.appraisal?.status === status;
        })?.length;
    };

    // expandableviews inprogress employee rejection chart
    const checkAppraisalStatusI = (status: string) => {
        return talentCategoreyData?.filter((item: any) => {
            return item?.employee?.employee_agree == true && item?.appraisal?.status === status;
        });
    };
    // expandableviews completed employee rejection chart  
    const checkAppraisalStatusC = (status: string) => {
        return talentCategoreyData?.filter((item: any) => {
            return item?.appraisal?.status === status &&
                (item?.employee?.employee_rejection === true);
        });
    };
    // expandableviews inmediation employee rejection chart
    const checkAppraisalStatusIM = (status: string) => {
        return talentCategoreyData?.filter((item: any) => {
            return item?.employee?.employee_agree == false && item?.appraisal?.status === status;
        });
    };
    //functions for emp rej pie chart
    //function for doughnut chart
    const [range1Myteam, setrange1Myteam] = React.useState<any>(0);
    const [range2Myteam, setrange2Myteam] = React.useState<any>(0);
    const [range3Myteam, setrange3Myteam] = React.useState<any>(0);
    const [range4Myteam, setrange4Myteam] = React.useState<any>(0);
    const checklengthinRangeMyteam = (min: number, max: number) => {
        return (
            (talentCategoreyData?.filter((item: any) => {
                // console.log(item?.appraisal?.appraiser_rating, "appraisal_rating3");
                return (
                    item?.normalizer?.normalizer_rating >= min &&
                    item?.normalizer?.normalizer_rating <= max && item?.appraisal?.status == "completed"
                );
            })?.length)
        );
    };
    // rating distribution expand views based on filter 
    const checklengthinRanges = (min: number, max: number) => {
        return (
            (talentCategoreyData?.filter((item: any) => {
                return (
                    item?.normalizer?.normalizer_rating >= min &&
                    item?.normalizer?.normalizer_rating <= max && item?.appraisal?.status == "completed"
                );
            }))
        );
    };
    useEffect(() => {
        setrange1Myteam(Math.round(checklengthinRangeMyteam(1, 2.49)))
        setrange2Myteam(Math.round(checklengthinRangeMyteam(2.5, 2.99)))
        setrange3Myteam(Math.round(checklengthinRangeMyteam(3, 3.99)))
        setrange4Myteam(Math.round(checklengthinRangeMyteam(4, 5)))
    }, [talentCategoreyData])
    //function for doughnut chart
    //function for status cards
    const checkAppraisalStatusforCardsMyteam = (status: string) => {
        if (talentCategoreyData)
            return talentCategoreyData?.filter((item: any) => {
                return item?.appraisal?.status === status;
            })?.length;
        else return 0;
    };
    const calculatePercentageforCardsMyteam = (num: number) => {
        if (talentCategoreyData && talentCategoreyData?.length != 0)
            return (num * 100) / talentCategoreyData?.length;
        else {
            return 0
        }
    };
    let CompletedEmpMyteam: any = checkAppraisalStatusforCardsMyteam("completed");
    let TotalEmpMyteam: any = checkAppraisalStatusforCardsMyteam("not-started") + checkAppraisalStatusforCardsMyteam("in-progress") +
        checkAppraisalStatusforCardsMyteam("normalized") + checkAppraisalStatusforCardsMyteam("rejected") + checkAppraisalStatusforCardsMyteam("completed")
    const StatusValuesMyteam: any = [
        {
            title: "Not-Started",
            percentage: calculatePercentageforCardsMyteam(checkAppraisalStatusforCardsMyteam("not-started")).toFixed(2),
            count: checkAppraisalStatusforCardsMyteam("not-started"),
            color: "linear-gradient(79deg, rgba(255,55,55,1) 39%, rgba(255,96,96,0.9136029411764706) 96%)"
        },
        {
            title: "In Progress",
            percentage: calculatePercentageforCardsMyteam(checkAppraisalStatusforCardsMyteam("in-progress") + checkAppraisalStatusforCardsMyteam("normalized")).toFixed(2),
            count: checkAppraisalStatusforCardsMyteam("in-progress") + checkAppraisalStatusforCardsMyteam("normalized"),
            color: "linear-gradient(to left, #F9C5A1, #F99B5B)"
        },
        {
            title: "Rejected",
            percentage: calculatePercentageforCardsMyteam(checkAppraisalStatusforCardsMyteam("rejected")).toFixed(2),
            count: checkAppraisalStatusforCardsMyteam("rejected"),
            color: "linear-gradient(to left, #BECFD9, #89949A)"
        },
        {
            title: "Completed",
            percentage: calculatePercentageforCardsMyteam(checkAppraisalStatusforCardsMyteam("completed")).toFixed(2),
            count: checkAppraisalStatusforCardsMyteam("completed"),
            color: "linear-gradient(to left, #35CFB6, #079B82)"
        },
    ];
    //function for status cards
    // function for TopPerformers
    const TopPerformersOfAppraiserMyteam = talentCategoreyData?.slice()?.filter((f: any) => f?.normalizer?.normalizer_rating >= 4 && f?.appraisal?.status == "completed")
        ?.sort((a: any, b: any) => b?.normalizer?.normalizer_rating - a?.normalizer?.normalizer_rating)
    return (
        <>
            <div
                style={{
                    background: "#F1F1F1",
                    minHeight: "100px",
                    overflow: "hidden",
                    height: "auto",
                }}
            >                <AppraiserDashboardContext>
                    {(RatingsExpandActive === false && TopperformersExpandActive === false &&
                        TeamtableExpandActive === false && NineboxExpand === false && solidperformersExpandActive === false) && <div>
                            <div>
                                <CalendarFilters
                                    Role={Role}
                                    GradeFilterr1={GradeFilterr1}
                                    SectionFilter1={SectionFilter1}
                                    divisionFilter1={divisionFilter1}
                                    subSectionFilter1={subSectionFilter1}
                                    setGradeFilterr1={setGradeFilterr1}
                                    setSectionFilter1={setSectionFilter1}
                                    setDivisionFilter1={setDivisionFilter1}
                                    setSubSectionFilter1={setSubSectionFilter1}
                                    page={page}
                                    setPage={setPage}
                                    CalenderName={CalenderName}
                                    employeeData1={employeeData}
                                    SectionFilter={SectionFilter}
                                    setSectionFilter={setSectionFilter}
                                    GradeFilter={GradeFilter}
                                    setGradeFilter={setGradeFilter}
                                    subSectionFilter={subSectionFilter}
                                    setSubSectionFilter={setSubSectionFilter}
                                    divisionFilter={divisionFilter}
                                    setDivisionFilter={setDivisionFilter}
                                    navigationFrom={navigationFrom}
                                    talentCategoreyFilter={talentCategoreyFilter}
                                    settalentCategoreyFilter={settalentCategoreyFilter}
                                    setvalueOfActiveCalender={setvalueOfActiveCalender}
                                    valueOfActiveCalender={valueOfActiveCalender}
                                />
                                {activeCalenderName != undefined &&
                                    <Timelinerevview activeCalenderName={activeCalenderName} />
                                }
                            </div>
                            <div>
                                {talentCategoreyData && <PerformanceRatingandEmpRejectionofOverallDashboard
                                    LOCATION_FROM={LOCATION_FROM}
                                    checkAppraisalStatusC={checkAppraisalStatusC}
                                    checkAppraisalStatusIR={checkAppraisalStatusI}
                                    checkAppraisalStatusIM={checkAppraisalStatusIM}
                                    checklengthinRanges={checklengthinRanges}
                                    completedCount={checkAppraisalStatusCompletedMyteam("completed")}
                                    inprogressCount={checkAppraisalStatusInProgressMyteam("rejected")}
                                    inMediaton={checkAppraisalinmediationStatusMyteam("rejected")}
                                    range1={range1Myteam}
                                    range2={range2Myteam}
                                    range3={range3Myteam}
                                    range4={range4Myteam}
                                    TotalEmp={TotalEmpMyteam}
                                    CompletedEmp={CompletedEmpMyteam}
                                    navigationFrom={navigationFrom}
                                    handleOnClickCard={handleOnClickCardMyteam}
                                    StatusValues={StatusValuesMyteam}
                                    setselectedRatingRange={setselectedRatingRange}
                                    setRatingsExpandActive={setRatingsExpandActive}
                                />}
                            </div>
                            <div ref={myRef} style={{ marginTop: "25px" }}>
                                <LineManagersTeamTable
                                    GradeFilterr={GradeFilter}
                                    SectionFilterr={SectionFilter}
                                    subSectionFilterr={subSectionFilter}
                                    divisionFilterr={divisionFilter}
                                    CalenderName={CalenderName}
                                    page={page}
                                    setPage={setPage}
                                    Rolee={Rolee}
                                    Role={Role}
                                    navigationFrom={navigationFrom}
                                    setSectionFilterr={setSectionFilter}
                                    setSubSectionFilter={setSubSectionFilter}
                                    setDivisionFilter={setDivisionFilter}
                                    employeeData1={talentCategoreyData}
                                    valueOfCard={valueOfCardMyteam}
                                    setTeamtableExpandActive={setTeamtableExpandActive}
                                />

                            </div>
                            <div style={{ marginTop: "25px", marginBottom: "25px" }}>
                                <NineboxandTopPerformanceofNormalizerOverallDashboard
                                    NAVIGATION_FROM={NAVIGATION_FROM}
                                    setSolidperformersExpandActive={setSolidperformersExpandActive}
                                    NineBoxValues={NineBoxValues}
                                    NineBoxData={NineBoxValues}
                                    topPerformerEmployees={TopPerformersOfAppraiserMyteam}
                                    navigationFrom={navigationFrom}
                                    setTopperformersExpandActive={setTopperformersExpandActive}
                                    setNineboxExpand={setNineboxExpand}
                                    indexBasedValue={indexBasedValue}
                                    setindexBasedValue={setindexBasedValue}
                                    indexBasedTitle={indexBasedTitle}
                                    setindexBasedTitle={setindexBasedTitle}
                                />
                            </div>
                        </div>}
                    {RatingsExpandActive && <div>
                        <GlobechartParentforoverallDashboard
                            FinalData={talentCategoreyData}
                            selectedRatingRange={selectedRatingRange}
                            setselectedRatingRange={setselectedRatingRange}
                            setRatingsExpandActive={setRatingsExpandActive}
                        />
                    </div>}
                    {TopperformersExpandActive && <div>
                        <TopPerformersparentforOverallDashboard
                            LOCATION_FROM={LOCATION_FROM}
                            topPerformerEmployeeso={TopPerformersOfAppraiserMyteam}
                            FinalData={talentCategoreyData}
                            setTopperformersExpandActive={setTopperformersExpandActive}
                        />
                    </div>}
                    {TeamtableExpandActive && <div>
                        <OverallDashboardExpandViewofTable
                            Role={Role}
                            employeeData1={talentCategoreyData}
                            setTeamtableExpandActive={setTeamtableExpandActive}
                            setRatingsExpandActive={setRatingsExpandActive}
                            setTopperformersExpandActive={setTopperformersExpandActive}
                            setNineboxExpand={setNineboxExpand}
                            setSolidperformersExpandActive={setSolidperformersExpandActive}
                        />
                    </div>}
                    {NineboxExpand && <div>
                        <ExpandNineBoxandSolidtalentsofOverallDashboard
                            navigationFrom={navigationFrom}
                            setNineboxExpand={setNineboxExpand}
                            setSolidperformersExpandActive={setSolidperformersExpandActive}
                            NineBoxData={NineBoxValues}
                            indexBasedTitle={indexBasedTitle}
                            setindexBasedTitle={setindexBasedTitle}
                            indexBasedValue={indexBasedValue}
                            setindexBasedValue={setindexBasedValue}
                        />
                    </div>}
                    {solidperformersExpandActive && <div>
                        <NineboxparentforOveralldashboard
                            navigationFrom={navigationFrom}
                            indexBasedTitle={indexBasedTitle}
                            indexBasedValue={indexBasedValue}
                            NineBoxData={NineBoxValues}
                            setSolidperformersExpandActive={setSolidperformersExpandActive}
                            employeeData1={talentCategoreyData}
                        />
                    </div>}
                </AppraiserDashboardContext>
            </div>
        </>
    )
}

export default OverallDashboardForNormalizer