import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Stack, Alert,Snackbar, } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Deleteicon from "../../assets/Images/Deleteicon.svg";
import Closeicon from "../../assets/Images/Closeicon.svg";
import { borderRadius, display } from "@mui/system";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    customAlert: {
      backgroundColor: '#3e8cb5',
      color: "white",
      height: '60px !important',
      alignItems: "center",
      fontSize: "1rem"
    },
    customSnackbar: {
      paddingBottom: '16px',
      paddingRight: '16px',
    },
  }));
const SnackBar: React.FC<any> = (props: any) => {
  const {
    successAlertTriger,
    // isAlertOpen,
    successAlertTrigerMSG,
    // handleAlertClose,
    handleCloseSnackbar,
  } = props;
  const classes = useStyles();

  return (
    <Snackbar
    className={classes.customSnackbar}
    open={successAlertTriger}
    autoHideDuration={3000}
    onClose={handleCloseSnackbar}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
    <Alert
      className={classes.customAlert}
      onClose={handleCloseSnackbar}
      sx={{ width: '100%' }}
      icon={false}
    >
      <b>{successAlertTrigerMSG}</b>
    </Alert>
    </Snackbar>
  );
};

export default SnackBar;

