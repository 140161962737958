import { CLIENT_URL } from "../../URL"

// Notification action PA Initiation (for All)
const At_Launch = {
    // to: "Pmstest@taqeef.com",
    to: "[email]",
    subject: "[year] [calendar name] Performance Appraisal has been initiated for your actions ",
    html:
        `
        <div style="font-size: 14px; font-family: Arial; color:black;">
        <span>Dear Employee,</span><br>\<br>\

        <span>This is to inform you that the [year] [calendar name] appraisal process has been initiated. Please <a href = ${CLIENT_URL}>log in</a> to the system to complete the performance appraisal process. </span><br>\<br>\

        <span>The mid-year performance appraisal provides an opportunity to review and assess your team members' progress and achievements over the past months. Please note that the appraisal period is from <b>[initiation date]</b> to <b>[closing date]</b>, and will include only employees who have completed their probation period. </span><br>\<br>\
       
        <span>As a reminder, it is mandatory to discuss the performance appraisal with each employee. This discussion allows employees to understand their performance, provide additional context, and address any questions or concerns they may have.</span><br>\<br>\
       
        <span>Appraisers are required to submit their team’s performance appraisals by <b>[dd/mm/yy]</b>.</span><br>\<br>\

        <span>If you encounter any difficulties during the process or require further information, please contact the Human Resources Team for assistance.</span><br>\<br>\

        <span>This is a system-generated email. Please do not reply.</span><br>\<br>\

        <span>Regards,</span><br>\

        <span>Human Resources Team</span>

        </div>    `
}

export {
    At_Launch,
}