import { Box, Grid, IconButton, MenuItem, Popover, Select, Stack, Typography } from '@mui/material';
import React from 'react'
import Infoicon from "../../../assets/Images/Infoicon.svg";
import { useGetNineboxQuery } from '../../../service/ninebox/ninebox';


const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // width: 200,
            fontSize: "14px !important",
            fontFamily: "arial !important",
            color: "#333333 !important",
        },
    },
};

const PotentialView = (props: any) => {
    const { data: nineBoxData } = useGetNineboxQuery("");
    const { showIfpreviousappraisal, employeeData, employeeDetails, potential, setPotential, disableTextAfterSubmission, setMoveTab, setNavPrompt, showPotentialView,} = props;

    console.log(nineBoxData,showPotentialView,potential, 'checkpotential')
    //popover
    const [anchorE, setAnchorE] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorE);
    const id = open ? "simple-popover" : undefined;

    const handlePotentialClose = () => {
        setAnchorE(null);
    };

    const handleClickPotential = (event: React.MouseEvent<HTMLButtonElement>) => {
        //setnavPrompt(true);
        setAnchorE(event.currentTarget);
    };


    //functions
    const handleChangePotential = (event: any) => {
        setPotential(event.target.value as string);
        setMoveTab(true);
        setNavPrompt(true);
    };


    return (
        <>
            {showPotentialView && (potential !== "" && potential !== undefined) && (
                <Grid item xs={4}>
                    <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-end"
                    // alignItems: "center"
                    // marginRight="33px"
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography color="#3e8cb5" fontFamily="arial" fontSize="17px" >
                                <IconButton style={{ padding: "4px" }} onClick={handleClickPotential} >
                                    <img style={{ width: "12px" }} src={Infoicon} alt="icon" />
                                </IconButton>
                                Potential Level

                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorE}
                                    onClose={handlePotentialClose}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    PaperProps={{
                                        style: {
                                            backgroundColor: "FEFCF8",
                                            boxShadow: "none",
                                            maxWidth: "450px",
                                            borderRadius: "5px",
                                        },
                                    }}
                                    sx={{
                                        "& .MuiPopover-paper": {
                                            border: "1px solid #3e8cb5",
                                            backgroundColor: "#ffffff",
                                        },
                                    }}
                                >
                                    {showIfpreviousappraisal ?
                                        <div
                                            style={{
                                                padding: "10px",
                                                fontSize: "134px",
                                                lineHeight: "20px",
                                                color: "#333333",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            {potential == "High" && (
                                                <Typography
                                                    style={{
                                                        fontSize: "14px",
                                                        // color: "#3e8cb5",
                                                        fontFamily: "Arial",
                                                        paddingBottom: "5px",
                                                        // borderBottom: "1px solid #d9d9d9",
                                                    }}
                                                >
                                                    <b>High:</b>
                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "#333333",
                                                            fontFamily: "Arial",
                                                        }}
                                                    >
                                                        {employeeData?.employee?.high}
                                                    </span>
                                                </Typography>
                                            )}

                                            {potential == "Moderate" && (
                                                <Typography
                                                    style={{
                                                        fontSize: "14px",
                                                        //color: "#3e8cb5",
                                                        fontFamily: "Arial",
                                                        paddingBottom: "5px",
                                                        paddingTop: "5px",
                                                        //borderBottom: "1px solid #d9d9d9",
                                                    }}
                                                >
                                                    <b>Moderate:</b>

                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "#333333",
                                                            fontFamily: "Arial",
                                                        }}
                                                    >
                                                        {employeeData?.employee?.moderate}
                                                    </span>
                                                </Typography>
                                            )}

                                            {potential == "Low" && (
                                                <Typography
                                                style={{
                                                    fontSize: "14px",
                                                    //color: "#3e8cb5",
                                                    fontFamily: "Arial",
                                                    paddingTop: "5px",
                                                }}
                                            >
                                                <b>Low:</b>

                                                <span
                                                    style={{
                                                        fontSize: "14px",
                                                        color: "#333333",
                                                        fontFamily: "Arial",
                                                    }}
                                                >
                                                    {employeeData?.employee?.low}
                                                </span>
                                            </Typography>
                                            )}
                                            
                                        </div>
                                        :
                                        <div
                                            style={{
                                                padding: "10px",
                                                fontSize: "134px",
                                                lineHeight: "20px",
                                                color: "#333333",
                                                fontFamily: "Arial",
                                            }}
                                        >{potential == "High" &&
                                            <Typography
                                                style={{
                                                    fontSize: "14px",
                                                    // color: "#3e8cb5",
                                                    fontFamily: "Arial",
                                                    paddingBottom: "5px",
                                                    // borderBottom: "1px solid #d9d9d9",
                                                }}
                                            >
                                                <b>High:</b>
                                                <span
                                                    style={{
                                                        fontSize: "14px",
                                                        color: "#333333",
                                                        fontFamily: "Arial",
                                                    }}
                                                >
                                                    {nineBoxData?.data[0]?.potential_definitions?.high}
                                                </span>
                                            </Typography>
                                            }
                                            {potential == "Moderate" && (
                                                <Typography
                                                    style={{
                                                        fontSize: "14px",
                                                        //color: "#3e8cb5",
                                                        fontFamily: "Arial",
                                                        paddingBottom: "5px",
                                                        paddingTop: "5px",
                                                        //borderBottom: "1px solid #d9d9d9",
                                                    }}
                                                >
                                                    <b>Moderate:</b>

                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "#333333",
                                                            fontFamily: "Arial",
                                                        }}
                                                    >
                                                        {nineBoxData?.data[0]?.potential_definitions
                                                            ?.moderate}{" "}
                                                    </span>
                                                </Typography>
                                            )}
                                            {potential == "Low" && (
                                                <Typography
                                                    style={{
                                                        fontSize: "14px",
                                                        //color: "#3e8cb5",
                                                        fontFamily: "Arial",
                                                        paddingTop: "5px",
                                                    }}
                                                >
                                                    <b>Low:</b>

                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "#333333",
                                                            fontFamily: "Arial",
                                                        }}
                                                    >
                                                        {nineBoxData?.data[0]?.potential_definitions?.low}{" "}
                                                    </span>
                                                </Typography>
                                            )}
                                        </div>
                                    }

                                </Popover>
                            </Typography>
                            <span
                                style={{
                                    color: "#717171",
                                    marginTop: "8px",
                                    fontSize: "16px",
                                    fontFamily: "Arial",
                                    // alignItems: "center"
                                }}
                            >
                                {potential}
                            </span>
                        </div>

                    </Stack>
                </Grid>
            )}

        </>
    )
}

export default PotentialView
