import React from 'react'
import { IconButton, Popover, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@mui/material'
import Infoicon from "../../../assets/Images/Infoicon.svg";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Removeattnew from "../../../assets/Images/Removeattnew.svg";
import CustomPopOverForTable from '../../UI/CustomPopoverForTable';
import RatingsHeader from './RatingsHeader';
import RatingsObjectiveDetails from './RatingsObjectiveDetails';


const RatingBackground = styled("div")({
    width: "27px",
    lineHeight: "27px",
    borderRadius: "50%",
    display: "block",
});

// Define common styles for TableCell
const tableCellStyle = {
    fontFamily: "Arial",
    borderColor: "#F7F9FB",
    color: "#3E8CB5",
    fontSize: "14px",
    fontWeight: "600",
};

const tableCellbodyStyle = {
    fontSize: "14px",
    color: "#333333",
    fontFamily: "Arial",
}

const RatingTable = (props: any) => {
    const { employee_id,
        objectiveDescription,
        colorarray,
        disableTextAfterSubmission,
        visiblityColumnData,
        popoverIndex,
        setPopoverIndex,
        activeObjectiveId,
        setActiveObjectiveId,
        employeeAttachments,
        setemployeeAttachments,
        deleteEmployeeMutation,
        anchorObjectiveTitleInfo,
        setAnchorObjectiveTitleInfo,
        openObjectiveTitleInfo,
        idObjectiveTitleInfo,
        handleOpenObjectiveTitleInfo,
        handleCloseObjectiveTitleInfo,
        anchorObjectiveLevelInfo,
        setAnchorObjectiveLevelInfo,
        openObjectiveLevelInfo,
        idObjectiveLevelInfo,
        handleOpenObjectiveLevelInfo,
        handleCloseObjectiveLevelInfo,
        anchorPreviousRatingPopOver,
        setAnchorPreviousRatingPopOver,
        openPreviousRating,
        id_Previous_Rating,
        handlePreviousRatingPopOverClose,
        handlePreviousRatingPopOverOpen,
        anchorAppraiserCommentsAttachments,
        setAnchorAppraiserCommentsAttachments,
        openAppraiserCommentsAttachments,
        idAppraiserCommentsAttachments,
        handleOpenAppraiserCommentsAttachments,
        handleCloseAppraiserCommentsAttachments,
        anchorRejectionReasonAttachment,
        setAnchorRejectionReasonAttachment,
        openRejectionReasonAttachment,
        idRejectionReasonAttachment,
        handleOpenRejectionReasonAttachment,
        handleCloseRejectionReasonAttachment,
        anchorEmployeeRejectionAttachment,
        setAnchorEmployeeRejectionAttachment,
        openEmployeeRejectionAttachment,
        handleClickOpenEmployeeRejectionAttachment,
        handleCloseEmployeeRejectionAttachment,
        anchorNormalizerReasonAttachment,
        setAnchorNormalizerReasonAttachment,
        openNormalizerReasonAttachment,
        idNormalizerReasonAttachment,
        handleOpenNormalizerReasonAttachment,
        handleCloseNormalizerReasonAttachment,
        getAppraiserCommentsAttachments,
        getAppraiserRejectionReasonAttachments,
        getEmployeeRejectionReasonAttachments,
        getNormalizerReasonAttachments,
        showIfpreviousappraisal,
    } = props;
    return (
        <>
            <div 
            // style={{ marginRight: "34px", marginLeft: "0px" }} 
            >
                <TableContainer>
                    <Table sx={{
                        borderCollapse: 'separate',
                        borderSpacing: '0px 15px'
                    }} size="small" aria-label="simple table">
                        <RatingsHeader
                        visiblityColumnData = {visiblityColumnData} />

                        <TableBody>
                            {objectiveDescription?.map((j: any, index: any) => {
                                return (
                                    <>
                                        <TableRow
                                            sx={{
                                                "& td, & th": {
                                                    border: "1px solid #80808014 ",
                                                    boxShadow: "1px 0px 0px 1px #80808014",
                                                    borderBottom: "none",
                                                    borderLeft: "0px",
                                                    borderTop: "0px"
                                                },
                                            }}
                                        >
                                            <RatingsObjectiveDetails
                                            showIfpreviousappraisal={showIfpreviousappraisal}
                                                index={index}
                                                colorarray={colorarray}
                                                popoverIndex={popoverIndex}
                                                objectiveDescription={j}
                                                activeObjectiveId = {activeObjectiveId}
                                                setActiveObjectiveId = {setActiveObjectiveId}
                                                setPopoverIndex={setPopoverIndex}
                                            />
                                            
                                            <TableCell
                                                width="10px"
                                                sx={{
                                                    ...tableCellbodyStyle,
                                                    wordBreak: "break-word",
                                                    background: "#ffffff",
                                                    padding: "0px 8px"
                                                }}
                                                align="center"
                                            >
                                                <Stack
                                                    direction="column"
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <div style={{ display: "inline-flex" }}>
                                                        {" "}

                                                        {showIfpreviousappraisal ? j.rating :(j?.ratings && j.rating_rejected == true) ?
                                                            <RatingBackground onClick={(e: any) => { handlePreviousRatingPopOverOpen(e, j); setPopoverIndex(index) }} style={{ color: "white", background: "#D2122E",textAlign:"center"}}>{j?.ratings?.rating}</RatingBackground> :
                                                            (j?.ratings && (j.rating_accepted == true || j.rating_resubmitted == true)) ?
                                                                <RatingBackground onClick={(e: any) => { handlePreviousRatingPopOverOpen(e, j); setPopoverIndex(index) }} style={{ color: "white", background: "#3e8cb5",textAlign:"center"  }}>{j?.ratings?.rating}</RatingBackground> :
                                                                (j?.ratings?.rating)}


                                                        {visiblityColumnData?.showPreviousRating && (
                                                            <CustomPopOverForTable
                                                                id={id_Previous_Rating}
                                                                open={popoverIndex === index && openPreviousRating}
                                                                anchorEl={anchorPreviousRatingPopOver}
                                                                onClose={handlePreviousRatingPopOverClose}
                                                            >
                                                                <div
                                                                    style={{
                                                                        ...tableCellbodyStyle,
                                                                        padding: "5px",
                                                                        fontSize: "12px",
                                                                        lineHeight: "20px",
                                                                    }}
                                                                >
                                                                    <span>Previous Rating:{j.previous_rating}</span>
                                                                </div>
                                                            </CustomPopOverForTable>
                                                        )}
                                                    </div>
                                                </Stack>
                                            </TableCell>

                                            {visiblityColumnData?.showAppraiserComments &&
                                                (<TableCell
                                                    width="200px"
                                                    sx={{
                                                        ...tableCellbodyStyle,
                                                        wordBreak: "break-word",
                                                        background: "#FBFBFB",
                                                    }}
                                                    align="left"
                                                >
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <span
                                                            style={{
                                                                ...tableCellbodyStyle,
                                                                wordBreak: "break-word",
                                                            }}
                                                        >
                                                            {(j.comments == "" || j.comments == undefined) ? "" : j.comments}
                                                        </span>
                                                        {getAppraiserCommentsAttachments(j?.name?._id)?.length > 0 &&
                                                            <AttachFileIcon
                                                                style={{
                                                                    color: "#93DCFA",
                                                                    height: "18px",
                                                                    transform: "rotate(30deg)",
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={(e: any) => {
                                                                    handleOpenAppraiserCommentsAttachments(e)
                                                                    setPopoverIndex(index)
                                                                }}
                                                                aria-describedby={"id8"}

                                                            />
                                                        }
                                                        <CustomPopOverForTable
                                                            id={idAppraiserCommentsAttachments}
                                                            open={(popoverIndex === index) && openAppraiserCommentsAttachments}
                                                            anchorEl={anchorAppraiserCommentsAttachments}
                                                            onClose={handleCloseAppraiserCommentsAttachments}
                                                        >
                                                            <div
                                                                style={{
                                                                    ...tableCellbodyStyle,
                                                                    padding: "5px",
                                                                    fontSize: "12px",
                                                                    lineHeight: "20px",
                                                                }}
                                                            >
                                                                {getAppraiserCommentsAttachments(j?.name?._id)?.map((k: any, index1: any) => {
                                                                    return (
                                                                        <>
                                                                            <Stack
                                                                                spacing={1}
                                                                                direction="row"
                                                                                alignItems="center"
                                                                            >
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: "12px",
                                                                                        fontFamily: "Arial",
                                                                                        color: "#333333",
                                                                                    }}
                                                                                >
                                                                                    {index1 + 1}.
                                                                                </Typography>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: "12px",
                                                                                        fontFamily: "Arial",
                                                                                        color: "#333333",
                                                                                        whiteSpace: "nowrap",
                                                                                        overflow: "hidden",
                                                                                        textOverflow: "ellipsis",
                                                                                        width: "170px"
                                                                                    }}
                                                                                >
                                                                                    {k.resp}
                                                                                </Typography>
                                                                                <Stack direction="row">
                                                                                </Stack>
                                                                            </Stack>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </CustomPopOverForTable>
                                                    </Stack>
                                                </TableCell>
                                                )}

                                            {visiblityColumnData?.showAppraiserRejectionReason && (
                                                <TableCell
                                                    width="200px"
                                                    sx={{
                                                        ...tableCellbodyStyle,
                                                        wordBreak: "break-word",
                                                        background: "#fbfbfb",
                                                    }}
                                                    align="left"
                                                >
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <span
                                                            style={{
                                                                ...tableCellbodyStyle,
                                                                wordBreak: "break-word",
                                                            }}
                                                        >
                                                            {(j.rating_rejected == true || j.rating_resubmitted) && j.rejection_reason}
                                                        </span>
                                                        {getAppraiserRejectionReasonAttachments(j?.name?._id)?.length > 0 &&
                                                            (j.rating_rejected == true) &&
                                                            <AttachFileIcon
                                                                style={{
                                                                    color: "#93DCFA",
                                                                    height: "18px",
                                                                    transform: "rotate(30deg)",
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={(e: any) => {
                                                                    handleOpenRejectionReasonAttachment(e)
                                                                    setPopoverIndex(index)
                                                                }}
                                                                aria-describedby={idRejectionReasonAttachment}

                                                            />
                                                        }
                                                        <CustomPopOverForTable
                                                            id={idRejectionReasonAttachment}
                                                            open={(popoverIndex === index) && openRejectionReasonAttachment}
                                                            anchorEl={anchorRejectionReasonAttachment}
                                                            onClose={handleCloseRejectionReasonAttachment}
                                                        >
                                                            <div
                                                                style={{
                                                                    ...tableCellbodyStyle,
                                                                    padding: "5px",
                                                                    fontSize: "12px",
                                                                    lineHeight: "20px",
                                                                }}
                                                            >
                                                                {getAppraiserRejectionReasonAttachments(j?.name?._id)?.map((k: any, index1: any) => {
                                                                    return (
                                                                        <>
                                                                            <Stack
                                                                                spacing={1}
                                                                                direction="row"
                                                                                alignItems="center"
                                                                            >

                                                                                <Typography
                                                                                    style={{
                                                                                        ...tableCellbodyStyle,
                                                                                        fontSize: "12px",
                                                                                    }}
                                                                                >
                                                                                    {index1 + 1}.
                                                                                </Typography>
                                                                                <Typography
                                                                                    style={{
                                                                                        ...tableCellbodyStyle,
                                                                                        fontSize: "12px",
                                                                                        whiteSpace: "nowrap",
                                                                                        overflow: "hidden",
                                                                                        textOverflow: "ellipsis",
                                                                                        width: "170px"
                                                                                    }}
                                                                                >
                                                                                    {k.resp}
                                                                                </Typography>
                                                                                <Stack direction="row">
                                                                                </Stack>

                                                                            </Stack>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </CustomPopOverForTable>
                                                    </Stack>
                                                </TableCell>
                                            )}

                                            {visiblityColumnData?.showEmployeeRating && (
                                                <>
                                                    <TableCell
                                                        width="10px"
                                                        sx={{
                                                            ...tableCellbodyStyle,
                                                            wordBreak: "break-word",
                                                            background: "#ffffff",
                                                            padding: "0px 8px"
                                                        }}
                                                        align="center"
                                                    >
                                                        <div style={{ display: "inline-flex" }}>
                                                            {((j.employee_rating?.rating_rejected ? <RatingBackground style={{ color: "white", background: "#D2122E" }}>{j.employee_rating?.ratings?.rating}</RatingBackground> : ""))}
                                                        </div>
                                                    </TableCell>


                                                    <TableCell
                                                        width="200px"
                                                        sx={{
                                                            ...tableCellbodyStyle,
                                                            wordBreak: "break-word",
                                                            background: "#fffff",
                                                        }}
                                                        align="left"
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            spacing={2}>
                                                            {j.employee_rating?.rejection_reason}

                                                            {getEmployeeRejectionReasonAttachments(j?.name?._id)?.length > 0 &&
                                                                (j.employee_rating?.rating_rejected) && (
                                                                    <AttachFileIcon
                                                                        style={{
                                                                            color: "#93DCFA",
                                                                            height: "18px",
                                                                            transform: "rotate(30deg)",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        aria-describedby={"id"}
                                                                        onClick={(e: any) => {
                                                                            handleClickOpenEmployeeRejectionAttachment(e, j);
                                                                            setPopoverIndex(index);
                                                                        }}
                                                                    />
                                                                )}
                                                            <CustomPopOverForTable
                                                                id={"id"}
                                                                open={popoverIndex === index && openEmployeeRejectionAttachment}
                                                                anchorEl={anchorEmployeeRejectionAttachment}
                                                                onClose={handleCloseEmployeeRejectionAttachment}
                                                            >
                                                                <div
                                                                    // sx={{
                                                                    //   p: 2,
                                                                    //   backgroundColor: "#f8f8ff",
                                                                    // }}
                                                                    style={{
                                                                        ...tableCellbodyStyle,
                                                                        padding: "5px",
                                                                        fontSize: "12px",
                                                                        lineHeight: "20px",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            ...tableCellbodyStyle,
                                                                            fontSize: "12px",
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden",
                                                                            textOverflow: "ellipsis",
                                                                            width: "170px",
                                                                        }}
                                                                    >
                                                                        {/* Attachments: {employeeAttachments} */}
                                                                        {getEmployeeRejectionReasonAttachments(
                                                                            j?.name?._id
                                                                        )?.map((k: any, index1: any) => {
                                                                            return (
                                                                                <>
                                                                                    <Stack
                                                                                        spacing={1}
                                                                                        direction="row"
                                                                                        alignItems="center"
                                                                                    >
                                                                                        <Typography
                                                                                            style={{
                                                                                                ...tableCellbodyStyle,
                                                                                                fontSize: "12px",
                                                                                            }}
                                                                                        >
                                                                                            {index1 + 1}.
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            style={{
                                                                                                ...tableCellbodyStyle,
                                                                                                fontSize: "12px",
                                                                                                whiteSpace: "nowrap",
                                                                                                overflow: "hidden",
                                                                                                textOverflow:
                                                                                                    "ellipsis",
                                                                                                width: "170px",
                                                                                            }}
                                                                                        >
                                                                                            {k.resp}
                                                                                        </Typography>
                                                                                        <Stack direction="row">
                                                                                            {disableTextAfterSubmission ?
                                                                                                <img
                                                                                                    src={Removeattnew}
                                                                                                    style={{ cursor: "default" }}
                                                                                                /> :
                                                                                                <img
                                                                                                    src={Removeattnew}
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                    onClick={() =>
                                                                                                        deleteEmployeeMutation(
                                                                                                            {
                                                                                                                employee_id:
                                                                                                                    employee_id,
                                                                                                                objective_description: j.name._id,
                                                                                                                name: k.remove,
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            }

                                                                                        </Stack>
                                                                                    </Stack>
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Typography>
                                                                </div>
                                                            </CustomPopOverForTable>
                                                        </Stack>
                                                    </TableCell>
                                                </>
                                            )}

                                            {visiblityColumnData?.showNormalizerRating && (
                                                <>
                                                    <TableCell
                                                        width="10px"
                                                        sx={{
                                                            fontSize: "14px",
                                                            // color: "#333333",
                                                            // color: "#3e8cb5",
                                                            fontFamily: "Arial",
                                                            wordBreak: "break-word",
                                                            background: "#ffffff",
                                                            padding: "0px 2px"
                                                        }}
                                                        align="center"
                                                    >
                                                        <div style={{ display: "inline-flex" }}>
                                                            {(j.normalizer_rating?.rating_resubmitted ?
                                                                <RatingBackground style={{ color: "white", background: "grey" }}>{j.normalizer_rating?.ratings?.rating}</RatingBackground> :
                                                                j.normalizer_rating?.ratings?.rating)}
                                                        </div>
                                                    </TableCell>
                                                    {visiblityColumnData?.showHRNormalizerComments && (
                                                        <TableCell
                                                            width="200px"
                                                            sx={{
                                                                ...tableCellbodyStyle,
                                                                background: "#fbfbfb",
                                                            }}
                                                            align="left"
                                                        >
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                                spacing={2}
                                                            ><Typography style={tableCellbodyStyle} >
                                                                    {(j.normalizer_rating?.rating_resubmitted ? j.normalizer_rating?.rejection_reason : "")}
                                                                </Typography>

                                                                {getNormalizerReasonAttachments(j?.name?._id)?.length > 0 &&
                                                                    (j.normalizer_rating?.rating_resubmitted) &&
                                                                    <AttachFileIcon
                                                                        style={{
                                                                            color: "#93DCFA",
                                                                            height: "18px",
                                                                            transform: "rotate(30deg)",
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        onClick={(e: any) => {
                                                                            handleOpenNormalizerReasonAttachment(e)
                                                                            setPopoverIndex(index)
                                                                        }}
                                                                        aria-describedby={"id8"}

                                                                    />
                                                                }
                                                                <CustomPopOverForTable
                                                                    id={idNormalizerReasonAttachment}
                                                                    open={(popoverIndex === index) && openNormalizerReasonAttachment}
                                                                    anchorEl={anchorNormalizerReasonAttachment}
                                                                    onClose={handleCloseNormalizerReasonAttachment}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            ...tableCellbodyStyle,
                                                                            padding: "5px",
                                                                            fontSize: "12px",
                                                                            lineHeight: "20px",
                                                                        }}
                                                                    >
                                                                        {getNormalizerReasonAttachments(j?.name?._id)?.map((k: any, index1: any) => {
                                                                            return (
                                                                                <>
                                                                                    <Stack
                                                                                        spacing={1}
                                                                                        direction="row"
                                                                                        alignItems="center"
                                                                                    >

                                                                                        <Typography
                                                                                            style={{
                                                                                                ...tableCellbodyStyle,
                                                                                                fontSize: "12px",
                                                                                            }}
                                                                                        >
                                                                                            {index1 + 1}.
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            style={{
                                                                                                ...tableCellbodyStyle,
                                                                                                fontSize: "12px",
                                                                                                whiteSpace: "nowrap",
                                                                                                overflow: "hidden",
                                                                                                textOverflow: "ellipsis",
                                                                                                width: "170px"
                                                                                            }}
                                                                                        >
                                                                                            {k.resp}
                                                                                        </Typography>
                                                                                        <Stack direction="row">

                                                                                        </Stack>

                                                                                    </Stack>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </CustomPopOverForTable>
                                                            </Stack>
                                                        </TableCell>
                                                    )}
                                                </>
                                            )}



                                        </TableRow >
                                    </>

                                )
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
            </div >
        </>
    )
}

export default RatingTable