import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, Breadcrumbs } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ExpandNineBox from "../../../../components/Dashboard_Reusable_Component/ExpandNineBox";
import { useLoggedInUser } from "../../../../hooks/useLoggedInUser";
import { useGetNineboxQuery } from "../../../../service/ninebox/ninebox";
import {  useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import OverallDashboardSolidTalents from "../../../../components/Dashboard_Reusable_Component/OverallDashboardSolidTalents";

export default function ExpandNineBoxandSolidtalentsofOverallDashboard(props: any) {
  const {NineBoxData,setNineboxExpand,indexBasedTitle,setindexBasedTitle,indexBasedValue,
    setindexBasedValue,setSolidperformersExpandActive,navigationFrom} = props
  // const { data: user } = useLoggedInUser();
  const location = useLocation();
  const { state }: { state: any } = useLocation();
  const { data: RangeValue } = useGetNineboxQuery("");
  const navigate = useNavigate();
  const [indexValue, setindexValue] = React.useState<any>(null);
  // function for ExpandNineBox setting the Employees
  useEffect(() => {
   if (indexValue == 0) {
      setindexBasedValue(NineBoxData[0]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[0]?.title)
    } else if (indexValue == 1) {
      setindexBasedValue(NineBoxData[1]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[1]?.title)
    } else if (indexValue == 2) {
      setindexBasedValue(NineBoxData[2]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[2]?.title)
    } else if (indexValue == 3) {
      setindexBasedValue(NineBoxData[3]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[3]?.title)
    } else if (indexValue == 4) {
      setindexBasedValue(NineBoxData[4]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[4]?.title)
    } else if (indexValue == 5) {
      setindexBasedValue(NineBoxData[5]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[5]?.title)
    } else if (indexValue == 6) {
      setindexBasedValue(NineBoxData[6]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[6]?.title)
    } else if (indexValue == 7) {
      setindexBasedValue(NineBoxData[7]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[7]?.title)
    } else if (indexValue == 8) {
      setindexBasedValue(NineBoxData[8]?.category)
      setindexBasedTitle(RangeValue?.data[0]?.box_9_definitions[8]?.title)
    }
  }, [indexValue,indexBasedTitle])
  // function for ExpandNineBox setting the Employees
  const handleNavigationForNineBoxEmployee = (name: any) => {
    setSolidperformersExpandActive(true);
    setNineboxExpand(false);
  }
  const handleBack = () =>{
    setNineboxExpand(false);
    if(navigationFrom == "Normalizer"){
    navigate("/normalizer") 
  }else if(navigationFrom == "PAADMINOverallDashboard"){
    navigate("/PA_Dashboard") 
  }else if (navigationFrom == "Reviewer"){
    navigate("/reviewer") 
  }
  }
  return (
    <>
      <div
        style={{
          background: "#f1f1f1",
          minHeight: "100px",
          overflow: "hidden",
          height: "auto",
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
          minHeight="50px"
          marginLeft="25px"
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
                cursor: "pointer"
              }}
              color="inherit"
              onClick={() => handleBack()}
            >
              Overall Dashboard
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
              color="text.primary"
            >
              9-Box Grid
            </Typography>
          </Breadcrumbs>
        </Stack>
        <div>
          <Box
            style={{
              marginLeft: "25px",
              marginRight: "25px",
              background: "#ffffff",
              padding: "20px",
              height: "auto",
              paddingBottom: "50px",
              marginBottom: "25px"
            }}
          >
            <Grid container spacing={2}>
              <Grid sx={{ paddingRight: "16px" }} item xs={12} md={12} lg={8}>
                <ExpandNineBox nineboxValues={NineBoxData} BaseStateValue={indexBasedTitle} setindexValue={setindexValue} />
              </Grid>
              <Grid
                sx={{
                  borderLeft: "1px solid #EEEEEE",
                  '@media (max-width: 1024px)': {
                    borderLeft: "none",
                    marginTop: "20px"
                  }
                }} item xs={12} md={12} lg={4}>
                <OverallDashboardSolidTalents
                  setindexBasedValue={setindexBasedValue}
                  indexBasedValue={indexBasedValue}
                  BaseStateValue={indexBasedTitle}
                  indexValue={indexValue}
                  setindexBasedTitle={setindexBasedTitle}
                  indexBasedTitle={indexBasedTitle}
                  handleNavigationForNineBoxEmployee={handleNavigationForNineBoxEmployee}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
}
