import React from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from 'react';

interface ApexChartProps { }
interface ApexChartState {
  options?: any;
  series?: any;
}
const GlobeChart = (props: any) => {
  const { completedCount, inprogressCount, inMediaton,navigationRole } = props;
  console.log(navigationRole,completedCount, inprogressCount, inMediaton, "the counts")
  const theme = useTheme();
  const [action, setAction] = useState<any>(0)
  const [action1, setAction1] = useState<any>(0)
  const [action2, setAction2] = useState<any>(0)
  const [Totalemp, setTotalemp] = useState<any>(0)
  useEffect(() => {
    if (navigationRole == 'PreviousDashboard'){
      setAction(completedCount)
      setAction1(0)
      setAction2(0)
    }else{
      setAction(completedCount)
      setAction1(inprogressCount)
      setAction2(inMediaton)
    }
    setTotalemp(completedCount + inprogressCount + inMediaton)
  })
  console.log(action, action1, action2, Totalemp, `${action + action1 + action2}`, "TotalEmpp")
  let options: ApexOptions;
  if (navigationRole === 'PreviousDashboard') {
   options = {
    colors: ["#39ac73"],
    labels: ["Completed"],
    theme: {
      monochrome: {
        enabled: false,
      },
    },
    responsive: [
      {
        //breakpoint: 480,
        options: {
          chart: {
            width: "100%",
            type: "donut",
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          console.log(config?.w?.config?.labels[config?.dataPointIndex]);
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (value: any, { seriesIndex, dataPointIndex, w }) {
        console.log(w.config.series[seriesIndex], "series")
        return w.config.series[seriesIndex]
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          size: "50%",
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontSize: '16px',
              fontFamily: ' Arial',
              color: '#333333',
              formatter: () => `${completedCount}/${completedCount}`
            }
          }
        }
      }
    }
  };
}else {
   options = {
    colors: ["#39ac73", "#ff884d", "#FF0000"],
    labels: ["Completed", "In Re-normalization", "In mediation"],
    theme: {
      monochrome: {
        enabled: false,
      },
    },
    responsive: [
      {
        options: {
          chart: {
            width: "100%",
            type: "donut",
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          console.log(config?.w?.config?.labels[config?.dataPointIndex]);
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (value: any, { seriesIndex, dataPointIndex, w }) {
        console.log(w.config.series[seriesIndex], "series")
        return w.config.series[seriesIndex]
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          size: "50%",
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontSize: '16px',
              fontFamily: ' Arial',
              color: '#333333',
              formatter: () => `${completedCount}/${completedCount + inprogressCount + inMediaton}`
            }
          }
        }
      }
    }
  };
}
  const series = [1, 1, 1];
  //chart when count is zero
  const zeroSeries = [0, 0, 0, 1];
  const zeroOptions: ApexOptions = {
    colors: ["#abb0b0"],
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
      formatter: function (value: any, { seriesIndex, dataPointIndex, w }) {
        console.log(w.config.series[seriesIndex], "series")
        return w.config.series[seriesIndex]
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "50%",
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontSize: '16px',
              fontFamily: ' Arial',
              color: '#333333',
              formatter: () => `${completedCount}/${completedCount + inprogressCount + inMediaton}`
            },
          }
        },
      }
    }
  };
  return (
    <div>
      {completedCount === 0 && inprogressCount === 0 && inMediaton === 0 &&
        <ReactApexChart
          options={zeroOptions}
          series={zeroSeries}
          type="donut"
          width="270"
        />
      }
      {(completedCount !== 0 || inprogressCount !== 0 || inMediaton !== 0) &&
        <ReactApexChart
        key={`${completedCount}-${ completedCount + inprogressCount +  inMediaton}`}
          options={options}
          series={[action, action1, action2]}
          type="donut"
          width="270"
        />}
    </div>
  );
};

export default GlobeChart;
