import React from "react"
import { Box, Grid, IconButton, MenuItem,styled, Popover, Select, Stack, Typography } from '@mui/material';
import Avatar from "@mui/material/Avatar";
import Infoicon from "../../../assets/Images/Infoicon.svg";
import Divider from '@mui/material/Divider';

const Item = styled(Box)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const Overallrating = styled("div")({
    fontSize: "17px",
    fontFamily: "arial",
    fontWeight: 400,
    color: "#3e8cb5",
    //textAlign: 'left'
  });
  
  const Overallratingvalue = styled("div")({
    fontSize: "17px",
    fontFaily: "arial",
    display: "flex",
    alignItems: "center"
  });
  const Name = styled("div")({
    fontSize: "17px",
    fontWeight: 400,
    color: "#3e8cb5",
    fontFamily: "Arial",
    textAlign: "left",
  });
  
  const Speciality = styled("div")({
    fontSize: "12px",
    fontWeight: 400,
    color: "#333333",
    opacity: 0.5,
    textAlign: "left",
  });
  
  const Pastratingvalue = styled("div")({
    fontSize: "12px",
    fontWeight: 400,
    opacity: 0.5,
    color: "#333333",
    // paddingTop:'2px',
    textAlign: "left",
    // paddingBottom: "5px",
  });
  const Dividerroot = styled("div")({
    "& .MuiDivider-root": {
      marginTop: "10px",
      marginBottom: "15px",
      marginLeft: "0px",
    },
  });
const JoiningDate = (props: any) => {
    const { employeeDetails } = props
    const inputDate = employeeDetails?.JoiningDate
    let formattedDate;
    if (inputDate && typeof inputDate === 'string') {
      const JoiningDate = inputDate.replace("+", "");
       const dateParts = JoiningDate.split("-");
      if (dateParts.length >= 3) {
          const year = dateParts[0];
          const month = new Date(inputDate).toLocaleString("default", { month: "short" });
          const day = dateParts[2].slice(0, 2);
  
          formattedDate = `${day}-${month}-${year}`;
          console.log(formattedDate,"formattedDate"); // Output formatted date                           
      }else {
        console.log("JoiningDate does not have enough parts to split.");
    }
    }else {
      console.log("JoiningDate is not defined or not a valid string.");
  }

    return (
        <>

               <Dividerroot>
              <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
                // spacing={2}
              >
                  <Box>

                    <Stack direction="row" spacing={2}
                      //  divider={<Divider orientation="vertical" flexItem />}
                       >
                    <Typography style={{ fontSize: "16px", color: "#3E8CB5", fontFamily: "Arial" }}>
      Joining Date
    </Typography>
    <Typography style={{ color: "#717171", fontSize: "16px", fontFamily: "Arial" }}>
      {formattedDate ? formattedDate : "-"}
    </Typography>
    <span style={{borderRight:"2px solid #60606087",
      marginBottom:"5px",marginTop:"5px"
    }}></span>
    {/* <Divider orientation="vertical" flexItem style={{ border:"4px solid red"
    }}/> */}
     {/* divider={<Divider orientation="vertical" flexItem />} */}
    <Typography style={{ color: "#3E8CB5", fontSize: "16px", fontFamily: "Arial" }}>
      Tenure
    </Typography>
    <Typography style={{ color: "#717171", fontSize: "16px", fontFamily: "Arial" }}>
      {employeeDetails?.Tenure ? employeeDetails.Tenure : "-"}
    </Typography>
                    </Stack>
                  </Box>


                  <Stack
                    direction="row"
                    // alignItems="center"
                    spacing={2}
                  >
                <Typography
                    style={{
                        fontSize: "16px",
                        color: "#3E8CB5",
                        fontFamily: "Arial",
                    }}
                >
                    Department
                </Typography>
                <Typography
                    style={{
                        color: "#717171",
                        // marginTop: "8px",
                        fontSize: "16px",
                        fontFamily: "Arial",
                    }}
                >
                    {employeeDetails?.Department ?  employeeDetails?.Department : "-"}
                </Typography>
                <span style={{borderRight:"2px solid #60606087",
      marginBottom:"5px",marginTop:"5px"
    }}></span>
                <Typography
                    style={{
                        fontSize: "16px",
                        color: "#3E8CB5",
                        fontFamily: "Arial",
                        // marginTop:"20px"
                    }}
                >
                    Department Head
                </Typography>
                <Typography
                    style={{
                        color: "#717171",
                        // marginTop: "8px",
                        fontSize: "16px",
                        fontFamily: "Arial",
                    }}
                >
                    {employeeDetails?.DepartmentHead ?  employeeDetails?.DepartmentHead : "-"}
                </Typography>


                  </Stack>
              </Stack>
            </Dividerroot>
        </>
    )
}
export default JoiningDate