import { pmsApi } from "../root";

const PERFORMANCE_GOALS_URL = `/api/v1/performanceGoals`

const apiWithTag = pmsApi.enhanceEndpoints({ addTagTypes: ['performanceGoals'] })


const performanceGoalsAPI = apiWithTag.injectEndpoints({

    endpoints: (builder) => ({

        getPerformanceGoals: builder.query<any, any>({
            query: () => ({
                url: `${PERFORMANCE_GOALS_URL}`,
                method: 'GET',
            }),
            providesTags: ['performanceGoals'],
        }),

        

       
        
 


})
    })

export const {
    useGetPerformanceGoalsQuery
} = performanceGoalsAPI

