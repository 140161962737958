import {CLIENT_URL} from "./constants/URL";

// const config = {
//     appId: '45ceb759-f44c-44ce-9ff7-220492887e36',
//     tenantId: "45ceb759-f44c-44ce-9ff7-220492887e36",
//     redirectUri: CLIENT_URL,
//     scopes: [
//         'user.read',
//     ]
// };

// const config = {
//     appId: 'e820d914-2b75-4aa7-8978-52674959c201',
//     tenantId: "1375a557-ba94-4f19-bb8e-d9567d7d05e4",
//     redirectUri: CLIENT_URL,
//     scopes: [
//         'user.read',
//     ]
// };

const config = {
    appId: '2e444a5e-c0ed-4e37-a98a-256b382f924e',
    tenantId: "03c1c77b-914a-4687-905b-9676da5d8d60",
    redirectUri: CLIENT_URL,
    scopes: [
        'user.read',
    ]
};


export default config;
