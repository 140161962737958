import * as React from "react";
import Grid from "@mui/material/Grid";
import { Container, Box, IconButton, Breadcrumbs } from "@mui/material";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { useGetEmployeeByFilterQuery, useGetReviewerEmployeeByFilterQuery } from "../../../../service";
import FilteredExpandtable from "../../../../components/Dashboard_Reusable_Component/GlobeChartChildExpandtable";
import { useLoggedInUser } from "../../../../hooks/useLoggedInUser";
import { useState, useRef, useEffect } from "react";
import { useGetPreviousAppraisalEmployeeByFilterQuery } from "../../../../service/employee/previousAppraisal";
import FilteredExpandtableClosed from "../../../../components/homepage/PreviousDashboardComponents/GlobeCharts/GlobeChartforchildClosed";

export default function GlobechartparentforCloseddashboard(props: any) {
  const location: any = useLocation();
  let navigationFrom = "PreviousDashboard";
  const tabLocation = location?.state?.from;
  console.log(tabLocation, "tabLocation1");
  // name:{valueOfActiveCalender},
  const CalendarName = location?.state?.name?.valueOfActiveCalender
  // LOCATION_FROM
  const LOCATION_FROM = location?.state?.LOCATION_FROM
  console.log(CalendarName, "CalendarName")
  const rangeLow = location?.state?.range?.checklengthinRangesL
  const rangeGood = location?.state?.range?.checklengthinRangesG
  const rangeHigh = location?.state?.range?.checklengthinRangesH
  const rangeAverage = location?.state?.range?.checklengthinRangesA
  const statuscompleted = location?.state?.status?.checkAppraisalStatusCompleted
  const statusinmediation = location?.state?.status?.checkAppraisalStatusInmediation
  const statusinrenormalizetion = location?.state?.status?.checkAppraisalStatusRenormalization
  const Appcalid = location?.state?.name?.valueOfActiveCalender
  console.log(statuscompleted, rangeGood, rangeHigh, rangeAverage, Appcalid, "tabLocation");
  const { data: user } = useLoggedInUser();
  const navigate = useNavigate();

  console.log(location?.state?.name?.valueOfActiveCalender, "location")
  const [appCalId, setappCalId] = React.useState<any>(`636cb1a7960b1548b80ff785`);
  const [expand, setexpand] = React.useState<any>("");

  const [titleDisplay, setTitleDisplay] = React.useState<any>("Employees Rejection");
  const [headersToDisplayWhenInMediation, setheadersToDisplayWhenInMediation] = React.useState<any>(false);
  const [headersToDisplayWhenRenormalization, setheadersToDisplayWhenRenormalization] = React.useState<any>(false);
  const [headersToDisplayWhenCompleted, setheadersToDisplayWhenCompleted] = React.useState<any>(false);
  useEffect(() => {
    if (tabLocation === "Completed") {
      setheadersToDisplayWhenCompleted(true)
    } else if (tabLocation === "In Re-normalization") {
      setheadersToDisplayWhenRenormalization(true)
    } else if (tabLocation === "In-Mediation") {
      setheadersToDisplayWhenInMediation(true)
    }
    else {
      setTitleDisplay("Ratings Distribution")
    }
  }, [tabLocation])
  const [users, setUsers] = useState<any>([]);
  const [hideEmployeeRating, sethideEmployeeRating] = React.useState<any>(false);
  useEffect(() => {
    if (tabLocation == "High Performers") {
      setexpand("expandableviews")
      setUsers(rangeHigh)
    } else if (tabLocation == "Good Performers") {
      setexpand("expandableviews")
      setUsers(rangeGood)
    } else if (tabLocation == "Average Performers") {
      setexpand("expandableviews")
      setUsers(rangeAverage)
    } else if (tabLocation == "Low Performers") {
      setUsers(rangeLow)
      setexpand("expandableviews")
    }
  }, [tabLocation]);
  useEffect(() => {
    if (tabLocation === "In Re-normalization") {
      setUsers(statusinrenormalizetion)
      setexpand("expandableviews")
      sethideEmployeeRating(true);
    } else if (tabLocation === "Completed") {
      setUsers(statuscompleted)
      setexpand("expandableviews")
      sethideEmployeeRating(true);
    } else if (tabLocation === "In-Mediation") {
      setUsers(statusinmediation)
      setexpand("expandableviews")
      sethideEmployeeRating(true);
    }
  }, [tabLocation]);

  return (
    <div
      style={{
        background: "#F1F1F1",
        height: "auto",
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        minHeight="50px"
        marginLeft="25px"
      >
        {LOCATION_FROM == "PreviousDashboardAppraiser" &&
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/ClosedcalendarDashboardAppraiser"}
              state={{
                from: { expand },
              }}
            >
              Previous PA
            </Link>
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/ClosedcalendarDashboardAppraiser"}
            >
              {titleDisplay}
            </Link>
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
              color="text.primary"
            >
              {tabLocation == "In Re-normalization" ? "Re-normalization" :tabLocation }
            </Typography>
          </Breadcrumbs>
        }
        {LOCATION_FROM == "PreviousDashboardNormalizer" &&
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
                cursor: "pointer"
              }}
              onClick={() => {
                navigate(-1)
              }}
            >
              Previous PA
            </Typography>
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/ClosedcalendarDashboardNormalizer"}
            >
              {titleDisplay}
            </Link>
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
              color="text.primary"
            >
              {tabLocation}
            </Typography>
          </Breadcrumbs>
        }
        {LOCATION_FROM == "PreviousDashboardReviewer" &&
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/ClosedcalendarDashboardReviewer"}
              state={{
                oldappCalId: { appCalId }
              }}
            >
              Previous PA
            </Link>
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/ClosedcalendarDashboardReviewer"}
              state={{
                oldappCalId: { appCalId }
              }}
            >
              {titleDisplay}
            </Link>
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
              color="text.primary"
            >
              {tabLocation}
            </Typography>
          </Breadcrumbs>
        }
      </Stack>
      <div >
        {/* <FilteredExpandtableClosed
        navigationFrom = {navigationFrom}
          CalendarName={CalendarName}        
          EmployeeData={users}
          hideEmployeeRating={hideEmployeeRating}
          headersToDisplayWhenInMediation={headersToDisplayWhenInMediation}
          headersToDisplayWhenRenormalization={headersToDisplayWhenRenormalization}
          headersToDisplayWhenCompleted={headersToDisplayWhenCompleted}
        /> */}
        <FilteredExpandtable
          CalendarName={CalendarName}
          navigationFrom={navigationFrom}
          EmployeeData={users}
          hideEmployeeRating={hideEmployeeRating}
          headersToDisplayWhenInMediation={headersToDisplayWhenInMediation}
          headersToDisplayWhenRenormalization={headersToDisplayWhenRenormalization}
          headersToDisplayWhenCompleted={headersToDisplayWhenCompleted}
        />
      </div>
    </div>
  );
}
