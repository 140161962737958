import * as React from "react";
import { Breadcrumbs } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import TopperformerchildExpandView from "../../../../components/Dashboard_Reusable_Component/TopperformerChildExpandview";


export default function TopPerformersparentforCloseddashboard(props: any) {
  const location  = useLocation();
  const { state }: { state: any } = useLocation();
  const CalendarName =state?.valueOfActiveCalender
  const navigate = useNavigate();
  let navigationFrom = "PreviousDashboard";
  const topPerformerEmployees = state?.topPerformerEmployees
  console.log(location,topPerformerEmployees, "location")

  const handleGoBack = () => {
   navigate(-1)
  };
  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        minHeight="50px"
        marginLeft="25px"
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            style={{
              fontSize: "18px",
              color: "#3e8cb5",
              fontFamily: "Arial",
              cursor:"pointer"
            }}
            color="text.primary"
            onClick={handleGoBack}
            >
            Previous PA
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              color: "#333333",
              fontFamily: "Arial",
            }}
            color="text.primary"
          >
            Top Performers
          </Typography>
        </Breadcrumbs>
      </Stack>
      <TopperformerchildExpandView 
      navigationFrom={navigationFrom}
      topPerformerEmployees={topPerformerEmployees}
      CalendarName={CalendarName}/>
    </>
  );
}
