import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Checkbox, Drawer, FormControlLabel, FormGroup, IconButton, Stack, Box, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Deleteicon from "../../assets/Images/Deleteicon.svg";
import Closeicon from "../../assets/Images/Closeicon.svg";
import { borderRadius, display } from "@mui/system";
import Scrollbar from "react-scrollbars-custom";
import { styled } from "@mui/material/styles";

const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
  "& .ScrollbarsCustom-Track": {

    //width:"10px !important"
  },
});
const ExportToexcel: React.FC<any> = (props: any) => {
  const {
    isDrawerOpen,
    setisDrawerOpen,
    handleSelectAll,
    columnHeaders,
    checkedCount,
    totalCount,
    handleheadingCalendar,
    handleEmployeeCode,
    handleheading1,
    handlefirstName,
    handleServiceReferenceDateData,
    handleheading2,
    handleheading3,
    handleFunctionData,
    handleSupervisoryRoleData,
    handleEmailIDData,
    handleDivision,
    handleSection,
    handleSubSection,
    handleWorkLocation,
    handleAppCodes,
    handleAppraiserName,
    handleRevCodes,
    handleReviewerName,
    handleNorCodes,
    handleNormalizerName,
    handleemployeerating,
    handleAppraiserRating,
    handleReviewerRating,
    handleNormalizerRating,
    handleOverallRating,
    handlePreviousRating,
    handlePotentialLevel,
    handleTalentCategory,
    handleManagerCode,
    handleManagerName,
    handleManagerPosition,
    handleStatus,
    handleheadingSortAccept,
    handlePendingAction,
    handleCloseGrade,
    handleWorkflowHistory
  } = props;
  const CustomScrollbar = Scrollbar as any;
// const checkedCount1 = checkedCount  == 32 ? 34 : checkedCount

console.log(columnHeaders, 'checkcolumnheaders')
  return (

    <>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => {
          setisDrawerOpen(false);
        }}
      >
        <Box sx={{ paddingLeft: "10px" }}>
          <Typography
            style={{
              fontSize: "16px",
              color: "#333333",
              fontFamily: "Arial",
              marginLeft: "-10px"
            }}
            variant="subtitle1"
          // align="center"
          >
            <Checkbox
              checked={checkedCount === totalCount}
              onChange={(event) => handleSelectAll(event.target.checked)}
            />
            Choose Fields
          </Typography>
          <Stack direction="column" width="250px">
            <Scroll>
              <CustomScrollbar
                style={{
                  height: "calc(100vh - 100px)",
                  // "& .ScrollbarsCustom-Track": {
                  //     width:"5px"
                  //   },
                }}>
                <div>
                  <FormGroup>

                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.CalendarName}
                          name="CalendarName"
                          onChange={handleheadingCalendar} />
                      }
                      label="Calendar Name"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.Ecode}
                          name="Ecode"
                          onChange={handleEmployeeCode} />
                      }
                      label="Ecode"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.Ename}
                          name="Ename"
                          onChange={handleheading1} />
                      }
                      label="Employee Name"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.Firstname}
                          name="Firstname"
                          onChange={handlefirstName} />
                      }
                      label="Known As"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.ServiceReferenceDate}
                          name="ServiceReferenceDate"
                          onChange={handleServiceReferenceDateData} />
                      }
                      label="Service Reference Date"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.Eposition}
                          name="Eposition"
                          onChange={handleheading2} />
                      }
                      label="Position"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.EGrade}
                          name="EGrade"
                          onChange={handleheading3}
                        />
                      }
                      label="Grade"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.Function}
                          name="Function"
                          onChange={handleFunctionData} />
                      }
                      label="Function"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.SupervisoryRole}
                          name="SupervisoryRole"
                          onChange={handleSupervisoryRoleData} />
                      }
                      label="Supervisory Role"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.EmailID}
                          name="EmailID"
                          onChange={handleEmailIDData} />
                      }
                      label="Email ID"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.EDivision}
                          name="EDivision"
                          onChange={handleDivision} />
                      }
                      label="Company"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.ESection}
                          name="ESection"
                          onChange={handleSection} />
                      }
                      label="Section"
                    />


                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.ESubSection}
                          name="ESubSection"
                          onChange={handleSubSection} />
                      }
                      label="Sub-section"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.WorkLocation}
                          name="WorkLocation"
                          onChange={handleWorkLocation} />
                      }
                      label="Work Location"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.appraiserCode}
                          name="appraiserCode"
                          onChange={handleAppCodes} />
                      }
                      label="Appraiser Code"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.AppraiserName}
                          name="AppraiserName"
                          onChange={handleAppraiserName} />
                      }
                      label="Appraiser Name"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.reviewerCode}
                          name="reviewerCode"
                          onChange={handleRevCodes} />
                      }
                      label="Reviewer Code"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.ReviewerName}
                          name="ReviewerName"
                          onChange={handleReviewerName} />
                      }
                      label="Reviewer Name"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.normalizerCode}
                          name="normalizerCode"
                          onChange={handleNorCodes} />
                      }
                      label="HR Normalizer Code"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.NormalizerName}
                          name="NormalizerName"
                          onChange={handleNormalizerName} />
                      }
                      label="HR Normalizer Name"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.employeerating}
                          name="employeerating"
                          onChange={handleemployeerating} />
                      }
                      label="Employee Rating "
                    />
                    {/* handleemployeerating */}

                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.appraiserRating}
                          name="appraiserRating"
                          onChange={handleAppraiserRating} />
                      }
                      label="Appraiser Rating"
                    />

                    {/* <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.reviewerRating}
                          name="reviewerRating"
                          onChange={handleReviewerRating} />
                      }
                      label="Reviewer Rating"
                    /> */}

                    {/* <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.normalizerRating}
                          name="normalizerRating"
                          onChange={handleNormalizerRating} />
                      }
                      label="HR Normalizer Rating"
                    /> */}
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.OverallRating}
                          name="OverallRating"
                          onChange={handleOverallRating} />
                      }
                      label="Overall Rating"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.PreviousRating}
                          name="PreviousRating"
                          onChange={handlePreviousRating} />
                      }
                      label="Previous Period Rating"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.PotentialLevel}
                          name="PotentialLevel"
                          onChange={handlePotentialLevel} />
                      }
                      label="Potential Level"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.TalentCategory}
                          name="TalentCategory"
                          onChange={handleTalentCategory} />
                      }
                      label="Talent Category"
                    />

                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.ManagerCode}
                          name="ManagerCode"
                          onChange={handleManagerCode} />
                      }
                      label="Manager Code"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.ManagerName}
                          name="ManagerName"
                          onChange={handleManagerName} />
                      }
                      label="Manager Name"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.ManagerPosition}
                          name="ManagerPosition"
                          onChange={handleManagerPosition} />
                      }
                      label="Manager Position"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.status}
                          name="status"
                          onChange={handleStatus} />
                      }
                      label="Status"
                    />
                     <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.PendingAction}
                          name="PendingAction"
                          onChange={handlePendingAction} />
                      }
                      label="Pending Action"
                    />
                      <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={columnHeaders.workflowHistory}
                          name="workflowHistory"
                          onChange={handleWorkflowHistory} />
                      }
                      label="Workflow History"
                    />
                  </FormGroup>
                </div>
              </CustomScrollbar>
            </Scroll>
            <Stack
              direction="row"
              spacing={2}
              paddingBottom="10px"
              paddingTop="20px"
              justifyContent="center"
            >
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  color: "#3E8CB5",
                  background: "transparent",
                  width: "70px",
                  height: "35px",
                }}
                variant="outlined"
                onClick={() => {
                  handleheadingSortAccept();
                }}
              >
                Apply
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  color: "#3E8CB5",
                  background: "transparent",
                  width: "70px",
                  height: "35px",
                }}
                variant="outlined"
                onClick={() => {
                  handleCloseGrade();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default ExportToexcel;
