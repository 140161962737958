import * as React from "react";
import { Breadcrumbs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FilteredExpandtable from "../../../../components/Dashboard_Reusable_Component/GlobeChartChildExpandtable";
import { useLoggedInUser } from "../../../../hooks/useLoggedInUser";
import { useState, useEffect, useContext } from "react";
import AppraiserDashboardContext from '../../../../components/reviewer/Dashboard/AppraiserDashboardContext';
import FilteredExpandTableofOverallDashboard from "../../../../components/homepage/OverallDashboardComponents/GlobeChart/GlobeChartChildforOverallDashboard";

export default function GlobechartParentforoverallDashboard(props: any) {
  const {
    FinalData, selectedRatingRange, setRatingsExpandActive, setselectedRatingRange
  } = props;
  const location: any = useLocation();
  const tabLocation = location?.state?.from;
  //added to fix the no data issue(27/9/2023)
  // navigationFrom used for breadcrumb
  const navigationFrom = location?.state?.navigationFrom
  const rangeLow = location?.state?.range?.checklengthinRangesL
  const rangeGood = location?.state?.range?.checklengthinRangesG
  const rangeHigh = location?.state?.range?.checklengthinRangesH
  const rangeAverage = location?.state?.range?.checklengthinRangesA
  const statuscompleted = location?.state?.status?.checkAppraisalStatusCompleted
  const statusinmediation = location?.state?.status?.checkAppraisalStatusInmediation
  const statusinrenormalizetion = location?.state?.status?.checkAppraisalStatusRenormalization
  const navigate = useNavigate();
  const [users, setUsers] = useState<any>([]);
  const [hideEmployeeRating, sethideEmployeeRating] = React.useState<any>(false);
  const [titleDisplay, setTitleDisplay] = React.useState<any>("Employees Rejection");
  const [headersToDisplayWhenCompleted, setheadersToDisplayWhenCompleted] = React.useState<any>(false);
  const [headersToDisplayWhenInMediation, setheadersToDisplayWhenInMediation] = React.useState<any>(false);
  const [headersToDisplayWhenRenormalization, setheadersToDisplayWhenRenormalization] = React.useState<any>(false);

  //according to my team dashboard
  useEffect(() => {
    if (tabLocation === "Completed") {
      setheadersToDisplayWhenCompleted(true)
    } else if (tabLocation === "In Re-normalization") {
      setheadersToDisplayWhenRenormalization(true)
    } else if (tabLocation === "In Mediation") {
      setheadersToDisplayWhenInMediation(true)
    } else {
      setTitleDisplay("Ratings Distribution")
    }
  }, [tabLocation])
  useEffect(() => {
    if (tabLocation == "High Performers") {
      setUsers(rangeHigh)
    } else if (tabLocation == "Good Performers") {
      setUsers(rangeGood)
    } else if (tabLocation == "Average Performers") {
      setUsers(rangeAverage)
    } else if (tabLocation == "Low Performers") {
      setUsers(rangeLow)
    }
  }, [tabLocation]);
  useEffect(() => {
    if (tabLocation === "In Re-normalization") {
      setUsers(statusinrenormalizetion)
      sethideEmployeeRating(true);
    } else if (tabLocation === "Completed") {
      setUsers(statuscompleted)
      sethideEmployeeRating(true);
    } else if (tabLocation === "In Mediation") {
      setUsers(statusinmediation)
      sethideEmployeeRating(true);
    }
  }, [tabLocation]);

  const handleBack = () => {
    if (navigationFrom == "NormalizerOverallDashboard") {
      navigate("/normalizer")
    } else if (navigationFrom == "ReviewerOverallDashboard") {
      navigate("/reviewer")
    } else if (navigationFrom == "PAADMINOverallDashboard") {
      navigate("/PA_Dashboard")
    }
    setRatingsExpandActive(false);
  }
  return (
    <div
      style={{
        background: "#F1F1F1",
        height: "auto",
      }}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        minHeight="50px"
        marginLeft="25px"
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            style={{
              fontSize: "18px",
              color: "#3e8cb5",
              fontFamily: "Arial",
              cursor: "pointer"
            }}
            color="inherit"
            onClick={() => handleBack()}
          >
            Overall Dashboard
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              color: "#3e8cb5",
              fontFamily: "Arial",
              cursor: "pointer"
            }}
            color="text.primary"
            onClick={() => handleBack()}
          >
            {titleDisplay}
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              color: "#333333",
              fontFamily: "Arial",
            }}
            color="text.primary">
            {/* {tabLocation} */}
            {tabLocation == "In Re-normalization" ? "Re-normalization" :tabLocation }
          </Typography>
        </Breadcrumbs>
      </Stack>
      <div >
        <AppraiserDashboardContext>
          {/* <FilteredExpandTableofOverallDashboard
      EmployeeData = {users}
      hideEmployeeRating={hideEmployeeRating}
      selectedRatingRange={selectedRatingRange}
      headersToDisplayWhenInMediation={headersToDisplayWhenInMediation}
      headersToDisplayWhenRenormalization={headersToDisplayWhenRenormalization}
      headersToDisplayWhenCompleted={headersToDisplayWhenCompleted}
      /> */}
          <FilteredExpandtable
            EmployeeData={users}
            hideEmployeeRating={hideEmployeeRating}
            headersToDisplayWhenInMediation={headersToDisplayWhenInMediation}
            headersToDisplayWhenRenormalization={headersToDisplayWhenRenormalization}
            headersToDisplayWhenCompleted={headersToDisplayWhenCompleted}
          />
        </AppraiserDashboardContext>
      </div>
    </div>

  );
}
