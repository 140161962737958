import { Box, Checkbox, FormControlLabel, Grid, TextField, styled } from '@mui/material';
import React from 'react'


const Typo7 = styled("div")({
    // marginLeft: "25px",
    // position: "absolute",
    marginTop: "20px",
    color: "#717171",
    fontSize: "16px",
    fontFamily: "arial",
    marginBottom: "10px",
});

const Labels = styled("div")({
    fontSize: "14px",
    color: "#333333",
    // opacity: 0.84,
    marginLeft: "5px",
    fontFamily: "arial",
    lineHeight: "1.5"
});

const Contain2 = styled("div")({
    // marginRight: "35px",  
    marginTop: "-15px",
});

const Tf1 = styled("div")({
    // marginLeft: "25px",
    marginTop: "10px",
    // marginRight: "34px",
    backgroundColor: "white",
    borderRadius: "5px",
    "& .MuiInputBase-input": {
        color: "#333333",
        fontSize: "14px",
        fontFamily: "arial",
        fontWeight: "400",
        textTransform: "none",
        // padding: "4px",
        textAlign: "left",
    },
});


const AppraiserFurtherRecommendationView = (props: any) => {

    const { furtherRecommendation, furtherRecommendationOthers, showIfpreviousappraisal, furtherRecommendationOthersCheckbox } = props;
    console.log(furtherRecommendationOthers, "furtherRecommendationOthers")
    return (
        <>
            {furtherRecommendation?.length > 0 || furtherRecommendationOthers && furtherRecommendationOthers !== "" || furtherRecommendationOthers && furtherRecommendationOthers !== undefined ? (
                <div style={{ marginBottom: "20px" }}>

                    <Typo7>
                        <b>Recommendations</b>
                    </Typo7>


                    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                        {furtherRecommendation?.map((i: any, index: any) => {
                            return (

                                <Grid alignItems="center" display="flex" item xs={2} sm={4} md={3} key={index}>

                                    <input type="checkbox" checked />
                                    <Labels>
                                        {showIfpreviousappraisal ? i?.name : i.name?.name}
                                    </Labels>
                                </Grid>

                            );
                        })}
                    </Grid>


                    {furtherRecommendationOthers && furtherRecommendationOthers !== "" && furtherRecommendationOthers !== undefined &&
                        <>

                            {furtherRecommendationOthersCheckbox == true && (<div
                                style={{
                                    marginBottom: "10px",                                  
                                    marginTop: "20px",
                                    //  marginLeft: "35px",
                                    color: "#717171",
                                    fontSize: "16px",
                                    fontFamily: "arial"
                                }}>
                                <Grid alignItems="center" display="flex" item xs={2} sm={4} md={3} >

                                    <input type="checkbox" checked  = {furtherRecommendationOthersCheckbox} />
                                    <Labels>
                                    Others
                                    </Labels>
                                </Grid>                               
                            </div>
                            )}
                            <Typo7 style={{ marginBottom: "30px" }}>
                                <b>Recommendations Justification</b>
                            </Typo7>
                            <Contain2>
                                <Tf1>
                                <Box sx={{ border: "1px solid #E0E0E0", padding: "8.5px 14px", borderRadius: "5px" }}>
                                                <div style={{
                                                    color: "#333333",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    fontWeight: "400",
                                                    textTransform: "none",
                                                    // padding: "8px",
                                                    textAlign: "left",
                                                    lineHeight: "23px"
                                                }}>
                                                    {furtherRecommendationOthers || ""}
                                                </div>
                                        {/* <TextField
                                            //  inputProps={{ maxLength: 500 }}
                                            size="small"
                                            InputProps={{ readOnly: true }}
                                            fullWidth
                                            multiline
                                            //   rows={1}
                                            value={furtherRecommendationOthers || ""}
                                        ></TextField> */}
                                    </Box>
                                </Tf1>
                            </Contain2>
                        </>}
                </div>
            ) : ""}
        </>
    )
}

export default AppraiserFurtherRecommendationView