import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'

const AreaOfImprovementView = (props: any) => {
    const {
        showIfAppraiser,
        showIfEmployee,
        areaOfImprovement,
        showIfpreviousappraisal
    } = props;
    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    height="20px"
                    marginBottom="10px"
                >
                    <div
                        style={{
                            color: "#717171",
                            fontSize: "16px",
                            fontFamily: "Arial",
                        }}
                    >
                       {showIfAppraiser && <b>Areas for Improvement (Appraiser)</b> }
                       {showIfEmployee && <b>Areas for Improvement (Employee)</b> }

                    </div>
                </Stack>


                <Table size="small" >
                    <TableHead>
                        <TableRow
                            sx={{
                                "& td, & th": {
                                    border: "1px solid #e0e0e0",
                                    bgcolor: "#eaeced",
                                },
                            }}
                        >
                            <TableCell
                                align="center"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                Specific Areas
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{
                                    fontFamily: "Arial",
                                    border: "1px solid #e0e0e0",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                Specific Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {areaOfImprovement &&
                            areaOfImprovement?.map((i: any, index: any) => {
                                return (
                                    <>
                                        <>
                                            <TableRow
                                                sx={{
                                                    "& td, & th": {
                                                        border: "1px solid #e0e0e0",

                                                    },
                                                }}
                                            >
                                                <TableCell
                                                    align="left"
                                                    // width="25%"
                                                    width="140px"
                                                    style={{
                                                        fontSize: "14px",
                                                        color: "#333333",
                                                        fontFamily: "Arial",
                                                        wordBreak: "break-word"
                                                    }}
                                                >
                                                    {showIfpreviousappraisal ?i?.specific_area : i[0]}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    // width="50%"
                                                    width="450px"
                                                    style={{
                                                        fontSize: "14px",
                                                        color: "#333333",
                                                        fontFamily: "Arial",
                                                        wordBreak: "break-word"
                                                    }}
                                                >
                                                    {showIfpreviousappraisal ? i?.specific_actions :areaOfImprovement &&
                                                        areaOfImprovement?.map((i: any, ix: any) => {
                                                            return i[1]?.map((j: any, jx: any) => {
                                                                return j.specific_actions?.map(
                                                                    (k: any, ix1: any) => {
                                                                        if (index === ix && k.value)
                                                                            return (
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: "14px",
                                                                                        color: "#333333",
                                                                                        fontFamily: "Arial",
                                                                                        wordBreak: "break-word"
                                                                                    }}
                                                                                >
                                                                                    {k.value}
                                                                                    <br />
                                                                                </span>
                                                                            );
                                                                    }
                                                                );
                                                            });
                                                        })}
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    </>
                                )
                            })
                        }

                    </TableBody>
                </Table>






            </div >
        </>
    )
}

export default AreaOfImprovementView