import React, { useEffect, useState, useRef, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditTwoTone from "@mui/icons-material/EditTwoTone";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import * as XLSX from "xlsx";
import { TextField, InputAdornment } from "@mui/material";
import Searchicon from "../../../assets/Images/Searchicon.svg";
import Newexcel from "../../../assets/Images/Newexcel.svg"
import FilterAltTwoToneIcon from "@mui/icons-material/FilterAltTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import {
  Container,
  TablePagination,
  Drawer,
  Box,
  Typography,
  Tooltip,
  Menu,
  ListItemIcon,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { styled } from "@mui/system";
import Close from "../../../assets/Images/Close.svg";
import Edit from "../../../assets/Images/Edit.svg";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import {
  useGetEmployeeQuery, useGetEmployeeByFilterQuery,
  useUpdateEmployeeAppraisalMutation,
  useRemovesMultipleEmployeeFromRoleExceptionMutation,
  useGetActiveCalenderQuery
} from "../../../service";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Scrollbar from "react-scrollbars-custom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Infoicon from "../appraisal/components/Icons/Infoicon.svg";
import { ADD_EMPLOYEE } from "../../../constants/routes/Routing";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AlertDialogSuccess from "../../UI/DialogSuccess";
import ListItemText from '@mui/material/ListItemText';
import Loader from "../../Loader/Loader";
//import Newexcel from "../../assets/Images/Newexcel.svg"
const Searchfeild = styled("div")({

  // marginTop: "8px",
  // marginBottom: "8px",
  "& .MuiOutlinedInput-root": {
    height: "30px",
    width: "100%",
    borderRadius: "25px",
    background: "#F2F6F8",
    // border:'2px solid white'
  },
  "& .MuiInputLabel-root": {
    fontSize: "12px",
    color: "#D5D5D5",
    marginTop: "-10px",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "12px",
    color: "#333333",
    opacity: "70%",
  },
  "& .MuiTextField-root": {
    minWidth: "100%",
    //   paddingLeft: "10px",
    //   paddingRight: "10px"
  },
});
const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
  "& .ScrollbarsCustom-TrackY": {
    width:"6px !important",
    top:"60px !important",
    right:"4px !important",
    height:"calc(100% - 72px) !important"
    },
    "& .ScrollbarsCustom-TrackX": {
      height:"6px !important"
      },
});
const useStyles = makeStyles((theme: any) => ({
  formControl: {
    // margin: theme?.spacing(1),
    width: 140,
    fontSize: "14px",
    color: "#333333",
    fontFamily: "Arial",
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
    fontSize: "13px !important",
    fontFamily: "Arial",
    color: "#333333",
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    fontSize: "12px !important",
    color: "#333333",
    fontFamily: "Arial",
  },
}));
export default function CEORole(props: any) {
  const classes = useStyles();
  const { values, checkedSwitch } = props;
  const CustomScrollbar = Scrollbar as any;
  const [count, setCount] = React.useState(650);
  const [searchNameForTabs, setsearchNameForTabs] = React.useState("");
  const navigate = useNavigate();

  //const { data } = useGetEmployeeQuery("all");
  const SELECT_FOR_DASHBOARD = `employee_code,legal_full_name,position_long_description,grade,manager_code,manager_name,Department,JoiningDate,Tenure,DepartmentHead,
  appraiser_name,section,normalizer_name,service_reference_date,reviewer_name,isGradeException,isCEORole,probation_status,function,first_name,
  email,isSupervisor,section,sub section,overall_rating,isSelected,division,manager_position,work_location,appraiser_code,reviewer_code,normalizer_code, employee_upload_flag`
  const { data, isLoading } = useGetEmployeeByFilterQuery(
    `?limit=700&select=${SELECT_FOR_DASHBOARD}&employee_upload_flag=true`
  );
  const { data: ActiveCData } = useGetActiveCalenderQuery("");
  let activeCal = ActiveCData?.data?.filter((i: any) => {
    return i?.isActive === true;
  });
  console.log(activeCal, "ActiveCData");

  console.log(data, "ghg");
  const [removeFromRoleexception] = useRemovesMultipleEmployeeFromRoleExceptionMutation();
  const [employeeUpdate] = useUpdateEmployeeAppraisalMutation();
  // if (data != undefined)
  // setCount(data.data.length)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  //for export to excel
  const [columnHeaders, setcolumnHeaders] = useState<any>({
    Ecode: true,
    Ename: true,
    Firstname:true,
    position: true,
    Grade: true,
    Ependingaction: true,
    Apprating: true,
    Revrating: true,
    Normrating: true,
    Status: true,
    ViewPA: true,
    FirstName: true,
    SupervisoryRole: true,
    Function: true,
    ServiceReferenceDate: true,
    PositionCode: true,
    division: true,
    Section: true,
    SubSection: true,
    ManagerCode: true,
    ManagerName: true,
    ManagerPosition: true,
    WorkLocation: true,
    GradeSet: true,
    JobCode: true,
    JobTitle: true,
    JobLevel: true,
    AppraiserName: true,
    ReviewerName: true,
    NormalizerName: true,
    Potentiallevel: true,
    TalentCategory: true,
    overallRating: true,
    PreviousRating: true,
    AppraiserCode: true,
    ReviewerCode: true,
    NormalizerCode: true,
    EmployeeMappedStatus: true,
    ProbationStatus: true,
    Employeeemail:true,
    SelectAll: true,
    JoiningDate: true,
    Department: true,
    DepartmentHead: true,
    Tenure: true,
  })
  
  const checkedCount = Object.keys(columnHeaders).reduce((count:any, key:any) => {
    if (key !== 'SelectAll') {
      return count + (columnHeaders[key] ? 1 : 0);
    }
    return count;
  }, 0);

  const totalCount = Object.keys(columnHeaders).length - 1; 
  console.log(checkedCount,totalCount,"totalCount")
  //for export to excel
  //for clearing search & mapping again
  const [users, setUsers] = React.useState<any>([]);
  const [usersSelected, setUsersSelected] = React.useState<any>(0);
 
  const ClearingSearch = React.useMemo(() => {
    setsearchNameForTabs('');
    if (data) {
      setUsers(data.data);
    }
  }, [values, data])
  // for clearing search & mapping again
  //checkbox funcs
  const handleOnCheck = (e: any) => {
    // setnavPrompt(true)
    const { name, checked } = e.target;

    console.log(name, checked, "handleChange")

    let filteredUsersID = users

      .filter((j: any) => {
        console.log(j.isSupervisor, "superv");
        if (sNS === "None" || sNS === "") {
          return j;
        }
        if (sNS === "S") {
          return j.isSupervisor === true;
        } else if (sNS === "NS") {
          return j.isSupervisor === undefined;
        }
      })
      .filter((j: any) => {
        if (positions === "None" || positions === "") {
          return j;
        } else {
          return j.position_long_description
            .toLocaleLowerCase()
            .includes(positions.toLocaleLowerCase());
        }
      })
      .filter((item1: any) => {
        if (GradesFilter.includes("None") || GradesFilter.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      .filter((item1: any) => {
        if (positionsFilter.includes("None") || positionsFilter.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      .filter((item1: any) => {
        if (positionFilter.includes("None") || positionFilter.length === 0) {
          return item1;
        } else {
          return !!positionFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      .filter((item1: any) => {
        if (sectionsFilter.includes("None") || sectionsFilter.length === 0) {
          return item1;
        } else {
          return !!sectionsFilter?.find((item2: any) => item1?.section === item2)
        }
      })
      .filter((item1: any) => {
        if (GradeFilter.includes("None") || GradeFilter.length === 0) {
          return item1;
        } else {
          return !!GradeFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      .filter((j: any) => {
        if (empgrades === "None" || empgrades === "") {
          return j;
        } else {
          return j.grade
            .toLocaleLowerCase()
            .includes(empgrades.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empsections === "None" || empsections === "") {
          return j;
        } else {
          return j.section
            .toLocaleLowerCase()
            .includes(empsections.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (searchNameForTabs === "") {
          return j;
        } else if (
          (j.employee_code !== undefined &&
            j.employee_code
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.legal_full_name !== undefined &&
            j.legal_full_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.section !== undefined &&
            j.section
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.position_long_description !== undefined &&
            j.position_long_description
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.appraiser_name !== undefined &&
            j.appraiser_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.probation_status !== undefined &&
            j.probation_status
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.reviewer_name !== undefined &&
            j.reviewer_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.function !== undefined &&
            j.function
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.normalizer_name !== undefined &&
            j.normalizer_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.grade !== undefined &&
            j.grade
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase()))
        ) {
          return j;
        }else if(searchNameForTabs !== ""){
          if (searchNameForTabs == "SP") {
            return j?.isSupervisor == true;
          }else if (searchNameForTabs == "N-SP") {
              return j?.isSupervisor != true;
            }
        }
      })
      .map((j: any) => j.employee_code);
    console.log(filteredUsersID, "filteredUsersID")
    // setnavPrompt(true);
    if (name === "allSelect") {
      const tempUser = users.map((employee: any) => {
        console.log(employee, "filteredUsersID")
        return filteredUsersID.includes(employee?.employee_code)
          ? {
            ...employee, isSelected: checked
            // isRoleException: checked ,
            // isGradeException: checked,isCEORole: checked,isLeavers: checked,isExcluded:checked
          }
          : employee;
      });


      setUsers(tempUser);

    } else {
      const tempUser = users?.map((employee: any) => {
        return employee._id === name
          // return filteredUsersID.includes(employee.employee_code)
          ? {
            ...employee, isSelected: checked,
            // isRoleException: checked ,
            // isGradeException: checked,isCEORole: checked,isLeavers: checked,isExcluded:checked
          }
          : employee;
      });

      setUsers(tempUser);

    }
  };
  React.useEffect(() => {
    const thedata = users
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })?.filter((j: any) => {
        if (positions === "None" || positions === "") {
          return j;
        } else {
          return j.position_long_description
            .toLocaleLowerCase()
            .includes(positions.toLocaleLowerCase());
          // return positions.toLocaleLowerCase().include(j.position_long_description.toLocaleLowerCase())
        }
      })
      .filter((item1: any) => {
        if (positionFilter.includes("None") || positionFilter.length === 0) {
          return item1;
        } else {
          return !!positionFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      .filter((item1: any) => {
        if (GradeFilter.includes("None") || GradeFilter.length === 0) {
          return item1;
        } else {
          return !!GradeFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      .filter((item1: any) => {
        if (sectionsFilter.includes("None") || sectionsFilter.length === 0) {
          return item1;
        } else {
          return !!sectionsFilter?.find((item2: any) => item1?.section === item2)
        }
      })
      .filter((item1: any) => {
        if (GradesFilter.includes("None") || GradesFilter.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      .filter((item1: any) => {
        if (positionsFilter.includes("None") || positionsFilter.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      .filter((j: any) => {
        if (empEmployeeCode === "None" || empEmployeeCode === "") {
          return j;
        } else {
          return j?.employee_code
            ?.toLocaleLowerCase()
            .includes(empEmployeeCode?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (AppName === "None" || AppName === "") {
          return j;
        } else {
          return j.appraiser_name
            .toLocaleLowerCase()
            .includes(AppName.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (RevName === "None" || RevName === "") {
          return j;
        } else {
          return j?.reviewer_name
            ?.toLocaleLowerCase() === RevName?.toLocaleLowerCase();
          //.includes(empgrades.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (norName === "None" || norName === "") {
          return j;
        } else {
          return j?.normalizer_name
            ?.toLocaleLowerCase() === norName?.toLocaleLowerCase();
          //.includes(empgrades.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empdivisions === "None" || empdivisions === "") {
          return j;
        } else {
          return j.division
            .toLocaleLowerCase()
            .includes(empdivisions.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empFullName === "None" || empFullName === "") {
          return j;
        } else {
          return j?.legal_full_name
            ?.toLocaleLowerCase()
            ?.includes(empFullName?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empFirstName === "None" || empFirstName === "") {
          return j;
        } else {
          return j?.first_name
            ?.toLocaleLowerCase()
            ?.includes(empFirstName?.toLocaleLowerCase());
        }
      })
      // .filter((j: any) => {
      //   if (empFunction === "None" || empFunction === "") {
      //     return j;
      //   } else {
      //     return j.Function.toLocaleLowerCase().includes(
      //       empFunction.toLocaleLowerCase()
      //     );
      //   }
      // })
      .filter((j: any) => {
        if (empPositionCode === "None" || empPositionCode === "") {
          return j;
        } else {
          return j?.position_code
            ?.toLocaleLowerCase()
            ?.includes(empPositionCode?.toLocaleLowerCase());
        }
      })

      .filter((j: any) => {
        if (empService === "None" || empService === "") {
          return j;
        } else {
          return j.service_reference_date
            .toLocaleLowerCase()
            .includes(empService.toLocaleLowerCase());
        }
      })

      .filter((j: any) => {
        console.log(j.isSupervisor, "superv");
        if (sNS === "None" || sNS === "") {
          return j;
        }
        if (sNS === "S") {
          return j.isSupervisor === true;
        } else if (sNS === "NS") {
          return j.isSupervisor === undefined;
        }
      })
      .filter((j: any) => {
        if (empSubSection === "None" || empSubSection === "") {
          return j;
        } else {
          return j["sub section"]
            ?.toLocaleLowerCase()
            .includes(empSubSection.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empGradeset === "None" || empGradeset === "") {
          return j;
        } else {
          return j?.grade_set
            ?.toLocaleLowerCase()
            ?.includes(empGradeset?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empManagerCode === "None" || empManagerCode === "") {
          return j;
        } else {
          return j?.manager_code
            ?.toLocaleLowerCase()
            ?.includes(empManagerCode?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empManagerPosition === "None" || empManagerPosition === "") {
          return j;
        } else {
          return j?.manager_position
            ?.toLocaleLowerCase()
            ?.includes(empManagerPosition?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empManagerName === "None" || empManagerName === "") {
          return j;
        } else {
          return j?.manager_name
            ?.toLocaleLowerCase()
            ?.includes(empManagerName?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empjobtitle === "None" || empjobtitle === "") {
          return j;
        } else {
          return j?.job_title
            ?.toLocaleLowerCase()
            ?.includes(empjobtitle?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empJobcode === "None" || empJobcode === "") {
          return j;
        } else {
          return j?.job_code
            ?.toLocaleLowerCase()
            ?.includes(empJobcode?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empjoblevel === "None" || empjoblevel === "") {
          return j;
        } else {
          return j?.job_level
            ?.toLocaleLowerCase()
            ?.includes(empjoblevel?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empWorkLocation === "None" || empWorkLocation === "") {
          return j;
        } else {
          return j?.work_location
            ?.toLocaleLowerCase()
            ?.includes(empWorkLocation?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empsections === "None" || empsections === "") {
          return j;
        } else {
          return j.section
            .toLocaleLowerCase()
            .includes(empsections.toLocaleLowerCase());
        }
      }).filter((j: any) => {
        if (searchNameForTabs === "") {
          return j;
        } else if (
          (j.employee_code !== undefined &&
            j.employee_code
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.legal_full_name !== undefined &&
            j.legal_full_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.first_name !== undefined &&
            j.first_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.section !== undefined &&
            j.section
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.position_long_description !== undefined &&
            j.position_long_description
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.appraiser_name !== undefined &&
            j.appraiser_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.probation_status !== undefined &&
            j.probation_status
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.reviewer_name !== undefined &&
            j.reviewer_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.function !== undefined &&
            j.function
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.normalizer_name !== undefined &&
            j.normalizer_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.grade !== undefined &&
            j.grade
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase()))
        ) {
          return j;
        }
      }).filter((j: any) => {
        return j?.isSelected === true
      })
    setUsersSelected(thedata?.length)
    // if(thedata?.length !=0){
    //   setnavPrompt(true)
    // }else{
    //   setnavPrompt(false)
    // }

  }, [users]);
  //checkbox funcs

  //for alert
  const [LaunchValDialog, setLaunchValDialog] = useState(false);
  const [LaunchValDialogMSG, setLaunchValDialogMSG] = useState("");
  const handleLaunchValDialog = () => {
    setLaunchValDialog(false);
    setLaunchValDialogMSG("")
  };
  //foralert

  //multiselect
  const [positionFilter, setpositionFilter] = React.useState<string[]>([]);
  const [GradeFilter, setGradeFilter] = React.useState<string[]>([]);
  console.log(positionFilter, "positionFilter");
  const handleChangeSelectPosition = (event: SelectChangeEvent<typeof positionFilter>) => {
    const {
      target: { value },
    } = event;
    if (value.includes("None")) { setpositionFilter([]) } else {
      setpositionFilter(

        typeof value === 'string' ? value.split(',') : value,
      );
    }

  };
  const handleChangeSelectGrade = (event: SelectChangeEvent<typeof GradeFilter>) => {
    const {
      target: { value },
    } = event;

    if (value.includes("None")) { setGradeFilter([]) } else {
      setGradeFilter(

        typeof value === 'string' ? value.split(',') : value,
      );
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // React.useEffect(() => {
  //   if (data != undefined) {
  //     setCount(data.data.length)
  //   }
  // }, [data])

  //for appraiser ,reiewer and normalizer name options
  const appraiserNames = Array.from(new Set(data?.data?.filter((j: any) => {
    return j?.isCEORole === true
  }) ?.filter((i: any) => {
    if (searchNameForTabs.length > 0) {
      const searchTerms = searchNameForTabs.toLowerCase().split(" ");
      return searchTerms.every(term =>
        i?.grade
          ?.toLowerCase()
          .includes(term)
      ) || searchTerms.every(term =>
        i?.position_long_description?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
        i?.legal_full_name?.toLowerCase().includes(term)
      )
      ||
      searchTerms.every((term: any) =>
        i?.position_long_description?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
        i?.employee_code?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
        i?.probation_status?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
        i?.appraiser_name?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
      i?.reviewer_name?.toLowerCase().includes(term)
    )||
      searchTerms.every((term: any) =>
        i?.normalizer_name?.toLowerCase().includes(term)
      )
    } else {
      return true;
    }
  })
  ?.map((i: any) => i?.appraiser_name)))
    .sort((a: any, b: any) => a?.localeCompare(b))
    .filter((i: any) => {
      return i != undefined
    })
  const ReviewerNames = Array.from(new Set(data?.data?.filter((j: any) => {
    return j?.isCEORole === true
  }) ?.filter((i: any) => {
    if (searchNameForTabs.length > 0) {
      const searchTerms = searchNameForTabs.toLowerCase().split(" ");
      return searchTerms.every(term =>
        i?.grade
          ?.toLowerCase()
          .includes(term)
      ) || searchTerms.every(term =>
        i?.position_long_description?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
        i?.legal_full_name?.toLowerCase().includes(term)
      )
      ||
      searchTerms.every((term: any) =>
        i?.position_long_description?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
        i?.employee_code?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
        i?.probation_status?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
        i?.appraiser_name?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
      i?.reviewer_name?.toLowerCase().includes(term)
    )||
      searchTerms.every((term: any) =>
        i?.normalizer_name?.toLowerCase().includes(term)
      )
    } else {
      return true;
    }
  })
  ?.map((i: any) => i?.reviewer_name)))
    ?.sort((a: any, b: any) => a?.localeCompare(b))
    ?.filter((i: any) => {
      return i != undefined
    })
  const NormalizerNames = Array.from(new Set(data?.data?.filter((j: any) => {
    return j?.isCEORole === true
  })
  ?.filter((i: any) => {
    if (searchNameForTabs.length > 0) {
      const searchTerms = searchNameForTabs.toLowerCase().split(" ");
      return searchTerms.every(term =>
        i?.grade
          ?.toLowerCase()
          .includes(term)
      ) || searchTerms.every(term =>
        i?.position_long_description?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
        i?.legal_full_name?.toLowerCase().includes(term)
      )
      ||
      searchTerms.every((term: any) =>
        i?.position_long_description?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
        i?.employee_code?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
        i?.probation_status?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
        i?.appraiser_name?.toLowerCase().includes(term)
      )||
      searchTerms.every((term: any) =>
      i?.reviewer_name?.toLowerCase().includes(term)
    )||
      searchTerms.every((term: any) =>
        i?.normalizer_name?.toLowerCase().includes(term)
      )
    } else {
      return true;
    }
  })
  ?.map((i: any) => i?.normalizer_name)))
    ?.sort((a: any, b: any) => a?.localeCompare(b))
    ?.filter((i: any) => {
      return i != undefined
    })
  console.log(appraiserNames, ReviewerNames, NormalizerNames, "NormalizerNames")
  //appraiser Filter
  const [AppName, setAppName] = React.useState("");
  const [anchorElnewserviceAppName, setAnchorElnewserviceAppName] = React.useState<null | HTMLElement>(
    null
  );
  const openserviceAppName = Boolean(anchorElnewserviceAppName);
  const handleClickserviceAppName = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewserviceAppName(event.currentTarget);
  };
  const handleCloseserviceAppName = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewserviceAppName(null);
  };
  const handleTargetserviceAppName = (event: any) => {

    setAppName(event?.target?.getAttribute("value"));


    setAnchorElnewserviceAppName(null);
    setPage(0);
  };
  //appraiser Filter
  //reviewer Filter
  const [RevName, setRevName] = React.useState("");
  const [anchorElnewserviceRevName, setAnchorElnewserviceRevName] = React.useState<null | HTMLElement>(
    null
  );
  const openserviceRevName = Boolean(anchorElnewserviceRevName);
  const handleClickserviceRevName = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewserviceRevName(event.currentTarget);
  };
  const handleCloseserviceRevName = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewserviceRevName(null);
  };
  const handleTargetserviceRevName = (event: any) => {

    setRevName(event?.target?.getAttribute("value"));


    setAnchorElnewserviceRevName(null);
    setPage(0);
  };
  //reviewer Filter
  //Normalizer filter
  const [norName, setNorName] = React.useState("");
  const [anchorElnewserviceNorName, setAnchorElnewserviceNorName] = React.useState<null | HTMLElement>(
    null
  );
  const openserviceNorName = Boolean(anchorElnewserviceNorName);
  const handleClickserviceNorName = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewserviceNorName(event.currentTarget);
  };
  const handleCloseserviceNorName = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewserviceNorName(null);
  };
  const handleTargetserviceNorName = (event: any) => {

    setNorName(event?.target?.getAttribute("value"));


    setAnchorElnewserviceNorName(null);
    setPage(0);
  };
  //Normalizer filter
  //for appraiser ,reiewer and normalizer name options

  //sorting
  // const [positions, setPositions] = React.useState<any>([]);
  const [positions, setPositions] = React.useState("");
  // console.log(positions, "position1");
  const handleChangeposition = (event: SelectChangeEvent) => {
    setPositions(event.target.value);
  };
  const [empgrades, setempGrades] = React.useState("");
  // console.log(empgrades, "position");
  const handleChangegrades = (event: SelectChangeEvent) => {
    setempGrades(event.target.value);
  };
  const [empdivisions, setempdivisions] = React.useState("");
  // console.log(empdivisions, "position");
  const handleChangedivisions = (event: SelectChangeEvent) => {
    setempdivisions(event.target.value);
  };

  const [empEmployeeCode, setempEmployeeCode] = React.useState("");
  console.log(empEmployeeCode, "position");
  const handleChangeEmployeeCode = (event: SelectChangeEvent) => {
    setempEmployeeCode(event.target.value);
  };

  const [empsections, setempsections] = React.useState("");
  // console.log(empsections, "position");
  const handleChangesections = (event: SelectChangeEvent) => {
    setempsections(event.target.value);
  };
  const [empFullName, setempFullName] = React.useState("");
  console.log(empFullName, "position");
  const handleChangeFullName = (event: SelectChangeEvent) => {
    setempFullName(event.target.value);
  };
  const [empFirstName, setempFirstName] = React.useState("");
  // console.log(empFirstName, "position");
  const handleChangeFirstName = (event: SelectChangeEvent) => {
    setempFirstName(event.target.value);
  };
  const [sNS, setsNS] = React.useState("");
  // console.log(sNS, "sNS");
  const handleChangesNS = (event: SelectChangeEvent) => {
    setsNS(event.target.value);
  };
  const [empPositionCode, setempPositionCode] = React.useState("");
  // console.log(empPositionCode, "position");
  const handleChangePositionCode = (event: SelectChangeEvent) => {
    setempPositionCode(event.target.value);
  };
  const [empService, setempService] = React.useState("");
  // console.log(empService, "position");
  const handleChangeService = (event: SelectChangeEvent) => {
    setempService(event.target.value);
  };
  const [empFunction, setempFunction] = React.useState("");
 
  const handleChangeFunction = (event: SelectChangeEvent) => {
    setempFunction(event.target.value);
  };
  const [empSubSection, setempSubSection] = React.useState("");
  // console.log(empSubSection, "position");
  const handleChangeSubSection = (event: SelectChangeEvent) => {
    setempSubSection(event.target.value);
  };
  const [empManagerCode, setempManagerCode] = React.useState("");
  // console.log(empManagerCode, "position");
  const handleChangeManagerCode = (event: SelectChangeEvent) => {
    setempManagerCode(event.target.value);
  };
  const [empManagerName, setempManagerName] = React.useState("");
  // console.log(empManagerName, "position");
  const handleChangeManagerName = (event: SelectChangeEvent) => {
    setempManagerName(event.target.value);
  };
  //ff
  const [empManagerPosition, setempManagerPosition] = React.useState("");
  // console.log(empManagerPosition, "position");
  const handleChangeManagerPosition = (event: SelectChangeEvent) => {
    setempManagerPosition(event.target.value);
  };
  const [empGradeset, setempGradeset] = React.useState("");
  // console.log(empGradeset, "position");
  const handleChangeGradeset = (event: SelectChangeEvent) => {
    setempGradeset(event.target.value);
  };
  const [empJobcode, setempJobcode] = React.useState("");
  // console.log(empJobcode, "position");
  const handleChangeJobcode = (event: SelectChangeEvent) => {
    setempJobcode(event.target.value);
  };
  const [empWorkLocation, setempWorkLocation] = React.useState("");
  // console.log(empWorkLocation, "position");
  const handleChangeWorkLocation = (event: SelectChangeEvent) => {
    setempWorkLocation(event.target.value);
  };
  const [empjobtitle, setempjobtitle] = React.useState("");
  // console.log(empjobtitle, "position");
  const handleChangeempjobtitle = (event: SelectChangeEvent) => {
    setempjobtitle(event.target.value);
  };
  const [empjoblevel, setempjoblevel] = React.useState("");
  //console.log(empjoblevel, "position");
  const handleChangeempjoblevel = (event: SelectChangeEvent) => {
    setempjoblevel(event.target.value);
  };

  const ITEM_HEIGHT = 28;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    // anchorOrigin: { vertical: "bottom", horizontal: "center" },
    // transformOrigin: { vertical: "top", horizontal: "right" },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth: 200,
        fontSize: "14px !important",
        fontFamily: "Arial",
        color: "#333333",
        // top:"250px !important",
        // left:"62px !important"
      },
    },
  };

  const sNSvalues = [{name : "SP", value : true}, {name : "N-SP", value : false}];



  //sorting
  //headings-sort
  const [managerCodeChecked, setmanagerCodeChecked] = React.useState(true);
  const handleManagerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setmanagerCodeChecked(event.target.checked);
    // let columnHeadersTemp = columnHeaders
    // columnHeadersTemp[event.target.name] = event.target.checked;
    // setcolumnHeaders(columnHeadersTemp)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [managerNameChecked, setmanagerNameChecked] = React.useState(true);
  const handleManagerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setmanagerNameChecked(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [isDrawerOpen, setisDrawerOpen] = React.useState(false);
  // console.log(isDrawerOpen, "position");
  const handleDrawer = (event: SelectChangeEvent) => {
    setisDrawerOpen(false);
  };

  const [heading1, setheading1] = React.useState(true);
  // console.log(heading1, "h1");
  const handleheading1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading1(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [firstname, setfirstname] = React.useState(true);
  const handlefirstname = (event: React.ChangeEvent<HTMLInputElement>) => {
    setfirstname(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading2, setheading2] = React.useState(true);
  // console.log(heading2, "h2");
  const handleheading2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading2(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading3, setheading3] = React.useState(true);
  // console.log(heading3, "h3");
  const handleheading3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading3(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [headingSN, setheadingSN] = React.useState(true);
  // console.log(headingSN, "h1");
  const handleheadingSN = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingSN(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading4, setheading4] = React.useState(true);
  // console.log(heading4, "h4");
  const handleheading4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading4(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading5, setheading5] = React.useState(true);
  // console.log(heading5, "h5");
  const handleheading5 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading5(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading6, setheading6] = React.useState(true);
  // console.log(heading6, "h6");
  const handleheading6 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading6(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading7, setheading7] = React.useState(true);
  // console.log(heading7, "h7");
  const handleheading7 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading7(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading8, setheading8] = React.useState(true);

  const handleheading8 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading8(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading9, setheading9] = React.useState(true);

  const handleheading9 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading9(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading10, setheading10] = React.useState(true);
  const handleheading10 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading10(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading11, setheading11] = React.useState(true);
  const handleheading11 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading11(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading12, setheading12] = React.useState(true);
  const handleheading12 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading12(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading13, setheading13] = React.useState(true);
  const handleheading13 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading13(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading14, setheading14] = React.useState(true);
  const handleheading14 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading14(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading15, setheading15] = React.useState(true);
  const handleheading15 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading15(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading16, setheading16] = React.useState(true);
  const handleheading16 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading16(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading17, setheading17] = React.useState(true);
  const handleheading17 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading17(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading18, setheading18] = React.useState(true);
  const handleheading18 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading18(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [heading19, setheading19] = React.useState(true);
  const handleheading19 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading19(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSelectAll = (selectAll:any) => {
    const updatedColumnHeaders = { ...columnHeaders };
    // Set all checkbox values to the selectAll value (true or false)
    Object.keys(updatedColumnHeaders).forEach((key) => {
      updatedColumnHeaders[key] = selectAll;
    });
    setcolumnHeaders(updatedColumnHeaders);
  };
  const [emailData, setemailData] = React.useState(true);
  const handleEmailData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setemailData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  //headings-sort

  //Grade Select Filter
  const [openGrade, setOpenGrade] = React.useState(false);
  const handleCloseGrade = () => {
    // setOpenGrade(false);
    setisDrawerOpen(false);
    setcolumnHeaders({
      Ecode: true,
      Ename: true,
      Firstname:true,
      position: true,
      Grade: true,
      Ependingaction: true,
      Apprating: true,
      Revrating: true,
      Normrating: true,
      Status: true,
      ViewPA: true,
      FirstName: true,
      SupervisoryRole: true,
      Function: true,
      ServiceReferenceDate: true,
      PositionCode: true,
      division: true,
      Section: true,
      SubSection: true,
      ManagerCode: true,
      ManagerName: true,
      ManagerPosition: true,
      WorkLocation: true,
      GradeSet: true,
      JobCode: true,
      JobTitle: true,
      JobLevel: true,
      AppraiserName: true,
      ReviewerName: true,
      NormalizerName: true,
      Potentiallevel: true,
      TalentCategory: true,
      overallRating: true,
      PreviousRating: true,
      AppraiserCode: true,
      ReviewerCode: true,
      NormalizerCode: true,
      EmployeeMappedStatus: true,
      ProbationStatus: true,
      Employeeemail:true,
      SelectAll: true,
      JoiningDate: true,
    Department: true,
    DepartmentHead: true,
    Tenure: true,
    })
  };
  const handleOpenGrade = () => {
    setOpenGrade(true);
  };

  //Supervisor NonSupervisor logic
  const [supervisorIDs, setSupervisorIDs] = React.useState<any[]>([]);


  React.useEffect(() => {
    const found = data?.data?.map((k: any) => k._id);
    // const found = data?.data?.filter((r:any) => dataCode.includes(r.employee_code)).map((k:any) => k._id)

    // console.log(found, "check");
  }, [data]);

  // React.useEffect(() => {
  //   if (data != undefined) {
  //     let ids: any[] = [];
  //     let EmployeeMasterData = data.data;
  //     data.data.forEach((emp: any) => {
  //       if (
  //         EmployeeMasterData.find(
  //           (item: any) => item.manager_code == emp.employee_code
  //         )
  //       )
  //         ids.push(emp._id);
  //     });
  //     setSupervisorIDs(ids);
  //     console.log(ids, "ids");
  //   }
  // }, [data])

  // React.useEffect(() => {
  //   let reviewers:any = []
  //   data?.data?.forEach((employee: any) => {
  //     let appraisals = data?.data?.filter(
  //         (emp: any) => emp.manager_code == employee.employee_code
  //     );
  //     let reviewals = [];
  //     let isReviewer = false;
  //     appraisals?.forEach((appraiser:any)=>{
  //       reviewals = data?.data?.filter(
  //           (emp: any) => emp.manager_code == appraiser.employee_code
  //       );
  //       if(reviewals.length > 0 )  isReviewer = true
  //     })
  //     if(isReviewer) reviewers.push(employee._id)
  //   })
  //
  //   console.log(reviewers,'reviewers')
  //
  // },[data])

  //populating filter dropdown
  const [department, setDepartment] = React.useState(true);
  const [departmentHead, setDepartmentHead] = React.useState(true);
  const [tenure, setTenure] = React.useState(true);
  const [joiningDate, setJoiningDate] = React.useState(true);
  const handledepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDepartment(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };const handledepartmentHead = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDepartmentHead(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };const handletenure = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTenure(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };const handlejoiningDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setJoiningDate(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  
  const [anchorElnew, setAnchorElnew] = React.useState<null | HTMLElement>(
    null
  );
  const opennew = Boolean(anchorElnew);
  const handleClicknew = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnew(event.currentTarget);
  };
  const handleClosenew = (event: React.MouseEvent<HTMLElement>) => {
    // console.log(event?.target.value)
    setAnchorElnew(null);
  };
  const handleTarget = (event: any) => {
    // console.log(event?.target.value)
    // setempEmployeeCode(event?.target?.value?.toString());
    setempEmployeeCode(event?.target?.getAttribute("value"));

    // console.log(event?.target.name.toString(),"handleTarget")
    setAnchorElnew(null);
    // handleClosenew(event);
    setPage(0);
  };

  //Legal Full Name
  const [anchorElnewFullName, setAnchorElnewFullName] = React.useState<null | HTMLElement>(
    null
  );
  const openFullName = Boolean(anchorElnewFullName);
  const handleClickFullName = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewFullName(event.currentTarget);
  };
  const handleCloseFullName = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewFullName(null);
  };
  const handleTargetFullName = (event: any) => {

    setempFullName(event?.target?.getAttribute("value"));


    setAnchorElnewFullName(null);
    setPage(0);
  };
  //Legal Full Name

  //function
  const [anchorElnewFunction, setAnchorElnewFunction] = React.useState<null | HTMLElement>(
    null
  );
  const openFunction = Boolean(anchorElnewFunction);
  const handleClickFunction = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewFunction(event.currentTarget);
  };
  const handleCloseFunction = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewFunction(null);
  };
  const handleTargetFunction = (event: any) => {

    setempFunction(event?.target?.getAttribute("value"));

    console.log(empFunction, "empFunction");
    setAnchorElnewFunction(null);
    setPage(0);
  };
  //function

  //FirstName
  const [anchorElnewFirstName, setAnchorElnewFirstName] = React.useState<null | HTMLElement>(
    null
  );
  const openFirstName = Boolean(anchorElnewFirstName);
  const handleClickFirstName = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewFirstName(event.currentTarget);
  };
  const handleCloseFirstName = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewFirstName(null);
  };
  const handleTargetFirstName = (event: any) => {

    setempFirstName(event?.target?.getAttribute("value"));


    setAnchorElnewFirstName(null);
  };
  //FirstName

  //Supervisory Role
  const [anchorElnewSupervisoryRole, setAnchorElnewSupervisoryRole] = React.useState<null | HTMLElement>(
    null
  );
  const openSupervisoryRole = Boolean(anchorElnewSupervisoryRole);
  const handleClickSupervisoryRole = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewSupervisoryRole(event.currentTarget);
  };
  const handleCloseSupervisoryRole = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewSupervisoryRole(null);
  };
  const handleTargetSupervisoryRole = (event: any) => {


    setsNS(event?.target?.getAttribute("value"))

    handleCloseSupervisoryRole(event);
    setPage(0);
  };
  //Supervisory Role
  //serviceRefDate
  const [anchorElnewserviceRefDate, setAnchorElnewserviceRefDate] = React.useState<null | HTMLElement>(
    null
  );
  const openserviceRefDate = Boolean(anchorElnewserviceRefDate);
  const handleClickserviceRefDate = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewserviceRefDate(event.currentTarget);
  };
  const handleCloseserviceRefDate = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewserviceRefDate(null);
  };
  const handleTargetserviceRefDate = (event: any) => {

    setempService(event?.target?.getAttribute("value"));


    setAnchorElnewserviceRefDate(null);
  };
  //serviceRefDate
  //Grade
  const [anchorElnewserviceGrade, setAnchorElnewserviceGrade] = React.useState<null | HTMLElement>(
    null
  );
  const openserviceGrade = Boolean(anchorElnewserviceGrade);
  const handleClickserviceGrade = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewserviceGrade(event.currentTarget);
  };
  const handleCloseserviceGrade = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewserviceGrade(null);
  };
  const handleTargetserviceGrade = (event: any) => {

    setempGrades(event?.target?.getAttribute("value"));


    setAnchorElnewserviceGrade(null);
  };
  //Grade
  //Position
  const [anchorElnewservicePosition, setAnchorElnewservicePosition] = React.useState<null | HTMLElement>(
    null
  );
  const openservicePosition = Boolean(anchorElnewservicePosition);
  const handleClickservicePosition = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewservicePosition(event.currentTarget);
  };
  const handleCloseservicePosition = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewservicePosition(null);
  };
  const handleTargetservicePosition = (event: any) => {

    setPositions(event?.target?.getAttribute("value"));


    setAnchorElnewservicePosition(null);
  };
  //Position
  //Probation status 
  const [ProbationStatus, setProbationStatus] = React.useState("");
  const [ProbationStatusVal, setProbationStatusVal] = React.useState<null | HTMLElement>(
    null
  );
  const openProbationStatusVal = Boolean(ProbationStatusVal);
  const handleClickProbationStatus = (event: React.MouseEvent<HTMLElement>) => {
    setProbationStatusVal(event.currentTarget);
  };
  const handleCloseProbationStatus = (event: React.MouseEvent<HTMLElement>) => {

    setProbationStatusVal(null);
  };
  const handleTargetProbationStatus = (event: any) => {

    setProbationStatus(event?.target?.getAttribute("value"));


    setProbationStatusVal(null);
    setPage(0);
  };
  //Probation status 
  //Position Code
  const [anchorElnewPositionCode, setAnchorElnewPositionCode] = React.useState<null | HTMLElement>(
    null
  );
  const openPositionCode = Boolean(anchorElnewPositionCode);
  const handleClickPositionCode = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewPositionCode(event.currentTarget);
  };
  const handleClosePositionCode = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewPositionCode(null);
  };
  const handleTargetPositionCode = (event: any) => {

    setempPositionCode(event?.target?.getAttribute("value"));


    setAnchorElnewPositionCode(null);
  };
  //Position Code
  //Division
  const [anchorElnewDivision, setAnchorElnewDivision] = React.useState<null | HTMLElement>(
    null
  );
  const openDivision = Boolean(anchorElnewDivision);
  const handleClickDivision = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewDivision(event.currentTarget);
  };
  const handleCloseDivision = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewDivision(null);
  };
  const handleTargetDivision = (event: any) => {

    setempdivisions(event?.target?.getAttribute("value"));


    setAnchorElnewDivision(null);
  };
  //Division
  //Section
  const [anchorElnewSection, setAnchorElnewSection] = React.useState<null | HTMLElement>(
    null
  );
  const openSection = Boolean(anchorElnewSection);
  const handleClickSection = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewSection(event.currentTarget);
  };
  const handleCloseSection = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewSection(null);
  };
  const handleTargetSection = (event: any) => {

    setempsections(event?.target?.getAttribute("value"));


    setAnchorElnewSection(null);
  };
  //Section
  //SubSection
  const [anchorElnewSubSection, setAnchorElnewSubSection] = React.useState<null | HTMLElement>(
    null
  );
  const openSubSection = Boolean(anchorElnewSubSection);
  const handleClickSubSection = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewSubSection(event.currentTarget);
  };
  const handleCloseSubSection = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewSubSection(null);
  };
  const handleTargetSubSection = (event: any) => {

    setempSubSection(event?.target?.getAttribute("value"));


    setAnchorElnewSubSection(null);
  };
  //SubSection
  //Manager Code
  const [anchorElnewManagerCode, setAnchorElnewManagerCode] = React.useState<null | HTMLElement>(
    null
  );
  const openSubManagerCode = Boolean(anchorElnewManagerCode);
  const handleClickManagerCode = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewManagerCode(event.currentTarget);
  };
  const handleClosManagerCode = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewManagerCode(null);
  };
  const handleTargetManagerCode = (event: any) => {

    setempManagerCode(event?.target?.getAttribute("value"));


    setAnchorElnewManagerCode(null);
  };
  //Manager Code
  //Manager Name
  const [anchorElnewManagerName, setAnchorElnewManagerName] = React.useState<null | HTMLElement>(
    null
  );
  const openSubManagerName = Boolean(anchorElnewManagerName);
  const handleClickManagerName = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewManagerName(event.currentTarget);
  };
  const handleClosManagerName = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewManagerName(null);
  };
  const handleTargetManagerName = (event: any) => {

    setempManagerName(event?.target?.getAttribute("value"));


    setAnchorElnewManagerName(null);
  };
  //Manager Name
  //Manager Position
  const [anchorElnewManagerPosition, setAnchorElnewManagerPosition] = React.useState<null | HTMLElement>(
    null
  );
  const openSubManagerPosition = Boolean(anchorElnewManagerPosition);
  const handleClickManagerPosition = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewManagerPosition(event.currentTarget);
  };
  const handleClosManagerPosition = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewManagerPosition(null);
  };
  const handleTargetManagerPosition = (event: any) => {

    setempManagerPosition(event?.target?.getAttribute("value"));


    setAnchorElnewManagerPosition(null);
  };
  //Manager Position
  //Work Location
  const [anchorElnewWorkLocation, setAnchorElnewWorkLocation] = React.useState<null | HTMLElement>(
    null
  );
  const openSubWorkLocation = Boolean(anchorElnewWorkLocation);
  const handleClickWorkLocation = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewWorkLocation(event.currentTarget);
  };
  const handleClosWorkLocation = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewWorkLocation(null);
  };
  const handleTargetWorkLocation = (event: any) => {

    setempWorkLocation(event?.target?.getAttribute("value"));


    setAnchorElnewWorkLocation(null);
  };
  //Work Location
  //Grade set
  const [anchorElnewGradeSet, setAnchorElnewGradeSet] = React.useState<null | HTMLElement>(
    null
  );
  const openSubGradeSet = Boolean(anchorElnewGradeSet);
  const handleClickGradeSet = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewGradeSet(event.currentTarget);
  };
  const handleClosGradeSet = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewGradeSet(null);
  };
  const handleTargetGradeSet = (event: any) => {

    setempGradeset(event?.target?.getAttribute("value"));


    setAnchorElnewGradeSet(null);
  };
  //Grade set
  //Job Code
  const [anchorElnewJobCode, setAnchorElnewJobCode] = React.useState<null | HTMLElement>(
    null
  );
  const openSubJobCode = Boolean(anchorElnewJobCode);
  const handleClickJobCode = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewJobCode(event.currentTarget);
  };
  const handleClosJobCode = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewJobCode(null);
  };
  const handleTargetJobCode = (event: any) => {

    setempJobcode(event?.target?.getAttribute("value"));


    setAnchorElnewJobCode(null);
  };
  //Job Code
  //Job Title
  const [anchorElnewJobTitle, setAnchorElnewJobTitle] = React.useState<null | HTMLElement>(
    null
  );
  const openSubJobTitle = Boolean(anchorElnewJobTitle);
  const handleClickJobTitle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewJobTitle(event.currentTarget);
  };
  const handleClosJobTitle = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewJobTitle(null);
  };
  const handleTargetJobTitle = (event: any) => {

    setempjobtitle(event?.target?.getAttribute("value"));


    setAnchorElnewJobTitle(null);
  };
  //Job Title
  //Job Level
  const [anchorElnewJobLevel, setAnchorElnewJobLevel] = React.useState<null | HTMLElement>(
    null
  );
  const openSubJobLevel = Boolean(anchorElnewJobLevel);
  const handleClickJobLevel = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewJobLevel(event.currentTarget);
  };
  const handleClosJobLevel = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewJobLevel(null);
  };
  const handleTargetJobLevel = (event: any) => {

    setempjoblevel(event?.target?.getAttribute("value"));


    setAnchorElnewJobLevel(null);
  };
  //Job level

  //populating filter dropdown
  const [serviceRef, setserviceRef] = React.useState<any>([]);
  const [gradesArray, setgradesArray] = React.useState<any>([]);
  const [positionArray, setpositionArray] = React.useState<any>([]);
  const [positioncodeArray, setpositioncodeArray] = React.useState<any>([]);
  const [divisionArray, setdivisionArray] = React.useState<any>([]);
  const [sectionArray, setsectionArray] = React.useState<any>([]);
  const [subSectionArray, setsubSectionArray] = React.useState<any>([]);
  const [managerCodeArray, setmanagerCodeArray] = React.useState<any>([]);
  const [managerNameArray, setmanagerNameArray] = React.useState<any>([]);
  const [managerPositionArray, setmanagerPositionArray] = React.useState<any>(
    []
  );
  const [workLocationArray, setworkLocationArray] = React.useState<any>([]);
  const [gradeSetArray, setgradeSetArray] = React.useState<any>([]);
  const [jobCodeArray, setjobCodeArray] = React.useState<any>([]);
  const [jobTitleArray, setjobTitleArray] = React.useState<any>([]);
  const [jobLevelArray, setjobLevelArray] = React.useState<any>([]);

  const [excel, setExcel] = React.useState<any>([]);
  const [page1, setPage1] = React.useState<any>([]);
  const [positionsFilter, setpositionsFilter] = React.useState<string[]>([]);
  const [GradesFilter, setSectionFilter] = React.useState<string[]>([]);

  React.useEffect(() => {
    const serviceRefDate = users
      .slice()
      ?.sort(function (a: any, b: any) {
        return a?.service_reference_date?.localeCompare(
          b?.service_reference_date
        );
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.service_reference_date;
      });
    // console.log(users,"users")
    // console.log(serviceRefDate,"serviceRefDate")
    const serviceRefDateContents = serviceRefDate.filter(
      (c: any, index: any) => {
        return (
          serviceRefDate.indexOf(c) === index && c != null && c != undefined
        );
      }
    );
    setserviceRef(serviceRefDateContents);
    console.log(serviceRef, "serviceRef")
    let grades = users
      .slice()
      .sort(function (a: any, b: any) {
        return a?.grade - b?.grade;
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.grade;
      });
    if (positionsFilter.length > 0) {
      grades = users
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.grade - b?.grade;
        })
        ?.filter((j: any) => {
          return j?.isCEORole === true
        })
        ?.filter((i: any) => {
          return !!positionsFilter?.find(item2 => i?.position_long_description === item2)
        })
        ?.map((i: any) => {
          return i?.grade;
        });
    }
    // search functionality based on grade
    else if (searchNameForTabs?.length > 0) {
      grades = users
        .slice()
        ?.sort(function (a: any, b: any) {
          return a?.grade?.localeCompare(
            b?.grade
          );
        })
        ?.filter((j: any) => {
          return j?.isCEORole === true
        })
        ?.filter((i: any) => {
          if (searchNameForTabs.length > 0) {
            const searchTerms = searchNameForTabs.toLowerCase().split(" ");
            return searchTerms.every(term =>
              i?.grade
                ?.toLowerCase()
                .includes(term)
            ) || searchTerms.every(term =>
              i?.position_long_description?.toLowerCase().includes(term)
            )||
            searchTerms.every((term: any) =>
              i?.legal_full_name?.toLowerCase().includes(term)
            )
            ||
            searchTerms.every((term: any) =>
              i?.position_long_description?.toLowerCase().includes(term)
            )||
            searchTerms.every((term: any) =>
              i?.employee_code?.toLowerCase().includes(term)
            )||
            searchTerms.every((term: any) =>
              i?.probation_status?.toLowerCase().includes(term)
            )||
            searchTerms.every((term: any) =>
              i?.appraiser_name?.toLowerCase().includes(term)
            )||
            searchTerms.every((term: any) =>
            i?.reviewer_name?.toLowerCase().includes(term)
          )||
            searchTerms.every((term: any) =>
              i?.normalizer_name?.toLowerCase().includes(term)
            )
          } else {
            return true;
          }
        })
        ?.map((i: any) => {
          return i?.grade;
        });
    }
    const gradeContents = grades.filter((c: any, index: any) => {
      return grades.indexOf(c) === index && c != null && c != undefined;
    });
    setgradesArray(gradeContents);
    console.log(gradeContents, "contents");
    let position = users
      .slice()
      ?.sort(function (a: any, b: any) {
        return a?.position_long_description?.localeCompare(
          b?.position_long_description
        );
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.position_long_description;
      });
    // GradeFilter
    if (GradesFilter.length > 0) {
      position = users
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.position_long_description - b?.position_long_description;
        })
        ?.filter((j: any) => {
          return j?.isCEORole === true
        })
        ?.filter((i: any) => {
          return !!GradesFilter?.find(item2 => i?.grade === item2)
        })
        ?.map((i: any) => {
          return i?.position_long_description;
        });
    }
    // search functionality based on position
    else if (searchNameForTabs?.length > 0) {
      position = users
        .slice()
        ?.sort(function (a: any, b: any) {
          return a?.position_long_description?.localeCompare(
            b?.position_long_description
          );
        })
        ?.filter((j: any) => {
          return j?.isCEORole === true
        })
        ?.filter((i: any) => {
          if (searchNameForTabs.length > 0) {
            const searchTerms = searchNameForTabs.toLowerCase().split(" ");
            return searchTerms.every(term =>
              i?.position_long_description
                ?.toLowerCase()
                .includes(term)
            ) || searchTerms.every(term =>
              i?.grade?.toLowerCase().includes(term)
            )||
            searchTerms.every((term: any) =>
              i?.legal_full_name?.toLowerCase().includes(term)
            )
            ||
            searchTerms.every((term: any) =>
              i?.position_long_description?.toLowerCase().includes(term)
            )||
            searchTerms.every((term: any) =>
              i?.employee_code?.toLowerCase().includes(term)
            )||
            searchTerms.every((term: any) =>
              i?.probation_status?.toLowerCase().includes(term)
            )||
            searchTerms.every((term: any) =>
              i?.appraiser_name?.toLowerCase().includes(term)
            )||
            searchTerms.every((term: any) =>
            i?.reviewer_name?.toLowerCase().includes(term)
          )||
            searchTerms.every((term: any) =>
              i?.normalizer_name?.toLowerCase().includes(term)
            )
          } else {
            return true;
          }
        })
        ?.map((i: any) => {
          return i?.position_long_description;
        });
    }
    const positionContents = position.filter((c: any, index: any) => {
      return position.indexOf(c) === index && c != null && c != undefined;
    });
    setpositionArray(positionContents);
    console.log(positionContents, "contents");

    const positionCode = users
      .slice()
      ?.sort(function (a: any, b: any) {
        return a?.position_code?.localeCompare(b?.position_code);
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.position_code;
      });
    const positionCodeContents = positionCode.filter((c: any, index: any) => {
      return positionCode.indexOf(c) === index && c != null && c != undefined;
    });
    setpositioncodeArray(positionCodeContents);
    console.log(positionContents, "contents");

    const division = users
      .slice()
      ?.sort(function (a: any, b: any) {
        return a?.division?.localeCompare(b?.division);
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.division;
      });
    const divisionContents = division.filter((c: any, index: any) => {
      return division.indexOf(c) === index && c != null && c != undefined;
    });
    setdivisionArray(divisionContents);
    console.log(divisionContents, "contents");

    let section = users
      .slice()
      ?.sort(function (a: any, b: any) {
        return a?.section?.localeCompare(b?.section);
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.section;
      });
      if (GradesFilter.length > 0) {
        section = users
          ?.slice()
          ?.sort(function (a: any, b: any) {
            return a?.section - b?.section;
          })
          ?.filter((j: any) => {
            return j?.isCEORole === true
          })
          ?.filter((i: any) => {
            return !!GradesFilter?.find((item2) => i?.grade === item2);
          })
          ?.map((i: any) => {
            return i?.section;
          });
      }
      else if (searchNameForTabs?.length > 0) {
        section = users
          .slice()
          ?.sort(function (a: any, b: any) {
            return a?.section?.localeCompare(
              b?.section
            );
          })
          ?.filter((j: any) => {
            return j?.isCEORole === true
          })
          ?.filter((i: any) => {
            if (searchNameForTabs.length > 0) {
              const searchTerms = searchNameForTabs.toLowerCase().split(" ");
              return (
                searchTerms.every((term: any) =>
                  i?.section?.toLowerCase().includes(term)
                ) ||
                searchTerms.every((term: any) =>
                  i?.legal_full_name?.toLowerCase().includes(term)
                )
                ||
                searchTerms.every((term: any) =>
                  i?.position_long_description?.toLowerCase().includes(term)
                )||
                searchTerms.every((term: any) =>
                  i?.employee_code?.toLowerCase().includes(term)
                )||
                searchTerms.every((term: any) =>
                  i?.probation_status?.toLowerCase().includes(term)
                )||
                searchTerms.every((term: any) =>
                  i?.appraiser_name?.toLowerCase().includes(term)
                )||
                searchTerms.every((term: any) =>
                i?.reviewer_name?.toLowerCase().includes(term)
              )||
                searchTerms.every((term: any) =>
                  i?.normalizer_name?.toLowerCase().includes(term)
                )
              );
            } else {
              return true;
            }
          })
          ?.map((i: any) => {
            return i?.section;
          });
      }
    const sectionContents = section.filter((c: any, index: any) => {
      return section.indexOf(c) === index && c != null && c != undefined;
    });
    setsectionArray(sectionContents);
    console.log(sectionContents, "contents");

    const subSection = users
      .slice()
      ?.sort(function (a: any, b: any) {
        return a?.["sub section"]?.localeCompare(b?.["sub section"]);
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i["sub section"];
      });
    const subSectionContents = subSection.filter((c: any, index: any) => {
      return subSection.indexOf(c) === index && c != null && c != undefined;
    });
    setsubSectionArray(subSectionContents);
    console.log(subSectionContents, "contents");

    const managerCode = users
      .slice()
      .sort(function (a: any, b: any) {
        return a.manager_code - b.manager_code;
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.manager_code;
      });
    const managerCodeContents = managerCode.filter((c: any, index: any) => {
      return managerCode.indexOf(c) === index && c != null && c != undefined;
    });
    setmanagerCodeArray(managerCodeContents);
    console.log(managerCodeContents, "contents");

    const managerName = users
      .slice()
      ?.sort(function (a: any, b: any) {
        return a?.manager_name?.localeCompare(b?.manager_name);
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.manager_name;
      });
    const managerNameContents = managerName.filter((c: any, index: any) => {
      return managerName.indexOf(c) === index && c != null && c != undefined;
    });
    setmanagerNameArray(managerNameContents);
    console.log(managerNameContents, "contents");

    const managerPosition = users
      .slice()
      ?.sort(function (a: any, b: any) {
        return a?.manager_position?.localeCompare(b?.manager_position);
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.manager_position;
      });
    const managerPositionContents = managerPosition.filter(
      (c: any, index: any) => {
        return (
          managerPosition.indexOf(c) === index && c != null && c != undefined
        );
      }
    );
    setmanagerPositionArray(managerPositionContents);
    console.log(managerPositionContents, "contents");

    const workLocation = users
      .slice()
      ?.sort(function (a: any, b: any) {
        return a?.work_location?.localeCompare(b?.work_location);
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.work_location;
      });
    const workLocationContents = workLocation.filter((c: any, index: any) => {
      return workLocation.indexOf(c) === index && c != null && c != undefined;
    });
    setworkLocationArray(workLocationContents);
    console.log(workLocationContents, "contents");

    const gradeSet = users
      .slice()
      ?.sort(function (a: any, b: any) {
        return a?.grade_set?.localeCompare(b?.grade_set);
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.grade_set;
      });
    const gradeSetContents = gradeSet.filter((c: any, index: any) => {
      return gradeSet.indexOf(c) === index && c != null && c != undefined;
    });
    setgradeSetArray(gradeSetContents);
    console.log(workLocationContents, "contents");

    const jobCode = users
      .slice()
      ?.sort(function (a: any, b: any) {
        return a?.job_code?.localeCompare(b?.job_code);
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.job_code;
      });
    const jobCodeContents = jobCode.filter((c: any, index: any) => {
      return jobCode.indexOf(c) === index && c != null && c != undefined;
    });
    setjobCodeArray(jobCodeContents);
    console.log(jobCodeContents, "contents");

    const jobTitle = users
      .slice()
      ?.sort(function (a: any, b: any) {
        return a?.job_title?.localeCompare(b?.job_title);
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.job_title;
      });
    const jobTitleContents = jobTitle.filter((c: any, index: any) => {
      return jobTitle.indexOf(c) === index && c != null && c != undefined;
    });
    setjobTitleArray(jobTitleContents);
    console.log(jobTitleContents, "contents");

    const jobLevel = users
      .slice()
      ?.sort(function (a: any, b: any) {
        return a?.job_level?.localeCompare(b?.job_level);
      })
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })
      .map((i: any) => {
        return i.job_level;
      });
    const jobLevelContents = jobLevel.filter((c: any, index: any) => {
      return jobLevel.indexOf(c) === index && c != null && c != undefined;
    });
    setjobLevelArray(jobLevelContents);
    console.log(jobLevelContents, "contents");
  }, [users, positionsFilter, searchNameForTabs, GradesFilter]);
  //populating filter dropdown
  const [snscheck, setSnscheck] = React.useState<any>([]);
  const [icon, setIcon] = React.useState<any>([]);
  const [icon1, setIcon1] = React.useState<any>([]);
  const [icon2, setIcon2] = React.useState<any>([]);
  const [icon3, setIcon3] = React.useState<any>([]);
  const [icon4, setIcon4] = React.useState<any>([]);
  const [icon5, setIcon5] = React.useState<any>([]);
  const [icon6, setIcon6] = React.useState<any>([]);
  const [icon7, setIcon7] = React.useState<any>([]);
  const [icon8, setIcon8] = React.useState<any>([]);
  const [icon9, setIcon9] = React.useState<any>([]);
  const [icon10, setIcon10] = React.useState<any>([]);
  const [icon11, setIcon11] = React.useState<any>([]);
  const [icon12, setIcon12] = React.useState<any>([]);
  const [icon13, setIcon13] = React.useState<any>([]);
  const [icon14, setIcon14] = React.useState<any>([]);
  const [icon15, setIcon15] = React.useState<any>([]);
  const [icon16, setIcon16] = React.useState<any>([]);
  const [icon17, setIcon17] = React.useState<any>([]);
  const [icon18, setIcon18] = React.useState<any>([]);
  const [icon19, setIcon19] = React.useState<any>([]);
  const [icon20, setIcon20] = React.useState<any>([]);

  const handleExport = () => {
    // setShow(0);
    // console.log(users, 'excel')
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(excel);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "MyExcel.xlsx");
  };
  //for section multiselect
  const [sectionsFilter, setsectionsFilter] = React.useState<string[]>([]);
  const isAllsectionFilter =
    sectionArray?.length > 0 && sectionsFilter?.length === sectionArray?.length;
  const handleChangeSelectsections = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      console.log((sectionsFilter?.length === sectionsFilter?.length ? [] : "select all"), "newwwwww")
      setsectionsFilter(sectionsFilter?.length === sectionArray?.length ? [] : sectionArray);
      return;
    }
    setsectionsFilter(value);
    setPage(0);
  };
  //All select section
  const isAllGradesFilter =
    gradesArray?.length > 0 && GradesFilter?.length === gradesArray?.length;

  const newsection = gradesArray?.length == GradesFilter?.length
  const handleChangeSelectSection = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      console.log((GradesFilter?.length === gradesArray?.length ? [] : "select all"), "newwwwww")

      setSectionFilter(GradesFilter?.length === gradesArray?.length ? [] : gradesArray);
      return;

    }

    setSectionFilter(value);
    setPage(0);
  };

  const isAllpositionsFilter =
    positionArray?.length > 0 && positionsFilter?.length === positionArray?.length;

  //const newsection = positionArray?.length == positionsFilter?.length
  const handleChangeSelectPositions = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      console.log((positionsFilter?.length === positionArray?.length ? [] : "select all"), "newwwwww")

      setpositionsFilter(positionsFilter?.length === positionArray?.length ? [] : positionArray);
      return;
    }
    setpositionsFilter(value);
    setPage(0);
  };


  React.useEffect(() => {
    if (empEmployeeCode === "None" || empEmployeeCode === "" || empEmployeeCode === "0") {
      setIcon(false);
    } else {
      setIcon(true);
    }
  }, [empEmployeeCode]);
  React.useEffect(() => {
    if (positionsFilter?.length == 0) {
      setIcon1(false);
    } else {
      setIcon1(true);
    }
  }, [positionsFilter]);
  React.useEffect(() => {
    if (AppName === "None" || AppName === "") {
      setIcon2(false);
    } else {
      setIcon2(true);
    }
  }, [AppName]);
  React.useEffect(() => {
    if (RevName === "None" || RevName === "") {
      setIcon3(false);
    } else {
      setIcon3(true);
    }
  }, [RevName]);
  React.useEffect(() => {
    if (empFullName === "None" || empFullName === "") {
      setIcon4(false);
    } else {
      setIcon4(true);
    }
  }, [empFullName]);
  React.useEffect(() => {
    if (norName === "None" || norName === "") {
      setIcon5(false);
    } else {
      setIcon5(true);
    }
  }, [norName]);
  React.useEffect(() => {
    if (empFunction === "None" || empFunction === "") {
      setIcon6(false);
    } else {
      setIcon6(true);
    }
  }, [empFunction]);
  React.useEffect(() => {
    if (empPositionCode === "None" || empPositionCode === "") {
      setIcon7(false);
    } else {
      setIcon7(true);
    }
  }, [empPositionCode]);
  React.useEffect(() => {
    if (empService === "None" || empService === "") {
      setIcon8(false);
    } else {
      setIcon8(true);
    }
  }, [empService]);
  React.useEffect(() => {
    if (sNS === "None" || sNS === "") {
      setIcon9(false);
    } else {
      setIcon9(true);
    }
    const Supervisorrole =users
    ?.filter((j: any) => {
      console.log(sNS, "superv");
      if (sNS === "None" || sNS === "") {
        return j;
      }
      if (sNS === "SP") {
        return j?.isSupervisor === true;
      } else if (sNS === "N-SP") {
        return j?.isSupervisor != true;
      }
    })
    if (Supervisorrole?.length == 0){
      setSnscheck(true)
    }else {
      setSnscheck(false)
    }
  }, [sNS]);
  React.useEffect(() => {
    if (GradesFilter?.length == 0) {
      setIcon20(false);
    } else {
      setIcon20(true);
    }
  }, [GradesFilter]);
  React.useEffect(() => {
    if (empsections === "None" || empsections === "") {
      setIcon10(false);
    } else {
      setIcon10(true);
    }
  }, [empsections]);
  React.useEffect(() => {
    if (empGradeset === "None" || empGradeset === "") {
      setIcon11(false);
    } else {
      setIcon11(true);
    }
  }, [empGradeset]);
  React.useEffect(() => {
    if (empManagerCode === "None" || empManagerCode === "") {
      setIcon12(false);
    } else {
      setIcon12(true);
    }
  }, [empManagerCode]);
  React.useEffect(() => {
    if (ProbationStatus === "None" || ProbationStatus === "") {
      setIcon13(false);
    } else {
      setIcon13(true);
    }
  }, [ProbationStatus]);
  React.useEffect(() => {
    if (empjobtitle === "None" || empjobtitle === "") {
      setIcon14(false);
    } else {
      setIcon14(true);
    }
  }, [empjobtitle]);
  React.useEffect(() => {
    if (empJobcode === "None" || empJobcode === "") {
      setIcon15(false);
    } else {
      setIcon15(true);
    }
  }, [empJobcode]);
  React.useEffect(() => {
    if (empjoblevel === "None" || empjoblevel === "") {
      setIcon16(false);
    } else {
      setIcon16(true);
    }
  }, [empjoblevel]);
  React.useEffect(() => {
    if (empWorkLocation === "None" || empWorkLocation === "") {
      setIcon17(false);
    } else {
      setIcon17(true);
    }
  }, [empWorkLocation]);
  React.useEffect(() => {
    if (empManagerPosition === "None" || empManagerPosition === "") {
      setIcon18(false);
    } else {
      setIcon18(true);
    }
  }, [empManagerPosition]);
  React.useEffect(() => {
    const Normalizerdata = users
      ?.filter((j: any) => {
        return j?.isCEORole === true
      })?.filter((j: any) => {
        if (ProbationStatus === "None" || ProbationStatus === "") {
          return j;
        } else {
          return j.probation_status
            .toLocaleLowerCase()
            .includes(positions.toLocaleLowerCase());
          // return positions.toLocaleLowerCase().include(j.position_long_description.toLocaleLowerCase())
        }
      })
      .filter((item1: any) => {
        if (positionFilter.includes("None") || positionFilter.length === 0) {
          return item1;
        } else {
          return !!positionFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      .filter((item1: any) => {
        if (sectionsFilter.includes("None") || sectionsFilter.length === 0) {
          return item1;
        } else {
          return !!sectionsFilter?.find((item2: any) => item1?.section === item2)
        }
      })
      .filter((item1: any) => {
        if (GradeFilter.includes("None") || GradeFilter.length === 0) {
          return item1;
        } else {
          return !!GradeFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      .filter((item1: any) => {
        if (GradesFilter.includes("None") || GradesFilter.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      .filter((item1: any) => {
        if (positionsFilter.includes("None") || positionsFilter.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      .filter((j: any) => {
        if (empEmployeeCode === "None" || empEmployeeCode === "") {
          return j;
        } else {
          return j?.employee_code
            ?.toLocaleLowerCase()
            .includes(empEmployeeCode?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empgrades === "None" || empgrades === "") {
          return j;
        } else {
          return j.grade
            .toLocaleLowerCase()
            .includes(empgrades.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empdivisions === "None" || empdivisions === "") {
          return j;
        } else {
          return j.division
            .toLocaleLowerCase()
            .includes(empdivisions.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empFullName === "None" || empFullName === "") {
          return j;
        } else {
          return j?.legal_full_name
            ?.toLocaleLowerCase()
            ?.includes(empFullName?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empFirstName === "None" || empFirstName === "") {
          return j;
        } else {
          return j?.first_name
            ?.toLocaleLowerCase()
            ?.includes(empFirstName?.toLocaleLowerCase());
        }
      })
      // .filter((j: any) => {
      //   if (empFunction === "None" || empFunction === "") {
      //     return j;
      //   } else {
      //     return j.Function.toLocaleLowerCase().includes(
      //       empFunction.toLocaleLowerCase()
      //     );
      //   }
      // })
      .filter((j: any) => {
        if (empPositionCode === "None" || empPositionCode === "") {
          return j;
        } else {
          return j?.position_code
            ?.toLocaleLowerCase()
            ?.includes(empPositionCode?.toLocaleLowerCase());
        }
      })

      .filter((j: any) => {
        if (empService === "None" || empService === "") {
          return j;
        } else {
          return j.service_reference_date
            .toLocaleLowerCase()
            .includes(empService.toLocaleLowerCase());
        }
      })

      .filter((j: any) => {
        console.log(j.isSupervisor, "superv");
        if (sNS === "None" || sNS === "") {
          return j;
        }
        if (sNS === "SP") {
          return j.isSupervisor === true;
        } else if (sNS === "N-SP") {
          return j.isSupervisor === undefined;
        }
      })
      .filter((j: any) => {
        if (empSubSection === "None" || empSubSection === "") {
          return j;
        } else {
          return j["sub section"]
            ?.toLocaleLowerCase()
            .includes(empSubSection.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empGradeset === "None" || empGradeset === "") {
          return j;
        } else {
          return j?.grade_set
            ?.toLocaleLowerCase()
            ?.includes(empGradeset?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empManagerCode === "None" || empManagerCode === "") {
          return j;
        } else {
          return j?.manager_code
            ?.toLocaleLowerCase()
            ?.includes(empManagerCode?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empManagerPosition === "None" || empManagerPosition === "") {
          return j;
        } else {
          return j?.manager_position
            ?.toLocaleLowerCase()
            ?.includes(empManagerPosition?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empFunction === "None" || empFunction === "") {
          return j;
        } else {
          return j.function.toLocaleLowerCase() === empFunction?.toLocaleLowerCase()
          // .includes(
          //   empFunction.toLocaleLowerCase()
          // );
        }
      })
      .filter((j: any) => {
        if (empManagerName === "None" || empManagerName === "") {
          return j;
        } else {
          return j?.manager_name
            ?.toLocaleLowerCase()
            ?.includes(empManagerName?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empjobtitle === "None" || empjobtitle === "") {
          return j;
        } else {
          return j?.job_title
            ?.toLocaleLowerCase()
            ?.includes(empjobtitle?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empJobcode === "None" || empJobcode === "") {
          return j;
        } else {
          return j?.job_code
            ?.toLocaleLowerCase()
            ?.includes(empJobcode?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empjoblevel === "None" || empjoblevel === "") {
          return j;
        } else {
          return j?.job_level
            ?.toLocaleLowerCase()
            ?.includes(empjoblevel?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empWorkLocation === "None" || empWorkLocation === "") {
          return j;
        } else {
          return j?.work_location
            ?.toLocaleLowerCase()
            ?.includes(empWorkLocation?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (AppName === "None" || AppName === "") {
          return j;
        } else {
          return j?.appraiser_name
            ?.toLocaleLowerCase() === AppName?.toLocaleLowerCase();
          //.includes(empgrades.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (RevName === "None" || RevName === "") {
          return j;
        } else {
          return j?.reviewer_name
            ?.toLocaleLowerCase() === RevName?.toLocaleLowerCase();
          //.includes(empgrades.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (norName === "None" || norName === "") {
          return j;
        } else {
          return j?.normalizer_name
            ?.toLocaleLowerCase() === norName?.toLocaleLowerCase();
          //.includes(empgrades.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empsections === "None" || empsections === "") {
          return j;
        } else {
          return j.section
            .toLocaleLowerCase()
            .includes(empsections.toLocaleLowerCase());
        }
      }).filter((j: any) => {
        if (searchNameForTabs === "") {
          return j;
        } else if (
          (j.employee_code !== undefined &&
            j.employee_code
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.legal_full_name !== undefined &&
            j.legal_full_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.first_name !== undefined &&
            j.first_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.section !== undefined &&
            j.section
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.position_long_description !== undefined &&
            j.position_long_description
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.appraiser_name !== undefined &&
            j.appraiser_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.probation_status !== undefined &&
            j.probation_status
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.reviewer_name !== undefined &&
            j.reviewer_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.function !== undefined &&
            j.function
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.normalizer_name !== undefined &&
            j.normalizer_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.grade !== undefined &&
            j.grade
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase()))
        ) {
          return j;
        }else if(searchNameForTabs !== ""){
          if (searchNameForTabs == "SP") {
            return j?.isSupervisor == true;
          }else if (searchNameForTabs == "N-SP") {
              return j?.isSupervisor != true;
            }
        }
      })
      .map((j: any, emp: any) => {
        // console.log(emp,"emp")
        // console.log(j,"j")
        let inputDate = j?.service_reference_date
        const dateParts = inputDate.split("-");
        const year = dateParts[0];
        const month = new Date(inputDate).toLocaleString("default", { month: "short" });
        const day = dateParts[2]?.slice(0, 2)
        const formattedDate = `${day}-${month}-${year}`;
        const inputDate1 = j?.JoiningDate
        let formattDate;
        if (inputDate1 && typeof inputDate1 === 'string') {
          const JoiningDate = inputDate1.replace("+", "");
           const dateParts = JoiningDate.split("-");
          if (dateParts.length >= 3) {
              const year = dateParts[0];
              const month = new Date(inputDate1).toLocaleString("default", { month: "short" });
              const day = dateParts[2].slice(0, 2);
      
              formattDate = `${day}-${month}-${year}`;
              console.log(formattDate,"formattDate"); // Output formatted date                           
          }else {
            console.log("JoiningDate does not have enough parts to split.");
        }
        }else {
          console.log("JoiningDate is not defined or not a valid string.");
      }
        let exportData: any = {}
        if (columnHeaders["Ecode"] == true) exportData["Ecode"] = j?.employee_code
        if (columnHeaders["Ename"] == true) exportData["Employee Name"] = j?.legal_full_name
        if (columnHeaders["Firstname"] == true) exportData["Known As"] = j?.first_name
        if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date "] = formattedDate
        if (columnHeaders["position"] == true) exportData["Position"] = j?.position_long_description
        if (columnHeaders["Grade"] == true) exportData["Grade"] = j?.grade
        if (columnHeaders["Function"] == true) exportData["Function"] = j?.function
        if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = j?.isSupervisor != true ? "N-SP" : "SP"
        if (columnHeaders["ProbationStatus"] == true) exportData["Probation Status"] = j?.probation_status
        if (columnHeaders["Employeeemail"] == true) exportData["Email ID"] = j?.email
        if (columnHeaders["division"] == true) exportData["Division"] = j?.division
        if (columnHeaders["Section"] == true) exportData["Section"] = j?.section  
        if (columnHeaders["SubSection"] == true) exportData["Sub-section"] = j.sub_section  
        if (columnHeaders["Department"] == true) exportData["Department"] = j.Department   
        if (columnHeaders["DepartmentHead"] == true) exportData["Department Head"] = j.DepartmentHead   
        if (columnHeaders["JoiningDate"] == true) exportData["Joining Date"] = formattDate   
        if (columnHeaders["Tenure"] == true) exportData["Tenure"] = j.Tenure  
        if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = j?.work_location
        if (columnHeaders["AppraiserCode"] == true) exportData["Appraiser Code"] = j?.appraiser_code
        if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = j?.appraiser_name
        if (columnHeaders["ReviewerCode"] == true) exportData["Reviewer Code"] = j?.reviewer_code
        if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = j?.reviewer_name
        if (columnHeaders["NormalizerCode"] == true) exportData["HR Normalizer Code"] = j?.normalizer_code
        if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = j?.normalizer_name
        if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = j?.manager_code
        if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = j?.manager_name
        if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = j?.manager_position
        return exportData
        // return {
        //   Ecode: j.employee_code,
        //   EmployeeName: j.legal_full_name,
        //   Position: j.position_long_description,
        //   Grade: j.grade,
        //   SupervisoryRole: j.isSupervisor,
        //   Function: j?.function,
        //   AppraiserName: j?.appraiser_name,
        //   ReviewerName: j?.reviewer_name,
        //   NormalizerName: j?.normalizer_name,
        //   Section: j.section,
        //   ServiceReferenceDate: j.service_reference_date,
        //   SubSection: j["sub section"],
        //   Division: j.division,
        //   ManagerPosition: j?.manager_position,
        //   WorkLocation: j?.work_location,
        //   AppraiserCode: j?.appraiser_code,
        //   ReviewerCode: j?.reviewer_code,
        //   NormalizerCode: j?.normalizer_code
        // };
      });
    console.log(Normalizerdata, "Normalizerdata");
    if (Normalizerdata == null) {
      setExcel(users);
      setPage1(users);
    } else {
      setExcel(Normalizerdata);
      setPage1(Normalizerdata?.length);
    }
    console.log(Normalizerdata?.length, "ggggg");
    // // setPage1(Normalizerdata?.length)
    // // console.log(page1,"page1")
    console.log(excel, "excellll");
    // setExcel(Normalizerdata)
    // return Normalizerdata;
  }, [
    users,
    positions,
    empFullName,
    empFirstName,
    empEmployeeCode,
    empgrades,
    empdivisions,
    sNS,
    empsections,
    ProbationStatus,
    empSubSection,
    empFunction,
    empPositionCode,
    empService,
    empGradeset,
    empManagerCode,
    empManagerPosition,
    empManagerName,
    empjobtitle,
    empJobcode,
    empjoblevel,
    empWorkLocation,
    empsections,
    AppName,
    RevName,
    norName,
    searchNameForTabs,
    positionFilter,
    GradeFilter,
    GradesFilter,
    positionsFilter,
    sectionsFilter,
    columnHeaders, heading1, heading2, heading5, heading6,
    heading8, heading9, heading4, heading19, heading10, heading7,
    heading11, heading12, heading13, heading14, heading15, heading16,
    heading17
  ]);
  //  React.useEffect(()=>{
  //   if(Normalizerdata){
  //     setExcel(Normalizerdata)
  //   }else{
  //     setExcel(users)
  //   }
  //  })

  React.useEffect(() => {
    const f = users
      ?.filter((j: any) => {
        if (positions === "None" || positions === "") {
          return j;
        } else {
          return j.position_long_description
            .toLocaleLowerCase()
            .includes(positions.toLocaleLowerCase());
          // return positions.toLocaleLowerCase().include(j.position_long_description.toLocaleLowerCase())
        }
      })
      .filter((item1: any) => {
        if (positionFilter.includes("None") || positionFilter.length === 0) {
          return item1;
        } else {
          return !!positionFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      .filter((item1: any) => {
        if (sectionsFilter.includes("None") || sectionsFilter.length === 0) {
          return item1;
        } else {
          return !!sectionsFilter?.find((item2: any) => item1?.section === item2)
        }
      })
      .filter((item1: any) => {
        if (GradeFilter.includes("None") || GradeFilter.length === 0) {
          return item1;
        } else {
          return !!GradeFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      .filter((item1: any) => {
        if (GradesFilter.includes("None") || GradesFilter.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      .filter((item1: any) => {
        if (positionsFilter.includes("None") || positionsFilter.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      .filter((j: any) => {
        if (empEmployeeCode === "None" || empEmployeeCode === "") {
          return j;
        } else {
          return j?.employee_code
            ?.toLocaleLowerCase()
            .includes(empEmployeeCode?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (AppName === "None" || AppName === "") {
          return j;
        } else {
          return j?.appraiser_name
            ?.toLocaleLowerCase()
            ?.includes(AppName.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (RevName === "None" || RevName === "") {
          return j;
        } else {
          return j?.reviewer_name
            ?.toLocaleLowerCase()
            ?.includes(RevName.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (norName === "None" || norName === "") {
          return j;
        } else {
          return j?.normalizer_name
            ?.toLocaleLowerCase()
            ?.includes(norName.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empgrades === "None" || empgrades === "") {
          return j;
        } else {
          return j.grade
            .toLocaleLowerCase()
            .includes(empgrades.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empdivisions === "None" || empdivisions === "") {
          return j;
        } else {
          return j.division
            .toLocaleLowerCase()
            .includes(empdivisions.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empFullName === "None" || empFullName === "") {
          return j;
        } else {
          return j?.legal_full_name
            ?.toLocaleLowerCase()
            ?.includes(empFullName?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empFirstName === "None" || empFirstName === "") {
          return j;
        } else {
          return j?.first_name
            ?.toLocaleLowerCase()
            ?.includes(empFirstName?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empFunction === "None" || empFunction === "") {
          return j;
        } else {
          return j.function.toLocaleLowerCase() === empFunction?.toLocaleLowerCase()
          // .includes(
          //   empFunction.toLocaleLowerCase()
          // );
        }
      })
      .filter((j: any) => {
        if (empPositionCode === "None" || empPositionCode === "") {
          return j;
        } else {
          return j?.position_code
            ?.toLocaleLowerCase()
            ?.includes(empPositionCode?.toLocaleLowerCase());
        }
      })

      .filter((j: any) => {
        if (empService === "None" || empService === "") {
          return j;
        } else {
          return j.service_reference_date
            .toLocaleLowerCase()
            .includes(empService.toLocaleLowerCase());
        }
      })
      // .filter((j: any) => {
      //   if (empSubSection === "None" || empSubSection === "") {
      //     return j;
      //   } else {
      //     return j.Service
      //       .toLocaleLowerCase()
      //       .includes(empSubSection.toLocaleLowerCase());
      //   }
      // })
      .filter((j: any) => {
        console.log(j.isSupervisor, "superv");
        if (sNS === "None" || sNS === "") {
          return j;
        }
        if (sNS === "SP") {
          return j.isSupervisor === true;
        } else if (sNS === "N-SP") {
          return j.isSupervisor === undefined;
        }
      })
      .filter((j: any) => {
        if (empSubSection === "None" || empSubSection === "") {
          return j;
        } else {
          return j["sub section"]
            ?.toLocaleLowerCase()
            .includes(empSubSection.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empGradeset === "None" || empGradeset === "") {
          return j;
        } else {
          return j?.grade_set
            ?.toLocaleLowerCase()
            ?.includes(empGradeset?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empManagerCode === "None" || empManagerCode === "") {
          return j;
        } else {
          return j?.manager_code
            ?.toLocaleLowerCase()
            ?.includes(empManagerCode?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empManagerPosition === "None" || empManagerPosition === "") {
          return j;
        } else {
          return j?.manager_position
            ?.toLocaleLowerCase()
            ?.includes(empManagerPosition?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empManagerName === "None" || empManagerName === "") {
          return j;
        } else {
          return j?.manager_name
            ?.toLocaleLowerCase()
            ?.includes(empManagerName?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empjobtitle === "None" || empjobtitle === "") {
          return j;
        } else {
          return j?.job_title
            ?.toLocaleLowerCase()
            ?.includes(empjobtitle?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empJobcode === "None" || empJobcode === "") {
          return j;
        } else {
          return j?.job_code
            ?.toLocaleLowerCase()
            ?.includes(empJobcode?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empjoblevel === "None" || empjoblevel === "") {
          return j;
        } else {
          return j?.job_level
            ?.toLocaleLowerCase()
            ?.includes(empjoblevel?.toLocaleLowerCase());
        }
      })
      .filter((j: any) => {
        if (empWorkLocation === "None" || empWorkLocation === "") {
          return j;
        } else {
          return j?.work_location
            ?.toLocaleLowerCase()
            ?.includes(empWorkLocation?.toLocaleLowerCase());
        }
      })
      ?.filter((j: any) => {
        if (empsections === "None" || empsections === "") {
          return j;
        } else {
          return j?.section
            ?.toLocaleLowerCase()
            ?.includes(empsections?.toLocaleLowerCase());
        }
      })?.filter((j: any) => {
        if (searchNameForTabs === "") {
          return j;
        } else if (
          (j.employee_code !== undefined &&
            j.employee_code
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.legal_full_name !== undefined &&
            j.legal_full_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.first_name !== undefined &&
            j.first_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.section !== undefined &&
            j.section
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.position_long_description !== undefined &&
            j.position_long_description
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.appraiser_name !== undefined &&
            j.appraiser_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.probation_status !== undefined &&
            j.probation_status
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.reviewer_name !== undefined &&
            j.reviewer_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.function !== undefined &&
            j.function
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.normalizer_name !== undefined &&
            j.normalizer_name
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase())) ||
          (j.grade !== undefined &&
            j.grade
              .toLocaleLowerCase()
              .includes(searchNameForTabs.toLocaleLowerCase()))
        ) {
          return j;
        }
        else if(searchNameForTabs !== ""){
          if (searchNameForTabs == "SP") {
            return j?.isSupervisor == true;
          }else if (searchNameForTabs == "N-SP") {
              return j?.isSupervisor != true;
            }
        }
      });

    console.log(f?.length, "fffff");
    // setPage1(f?.length)
    console.log(page1, "page1");
    // console.log(f,"excel")
    // setExcel(f)
    return f;
  }, [
    empsections,
    empWorkLocation,
    empEmployeeCode,
    positions,
    empFirstName,
    empjoblevel,
    empJobcode,
    empjobtitle,
    empManagerName,
    empManagerPosition,
    empManagerCode,
    empGradeset,
    empSubSection,
    sNS,
    empService,
    empPositionCode,
    empFunction,
    empdivisions,
    empFullName,
    empgrades,
    AppName,
    RevName,
    norName
  ]);
  //Edit Button function
  const handleRemove = (id: any) => {

    employeeUpdate({
      id: id,
      isCEORole: false
    }).then((res: any) => {
      res.error ? <> </> :
        setLaunchValDialog(true);
      setLaunchValDialogMSG("Employee has been removed from C-Level")
    });
  }

  // const handleSearchBar = (e: any) => {
  //   setsearchNameForTabs(e.target.value);
  //   setPage(0);
  // }
  const maxLengthForSearch = 30;
  const handleSearchBar = (e: any) => {
      if (e.target.value.length > maxLengthForSearch) {
        e.target.value = e.target.value.slice(0, maxLengthForSearch);
      }
      setsearchNameForTabs(e.target.value);
      setPage(0);
    }
  //for editing and deleting multiple employee
  const handleMultiDelete = () => {
    console.log("clicked edit")
    let data = users
    ?.filter((j: any) => {
      return j?.isCEORole === true
    })?.filter((i: any) => {
      return i?.isSelected === true
    }).map((i: any) => {
      return i?._id
    })
    console.log(data?.length, "selectedData")
    if (data?.length > 0 ) {
      removeFromRoleexception({
        id: data,
        exception: "CEORole"
      }).then((res: any) => {
        res.error ? <> </> :
          setLaunchValDialog(true);
        setLaunchValDialogMSG("The selected employees were removed from C-Level")
      });
    } else {
      setLaunchValDialog(true);
      setLaunchValDialogMSG("No employees were selected")
    }
  }
  //for showing no data available
  const tableDataLength = users?.filter((j: any) => {
    return j?.isCEORole === true
  })
  const tableDataFilterdLength =    users?.filter((j: any) => {
    return j.employee_upload_flag && j?.isCEORole === true
  })?.filter((j: any) => {
    if (positions === "None" || positions === "") {
      return j;
    } else {
      return j?.position_long_description
        ?.toLocaleLowerCase() === positions?.toLocaleLowerCase();
      //.includes(positions.toLocaleLowerCase());
      // return positions.toLocaleLowerCase().include(j.position_long_description.toLocaleLowerCase())
    }
  })
    .filter((item1: any) => {
      if (GradesFilter.includes("None") || GradesFilter.length === 0) {
        return item1;
      } else {
        return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
      }
    })
    .filter((item1: any) => {
      if (positionsFilter.includes("None") || positionsFilter.length === 0) {
        return item1;
      } else {
        return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
      }
    })
    .filter((item1: any) => {
      if (positionFilter.includes("None") || positionFilter.length === 0) {
        return item1;
      } else {
        return !!positionFilter?.find((item2: any) => item1?.position_long_description === item2)
      }
    })
    .filter((item1: any) => {
      if (GradeFilter.includes("None") || GradeFilter.length === 0) {
        return item1;
      } else {
        return !!GradeFilter?.find((item2: any) => item1?.grade === item2)
      }
    })
    .filter((j: any) => {
      if (
        empEmployeeCode === "None" ||
        empEmployeeCode === "" ||
        empEmployeeCode === "0"
      ) {
        return j;
      } else {
        return j?.employee_code?.toLocaleLowerCase() === empEmployeeCode?.toLocaleLowerCase();

        // .includes(empEmployeeCode?.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (empgrades === "None" || empgrades === "") {
        return j;
      } else {
        return j?.grade
          ?.toLocaleLowerCase() === empgrades?.toLocaleLowerCase();
        //.includes(empgrades.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (AppName === "None" || AppName === "") {
        return j;
      } else {
        return j?.appraiser_name
          ?.toLocaleLowerCase() === AppName?.toLocaleLowerCase();
        //.includes(empgrades.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (RevName === "None" || RevName === "") {
        return j;
      } else {
        return j?.reviewer_name
          ?.toLocaleLowerCase() === RevName?.toLocaleLowerCase();
        //.includes(empgrades.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (norName === "None" || norName === "") {
        return j;
      } else {
        return j?.normalizer_name
          ?.toLocaleLowerCase() === norName?.toLocaleLowerCase();
        //.includes(empgrades.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (empdivisions === "None" || empdivisions === "") {
        return j;
      } else {
        return j?.division
          ?.toLocaleLowerCase() === empdivisions?.toLocaleLowerCase();
        //.includes(empdivisions.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (empFullName === "None" || empFullName === "") {
        return j;
      } else {
        return j?.legal_full_name
          ?.toLocaleLowerCase() === empFullName?.toLocaleLowerCase();
        //?.includes(empFullName?.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (empFirstName === "None" || empFirstName === "") {
        return j;
      } else {
        return j?.first_name
          ?.toLocaleLowerCase() === empFirstName?.toLocaleLowerCase();
        //?.includes(empFirstName?.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (empFunction === "None" || empFunction === "") {
        return j;
      } else {
        return j.function.toLocaleLowerCase() === empFunction?.toLocaleLowerCase()
        // .includes(
        //   empFunction.toLocaleLowerCase()
        // );
      }
    })
    .filter((j: any) => {
      if (
        empPositionCode === "None" ||
        empPositionCode === ""
      ) {
        return j;
      } else {
        return j?.position_code
          ?.toLocaleLowerCase() === empPositionCode?.toLocaleLowerCase();
        //?.includes(empPositionCode?.toLocaleLowerCase());
      }
    })

    .filter((j: any) => {
      if (empService === "None" || empService === "") {
        return j;
      } else {
        return j?.service_reference_date
          ?.toLocaleLowerCase() === empService?.toLocaleLowerCase();
        //.includes(empService.toLocaleLowerCase());
      }
    })
    // .filter((j: any) => {
    //   if (empSubSection === "None" || empSubSection === "") {
    //     return j;
    //   } else {
    //     return j.Service
    //       .toLocaleLowerCase()
    //       .includes(empSubSection.toLocaleLowerCase());
    //   }
    // })
    .filter((j: any) => {
      //console.log(j.isSupervisor, "superv");
      if (sNS === "None" || sNS === "") {
        return j;
      }
      if (sNS === "SP") {
        return j?.isSupervisor === true;
      } else if (sNS === "N-SP") {
        return j?.isSupervisor === false;
      }
    })
    .filter((j: any) => {
      if (empSubSection === "None" || empSubSection === "") {
        return j;
      } else {
        return j["sub section"]
          ?.toLocaleLowerCase() === empSubSection?.toLocaleLowerCase();
        //.includes(empSubSection.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (empGradeset === "None" || empGradeset === "") {
        return j;
      } else {
        return j?.grade_set
          ?.toLocaleLowerCase() === empGradeset?.toLocaleLowerCase();
        //?.includes(empGradeset?.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (empManagerCode === "None" || empManagerCode === "") {
        return j;
      } else {
        return j?.manager_code
          ?.toLocaleLowerCase() === empManagerCode?.toLocaleLowerCase();
        //?.includes(empManagerCode?.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (
        empManagerPosition === "None" ||
        empManagerPosition === ""
      ) {
        return j;
      } else {
        return j?.manager_position
          ?.toLocaleLowerCase() === empManagerPosition?.toLocaleLowerCase();
        //?.includes(empManagerPosition?.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (empManagerName === "None" || empManagerName === "") {
        return j;
      } else {
        return j?.manager_name
          ?.toLocaleLowerCase() === empManagerName?.toLocaleLowerCase();
        //?.includes(empManagerName?.toLocaleLowerCase());
      }
    })
    .filter((item1: any) => {
      if (sectionsFilter.includes("None") || sectionsFilter.length === 0) {
        return item1;
      } else {
        return !!sectionsFilter?.find((item2: any) => item1?.section === item2)
      }
    })
    .filter((j: any) => {
      if (empjobtitle === "None" || empjobtitle === "") {
        return j;
      } else {
        return j?.job_title
          ?.toLocaleLowerCase() === empjobtitle?.toLocaleLowerCase();
        //?.includes(empjobtitle?.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (empJobcode === "None" || empJobcode === "") {
        return j;
      } else {
        return j?.job_code
          ?.toLocaleLowerCase() === empJobcode?.toLocaleLowerCase();
        //?.includes(empJobcode?.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (empjoblevel === "None" || empjoblevel === "") {
        return j;
      } else {
        return j?.job_level
          ?.toLocaleLowerCase() === empjoblevel?.toLocaleLowerCase();
        //?.includes(empjoblevel?.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (
        empWorkLocation === "None" ||
        empWorkLocation === ""
      ) {
        return j;
      } else {
        return j?.work_location
          ?.toLocaleLowerCase() === empWorkLocation?.toLocaleLowerCase();
        //?.includes(empWorkLocation?.toLocaleLowerCase());
      }
    })
    .filter((j: any) => {
      if (empsections === "None" || empsections === "") {
        return j;
      } else {
        return j.section
          .toLocaleLowerCase() === empsections?.toLocaleLowerCase();
        //.includes(empsections.toLocaleLowerCase());
      }
    }).filter((j: any) => {
      if (searchNameForTabs === "") {
        return j;
      } else if (
        (j.employee_code !== undefined &&
          j.employee_code
            .toLocaleLowerCase()
            .includes(searchNameForTabs.toLocaleLowerCase())) ||
        (j.legal_full_name !== undefined &&
          j.legal_full_name
            .toLocaleLowerCase()
            .includes(searchNameForTabs.toLocaleLowerCase())) ||
        (j.first_name !== undefined &&
          j.first_name
            .toLocaleLowerCase()
            .includes(searchNameForTabs.toLocaleLowerCase())) ||
        (j.section !== undefined &&
          j.section
            .toLocaleLowerCase()
            .includes(searchNameForTabs.toLocaleLowerCase())) ||
        (j.position_long_description !== undefined &&
          j.position_long_description
            .toLocaleLowerCase()
            .includes(searchNameForTabs.toLocaleLowerCase())) ||
        (j.appraiser_name !== undefined &&
          j.appraiser_name
            .toLocaleLowerCase()
            .includes(searchNameForTabs.toLocaleLowerCase())) ||
        (j.probation_status !== undefined &&
          j.probation_status
            .toLocaleLowerCase()
            .includes(searchNameForTabs.toLocaleLowerCase())) ||
        (j.reviewer_name !== undefined &&
          j.reviewer_name
            .toLocaleLowerCase()
            .includes(searchNameForTabs.toLocaleLowerCase())) ||
        (j.function !== undefined &&
          j.function
            .toLocaleLowerCase()
            .includes(searchNameForTabs.toLocaleLowerCase())) ||
        (j.normalizer_name !== undefined &&
          j.normalizer_name
            .toLocaleLowerCase()
            .includes(searchNameForTabs.toLocaleLowerCase())) ||
        (j.grade !== undefined &&
          j.grade
            .toLocaleLowerCase()
            .includes(searchNameForTabs.toLocaleLowerCase()))
      ) {
        return j;
      }else if(searchNameForTabs !== ""){
        if (searchNameForTabs == "SP") {
          return j?.isSupervisor == true;
        }else if (searchNameForTabs == "N-SP") {
            return j?.isSupervisor != true;
          }
      }
    }).length
  //export with choice
  const handleheadingSortAccept = () => {

    setisDrawerOpen(false);
    handleExport();
    setcolumnHeaders({
      Ecode: true,
      Ename: true,
      Firstname:true,
      position: true,
      Grade: true,
      Ependingaction: true,
      Apprating: true,
      Revrating: true,
      Normrating: true,
      Status: true,
      ViewPA: true,
      FirstName: true,
      SupervisoryRole: true,
      Function: true,
      ServiceReferenceDate: true,
      PositionCode: true,
      division: true,
      Section: true,
      SubSection: true,
      ManagerCode: true,
      ManagerName: true,
      ManagerPosition: true,
      WorkLocation: true,
      GradeSet: true,
      JobCode: true,
      JobTitle: true,
      JobLevel: true,
      AppraiserName: true,
      ReviewerName: true,
      NormalizerName: true,
      Potentiallevel: true,
      TalentCategory: true,
      overallRating: true,
      PreviousRating: true,
      AppraiserCode: true,
      ReviewerCode: true,
      NormalizerCode: true,
      EmployeeMappedStatus: true,
      ProbationStatus: true,
      Employeeemail:true,
      SelectAll: true,
      JoiningDate:true,
      Department:true,
      DepartmentHead:true,
      Tenure:true,
    })
  };
  const handleExportFunction = () => {
    setisDrawerOpen(true);

  };

  if (isLoading) {
    return <div><Loader /></div>;
  }
  console.log(usersSelected,page1,"usersSelected")
  return (
    <>
      <Box >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* <span
            style={{
              color: "#3e8cb5",
              fontSize: "18px",
              fontFamily: "Arial",
            }}
          >
            Employee List
          </span> */}
          <div style={{ paddingLeft: "10px", paddingRight: "15px" }}>
            {/* <Button
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: "Arial",
                borderColor: "#3E8CB5",
                color: "#3E8CB5",
                background: "transparent",
              }}
              variant="outlined"
              onClick={() => {
                setisDrawerOpen(true);
              }}
            >
              Choose Fields
            </Button>
            <img
              src={Newexcel}
              alt="icon"
              style={{ marginLeft: "15px", marginTop: "5px" }}
              onClick={handleExport}
            /> */}
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => {
                setisDrawerOpen(false);
              }}
            >
             <Box sx={{ padding: "10px",  }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#333333",
                    fontFamily: "Arial",
                    marginLeft :"-10px"
                  }}
                  variant="subtitle1"
                  // align="center"
                >
                  <Checkbox
                      checked={checkedCount === totalCount}
                        onChange={(event) => handleSelectAll(event.target.checked)}
                      />
                  Choose Fields
                </Typography>
                <FormGroup>
                <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox checked={columnHeaders?.Ecode} name="Ecode" onChange={handleheading1} />
                    }
                    label="Ecode"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox checked={columnHeaders?.Ename}
                        name="Ename" onChange={handleheading2} />
                    }
                    label="Employee Name"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox checked={columnHeaders?.Firstname}
                        name="Firstname" onChange={handlefirstname } />
                    }
                    label="Known As"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox checked={columnHeaders?.ServiceReferenceDate}
                        name="ServiceReferenceDate"
                        onChange={handleheading4} />
                    }
                    label="Service Reference Date"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox checked={columnHeaders?.position}
                        name="position"
                        onChange={handleheading6} />
                    }
                    label="Position"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox checked={columnHeaders?.Grade}
                        name="Grade"
                        onChange={handleheading5} />
                    }
                    label="Grade"
                  />
                    <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.Function}
                        name="Function"
                        onChange={handleheading19}
                      />
                    }
                    label="Function"
                  />
                   <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.SupervisoryRole}
                        name="SupervisoryRole"
                        onChange={handleheadingSN}
                      />
                    }
                    label="Supervisory Role"
                  />
                 
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.ProbationStatus}
                        name="ProbationStatus"
                        onChange={handleheading3}
                      />
                    }
                    label="Probation Status"
                  />
                <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.Employeeemail}
                        name="Employeeemail"
                        onChange={handleEmailData}
                      />
                    }
                    label="Email ID "
                  />
                   <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox checked={columnHeaders?.division}
                        name="division"
                        onChange={handleheading8} />
                    }
                    label="Division"
                  />
                   <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox checked={columnHeaders?.Section}
                        name="Section"
                        onChange={handleheading9} />
                    }
                    label="Section"
                  />
                    <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.SubSection}
                        name="SubSection"
                        onChange={handleheading10}
                      />
                    }
                    label="Sub Section"
                  />
                   <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders.Department}
                        name="Department"
                        onChange={handledepartment}
                      />
                    }
                    label="Department"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders.DepartmentHead}
                        name="DepartmentHead"
                        onChange={handledepartmentHead}
                      />
                    }
                    label="Department Head"
                  />
                    <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders.JoiningDate}
                        name="JoiningDate"
                        onChange={handlejoiningDate}
                      />
                    }
                    label="Joining Date"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders.Tenure}
                        name="Tenure"
                        onChange={handletenure}
                      />
                    }
                    label="Tenure"
                  />
                   <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.WorkLocation}
                        name="WorkLocation"
                        onChange={handleheading14}
                      />
                    }
                    label="Work Location"
                  />
                   <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.AppraiserCode}
                        name="AppraiserCode"
                        onChange={handleheading15}
                      />
                    }
                    label="Appraiser Code"
                  />
                   <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox checked={columnHeaders?.AppraiserName}
                        name="AppraiserName"
                        onChange={handleheading7} />
                    }
                    label="Appraiser Name"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.ReviewerCode}
                        name="ReviewerCode"
                        onChange={handleheading16}
                      />
                    }
                    label="Reviewer Code"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.ReviewerName}
                        name="ReviewerName"
                        onChange={handleheading11}
                      />
                    }
                    label="Reviewer Name"
                  />
                   <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.NormalizerCode}
                        name="NormalizerCode"
                        onChange={handleheading17}
                      />
                    }
                    label="HR Normalizer Code"
                  />
                  
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.NormalizerName}
                        name="NormalizerName"
                        onChange={handleheading12}
                      />
                    }
                    label="HR Normalizer Name"
                  />
                 
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.ManagerCode}
                        onChange={handleManagerCode}
                        name="ManagerCode"
                      />
                    }
                    label="Manager Code"
                  />
                 <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.ManagerName}
                        onChange={handleManagerName}
                        name="ManagerName"
                      />
                    }
                    label="Manager Name"
                  />
                 <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={columnHeaders?.ManagerPosition}
                        onChange={handleheading13}
                        name="ManagerPosition"
                      />
                    }
                    label="Manager Position"
                  />
                
                 
                  
                 
                  {/* <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={heading18}
                        name="EmployeeMappedStatus"
                        onChange={handleheading18}
                      />
                    }
                    label="Employee Mapped Status"
                  /> */}
                  
                </FormGroup>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" paddingTop="10px">
                  <Button
                    style={{
                      textTransform: "none",
                      fontSize: "15px",
                      fontFamily: "Arial",
                      borderColor: "#3E8CB5",
                      color: "#3E8CB5",
                      background: "transparent",
                      width: "70px",
                      height: "35px",
                    }}
                    variant="outlined"
                    onClick={() => {
                      handleheadingSortAccept();
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                     style={{
                      textTransform: "none",
                      fontSize: "15px",
                      fontFamily: "Arial",
                      borderColor: "#3E8CB5",
                      color: "#3E8CB5",
                      background: "transparent",
                      width: "70px",
                      height: "35px",
                    }}
                    variant="outlined"
                    onClick={() => {
                      handleCloseGrade();
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Box>
            </Drawer>
          </div>
        </Stack>
      </Box>
      {tableDataLength?.length > 0 && 
      <Stack
      direction="row"
      display= 'flex'
      alignItems="center"
      justifyContent="end"
      spacing={2}
      marginTop="10px" marginBottom="10px"
      >
          {checkedSwitch === false && activeCal?.length ==0 &&
          <Tooltip title="Delete">
          <IconButton
            aria-label="CancelOutlinedIcon "
            onClick={(e) => { handleMultiDelete() }}
          >
            <img src={Close} alt="icon" />
          </IconButton>
        </Tooltip>
         }
        <img
          //sx={{paddingRight:"10px"}}
          src={Newexcel}
          alt="icon"
          style={{ cursor: "pointer", }}
          onClick={handleExportFunction}
        />
        <Searchfeild>
          <TextField
            id="outlined-basic"
            autoComplete="off"
            placeholder="Search Here..."
            value={searchNameForTabs}
            onChange={handleSearchBar}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={Searchicon} alt="icon" />
                </InputAdornment>
              ),
            }}
          />
        </Searchfeild>
      </Stack>}
      {/* {tableDataLength?.length > 0 &&  */}
      <>
        <Scroll>
          <CustomScrollbar style={{ minWidth: 650, height: "calc(100vh - 320px)" }}>
            <Table size="small" aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    "& td, & th": {
                      whiteSpace: "nowrap",
                      bgcolor: "#eaeced",
                      // border: 1,
                      // borderColor: "#e0e0e0",
                    },
                  }}
                >
                  {checkedSwitch === false && <TableCell width="0.5%" align="center">
                    <input
                      type="checkbox"
                      style={{
                        height: "20px",
                        width: "20px",
                      }}
                      name="allSelect"
                      checked={
                        usersSelected === page1 && page1 != 0
                      }
                      onChange={handleOnCheck}
                    />
                  </TableCell>}

                  <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Arial",
                      color: "#3E8CB5",
                      fontSize: "14px",
                      fontWeight: "600",
                      // bgcolor: "#ebf2f4",
                    }}
                  >Ecode
                    {/* <Stack direction="row" alignItems="center" >
                        <div
                          aria-controls={opennew ? "fade-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={opennew ? "true" : undefined}
                          onClick={handleClicknew}
                        >
                          <Stack direction="row" alignItems="center">
                            Ecode
                            <ArrowDropDownOutlinedIcon style={{cursor: "pointer"}}/>
                          </Stack>
                        </div>
                        <Menu
                          MenuListProps={{
                            "aria-labelledby": "fade-button",

                          }}
                          sx={{
                            height:"200px",
                            width:"90px"
                          }}
                          anchorEl={anchorElnew}
                          open={opennew}
                          onClose={handleClosenew}
                          
                        >
                           <MenuItem 
                          style={{
                            fontSize: "14px",
                            fontFamily: "Arial",
                            color: "#333333",
                            justifyContent:"center"
                            //paddingLeft: "15px",
                            //height:"200px"
                          }}
                          key="None"
                          value="None"
                           onClick={handleTarget}
                           >Clear Filter
                           </MenuItem>
                         
                          {users
                              .slice()
                              .sort(function (a: any, b: any) {
                                return a.employee_code - b.employee_code;
                              })
                              ?.filter((j: any) => {
                                return j?.isCEORole === true 
                              })
                              .map((name: any) => (
                                <MenuItem
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Arial",
                                    color: "#333333",
                                    paddingLeft: "15px",
                                    justifyContent:"center"
                                    //height:"200px"
                                  }}
                                  key={name.employee_code}
                                  value={name.employee_code}
                                  onClick={handleTarget}
                                >
                                  {name.employee_code}
                                </MenuItem>
                              ))}
                        </Menu>
                        {icon && <FilterAltTwoToneIcon />}
                        </Stack> */}

                  </TableCell>


                  <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Arial",
                      color: "#3E8CB5",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    style={{ minWidth: 250 }}
                  >
                    Employee Name
                    {/* <Stack direction="row" alignItems="center" justifyContent="center" >
                        <div
                          aria-controls={openFullName ? "fade-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openFullName ? "true" : undefined}
                          onClick={handleClickFullName}
                        >
                          <Stack direction="row" alignItems="center">
                          Employee Name
                            <ArrowDropDownOutlinedIcon style={{cursor: "pointer"}}/>
                          </Stack>
                        </div>
                        <Menu
                          MenuListProps={{
                            "aria-labelledby": "fade-button",

                          }}
                          sx={{
                            height:"200px",
                            width:"260px"
                          }}
                          anchorEl={anchorElnewFullName}
                          open={openFullName}
                          onClose={handleCloseFullName}
                          
                        >
                           <MenuItem 
                          style={{
                            fontSize: "14px",
                            fontFamily: "Arial",
                            color: "#333333",
                            justifyContent:"left"
                            //paddingLeft: "15px",
                            //height:"200px"
                          }}
                          key="None"
                          value="None"
                          //name="None"
                           onClick={handleTargetFullName}
                           >Clear Filter
                           </MenuItem>
                         
                          {users
                              .slice()
                              .sort(function (a: any, b: any) {
                                return a.legal_full_name - b.legal_full_name;
                              })
                              ?.filter((j: any) => {
                                return j?.isCEORole === true 
                              })
                              .map((name: any) => (
                                <MenuItem
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Arial",
                                    color: "#333333",
                                    paddingLeft: "15px",
                                    justifyContent:"left"
                                    //height:"200px"
                                  }}
                                  key={name.legal_full_name}
                                  value={name.legal_full_name}
                                  //name={name.legal_full_name}
                                  onClick={handleTargetFullName}
                                >
                                  {name.legal_full_name}
                                </MenuItem>
                              ))}
                        </Menu>
                        {icon4 && <FilterAltTwoToneIcon />}
                        </Stack>
                      */}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Arial",
                      color: "#3E8CB5",
                      fontSize: "14px",
                      fontWeight: "600",
                      // bgcolor: "#ebf2f4",
                    }}
                  >Known As</TableCell>





                  <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Arial",
                      color: "#3E8CB5",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    style={{ minWidth: 250 }}
                  >
                    <div>
                      {/* <Stack direction="row" alignItems="center" justifyContent="center">
                        <div
                          aria-controls={openservicePosition  ? "fade-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openservicePosition  ? "true" : undefined}
                          onClick={handleClickservicePosition}
                        >
                          <Stack direction="row" alignItems="center">
                          Position
                            <ArrowDropDownOutlinedIcon style={{cursor: "pointer"}}/>
                          </Stack>
                        </div>
                        <Menu
                          MenuListProps={{
                            "aria-labelledby": "fade-button",

                          }}
                          sx={{
                            height:"200px",
                            width:"240px"
                          }}
                          anchorEl={anchorElnewservicePosition}
                          open={openservicePosition }
                          onClose={handleCloseservicePosition}
                          
                        >
                           <MenuItem 
                          style={{
                            fontSize: "14px",
                            fontFamily: "Arial",
                            color: "#333333",
                            justifyContent:"left",
                            width:"100px",
                            paddingLeft: "16px",

                            //height:"200px"
                          }}
                          key="None"
                          value="None"
                           onClick={handleTargetservicePosition}
                           >None
                           </MenuItem>
                         
                           {positionArray?.map((name: any) => (
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Arial",
                                  color: "#333333",
                                    paddingLeft: "16px",
                                }}
                                key={name}
                                value={name}
                                onClick={handleTargetservicePosition}
                              >
                                {name}
                              </MenuItem>
                            ))}
                        </Menu>
                        {icon1 && <FilterAltTwoToneIcon />}
                        </Stack> */}
                      {/* <FormControl sx={{ m: 0, width: 100, height: "0" }}>
                              <Stack direction="row">
                                <span> Position</span>
                                <Select
                                  size="small"
                                  sx={{ width: "25px", fontSize: "0rem" }}
                                  disableUnderline
                                 
                                  variant="standard"
                                  MenuProps={MenuProps}
                                  multiple
                                  value={positionFilter}
                                  onChange={handleChangeSelectPosition}
                                 
                                  renderValue={(selected) => selected.join(', ')}
                                >
                                  <MenuItem
                                    
                                    key={0}
                                    value="None"
                                    sx={{
                                      padding: "0px",
                                      fontSize: "12px",
                                      paddingLeft: "37px"
                                    }}
                                  >
                                   
                                    <ListItemText
                                      primaryTypographyProps={{ fontSize: "14px", fontFamily: "arial", color: "#333333" }}
                                      primary="None" />
                                  </MenuItem>

                                  {positionArray?.map((name: any, index: any) => (
                                      <MenuItem
                                        sx={{
                                          padding: "0px",
                                          fontSize: "14px"
                                        }}
                                        
                                        key={name}
                                        value={name}
                                      >
                                        <Checkbox
                                          style={{ padding: "3px", paddingLeft: "14px" }}
                                          size="small"
                                          checked={positionFilter.indexOf(name) > -1} />
                                        <ListItemText
                                          primaryTypographyProps={{ fontSize: "14px", fontFamily: "arial", color: "#333333" }}
                                          primary={name} />
                                      </MenuItem>
                                    )
                                    )}
                                </Select>
                                {icon2 && (
                                  <FilterAltTwoToneIcon />)}
                              </Stack>
                            </FormControl> */}
                      <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                        <Stack direction="row">
                          <span>Position</span>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            size="small"
                            sx={{ width: "25px", fontSize: "0rem",
                            "& .MuiSvgIcon-root": {
                              color:"#3e8cb5 !important"
                              }, }}
                            disableUnderline
                            variant="standard"
                            MenuProps={MenuProps}
                            multiple
                            value={positionsFilter}
                            onChange={handleChangeSelectPositions}
                            renderValue={(selected) => selected.join(', ')}
                          >
                            <MenuItem
                              style={{
                                fontSize: "14px",
                                color: "#333333",
                                fontFamily: "Arial",
                                padding: "0px",
                                //paddingLeft: "37px",
                              }}
                              key="all"
                              value="all"
                              classes={{
                                root: isAllpositionsFilter ? classes.selectedAll : "",
                              }}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: "14px !important",
                                    },
                                  }}
                                  size="small"
                                  style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                  classes={{
                                    indeterminate: classes.indeterminateColor,
                                  }}
                                  checked={isAllpositionsFilter}
                                  indeterminate={
                                    positionsFilter?.length > 0 &&
                                    positionsFilter?.length < positionArray?.length
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText
                                sx={{
                                  "& .MuiTypography-root": {
                                    fontSize: "13px",
                                    fontFamily: "Arial",
                                    color: "#333333",
                                    paddingRight: "10px"
                                  },
                                }}
                                classes={{ primary: classes.selectAllText }}
                                primary="Select All"
                              />
                            </MenuItem>
                            {/* gradesArray */}
                            { positionArray?.map((option: any) => (
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                  padding: "0px",
                                }}
                                key={option}
                                value={option}
                              >

                                <ListItemIcon>
                                  <Checkbox
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: "14px !important",
                                      },
                                    }}
                                    size="small"
                                    style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                    checked={positionsFilter.indexOf(option) > -1}
                                  />
                                </ListItemIcon>

                                <ListItemText
                                  primaryTypographyProps={{ fontSize: "13px", fontFamily: "arial", color: "#333333", paddingRight: "10px" }}
                                  primary={option} />
                              </MenuItem>

                            ))}
                          </Select>
                          {icon1 && (
                            <FilterAltTwoToneIcon />)}
                        </Stack>
                      </FormControl>
                    </div>
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Arial",
                      color: "#3E8CB5",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {/* <Stack direction="row" alignItems="center" >
                        <div
                          aria-controls={openserviceGrade ? "fade-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openserviceGrade ? "true" : undefined}
                          onClick={handleClickserviceGrade}
                        >
                          <Stack direction="row" alignItems="center">
                            Grade
                            <ArrowDropDownOutlinedIcon style={{ cursor: "pointer" }} />
                          </Stack>
                        </div>
                        <Menu
                          MenuListProps={{
                            "aria-labelledby": "fade-button",

                          }}
                          sx={{
                            height: "200px",
                            width: "90px"
                          }}
                          anchorEl={anchorElnewserviceGrade}
                          open={openserviceGrade}
                          onClose={handleCloseserviceGrade}

                        >
                          <MenuItem
                            style={{
                              fontSize: "14px",
                              fontFamily: "Arial",
                              color: "#333333",
                              justifyContent: "left"
                              //paddingLeft: "15px",
                              //height:"200px"
                            }}
                            key="None"
                            value="None"
                            onClick={handleTargetserviceGrade}
                          >None
                          </MenuItem>

                          {gradesArray.map((name: any) => (
                            <MenuItem
                              style={{
                                fontSize: "14px",
                                fontFamily: "Arial",
                                color: "#333333",
                                paddingLeft: "15px",
                              }}
                              key={name}
                              value={name}
                              onClick={handleTargetserviceGrade}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Menu>
                        {icon2 && <FilterAltTwoToneIcon />}
                      </Stack> */}
                    {/* <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                              <Stack direction="row">
                                <span>Grade</span>
                                <Select
                                  size="small"
                                  sx={{ width: "25px", fontSize: "0rem" }}
                                  disableUnderline
                                
                                  variant="standard"
                                  MenuProps={MenuProps}
                                  multiple
                                  value={GradeFilter}
                                  onChange={handleChangeSelectGrade}
                                 
                                  renderValue={(selected) => selected.join(', ')}
                                >
                                  <MenuItem
                                  
                                    key={0}
                                    value="None"
                                    sx={{
                                      padding: "0px",
                                      fontSize: "14px",
                                      paddingLeft: "37px",
                                      fontFamily: "Arial",
                                      color: "#333333"
                                    }}
                                  >
                                  
                                    <ListItemText
                                      primaryTypographyProps={{ fontSize: "14px", fontFamily: "Arial", color: "#333333" }}
                                      primary="None" />
                                  </MenuItem>

                                  {gradesArray?.map((name: any, index: any) => (
                                      <MenuItem
                                        sx={{
                                          padding: "0px",
                                          fontSize: "14px",
                                          fontFamily: "Arial",
                                          color: "#333333"
                                        }}
                                      
                                        key={name}
                                        value={name}
                                      >
                                        <Checkbox
                                          style={{ padding: "3px", paddingLeft: "12px" }}

                                          size="small"
                                          checked={GradeFilter.indexOf(name) > -1} />
                                        <ListItemText
                                          primaryTypographyProps={{ fontSize: "14px", fontFamily: "Arial", color: "#333333" }}
                                          primary={name} />
                                      </MenuItem>
                                    )
                                    )}
                                </Select>
                                {icon3 && (
                                  <FilterAltTwoToneIcon />)}
                              </Stack>
                            </FormControl> */}
                    <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                      <Stack direction="row">
                        <span>Grade</span>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          size="small"
                          sx={{ width: "25px", fontSize: "0rem",
                          "& .MuiSvgIcon-root": {
                            color:"#3e8cb5 !important"
                            }, }}
                          disableUnderline
                          variant="standard"
                          MenuProps={MenuProps}
                          multiple
                          value={GradesFilter}
                          onChange={handleChangeSelectSection}
                          renderValue={(selected) => selected.join(', ')}
                        >
                          <MenuItem
                            style={{
                              fontSize: "14px",
                              color: "#333333",
                              fontFamily: "Arial",
                              padding: "0px",
                              //paddingLeft: "37px",
                            }}
                            key="all"
                            value="all"
                            classes={{
                              root: isAllGradesFilter ? classes.selectedAll : "",
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                size="small"
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: "14px !important",
                                  },
                                }}
                                style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                classes={{
                                  indeterminate: classes.indeterminateColor,
                                }}
                                checked={isAllGradesFilter}
                                indeterminate={
                                  GradesFilter?.length > 0 &&
                                  GradesFilter?.length < gradesArray?.length
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "13px",
                                  fontFamily: "Arial",
                                  color: "#333333",
                                  paddingRight: "10px"
                                },
                              }}
                              classes={{ primary: classes.selectAllText }}
                              primary="Select All"
                            />
                          </MenuItem>
                          {/* gradesArray */}
                          {gradesArray?.map((option: any) => (


                            <MenuItem
                              style={{
                                fontSize: "14px",
                                color: "#333333",
                                fontFamily: "Arial",
                                padding: "0px",
                              }}
                              key={option}
                              value={option}
                            >

                              <ListItemIcon>
                                <Checkbox
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: "14px !important",
                                    },
                                  }}
                                  size="small"
                                  style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                  checked={GradesFilter.indexOf(option) > -1}
                                />
                              </ListItemIcon>

                              <ListItemText
                                primaryTypographyProps={{ fontSize: "13px", fontFamily: "Arial", color: "#333333", paddingRight: "10px" }}
                                primary={option} />
                            </MenuItem>

                          ))}
                        </Select>
                        {icon20 && (
                          <FilterAltTwoToneIcon />)}
                      </Stack>
                    </FormControl>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Arial",
                      color: "#3E8CB5",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <Stack direction="row" alignItems="center" justifyContent="center" >
                      <div
                        aria-controls={openProbationStatusVal ? "fade-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openProbationStatusVal ? "true" : undefined}
                        onClick={handleClickProbationStatus}
                      >
                        <Stack direction="row" alignItems="center">
                          Probation <br></br>Status
                          <ArrowDropDownOutlinedIcon style={{ cursor: "pointer" }} />
                        </Stack>
                      </div>
                      <Menu
                        MenuListProps={{
                          "aria-labelledby": "fade-button",

                        }}
                        sx={{
                          height: "200px",
                          // width: "290px"
                        }}
                        anchorEl={ProbationStatusVal}
                        open={openProbationStatusVal}
                        onClose={handleCloseProbationStatus}

                      >
                        <MenuItem
                          style={{
                            fontSize: "13px",
                            fontFamily: "Arial",
                            color: "#333333",
                            padding: "5px 15px 0px 15px",
                            //padding: "0px 10px 2px 17px",
                            //paddingLeft: "15px",
                            //height:"200px"
                          }}
                          key="None"
                          value="None"
                          onClick={handleTargetProbationStatus}
                        >Clear Filter
                        </MenuItem>
                        {users
                          .slice()
                          .sort(function (a: any, b: any) {
                            return a.probation_status - b.probation_status;
                          })
                          ?.filter((j: any) => {
                            return j?.isCEORole === true
                          })
                          ?.filter((item: any, index: any, array: any) => array?.map((data: any) => { return data?.probation_status }).indexOf(item?.probation_status) === index)
                          ?.filter((i: any) => {
                            if (searchNameForTabs.length > 0) {
                              const searchTerms = searchNameForTabs.toLowerCase().split(" ");
                              return searchTerms.every(term =>
                                i?.grade
                                  ?.toLowerCase()
                                  .includes(term)
                              ) || searchTerms.every(term =>
                                i?.position_long_description?.toLowerCase().includes(term)
                              )||
                              searchTerms.every((term: any) =>
                                i?.legal_full_name?.toLowerCase().includes(term)
                              )
                              ||
                              searchTerms.every((term: any) =>
                                i?.position_long_description?.toLowerCase().includes(term)
                              )||
                              searchTerms.every((term: any) =>
                                i?.employee_code?.toLowerCase().includes(term)
                              )||
                              searchTerms.every((term: any) =>
                                i?.probation_status?.toLowerCase().includes(term)
                              )||
                              searchTerms.every((term: any) =>
                                i?.appraiser_name?.toLowerCase().includes(term)
                              )||
                              searchTerms.every((term: any) =>
                              i?.reviewer_name?.toLowerCase().includes(term)
                            )||
                              searchTerms.every((term: any) =>
                                i?.normalizer_name?.toLowerCase().includes(term)
                              )
                            } else {
                              return true;
                            }
                          })
                          ?.map((name: any) => (
                            <MenuItem
                              style={{
                                fontSize: "13px",
                                fontFamily: "Arial",
                                color: "#333333",
                                padding: "5px 15px 0px 15px",
                                // padding: "0px 10px 2px 17px",
                                justifyContent: "left",
                                backgroundColor: ProbationStatus === name?.probation_status ? "#EAECED" : "",
                                //height:"200px"
                              }}
                              key={name?.probation_status}
                              value={name?.probation_status}
                              //name={name.legal_full_name}
                              onClick={handleTargetProbationStatus}
                            >
                              {name?.probation_status}
                            </MenuItem>
                          ))}
                        {/* <MenuItem
                            style={{
                              fontSize: "13px",
                              fontFamily: "Arial",
                              color: "#333333",
                              padding: "0px 10px 2px 17px",
                              //paddingLeft: "15px",
                              //height:"200px"
                            }}
                            key="Confirmed"
                            value="Confirmed"
                            onClick={handleTargetProbationStatus}
                          >
                            Confirmed
                          </MenuItem>
                          <MenuItem
                            style={{
                              fontSize: "13px",
                              fontFamily: "Arial",
                              color: "#333333",
                              padding: "0px 10px 2px 17px",
                              //paddingLeft: "15px",
                              //height:"200px"
                            }}
                            key="probation"
                            value="probation"
                            onClick={handleTargetProbationStatus}
                          >In probation
                          </MenuItem> */}

                      </Menu>
                      {icon13 && <FilterAltTwoToneIcon />}
                    </Stack>

                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Arial",
                      color: "#3E8CB5",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <Stack direction="row" alignItems="center" >
                      <div
                        aria-controls={openSupervisoryRole ? "fade-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openSupervisoryRole ? "true" : undefined}
                        onClick={handleClickSupervisoryRole}
                      >
                        <Stack direction="row" alignItems="center">
                          <span
                            style={{
                              whiteSpace: "pre-line",

                            }}
                          >

                            Supervisory Role
                          </span>
                          <ArrowDropDownOutlinedIcon style={{ cursor: "pointer" }} />
                        </Stack>
                      </div>
                      <Menu
                        MenuListProps={{
                          "aria-labelledby": "fade-button",

                        }}
                        sx={{
                          height: "200px",
                          // width: "300px"
                        }}
                        anchorEl={anchorElnewSupervisoryRole}
                        open={openSupervisoryRole}
                        onClose={handleCloseSupervisoryRole}

                      >
                        <MenuItem
                          style={{
                            fontSize: "13px",
                            fontFamily: "Arial",
                            color: "#333333",
                            padding: "0px 10px 2px 17px",
                            // width: "100px"
                            //paddingLeft: "15px",
                            //height:"200px"
                          }}
                          key="None"
                          value="None"
                          onClick={handleTargetSupervisoryRole}
                        >Clear Filter
                        </MenuItem>

                        {
                        // tableDataFilterdLength > 0
                        users?.filter((j: any) => {
                          return j.employee_upload_flag && j?.isCEORole === true
                        }).filter((j: any) => {
                          if (searchNameForTabs === "") {
                            return j;
                          } else if (
                            (j.employee_code !== undefined &&
                              j.employee_code
                                .toLocaleLowerCase()
                                .includes(searchNameForTabs.toLocaleLowerCase())) ||
                            (j.legal_full_name !== undefined &&
                              j.legal_full_name
                                .toLocaleLowerCase()
                                .includes(searchNameForTabs.toLocaleLowerCase())) ||
                            (j.first_name !== undefined &&
                              j.first_name
                                .toLocaleLowerCase()
                                .includes(searchNameForTabs.toLocaleLowerCase())) ||
                            (j.section !== undefined &&
                              j.section
                                .toLocaleLowerCase()
                                .includes(searchNameForTabs.toLocaleLowerCase())) ||
                            (j.position_long_description !== undefined &&
                              j.position_long_description
                                .toLocaleLowerCase()
                                .includes(searchNameForTabs.toLocaleLowerCase())) ||
                            (j.appraiser_name !== undefined &&
                              j.appraiser_name
                                .toLocaleLowerCase()
                                .includes(searchNameForTabs.toLocaleLowerCase())) ||
                            (j.probation_status !== undefined &&
                              j.probation_status
                                .toLocaleLowerCase()
                                .includes(searchNameForTabs.toLocaleLowerCase())) ||
                            (j.reviewer_name !== undefined &&
                              j.reviewer_name
                                .toLocaleLowerCase()
                                .includes(searchNameForTabs.toLocaleLowerCase())) ||
                            (j.function !== undefined &&
                              j.function
                                .toLocaleLowerCase()
                                .includes(searchNameForTabs.toLocaleLowerCase())) ||
                            (j.normalizer_name !== undefined &&
                              j.normalizer_name
                                .toLocaleLowerCase()
                                .includes(searchNameForTabs.toLocaleLowerCase())) ||
                            (j.grade !== undefined &&
                              j.grade
                                .toLocaleLowerCase()
                                .includes(searchNameForTabs.toLocaleLowerCase()))
                          ) {
                            return j;
                          }
                        })?.length >0 &&  sNSvalues
                          .map((name: any) => (
                            <MenuItem
                              style={{
                                fontSize: "13px",
                                fontFamily: "Arial",
                                color: "#333333",
                                // paddingLeft: "15px",
                                padding: "0px 10px 2px 17px",  
                                backgroundColor: sNS === name?.name  ? "#EAECED" : "",
                                // width: "100px"
                                //height:"200px"
                              }}
                              key={name?.name}
                              value={name?.name}
                              onClick={handleTargetSupervisoryRole}
                            >
                              {name?.name}
                            </MenuItem>
                          ))}
                      </Menu>
                      {icon9 && <FilterAltTwoToneIcon />}
                    </Stack>

                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Arial",
                      color: "#3E8CB5",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <Stack direction="row" alignItems="center" justifyContent="center"  >
                      <div
                        aria-controls={openSupervisoryRole ? "fade-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openSupervisoryRole ? "true" : undefined}
                        onClick={handleClickFunction}
                      >
                        <Stack direction="row" alignItems="center">
                          Function
                          <ArrowDropDownOutlinedIcon style={{ cursor: "pointer" }} />
                        </Stack>
                      </div>
                      <Menu
                        MenuListProps={{
                          "aria-labelledby": "fade-button",

                        }}
                        sx={{
                          height: "200px",
                          // width: "160px"
                        }}
                        anchorEl={anchorElnewFunction}
                        open={openFunction}
                        onClose={handleCloseFunction}

                      >
                        <MenuItem
                          style={{
                            fontSize: "13px",
                            fontFamily: "Arial",
                            color: "#333333",
                            padding: "0px 10px 2px 17px",                                  // width: "100px"
                            // width: "110px"
                            //paddingLeft: "15px",
                            //height:"200px"
                          }}
                          key="None"
                          value="None"
                          onClick={handleTargetFunction}
                        >Clear Filter
                        </MenuItem>
                        {users
                        ?.slice()
                        ?.sort(function (a: any, b: any) {
                          return a?.function - b?.function;
                        })
                        ?.filter((j: any) => {
                          return j?.isCEORole === true
                        })
                        ?.filter((i: any) => {
                          if (searchNameForTabs.length > 0) {
                            const searchTerms = searchNameForTabs.toLowerCase().split(" ");
                            return searchTerms.every(term =>
                              i?.grade
                                ?.toLowerCase()
                                .includes(term)
                            ) || searchTerms.every(term =>
                              i?.position_long_description?.toLowerCase().includes(term)
                            )||
                            searchTerms.every((term: any) =>
                              i?.legal_full_name?.toLowerCase().includes(term)
                            )
                            ||
                            searchTerms.every((term: any) =>
                              i?.position_long_description?.toLowerCase().includes(term)
                            )||
                            searchTerms.every((term: any) =>
                              i?.employee_code?.toLowerCase().includes(term)
                            )||
                            searchTerms.every((term: any) =>
                              i?.probation_status?.toLowerCase().includes(term)
                            )||
                            searchTerms.every((term: any) =>
                              i?.appraiser_name?.toLowerCase().includes(term)
                            )||
                            searchTerms.every((term: any) =>
                            i?.reviewer_name?.toLowerCase().includes(term)
                          )||
                            searchTerms.every((term: any) =>
                              i?.normalizer_name?.toLowerCase().includes(term)
                            )
                          } else {
                            return true;
                          }
                        })
                        ?.filter((item: any, index: any, array: any) => array?.map((data: any) => { return data?.function }).indexOf(item?.function) === index)
                          ?.map((name: any) => (
                            <MenuItem
                              style={{
                                fontSize: "13px",
                                fontFamily: "Arial",
                                color: "#333333",
                                padding: "0px 10px 2px 17px",
                                backgroundColor: empFunction === name?.function ? "#EAECED" : "",

                                //height:"200px"
                              }}
                              key={name?.function}
                              value={name?.function}
                              //name={name.legal_full_name}
                              onClick={handleTargetFunction}
                            >
                              {name?.function}
                            </MenuItem>
                          ))}



                      </Menu>
                      {icon6 && <FilterAltTwoToneIcon />}
                    </Stack>

                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Arial",
                      color: "#3E8CB5",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {/* <Stack direction="row" alignItems="center" justifyContent="center" >
                        <div
                          aria-controls={openSection ? "fade-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openSection ? "true" : undefined}
                          onClick={handleClickSection}
                        >
                          <Stack direction="row" alignItems="center">
                          Section
                            <ArrowDropDownOutlinedIcon style={{cursor: "pointer"}}/>
                          </Stack>
                        </div>
                        <Menu
                          MenuListProps={{
                            "aria-labelledby": "fade-button",

                          }}
                          sx={{
                            height:"200px",
                            width:"200px"
                          }}
                          anchorEl={anchorElnewSection}
                          open={openSection}
                          onClose={handleCloseSection }
                          
                        >
                           <MenuItem 
                          style={{
                            fontSize: "14px",
                            fontFamily: "Arial",
                            color: "#333333",
                            justifyContent:"left"
                            //paddingLeft: "15px",
                            //height:"200px"
                          }}
                          key="None"
                          value="None"
                           onClick={handleTargetSection}
                           >None
                           </MenuItem>
                         
                           {sectionArray.map((name: any) => (
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Arial",
                                  color: "#333333",
                                  paddingLeft: "15px",
                                }}
                                key={name}
                                value={name}
                                onClick={handleTargetSection}
                              >
                                {name}
                              </MenuItem>
                            ))}
                        </Menu>
                        {icon20 && <FilterAltTwoToneIcon />}
                        </Stack> */}
                    <FormControl sx={{ m: 0, width: 100, height: "0" }}>
                      <Stack direction="row" alignItems="center">
                        <span> Section </span>
                        <Select
                          size="small"
                          sx={{ width: "25px", fontSize: "0rem","& .MuiSvgIcon-root": {
                            color:"#3e8cb5 !important"
                            }, }}
                          disableUnderline
                          // value={personName}
                          // onChange={handleChanges}
                          onChange={handleChangesections}
                          // input={<OutlinedInput label="Name" />}
                          variant="standard"
                          MenuProps={MenuProps}
                        >
                          <MenuItem
                            style={{
                              fontSize: "13px",
                              fontFamily: "Arial",
                              color: "#333333",
                              padding: "0px 10px 2px 17px",                                  // width: "100px"
                            }}
                            key="None"
                            value="None"
                          >
                            Clear Filter
                          </MenuItem>

                          {sectionArray.map((name: any) => (
                            <MenuItem
                              style={{
                                fontSize: "13px",
                                fontFamily: "Arial",
                                color: "#333333",
                                padding: "0px 10px 2px 17px",
                                backgroundColor: empsections === name ? "#EAECED" : "",
                                // width: "100px"
                              }}
                              key={name}
                              value={name}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                        {icon10 && <FilterAltTwoToneIcon />}
                      </Stack>
                    </FormControl>
                  </TableCell>



                  <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Arial",
                      color: "#3E8CB5",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <div>
                      <Stack direction="row" alignItems="center" justifyContent="center">
                        <div
                          aria-controls={openserviceAppName ? "fade-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openserviceAppName ? "true" : undefined}
                          onClick={handleClickserviceAppName}
                        >
                          <Stack direction="row" alignItems="center">
                            Appraiser Name
                            <ArrowDropDownOutlinedIcon style={{ cursor: "pointer" }} />
                          </Stack>
                        </div>
                        <Menu
                          MenuListProps={{
                            "aria-labelledby": "fade-button",

                          }}
                          sx={{
                            height: "200px",
                            // width: "240px"
                          }}
                          anchorEl={anchorElnewserviceAppName}
                          open={openserviceAppName}
                          onClose={handleCloseserviceAppName}

                        >
                          <MenuItem
                            style={{
                              fontSize: "13px",
                              fontFamily: "Arial",
                              color: "#333333",
                              // justifyContent:"left",
                              // width:"100px",
                              padding: "0px 10px 2px 17px",                                  // width: "100px"

                              //height:"200px"
                            }}
                            key="None"
                            value="None"
                            onClick={handleTargetserviceAppName}
                          >Clear Filter
                          </MenuItem>

                          {appraiserNames
                          ?.slice()
                          ?.sort(function (a: any, b: any) {
                            return a?.appraiser_name?.localeCompare(
                              b?.appraiser_name
                            );
                          })?.map((name: any) => (
                            <MenuItem
                              style={{
                                fontSize: "13px",
                                fontFamily: "Arial",
                                color: "#333333",
                                padding: "0px 10px 2px 17px", 
                                backgroundColor: AppName === name ? "#EAECED" : "",

                                // width: "100px"
                              }}
                              key={name}
                              value={name}
                              onClick={handleTargetserviceAppName}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Menu>
                        {icon2 && <FilterAltTwoToneIcon />}
                      </Stack>

                    </div>
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Arial",
                      color: "#3E8CB5",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <div>
                      <Stack direction="row" alignItems="center" justifyContent="center">
                        <div
                          aria-controls={openserviceRevName ? "fade-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openserviceRevName ? "true" : undefined}
                          onClick={handleClickserviceRevName}
                        >
                          <Stack direction="row" alignItems="center">
                            Reviewer Name
                            <ArrowDropDownOutlinedIcon style={{ cursor: "pointer" }} />
                          </Stack>
                        </div>
                        <Menu
                          MenuListProps={{
                            "aria-labelledby": "fade-button",

                          }}
                          sx={{
                            height: "200px",
                            // width:"240px"
                          }}
                          anchorEl={anchorElnewserviceRevName}
                          open={openserviceRevName}
                          onClose={handleCloseserviceRevName}

                        >
                          <MenuItem
                            style={{
                              fontSize: "13px",
                              fontFamily: "Arial",
                              color: "#333333",
                              padding: "0px 10px 2px 17px",                                  // width: "100px"
                              // width:"100px",
                              // paddingLeft: "16px",

                              //height:"200px"
                            }}
                            key="None"
                            value="None"
                            onClick={handleTargetserviceRevName}
                          >Clear Filter
                          </MenuItem>

                          {ReviewerNames
                          .slice()
                          ?.sort(function (a: any, b: any) {
                            return a?.reviewer_name?.localeCompare(
                              b?.reviewer_name
                            );
                          })?.map((name: any) => (
                            <MenuItem
                              style={{
                                fontSize: "13px",
                                fontFamily: "Arial",
                                color: "#333333",
                                padding: "0px 10px 2px 17px",
                                backgroundColor: RevName === name ? "#EAECED" : "",
                                // width: "100px"
                              }}
                              key={name}
                              value={name}
                              onClick={handleTargetserviceRevName}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Menu>
                        {icon3 && <FilterAltTwoToneIcon />}
                      </Stack>

                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Arial",
                      color: "#3E8CB5",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <div>
                      <Stack direction="row" alignItems="center" justifyContent="center">
                        <div
                          aria-controls={openserviceNorName ? "fade-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openserviceNorName ? "true" : undefined}
                          onClick={handleClickserviceNorName}
                        >
                          <Stack direction="row" alignItems="center">
                          HR Normalizer Name
                            <ArrowDropDownOutlinedIcon style={{ cursor: "pointer" }} />
                          </Stack>
                        </div>
                        <Menu
                          MenuListProps={{
                            "aria-labelledby": "fade-button",

                          }}
                          sx={{
                            height: "200px",
                            // width:"240px"
                          }}
                          anchorEl={anchorElnewserviceNorName}
                          open={openserviceNorName}
                          onClose={handleCloseserviceNorName}

                        >
                          <MenuItem
                            style={{
                              fontSize: "13px",
                              fontFamily: "Arial",
                              color: "#333333",
                              padding: "0px 10px 2px 17px",
                              // width: "100px"
                              // justifyContent:"left",
                              // width:"100px",
                              // paddingLeft: "16px",

                              //height:"200px"
                            }}
                            key="None"
                            value="None"
                            onClick={handleTargetserviceNorName}
                          >Clear Filter
                          </MenuItem>

                          { NormalizerNames
                            .slice()
                            ?.sort(function (a: any, b: any) {
                              return a?.normalizer_name?.localeCompare(
                                b?.normalizer_name
                              );
                            })?.map((name: any) => (
                            <MenuItem
                              style={{
                                fontSize: "13px",
                                fontFamily: "Arial",
                                color: "#333333",
                                padding: "0px 10px 2px 17px", 
                                backgroundColor: norName === name ? "#EAECED" : "",
                                // width: "100px"
                              }}
                              key={name}
                              value={name}
                              onClick={handleTargetserviceNorName}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Menu>
                        {icon5 && <FilterAltTwoToneIcon />}
                      </Stack>

                    </div>
                  </TableCell>
                  {/* <TableCell
                    align="center"
                    sx={{
                      fontFamily: "Arial",
                      color: "#3E8CB5",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Action
                  </TableCell> */}
                   <TableCell
                      align="center"
                      sx={{
                        fontFamily: "Arial",
                        color: "#3E8CB5",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                        <Stack direction="row">
                          <span>Department</span>
                        </Stack>
                      </FormControl>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontFamily: "Arial",
                        color: "#3E8CB5",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                        <Stack direction="row">
                          <span>Department Head</span>
                        </Stack>
                      </FormControl>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontFamily: "Arial",
                        color: "#3E8CB5",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                        <Stack direction="row">
                          <span>Joining Date</span>
                        </Stack>
                      </FormControl>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontFamily: "Arial",
                        color: "#3E8CB5",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                        <Stack direction="row">
                          <span>Tenure</span>
                        </Stack>
                      </FormControl>
                    </TableCell>
                </TableRow>
              </TableHead>

              {tableDataFilterdLength > 0 && <TableBody>
                {data != undefined &&
                  // (rowsPerPage > 0
                  //   ? data.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  //   : data.data
                  // )
                  users?.filter((j: any) => {
                    return j.employee_upload_flag && j?.isCEORole === true
                  })?.filter((j: any) => {
                    if (positions === "None" || positions === "") {
                      return j;
                    } else {
                      return j?.position_long_description
                        ?.toLocaleLowerCase() === positions?.toLocaleLowerCase();
                      //.includes(positions.toLocaleLowerCase());
                      // return positions.toLocaleLowerCase().include(j.position_long_description.toLocaleLowerCase())
                    }
                  })
                    .filter((item1: any) => {
                      if (GradesFilter.includes("None") || GradesFilter.length === 0) {
                        return item1;
                      } else {
                        return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
                      }
                    })
                    .filter((item1: any) => {
                      if (positionsFilter.includes("None") || positionsFilter.length === 0) {
                        return item1;
                      } else {
                        return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
                      }
                    })
                    .filter((item1: any) => {
                      if (positionFilter.includes("None") || positionFilter.length === 0) {
                        return item1;
                      } else {
                        return !!positionFilter?.find((item2: any) => item1?.position_long_description === item2)
                      }
                    })
                    .filter((item1: any) => {
                      if (GradeFilter.includes("None") || GradeFilter.length === 0) {
                        return item1;
                      } else {
                        return !!GradeFilter?.find((item2: any) => item1?.grade === item2)
                      }
                    })
                    .filter((j: any) => {
                      if (
                        empEmployeeCode === "None" ||
                        empEmployeeCode === "" ||
                        empEmployeeCode === "0"
                      ) {
                        return j;
                      } else {
                        return j?.employee_code?.toLocaleLowerCase() === empEmployeeCode?.toLocaleLowerCase();

                        // .includes(empEmployeeCode?.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (empgrades === "None" || empgrades === "") {
                        return j;
                      } else {
                        return j?.grade
                          ?.toLocaleLowerCase() === empgrades?.toLocaleLowerCase();
                        //.includes(empgrades.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (AppName === "None" || AppName === "") {
                        return j;
                      } else {
                        return j?.appraiser_name
                          ?.toLocaleLowerCase() === AppName?.toLocaleLowerCase();
                        //.includes(empgrades.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (RevName === "None" || RevName === "") {
                        return j;
                      } else {
                        return j?.reviewer_name
                          ?.toLocaleLowerCase() === RevName?.toLocaleLowerCase();
                        //.includes(empgrades.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (norName === "None" || norName === "") {
                        return j;
                      } else {
                        return j?.normalizer_name
                          ?.toLocaleLowerCase() === norName?.toLocaleLowerCase();
                        //.includes(empgrades.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (empdivisions === "None" || empdivisions === "") {
                        return j;
                      } else {
                        return j?.division
                          ?.toLocaleLowerCase() === empdivisions?.toLocaleLowerCase();
                        //.includes(empdivisions.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (empFullName === "None" || empFullName === "") {
                        return j;
                      } else {
                        return j?.legal_full_name
                          ?.toLocaleLowerCase() === empFullName?.toLocaleLowerCase();
                        //?.includes(empFullName?.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (empFirstName === "None" || empFirstName === "") {
                        return j;
                      } else {
                        return j?.first_name
                          ?.toLocaleLowerCase() === empFirstName?.toLocaleLowerCase();
                        //?.includes(empFirstName?.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (empFunction === "None" || empFunction === "") {
                        return j;
                      } else {
                        return j.function.toLocaleLowerCase() === empFunction?.toLocaleLowerCase()
                        // .includes(
                        //   empFunction.toLocaleLowerCase()
                        // );
                      }
                    })
                    .filter((j: any) => {
                      if (
                        empPositionCode === "None" ||
                        empPositionCode === ""
                      ) {
                        return j;
                      } else {
                        return j?.position_code
                          ?.toLocaleLowerCase() === empPositionCode?.toLocaleLowerCase();
                        //?.includes(empPositionCode?.toLocaleLowerCase());
                      }
                    })

                    .filter((j: any) => {
                      if (empService === "None" || empService === "") {
                        return j;
                      } else {
                        return j?.service_reference_date
                          ?.toLocaleLowerCase() === empService?.toLocaleLowerCase();
                        //.includes(empService.toLocaleLowerCase());
                      }
                    })
                    // .filter((j: any) => {
                    //   if (empSubSection === "None" || empSubSection === "") {
                    //     return j;
                    //   } else {
                    //     return j.Service
                    //       .toLocaleLowerCase()
                    //       .includes(empSubSection.toLocaleLowerCase());
                    //   }
                    // })
                    .filter((j: any) => {
                      //console.log(j.isSupervisor, "superv");
                      if (sNS === "None" || sNS === "") {
                        return j;
                      }
                      if (sNS === "SP") {
                        return j?.isSupervisor === true;
                      } else if (sNS === "N-SP") {
                        return j?.isSupervisor === false;
                      }
                    })
                    .filter((j: any) => {
                      if (empSubSection === "None" || empSubSection === "") {
                        return j;
                      } else {
                        return j["sub section"]
                          ?.toLocaleLowerCase() === empSubSection?.toLocaleLowerCase();
                        //.includes(empSubSection.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (empGradeset === "None" || empGradeset === "") {
                        return j;
                      } else {
                        return j?.grade_set
                          ?.toLocaleLowerCase() === empGradeset?.toLocaleLowerCase();
                        //?.includes(empGradeset?.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (empManagerCode === "None" || empManagerCode === "") {
                        return j;
                      } else {
                        return j?.manager_code
                          ?.toLocaleLowerCase() === empManagerCode?.toLocaleLowerCase();
                        //?.includes(empManagerCode?.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (
                        empManagerPosition === "None" ||
                        empManagerPosition === ""
                      ) {
                        return j;
                      } else {
                        return j?.manager_position
                          ?.toLocaleLowerCase() === empManagerPosition?.toLocaleLowerCase();
                        //?.includes(empManagerPosition?.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (empManagerName === "None" || empManagerName === "") {
                        return j;
                      } else {
                        return j?.manager_name
                          ?.toLocaleLowerCase() === empManagerName?.toLocaleLowerCase();
                        //?.includes(empManagerName?.toLocaleLowerCase());
                      }
                    })
                    .filter((item1: any) => {
                      if (sectionsFilter.includes("None") || sectionsFilter.length === 0) {
                        return item1;
                      } else {
                        return !!sectionsFilter?.find((item2: any) => item1?.section === item2)
                      }
                    })
                    .filter((j: any) => {
                      if (empjobtitle === "None" || empjobtitle === "") {
                        return j;
                      } else {
                        return j?.job_title
                          ?.toLocaleLowerCase() === empjobtitle?.toLocaleLowerCase();
                        //?.includes(empjobtitle?.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (empJobcode === "None" || empJobcode === "") {
                        return j;
                      } else {
                        return j?.job_code
                          ?.toLocaleLowerCase() === empJobcode?.toLocaleLowerCase();
                        //?.includes(empJobcode?.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (empjoblevel === "None" || empjoblevel === "") {
                        return j;
                      } else {
                        return j?.job_level
                          ?.toLocaleLowerCase() === empjoblevel?.toLocaleLowerCase();
                        //?.includes(empjoblevel?.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (
                        empWorkLocation === "None" ||
                        empWorkLocation === ""
                      ) {
                        return j;
                      } else {
                        return j?.work_location
                          ?.toLocaleLowerCase() === empWorkLocation?.toLocaleLowerCase();
                        //?.includes(empWorkLocation?.toLocaleLowerCase());
                      }
                    })
                    .filter((j: any) => {
                      if (empsections === "None" || empsections === "") {
                        return j;
                      } else {
                        return j.section
                          .toLocaleLowerCase() === empsections?.toLocaleLowerCase();
                        //.includes(empsections.toLocaleLowerCase());
                      }
                    }).filter((j: any) => {
                      if (searchNameForTabs === "") {
                        return j;
                      }else if (
                        (j.employee_code !== undefined &&
                          j.employee_code
                            .toLocaleLowerCase()
                            .includes(searchNameForTabs.toLocaleLowerCase())) ||
                        (j.legal_full_name !== undefined &&
                          j.legal_full_name
                            .toLocaleLowerCase()
                            .includes(searchNameForTabs.toLocaleLowerCase())) ||
                        (j.first_name !== undefined &&
                          j.first_name
                            .toLocaleLowerCase()
                            .includes(searchNameForTabs.toLocaleLowerCase())) ||
                        (j.section !== undefined &&
                          j.section
                            .toLocaleLowerCase()
                            .includes(searchNameForTabs.toLocaleLowerCase())) ||
                        (j.position_long_description !== undefined &&
                          j.position_long_description
                            .toLocaleLowerCase()
                            .includes(searchNameForTabs.toLocaleLowerCase())) ||
                        (j.appraiser_name !== undefined &&
                          j.appraiser_name
                            .toLocaleLowerCase()
                            .includes(searchNameForTabs.toLocaleLowerCase())) ||
                        (j.probation_status !== undefined &&
                          j.probation_status
                            .toLocaleLowerCase()
                            .includes(searchNameForTabs.toLocaleLowerCase())) ||
                        (j.reviewer_name !== undefined &&
                          j.reviewer_name
                            .toLocaleLowerCase()
                            .includes(searchNameForTabs.toLocaleLowerCase())) ||
                        (j.function !== undefined &&
                          j.function
                            .toLocaleLowerCase()
                            .includes(searchNameForTabs.toLocaleLowerCase())) ||
                        (j.normalizer_name !== undefined &&
                          j.normalizer_name
                            .toLocaleLowerCase()
                            .includes(searchNameForTabs.toLocaleLowerCase())) ||
                        (j.grade !== undefined &&
                          j.grade
                            .toLocaleLowerCase()
                            .includes(searchNameForTabs.toLocaleLowerCase()))
                      ) {
                        return j;
                      }
                      else if(searchNameForTabs !== ""){
                        if (searchNameForTabs == "SP") {
                          return j?.isSupervisor == true;
                        }else if (searchNameForTabs == "N-SP") {
                            return j?.isSupervisor != true;
                          }
                      }
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    //  (rowsPerPage > 0
                    //    ? data.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    //    : data.data
                    //  )
                    .map((emp: any, index: number) => {
                      const inputDate = emp?.JoiningDate
                      let formattedDate;
                      if (inputDate && typeof inputDate === 'string') {
                        const JoiningDate = inputDate.replace("+", "");
                         const dateParts = JoiningDate.split("-");
                        if (dateParts.length >= 3) {
                            const year = dateParts[0];
                            const month = new Date(inputDate).toLocaleString("default", { month: "short" });
                            const day = dateParts[2].slice(0, 2);
                    
                            formattedDate = `${day}-${month}-${year}`;
                            console.log(formattedDate,"formattedDate"); // Output formatted date                           
                        }else {
                          console.log("JoiningDate does not have enough parts to split.");
                      }
                      }else {
                        console.log("JoiningDate is not defined or not a valid string.");
                    }
                        return (
                      <TableRow
                        key={index}
                        sx={{
                          "& td, & th": {
                            whiteSpace: "nowrap",
                            // border: 1,
                            // borderColor: "#e0e0e0",
                          },
                        }}
                      >
                        {checkedSwitch === false && <TableCell width="0.5%" align="center">
                          <input
                            type="checkbox"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                            name={emp._id}
                            checked={
                              emp?.isSelected
                            }
                            onChange={handleOnCheck}
                          />
                        </TableCell>}
                     
                          <TableCell
                            align="center"
                            // width={250}
                            sx={{
                              fontSize: "14px",
                              color: "#333333",
                              fontFamily: "Arial",
                              paddingRight: "40px"
                            }}
                          >
                            {emp?.employee_code}
                          </TableCell>
                     
                     
                          <TableCell
                            align="left"
                            // width={250}
                            sx={{
                              fontSize: "14px",
                              color: "#333333",
                              fontFamily: "Arial",
                            }}
                            style={{ minWidth: 250 }}
                          >
                            {emp?.legal_full_name}
                          </TableCell>
                   
                         <TableCell
                            align="left"
                            // width={250}
                            sx={{
                              fontSize: "14px",
                              color: "#333333",
                              fontFamily: "Arial",
                            }}
                            // style={{ minWidth: 250 }}
                          >
                            {emp?.first_name}
                          </TableCell>

                     
                          <TableCell
                            align="left"
                            // width={200}
                            sx={{
                              fontSize: "14px",
                              color: "#333333",
                              fontFamily: "Arial",
                            }}
                            style={{ minWidth: 250 }}
                          >
                            {emp?.position_long_description}
                          </TableCell>
                    
                        <TableCell
                          align="center"
                          // width={150}
                          sx={{
                            fontSize: "14px",
                            color: "#333333",
                            fontFamily: "Arial",
                            paddingRight: "50px"

                          }}
                        >
                          {emp.grade}
                        </TableCell>
                        <TableCell
                          align="left"
                          // width={150}
                          sx={{
                            fontSize: "14px",
                            color: "#333333",
                            fontFamily: "Arial",
                            paddingRight: "40px"

                          }}
                        >
                          {emp?.probation_status}
                        </TableCell>
                        <TableCell
                          align="center"
                          // width={250}
                          sx={{
                            fontSize: "14px",
                            color: "#333333",
                            fontFamily: "Arial",
                            paddingRight: "40px"

                          }}
                        >
                           {emp.isSupervisor != true ? "N-SP" : "SP"}
                        </TableCell>
                        <TableCell
                          align="left"
                          // width={250}
                          sx={{
                            fontSize: "14px",
                            color: "#333333",
                            fontFamily: "Arial",
                          }}
                        >

                          {emp?.function}
                        </TableCell>
                     
                          <TableCell
                            align="left"
                            // width={200}
                            sx={{
                              fontSize: "14px",
                              color: "#333333",
                              fontFamily: "Arial",
                            }}
                          >
                            {emp?.section}
                          </TableCell>
                      
                        <TableCell
                          align="left"
                          // width={200}
                          sx={{
                            fontSize: "14px",
                            color: "#333333",
                            fontFamily: "Arial",
                          }}
                        >
                          {emp?.appraiser_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          // width={200}
                          sx={{
                            fontSize: "14px",
                            color: "#333333",
                            fontFamily: "Arial",
                          }}
                        >
                          {emp?.reviewer_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          // width={200}
                          sx={{
                            fontSize: "14px",
                            color: "#333333",
                            fontFamily: "Arial",
                          }}
                        >
                          {emp?.normalizer_name}
                        </TableCell>
                        {/* <TableCell
                          align="justify"
                          sx={{
                            fontFamily: "Arial",
                            borderColor: "lightgrey",
                            fontSize: "14px",
                            color: "#333333",
                            wordBreak: "break-word",
                          }}
                        > */}
                          {/* <Tooltip title="Edit">
                              <IconButton
                                aria-label="EditIcon"                             
                                onClick={(e) => {handleEdit(emp?.employee_code)}}
                              >
                                <img src={Edit} alt="icon" />
                              </IconButton>
                            </Tooltip> */}
                          {/* <Tooltip title="Remove">
                            <IconButton
                              aria-label="CancelOutlinedIcon "
                              onClick={(e) => { handleRemove(emp?._id) }}

                            >
                              <img src={Close} alt="icon" />
                            </IconButton>
                          </Tooltip> */}
                        {/* </TableCell> */}
                        <TableCell
                              align="left"
                              // width={200}
                              sx={{
                                fontSize: "14px",
                                color: "#333333",
                                whiteSpace: "nowrap",
                                fontFamily: "Arial",
                              }}
                            >
                              {emp.Department}
                            </TableCell> <TableCell
                              align="left"
                              // width={200}
                              sx={{
                                fontSize: "14px",
                                color: "#333333",
                                whiteSpace: "nowrap",
                                fontFamily: "Arial",
                              }}
                            >
                              {emp.DepartmentHead}
                            </TableCell> <TableCell
                              align="left"
                              // width={200}
                              sx={{
                                fontSize: "14px",
                                color: "#333333",
                                whiteSpace: "nowrap",
                                fontFamily: "Arial",
                              }}
                            >
                              {formattedDate}
                            </TableCell> <TableCell
                              align="left"
                              // width={200}
                              sx={{
                                fontSize: "14px",
                                color: "#333333",
                                whiteSpace: "nowrap",
                                fontFamily: "Arial",
                              }}
                            >
                              {emp.Tenure}
                            </TableCell>
                      </TableRow>
                    )}
                    )}
              </TableBody>}
              {tableDataFilterdLength == 0 && <TableBody>
 <TableRow>
   <TableCell 
   colSpan={6}
   align="center" 
   style={{ fontWeight: 'bold',border:"none",color:"#808080",fontSize:"18px",fontFamily:"arial",display:"flex",width:"max-content" }}
   >
     No data to display
   </TableCell>
 </TableRow>
</TableBody>
              }
            </Table>
          </CustomScrollbar>
        </Scroll>
        <AlertDialogSuccess
          isAlertOpen={LaunchValDialog}
          handleAlertClose={handleLaunchValDialog}
        >
          {LaunchValDialogMSG}

        </AlertDialogSuccess>
        </>
      {/* } */}
      {/* {tableDataLength?.length === 0 && <div
        style={{
          fontFamily: "Arial",
          fontSize: "18px",
          fontWeight: "500",
          color: "#808080",
          display:"flex",
          width:"maxContent",
          // display: "flex",
          // justifyContent: "center",
           paddingTop: "30px"
        }}
      >
        No data to display
      </div>} */}
      {/* {(tableDataLength?.length > 0 && tableDataFilterdLength > 0) &&  */}
      <TablePagination
        sx={{
          "& .MuiTablePagination-selectLabel": {
            fontFamily: "Arial",
            fontSize: "14px",
            color: "#333333",
          },
        }}
        rowsPerPageOptions={[5, 10, 20, 50]}
        component="div"
        count={page1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
       {/* } */}
    </>
  );
}
