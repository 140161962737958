import { TextField, styled } from '@mui/material';
import React from 'react'

const AppraiserMessageHeader = styled("div")({
    color: "#717171",
    fontSize: "16px",
    fontFamily: "arial",
    marginBottom:"10px"
});

const AppraiserMessageTextField = styled(TextField)({
    width: "100%",
    // marginLeft: "25px",
    backgroundColor: "#f8f8f8",
    borderRadius: "5px",
    "& .MuiInputBase-input": {
        color: "#333333",
        fontSize: "14px",
        fontFamily: "arial",
        fontWeight: "400",
        textTransform: "none",
    },
});

const AppraiserMessageForEmployeeEdit = (props: any) => {
    const {
        disableTextAfterSubmission,
        moveTab,
        setMoveTab,
        navPrompt,
        setNavPrompt,
        appraiserMessageForEmployee,
        setAppraiserMessageForEmployee,
        showAppraiserMessageForEmployee,
        employeeDataIsFetching,
        employeeDataIsLoading
    } = props;
    return (
        <>
            {showAppraiserMessageForEmployee && (
                <div style={{ 
                    // marginRight: "33px",
                     marginBottom: "20px" }} >
                    <AppraiserMessageHeader> <b>Appraiser Message for Employee</b> </AppraiserMessageHeader>

                    <AppraiserMessageTextField
                        size="small"
                        multiline
                        placeholder="Add"
                        autoComplete="off"
                        value={appraiserMessageForEmployee}
                        disabled={employeeDataIsFetching || employeeDataIsLoading}
                        onChange={(e) => {
                            setAppraiserMessageForEmployee(e.target.value);
                            setNavPrompt(true);
                            setMoveTab(true);
                        }}
                        InputProps={{ readOnly: disableTextAfterSubmission }}
                    />
                </div>
            )}
        </>)
}

export default AppraiserMessageForEmployeeEdit