import React from "react";
import { Stack, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { APPRAISER, APPRAISER_SUBMISSION,APPRAISER_RESUBMISSION,NORMALIZER_REJECT,REVIEWER_REJECT, EMPLOYEE_APPRAISER_SUBMISSION, EMPLOYEE_LANDING,EMPLOYEE_REJECT, EMPLOYEE_REJECTS, NORMALIZER_ACTION,NORMALIZER_ACCEPTANCE, NORMALIZER_APPROVE, NORMALIZER_REJECTION,REVIEWER_ACCEPTANCE, REVIEWER_APPROVE, REVIEWER_APPROVE_APPRAISER_EMPLOYEEREJECTION, REVIEWER_REJECTION, REVIEWER_REJECT_APPRAISER_EMPLOYEEREJECTION, EMPLOYEE_REVIEWER_ACCEPTANCE, APPRAISER_ACTION, NORMALIZER_ACTION_SCREEN, EMPLOYEE_REVIEWER_REJECT, APPRAISER_OVERVIEW } from "../../constants/routes/Routing";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const BreadCrumbForPreviousAppraisal = (props: any) => {
  const { NAVIGATION_FROM } = props;
  const { employee_id } = useParams();
  const [titleDisplay, setTitleDisplay] = React.useState<any>();
  React.useEffect(()=>{
    if(NAVIGATION_FROM == "Appraiseroverview"){
      setTitleDisplay(APPRAISER_OVERVIEW)
    }else if(NAVIGATION_FROM =="Appraiser-action"){
      setTitleDisplay(APPRAISER_ACTION)
    }else if (NAVIGATION_FROM == "Appraiser-submission"){
    setTitleDisplay(APPRAISER_RESUBMISSION)
    }else if(NAVIGATION_FROM =="Reviewer-Approve"){
    setTitleDisplay(REVIEWER_ACCEPTANCE)}
    else if(NAVIGATION_FROM =="Reviewer-Rejection"){
      setTitleDisplay(REVIEWER_REJECT)
    }else if(NAVIGATION_FROM == "Employee-Reviewer-accpetance"){
      setTitleDisplay(EMPLOYEE_REVIEWER_ACCEPTANCE)
    }else if(NAVIGATION_FROM == "Employee-Reviewer-rejection"){
      setTitleDisplay(EMPLOYEE_REVIEWER_REJECT)
    }else if (NAVIGATION_FROM=="Normalizer-Approve"){
      setTitleDisplay(NORMALIZER_ACCEPTANCE)
    }else if (NAVIGATION_FROM=="Normalizer-Reject"){
      setTitleDisplay(NORMALIZER_REJECT)
    }else if(NAVIGATION_FROM == "Normalizer-Action"){
      setTitleDisplay(NORMALIZER_ACTION_SCREEN)
    }
  })
  console.log(NAVIGATION_FROM,"NAVIGATION_FROM")
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={0}
      minHeight="50px"
      marginLeft="25px"
    >{(NAVIGATION_FROM == "EmployeeLanding" || NAVIGATION_FROM == "EmployeeReject") && 
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            fontSize: "18px",
            color: "#3e8cb5",
            fontFamily: "Arial",
          }}
          color="inherit"
          to={`${EMPLOYEE_LANDING}/employee/${employee_id}`}
        >
          Home
        </Link>

        {(NAVIGATION_FROM === "EmployeeReject") && (
          <Link
            style={{
              fontSize: "18px",
              color: "#3e8cb5",
              fontFamily: "Arial",
            }}
            color="inherit"
            to={`${EMPLOYEE_REJECT}/employee/${employee_id}`}
          >
            Employee Rejection
          </Link>
        )}

        <Typography
          style={{
            fontSize: "18px",
            color: "#333333",
            fontFamily: "Arial",
          }}
        >
          Previous PA
        </Typography>
      </Breadcrumbs>
        }
      {/* {titleDisplay !== "" && */}
      {(NAVIGATION_FROM == "Appraiseroverview" || NAVIGATION_FROM == "Appraiser-action" || NAVIGATION_FROM == "Appraiser-submission" )&&

        <Breadcrumbs aria-label="breadcrumb">
          <Link
            style={{
              fontSize: "18px",
              color: "#3e8cb5",
              fontFamily: "Arial",
            }}
            color="inherit"
            to={"/dashboardreview"}
          >
            My Team Dashboard
          </Link>

          <Link
            style={{
              fontSize: "18px",
              color: "#3e8cb5",
              fontFamily: "Arial",
            }}
            color="inherit"
            to={`/dashboardreview`}
            state={{
              from: 1,
            }}
          >
            My Action
          </Link>
          <Link
            style={{
              fontSize: "18px",
              color: "#3e8cb5",
              fontFamily: "Arial",
            }}
            color="inherit"
            to={`${titleDisplay}/employee/${employee_id}`}
          >
            PA Overview
          </Link>

          <Typography
            style={{
              fontSize: "18px",
              color: "#333333",
              fontFamily: "Arial",
            }}
          >
            Previous PA
          </Typography>
        </Breadcrumbs>
      }
      {(NAVIGATION_FROM =="Reviewer-Approve" || NAVIGATION_FROM =="Reviewer-Rejection" || NAVIGATION_FROM == "Employee-Reviewer-rejection")  && 
       <Breadcrumbs aria-label="breadcrumb">
          <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit" to={`/reviewer`} 
              state={{
                from: "My Team Dashboard"
              }}>
                 My Team Dashboard
                 </Link>
                 <Link style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
                color="inherit"
                to={`/reviewer`}
                state={{
                  from: `${1}`
                }}

              >My Actions
              </Link>
          <Link
            style={{
              fontSize: "18px",
              color: "#3e8cb5",
              fontFamily: "Arial",
            }}
            color="inherit"
            to={`${titleDisplay}/employee/${employee_id}`}
          >
            PA Overview
          </Link>

          <Typography
            style={{
              fontSize: "18px",
              color: "#333333",
              fontFamily: "Arial",
            }}
          >
            Previous PA
          </Typography>
        </Breadcrumbs>
           }
           {(NAVIGATION_FROM == "Employee-Reviewer-accpetance") &&
            <Breadcrumbs aria-label="breadcrumb">
            <Link
                style={{
                  fontSize: "18px",
                  color: "#3e8cb5",
                  fontFamily: "Arial",
                }}
                color="inherit" to={`/reviewer`} 
                state={{
                  from: "My Team Dashboard"
                }}>
                   My Team Dashboard
                   </Link>
                   <Link style={{
                  fontSize: "18px",
                  color: "#3e8cb5",
                  fontFamily: "Arial",
                }}
                  color="inherit"
                  to={`/reviewer`}
                  state={{
                    from: `${1}`
                  }}
  
                >My Actions
                </Link>
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={`${titleDisplay}/employee/${employee_id}`}
            >
              PA Overview
            </Link>
  
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
            >
              Previous PA
            </Typography>
          </Breadcrumbs>
           }
           {(NAVIGATION_FROM =="Normalizer-Reject") && 
            <Breadcrumbs aria-label="breadcrumb">
                         <Link
                style={{
                  fontSize: "18px",
                  color: "#3e8cb5",
                  fontFamily: "Arial",
                }}
                color="inherit" to={`/normalizer`}
                state={{
                  from: "toMyTeamDashboard" 
                }}> My Team Dashboard
                </Link>
                 <Link style={{
              fontSize: "18px",
              color: "#3e8cb5",
              fontFamily: "Arial",
            }} 
            color="inherit" 
            to={`/normalizer`}
            state={{
              from:`${1}`
            }}
           
            >My Actions</Link>
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={`${titleDisplay}/employee/${employee_id}`}
            >
              PA Overview
            </Link>
  
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
            >
              Previous PA
            </Typography>
          </Breadcrumbs>
           }
      {(NAVIGATION_FROM == "Normalizer-Approve" ||  NAVIGATION_FROM == "Normalizer-Action" )&&
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            style={{
              fontSize: "18px",
              color: "#3e8cb5",
              fontFamily: "Arial",
            }}
            color="inherit" to={`/normalizer`}
            state={{
              from: "toMyTeamDashboard" 
            }}> My Team Dashboard
          </Link>
          <Link style={{
            fontSize: "18px",
            color: "#3e8cb5",
            fontFamily: "Arial",
          }}
            color="inherit"
            to={`/normalizer`}
            state={{
              from: `${1}`
            }}

          >My Actions</Link>
          <Link
            style={{
              fontSize: "18px",
              color: "#3e8cb5",
              fontFamily: "Arial",
            }}
            color="inherit"
            to={`${titleDisplay}/employee/${employee_id}`}
          >
            PA Overview
          </Link>

          <Typography
            style={{
              fontSize: "18px",
              color: "#333333",
              fontFamily: "Arial",
            }}
          >
            Previous PA
          </Typography>
        </Breadcrumbs>}
    </Stack>
  );
};

export default BreadCrumbForPreviousAppraisal;