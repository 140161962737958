import { Box, TextField, Typography, styled } from '@mui/material';
import React from 'react'

const Tf1 = styled("div")({
    "& .MuiInputBase-input": {
      color: "#333333",
      fontSize: "14px",
      fontFamily: "arial",
      fontWeight: "400",
      textTransform: "none",
      // padding: "4px",
      textAlign: "left"
    },
  });

const AppraiserMessageForEmployeeView = (props : any) => {
   const {employeeData,appraiserMessageForEmployee} = props;
   
  return (
   <>
     {employeeData?.data?.appraisal?.appraiser_overall_feedback !== null &&
                    employeeData?.data?.appraisal?.appraiser_overall_feedback !== "" &&
                    <div style={{ marginBottom: "20px" , 
                    // marginRight : "-32px"
                    }}>
                      <Typography
                        style={{
                          fontSize: "16px",
                          color: "#717171",
                          marginBottom: "10px",
                          fontFamily: "arial"
                        }}
                      >
                        <b>Appraiser Message for Employee</b>
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontFamily: "arial",
                          color: "#333333",
                          wordBreak: "break-word",
                          // width: "94.6%"
                          // marginRight: "65px"
                        }}
                      >

                        <Tf1>
                        <Box sx={{ border: "1px solid #E0E0E0", padding: "8.5px 14px", borderRadius: "5px" }}>
              <div style={{
                color: "#333333",
                fontSize: "14px",
                fontFamily: "arial",
                fontWeight: "400",
                textTransform: "none",
                // padding: "8px",
                textAlign: "left",
                lineHeight: "23px"
              }}>
                {appraiserMessageForEmployee}
              </div>
              </Box>
                          {/* <TextField
                            fullWidth
                            InputProps={{ readOnly: true, }}
                            multiline
                            // inputProps={{ maxLength: 500 }}
                            size="small"
                            value={appraiserMessageForEmployee}
                          /> */}
                        </Tf1>

                      </Typography>
                    </div>
               }  
   </>
  )
}

export default AppraiserMessageForEmployeeView