import React from 'react';
import { Popover } from '@mui/material';


const CustomPopOverForTable = (props: any) => {
    const { popoverIndex,
        id,
        open,
        anchorEl,
        onClose,
        children, } = props;

    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                PaperProps={{
                    style: {
                        backgroundColor: "FEFCF8",
                        boxShadow: "none",
                        maxWidth: "400px",
                        borderRadius: "5px",
                    },
                }}
                sx={{
                    "& .MuiPopover-paper": {
                        border: "1px solid #3e8cb5",
                        backgroundColor: "#ffffff",
                    },
                }}
            >
                {children}
            </Popover>
        </>
    )

}

export default CustomPopOverForTable



