import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Stack, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import Uploadatt from "../../../../assets/Images/Uploadatt.svg";
import Removeattnew from "../../../../assets/Images/Removeattnew.svg";
import DialogWithOkButton from '../../../UI/DialogWithOkButton';
import { useAttachmentsEmployeeMutation } from '../../../../service';
import { useCreateAzureBlobMutation } from '../../../../service/azureblob';

const Item1 = styled(Box)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const Item2 = styled("div")(({ theme }) => ({
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "450px",
    margin: "1rem",
    // // paddingTop: "1px",
    // marginLeft: "15px",
    // marginTop: "5px",
}));

const Contain = styled("div")({
    "& .MuiButtonBase-root": {
        color: "#333333",
        backgroundColor: "#FFFFFF",
        height: "34px",
        width: "34px",
        boxShadow: "0px 0px 1px 1px #D4D4D4",
    },

    "& .MuiButton-root": {
        border: ` 1px solid `,
        borderColor: "#D4D4D4",
        minWidth: "0px",
        borderRadius: "50px",
        width: "25px",
        height: "25px",
        "&:focus": {
            // borderColor: '#3C8BB5',
        },
    },
});

const Text = styled("div")({
    "& .MuiButton-root": {
        color: "#858585",
        // opacity: 0.6,
        fontSize: "13px",
        fontWeight: "400",
        textTransform: "none",
    },
    paddingTop: "0px",
    color: "#858585",
    marginLeft: "-3px",
});


const EmployeeRejectRatingSlider = (props: any) => {
    const { objectiveData,
        ratingData,
        setMoveTab,
        setNavPrompt,
        openDrawer,
        employeeData,
        employee_id,
        setOpenDrawer,
        refetchEmployeeData,
        updateEmployeeRejection,
        updateLoggedRole,
        acceptRating,
        updateAppraisalRating
    } = props;
    const inputRef = useRef<any>(null);
    const [objectiveTitle, setObjectiveTitle] = useState<any>("");
    const [comments, setComments] = useState("");
    const [name, setName] = useState<string>("");
    const [fileSelected, setFileSelected] = useState<any>("");
    const [hideAttachment, setHideAttachment] = useState<any>(false);
    const [ratingValue, setRatingValue] = useState<any>("");
    const [ratingAppraiser, setRatingAppraiser] = useState<any>("");
    const [activeObjectiveDescription, setActiveObjectiveDescription] =
        useState("");
    const [activeObjectiveDescriptionName, setActiveObjectiveDescriptionName] =
        useState("");
    const [rating, setRating] = useState<any>("");
    const [openAlertOk, setOpenAlertOk] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [ratingparams, setratingparams] = useState<any>("");
    const [showWithdraw, setShowWithdraw] = useState(false);

    // mutations
    const [sendItem] = useCreateAzureBlobMutation();
    const [attachmentsEmployee] = useAttachmentsEmployeeMutation();


   
    // Define the function to handle the logic
    const handleObjectiveData = (data: any) => {
        let appraiserRatingValue =
            employeeData?.data?.appraisal?.objective_description
                .filter((i: any) => i?.name?._id === data?.name?._id)
                .map((k: any) => {
                    if (k.ratings) return k.ratings.rating;
                })[0];
        setObjectiveTitle(data?.name?.objectiveTitle);
        setComments(data?.rejection_reason);
        setName("");
        setRatingAppraiser(appraiserRatingValue);
        setActiveObjectiveDescription(data?._id);
        setActiveObjectiveDescriptionName(data?.name?._id);
        if (data?.rating_rejected == true) {
            setRating(data?.ratings?._id);
            setRatingValue(data?.ratings?.rating);
        } else {
            setRating("");
            setRatingValue("");
        }

        if (data?.rating_rejected == true && data?.action_performed == true) {
            setShowWithdraw(true);
        } else {
            setShowWithdraw(false);
        }
    }

    useEffect(() => {
        if (openDrawer && objectiveData) {
            handleObjectiveData(objectiveData);
        }
    }, [openDrawer, objectiveData]);

    useEffect(() => {
        if (acceptRating && employeeData && objectiveData) {
            setNavPrompt(false)
            let temp = employeeData?.data?.appraisal?.objective_description
                ?.filter((i: any) => i.name._id === objectiveData?.name?._id)
                ?.map((k: any) => k.ratings?._id)[0];
    
            let normalizerRatingValue =
                employeeData?.data?.normalizer?.objective_description
                    .filter((i: any) => i?.name?._id == objectiveData?.name?._id)
                    .map((j: any) => {
                        if (j?.ratings?._id !== temp) {
                            return true
                        } else return false
                    })[0]
    
            let appraiser_objective_description = employeeData.data.appraisal.objective_description.map(
                (i: any) => {
                    if (i.name._id === objectiveData?.name?._id)
                        return {
                            ...i,
                            rating_rejected: false,
                            rating_resubmitted: normalizerRatingValue,
                        }
                    else return i
                }
            )
    
            const getAppraiserRating = (objId: any) => {
                return employeeData?.data?.appraisal?.objective_description?.find((item: any) => item.name._id === objId).ratings._id
            }
    
            let current_objective_description = employeeData?.data?.current_rating?.objective_description?.map(
                (item: any) => {
                    console.log(rating, item, 'checkRating')
                    // if (item.name._id === objectiveData?.name?._id) {
                    if (item.name._id === objectiveData?.name?._id) {
                        return {
                            ...item,
                            ratings: getAppraiserRating(item.name._id)
                        }
                    } else return item
                }
            );
    
            setActiveObjectiveDescriptionName(objectiveData?.name?._id);
            setActiveObjectiveDescription(objectiveData?._id);
            updateAppraisalRating({
                objective_description: objectiveData?._id,
                objective_description_name: objectiveData?.name?._id,
                ratings: temp,
                rating_rejected: false,
                action_performed: true,
                rejection_reason: "",
                current_objective_description: current_objective_description,
                appraiser_objective_description: appraiser_objective_description,
                id: employee_id,
            }).then((res: any) => {
                console.log(res, 'resssssssssssssssssss')
            })
            setRatingAppraiser("")
        };
    },[acceptRating,employeeData])

   



    useEffect(() => {
        if (fileSelected !== "" && name !== "") {
            setHideAttachment(true);
        } else {
            setHideAttachment(false);
        }
    }, [name, fileSelected]);

    // functions
    const handleClickOpenAttachment = (e: any) => {
        document.getElementById("photo")?.click();
    };

    const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;
        // if (!fileList) return;
        //@ts-ignore
        setName(fileList[0].name);
        // setFileSelected(fileList[0]);
        let reader = new FileReader();
        //@ts-ignore
        reader.readAsDataURL(fileList[0]);
        reader.onload = (e) => {
            setFileSelected(e.target?.result);
        };
    };

    const imageClick = () => {
        const newData = {
            // token:tokens,
            // newstitle: newsTitle,
            // newsdesc: convertedContent,
            newspic: fileSelected,
            newspicname: name,
        };

        sendItem(newData).then((res: any) => {
            attachmentsEmployee({
                attachments: {
                    url: name,
                    objective_description: activeObjectiveDescriptionName,
                },
                id: employee_id,
            });
        });
    };

    const handleAlertOkClose = () => {
        setOpenAlertOk(false);
        setMessage("")
    }

    const resetFileInput = () => {
        setFileSelected("");
        setName("");
        inputRef.current.value = null;
    };

    const handleRatingAlert = (j: any) => {
        console.log(j, "jjjjjjj");
        setratingparams(j.rating);
        if (ratingAppraiser === j.rating) {
            setOpenAlertOk(true);
            setMessage(
                // "You cannot select the same rating as the Appraiser.please change the rating."
                "You cannot select the same rating as the Appraiser. Please change the rating."
            );
        }
        // else if (comments == "" || comments == undefined || comments == ""){
        //   setrejectAlert(true);
        //   setrejectAlertMessage("Please add Comments for Rejection")

        // }
        else {
            if (j) setRating(j._id);
            // setratingSelection(true);
        }
    };
    const saveAsDraftHandler = () => {
        setNavPrompt(false);
        if (ratingValue === null ||
            ratingValue === "" ||
            ratingValue === undefined) {
            setMessage("Please select a rating to reject.");
            setOpenAlertOk(true);
        }
        else if (ratingAppraiser === ratingValue) {
            setMessage(
                // "You cannot select the same rating as the Appraiser. Please change the Rating."
                "You cannot select the same rating as the Appraiser. Please change the rating."
            );
            setOpenAlertOk(true);

            // if (copiedFinalRating === rating) {
            //     setOpenAlertOk(true);
            // setTimeout(() => {
            //   setOpenAlertOk(false);
            // }, 3000);
        } else if (ratingValue === null || ratingValue === "" || ratingValue === undefined) {
            setMessage(
                // "You cannot select the same rating as the Appraiser. Please change the Rating."
                "Please select a rating to reject!"
            );
            setOpenAlertOk(true);
        }
        else if (comments?.trim() == "" || comments?.trim() == undefined || comments?.trim() == "") {
            setMessage("Please add rejection reason.");
            setOpenAlertOk(true);
        } else {
            closeDrawer();
            let appraiser_objective_description = employeeData.data.appraisal.objective_description.map(
                (i: any) => {
                    if (employeeData?.data?.appraisal?.appraiser_status == "appraiser-rejected-employee" && i.name._id === activeObjectiveDescriptionName)
                        return { ...i, rating_rejected: true }
                    else return i
                }
            )

            let current_objective_description = employeeData?.data?.current_rating?.objective_description?.map(
                (item: any) => {
                    if (item.name._id === activeObjectiveDescriptionName) {
                        return {
                            ...item,
                            // ratings: ratingDataCurrent,
                            ratings: rating,
                        }
                    } else return item
                }
            )

            updateEmployeeRejection({
                objective_description: activeObjectiveDescription,
                objective_description_name: activeObjectiveDescriptionName,
                ratings: rating,
                rating_rejected: true,
                action_performed: true,
                rejection_reason: comments?.trim(),
                appraiser_objective_description: appraiser_objective_description,
                pa_status: "Pending with Employee",
                employee_status: "draft",
                current_objective_description: current_objective_description,
                // comments: "",
                // rating_comments: comments,
                employee_id,
                // "employee.training_recommendation": formValues,
                // "employee.area_of_improvement": area,
            }).then((Res: any) => {
                if (!Res.error) {
                    if (name && fileSelected) {
                        return imageClick();
                    }
                    refetchEmployeeData()
                    setNavPrompt(false)
                    setMoveTab(false);
                    setRating("");
                    console.log(Res, 'responseeeee')
                } else {
                    updateLoggedRole({
                        pa_action_by: `${Res.error} : ${new Date()}`,
                        id: employee_id
                    })
                    setMessage("Something Went Wrong.")
                    setOpenAlertOk(true)
                }
            })
        }
    };

    const ratingWithdrawHandler = () => {
        closeDrawer();
        if (employeeData) {
            let previousObjectiveDescription = (employeeData?.data?.employee_previous_submission?.objective_description?.length == 0 ?
                employeeData?.data?.appraisal_previous_submission?.objective_description :
                employeeData?.data?.employee_previous_submission?.objective_description)
            let previousRatingData = previousObjectiveDescription && previousObjectiveDescription
                ?.filter((i: any) => i.name == activeObjectiveDescriptionName)
                ?.map((k: any) => {
                    return {
                        ratings: k.ratings,
                        rejection_reason: k.rejection_reason ? k.rejection_reason : "",
                        rating_rejected: k.rating_rejected,
                    }
                })[0];

            let appraiserObjectiveDescription = employeeData?.data?.appraisal?.objective_description;
            const getRatings = (id: any) => {
                let rating = appraiserObjectiveDescription.find((item: any) => item.name._id == id)
                return rating.ratings
            }
            let current_objective_description = employeeData?.data?.current_rating?.objective_description
                .map((item: any) => {
                    return item.name._id == activeObjectiveDescriptionName ?
                        {
                            ...item,
                            ratings: getRatings(item.name._id)
                        } :
                        item
                })


            updateEmployeeRejection({
                objective_description: activeObjectiveDescription,
                objective_description_name: activeObjectiveDescriptionName,
                ratings: previousRatingData.ratings,
                rating_rejected: previousRatingData.rating_rejected,
                action_performed: false,
                rejection_reason: previousRatingData.rejection_reason,
                rating_comments: "",
                current_objective_description: current_objective_description,
                employee_id,
            });
            setRating("");
            setRatingValue("")
            setComments("");
        }
    };

    const closeDrawer = () => {
        setMoveTab(false);
        setOpenDrawer(false);
        setRating("");
        setRatingValue("");
        // setratingSelection(false);
    };

    return (
        <>
            <div
                style={{
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    backgroundColor: "#ebf2f4",
                    color: "#3E8CB5",
                    fontSize: "20px",
                }}
            >
                Employee Rejection
            </div>





            {/* {openAlertOk && <Alert severity="error">
          You cannot put the same rating as the Appraiser. Please change the rating.
          </Alert>} */}


            <DialogWithOkButton
                isAlertOpen={openAlertOk}
                handleAlertClose={handleAlertOkClose}>
                {message}
            </DialogWithOkButton>

            <Item2 sx={{ width: "fitContent" }}>
                <Typography
                    // style={{
                    //   paddingLeft: "25px",
                    //   paddingTop: "16px",
                    //   //paddingBottom: "10px",
                    //   //backgroundColor: "#ebf2f4",
                    //   color: "#3E8CB5",
                    //   fontSize: "17px",
                    // }}
                    style={{
                        paddingLeft: "8px",
                        paddingBottom: "16px",
                        fontFamily: "arial",
                        color: "#3E8CB5",
                        fontSize: "17px",
                        textAlign: "left",
                        wordBreak: "break-word",
                    }}
                >
                    {objectiveTitle}
                </Typography>
                <div
                    style={{
                        textAlign: "left",
                        paddingLeft: "10px",
                        fontSize: "12px",
                        paddingBottom: "7px",
                        fontFamily: "arial",
                        color: "#7A7A7A",
                        // paddingTop: "13px",
                    }}
                >
                    Employee Rating
                </div>

                <>
                    <Stack
                        style={{ paddingLeft: "10px" }}
                        direction="row"
                        spacing={1.7}
                    // height="50px"
                    >
                        {ratingData &&
                            ratingData?.data
                                .slice()
                                .sort(function (a: any, b: any) {
                                    return a.rating - b.rating;
                                })
                                .map((ratings: any, _id: any) => (
                                    <Item1
                                        sx={{
                                            marginLeft: "2px",
                                            padding: "0px",
                                            justifyContent: "center",
                                            position: "relative",
                                        }}
                                    >
                                        <Contain>
                                            <Button
                                                onClick={() => {
                                                    // if (ratings) setRating(ratings._id);
                                                    // ratingColorHandler(rating._id)
                                                    // setNavPrompt(true);
                                                    handleRatingAlert(ratings);
                                                    // setDisplayEmployeeRating(false);
                                                    setRating(ratings._id);
                                                    setRatingValue(ratings.rating);
                                                }}
                                                style={{
                                                    //@ts-ignore
                                                    borderColor: rating === ratings._id && "#3C8BB5",
                                                    backgroundColor: rating === ratings._id ? "rgb(123 210 255 / 29%)" : ""
                                                }}
                                                size="small"
                                            >
                                                <p
                                                    style={{
                                                        fontSize: "11px",
                                                        color: "#333333",
                                                        opacity: "80%",
                                                    }}
                                                >
                                                    {ratings.rating}
                                                </p>
                                            </Button>

                                            {/* {rating === ratings._id && (
                      <p
                        style={{
                          color: "#3C8BB5",
                          fontSize: "10px",
                          position: "absolute",
                          left: "50%",
                          transform: "translate(-50%, 0px)",
                          maxWidth: "50px",
                        }}
                      >
                        {ratings.rating_scale}
                      </p>
                    )} */}
                                        </Contain>

                                        {/* <p style={{ color: "#3C8BB5", fontSize: "10px" }}>{ratings.rating_scale}</p> */}
                                    </Item1>
                                ))}
                    </Stack>
                    <Stack
                        direction="column"
                        position="relative"
                    // display="flex"
                    // alignItems="center"
                    >
                        <div
                            style={{
                                textAlign: "left",
                                paddingLeft: "10px",
                                fontSize: "12px",
                                paddingBottom: "7px",
                                paddingTop: "16px",
                                fontFamily: "arial",
                                color: "#7A7A7A",
                            }}
                        >
                            Appraiser Rating
                        </div>
                        {/* <Contain>
            {filterData2 &&
                    filterData2.map((i: any, index: any) => {
                      console.log(i, "123");
                      return (
                        <>
                      <span> {i[0]}</span>
                          </>
                      );
                    })}
            </Contain> */}
                        <Item1
                            sx={{
                                marginLeft: "2px",
                                padding: "0px",
                                justifyContent: "center",
                                cursor: "default"
                            }}
                        >
                            <Contain>
                                <Stack
                                    direction="column"
                                    alignItems="start"
                                    display="flex"
                                    marginLeft="10px"
                                    spacing={1}
                                >
                                    <Button
                                        style={{
                                            //@ts-ignore
                                            borderColor: "#3C8BB5",
                                            cursor: "default"
                                            // marginLeft: "30px",
                                        }}
                                        size="small"
                                    >
                                        <p
                                            style={{
                                                fontSize: "11px",
                                                color: "#333333",
                                                opacity: "80%",
                                            }}
                                        >
                                            {ratingAppraiser}
                                        </p>
                                    </Button>
                                    <span
                                        style={{
                                            color: "#3C8BB5",
                                            fontSize: "10px",
                                            // paddingLeft: "15px",
                                            position: "absolute",
                                            top: "95%",
                                            transform: "translate(-25%, 0px)",
                                            maxWidth: "50px",
                                        }}
                                    >
                                        {/* {getRatingDescription(ratingAppraiser)} */}
                                        {/* Very Bad Performance */}
                                    </span>
                                </Stack>
                            </Contain>
                        </Item1>
                    </Stack>
                </>
            </Item2>
            <div
                style={{
                    textAlign: "left",
                    paddingLeft: "27px",
                    fontSize: "12px",
                    paddingBottom: "7px",
                    paddingTop: "6px",
                    color: "#7A7A7A", fontFamily: "arial"
                }}
            >
                Rejection Reason<span style={{
                    fontSize: "20px",
                }}>*</span>
            </div>

            <TextField
                sx={{
                    "& .MuiInputBase-input": {
                        fontSize: "14px",
                        fontFamily: "Arial",
                        color: "#333333",
                    },
                }}
                size="small"
                autoComplete="off"
                multiline
                style={{ paddingLeft: "28px", width: "75%" }}
                value={comments}
                inputProps={{ maxLength: 500 }}
                onChange={(e) => {
                    setComments(e.target.value);
                    setMoveTab(true);
                    setNavPrompt(true);
                }}
            />
            {/* <div
                style={{
                    textAlign: "left",
                    paddingLeft: "29px",
                    fontSize: "12px",
                    paddingBottom: "10px",
                    paddingTop: "16px",
                    color: "#7A7A7A",
                    fontFamily: "arial"
                }}
            >
                Attachment
            </div> */}

            {/* <Stack>
                <div style={{ paddingLeft: "20px" }}>
                    <Stack direction="row" alignItems="center" gap="5px">
                        <span> */}
                            {/* <input
                                //@ts-ignore
                                // style={styles.colors}
                                id="photo"
                                name="photo"
                                type="file"
                                title={name}
                                ref={inputRef}
                                style={{ display: "none" }}
                                multiple={false}
                                onChange={(e) => {
                                    handleImageChange(e);
                                    setMoveTab(true);
                                }}
                            /> */}
                        {/* </span> */}
                        {/* <IconButton>
                            <img
                                src={Uploadatt}
                                onClick={(e: any) => {
                                    // setActiveObjectiveDescriptionName(j?.name?._id)
                                    handleClickOpenAttachment(e);
                                    // setPopoverIndex(index)
                                }}
                            />
                        </IconButton> */}
                        {/*            
          {employeeData &&
            objectiveTitleData &&
            objectiveDescription?.map((j: any, index: number) => {   
            
              return (
                <>
                  {employeeData && getAttachments1(j?.name?._id)?.map((k: any, index1: any) => {
                    return (
                      <>
                        <Stack
                          spacing={1}
                          direction="row"
                          alignItems="center"
                          paddingLeft="5px"
                        >

                          <Typography
                            style={{
                              fontSize: "12px",
                              fontFamily: "Arial",
                              color: "#333333",

                              // maxWidth:"215px",
                              // wordBreak:"break-all"
                            }}
                          >
                            {index1 + 1}.
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "12px",
                              fontFamily: "Arial",
                              color: "#333333",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "170px"
                            }}
                          >
                            {k.resp}
                          </Typography>
                          <Stack direction="row">                              
                            <IconButton>                                 
                              <img
                                src={Removeattnew}
                                onClick={() => deleteEmployeeMutation({
                                  employee_id: employee_id,
                                  name: k.remove
                                })}
                                 />
                            </IconButton>
                          </Stack>

                        </Stack>
                      </>
                    )
                  })}

                </>)
            })} */}
                        {/* <Text>
            {hideAttachment && (
              <IconButton
                onClick={() => {
                  // setFileSelected('')
                  // setName('')
                  resetFileInput();
                }}
              >
                <img src={Removeattnew} alt="icon" />
              </IconButton>
            )}

          </Text> */}
                        {/* <Typography
                            sx={{
                                fontSize: "14px",
                                color: "#7a7a7a",
                                fontFamily: "arial",
                            }}
                        >
                            {name}
                        </Typography> */}
                        {/* <Text>
                            {hideAttachment && (
                                <IconButton
                                    onClick={() => {
                                        // setFileSelected('')
                                        // setName('')
                                        resetFileInput();
                                    }}
                                >
                                    <img src={Removeattnew} alt="icon" />
                                </IconButton>
                            )}
                        </Text> */}
                    {/* </Stack> */}
                {/* </div> */}
            {/* </Stack> */}

            <Stack
                alignItems="left"
                direction="row"
                paddingLeft="28px"
                paddingTop="16px"
                spacing={2}
            >
                <Button
                    style={{
                        textTransform: "none",
                        fontSize: "15px",
                        fontFamily: "Arial",
                        borderColor: "#3E8CB5",
                        color: "#3E8CB5",
                        background: "transparent",
                        height: "35px",
                        // width: "70px",
                    }}
                    variant="outlined"
                    onClick={() => {
                        // setNavPrompt(false);
                        saveAsDraftHandler();

                        // if (name && fileSelected) {
                        //   return imageClick();
                        // }
                    }}
                // variant="outlined"
                // onClick={saveAsDraftHandler}
                >
                    {" "}
                    Save as Draft
                </Button>
                {showWithdraw && (
                    <Button
                        style={{
                            textTransform: "none",
                            fontSize: "15px",
                            fontFamily: "Arial",
                            borderColor: "#3E8CB5",
                            color: "#3E8CB5",
                            background: "transparent",
                            height: "35px",
                            width: "75px",
                        }}
                        variant="outlined"
                        onClick={ratingWithdrawHandler}
                    >
                        {" "}
                        Withdraw{" "}
                    </Button>
                )}
                <Button
                    style={{
                        textTransform: "none",
                        fontSize: "15px",
                        fontFamily: "Arial",
                        borderColor: "#3E8CB5",
                        color: "#3E8CB5",
                        background: "transparent",
                        height: "35px",
                        width: "70px",
                    }}
                    variant="outlined"
                    onClick={closeDrawer}
                >
                    {" "}
                    Cancel{" "}
                </Button>
            </Stack>
        </>
    )
}

export default EmployeeRejectRatingSlider