import React from 'react'
import EmployeeReviewerReject from '../../../components/appraisalNew/reviewer/employeeReviewerReject/EmployeeReviewerReject'
import ProvidedReviewerContextProvider from '../../../context/appraisal/ReviewerContext';

const EmployeeReviewerRejectPage = () => {
  return (
    <ProvidedReviewerContextProvider>
    <>
    <EmployeeReviewerReject/>
    </>
    </ProvidedReviewerContextProvider>
  )
}

export default EmployeeReviewerRejectPage