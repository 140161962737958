import { Typography } from '@mui/material';
import React from 'react'

const PerformanceAppraisalHistory = (props: any) => {

    const { employeeData, showIfpreviousappraisal } = props;

    return (
        <>
            <Typography
                style={{
                    marginBottom: "10px",
                    color: "#3e8cb5",
                    fontSize: "16px",
                    fontFamily: "Arial"
                }}
            >
                <b>Performance Appraisal History</b>
            </Typography>
            {showIfpreviousappraisal == true ? (
                <ul>
                    {employeeData?.employee?.workflow_history?.map((item: any) => {
                        return (
                            <li style={{ listStyleType: 'disc', marginBottom: "10px" }}>{item.pa_action_by}</li>
                        )
                    })
                    }
                </ul>
            ) : (
                <ul>
                    {employeeData?.data?.workflow_history?.map((item: any) => {
                        return (
                            <li style={{ fontFamily: "Arial", listStyleType: 'disc', marginBottom: "10px" }}>{item.pa_action_by}</li>
                        )
                    })
                    }
                </ul>
            )
            }
        </>
    )
}

export default PerformanceAppraisalHistory