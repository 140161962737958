import React from 'react'
import ReviewerApproveAppraiser from '../../../components/appraisal/reviewer/employee_reviewer_acceptance/ReviewerApproveAppraiser'

const Employee_ReviewerAcceptance = () => {
  return (
   <>
   <ReviewerApproveAppraiser/>
   </>
  )
}

export default Employee_ReviewerAcceptance