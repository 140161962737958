import * as React from "react";
import { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Container, Box, TablePagination, Breadcrumbs, TextField, Drawer, FormGroup, ListItemIcon, } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { FormControl, MenuItem, Menu } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ListItemText from '@mui/material/ListItemText';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Newexcel from "../../components/reviewer/Dashboard/Reviewericons/Newexcel.svg"
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Scrollbar } from "react-scrollbars-custom";
import { useGetActiveCalenderQuery, useGetEmployeeByFilterQuery } from "../../service";
import InputAdornment from "@mui/material/InputAdornment";
import Searchlens from "../../assets/Images/Searchlens.svg"
import * as XLSX from "xlsx";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from '@mui/styles';
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ExportToexcel from "../ExportToExcel/ExporttoExcel";

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    width: 140,
    fontSize: "14px",
    color: "#333333",
    fontFamily: "Arial",
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
    fontSize: "13px !important",
    fontFamily: "Arial",
    color: "#333333",
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    fontSize: "12px !important",
    color: "#333333",
    fontFamily: "Arial",
  },
}));

const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
});
const Searchfeild = styled("div")({
  "& .MuiOutlinedInput-root": {
    height: "28px",
    width: "144px",
    borderRadius: "15px",
    background: "#F2F6F8",
  },
  "& .MuiInputLabel-root": {
    fontSize: "13px",
    color: "#306D8F",
    marginTop: "-10px",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "13px",
    color: "#306D8F",
  },
});
export default function FilteredTableofExpNinebox(props: any) {
  const classes = useStyles();
  // getting livecalendar details
  const { data: activecalendardata, isLoading: isTitleLoading } = useGetActiveCalenderQuery('')
  let CalendarName1 = activecalendardata?.data[0]?.name
  const { hideEmployeeRating, AllEmployee, navigationFrom, CalendarName } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const CustomScrollbar = Scrollbar as any;
  const [tablecount, settablecount] = React.useState<any>(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // getting status based on appraisal status
  const getStatus = (status: any) => {
    if (status == "in-progress") {
      return "In progress"
    } else if (status == "not-started") {
      return "Not started"
    } else if (status == "normalized") {
      return "Normalized"
    } else if (status == "rejected") {
      return "Rejected "
    } else if (status == "completed") {
      return "Completed "
    }
  }
  //search Functionality
  const [enteredName, setenteredName] = useState("");
  //drawer functions
  const [heading1, setheading1] = React.useState(true);
  const [heading2, setheading2] = React.useState(true);
  const [heading3, setheading3] = React.useState(true);
  const [heading4, setheading4] = React.useState(true);
  const [headingOfSection, setheadingOfSection] = React.useState(true);
  const [headingOfPAStatus, setheadingOfPAStatus] = React.useState(true);
  const [headingOfDivision, setheadingOfDivision] = React.useState(true);
  const [headingOfSubSection, setheadingOfSubSection] = React.useState(true);
  const [headingOfAppraiserName, setheadingOfAppraiserName] = React.useState(true);
  const [headingOfReviewerName, setheadingOfReviewerName] = React.useState(true);
  const [headingOfNormalizerName, setheadingOfNormalizerName] = React.useState(true);
  const [headingOfOverallRating, setheadingOfOverallRating] = React.useState(true);
  const [headingOfPreviousRating, setheadingOfPreviousRating] = React.useState(true);
  const [headingOfPotentialLevel, setheadingOfPotentialLevel] = React.useState(true);
  const [headingOfTalentCategory, setheadingOfTalentCategory] = React.useState(true);
  const [headingOfWorkLocation, setheadingOfWorkLocation] = React.useState(true);
  const [headingOfManagerCode, setheadingOfManagerCode] = React.useState(true);
  const [headingOfManagerName, setheadingOfManagerName] = React.useState(true);
  const [headingOfManagerPosition, setheadingOfManagerPosition] = React.useState(true);
  const [headingOfPendingAction, setheadingOfPendingAction] = React.useState(true);
  const [ServiceReferenceDateData, setServiceReferenceDateData] = React.useState(true);
  const [FunctionData, setFunctionData] = React.useState(true);
  const [SupervisoryRoleData, setSupervisoryRoleData] = React.useState(true);
  const [EmailIDData, setEmailIDData] = React.useState(true);
  const [rejstatusValue, rejsetstatusValue] = React.useState(true);
  const [firstName, setfirstName] = React.useState(true);
  const [employeerating, setemployeerating] = React.useState(true);
  const [headingcalendar, setheadingcalendar] = React.useState(true);

  const handleServiceReferenceDateData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceReferenceDateData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleFunctionData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFunctionData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSupervisoryRoleData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSupervisoryRoleData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleEmailIDData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailIDData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [isDrawerOpen, setisDrawerOpen] = React.useState(false);
  const [headingOfEcode, setheadingOfEcode] = React.useState(true);
  const [columnHeaders, setcolumnHeaders] = useState<any>({
    Ecode: true,
    Ename: true,
    Firstname: true,
    Eposition: true,
    ESection: true,
    EGrade: true,
    EDivision: true,
    ESubSection: true,
    AppraiserName: true,
    ReviewerName: true,
    NormalizerName: true,
    employeerating: true,
    appraiserRating: true,
    // reviewerRating: true,
    // normalizerRating: true,
    // appraisercode: true,
    // reviewercode: true,
    // normalizercode: true,
    OverallRating: true,
    PreviousRating: true,
    PotentialLevel: true,
    TalentCategory: true,
    WorkLocation: true,
    ManagerCode: true,
    ManagerName: true,
    ManagerPosition: true,
    PendingAction:true,
    appraiserCode: true,
    reviewerCode: true,
    normalizerCode: true,
    status: true,
    ServiceReferenceDate: true,
    Function: true,
    SupervisoryRole: true,
    EmailID: true,
    CalendarName: true,
    SelectAll: true,
    workflowHistory : true
  })
  // checkedcount and totalcount used for choose fields checkbox
  const checkedCount = Object.keys(columnHeaders).reduce((count: any, key: any) => {
    if (key !== 'SelectAll') {
      return count + (columnHeaders[key] ? 1 : 0);
    }
    return count;
  }, 0);
  const totalCount = Object.keys(columnHeaders).length - 1;
  // checkedcount and totalcount used for choose fields checkbox
  const [statusValue, setstatusValue] = React.useState(true);
  const handleStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setstatusValue(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAppCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleRevCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNorCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSelectAll = (selectAll: any) => {
    const updatedColumnHeaders = { ...columnHeaders };
    Object.keys(updatedColumnHeaders).forEach((key) => {
      updatedColumnHeaders[key] = selectAll;
    });
    setcolumnHeaders(updatedColumnHeaders);
  };
  //For multislect options
  const [gradesArray, setgradesArray] = React.useState<any>([]);
  const [positionArray, setpositionArray] = React.useState<any>([]);
  const [sectionArray, setsectionArray] = React.useState<any>([]);
  const [positionsFilter, setpositionsFilter] = React.useState<string[]>([]);
  const [GradesFilter, setGradesFilter] = React.useState<string[]>([]);
  //  filter dropdown values setting
  useEffect(() => {
    let grades = AllEmployee
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.grade - b?.grade;
      })
      .map((i: any) => {
        return i?.grade;
      });
    //for filtering graades options
    if (positionsFilter.length > 0) {
      grades = AllEmployee
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.grade - b?.grade;
        })
        ?.filter((i: any) => {
          return !!positionsFilter?.find(item2 => i?.position_long_description === item2)
        })
        ?.map((i: any) => {
          return i?.grade;
        });
    }
    // search functionality based on grade
    else if (enteredName?.length > 0) {
      grades = AllEmployee
        .slice()
        ?.sort(function (a: any, b: any) {
          return a?.grade?.localeCompare(
            b?.grade
          );
        })
        ?.filter((i: any) => {
          if (enteredName.length > 0) {
            const searchTerms = enteredName.toLowerCase().split(" ");
            return searchTerms.every(term =>
              i?.grade
                ?.toLowerCase()
                .includes(term)
            ) || searchTerms.every(term =>
              i?.grade?.toLowerCase().includes(term)
            ) || searchTerms.every(term =>
              i?.position_long_description?.toLowerCase().includes(term)
            ) || searchTerms.every(term =>
              i?.section?.toLowerCase().includes(term)
            ) || searchTerms.every(term =>
              i?.employee_code?.toLowerCase().includes(term)
            ) || searchTerms.every(term =>
              i?.legal_full_name?.toLowerCase().includes(term)
            )
          } else {
            return true;
          }
        })
        ?.map((i: any) => {
          return i?.grade;
        });
    }
    const gradeContents = grades
      ?.filter((c: any, index: any) => {
        return grades?.indexOf(c) === index && c != null && c != undefined;
      });
    setgradesArray(gradeContents);
    let position = AllEmployee
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.position_long_description?.localeCompare(b?.position_long_description);
      })
      ?.map((i: any) => {
        return i?.position_long_description;
      });
    //  GradesFilter
    if (GradesFilter?.length > 0) {
      position = AllEmployee
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.position_long_description - b?.position_long_description;
        })
        ?.filter((i: any) => {
          return !!GradesFilter?.find(item2 => i?.grade === item2)
        })
        ?.map((i: any) => {
          return i?.position_long_description;
        });
    }
    // search functionality based on position
    else if (enteredName?.length > 0) {
      position = AllEmployee
        .slice()
        ?.sort(function (a: any, b: any) {
          return a?.position_long_description?.localeCompare(
            b?.position_long_description
          );
        })
        ?.filter((i: any) => {
          if (enteredName.length > 0) {
            const searchTerms = enteredName.toLowerCase().split(" ");
            return searchTerms.every(term =>
              i?.position_long_description
                ?.toLowerCase()
                .includes(term)
            ) || searchTerms.every(term =>
              i?.grade?.toLowerCase().includes(term)
            ) || searchTerms.every(term =>
              i?.position_long_description?.toLowerCase().includes(term)
            ) || searchTerms.every(term =>
              i?.section?.toLowerCase().includes(term)
            ) || searchTerms.every(term =>
              i?.employee_code?.toLowerCase().includes(term)
            ) || searchTerms.every(term =>
              i?.legal_full_name?.toLowerCase().includes(term)
            )
          } else {
            return true;
          }
        })
        ?.map((i: any) => {
          return i?.position_long_description;
        });
    }
    const positionContents = position?.filter((c: any, index: any) => {
      return position?.indexOf(c) === index && c != null && c != undefined;
    });
    setpositionArray(positionContents);
    let section = AllEmployee
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.section?.localeCompare(b?.section);
      })
      .map((i: any) => {
        return i?.section;
      });
    if (GradesFilter?.length > 0) {
      section = AllEmployee
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.grade - b?.grade;
        })
        ?.filter((i: any) => {
          return !!GradesFilter?.find(item2 => i?.grade === item2)
        })
        ?.map((i: any) => {
          return i?.section;
        });
    }
    else if (positionsFilter.length > 0) {
      section = AllEmployee
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.position_long_description - b?.position_long_description;
        })
        ?.filter((i: any) => {
          return !!positionsFilter?.find(item2 => i?.position_long_description === item2)
        })
        ?.map((i: any) => {
          return i?.section;
        });
    }
    else if (enteredName?.length > 0) {
      section = AllEmployee
        .slice()
        ?.sort(function (a: any, b: any) {
          return a?.section?.localeCompare(
            b?.section
          );
        })
        ?.filter((i: any) => {
          if (enteredName.length > 0) {
            const searchTerms = enteredName.toLowerCase().split(" ");
            return searchTerms.every(term =>
              i?.section
                ?.toLowerCase()
                .includes(term)
            ) || searchTerms.every(term =>
              i?.grade?.toLowerCase().includes(term)
            ) || searchTerms.every(term =>
              i?.position_long_description?.toLowerCase().includes(term)
            ) || searchTerms.every(term =>
              i?.section?.toLowerCase().includes(term)
            ) || searchTerms.every(term =>
              i?.employee_code?.toLowerCase().includes(term)
            ) || searchTerms.every(term =>
              i?.legal_full_name?.toLowerCase().includes(term)
            )
          } else {
            return true;
          }
        })
        ?.map((i: any) => {
          return i?.section;
        });
    }
    const sectionContents = section?.filter((c: any, index: any) => {
      return section?.indexOf(c) === index && c != null && c != undefined;
    });
    setsectionArray(sectionContents);
  }, [AllEmployee, positionsFilter, GradesFilter, enteredName])
  //for section multiselect
  const [sectionsFilter, setsectionsFilter] = React.useState("");
  const [anchorElSections, setAnchorElSections] = React.useState<null | HTMLElement>(null);
  const openSections = Boolean(anchorElSections);
  const handleClickSections = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSections(event.currentTarget);
  };
  const handleClloseSections = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSections(null);
  };
  const handleChangeSelectsections = (event: any) => {
    setsectionsFilter(event?.target?.getAttribute("value"));
    setAnchorElSections(null)
    setPage(0);
  };
  //for section multiselect
  //for grades multiselect
  const isAllGradesFilter =
    gradesArray?.length > 0 && GradesFilter?.length === gradesArray?.length;
  const newsection = gradesArray?.length == GradesFilter?.length
  const handleChangeSelectGrades = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setGradesFilter(GradesFilter?.length === gradesArray?.length ? [] : gradesArray);
      return;
    }
    setGradesFilter(value);
    setPage(0);
  };
  //for grades multiselect
  //for positions multiselect
  const isAllpositionsFilter =
    positionArray?.length > 0 && positionsFilter?.length === positionArray?.length;
  const handleChangeSelectPositions = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setpositionsFilter(positionsFilter?.length === positionArray?.length ? [] : positionArray);
      return;
    }
    setpositionsFilter(value);
    setPage(0);
  };
  //For positions multislect 
  //multiselect
  const [employeeNamefilter, setEmployeeNameFilter] = React.useState<string[]>([]);
  const [positionFilter, setpositionFilter] = React.useState<string[]>([]);
  const [GradeFilter, setGradeFilter] = React.useState<string[]>([]);
  const [sectionfilter, setSectionfilter] = React.useState<string[]>([]);
  const [potentialfilter, setPotentialfilter] = React.useState<string[]>([]);
  //filter
  const ITEM_HEIGHT = 28;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth: 400,

      },
    },
  };
  const handleEmployeeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfEcode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading1(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading2(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading3(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfSection(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleDivision = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfDivision(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlefirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setfirstName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSubSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfSubSection(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAppraiserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfAppraiserName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleReviewerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfReviewerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNormalizerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfNormalizerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleOverallRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfOverallRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleemployeerating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setemployeerating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAppraiserRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleReviewerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNormalizerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlePreviousRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPreviousRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheadingCalendar = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingcalendar(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  }

  const handlePotentialLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPotentialLevel(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleTalentCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfTalentCategory(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleWorkLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfWorkLocation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerCode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerPosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerPosition(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlePendingAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPendingAction(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  const handleWorkflowHistory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
      SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
    }));
  };
  //drawer functions
  //Excel export Function
  const handleExport = () => {
    const mapped = AllEmployee
      .filter((item1: any) => {
        if (positionsFilter?.includes("None") || positionsFilter?.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      .filter((item1: any) => {
        if (GradesFilter?.includes("None") || GradesFilter?.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      ?.filter((j: any) => {
        if (sectionsFilter === "None" || sectionsFilter === "") {
          return j;
        } else {
          return j?.section
            ?.toLocaleLowerCase() === sectionsFilter?.toLocaleLowerCase();
        }
      })
      ?.filter((j: any) => {
        if (enteredName === "") {
          return j;
        } else if (
          (j?.legal_full_name !== undefined &&
            j?.legal_full_name
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j.position_long_description !== undefined &&
            j?.position_long_description
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.section !== undefined &&
            j?.section
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (getStatus(j?.appraisal?.status) !== undefined &&
            getStatus(j?.appraisal?.status)
              ?.toLocaleLowerCase()
              ?.includes(enteredName?.toLocaleLowerCase()))
        ) {
          return j;
        }
      })
      ?.map((j: any) => {
        let inputDate = j?.service_reference_date
        const dateParts = inputDate?.split("-");
        let date = new Date(inputDate);
        const year = date?.getFullYear();
        const month = date?.toLocaleString("default", { month: "short" });
        const day = date?.getDate();
        const formattedDate = `${day}-${month}-${year}`;
        let exportData: any = {}
        if (columnHeaders["CalendarName"] === true) {
          const CalendarNamee = navigationFrom === "PreviousDashboard" ? CalendarName : CalendarName1;
          exportData["Calendar Name"] = CalendarNamee;
        }
        if (columnHeaders["Ecode"] == true) exportData["Ecode"] = j?.employee_code
        if (columnHeaders["Ename"] == true) exportData["Employee Name"] = j?.legal_full_name
        if (columnHeaders["Firstname"] == true) exportData["Known As"] = j?.first_name
        if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date"] = formattedDate
        if (columnHeaders["Eposition"] == true) exportData["Position"] = j?.position_long_description
        if (columnHeaders["EGrade"] == true) exportData["Grade"] = j?.grade
        if (columnHeaders["Function"] == true) exportData["Function"] = j?.function
        if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = j?.isSupervisor != true ? "N-SP" : "SP"
        if (columnHeaders["EmailID"] == true) exportData["Email ID"] = j?.email
        // if (columnHeaders["EDivision"] == true) exportData["Division"] = j?.division
        if (columnHeaders["EDivision"] == true) exportData["Company"] = j?.division
        if (columnHeaders["ESection"] == true) exportData["Section"] = j?.section
        if (columnHeaders["ESubSection"] == true) exportData["Sub-section"] = j?.sub_section
        if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = j?.work_location
        if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = j?.appraiser_code
        if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = j?.appraiser_name
        if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = j?.reviewer_code
        if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = j?.reviewer_name
        if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = j?.normalizer_code
        if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = j?.normalizer_name
        if (columnHeaders["employeerating"] === true) {
          const employeeRating = navigationFrom === "PreviousDashboard" ? (
            parseFloat(j?.employee_rating)
          ) : (
            parseFloat(j?.employee?.employee_rating)
          )
          exportData["Employee Rating"] = isNaN(employeeRating) ? "-" :employeeRating?.toFixed(2)
          // if (!isNaN(employeeRating)) {
          //   exportData["Employee Rating"] = employeeRating.toFixed(2);
          // }
        }
        if (columnHeaders["appraiserRating"] === true) {
          const appraiserRating = navigationFrom === "PreviousDashboard" ? (
            parseFloat(j?.normalized_overallRating)
          ) : (
            parseFloat(j?.normalizer?.normalized_overallRating)
          )
          exportData["Appraiser Rating"] = isNaN(appraiserRating) ? "-" :appraiserRating?.toFixed(2)
          // if (!isNaN(appraiserRating)) {
          //   exportData["Appraiser Rating"] = appraiserRating.toFixed(2);
          // }else{
          //   exportData["Appraiser Rating"] =appraiserRating.toFixed(2);
          // }
        }
        // if (columnHeaders["reviewerRating"] === true) {
        //   const reviewerRating = navigationFrom === "PreviousDashboard" ? (
        //     parseFloat(j?.reviewer_rating)
        //   ) : (
        //     parseFloat(j?.reviewer?.reviewer_rating)
        //   )
        //   if (!isNaN(reviewerRating)) {
        //     exportData["Reviewer Status"] = reviewerRating.toFixed(2);
        //   }
        // }
        // if (columnHeaders["normalizerRating"] === true) {
        //   const normalizerRating = navigationFrom === "PreviousDashboard" ? (
        //     parseFloat(j?.normalizer_rating)
        //   ) : (
        //     parseFloat(j?.normalizer?.normalizer_rating)
        //   )
        //   if (!isNaN(normalizerRating)) {
        //     exportData["HR Normalizer Status"] = normalizerRating.toFixed(2);
        //   }
        // }
        if (columnHeaders["OverallRating"] === true) {
          const OverallRating = navigationFrom === "PreviousDashboard" ? (
            parseFloat(j?.overall_rating)
          ) : (parseFloat(j?.appraisal?.status == "completed" ? j?.current_rating?.overall_rating : 0 ))
          exportData["Overall Rating"] = isNaN(OverallRating) ? "-" :OverallRating?.toFixed(2)

          // if (!isNaN(OverallRating)) {
            
          //   exportData["Overall Rating"] = OverallRating.toFixed(2);
          // }else{
          //   exportData["Overall Rating"] =OverallRating.toFixed(2);
          // }
        }
        if (columnHeaders["PreviousRating"] == true) {
          const PreviousRating = parseFloat(j?.previous_rating)
          exportData["Previous Period Rating"] = isNaN(PreviousRating) ? "-" :PreviousRating?.toFixed(2)
        }
        if (columnHeaders["PotentialLevel"] == true) exportData["Potential Level"] = (j?.normalizer?.normalized_overallRating !== 0 ? j?.appraisal?.potential : "-")
        if (columnHeaders["TalentCategory"] == true) exportData["Talent Category"] =  (j?.talent_category !== "" || j?.talent_category !== undefined
          ? j?.talent_category : "-")
        if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = j?.manager_code
        if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = j?.manager_name
        if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = j?.manager_position
        if (columnHeaders["status"] == true) exportData["Status"] = getStatus(j?.appraisal?.status)
          if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] = j.appraisal?.status == "not-started" ? "Pending with Appraiser" :j.appraisal?.status == "completed" ? ("-") : j.appraisal.pa_status
        if (columnHeaders["workflowHistory"] == true) exportData["Workflow History"] = j.workflow_history?.map((item: any) => item.pa_action_by)?.join(';\n');
        return exportData
      });
    const a = [1]
    const Emptymapped = a.map((j: any) => {
      let exportData: any = {}
      if (columnHeaders["CalendarName"] == true) exportData["Calendar Name"] = ""
      if (columnHeaders["Ecode"] == true) exportData["Ecode"] = ""
      if (columnHeaders["Ename"] == true) exportData["Employee Name"] = ""
      if (columnHeaders["Firstname"] == true) exportData["Known As"] = ""
      if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date "] = ""
      if (columnHeaders["Eposition"] == true) exportData["Position"] = ""
      if (columnHeaders["EGrade"] == true) exportData["Grade"] = ""
      if (columnHeaders["Function"] == true) exportData["Function"] = ""
      if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = ""
      if (columnHeaders["EmailID"] == true) exportData["Email ID"] = ""
      // if (columnHeaders["EDivision"] == true) exportData["Division"] = ""
      if (columnHeaders["EDivision"] == true) exportData["Company"] = ""
      if (columnHeaders["ESection"] == true) exportData["Section"] = ""
      if (columnHeaders["ESubSection"] == true) exportData["Sub-section"] = ""
      if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = ""
      if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = ""
      if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = ""
      if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = ""
      if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = ""
      if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = ""
      if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = ""
      if (columnHeaders["employeerating"] == true) exportData["Employee Rating "] = ""
      if (columnHeaders["appraiserRating"] == true) exportData["Appraiser Rating"] = ""
      // if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Status"] = ""
      // if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Status"] = ""
      if (columnHeaders["OverallRating"] == true) exportData["Overall Rating"] = ""
      if (columnHeaders["PreviousRating"] == true) exportData["Previous Period Rating"] = ""
      if (columnHeaders["PotentialLevel"] == true) exportData["Potential Level"] = ""
      if (columnHeaders["TalentCategory"] == true) exportData["Talent Category"] = ""
      if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = ""
      if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = ""
      if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = ""
      if (columnHeaders["status"] == true) exportData["Status"] = ""
      if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] =""
      if (columnHeaders["workflowHistory"] == true) exportData["Workflow History"] = "";
      return exportData
    });
    //Export Funcs
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(mapped == "" ? Emptymapped : mapped);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, "MyExcel.xlsx");
  };
  //Functions for apply and cancel buttons
  const handleExportFunction = () => {
    setisDrawerOpen(true);
  };
  // function for export to excel ddrawer Apply button
  const handleheadingSortAccept = () => {
    setisDrawerOpen(false);
    handleExport();
    // reset checkbox
    setcolumnHeaders({
      Ecode: true,
    Ename: true,
    Firstname: true,
    Eposition: true,
    ESection: true,
    EGrade: true,
    EDivision: true,
    ESubSection: true,
    AppraiserName: true,
    ReviewerName: true,
    NormalizerName: true,
    employeerating: true,
    appraiserRating: true,
    // reviewerRating: true,
    // normalizerRating: true,
    appraisercode: true,
    reviewercode: true,
    normalizercode: true,
    OverallRating: true,
    PreviousRating: true,
    PotentialLevel: true,
    TalentCategory: true,
    WorkLocation: true,
    ManagerCode: true,
    ManagerName: true,
    ManagerPosition: true,
    PendingAction:true,
    appraiserCode: true,
    reviewerCode: true,
    normalizerCode: true,
    status: true,
    ServiceReferenceDate: true,
    Function: true,
    SupervisoryRole: true,
    EmailID: true,
    CalendarName: true,
    SelectAll: true,
    workflowHistory : true
    })
  };
  // function for export to excel ddrawer close button
  const handleCloseGrade = () => {
    setisDrawerOpen(false);
    // reset checkbox
    setcolumnHeaders({
      Ecode: true,
    Ename: true,
    Firstname: true,
    Eposition: true,
    ESection: true,
    EGrade: true,
    EDivision: true,
    ESubSection: true,
    AppraiserName: true,
    ReviewerName: true,
    NormalizerName: true,
    employeerating: true,
    appraiserRating: true,
    // reviewerRating: true,
    // normalizerRating: true,
    appraisercode: true,
    reviewercode: true,
    normalizercode: true,
    OverallRating: true,
    PreviousRating: true,
    PotentialLevel: true,
    TalentCategory: true,
    WorkLocation: true,
    ManagerCode: true,
    ManagerName: true,
    ManagerPosition: true,
    PendingAction:true,
    appraiserCode: true,
    reviewerCode: true,
    normalizerCode: true,
    status: true,
    ServiceReferenceDate: true,
    Function: true,
    SupervisoryRole: true,
    EmailID: true,
    CalendarName: true,
    SelectAll: true,
    workflowHistory : true
    })
  };
  // function for pagination
  useEffect(() => {
    const Paginate = AllEmployee
      ?.filter((j: any) => {
        if (sectionsFilter === "None" || sectionsFilter === "") {
          return j;
        } else {
          return j?.section
            ?.toLocaleLowerCase() === sectionsFilter?.toLocaleLowerCase();
        }
      })
      .filter((item1: any) => {
        if (positionsFilter?.includes("None") || positionsFilter?.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      .filter((item1: any) => {
        if (GradesFilter?.includes("None") || GradesFilter?.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      ?.filter((j: any) => {
        if (enteredName === "") {
          return j;
        } else if (

          (j?.legal_full_name !== undefined &&
            j?.legal_full_name
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.grade !== undefined &&
            j?.grade
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j.position_long_description !== undefined &&
            j?.position_long_description
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.section !== undefined &&
            j?.section
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (getStatus(j?.appraisal?.status) !== undefined &&
            getStatus(j?.appraisal?.status)
              ?.toLocaleLowerCase()
              ?.includes(enteredName?.toLocaleLowerCase()))
        ) {
          return j;
        }
      })
    settablecount(Paginate?.length)
  }, [AllEmployee, enteredName, GradesFilter, positionsFilter, sectionsFilter])
  const maxLengthForSearch = 30;
  // functio for searchbar
  const handleSearchBar = (e: any) => {
    if (e.target.value.length > maxLengthForSearch) {
      e.target.value = e.target.value.slice(0, maxLengthForSearch);
    }
    setenteredName(e.target.value);
    setPage(0);
  }
  // filter icons on the filter
  const [icon3, setIcon3] = React.useState<any>([]);
  const [icon1, setIcon1] = React.useState<any>([]);
  const [icon2, setIcon2] = React.useState<any>([]);
  React.useEffect(() => {
    if (GradesFilter?.length == 0) {
      setIcon2(false);
    } else {
      setIcon2(true);
    }
  }, [GradesFilter]);
  React.useEffect(() => {
    if (positionsFilter?.length == 0) {
      setIcon3(false);
    } else {
      setIcon3(true);
    }
  }, [positionsFilter]);
  React.useEffect(() => {
    if (sectionsFilter === "None" || sectionsFilter == "") {
      setIcon1(false);
    } else {
      setIcon1(true);
    }
  }, [sectionsFilter]);
  // function for NO data to display message showing while empty
  const tableDataFilterdLength = AllEmployee
    ?.filter((j: any) => {
      if (sectionsFilter === "None" || sectionsFilter === "") {
        return j;
      } else {
        return j?.section
          ?.toLocaleLowerCase() === sectionsFilter?.toLocaleLowerCase();
      }
    })
    .filter((item1: any) => {
      if (positionsFilter?.includes("None") || positionsFilter?.length === 0) {
        return item1;
      } else {
        return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
      }
    })
    .filter((item1: any) => {
      if (GradesFilter?.includes("None") || GradesFilter?.length === 0) {
        return item1;
      } else {
        return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
      }
    })
    ?.filter((j: any) => {
      if (enteredName === "") {
        return j;
      } else if (
        (j?.legal_full_name !== undefined &&
          j?.legal_full_name
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.grade !== undefined &&
          j?.grade
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j.position_long_description !== undefined &&
          j?.position_long_description
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.section !== undefined &&
          j?.section
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (getStatus(j?.appraisal?.status) !== undefined &&
          getStatus(j?.appraisal?.status)
            ?.toLocaleLowerCase()
            ?.includes(enteredName?.toLocaleLowerCase()))
      ) {
        return j;
      }
    })

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          padding: "20px",
          marginLeft: "25px",
          marginRight: "25px"
        }}
      >
        <div>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingBottom="20px">
            <Typography style={{ fontSize: "20px", color: "333333", fontFamily: "Arial" }}>
            </Typography>
            <div>
              <Stack direction="row" alignItems="flex-start" >
                <Searchfeild >
                  <TextField
                    id="outlined-basic"
                    placeholder="Search Here..."
                    autoComplete="off"
                    inputProps={{ maxLength: 256 }}
                    onChange={handleSearchBar}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Searchlens} alt="icon" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Searchfeild>
                <div>
                  <img
                    src={Newexcel}
                    alt="icon"
                    style={{ marginLeft: "15px", marginTop: "5px", cursor: "pointer" }}
                    onClick={handleExportFunction}
                  />
                </div>
              </Stack>
            </div>
          </Stack>
          <ExportToexcel
            isDrawerOpen={isDrawerOpen}
            setisDrawerOpen={setisDrawerOpen}
            checkedCount={checkedCount}
            totalCount={totalCount}
            columnHeaders={columnHeaders}
            handleSelectAll={handleSelectAll}
            handleheadingCalendar={handleheadingCalendar}
            handleEmployeeCode={handleEmployeeCode}
            handleheading1={handleheading1}
            handlefirstName={handlefirstName}
            handleServiceReferenceDateData={handleServiceReferenceDateData}
            handleheading2={handleheading2}
            handleheading3={handleheading3}
            handleFunctionData={handleFunctionData}
            handleSupervisoryRoleData={handleSupervisoryRoleData}
            handleEmailIDData={handleEmailIDData}
            handleDivision={handleDivision}
            handleSection={handleSection}
            handleSubSection={handleSubSection}
            handleWorkLocation={handleWorkLocation}
            handleAppCodes={handleAppCodes}
            handleAppraiserName={handleAppraiserName}
            handleRevCodes={handleRevCodes}
            handleReviewerName={handleReviewerName}
            handleNorCodes={handleNorCodes}
            handleNormalizerName={handleNormalizerName}
            handleemployeerating={handleemployeerating}
            handleAppraiserRating={handleAppraiserRating}
            handleReviewerRating={handleReviewerRating}
            handleNormalizerRating={handleNormalizerRating}
            handleOverallRating={handleOverallRating}
            handlePreviousRating={handlePreviousRating}
            handlePotentialLevel={handlePotentialLevel}
            handleTalentCategory={handleTalentCategory}
            handleManagerCode={handleManagerCode}
            handleManagerName={handleManagerName}
            handleManagerPosition={handleManagerPosition}
            handlePendingAction={handlePendingAction}
            handleStatus={handleStatus}
            handleheadingSortAccept={handleheadingSortAccept}
            handleCloseGrade={handleCloseGrade}
            handleWorkflowHistory = {handleWorkflowHistory} />
          <TableContainer>
            <Scroll>
              <CustomScrollbar style={{ width: "100%", height: "calc(100vh - 270px)" }}>
                <Table size="small" aria-label="simple table">
                  <TableHead
                    style={{ position: "sticky", zIndex: "1000", top: "0px" }}
                  >
                    <TableRow sx={{ bgcolor: "#eaeced" }}>
                      <TableCell
                        width="300px"
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >Employee Name
                      </TableCell>
                      <TableCell
                        width="250px"
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      ><FormControl sx={{ m: 0, width: 80, height: "0" }}>
                          <Stack direction="row">
                            <span>Position</span>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              size="small"
                              sx={{
                                width: "25px", fontSize: "0rem",
                                "& .MuiSvgIcon-root": {
                                  color: "#3e8cb5 !important"
                                },
                              }}
                              disableUnderline
                              variant="standard"
                              MenuProps={MenuProps}
                              multiple
                              value={positionsFilter}
                              onChange={handleChangeSelectPositions}
                              renderValue={(selected) => selected.join(', ')}
                            >
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                  padding: "0px",
                                }}
                                key="all"
                                value="all"
                                classes={{
                                  root: isAllpositionsFilter ? classes.selectedAll : "",
                                }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: "14px !important",
                                      },
                                    }}
                                    style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                    size="small"
                                    classes={{
                                      indeterminate: classes.indeterminateColor,
                                    }}
                                    checked={isAllpositionsFilter}
                                    indeterminate={
                                      positionsFilter?.length > 0 &&
                                      positionsFilter?.length < positionArray?.length
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "13px",
                                      fontFamily: "Arial",
                                      color: "#333333",
                                      paddingRight: "10px"
                                    },
                                  }}
                                  classes={{ primary: classes.selectAllText }}
                                  primary="Select All"
                                /></MenuItem>
                              {positionArray?.map((option: any) => (
                                <MenuItem
                                  style={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                    padding: "0px",
                                  }}
                                  key={option}
                                  value={option}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: "14px !important",
                                        },
                                      }}
                                      style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                      size="small"
                                      checked={positionsFilter.indexOf(option) > -1}
                                    />
                                  </ListItemIcon>

                                  <ListItemText sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "13px",
                                      fontFamily: "Arial",
                                      color: "#333333",
                                      paddingRight: "10px"
                                    },
                                  }} primary={option} />
                                </MenuItem>))}
                            </Select>
                            {icon3 && (
                              <FilterAltTwoToneIcon />)}
                          </Stack>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        width="30px"
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                          <Stack direction="row">
                            <span>Grade</span>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              size="small"
                              sx={{
                                width: "25px", fontSize: "0rem",
                                "& .MuiSvgIcon-root": {
                                  color: "#3e8cb5 !important"
                                },
                              }}
                              disableUnderline
                              variant="standard"
                              MenuProps={MenuProps}
                              multiple
                              value={GradesFilter}
                              onChange={handleChangeSelectGrades}
                              renderValue={(selected) => selected.join(', ')}
                            >
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                  padding: "0px",
                                }}
                                key="all"
                                value="all"
                                classes={{
                                  root: isAllGradesFilter ? classes.selectedAll : "",
                                }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: "14px !important",
                                      },
                                    }}
                                    style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                    size="small"
                                    classes={{
                                      indeterminate: classes.indeterminateColor,
                                    }}
                                    checked={isAllGradesFilter}
                                    indeterminate={
                                      GradesFilter?.length > 0 &&
                                      GradesFilter?.length < gradesArray?.length
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "13px",
                                      fontFamily: "Arial",
                                      color: "#333333",
                                      paddingRight: "10px"
                                    },
                                  }}
                                  classes={{ primary: classes.selectAllText }}
                                  primary="Select All"
                                />
                              </MenuItem>
                              {gradesArray?.map((option: any) => (
                                <MenuItem
                                  style={{
                                    fontSize: "14px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                    padding: "0px",
                                  }}
                                  key={option}
                                  value={option}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: "14px !important",
                                        },
                                      }}
                                      style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                      size="small"
                                      checked={GradesFilter.indexOf(option) > -1}
                                    />
                                  </ListItemIcon>
                                  <ListItemText sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "13px",
                                      fontFamily: "Arial",
                                      color: "#333333",
                                      paddingRight: "10px"
                                    },
                                  }} primary={option} />
                                </MenuItem>
                              ))}
                            </Select>
                            {icon2 && (
                              <FilterAltTwoToneIcon />)}
                          </Stack>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        width="200px"
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        <Stack direction="row" alignItems="center" justifyContent="center" >
                          <div
                            aria-controls={openSections ? "fade-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openSections ? "true" : undefined}
                            onClick={handleClickSections}
                          >
                            <Stack direction="row" alignItems="center">
                              Section
                              <ArrowDropDownOutlinedIcon style={{ cursor: "pointer" }} />
                            </Stack>
                          </div>
                          <Menu
                            MenuListProps={{
                              "aria-labelledby": "fade-button",

                            }}
                            sx={{
                              height: "200px",
                            }}
                            anchorEl={anchorElSections}
                            open={openSections}
                            onClose={handleClloseSections}
                          >
                            <MenuItem
                              style={{
                                fontSize: "13px",
                                fontFamily: "Arial",
                                color: "#333333",
                                padding: "0px 10px 2px 17px",
                              }}
                              key="None"
                              value="None"
                              onClick={handleChangeSelectsections}
                            >Clear Filter
                            </MenuItem>
                            {sectionArray?.map((name: any) => (
                              <MenuItem
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "Arial",
                                  color: "#333333",
                                  padding: "0px 10px 2px 17px",
                                  backgroundColor: sectionsFilter === name ? "#EAECED" : "",
                                }}
                                key={name}
                                value={name}
                                onClick={handleChangeSelectsections}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Menu>
                          {icon1 && <FilterAltTwoToneIcon />}
                        </Stack>
                      </TableCell>
                      {hideEmployeeRating && navigationFrom === "PreviousDashboard" && <TableCell
                        width="50px"
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Employee<br></br> Rating
                      </TableCell>}
                      <TableCell
                        width="70px"
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >Potential <br></br> Level
                      </TableCell>
                      <TableCell
                        width="250px"
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Talent<br></br> Category
                      </TableCell>
                      <TableCell
                        width="30px"
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Overall<br></br> Rating
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {tableDataFilterdLength?.length > 0 ? (
                    <TableBody>
                      {AllEmployee
                        .sort((a: any, b: any) => {
                          if (navigationFrom === "PreviousDashboard") {
                            return b?.overall_rating - a?.overall_rating; // Sort by overall_rating in descending order
                          } else {
                            return b?.normalizer?.normalizer_rating - a?.normalizer?.normalizer_rating; // Sort by normalizer_rating in descending order
                          }
                        })
                        ?.filter((j: any) => {
                          if (sectionsFilter === "None" || sectionsFilter === "") {
                            return j;
                          } else {
                            return j?.section
                              ?.toLocaleLowerCase() === sectionsFilter?.toLocaleLowerCase();
                          }
                        })
                        .filter((item1: any) => {
                          if (positionsFilter?.includes("None") || positionsFilter?.length === 0) {
                            return item1;
                          } else {
                            return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
                          }
                        })
                        .filter((item1: any) => {
                          if (GradesFilter?.includes("None") || GradesFilter?.length === 0) {
                            return item1;
                          } else {
                            return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
                          }
                        })
                        ?.filter((j: any) => {
                          if (enteredName === "") {
                            return j;
                          } else if (

                            (j?.legal_full_name !== undefined &&
                              j?.legal_full_name
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.grade !== undefined &&
                              j?.grade
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j.position_long_description !== undefined &&
                              j?.position_long_description
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.section !== undefined &&
                              j?.section
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (getStatus(j?.appraisal?.status) !== undefined &&
                              getStatus(j?.appraisal?.status)
                                ?.toLocaleLowerCase()
                                ?.includes(enteredName?.toLocaleLowerCase()))
                          ) {
                            return j;
                          }
                        })?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        ).map((j: any) => {
                          return (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  borderColor: "lightgrey",
                                },
                              }}
                            >
                              <TableCell
                                align="left"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {j?.legal_full_name}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {j?.position_long_description}
                              </TableCell>
                              <TableCell sx={{
                                fontFamily: "Arial",
                                borderColor: "lightgrey",
                                fontSize: "14px",
                                color: "#333333",
                                wordBreak: "break-word",
                                paddingRight: "50px"
                              }}
                                align="center">
                                {j?.grade}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                              >{j?.section}
                              </TableCell>
                              {hideEmployeeRating && navigationFrom === "PreviousDashboard" && <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  // color: "#333333",
                                  color: (j?.appraisal?.status == "rejected" || j.employee.employee_status === "rejected") ? "red" : "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {(
                                  j?.employee?.employee_rating == 0) ? (
                                  <span> - </span>
                                ) : (
                                  j?.employee?.employee_rating?.toFixed(2)
                                )}
                              </TableCell>}
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                              >{j?.appraisal?.potential}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {j?.talent_category}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: (j?.appraisal?.status === "completed" && j?.employee?.employee_status === "rejected") ? "#1976d2" : "#33333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {navigationFrom === "PreviousDashboard" ? <span>{j?.overall_rating?.toFixed(2)}</span>
                                  :
                                  <span>
                                    {j?.normalizer?.normalizer_rating?.toFixed(2)}
                                  </span>}
                                {/* {(j?.appraisal?.status === "completed" && j?.employee?.employee_status === "rejected") ? (
                                    <span style={{ color: "#1976d2" }}>{j?.normalizer?.normalizer_rating?.toFixed(2)}</span>
                                  ) : (j?.appraisal?.status === "completed") ? (<span>{j?.normalizer?.normalizer_rating?.toFixed(2)}</span>) :
                                    "-"
                                  } */}
                              </TableCell>
                            </TableRow>)
                        })}
                    </TableBody>) : (
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          align="left"
                          style={{ fontWeight: '500', border: "none", color: "#808080", fontSize: "18px", fontFamily: "arial", paddingTop: "10px" }}
                        >
                          No data to display
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </CustomScrollbar>
            </Scroll>
          </TableContainer>
          {tableDataFilterdLength?.length > 0 && <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={tablecount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
        </div>
      </Box>
    </>
  );
}
