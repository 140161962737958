import React from 'react'
import PerformanceGoalsView from '../performanceGoals/PerformanceGoalsView'
import { Typography } from '@mui/material'

const PerformanceGoals = (props: any) => {
  const { showIfAppraiser, showIfEmployee,
    performanceGoalsFieldValues,
    performanceGoalsFieldValuesEmployee,
    showPerformanceGoalsFieldValuesEmployee,
    showPerformanceGoalsFieldValues } = props
  return (
    <>

     {(performanceGoalsFieldValues?.length > 0 || performanceGoalsFieldValuesEmployee?.length > 0 ) && ( <Typography
        style={{ color: "#3e8cb5", fontSize: "16px", fontFamily: "Arial", marginBottom: "20px" }}
      >
        <b>Performance Goals</b>
      </Typography>)}
      {showPerformanceGoalsFieldValues && showIfAppraiser &&
        <PerformanceGoalsView
          showIfAppraiser={showIfAppraiser}
          performanceGoals={performanceGoalsFieldValues} />
      }
      {showPerformanceGoalsFieldValuesEmployee && showIfEmployee &&
        <PerformanceGoalsView
          showIfEmployee={showIfEmployee}
          performanceGoals={performanceGoalsFieldValuesEmployee} />
      }
    </>
  )
}

export default PerformanceGoals