import * as React from "react";
import {Breadcrumbs } from "@mui/material";
import {  useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useGetEmployeeByFilterQuery,useGetActiveCalenderQuery, useGetReviewerEmployeeByFilterQuery } from "../../../../service";
import FilteredTableofExpNinebox from "../../../../components/Dashboard_Reusable_Component/FilteredTableofExpNinebox";
import { useLoggedInUser } from "../../../../hooks/useLoggedInUser";
import { useState, useEffect } from "react";
import FilteredTableofExpNineboxODChild from "../../../../components/homepage/OverallDashboardComponents/NineBox/FilteredTableofExpNineboxODChild";

export default function NineboxparentforOveralldashboard(props: any) {
    const{indexBasedValue,indexBasedTitle,employeeData1,setSolidperformersExpandActive,navigationFrom}=props;
  const location: any = useLocation();
  const navigate = useNavigate();
  const [users, setUsers] = useState<any>([]);
  const [hideEmployeeRating, sethideEmployeeRating] = React.useState<any>(false);
  useEffect(() => {
  const employeeDetails = employeeData1?.filter((i:any)=>{
    return i?.legal_full_name == indexBasedTitle
  })
  setUsers(employeeDetails);
  }, [employeeData1,indexBasedValue])
  const handleBack = () =>{
    if(navigationFrom == "Normalizer"){
      navigate("/normalizer") 
    }else if(navigationFrom == "PAADMINOverallDashboard"){
      navigate("/PA_Dashboard") 
    }else if (navigationFrom == "Reviewer"){
      navigate("/reviewer") 
    }
    setSolidperformersExpandActive(false);
  }
  return (
    <div
      style={{
        background: "#F1F1F1",
        height: "auto",
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        minHeight="50px"
        marginLeft="25px"
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            style={{
              fontSize: "18px",
              color: "#3e8cb5",
              fontFamily: "Arial",
              cursor: "pointer"
            }}
            color="inherit"
            onClick={() => handleBack()}
          >
            Overall Dashboard
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              color: "#333333",
              fontFamily: "Arial",
            }}
            color="text.primary"
          >
            {indexBasedTitle == "" || indexBasedTitle == undefined ? "9-Box Grid" : indexBasedTitle}
          </Typography>
        </Breadcrumbs>
      </Stack>
      <div >
        <FilteredTableofExpNinebox
          EmployeeData={users}
          hideEmployeeRating={hideEmployeeRating}
          AllEmployee={indexBasedValue}
        />
      </div>
    </div>
  );
}
