import { Typography } from '@mui/material';
import React from 'react'

const OneToOneMeetingDateEdit = (props: any) => {
    const { disableTextAfterSubmission, moveTab, setMoveTab, navPrompt, setNavPrompt, oneToOneMeetingDate, setOneToOneMeetingDate, employeeData, employeeDataIsFetching , employeeDataIsLoading } = props;

    // functions 
    const handleOneToOneMeetingDate = (e: any) => {
        setOneToOneMeetingDate(e.target.value);
    }

    return (
        <>
            <div style={{ marginBottom: "20px" ,
            //  marginLeft : "36px"
              }}>
                <Typography
                    style={{
                        fontSize: "16px",
                        color: "#717171",
                        marginBottom: "10px",
                        fontFamily: "arial"
                    }}
                >
                    <b>One-to-One Meeting Date</b><span style={{
                        fontSize: "22px",
                    }}>*</span>
                </Typography>
                <div>
                    <input
                        type="date"
                        name="one_to_one_meeting_date"
                        style={{
                            width: "250px",
                            color: "#7b7b7b",
                            height: "37px",
                            border: "1px solid #c6c6c6",
                            borderRadius: "3px",
                            background: "none",

                        }}
                        disabled={disableTextAfterSubmission || employeeDataIsFetching || employeeDataIsLoading}
                        value={oneToOneMeetingDate}
                        // min={employeeData?.data?.calendar?.end_date_normalizer?.slice(0, 10)}
                        min={employeeData?.data?.normalizer?.normalized_Date?.slice(0, 10)}
                        max={new Date().toISOString().slice(0, 10)}
                        // data-date=""
                        data-date-format="DD MMMM YYYY"
                        onKeyDown={(e) => {
                            if (e.code !== "Tab") {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            handleOneToOneMeetingDate(e);
                            setNavPrompt(true);
                            setMoveTab(true);
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default OneToOneMeetingDateEdit