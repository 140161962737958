import { FormControl, IconButton, MenuItem, Popover, Select, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import RatingsHeader from '../../ratings/RatingsHeader';
import RatingsObjectiveDetails from '../../ratings/RatingsObjectiveDetails';
import CustomPopOverForTable from '../../../UI/CustomPopoverForTable';
import Infoicon from "../../../../assets/Images/Infoicon.svg";
import Uploadatt from "../../../../assets/Images/Uploadatt.svg";
import Removeattnew from "../../../../assets/Images/Removeattnew.svg";
import { useCreateAzureBlobMutation } from '../../../../service/azureblob';


const Train = styled("div")({
  "& .MuiInputBase-root": {
    // color: "rgb(51 51 51/50%)",
    fontSize: "14px",
    color: "#333333",
    textTransform: "none",
    fontFamily: "Arial",
    backgroundColor: "#f8f8f8",
    borderRadius: "5px",
    padding: "5px",
  },
});

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 60,
      fontSize: "14px !important",
      fontFamily: "arial !important",
      color: "#333333 !important",
    },
  },
};

const Text = styled("div")({
  // position: "absolute",
  width: "100%",

  "& .MuiInputBase-input": {
    fontWeight: "400",
    textTransform: "none",
    fontFamily: "Arial",
    fontSize: "14px",
    color: "#333333",
    // backgroundColor: "#f8f8f8",
    backgroundColor: "#ededed",
    padding: "5px",
    borderRadius: "5px",
    // minHeight: "50px",
  },
});

const AppraiserSubmissionRatings = (props: any) => {
  const { visiblityColumnData,
    colorarray,
    ratingData,
    setMoveTab,
    setNavPrompt,
    disableTextAfterSubmission,
    setDisableTextAfterSubmission,
    objectiveDescription,
    setObjectiveDescription,
    attachedFiles,
    setAttachedFiles,
    refetchEmployeeData,
    employeeDataIsFetching,
    employeeDataIsLoading
  } = props;
  const inputRef = useRef<any>(null);
  const [popoverIndex, setPopoverIndex] = useState("");
  const [activeObjectiveId, setActiveObjectiveId] = useState<any>();
  const [activeObjectiveDescriptionName, setActiveObjectiveDescriptionName] =
    useState<any>();
  const [fileSelected, setFileSelected] = useState<any>("");
  const [name, setName] = useState<string>("");
  const [showIsUploading, setShowIsUploading] = useState<any>(false)


  // mutations
  const [sendItem, { data, isLoading: isUploadingAttachment }] =
    useCreateAzureBlobMutation();

  // popovers

  //  pop over for rating definition
  const [anchorRatingDefinition, setAnchorRatingDefinition] = React.useState<HTMLButtonElement | null>(null);
  const openInfoRatingDefinition = Boolean(anchorRatingDefinition);
  const idRatingDefinition = openInfoRatingDefinition ? "simple-popover" : undefined;


  const handleOpenRatingDefinitionInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorRatingDefinition(event.currentTarget);
  };

  const handleCloseRatingDefinitionInfo = () => {
    setAnchorRatingDefinition(null);
  };


  //  popover for appraiser attachment
  const [anchorAppraiserAttachment, setAnchorAppraiserAttachment] = React.useState<HTMLButtonElement | null>(null);
  const openAppraiserAttachment = Boolean(anchorAppraiserAttachment);
  const idAppraiserAttachment = openAppraiserAttachment ? "simple-popover" : undefined;


  const handleClickOpenAppraiserAttachment = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorAppraiserAttachment(event.currentTarget);
  };

  const handleClickCloseAppraiserAttachment = () => {
    setAnchorAppraiserAttachment(null);
  };

  // functions
  const handleClickOpenAttachmentById = (e: any, index: any) => {
    document.getElementById("photo")?.click();
  };

  const handleImageChange = function (
    e: any
    // j: any
  ) {
    const fileList = e.target.files;
    // if (!fileList) return;
    //@ts-ignore
    setName(fileList[0].name);
    // setFileSelected(fileList[0]);

    let reader = new FileReader();
    //@ts-ignore
    reader.readAsDataURL(fileList[0]);
    reader.onload = (e) => {
      setFileSelected(e.target?.result);
    };
  };

  //Function to remove attachments from object
  const removeAttachments = (fileItem: any) => {
    let files = attachedFiles?.map((item: any) => {
      return item;
    });
    files.splice(
      files.findIndex(
        (item: any) =>
          item?.name == fileItem?.name &&
          item?.objective_description == fileItem?.objective_description
      ),
      1
    );
    setAttachedFiles(files);
  };

  useEffect(() => {
    setShowIsUploading(true);
    sendItem({
      // newspic: fileList[0].name,
      newspic: fileSelected,
      newspicname: name,
    })
      .then((res: any) => {
        setShowIsUploading(false);
      });
    if (name != "" && name != undefined) {
      let files = attachedFiles.map((item: any) => {
        return item;
      });
      files.push({
        url: name,
        name: name,
        objective_description: activeObjectiveDescriptionName,
      });
      setAttachedFiles(files);
    }
  }, [fileSelected]);

  useEffect(() => {
    if (isUploadingAttachment == true) {
      setShowIsUploading(true)
    } else if (isUploadingAttachment == false) {
      setShowIsUploading(false)
    }
  }, [isUploadingAttachment])

  const getAttachments = (id: any) => {
    return attachedFiles
      ?.filter((i: any) => i?.objective_description == id)
      .map((k: any) => {
        console.log(k, "zzzzz");
        return {
          resp: (
            <div>
              {" "}
              {(k.url == k.name) ? (k.name) : (<a href={k.url}> {k.name} </a>)}<br />

            </div>
          ),
          remove: {
            name: k.name,
            objective_description: k.objective_description,
          },
        };

        // return k.name
      });
  };

  const appraiserCommentsHandler = (e: any, j: any) => {
    // const selectedRating = ratingData?.data?.find((rating: any) => rating._id === j);
    setObjectiveDescription(() => {
      return objectiveDescription?.map((i: any) => {
        return i._id === j._id
          ? {
            ...i,
            comments: e.target.value,
            // rating_scale :selectedRating?.rating_scale 
          }
          : i;
      });
    });
  };


  const appraiserRatingsHandler = (j: any, ratingId: any) => {
    const selectedRating = ratingData?.data?.find((rating: any) => rating._id === ratingId);
    setObjectiveDescription(() => {
      return objectiveDescription?.map((i: any) => {

        return i._id === j._id
          ? {
            ...i,
            ratings: ratingId,
            rating_scale: selectedRating?.rating_scale,
            ratingdef: selectedRating?.definition, // Setting ratingdef here
            // rating_scale :selectedRating?.definition // rating_scale
          }
          : i;
      });
    });
    if (ratingData && ratingData?.data) {
      const selectedRating = ratingData?.data?.find((rating: any) => rating._id === ratingId);
    }
  }

  return (

    <>
      <div
      // style={{ marginRight: "66px", marginLeft: "36px" }}
      >
        <TableContainer>
          <Table sx={{
            borderCollapse: 'separate',
            borderSpacing: '0px 15px',
          }} size="small" aria-label="simple table">

            {/*  Table Headers will be here */}
            <RatingsHeader
              visiblityColumnData={visiblityColumnData}
              isUploadingAttachment={isUploadingAttachment} />


            <TableBody>
              {objectiveDescription?.map((j: any, index: any) => {
                console.log(j,'checkRatings')
                return (
                  <>
                    <TableRow
                      sx={{
                        "& td, & th": {
                          border: "1px solid #80808014 ",
                          boxShadow: "1px 0px 0px 1px #80808014",
                          borderBottom: "none",
                          borderLeft: "0px",
                          borderTop: "0px"
                        },
                      }}
                    >
                      <RatingsObjectiveDetails
                        index={index}
                        colorarray={colorarray}
                        popoverIndex={popoverIndex}
                        setPopoverIndex={setPopoverIndex}
                        objectiveDescription={j}
                        activeObjectiveId={activeObjectiveId}
                        setActiveObjectiveId={setActiveObjectiveId}
                      />

                      <TableCell
                       width = "1px"
                        align="center"
                        sx={{
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",

                          // paddingLeft: "0px",
                          // paddingRight: "0px",
                          background: "#ffffff",
                          // boxShadow: "1px 0px 0px 1px #80808014",
                          // boxShadow: "0px 0px 4px 2px #8080802e",                     
                        }}                              >


                        <Stack sx={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          justifyContent: "center"
                        }}
                          direction="row" alignItems="center" >
                          <Train>
                            <FormControl
                              sx={{ minWidth: 60 }}
                              size="small"
                            >
                              <Select
                                // labelId="demo-select-small"
                                id="demo-select-small"
                                value={j?.ratings}
                                onChange={(e: any) => {
                                  setActiveObjectiveId(j._id);
                                  const selectedRatingId = e.target.value;
                                }}
                                variant="standard"
                                MenuProps={MenuProps}
                                disableUnderline
                                disabled={employeeDataIsFetching || employeeDataIsLoading}
                              >
                                {ratingData &&
                                  ratingData.data
                                    .slice()
                                    .sort(function (a: any, b: any) {
                                      return a.rating - b.rating;
                                    })
                                    .map((i: any, index1: any) => {
                                      console.log(i, "Anyyyy");
                                      return (
                                        <MenuItem
                                          sx={{
                                            fontSize: "14px",
                                            fontFamily: "Arial",
                                            color: "#333333",
                                          }}
                                          value={i._id}
                                          onClick={() => {
                                            appraiserRatingsHandler(
                                              j,
                                              i._id
                                            );
                                            setMoveTab(true);
                                            setNavPrompt(true);
                                          }}
                                          disabled={disableTextAfterSubmission}
                                        >
                                          {i.rating}
                                        </MenuItem>
                                      );
                                    })}

                              </Select>
                            </FormControl>
                          </Train>
                          {j?.ratingdef !== undefined && j?.ratingdef !== "" && (
                            <>

                              <IconButton
                                style={{ padding: "4px" }}
                                onClick={(e) => {
                                  handleOpenRatingDefinitionInfo(e);
                                  setPopoverIndex(index);
                                }}
                              >
                                <img width="12px" src={Infoicon} alt="icon" />
                              </IconButton>

                              <Popover
                                id={idRatingDefinition}
                                open={popoverIndex === index && openInfoRatingDefinition}
                                anchorEl={anchorRatingDefinition}
                                onClose={handleCloseRatingDefinitionInfo}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                PaperProps={{
                                  style: {
                                    backgroundColor: "FEFCF8",
                                    boxShadow: "none",
                                    maxWidth: "400px",
                                    borderRadius: "5px",
                                  },
                                }}
                                sx={{
                                  "& .MuiPopover-paper": {
                                    border: "1px solid #3e8cb5",
                                    backgroundColor: "#ffffff",
                                  },
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "arial",
                                    padding: "5px",
                                  }}
                                >
                                  <b>{j?.rating_scale}</b>:{j?.ratingdef}
                                </Typography>
                              </Popover>
                            </>
                          )}
                        </Stack>


                      </TableCell>

                      <TableCell
                        width="100px"
                        align="center"
                        sx={{
                          fontSize: "14px",
                          color: "#333333",
                          fontFamily: "Arial",
                          background: "#FBFBFB",
                          // boxShadow: "1px 0px 0px 1px #80808014",
                          // boxShadow: "0px 0px 4px 2px #8080802e",
                        }}
                      >
                        <Text>
                          <TextField
                            disabled={employeeDataIsFetching || employeeDataIsLoading}
                            fullWidth
                            multiline
                            autoComplete="off"
                            size="small"
                            placeholder="Add"
                            inputProps={{ maxLength: 500 }}
                            variant="standard"
                            InputProps={{
                              disableUnderline: true, // <== added this
                              readOnly: disableTextAfterSubmission
                            }}
                            value={j.comments}
                            onChange={(e) => {
                              appraiserCommentsHandler(e, j);
                              setMoveTab(true);
                              setNavPrompt(true);
                            }}

                          />
                        </Text>
                      </TableCell>
                      {/* <TableCell
                      width="50px"
                      align="center"
                      sx={{
                        fontSize: "14px",
                        color: "#333333",
                        fontFamily: "Arial",
                        background: "#ffffff",
                        // boxShadow: "1px 0px 0px 1px #80808014",
                        // boxShadow: "0px 0px 4px 2px #8080802e",

                      }}
                    > */}

                      {/* <input
                        id="photo"
                        name="photo"
                        type="file"
                        ref={inputRef}
                        multiple={true}
                        style={{ display: "none" }}
                        onChange={(e: any) => {
                          console.log(e, "fileselected");
                          setMoveTab(true);
                          setNavPrompt(true);
                          return handleImageChange(e);
                        }}
                      /> */}

                      {/* <IconButton style={{ padding: "0px" }}
                      // disabled={showIsUploading || disableTextAfterSubmission}
                      >
                        <img
                          src={Uploadatt}
                          onClick={(e: any) => {
                            setActiveObjectiveDescriptionName(
                              j?.name?._id
                            );
                            handleClickOpenAttachmentById(e, index);
                            setPopoverIndex(index);
                          }}
                        />
                      </IconButton> */}

                      {/* {getAttachments(j?.name?._id)?.length > 0 && (
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#52C8F8",
                            fontFamily: "Arial",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          aria-describedby={"idAppraiserAttachment"}
                          onClick={(e: any) => {
                            handleClickOpenAppraiserAttachment(e);
                            setPopoverIndex(index);
                          }}

                        >
                          File Attached
                        </Typography>
                      )} */}
                      {/* <CustomPopOverForTable
                        id={idAppraiserAttachment}
                        open={popoverIndex === index && openAppraiserAttachment}
                        anchorEl={anchorAppraiserAttachment}
                        onClose={handleClickCloseAppraiserAttachment}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        PaperProps={{
                          style: {
                            backgroundColor: "FEFCF8",
                            boxShadow: "none",
                            maxWidth: "400px",
                            borderRadius: "5px",
                          },
                        }}
                        sx={{
                          "& .MuiPopover-paper": {
                            border: "1px solid #3e8cb5",
                            backgroundColor: "#ffffff",
                            // width: "30%",
                          },
                        }}
                      > */}
                      {/* <div
                          style={{
                            padding: "5px",
                            fontSize: "12px",
                            lineHeight: "20px",
                            color: "#333333",
                            fontFamily: "Arial",
                          }}
                        > */}
                      {/* {getAttachments(j?.name?._id)?.map(
                            (k: any, index1: any) => {
                              return (
                                <>
                                  <Stack
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <Typography
                                      style={{
                                        fontSize: "12px",
                                        fontFamily: "Arial",
                                        color: "#333333",                                      
                                      }}
                                    >                                    
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: "12px",
                                        fontFamily: "Arial",
                                        color: "#333333",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: "170px",
                                      }}
                                    >
                                      {k.resp}
                                    </Typography>
                                    <Stack direction="row">
                                      {disableTextAfterSubmission ?
                                        <img
                                          src={Removeattnew}
                                          style={{
                                            cursor: "default",
                                          }}

                                        /> :
                                        <img
                                          src={Removeattnew}
                                          onClick={() => {
                                            removeAttachments(
                                              k.remove
                                            );
                                            setMoveTab(true);
                                          }
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}

                                        />
                                      }


                                    </Stack>
                                  </Stack>
                                </>
                              );
                            }
                          )} */}
                      {/* </div> */}
                      {/* </CustomPopOverForTable> */}
                      {/* </TableCell> */}

                    </TableRow >
                  </>

                )
              })}

            </TableBody>
          </Table>
        </TableContainer>
      </div >
    </>
  )
}

export default AppraiserSubmissionRatings