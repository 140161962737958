import { Button, Link, Stack } from '@mui/material'
import React from 'react'
import { useParams, useNavigate } from "react-router-dom";
import {NORMALIZER_ACTION_SCREEN } from '../../../constants/routes/Routing';

const ReNormalizerFooterButton = (props:any) => {
  const navigate = useNavigate();
  const { employee_id } = useParams();

  const handleCancel = () =>{
    navigate('/normalizer', { state: { from: 1 } });

  }
  const handleRenormalization = () => {
    navigate(`${NORMALIZER_ACTION_SCREEN}/employee/${employee_id}`);
  }
  return (
    <Stack justifyContent="center" spacing={2} paddingTop="30px" direction="row">
    <>

                  <Button
                    variant="outlined"
                    style={{
                      backgroundColor: "Transparent",
                      fontSize: "15px",
                      fontWeight: 400,
                      textTransform: "none",
                      color: "#3e8cb5",
                      borderColor: "#3E8CB5",
                      height: "35px",
                      fontFamily: "Arial",
                      width: "130px",
                    }}
                    onClick={() => {
                        handleRenormalization();
                    }}
                  >
                    Re-Normalize
                  </Button>

                    <Button
                      variant="outlined"
                      style={{
                        backgroundColor: "Transparent",
                        fontSize: "15px",
                        fontWeight: 400,
                        textTransform: "none",
                        color: "#3e8cb5",
                        borderColor: "#3E8CB5",
                        height: "35px",
                        fontFamily: "Arial",
                        width: "70px",
                      }}
                      onClick ={() => {handleCancel()}}
                    >
                      Cancel
                    </Button>
                </>
          </Stack>
  )
}

export default ReNormalizerFooterButton