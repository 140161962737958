import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const Contain = styled("div")({
  "& .MuiButton-root": {
    background: "rgb(255 255 255 / 17%)",
    minWidth: "0px",
    borderRadius: "50px",
    width: "55px",
    height: "55px",
  },
});
function StatusCard(props:any) {
    const {title,percentage,color,count,handleOnClickCard} = props;
  return (
    <div >
      <Box
        sx={{
          background:color,
          padding: "15px 10px",
          cursor:"pointer"
        }}
        onClick={(e) => handleOnClickCard(e,title)}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div>
            <Typography
              style={{
                fontSize: "16px",
                fontFamily: "Arial",
                color: "#ffffff",
              }}
              
            >
              {title}
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                fontFamily: "Arial",
                color: "#ffffff",
              }}
            >
             {percentage}%
            </Typography>
          </div>
          <div>
            <div
              style={{
                width: "55px",
                color: "#ffffff",
                lineHeight:"50px",
                fontFamily: "Arial",
                borderRadius: "50%",
                textAlign:"center",
                fontSize: "16px",
                background: "rgb(255 255 255/10%)",
              }}
            >
             {count}
            </div>
          </div>
        </Stack>
      </Box>
    </div>
  );
}
export default StatusCard;
