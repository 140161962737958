import * as React from "react";
import { Breadcrumbs } from "@mui/material";
import { Link, useLocation,useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FilteredExpandtable from "../../../../components/Dashboard_Reusable_Component/GlobeChartChildExpandtable";
import { useState, useEffect } from "react";

export default function GlobeChartParentforMyTeamDashboard(props: any) {
  const location: any = useLocation();
  const tabLocation = location?.state?.from;
  console.log(location?.state, "tabLocation");
  // navigationFrom is used for breadcrumbs
  const navigationFrom =location?.state?.navigationFrom
  // variables are chart values coming from dashboard based on the applied filter also
  const rangeLow =location?.state?.range?.checklengthinRangesL
  const rangeGood =location?.state?.range?.checklengthinRangesG
  const rangeHigh =location?.state?.range?.checklengthinRangesH
  const rangeAverage =location?.state?.range?.checklengthinRangesA
  const statuscompleted =location?.state?.status?.checkAppraisalStatusCompleted
  const statusinmediation =location?.state?.status?.checkAppraisalStatusInmediation
  const statusinrenormalizetion =location?.state?.status?.checkAppraisalStatusRenormalization

  const [titleDisplay, setTitleDisplay] = React.useState<any>("Employees Rejection");
  const [headersToDisplayWhenInMediation, setheadersToDisplayWhenInMediation] = React.useState<any>(false);
  const [headersToDisplayWhenRenormalization, setheadersToDisplayWhenRenormalization] = React.useState<any>(false);
  const [headersToDisplayWhenCompleted, setheadersToDisplayWhenCompleted] = React.useState<any>(false);
  useEffect(() => {
  if(tabLocation === "Completed"){
    setheadersToDisplayWhenCompleted(true)
  }else if(tabLocation === "In Re-normalization"){
    setheadersToDisplayWhenRenormalization(true)
  }else if(tabLocation === "In Mediation"){
    setheadersToDisplayWhenInMediation(true)
  }else{
    setTitleDisplay("Ratings Distribution")
  }
  }, [tabLocation])
  const [users, setUsers] = useState<any>([]);
  const [hideEmployeeRating, sethideEmployeeRating] = React.useState<any>(false);
  useEffect(() => {
    if (tabLocation == "High Performers") {
      setUsers(rangeHigh)
    } else if (tabLocation == "Good Performers") {
      setUsers(rangeGood)
    } else if (tabLocation == "Average Performers") {
      setUsers(rangeAverage)
    } else if (tabLocation == "Low Performers") {
      setUsers(rangeLow)
    }
  }, [tabLocation]);
  useEffect(() => {
    if (tabLocation === "In Re-normalization") {
      setUsers(statusinrenormalizetion)
      sethideEmployeeRating(true);
    } else if (tabLocation === "Completed") {
      setUsers(statuscompleted)
      sethideEmployeeRating(true);
    } else if (tabLocation === "In Mediation") {
      setUsers(statusinmediation)
      sethideEmployeeRating(true);
    }
  }, [tabLocation]);
  return (
    <div
      style={{
        background: "#F1F1F1",
        height: "auto",
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        minHeight="50px"
        marginLeft="25px"
      >
        {navigationFrom == "Appraiser" &&
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/dashboardreview"}
            >
              My Team Dashboard
            </Link>
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/dashboardreview"}
            >
              {titleDisplay}
            </Link>
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
              color="text.primary"
            >
              {/* {tabLocation} */}
              {tabLocation == "In Re-normalization" ? "Re-normalization" :tabLocation }
            </Typography>
          </Breadcrumbs>}
        {navigationFrom == "Reviewer" &&
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/Reviewer"}
              state={{
                from: "toMyTeamDashboard",
              }}
            >
              My Team Dashboard
            </Link>
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/Reviewer"}
              state={{
                from: "toMyTeamDashboard",
              }}
            >
              {titleDisplay}
            </Link>
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
              color="text.primary"
            >
              {tabLocation}
            </Typography>
          </Breadcrumbs>}
        {navigationFrom == "Normalizer" &&
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/normalizer"}
              state={{
                from: "toMyTeamDashboard",
              }}
            >
              My Team Dashboard
            </Link>
            <Link
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
              }}
              color="inherit"
              to={"/normalizer"}
              state={{
                from: "toMyTeamDashboard",
              }}
            >
              {titleDisplay}
            </Link>
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
              color="text.primary"
            >
              {tabLocation}
            </Typography>
          </Breadcrumbs>}
      </Stack>
      <div >
        <FilteredExpandtable
          EmployeeData={users}
          hideEmployeeRating={hideEmployeeRating}
          headersToDisplayWhenInMediation={headersToDisplayWhenInMediation}
          headersToDisplayWhenRenormalization={headersToDisplayWhenRenormalization}
          headersToDisplayWhenCompleted={headersToDisplayWhenCompleted}
        />
      </div>
    </div>
  );
}
