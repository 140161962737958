import React from 'react'
import EmployeeReviewerApprove from '../../../components/appraisalNew/reviewer/employeeReviewerApprove/EmployeeReviewerApprove'
import ProvidedReviewerContextProvider from '../../../context/appraisal/ReviewerContext';

const EmployeeReviewerApprovePage = () => {
    return (
        <ProvidedReviewerContextProvider>
            <>
                <EmployeeReviewerApprove />
            </>
        </ProvidedReviewerContextProvider>
    )
}

export default EmployeeReviewerApprovePage