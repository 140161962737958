import { IconButton, Stack, TableCell, Typography } from '@mui/material';
import React, { useState } from 'react'
import CustomPopOverForTable from '../../UI/CustomPopoverForTable';
import Infoicon from "../../../assets/Images/Infoicon.svg";


const tableCellbodyStyle = {
    fontSize: "14px",
    color: "#333333",
    fontFamily: "Arial",
}

const RatingsObjectiveDetails = (props: any) => {
    const { colorarray,
        popoverIndex,
        setPopoverIndex,
        objectiveDescription,
        index,
        activeObjectiveId,
        setActiveObjectiveId ,
        showIfpreviousappraisal } = props;

    // popovers
    // popovers for ObjectiveTitleInfo
    const [anchorObjectiveTitleInfo, setAnchorObjectiveTitleInfo] = useState<HTMLButtonElement | null>(null);
    const openObjectiveTitleInfo = Boolean(anchorObjectiveTitleInfo);
    const idObjectiveTitleInfo = openObjectiveTitleInfo ? "simple-popover" : undefined;

    const handleOpenObjectiveTitleInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorObjectiveTitleInfo(event.currentTarget);
  };
    const handleCloseObjectiveTitleInfo = () => {
        setAnchorObjectiveTitleInfo(null);
    };


    // popovers for ObjectiveLevelInfo
    const [anchorObjectiveLevelInfo, setAnchorObjectiveLevelInfo] = useState<HTMLButtonElement | null>(null);
    const openObjectiveLevelInfo = Boolean(anchorObjectiveLevelInfo);
    const idObjectiveLevelInfo = openObjectiveLevelInfo ? "simple-popover" : undefined;

    const handleOpenObjectiveLevelInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        // setAnchorEl(event.currentTarget);
        setAnchorObjectiveLevelInfo(anchorObjectiveLevelInfo ? null : event.currentTarget);
    };

    const handleCloseObjectiveLevelInfo = () => {
        setAnchorObjectiveLevelInfo(null);
    };


    return (
        <>
            <TableCell
                width="70px"
                sx={{
                    fontSize: "14px",
                    color: "#333333",
                    fontFamily: "Arial",
                    wordBreak: "break-word",
                    backgroundColor: (() => {
                        // Determine which objective group to use based on the showIfpreviousappraisal flag
                        const objectiveGroup = showIfpreviousappraisal 
                            ? objectiveDescription?.objective_group 
                            : objectiveDescription?.objective_group?.name;
                        console.log(objectiveGroup,"objectiveGroup")
                        // Find the color entry in the colorarray
                        const colorEntry = colorarray?.find((item: any) =>{
                            console.log(item,"objectiveGroup1")
                           return  item?.objective_group === objectiveGroup
                    });

                        // Return the color or default to "#B6E9EE"
                        return colorEntry ? colorEntry.color : "#B6E9EE";
                    })(),
                }}
                    // backgroundColor: colorarray?.find((item: any) => item?.objective_type == objectiveDescription?.objective_type?.name?.name) != undefined ? colorarray?.find((item: any) => item?.objective_type == objectiveDescription?.objective_type?.name?.name)?.color : "#B6E9EE",
                align="left"
            >
                  {showIfpreviousappraisal ? objectiveDescription?.objective_group :objectiveDescription.objective_group?.name}
                {/* {showIfpreviousappraisal ? objectiveDescription?.objective_type :objectiveDescription.objective_type?.name?.name} */}
            </TableCell>
            <TableCell
                width="70px"
                sx={{
                    fontSize: "14px",
                    color: "#333333",
                    fontFamily: "Arial",
                    wordBreak: "break-word",
                    // backgroundColor: colorarray?.find((item: any) => item?.objective_type == objectiveDescription?.objective_type?.name?.name) != undefined ? colorarray?.find((item: any) => item?.objective_type == objectiveDescription?.objective_type?.name?.name)?.color : "#B6E9EE",
                    // backgroundColor: colorarray?.find((item: any) => item?.objective_type == objectiveDescription?.objective_type?.name?.name) != undefined ? colorarray?.find((item: any) => item?.objective_type == objectiveDescription?.objective_type?.name?.name)?.color : "#B6E9EE",
                }}
                align="left"
            >
                 {showIfpreviousappraisal ? objectiveDescription?.objective_type :objectiveDescription.objective_type?.name?.name}
                {/* {showIfpreviousappraisal ? objectiveDescription?.objective_type :objectiveDescription.objective_type?.name?.name} */}
            </TableCell>
            {/* <TableCell
                width="100px"
                sx={{
                    ...tableCellbodyStyle,
                    wordBreak: "break-word",
                    background: "#ffffff",
                }}
                align="left"
            >
                <Stack direction="row" alignItems="center" >
                    <Typography
                        style={{
                            ...tableCellbodyStyle,
                            textAlign: "left",
                            wordBreak: "break-word",
                        }}
                    >
                    {showIfpreviousappraisal ? objectiveDescription?.objectiveTitle :objectiveDescription?.name?.objectiveTitle}
                    </Typography>
                </Stack> */}

                <CustomPopOverForTable
                    id={idObjectiveTitleInfo}
                    open={popoverIndex === index && openObjectiveTitleInfo}
                    anchorEl={anchorObjectiveTitleInfo}
                    onClose={handleCloseObjectiveTitleInfo}
                >
                    <Stack
                        direction="column"
                        padding="5px"
                        spacing={2}
                    >
                        <Typography
                            style={{
                                fontSize: "12px",
                                fontFamily: "arial",
                                padding: "5px",
                            }}
                        >
                            {showIfpreviousappraisal ? objectiveDescription.description :openObjectiveTitleInfo &&
                                activeObjectiveId &&
                                objectiveDescription?._id === activeObjectiveId &&
                                objectiveDescription?.name?.description}
                        </Typography>
                    </Stack>
                </CustomPopOverForTable>
            {/* </TableCell> */}
            <TableCell
                width="220px"
                align="center"
                sx={{
                    ...tableCellbodyStyle,
                    wordBreak: "break-word",
                    // textAlign: "center",
                    textAlign: "left",
                    background: "#FBFBFB",
                    padding: "0px 8px",                 
                }}
            >
                  {showIfpreviousappraisal ? objectiveDescription?.description : objectiveDescription?.name?.description}
                {/* <Stack direction="row" alignItems="center" justifyContent="center"> */}
                    {/* {(objectiveDescription?.level_1_isChecked ||
                        objectiveDescription?.level_2_isChecked ||
                        objectiveDescription?.level_3_isChecked ||
                        objectiveDescription?.level_4_isChecked) && (
                            <IconButton
                                sx={{ padding: "4px" }}
                                aria-describedby={idObjectiveLevelInfo}
                                onClick={(e: any) => {
                                    setActiveObjectiveId(objectiveDescription?._id);
                                    handleOpenObjectiveLevelInfo(e);
                                    setPopoverIndex(index);
                                }}
                            >
                                <img
                                    style={{ width: "12px" }}
                                    src={Infoicon}
                                    alt="icon"
                                />
                            </IconButton>
                        )}
                    {objectiveDescription.level_1_isChecked && (
                        <>
                            <span>L1 </span>
                        </>
                    )}
                    {objectiveDescription.level_2_isChecked && (
                        <>
                            <span>L2 </span>
                        </>
                    )}
                    {objectiveDescription.level_3_isChecked && (
                        <>
                            <span>L3 </span>
                        </>
                    )}
                    {objectiveDescription.level_4_isChecked && (
                        <>
                            <span>L4 </span>
                        </>
                    )} */}
                {/* </Stack> */}
                <CustomPopOverForTable
                    id={"id102"}
                    open={popoverIndex === index && openObjectiveLevelInfo}
                    anchorEl={anchorObjectiveLevelInfo}
                    onClose={handleCloseObjectiveLevelInfo}
                >

                    <div
                        style={{
                            fontSize: "12px",
                            fontFamily: "arial",
                            lineHeight: "20px",
                            padding: "5px",
                        }}
                    >
                        {openObjectiveLevelInfo &&
                            objectiveDescription._id === activeObjectiveId && (
                                <>
                                    {objectiveDescription.level_1_isChecked && (
                                        <>
                                            <span>L1:</span>
                                            <span>
                                                <b>{showIfpreviousappraisal ?objectiveDescription?.level_1?.level_definition :
                                                    objectiveDescription?.name?.level_1
                                                        ?.level_definition
                                                }
                                                </b>
                                            </span>
                                            <br />
                                            <ul style={{
                                                marginTop: "0px",
                                                marginBottom: "0px",
                                            }}>
                                                {showIfpreviousappraisal ? objectiveDescription?.level_1?.behavioral_objective.map(
                                                (item: any) => {
                                                  return <li>{item}</li>;
                                                }
                                              ) :
                                                objectiveDescription?.name?.level_1?.behavioral_objective.map(
                                                    (item: any) => {
                                                        return <li>{item}</li>;
                                                    }
                                                )}
                                            </ul>
                                        </>
                                    )}
                                    {objectiveDescription.level_2_isChecked && (
                                        <>
                                            <span>L2:</span>
                                            <span>
                                                <b> { showIfpreviousappraisal ? objectiveDescription?.level_2?.level_definition : objectiveDescription?.name?.level_2
                                                        ?.level_definition}</b>
                                            </span>
                                            <br />
                                            <ul style={{ marginBottom: "0px", marginTop: "0px" }}>
                                            {showIfpreviousappraisal ? objectiveDescription?.level_2?.behavioral_objective.map(
                                                      (item: any) => {
                                                        return <li>{item}</li>;
                                                      }
                                                    ) :objectiveDescription?.name?.level_2?.behavioral_objective.map(
                                                    (item: any) => {
                                                        return <li>{item}</li>;
                                                    }
                                                )}
                                            </ul>
                                        </>
                                    )}
                                    {objectiveDescription.level_3_isChecked && (
                                        <>
                                            <span>L3:</span>
                                            <span>
                                                <b>{showIfpreviousappraisal ? objectiveDescription?.level_3?.level_definition :
                                                    objectiveDescription?.name?.level_3
                                                        ?.level_definition
                                                }</b>
                                            </span>
                                            <br />
                                            <ul style={{ marginBottom: "0px", marginTop: "0px" }}>
                                            {showIfpreviousappraisal ? objectiveDescription?.level_3?.behavioral_objective.map(
                                                      (item: any) => {
                                                        return <li>{item}</li>;
                                                      }
                                                    ) :objectiveDescription?.name?.level_3?.behavioral_objective.map(
                                                    (item: any) => {
                                                        return <li>{item}</li>;
                                                    }
                                                )}
                                            </ul>
                                        </>
                                    )}
                                    {objectiveDescription.level_4_isChecked && (
                                        <>
                                            <span>L4:</span>
                                            <span>
                                                <b>{showIfpreviousappraisal ?objectiveDescription?.level_4?.level_definition
                                                : objectiveDescription?.name?.level_4
                                                        ?.level_definition
                                                }</b>
                                            </span>
                                            <br />
                                            <ul style={{ marginBottom: "0px", marginTop: "0px" }}>
                                            {showIfpreviousappraisal ? objectiveDescription?.level_4?.behavioral_objective.map(
                                                      (item: any) => {
                                                        return <li>{item}</li>;
                                                      }
                                                    ) : objectiveDescription?.name?.level_4?.behavioral_objective.map(
                                                    (item: any) => {
                                                        return <li>{item}</li>;
                                                    }
                                                )}
                                            </ul>
                                        </>
                                    )}
                                </>
                            )}
                    </div>
                </CustomPopOverForTable>
            </TableCell>
        </>
    )
}

export default RatingsObjectiveDetails