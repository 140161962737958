import * as React from "react";
import { Breadcrumbs} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useLoggedInUser } from "../../../../hooks/useLoggedInUser";
import { useState, useEffect } from "react";
import FilteredTableofExpNinebox from "../../../../components/Dashboard_Reusable_Component/FilteredTableofExpNinebox";
import { useGetPreviousAppraisalEmployeeByFilterQuery } from "../../../../service/employee/previousAppraisal";
import FilteredTableofExpNineboxClosed from "../../../../components/homepage/PreviousDashboardComponents/Ninebox/FilteredTableofExpNineboxClosed";


export default function NineboxparentforCloseddashboard(props: any) {
  const location: any = useLocation();
  const tabLocation = location?.state?.selectName;
  const LOCATION_FROM = location?.state?.LOCATION_FROM
  const CalendarName=location?.state?.valueOfActiveCalender
  const { data: user } = useLoggedInUser();
  const navigate = useNavigate();
   const [employee, setEmployee] = React.useState<any>(`636cb1a7960b1548b80ff785`);

  const [appCalId, setappCalId] = React.useState<any>(`636cb1a7960b1548b80ff785`);
  const SELECT_FOR_DASHBOARD = `employee_code,legal_full_name,position_long_description,grade,
  division,appraiser_name,reviewer_name,normalizer_name,appraisal.potential,manager_code,manager_name,manager_position,work_location
  section,sub_section,appraisal.appraiser_rating,reviewer.reviewer_rating,first_name,
  normalizer.normalizer_rating,appraisal.status,overall_rating,talent_category, service_reference_date,isSupervisor,email,function,
  appraisal.appraiser_status,reviewer.reviewer_status,normalizer.normalizer_status,reviewer.rejection_count,
  appraisal.objective_description,reviewerIsDisabled,pa_status,employee.employee_rating`
  let navigationFrom = "PreviousDashboard";
    React.useEffect(() => {
    if (user?.calendar?._id !== undefined) {
      setappCalId(user?.calendar?._id)
    }
  }, [user])
  const { data: employeeData } = useGetPreviousAppraisalEmployeeByFilterQuery(
    `?manager_code=${user?.employee_code}&calendar=${appCalId}&limit=800&select=${SELECT_FOR_DASHBOARD}`
  );
  const [users, setUsers] = useState<any>([]);
    const [enteredName, setenteredName] = useState("");
  const [hideEmployeeRating, sethideEmployeeRating] = React.useState<any>(false);
  useEffect(()=>{
    setEmployee(location?.state?.indexBasedValue);
   },[location])
  //getting employee details
  useEffect(() => {
  const employeeDetails = employeeData?.data?.filter((i:any)=>{
    console.log(i?.legal_full_name,"new")
    console.log(i?.legal_full_name == tabLocation)
    return i?.legal_full_name == tabLocation
  })
    setUsers(employeeDetails);
  }, [employeeData])
  const handleBack = () => {
    if (LOCATION_FROM == "PreviousDashboardAppraiser") {
      navigate('/ClosedcalendarDashboardAppraiser')
    } else if (LOCATION_FROM == "PreviousDashboardReviewer") {
      navigate('/ClosedcalendarDashboardReviewer')
    } else if (LOCATION_FROM == "PreviousDashboardNormalizer") {
      navigate('/ClosedcalendarDashboardNormalizer')
    }
  }
  return (
    <div
      style={{
        background: "#F1F1F1",
        height: "auto",
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        minHeight="50px"
        marginLeft="25px"
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            style={{
              fontSize: "18px",
              color: "#3e8cb5",
              cursor: "pointer",
              fontFamily: "Arial",
            }}
            color="inherit"
            onClick={() => { handleBack() }}
          >
            Previous PA
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              color: "#333333",
              fontFamily: "Arial",
            }}
            color="text.primary"
          >
            {location?.state?.indexBasedTitle == "" || location?.state?.indexBasedTitle == undefined ? "9-Box Grid" : location?.state?.indexBasedTitle}
          </Typography>
        </Breadcrumbs>
      </Stack>
      <div >
        {/* <FilteredTableofExpNineboxClosed
      navigationFrom={navigationFrom}
      CalendarName={CalendarName}
      EmployeeData = {users}
      hideEmployeeRating={hideEmployeeRating}
      AllEmployee={location?.state?.indexBasedValue}
      /> */}
        <FilteredTableofExpNinebox
          navigationFrom={navigationFrom}
          CalendarName={CalendarName}
          EmployeeData={users}
          hideEmployeeRating={hideEmployeeRating}
          AllEmployee={location?.state?.indexBasedValue}
        />
      </div>
    </div>
  );
}
