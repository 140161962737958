import { CLIENT_URL } from "../../URL"

// Notification action to Employee after Normalizer accepts PA (for Employee)
const NORMALIZER_ACCEPTS_PA = {
    to: "[email]",
    subject: "[year] [calendar name] Performance Appraisal has been submitted for your review and feedback",
    html:  
    `
    <div style="font-size: 14px; font-family: Arial; color:black;">
    <span>Dear [Employee FirstName],</span><br>\<br>\

    <span>The [year] [calendar name] performance appraisal has been submitted by [Appraiser name] for your review and feedback. Please <a href = ${CLIENT_URL}>log in</a> to the system to complete the performance appraisal process.</span><br>\<br>\

    <span>Before submission, kindly arrange a one-on-one meeting with your manager to discuss  your performance, provide additional context, and address any questions or concerns you may have.</span><br>\<br>\

    <span>Please ensure your performance appraisal is submitted by <b>[dd/mm/yy]</b>.</span><br>\<br>\

    <span>This is a system-generated email, please do not reply.</span><br>\<br>\

    <span>Regards,</span><br>\

    <span>Human Resources Team</span>

    </div>
    `

}


// Notification action Normalizer rejects Reviewer PA (for Appraiser/Reviewer) (Pre-normalization)
const NORMALIZER_REJECTS_PA = {
    to: "[email]",
    subject: "[year] [calendar name] Performance Appraisal for [employee code]-[employee name] has been rejected by HR Normalizer",
    html:  
    `
    <div style="font-size: 14px; font-family: Arial; color:black;">
    <span>Dear [Appraiser FirstName],</span><br>\<br>\

    <span>The [year] [calendar name] performance appraisal for [employee code]-[employee name] has been rejected by [Normalizer name] and is now <b>pending resubmission</b>.</span><br>\<br>\

    <span>To resubmit the performance appraisal process, please <a href = ${CLIENT_URL}>log in</a> to the system.</span><br>\<br>\

    <span>This is a system-generated email, please do not reply.</span><br>\<br>\
    
    <span>Regards,</span><br>\
    <span>Human Resources Team</span>
    </div>
    `

}

export {
    NORMALIZER_ACCEPTS_PA,
    NORMALIZER_REJECTS_PA
}