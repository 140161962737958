import React from 'react'
import PerformanceGoalsEdit from '../performanceGoals/PerformanceGoalsEdit'
import PerformanceGoalsView from '../performanceGoals/PerformanceGoalsView';
import { PERFORMANCE_GOAL_VALUE } from '../../../constants/DialogContents/DialogContents';

const PerformanceGoalsTab = (props: any) => {
  const { performanceGoals,
    appraiserPerformanceGoals,
    showIfAppraiserAction,
    setPerformanceGoals,
    moveTab,
    setMoveTab,
    navPrompt,
    setNavPrompt,
    disableTextAfterSubmission,
    goalsCategoryData,
    showIfAppraiserOverview,
    performanceGoalsFieldValues,
    performanceGoalsFieldValuesEmployee,
    showPerformanceGoalsFieldValues,
    showPerformanceGoalsFieldValuesEmployee,
    showIfAppraiserresubmission,
    employeeData,
    employeeDataIsFetching,
    employeeDataIsLoading } = props;
  let showIfAppraiser = true
  let showIfEmployee = true
  let showIfAppraiserResubmissionEmployeeRejection = employeeData?.data?.appraisal?.status == "rejected";
  console.log(showPerformanceGoalsFieldValues, 'showPerformanceGoalsFieldValues')
  return (
    <>
      {
        (showIfAppraiserAction || showIfAppraiserResubmissionEmployeeRejection) && !showPerformanceGoalsFieldValues &&
        !showPerformanceGoalsFieldValuesEmployee &&
        (<div
          style={{
            marginTop: "20px",
            fontSize: "14px",
            fontFamily: "arial",
            fontWeight: "400",
            textTransform: "none",
          }}>{PERFORMANCE_GOAL_VALUE}</div>)
      }
      {(showIfAppraiserAction && showPerformanceGoalsFieldValues) &&
        <PerformanceGoalsView
          showIfAppraiser={showIfAppraiser}
          performanceGoals={performanceGoalsFieldValues}
        />
      }
      {(showIfAppraiserresubmission == true && employeeData?.data?.appraisal?.status == "rejected" && showPerformanceGoalsFieldValues) &&
        <PerformanceGoalsView
          showIfAppraiser={showIfAppraiser}
          performanceGoals={performanceGoalsFieldValues}
        />
      }
      {(showIfAppraiserAction && showPerformanceGoalsFieldValuesEmployee) &&
        <PerformanceGoalsView
          showIfEmployee={showIfEmployee}
          performanceGoals={performanceGoalsFieldValuesEmployee}
        />
      }
      {(showIfAppraiserresubmission && employeeData?.data?.appraisal?.status == "rejected" && showPerformanceGoalsFieldValuesEmployee) &&
        <PerformanceGoalsView
          showIfEmployee={showIfEmployee}
          performanceGoals={performanceGoalsFieldValuesEmployee}
        />
      }
      {showIfAppraiserOverview &&
        <PerformanceGoalsEdit
          showIfAppraiserOverview={showIfAppraiserOverview}
          performanceGoals={performanceGoals}
          setPerformanceGoals={setPerformanceGoals}
          moveTab={moveTab}
          setMoveTab={setMoveTab}
          navPrompt={navPrompt}
          setNavPrompt={setNavPrompt}
          disableTextAfterSubmission={disableTextAfterSubmission}
          goalsCategoryData={goalsCategoryData}
          employeeDataIsLoading={employeeDataIsLoading}
          employeeDataIsFetching={employeeDataIsFetching} />
      }
      { ((showIfAppraiserresubmission == true && showIfAppraiserresubmission !== undefined) && employeeData?.data?.appraisal?.status !== "rejected") &&
        <PerformanceGoalsEdit
          showIfAppraiserOverview={showIfAppraiserOverview}
          performanceGoals={performanceGoals}
          setPerformanceGoals={setPerformanceGoals}
          moveTab={moveTab}
          setMoveTab={setMoveTab}
          navPrompt={navPrompt}
          setNavPrompt={setNavPrompt}
          disableTextAfterSubmission={disableTextAfterSubmission}
          goalsCategoryData={goalsCategoryData}
          employeeDataIsLoading={employeeDataIsLoading}
          employeeDataIsFetching={employeeDataIsFetching} />
      }
    </>
  )
}

export default PerformanceGoalsTab