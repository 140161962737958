import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import React from 'react'

const NormalizerDiscussedRatingView = (props: any) => {
    const { showNormalizerDiscussedRating,appraiserChecked, reviewerChecked, employeeChecked } = props;
    return (
        <>
                    
                    {/* {showNormalizerDiscussedRating && ( */}
                    <div style={{ marginBottom: "20px" }}>
                        <Typography
                            style={{
                                fontSize: "16px",
                                color: "#717171",
                                marginBottom: "10px",
                                fontFamily: "Arial"
                            }}
                        >
                            <b>HR Normalizer discussed the rating with</b>                          
                        </Typography>
                        <FormGroup>
                            <FormControlLabel
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        color: "#333333",
                                        fontFamily: "Arial",
                                    },
                                }}
                                control={
                                    <Checkbox sx={{
                                        "& .MuiSvgIcon-root": {
                                            fontSize: "18px !important",
                                        },
                                    }} checked={appraiserChecked} name="Appraiser" />
                                }
                                label="Appraiser"
                            />
                            <FormControlLabel
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        color: "#333333",
                                        fontFamily: "Arial",
                                    },
                                }}
                                control={
                                    <Checkbox sx={{
                                        "& .MuiSvgIcon-root": {
                                            fontSize: "18px !important",
                                        },
                                    }} checked={reviewerChecked} name="Reviewer" />
                                }
                                label="Reviewer"
                            />
                            <FormControlLabel
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        color: "#333333",
                                        fontFamily: "Arial",
                                    },
                                }}
                                control={
                                    <Checkbox sx={{
                                        "& .MuiSvgIcon-root": {
                                            fontSize: "18px !important",
                                        },
                                    }} checked={employeeChecked} name="Employee" />
                                }
                                label="Employee"
                            />

                        </FormGroup>
                    </div>
                {/* )} */}
        </>
    )
}

export default NormalizerDiscussedRatingView