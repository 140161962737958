import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, styled } from '@mui/material'
import React, { useEffect } from 'react'
import Blueminus from "../../../assets/Images/Blueminus.svg";
import Blueadd from "../../../assets/Images/Blueadd.svg";


const Tf3 = styled("div")({
    borderRadius: "5px",
    backgroundColor: "#f8f8f8",

    "& .MuiInputBase-input": {
        fontSize: "14px",
        fontWeight: "400",
        textTransform: "none",
        fontFamily: "Arial",
        color: "#333333",
        padding: "8px",
        textAlign: "left"
    },
});

const Tf4 = styled("div")({
    marginTop: "10px",
    borderRadius: "5px",
    backgroundColor: "#f8f8f8",

    //opacity: 0.7,
    "& .MuiInputBase-input": {
        color: "#333333",
        fontSize: "14px",
        fontWeight: "400",
        textTransform: "none",
        fontFamily: "Arial",
        // minHeight: "110px !important",
        padding: "8px",
        textAlign: "left"
    },

    "& .MuiTextField-root": {
        width: "100%",
    },
});

const Addmore = styled("div")({
    textDecoration: "underline",
    color: "#93DCFA",
});

const AreaOfImprovementEdit = (props: any) => {
    const {
        showIfEmployee,
        showIfAppraiserOverview,
        areaOfImprovement,
        setAreaOfImprovement,
        disableTextAfterSubmission,
        moveTab,
        setMoveTab,
        navPrompt,
        setNavPrompt,
        showIfAppraiserresubmission,
        employeeDataIsLoading,
        employeeDataIsFetching
    } = props;


    //  functions

    //  to add Area of Improvement field
    const handleImprovementAdd = () => {

        return setAreaOfImprovement((prevState: any) => {
            const toSpread = {
                id: Date.now(),
                value: "",
                specific_actions: [{ value: "" }],
            };
            const newArea = [...prevState, toSpread];
            return newArea;

        });
    };

    //  to remove Area of Improvement field
    const handleSpecificRemove = (index: any) => {

        if (!disableTextAfterSubmission) {
            const newAreaList = [...areaOfImprovement];
            newAreaList?.splice(index, 1);
            setMoveTab(true);
            setNavPrompt(true);
            setAreaOfImprovement(newAreaList);
        }
    };

    // useEffects 
    useEffect(() => {
        if (areaOfImprovement && areaOfImprovement?.length == 0) {
            handleImprovementAdd();
        }
    }, [areaOfImprovement])


    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                    height="20px"
                    marginBottom="10px"
                >
                    <div
                        style={{
                            color: "#717171",
                            fontSize: "16px",
                            fontFamily: "Arial",
                        }}
                    >
                        {(showIfAppraiserOverview || showIfAppraiserresubmission) && (<b> Areas for Improvement</b>)}
                        {showIfEmployee && (<b> Areas for Improvement (Employee)</b>)}
                    </div>
                </Stack>
                <Table size="small">
                    <TableHead>
                        <TableRow
                            sx={{
                                "& td, & th": {
                                    border: "1px solid #e0e0e0",
                                    bgcolor: "#eaeced",
                                },
                            }}
                        >
                            <TableCell
                                width="26%"
                                align="center"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                Specific Areas
                            </TableCell>
                            <TableCell
                                width="50%"
                                align="center"
                                style={{
                                    fontFamily: "Arial",
                                    border: "1px solid #e0e0e0",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                Specific Actions
                            </TableCell>
                            <TableCell
                                width="50%"
                                align="center"
                                style={{
                                    fontFamily: "Arial",
                                    border: "1px solid #e0e0e0",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {areaOfImprovement && areaOfImprovement?.map((item: any, index: any) => (
                            <TableRow
                                sx={{
                                    "& td, & th": {
                                        border: "1px solid #e0e0e0",
                                    },
                                }}
                            >
                                <TableCell>
                                    <Tf3>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            autoComplete="off"
                                            multiline
                                            placeholder="Add"
                                            name="improvement"
                                            key={index}
                                            disabled = {employeeDataIsFetching || employeeDataIsLoading}
                                            value={areaOfImprovement[index].value}
                                            inputProps={{ maxLength: 500 }}
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true, // <== added this
                                                readOnly: disableTextAfterSubmission
                                            }}
                                            onChange={(e) => {
                                                setAreaOfImprovement(
                                                    areaOfImprovement?.map(
                                                        (previousState: any, ix: any) => {
                                                            // console.log(area, "areaaaaaa");
                                                            if (ix === index) {
                                                                return {
                                                                    ...previousState,
                                                                    value: e.target.value,
                                                                };
                                                            }
                                                            return previousState;
                                                        }
                                                    ));
                                                setMoveTab(true);
                                                setNavPrompt(true)
                                            }}
                                        />
                                    </Tf3>
                                </TableCell>
                                <TableCell
                                    style={{
                                        width: "100%",
                                        paddingBottom: "10px",
                                        paddingTop: "0px",
                                    }}
                                >
                                    {areaOfImprovement?.map((i: any, mapIndex: number) => {
                                        return (
                                            <>
                                                {index === mapIndex &&
                                                    areaOfImprovement &&
                                                    i.specific_actions?.map(
                                                        (j: any, index1: any) => {
                                                            return (
                                                                <Stack
                                                                >
                                                                    <Tf4>
                                                                        <Box>
                                                                            <TextField
                                                                                // fullWidth
                                                                                multiline
                                                                                autoComplete="off"
                                                                                size="small"
                                                                                placeholder="Add"
                                                                                name="specificAction"
                                                                                // key={index}
                                                                                value={
                                                                                    areaOfImprovement[index]
                                                                                        ?.specific_actions[
                                                                                        index1
                                                                                    ]?.value
                                                                                }
                                                                                inputProps={{
                                                                                    maxLength: 500,
                                                                                }}
                                                                                variant="standard"
                                                                                disabled={employeeDataIsFetching || employeeDataIsLoading}
                                                                                InputProps={{
                                                                                    disableUnderline:
                                                                                        true, // <== added this
                                                                                    readOnly: disableTextAfterSubmission
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    setAreaOfImprovement(
                                                                                        areaOfImprovement?.map(
                                                                                            (
                                                                                                previousState: any,
                                                                                                ix: any
                                                                                            ) => {
                                                                                                if (
                                                                                                    ix === index
                                                                                                ) {
                                                                                                    return {
                                                                                                        ...previousState,
                                                                                                        specific_actions:
                                                                                                            previousState.specific_actions.map(
                                                                                                                (
                                                                                                                    previousState1: any,
                                                                                                                    ix1: any
                                                                                                                ) => {
                                                                                                                    if (
                                                                                                                        ix1 ===
                                                                                                                        index1
                                                                                                                    ) {
                                                                                                                        return {
                                                                                                                            ...previousState1,
                                                                                                                            value:
                                                                                                                                e
                                                                                                                                    .target
                                                                                                                                    .value,
                                                                                                                        };
                                                                                                                    }
                                                                                                                    return previousState1;
                                                                                                                }),
                                                                                                    };
                                                                                                }
                                                                                                return {
                                                                                                    ...previousState,
                                                                                                };
                                                                                            }
                                                                                        )
                                                                                    );
                                                                                    setMoveTab(true);
                                                                                    setNavPrompt(true)
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    </Tf4>
                                                                </Stack>
                                                            );
                                                        })}
                                            </>
                                        );
                                    })}
                                </TableCell>
                                <TableCell 
                                 align="center"
                                // style={{ borderColor: "#ffffff" }}
                                >
                                    {/* <Stack
                                        direction="row"
                                        alignItems="center"
                                        // width="100px"
                                        // marginLeft="25px"
                                        // marginTop="18px"
                                        spacing={1}
                                    > */}
                                        {/* <img onClick={handleImprovementAdd} src={Blueplus} alt="icon" /> */}


                                        {areaOfImprovement?.length !== 0 && (
                                            // (<IconButton onClick={() => handleImprovementRemove(index)}><RemoveIcon />Remove</IconButton>)
                                            // <Stack
                                            //     direction="row"
                                            //     alignItems="center"
                                            //     // width="100px"
                                            //     // marginLeft="25px"
                                            //     // marginTop="18px"
                                            //     spacing={0}
                                            // >
                                                <Tooltip title="Delete">
                                                    <Addmore
                                                        //  InputProps={{
                                                        //   disableUnderline: true,
                                                        //   readOnly: disableTextAfterSubmission
                                                        // }}
                                                        style={{
                                                            cursor: "pointer",
                                                            color: "#3E8CB5",
                                                            fontSize: "14px",
                                                        }}
                                                        onClick={() =>
                                                            handleSpecificRemove(index)
                                                        }
                                                    >
                                                        <img src={Blueminus} alt="icon" style={{ cursor: disableTextAfterSubmission ? "default" : "pointer" }} />
                                                    </Addmore>
                                                </Tooltip>
                                            // </Stack>
                                        )}

                                    {/* </Stack> */}
                                    {areaOfImprovement?.length - 1 === index && (
                                        <div>
                                            {disableTextAfterSubmission ?
                                                <Addmore
                                                    style={{
                                                        cursor: "default",
                                                        color: "#3E8CB5",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    <img src={Blueadd} alt="icon" />
                                                </Addmore>
                                                :
                                                <Tooltip title="Add">
                                                    <Addmore
                                                        style={{
                                                            cursor: "pointer",
                                                            color: "#3E8CB5",
                                                            fontSize: "14px",
                                                        }}
                                                        onClick={() => {
                                                            handleImprovementAdd();
                                                            setMoveTab(true);
                                                            setNavPrompt(true);
                                                        }}
                                                    >
                                                        <img src={Blueadd} alt="icon" />
                                                    </Addmore>
                                                </Tooltip>}
                                        </div>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </div>
        </>
    )
}

export default AreaOfImprovementEdit